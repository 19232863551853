import React, { useEffect, useCallback, useReducer, useState, useMemo } from 'react';
import moment from 'moment-timezone';
import { isNull, isEmpty, isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { PageContainer, EntityHeader } from 'modules';
import {
  Section,
  Picker,
  Input,
  RadioButtonGroup,
  BaseTimePicker,
  NoData,
  BaseSelect,
  ImageUploader,
  PdfFileUploader,
  Loader,
  ErrorSection,
} from 'ui-kit';
import { notification } from 'utils/services';
import {
  convertVisitJSON,
  getDataFromInclude,
  checkIfFormUnchanged,
  formatDateTimeZone,
  getFullYearAge,
  formatTimeToUtc,
} from 'utils/custom';
import { AuthEntity, VisitEntity } from '_entities';
import { URLS, INPUT_TYPE, MESSAGES } from '_constants';
import * as Styled from 'modules/VisitView/styles';
import { genderOptions } from 'pages/Youth/Add/constants';
import YouthAgeModal from 'modules/Modals/YouthAgeModal';
import { PAGE_TYPE } from '../../../VisitView/constants';
import { formReducer, init, getRadioValue } from '../../_utils';
import {
  formInitialState,
  FORM_ACTION_TYPES,
  INPUT_TYPES,
  formNames,
  PAGE_COLUMNS,
  SUBMISSION_COLUMN,
} from '../../constants';

const { updateVisit } = VisitEntity.actions;
const { getVisitData, getVisitInfo } = VisitEntity.selectors;
const { getTimeZone } = AuthEntity.selectors;

function VisitEditForm() {
  const [loading, setLoading] = useState(false);
  const visitData = useSelector(getVisitData);
  const timeZone = useSelector(getTimeZone);
  const { headerTitle, sectionTitle, submitUrl } = useSelector(getVisitInfo);
  const [state, formDispatch] = useReducer(formReducer, formInitialState, init);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formStateData = state.data?.attributes;
  const formStateError = state.errors;
  const { canBeSubmitted } = state;
  const visitCreator = state.data?.visitCreator;
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useMemo(() => convertVisitJSON(visitData), [visitData]);
  const { attributes } = data;

  useEffect(() => {
    const baseSubmittedTime = formatDateTimeZone(attributes.submitted, timeZone, 'HH:mm');
    const baseOffset = parseInt(moment(attributes.submitted).format('Z')) * 60;
    const dateOffsetInMinutes = parseInt(formatDateTimeZone(attributes.submitted, timeZone, 'Z')) * 60;
    const submittedDateWithTimeZone = new Date(
      new Date(attributes.submitted).getTime() + (dateOffsetInMinutes - baseOffset) * 60 * 1000,
    );

    const payload = {
      ...data,
      attributes: {
        ...attributes,
        [formNames.submitted]: submittedDateWithTimeZone,
        [formNames.submittedTime]: baseSubmittedTime,
      },
    };
    formDispatch({ type: FORM_ACTION_TYPES.SET_INITIAL_DATA, payload });
  }, []);

  const youthData = useMemo(() => {
    const result = {};
    const formData = visitData && getDataFromInclude(visitData.included, 'Form').attributes.form_json.pages;

    const youthListData = formData.reduce((acc, item) => {
      // eslint-disable-next-line no-prototype-builtins
      const isQuestions = item.hasOwnProperty('questions');
      if (item.name !== 'Visit Details') return acc;
      item[isQuestions ? 'questions' : 'elements'].forEach((question) => {
        if (question.name === 'youth_id') {
          acc = { ...acc, ...question };
        }
      });
      return acc;
    }, {});

    if (isEmpty(youthListData)) return null;

    const youthGenderOptions =
      youthListData.gender &&
      Object.keys(youthListData.gender).reduce((acc, item) => {
        const currentGender = genderOptions.find((genderOption) => genderOption.value === youthListData.gender[item]);
        acc[item] = currentGender;
        return acc;
      }, {});

    const youthIdOptions = youthListData?.choices?.reduce((acc, item) => {
      acc[item.value] = { ...item, label: item.value };
      return acc;
    }, {});

    result[formNames.youthId] = youthIdOptions;
    result[formNames.youthGender] = youthGenderOptions;
    result[formNames.youthBirthdate] = youthListData.birthdate;

    return result;
  }, [visitData]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
    },
    [formDispatch],
  );

  const handleSelectChange = useCallback(
    (obj, inputName) => {
      // multiselect return array of options
      const name = Array.isArray(obj) ? inputName : obj.name;
      if (name === formNames.youthId) {
        if (getFullYearAge(youthData?.youth_birthdate[obj?.value]) > 20) {
          setIsModalOpen(() => true);
        }

        return formDispatch({ type: FORM_ACTION_TYPES.SET_YOUTH_DATA, payload: youthData, id: obj.value });
      }
      return formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: obj });
    },
    [formDispatch],
  );

  const handleCloseModal = () => {
    setIsModalOpen(() => false);
  };

  const handlePickerChange = useCallback(
    (date) => {
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: formNames.submitted, payload: date });
    },
    [formDispatch],
  );

  const handleChangeTime = (time) => {
    const payload = time || '';
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: formNames.submittedTime, payload });
  };

  const handleImageChange = useCallback((img, name) => {
    const payload = img.map((item) => ({ ...item, name }));
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload });
  }, []);

  const handlePdfChange = useCallback(async ({ target: { files } }, name) => {
    const [file] = files;
    const reader = new FileReader();

    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
    const payload = [{ file, name, file_path: reader.result }];
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload });
  }, []);

  const handlePdfRemove = (name) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: null });
  };

  const isRadiobuttonGroup = useCallback((arr) => {
    if (!arr.length === 3) return false;
    return arr.some(({ value }) => value === true);
  }, []);

  const handleFormDatePicker = (name, date) => {
    const updatedErrors = Object.entries(errors).reduce(
      (acc, [errorName, errorText]) => (errorName !== name ? { ...acc, [errorName]: errorText } : acc),
      {},
    );

    setErrors(updatedErrors);
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: date });
  };

  const getFormInputs = useCallback(
    (item, i) => {
      const { name, title: itemTitle, choices } = item;
      const title = `${i}. ${itemTitle}`;
      switch (item.type || item[0].type) {
        case INPUT_TYPES.TEXT:
        case INPUT_TYPES.NUMBER:
        case INPUT_TYPES.COMMENT: {
          if (item.inputType && item.inputType === 'date') {
            const prevInputDate = formStateData[item.minValueExpression?.match(/{(.*?)}/)[1]];
            const minDate = prevInputDate || null;
            const currentDate = new Date();
            const maxDate = new Date(new Date().setFullYear(currentDate.getFullYear() + 1));
            const selectedDate = formStateData[name] ? formStateData[name] : null;

            return (
              <div style={{ minHeight: '29px' }}>
                <Styled.Title htmlFor={name}>{title}</Styled.Title>
                <Picker
                  idInput={name}
                  showMonthYearDropdown
                  onChange={(date) => handleFormDatePicker(name, date)}
                  selected={selectedDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  inputFor={name}
                  error={errors[name]}
                  required
                  isFullWidth
                  pageColumns={PAGE_COLUMNS}
                />
              </div>
            );
          }
          return (
            <Input
              name={name}
              value={formStateData[name] || ''}
              type={INPUT_TYPE.TEXT}
              floatingLabel
              title={title}
              onChange={handleChange}
              disabled={name === formNames.youthBirthdate}
              pageColumns={PAGE_COLUMNS}
            />
          );
        }

        case INPUT_TYPES.CHECKBOX: {
          return (
            <BaseSelect
              name={name}
              labelText={title}
              options={choices}
              value={formStateData[item.name]}
              onChange={(options) => handleSelectChange(options, name)}
              placeholder="Select an option"
              inputId={name}
              isMulti
              pageColumns={PAGE_COLUMNS}
            />
          );
        }

        case INPUT_TYPES.DROPDOWN: {
          if (name === formNames.youthGender) {
            return (
              <Input
                name={name}
                value={formStateData[name]?.label || ''}
                type={INPUT_TYPE.TEXT}
                floatingLabel
                title={title}
                onChange={handleChange}
                disabled
                pageColumns={PAGE_COLUMNS}
              />
            );
          }

          return (
            <BaseSelect
              name={name}
              labelText={title}
              options={choices}
              value={formStateData[name]}
              onChange={handleSelectChange}
              inputId={name}
              placeholder="Select an option"
              pageColumns={PAGE_COLUMNS}
            />
          );
        }

        case INPUT_TYPES.RADIOGROUP: {
          if (isRadiobuttonGroup(choices)) {
            return (
              <RadioButtonGroup
                name={name}
                title={title}
                options={choices}
                direction="row"
                value={getRadioValue(formStateData[name])}
                onChange={handleChange}
                pageColumns={PAGE_COLUMNS}
              />
            );
          }

          return (
            <BaseSelect
              name={name}
              labelText={title}
              options={choices}
              value={formStateData[name]}
              onChange={handleSelectChange}
              inputId={name}
              placeholder="Select an option"
              disabled={name === formNames.youthGender}
              pageColumns={PAGE_COLUMNS}
            />
          );
        }

        case INPUT_TYPES.FILE: {
          const { name: fieldName, file } = item.data[0];
          const fileFieldTitle = `${i}. ${file.title || ''}`;
          const isDataArray = isArray(formStateData[fieldName.toLowerCase()]);
          const imageValue = isDataArray
            ? formStateData[fieldName.toLowerCase()]
            : [formStateData[fieldName.toLowerCase()]] || [];
          const { file_name: fileName } = imageValue[0] || [];

          if (item.fileType === 'pdf') {
            return (
              <Styled.ItemWrapper>
                <Styled.Dt>{fileFieldTitle}</Styled.Dt>
                <Styled.Dd>
                  <PdfFileUploader
                    onChange={handlePdfChange}
                    value={imageValue}
                    name={fieldName.toLowerCase()}
                    onClean={handlePdfRemove}
                  />
                </Styled.Dd>
              </Styled.ItemWrapper>
            );
          }

          return (
            <Styled.ItemWrapper>
              <Styled.Dt>{fileFieldTitle}</Styled.Dt>
              <Styled.Dd>
                <ImageUploader
                  image={imageValue}
                  onChange={(img) => handleImageChange(img, fieldName.toLowerCase())}
                  name={fieldName.toLowerCase()}
                />
                <p>{fileName}</p>
              </Styled.Dd>
            </Styled.ItemWrapper>
          );
        }

        default:
          return null;
      }
    },
    [formStateData, handleChange, handleImageChange, isRadiobuttonGroup, handleSelectChange, errors],
  );

  const handleSubmit = async () => {
    formDispatch({ type: FORM_ACTION_TYPES.VALIDATE_DATA });

    if (checkIfFormUnchanged(attributes, state.data.attributes, timeZone)) {
      notification.success(MESSAGES.VISIT.EDIT.SUCCESS);
      history.push(`${submitUrl}/${visitData.data.id}`);
      return;
    }

    if (!canBeSubmitted) return;

    const getCheckboxValue = (arr) => {
      if (arr.length === 0) return null;
      return arr.map((item) => item.value);
    };

    if (!state.data.questions) return;

    setLoading(true);

    const questionsInputNames = state.data.questions.reduce((acc, item) => {
      switch (item.type) {
        case INPUT_TYPES.FILE: {
          const imgItem = item.data[0];
          acc.push({ name: imgItem.name.toLowerCase(), type: imgItem.file.type });
          break;
        }

        default:
          acc.push({ name: item.name, type: item.type });
          break;
      }
      return acc;
    }, []);

    const updatedVisitData = questionsInputNames.reduce(
      (acc, item) => {
        switch (item.type) {
          case INPUT_TYPES.CHECKBOX:
            acc[item.name] = getCheckboxValue(formStateData[item.name]);
            return acc;
          case INPUT_TYPES.DROPDOWN:
          case INPUT_TYPES.RADIOGROUP: {
            acc[item.name] = formStateData[item.name]?.value || formStateData[item.name];
            return acc;
          }
          case INPUT_TYPES.FILE: {
            if (!acc[item.name]) return acc;
            // Delete field from store if image was deleted
            if (acc[item.name]?.length === 0) {
              delete acc[item.name];
              return acc;
            }
            const imgInfo = acc[item.name];
            if (imgInfo.file_path?.includes('https://') || imgInfo.file_path?.includes('http://')) {
              // Image not changed
              acc[item.name] = imgInfo.file_path;
            } else {
              const imgUpdatedInfo = acc[item.name][0];
              // Image was changed
              const { name: fileName, type: fileType } = imgUpdatedInfo?.file;
              acc[item.name] = [{ name: fileName, type: fileType, content: imgUpdatedInfo.file_path }];
            }
            return acc;
          }
          case INPUT_TYPES.COMMENT:
          case INPUT_TYPES.TEXT: {
            const fieldValue = formStateData[item.name];
            const initialValue = visitData.data.attributes[item.name];
            // Empty value of some text fields must be string and some must be null. Compare initial values with formStateValues.
            if (initialValue === null && fieldValue === '') {
              acc[item.name] = initialValue;
            } else {
              acc[item.name] = fieldValue instanceof Date ? moment(fieldValue).format('YYYY-MM-DD') : fieldValue;
            }
            return acc;
          }
          default:
            {
              const field = formStateData[item.name];
              if (isNull(field) || field === '') acc[item.name] = null;
              if (field) acc[item.name] = formStateData[item.name];
            }
            return acc;
        }
      },
      { ...formStateData },
    );

    updatedVisitData.visit_time = formatTimeToUtc({
      date: formStateData.submitted,
      timeZone,
      hoursTime: formStateData[formNames.submittedTime],
    });
    updatedVisitData.modified = formatTimeToUtc({
      date: new Date(),
      timeZone,
      hoursTime: formStateData[formNames.submittedTime],
    });

    const updatedVisit = {
      data: {
        attributes: updatedVisitData,
        id: visitData.data.id,
        type: 'Visit',
      },
    };

    try {
      await dispatch(updateVisit(updatedVisit, visitData.data.id));
      notification.success(MESSAGES.VISIT.EDIT.SUCCESS);
      history.push(`${submitUrl}/${visitData.data.id}`);
    } catch (err) {
      const error = err.response.data;
      setErrors(
        error?.errors.reduce((acc, { source: { pointer }, detail }) => {
          return { ...acc, [pointer.split('/').pop()]: detail };
        }, {}),
      );
      notification.error();
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <PageContainer>
      <Styled.Wrapper>
        <EntityHeader data={visitData} pageType={PAGE_TYPE.EDIT} title={headerTitle} submitFunction={handleSubmit} />
        <ErrorSection errors={errors} />
        <Styled.RowWrapper>
          <Section title={sectionTitle}>
            <Styled.VerticalBorder />
            {state.data.questions ? (
              state.data.questions.map((item, i) => {
                const formInput = getFormInputs(item, i + 1);
                if (isNull(formInput)) return null;
                return <Styled.InputWrapper key={`${i}${item.name}`}>{formInput}</Styled.InputWrapper>;
              })
            ) : (
              <NoData />
            )}
          </Section>
          <Section title="Submission information">
            <Styled.Dl isSubmission>
              <Styled.InputWrapper>
                <Picker
                  label="Submitted date (mm/dd/yyyy)*"
                  showMonthYearDropdown
                  idInput="closeDay"
                  onChange={handlePickerChange}
                  selected={formStateData?.submitted || null}
                  error={formStateError[formNames.submitted]}
                  inputFor="closeDate"
                  isFullWidth
                  name={formNames.submitted}
                  required
                  pageColumns={SUBMISSION_COLUMN}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <BaseTimePicker
                  onChange={(time) => handleChangeTime(time)}
                  error={formStateError?.submitted_time}
                  value={formStateData?.submitted_time}
                  label="Submitted time*"
                  name={formNames.submittedTime}
                  pageColumns={SUBMISSION_COLUMN}
                />
              </Styled.InputWrapper>
              <Styled.SubmissionItemWrapper>
                <Styled.Dt>Completed by</Styled.Dt>
                <Styled.Dd>
                  <Link
                    to={`${URLS.users}/${visitCreator?.id}`}
                  >{`${visitCreator?.first_name} ${visitCreator?.last_name}`}</Link>
                </Styled.Dd>
              </Styled.SubmissionItemWrapper>
            </Styled.Dl>
          </Section>
        </Styled.RowWrapper>
      </Styled.Wrapper>
      <YouthAgeModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </PageContainer>
  );
}

export default VisitEditForm;
