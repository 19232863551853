import { API_URLS, REQUEST_HEADERS } from '_constants';
import * as TYPES from './Venue.types';

export const getVenueList = (params) => {
  // we need that to make url for backend format
  const decodeParams = decodeURIComponent(params);
  const encodeParams = encodeURI(decodeParams);
  return {
    types: [TYPES.GET_VENUE_LIST, TYPES.GET_VENUE_LIST_SUCCESS, TYPES.GET_VENUE_LIST_FAIL],
    promise: (client) => client.get(`${API_URLS.venues}?${encodeParams}&include=aggregationpolygon_set`),
  };
};

export const getVenueCSV = (queryParams) => ({
  types: [TYPES.GET_VENUE_CSV, TYPES.GET_VENUE_CSV_SUCCESS, TYPES.GET_VENUE_CSV_FAIL],
  promise: (client) => {
    const detectedTz = `tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    let params = `?${detectedTz}`;
    if (queryParams) {
      params += `&${queryParams}`;
    }
    return client.get(`${API_URLS.venuesCSV}${params}`);
  },
});

export const getVenueData = (id) => ({
  types: [TYPES.GET_VENUE_DATA, TYPES.GET_VENUE_DATA_SUCCESS, TYPES.GET_VENUE_DATA_FAIL],
  promise: (client) => client.get(`${API_URLS.venues}/${id}?include=aggregationpolygon_set,last_modified_by`),
});

export const addVenue = (data) => ({
  types: [TYPES.ADD_VENUE, TYPES.ADD_VENUE_SUCCESS, TYPES.ADD_VENUE_FAIL],
  promise: (client) => client.post(API_URLS.venues, data, { headers: { ...REQUEST_HEADERS } }),
});

export const editVenue = (id, data) => ({
  types: [TYPES.EDIT_VENUE, TYPES.EDIT_VENUE_SUCCESS, TYPES.EDIT_VENUE_FAIL],
  promise: (client) => client.patch(`${API_URLS.venues}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
});

export const resetVenue = () => ({
  type: TYPES.RESET_VENUE,
});
