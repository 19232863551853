import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Img } from 'ui-kit';
import { BUTTON_TYPES } from '_constants';
import { CampaignEntity } from '_entities';
import { CLOSE_MODAL_ICON } from 'assets/icons';
import { notification } from 'utils/services';
import * as Styled from './styles';

const { updateAssignments } = CampaignEntity.actions;
const assignmentEdited = 'Assignments were successfully updated';

export default function RemoveDataCollectors({ open, onClose, assignmentModal }) {
  const [listOfCollectors, setListOfCollectors] = useState(assignmentModal?.usersList);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const { retailer__name: retailerName, city, address, zipcode } = assignmentModal;

  useEffect(() => {
    setListOfCollectors(assignmentModal?.usersList);
  }, [assignmentModal]);

  const closeModal = () => {
    setIsError(false);
    onClose();
  };

  const saveDataCollectors = async () => {
    const updatedCollectors = listOfCollectors.map((elem) => {
      return {
        id: elem.id,
        type: 'User',
      };
    });

    const updatedData = {
      data: { type: 'Assignment', id: assignmentModal.id, relationships: { users: { data: updatedCollectors } } },
    };

    try {
      const res = await dispatch(updateAssignments(assignmentModal.id, updatedData));
      if (res?.data?.message?.length > 0) {
        notification.success(assignmentEdited);
      }
    } catch (err) {
      err?.response.data.errors?.map((item) => notification.error(item.detail));
    } finally {
      onClose();
    }
  };

  const handleRemoveCollector = (id) => {
    if (listOfCollectors.length === 1) {
      setIsError(true);
      return null;
    }
    const updatedListOfCollectors = listOfCollectors.filter((item) => {
      return item.id !== id;
    });
    return setListOfCollectors(updatedListOfCollectors);
  };

  if (!open) return null;
  return (
    <Modal autoWidth withoutPadding open={open} onClose={closeModal} preventDesktopOverflow>
      <Styled.Wrapper>
        <Styled.HeaderContainer>
          <Styled.Title>Edit assigned data collectors</Styled.Title>
          <Styled.Description>
            <Styled.RetailerDataContainer>
              <Styled.RetailerText>
                Retailer name: <span>{retailerName}</span>
              </Styled.RetailerText>
              <Styled.RetailerText>
                Address: <span>{address}</span>
              </Styled.RetailerText>
              <Styled.RetailerText>
                City: <span>{city}</span>
              </Styled.RetailerText>
              <Styled.RetailerText>
                Zip code: <span>{zipcode}</span>
              </Styled.RetailerText>
            </Styled.RetailerDataContainer>
          </Styled.Description>
        </Styled.HeaderContainer>
        <Styled.DropWrapper>
          {listOfCollectors?.map((item) => {
            return (
              <Styled.CollectorsContainer>
                <Styled.TextContainer>{item.attributes.email}</Styled.TextContainer>
                <Styled.ImgContainer>
                  <Img
                    onClick={() => handleRemoveCollector(item.id)}
                    src={CLOSE_MODAL_ICON}
                    size={[8, 8]}
                    alt="close"
                  />
                </Styled.ImgContainer>
              </Styled.CollectorsContainer>
            );
          })}
          {isError && (
            <span>Please assign another data collector if you want to remove the current data collector. </span>
          )}
        </Styled.DropWrapper>
        <Styled.Footer>
          <Button onClick={closeModal} text="Cancel" variant={BUTTON_TYPES.DARK} />
          <Button text="Apply changes" variant={BUTTON_TYPES.DANGER} onClick={saveDataCollectors} />
        </Styled.Footer>
      </Styled.Wrapper>
    </Modal>
  );
}

RemoveDataCollectors.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  assignmentModal: PropTypes.object.isRequired,
};

RemoveDataCollectors.defaultProps = {
  open: false,
};
