import React from 'react';
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import { CollapsedTab } from 'modules/Modals/old/FilterModal/components/CollapsedTab';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import DatePickerGroup from 'modules/Modals/old/FilterModal/components/DatePickerGroup';
import * as Styled from './styles';

const FIELDS = [
  {
    id: 1,
    field: FILTER_FIELDS.OBJECT_TYPE,
    title: 'Object Type',
    options: FILTER_OPTIONS.OBJECT_TYPE_OPTIONS,
  },
  {
    id: 2,
    field: FILTER_FIELDS.ACTION_FLAG,
    title: 'Action Type',
    options: FILTER_OPTIONS.ACTION_TYPE_OPTIONS,
  },
];

export const AuditLogsQuickQuery = () => (
  <Styled.Wrapper>
    <FormGroup title="Show">
      <DatePickerGroup name={FILTER_FIELDS.ACTION_TIME_RANGE} title="Date range" />
    </FormGroup>
    {FIELDS.map((item) => (
      <CollapsedTab title={item.title} key={item.id}>
        <CheckedList withSelectAll name={item.field} options={item.options} />
      </CollapsedTab>
    ))}
  </Styled.Wrapper>
);
