import { parseYouthProfileData, parseYouthTableData } from 'utils/custom';
import * as TYPES from './Youth.types';

const initialState = {
  youth: {},
  youthTableData: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_YOUTH_LIST_SUCCESS: {
      const { data, meta } = action?.result;
      const newData = parseYouthTableData(data);
      return {
        ...state,
        youthTableData: { data: newData, pagination: meta?.pagination },
      };
    }

    case TYPES.GET_YOUTH_SUCCESS: {
      const { data, included } = action?.result;

      return {
        ...state,
        youth: parseYouthProfileData({ data, included }, action?.timeZone),
      };
    }

    default:
      return state;
  }
};

export default reducer;
