import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Loader } from 'ui-kit';
import { isEmpty } from 'lodash';
import { BUTTON_TYPES } from '_constants';
import { notification } from 'utils/services';
import { CampaignEntity } from '_entities';
import * as Styled from '../styles';

const { bulkAssignments, removeBulkAssignments } = CampaignEntity.actions;

const MODAL_TYPE = {
  description: {
    assign: (count) => `Confirm assigning collectors below to ${count} retailer(s) in the campaign `,
    delete: (count) => `Confirm unassigning  collectors below to ${count} retailer(s) in the campaign `,
  },
  onSuccess: {
    assign: 'Assignments were successfully created',
    delete: 'Assignments were successfully deleted',
  },
  buttonNames: { assign: 'Assign', delete: 'Delete' },
};

function UsersAssigningModal({ isOpen, onClose, search, dispatch, id, retailersCount, selectedCollectors, type }) {
  const [loading, setLoading] = useState(false);

  const handleAssign = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const users = selectedCollectors?.map((item) => {
        return {
          id: item.key,
          type: 'User',
        };
      });

      const bulkData = {
        relationships: {
          retailers: {
            data: [],
          },
          campaign: {
            data: {
              id,
              type: 'Campaign',
            },
          },
          users: {
            data: users,
          },
        },
        type: 'Assignment',
      };

      const removeBulkData = {
        relationships: {
          assignments: {
            data: [],
          },
          campaign: {
            data: {
              id,
              type: 'Campaign',
            },
          },
          users: {
            data: users,
          },
        },
        type: 'Assignment',
      };

      const res = await dispatch(
        type === 'assign'
          ? bulkAssignments({ data: bulkData }, search)
          : removeBulkAssignments({ data: removeBulkData }, search),
      );
      if (res.data.message.length > 0) {
        notification.success(MODAL_TYPE.onSuccess[type]);
      }
      if (!isEmpty(res?.data?.user_can_not_be_assigned_warning)) {
        notification.infoList(
          'The following users can not be assigned to the retailers: ',
          res.data.user_can_not_be_assigned_warning,
        );
      }
    } catch (err) {
      if (err.response) {
        err.response.data.errors.map((item) => notification.error(item.detail));
      }
      if (err.message) {
        notification.error(err.message);
      }
    } finally {
      setLoading(false);
      onClose();
    }
  };

  if (loading) return <Loader />;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Styled.ModalWrapper>
        <Styled.ModalTitle>{MODAL_TYPE.description[type](retailersCount)}</Styled.ModalTitle>
        <Styled.ModalDescription>
          {selectedCollectors.map(({ label }) => (
            <li key={label}>{label}</li>
          ))}
        </Styled.ModalDescription>
        <Styled.ModalButtonWrapper>
          <Styled.ModalButtonCancel onClick={onClose} text="Close" variant={BUTTON_TYPES.DARK} aria-label="Close" />

          <Styled.ModalButtonSet
            onClick={handleAssign}
            text={MODAL_TYPE.buttonNames[type]}
            variant={BUTTON_TYPES.DANGER}
            aria-label={MODAL_TYPE.buttonNames[type]}
          />
        </Styled.ModalButtonWrapper>
      </Styled.ModalWrapper>
    </Modal>
  );
}

UsersAssigningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  selectedCollectors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  type: PropTypes.string.isRequired,
  search: PropTypes.string,
  retailersCount: PropTypes.number,
};

UsersAssigningModal.defaultProps = {
  selectedCollectors: '',
  search: '',
  retailersCount: 0,
};

export default UsersAssigningModal;
