export const VIOLATION_STATUS_RADIO_VALUE = [
  {
    label: 'Open',
    value: true,
    name: 'violation_status',
  },
  {
    label: 'Closed',
    value: false,
    name: 'violation_status',
  },
];

export const FORM_ACTION_TYPES = {
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  SET_INITIAL_ERROR: 'SET_INITIAL_ERROR',
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
};

export const formInitialState = {
  data: { violation: true, violation_comment: '' },
  errors: {},
  canBeSubmitted: true,
};

export const FORM_REQUIRED_FIELDS = [];
