import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'ui-kit';
import * as Styled from './styles';

const Button = ({ onClick, text, disabled, variant, loading, type, icon, style, ...rest }) => {
  return (
    <Styled.ButtonWrapper
      title={text}
      isLoading={loading}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={style}
      {...rest}
    >
      {icon && <Img src={icon} width="18" height="18" sizes={[16, 16]} />}
      <Styled.ButtonTitle>{text}</Styled.ButtonTitle>
      {/* {loading && <Loader coverElement />} */}
    </Styled.ButtonWrapper>
  );
};

Button.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
  text: PropTypes.string,
  variant: PropTypes.string.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
};

Button.defaultProps = {
  style: {},
  onClick: () => {},
  text: 'Click me',
  disabled: false,
  loading: false,
  type: 'button',
  icon: false,
};

export default memo(Button);
