import ImageUploading from 'react-images-uploading';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, ImageZoom } from 'ui-kit';
import { BUTTON_TYPES } from '_constants';
import * as Styled from './styles';

const ImageUploader = (props) => {
  const { multiple, image, onChange, maxImageCount } = props;

  return (
    <Styled.Wrapper>
      <ImageUploading
        multiple={multiple}
        value={image}
        onChange={onChange}
        maxNumber={maxImageCount}
        dataURLKey="file_path"
        {...props}
      >
        {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove }) => {
          return (
            <Styled.Inner>
              {!image.length && <Button onClick={onImageUpload} variant={BUTTON_TYPES.GREY} text="Choose file" />}
              {multiple && image.length && (
                <Button text="Remove all images" variant={BUTTON_TYPES.GREY} onClick={onImageRemoveAll} />
              )}
              {imageList?.map((item, index) => {
                return (
                  <Styled.ImageWrapper key={index}>
                    {item?.data_url ||
                      (item?.file_path && (
                        <ImageZoom src={item?.data_url || item?.file_path} zoom alt={item?.file?.name} />
                      ))}
                    <Styled.ImageTitle>{item?.file?.name}</Styled.ImageTitle>
                    <Styled.ButtonWrapper>
                      <Button text="Choose file" variant={BUTTON_TYPES.GREY} onClick={() => onImageUpdate(index)} />
                      <Button text="Clean" variant={BUTTON_TYPES.GREY} onClick={() => onImageRemove(index)} />
                    </Styled.ButtonWrapper>
                  </Styled.ImageWrapper>
                );
              })}
            </Styled.Inner>
          );
        }}
      </ImageUploading>
    </Styled.Wrapper>
  );
};

ImageUploader.propTypes = {
  multiple: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  maxImageCount: PropTypes.number,
  imageList: PropTypes.array,
};

ImageUploader.defaultProps = {
  multiple: false,
  maxImageCount: 5,
  imageList: [],
};

export default memo(ImageUploader);
