import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  padding: 0;
  word-break: normal;

  @media ${DEVICES.tablet} {
    padding: 0 0 24px;
    word-break: inherit;

    button {
      padding: 7px 0;
    }
  }

  a {
    word-break: inherit;
  }
`;

export const TabsPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--rgb-grey);
  box-sizing: border-box;
  padding: 0 20px 0 0;
  padding: ${({ amount }) => (amount ? '0 20px 0 0;' : '0;')};
  height: 46px;
`;

export const AmountText = styled.div`
  margin: 0 10px 0 auto;
`;
