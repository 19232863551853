/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useRef } from 'react';
import Wrapper, { LabelAda, RemoveWrapper, Input } from './inputSearch.style';
import LoupeIcon from './icon/loupe';
import RemoveSearch from './icon/removeSearch';

const InputSearch = ({ placeholder, onChange, onKeyDown, isWidth, onRemoveSearch, searchValue }) => {
  const [isActive, setIsActive] = useState(false);
  const input = useRef(null);

  const handleEvent = () => {
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsActive(false);
  };

  const handleInputChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <Wrapper onClick={handleEvent} isActive={isActive} onBlur={handleBlur} isWidth={isWidth}>
      <LoupeIcon />
      <LabelAda hidden htmlFor="searchInputFilter" id="search Retailers" aria-label="search input">
        search input{' '}
      </LabelAda>
      <Input
        placeholder="Search by retailer"
        onFocus={handleEvent}
        ref={input}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        value={searchValue}
        type="text"
        id="searchInputFilter"
        name="searchInputFilter"
      />
      {onRemoveSearch && (
        <RemoveWrapper aria-label="Clear search value" onClick={onRemoveSearch}>
          <RemoveSearch />
        </RemoveWrapper>
      )}
    </Wrapper>
  );
};

export default InputSearch;
