import * as actions from './Notification.actions';
import reducer from './Notification.reducer';
import * as types from './Notification.types';
import * as selectors from './Notification.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
