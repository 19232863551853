import { useSelector } from 'react-redux';
import { AuthEntity } from '_entities';

const { getUserSubdomain } = AuthEntity.selectors;

function IsMassAccount() {
  const userState = useSelector(getUserSubdomain);
  const massAccountStates = ['mass', 'post'];
  return massAccountStates.includes(userState);
}

export default IsMassAccount;
