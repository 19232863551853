import styled from 'styled-components';
import { DEVICES } from '_constants';

export const ListWrapper = styled.section`
  width: 100%;

  :not(:last-of-type) {
    margin-bottom: 25px;
  }

  @media ${DEVICES.tablet} {
    :not(:last-of-type) {
      margin-bottom: 40px;
    }
  }
`;

export const ListTitle = styled.h2`
  font-size: 16px;
  line-height: 1.625;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ListInner = styled.ul``;
