import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import { isNull } from 'lodash';
import { PageContainer, AccessControl, HasAccess } from 'modules';
import BackPageButton from 'modules/BackPageButton';
import { AuthEntity, CampaignEntity } from '_entities';
import { useDispatch, useSelector } from 'react-redux';
import { BUTTON_TYPES, URLS, DEFAULT_SORT, PERMISSIONS, MESSAGES, STATUS_CODES, USER_ROLE_TYPES } from '_constants';
import { TableSizeControls, TableTabsWrapper, Button, Section, Loader } from 'ui-kit';
import { SetQueryParams } from 'utils/old/setQueryParams';
import processQSParams from 'utils/old/processQSParams';
import AssignmentsTable from 'pages/Campaign/View/AssignmentsTable';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import moment from 'moment';
import { notification } from 'utils/services';
import { formatDateTimeZone, getDataFromInclude } from 'utils/custom';
import sortParam from 'utils/old/sortParam';
import * as Styled from './styles';

const { CAMPAIGN_MANAGER, AGENCY_MANAGER, ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER } = USER_ROLE_TYPES;

const roleRuleSet = {
  creator: [CAMPAIGN_MANAGER],
  agency: [AGENCY_MANAGER],
  all: [ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER],
};

const { getCampaignData, getAssignmentsList, archiveCampaign } = CampaignEntity.actions;
const { getViewCampaignData, getAssignmentsData } = CampaignEntity.selectors;
const { getTimeZone, getUserId } = AuthEntity.selectors;

const pageTitle = 'View campaign';
const pageSubTitle = 'Assignments';
const editButtonTitle = 'Edit';
const archiveButtonTitle = { archive: 'Archive', unarchive: 'Unarchive' };
const manageCampaignTitle = 'Manage assignments';

const ViewCampaign = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const { data: tableData, pagination } = useSelector(getAssignmentsData);

  const campaignViewData = useSelector(getViewCampaignData);
  const timeZone = useSelector(getTimeZone);

  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState({
    sortBy: DEFAULT_SORT.retailerVisits.prop,
    sortOrder: DEFAULT_SORT.retailerVisits.order,
  });
  const [isLoading, setIsLoading] = useState(true);

  const getAssignments = async ({ page = 1, perPage, sortBy, sortOrder, ...qsParams }) => {
    const campaign = id;
    const params = processQSParams({ page, perPage, sortOrder, campaign, sortBy, ...qsParams });
    try {
      await dispatch(getAssignmentsList(params));
    } catch (e) {
      console.log(e);
    }
  };

  const { data, included } = campaignViewData || {};

  const {
    campaign_name: campaignName,
    created,
    modified,
    private: privateCampaign,
    archived,
    retailers_count: retailersCount,
    retailers_active_count: retailersActiveCount,
    retailers_duplicate_count: retailersDuplicateCount,
    retailers_historic_count: retailerHistoricCount,
    retailers_assigned: retailersAssigned,
    retailers_not_assigned: retailersNotAssigned,
    sec_geog_name: secGeogName,
  } = data?.attributes || {};

  const createdDate = formatDateTimeZone(created, timeZone, 'L');
  const modifiedDate = formatDateTimeZone(modified, timeZone, 'L');

  const accountId = data?.relationships?.account.data.id;
  const agencyId = data?.relationships?.agency.data.id;
  const managerId = data?.relationships?.campaign_manager.data.id;
  const formId = data?.relationships?.form.data.id;
  const lastModifiedId = data?.relationships?.last_modified_by?.data?.id;

  const account = included && getDataFromInclude(included, 'Account', accountId)?.attributes?.name;
  const agency = included && getDataFromInclude(included, 'Agency', agencyId)?.attributes?.name;
  const manager = included && getDataFromInclude(included, 'User', managerId)?.attributes?.email;
  const form = included && getDataFromInclude(included, 'Form', formId)?.attributes?.name;
  const lastModifiedUser = included && getDataFromInclude(included, 'User', lastModifiedId)?.attributes?.email;

  const handleEdit = () => {
    history.push(`${URLS.campaignPages.edit}/${id}`);
  };

  const handleArchive = useCallback(async () => {
    const archiveData = {
      data: {
        type: 'Campaign',
        id,
        attributes: {
          archived: archived ? null : moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        },
      },
    };

    try {
      await dispatch(archiveCampaign(id, archiveData));
      notification.success(archived ? MESSAGES.CAMPAIGN.UNARCHIVE.SUCCESS : MESSAGES.CAMPAIGN.ARCHIVE.SUCCESS);
      history.push(`${URLS.campaign_list}`);
    } catch (err) {
      console.log(err);
    }
  }, [archived]);

  const handleEditAssignments = () => {
    history.push(`${URLS.campaignPages.manageAssignments}/${id}`);
  };

  const loadAssignments = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
    } = {}) => {
      try {
        setIsLoading(true);
        await getAssignments({ page, perPage, sortBy, sortOrder });
        SetQueryParams({
          queryString: {
            page,
            page_size: perPage,
            ordering: sortParam({ sortBy, sortOrder }),
          },
          history,
        });
      } catch (e) {
        console.log('e', e);
      }
    },
    [currentPage, dispatch, currentPerPage, SetQueryParams, {}, sortOption],
  );

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadAssignments({ sortBy, sortOrder });
    },
    [loadAssignments, setSortOption],
  );

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadAssignments({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadAssignments],
  );

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadAssignments({ page: value });
    },
    [loadAssignments, setCurrentPage],
  );

  useEffect(() => {
    (async () => {
      try {
        await loadAssignments({ page: currentPage, perPage: currentPerPage });
        await dispatch(getCampaignData(id));
      } catch (err) {
        const statusCode = err.response?.status;
        if (statusCode === STATUS_CODES.NOT_FOUND) {
          history.push(URLS.notFound);
        } else {
          console.log('err: ', err);
        }
      }
    })();
  }, []);

  const canSeePrivateStatus = HasAccess(
    [PERMISSIONS.CHANGE_PRIVATE_CAMPAIGN_FLAG, PERMISSIONS.ADD_PRIVATE_CAMPAIGN_FLAG],
    data?.relationships,
    roleRuleSet,
  );

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, [data, pagination]);

  return (
    <PageContainer>
      <Styled.MainContainer>
        {isLoading && <Loader />}
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>{pageTitle}</Styled.Title>
          </Styled.TitleWrapper>
          <AccessControl permission={PERMISSIONS.CHANGE_CAMPAIGN} data={data?.relationships} roleRuleSet={roleRuleSet}>
            <Styled.ButtonWrapper>
              <Button
                onClick={handleArchive}
                variant={BUTTON_TYPES.DANGER}
                text={archived ? archiveButtonTitle.unarchive : archiveButtonTitle.archive}
              />
              <Button onClick={handleEdit} variant={BUTTON_TYPES.DANGER} text={editButtonTitle} />
            </Styled.ButtonWrapper>
          </AccessControl>
        </Styled.TopLine>
        <Styled.Inner>
          <Styled.SectionGroup>
            <Section title="Details">
              <Styled.VerticalBorder isDetails />
              <Styled.Dl>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Campaign name</Styled.Dt>
                  <Styled.Dd>{campaignName}</Styled.Dd>
                </Styled.ItemWrapper>
                {canSeePrivateStatus && (
                  <Styled.ItemWrapper isDetails>
                    <Styled.Dt>Is Private Campaign</Styled.Dt>
                    <Styled.Dd>{privateCampaign ? 'Yes' : 'No'}</Styled.Dd>
                  </Styled.ItemWrapper>
                )}
                {!isNull(retailersCount) && (
                  <>
                    <Styled.ItemWrapper isDetails>
                      <Styled.Dt>Initial Retailers Count</Styled.Dt>
                      <Styled.Dd>{retailersCount}</Styled.Dd>
                    </Styled.ItemWrapper>
                    <Styled.ItemWrapper isDetails>
                      <Styled.Dt>Retailers Duplicates Count</Styled.Dt>
                      <Styled.Dd>{retailersDuplicateCount}</Styled.Dd>
                    </Styled.ItemWrapper>
                    <Styled.ItemWrapper isDetails>
                      <Styled.Dt>Retailers Historic Count</Styled.Dt>
                      <Styled.Dd>{retailerHistoricCount}</Styled.Dd>
                    </Styled.ItemWrapper>
                    <Styled.ItemWrapper isDetails>
                      <Styled.Dt>Retailers Count</Styled.Dt>
                      <Styled.Dd>{retailersActiveCount}</Styled.Dd>
                    </Styled.ItemWrapper>
                    <Styled.ItemWrapper isDetails>
                      <Styled.Dt>Retailers already assigned</Styled.Dt>
                      <Styled.Dd>{retailersAssigned}</Styled.Dd>
                    </Styled.ItemWrapper>
                    <Styled.ItemWrapper isDetails>
                      <Styled.Dt>Retailers left to assign</Styled.Dt>
                      <Styled.Dd>{retailersNotAssigned}</Styled.Dd>
                    </Styled.ItemWrapper>
                  </>
                )}
              </Styled.Dl>
            </Section>
            <Section title="Relationships">
              <Styled.VerticalBorder />
              <Styled.Dl>
                <Styled.ItemWrapper>
                  <Styled.Dt>Account</Styled.Dt>
                  <Styled.Dd>{account}</Styled.Dd>
                </Styled.ItemWrapper>
                <Styled.ItemWrapper>
                  <Styled.Dt>Agency</Styled.Dt>
                  <Styled.Dd>{agency}</Styled.Dd>
                </Styled.ItemWrapper>
                <Styled.ItemWrapper>
                  <Styled.Dt>Campaign manager</Styled.Dt>
                  <Styled.Dd>
                    <Link to={`${URLS.users}/${managerId}`}>{manager}</Link>
                  </Styled.Dd>
                </Styled.ItemWrapper>
                <Styled.ItemWrapper>
                  <Styled.Dt>Form</Styled.Dt>
                  <Styled.Dd>{form}</Styled.Dd>
                </Styled.ItemWrapper>
                <Styled.ItemWrapper>
                  <Styled.Dt>Secondary Geography</Styled.Dt>
                  <Styled.Dd>{secGeogName}</Styled.Dd>
                </Styled.ItemWrapper>
              </Styled.Dl>
            </Section>
          </Styled.SectionGroup>
          <Section title="Created & Modified">
            <Styled.Dl>
              <Styled.ItemWrapper isCreatedAndModified>
                <Styled.Dt>Created</Styled.Dt>
                <Styled.Dd>{createdDate}</Styled.Dd>
              </Styled.ItemWrapper>
              <Styled.ItemWrapper isCreatedAndModified>
                <Styled.Dt>Last modified user</Styled.Dt>
                <Styled.Dd>
                  {lastModifiedId ? <Link to={`${URLS.users}/${lastModifiedId}`}>{lastModifiedUser}</Link> : '-'}
                </Styled.Dd>
              </Styled.ItemWrapper>
              <Styled.ItemWrapper isCreatedAndModified>
                <Styled.Dt>Last modified date</Styled.Dt>
                <Styled.Dd>{modifiedDate}</Styled.Dd>
              </Styled.ItemWrapper>
            </Styled.Dl>
          </Section>
        </Styled.Inner>
      </Styled.MainContainer>
      <Styled.MainContainer>
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <Styled.Title>{pageSubTitle}</Styled.Title>
          </Styled.TitleWrapper>
          <AccessControl permission={PERMISSIONS.CHANGE_CAMPAIGN} data={data?.relationships} roleRuleSet={roleRuleSet}>
            <Styled.ButtonWrapper>
              <Button onClick={handleEditAssignments} variant={BUTTON_TYPES.DANGER} text={manageCampaignTitle} />
            </Styled.ButtonWrapper>
          </AccessControl>
        </Styled.TopLine>
        <TableTabsWrapper
          hasTabs={false}
          amount={pagination?.count || 0}
          tableComponent={
            <Styled.TableContainer>
              <AssignmentsTable data={tableData} sortOption={sortOption} sortHandler={sortHandler} userId={userId} />
              <TableSizeControls
                itemsPerPageProps={{
                  handlePerPageChange,
                  value: currentPerPage,
                }}
                pageCountProps={{
                  value: currentPage,
                  pages: pagination?.pages || 1,
                  handlePageChange,
                }}
                paginationInfoProps={{
                  total: pagination?.count || 0,
                  currentPage,
                  perPage: currentPerPage,
                }}
                paginationNavProps={{
                  currentPage,
                  handlePageChange,
                  pages: pagination?.pages || 1,
                  titleTable: 'Youth',
                }}
              />
            </Styled.TableContainer>
          }
        />
      </Styled.MainContainer>
    </PageContainer>
  );
};

export default ViewCampaign;
