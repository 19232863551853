import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { PageContainer, AccessControl } from 'modules';
import BackPageButton from 'modules/BackPageButton';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Button, Section } from 'ui-kit';
import { UserEntity, AuthEntity } from '_entities';
import { BUTTON_TYPES, URLS, PERMISSIONS, MESSAGES } from '_constants';
import useIsUserCanEditHook from 'pages/User/utils';
import { notification } from 'utils/services';
import ViewItem from './components/ViewItem';
import * as Styled from './styles';
import { formNames } from '../Add/constants';
import { listedItems, viewItemList } from './constants';

const { getUserInfo, resetUserInfo } = UserEntity.actions;
const { getUserProfile } = UserEntity.selectors;
const { getTimeZone } = AuthEntity.selectors;
const { forgotPassword } = AuthEntity.actions;

function UserView() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [isUserCanEdit] = useIsUserCanEditHook();
  const history = useHistory();
  const { id } = useParams();
  const userProfile = useSelector(getUserProfile);
  const timeZone = useSelector(getTimeZone);
  const listOfUserAgencies = userProfile?.agency?.map((item) => item.value);
  const listOfUserSecGeogs = userProfile?.sec_geogs?.map((item) => item.value);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getUserInfo(id, timeZone));
      } catch (err) {
        console.log(err);
        history.push(URLS.notFound);
      } finally {
        setIsLoading(() => false);
      }
    })();
    return async () => {
      await dispatch(resetUserInfo());
    };
  }, []);

  const handleEditUser = () => {
    history.push(`${URLS.userPages.edit}/${id}`);
  };

  const isPermissionToEdit = useMemo(() => {
    return isUserCanEdit(userProfile?.state, id, userProfile?.role?.roleType, listOfUserAgencies, listOfUserSecGeogs);
  }, [id, userProfile, listOfUserAgencies, isUserCanEdit, listOfUserSecGeogs]);

  const handleResetPassword = () => {
    (async () => {
      setIsLoading(() => true);
      const data = {
        email: userProfile[formNames.email],
      };
      try {
        dispatch(forgotPassword(data));
        notification.success(MESSAGES.USER.RESET_BUTTON.SUCCESS);
      } catch (err) {
        console.log(err);
        notification.error(MESSAGES.COMMON_ERROR);
      } finally {
        setIsLoading(() => false);
      }
    })();
  };

  if (isLoading) return <Loader />;

  return (
    <PageContainer>
      <Styled.Wrapper>
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>View user</Styled.Title>
          </Styled.TitleWrapper>
          <Styled.ButtonWrapper>
            {userProfile[formNames.isActive] && (
              <AccessControl permission={PERMISSIONS.CAN_SEND_PASSWORD_RESET_EMAIL}>
                <Button onClick={handleResetPassword} variant={BUTTON_TYPES.DARK} text="Send password reset email" />
              </AccessControl>
            )}
            <AccessControl permission={PERMISSIONS.CHANGE_USER}>
              {isPermissionToEdit && <Button onClick={handleEditUser} variant={BUTTON_TYPES.DANGER} text="Edit" />}
            </AccessControl>
          </Styled.ButtonWrapper>
        </Styled.TopLine>
        <Styled.RowWrapper>
          <Section title="Main information">
            <Styled.Dl>
              <Styled.VerticalBorder />
              {viewItemList.map(({ name, title }) => (
                <Styled.UserItemWrapper key={name}>
                  <Styled.Dt>{title}</Styled.Dt>
                  <Styled.Dd>{userProfile[name] || '-'}</Styled.Dd>
                  <Styled.HorizontalBorder />
                </Styled.UserItemWrapper>
              ))}
              <Styled.UserItemWrapper>
                <Styled.Dt>User role</Styled.Dt>
                <Styled.Dd>{userProfile[formNames.role]?.label || '-'}</Styled.Dd>
                <Styled.HorizontalBorder />
              </Styled.UserItemWrapper>
              <Styled.UserItemWrapper>
                <Styled.Dt>Status</Styled.Dt>
                <Styled.Dd>{userProfile[formNames.isActive] ? 'Active' : 'Inactive'}</Styled.Dd>
                <Styled.HorizontalBorder />
              </Styled.UserItemWrapper>
              {/* <Styled.UserItemWrapper>
                <Styled.Dt>User Permissions</Styled.Dt>
                <Styled.Dd>{userProfile[formNames.isStaff] ? 'Staff' : '-'}</Styled.Dd>
                <Styled.HorizontalBorder />
              </Styled.UserItemWrapper>
              {userProfile[formNames.isSuperUser] && (
                <Styled.UserItemWrapper>
                  <Styled.Dt />
                  <Styled.Dd>Superuser</Styled.Dd>
                  <Styled.HorizontalBorder />
                </Styled.UserItemWrapper>
              )} */}
              {listedItems.map(({ name, title }, index) => (
                <Styled.UserItemWrapper key={name}>
                  <Styled.Dt>{title}</Styled.Dt>
                  {userProfile[name]?.length ? (
                    <div>
                      {userProfile[name].map((item) => (
                        <Styled.Dd key={item.value}>{item.label}</Styled.Dd>
                      ))}
                    </div>
                  ) : (
                    <Styled.Dd>-</Styled.Dd>
                  )}
                  {index !== listedItems.length - 1 && <Styled.HorizontalBorder />}
                </Styled.UserItemWrapper>
              ))}
            </Styled.Dl>
          </Section>
          <Section title="Created & Modified">
            <Styled.Dl>
              <ViewItem>
                <Styled.Dt>Created</Styled.Dt>
                <Styled.Dd>{userProfile.date_joined || '-'}</Styled.Dd>
              </ViewItem>
              <ViewItem>
                <Styled.Dt>Last modified date</Styled.Dt>
                <Styled.Dd>{userProfile.modified || '-'}</Styled.Dd>
              </ViewItem>
              <ViewItem>
                <Styled.Dt>Last modified user</Styled.Dt>
                <Styled.Dd>
                  <Link to={`${URLS.users}/${userProfile.last_modified_by?.id}`}>
                    {userProfile.last_modified_by?.email}
                  </Link>
                </Styled.Dd>
              </ViewItem>
            </Styled.Dl>
          </Section>
        </Styled.RowWrapper>
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default UserView;
