import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  margin: 0 24px;
  flex-direction: column;
  overflow: auto;
  border-bottom: none;

  h2 {
    font-size: 18px;
    font-weight: 500;
  }

  @media ${DEVICES.mobileL} {
    margin: 0 10px;
  }

  label {
    margin: 43px 0 18px 24px;
    font-size: 18px;
    font-weight: 600;
  }

  table {
    border: 1px solid var(--grey);
    border-bottom: none;
    border-spacing: 0;
    width: 100%;
    ${({ isReports }) =>
      isReports &&
      css`
        @media ${DEVICES.mobileL} {
          margin: 0 10px;
          width: calc(100% - 10px);
        }
      `}
    tr {
      td:first-of-type {
        background: var(--opacity-light-grey);
      }
    }
    tr {
      th {
        background: var(--opacity-light-grey);
        text-align: left;
        border-bottom: 1px solid var(--grey);
        padding: 17px 20px;

        font-size: 14px;
        line-height: 16px;
        align-items: center;
        color: var(--dark);
        box-sizing: border-box;

        ${({ isBurger }) =>
          isBurger &&
          css`
            &:last-child {
              width: 50px;
              padding: 17px 0;
              text-align: center;
              border-left: 1px solid var(--grey);
            }

            & + & {
              border-left: none;
              border-right: 0;
            }
          `}
      }

      td {
        border-bottom: 1px solid var(--grey);
        padding: 17px 20px;

        font-size: 14px;
        line-height: 16px;
        cursor: pointer;

        label {
          display: none;
        }

        label {
          display: none;
        }

        ${({ isBurger }) =>
          isBurger &&
          css`
            &:last-child {
              text-align: center;
              border-left: 1px solid var(--grey);
            }
          `}
      }
    }
  }
`;

const TableCaption = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  margin: ${({ isView }) => (isView ? `0 24px 24px 0px;` : `0 24px 24px 24px;`)};

  @media print {
    display: none !important;
  }
  font-size: 14px;
`;

const WrapperTables = styled.div`
  margin: 0 24px;
  ${({ isPopup }) => isPopup && `margin: 0 0px`};

  ${TableCaption} {
    margin-top: 27px;
    margin-left: 0px;
  }
  ${Wrapper} {
    label {
      @media ${DEVICES.tablet} {
        margin: 20px 0 0px 0px;
      }
      margin: 26px 10px 0 0px;
      margin-bottom: 10px !important;
    }
  }
`;

export { Wrapper, TableCaption, WrapperTables };
