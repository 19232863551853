import moment from 'moment';
/**
 * Return object with user timezone and timezone offset
 *
 * @example getTimeZone(Date.now())
 * return {
 *    timeZone: Europe/Kiev,
 *    timeZoneOffset: GMT+0300,
 * }
 *
 * @param {date} Date
 *
 * @return {object}
 */
export const getTimeZone = (date) => {
  return {
    timeZone: new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone,
    timeZoneOffset: `(UTC ${moment(date).format('ZZ')})`,
  };
};
