import styled from 'styled-components';

import { Wrapper as FormGroup } from '../FormGroup/styles';

export const Wrapper = styled.div`
  ${FormGroup} {
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--whisper);
    }
  }
`;
