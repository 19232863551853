import axios from 'axios';
import { tokenService } from 'utils/services';
import { AUTH_TOKEN } from '_constants';
import { baseUrl } from 'lib/baseUrl';

const _API = axios.create({
  // withCredentials: true
  headers: {
    accept: 'application/vnd.api+json, application/json',
    'X-Version': '2.0',
  },
});

_API.interceptors.response.use((response) => {
  if (response.data.token && (response.config.url.includes('auth') || response.config.url.includes('bootstrap'))) {
    tokenService.manageToken(response.data.token);
  }
  return Promise.resolve(response.data);
});

/**
 * Add API_URL to request url before sending request.
 */
_API.interceptors.request.use((config) => {
  const authToken = tokenService.getToken();
  // eslint-disable-next-line no-param-reassign
  if (authToken) config.headers[AUTH_TOKEN] = `Token ${authToken}`;
  // eslint-disable-next-line no-param-reassign
  config.url = `${baseUrl}${config.url}`;
  // config.withCredentials = true;
  return config;
});

export default _API;
