const getUserRole = (id, roleList) => {
  const role = roleList.find((item) => item.id === id);
  return role.attributes;
};

const getUserAccount = (id, included) => {
  const account = included.find((item) => item.type === 'Account' && item.id === id);
  return account?.attributes?.subdomain;
};

export const parseUserListData = ({ data, included }) => {
  const roleList = included?.filter(({ type }) => type === 'Role');

  return data.map((item) => {
    const { id } = item.relationships.role.data;
    const role = getUserRole(id, roleList);
    const accountId = item.relationships.account?.data?.id;
    const agencies = item.relationships.agencies.data.map(({ id: agencyID }) => agencyID);
    const secGeogs = item.relationships.sec_geogs?.data.map(({ id: secGeogId }) => secGeogId);
    const state = getUserAccount(accountId, included);

    return {
      ...item.attributes,
      role: role.name,
      userRoleType: role.role_type,
      id: item.id,
      state,
      agencies,
      secGeogs,
    };
  });
};
