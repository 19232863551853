import styled from 'styled-components';

export const Tab = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  > img {
    margin: 0 6px 0 0;
    transform: rotate(${({ isActive }) => (isActive ? '90deg' : '0deg')});
  }
`;

export const Content = styled.div`
  margin: 20px 0 0;
`;

export const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  padding: 16px;
  border-bottom: 1px solid var(--whisper);
`;
