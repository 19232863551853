import { CAMPAIGN_MANAGER_ROLE, USER_ROLES } from '_constants';
import { AuthEntity } from '_entities';
import { useSelector } from 'react-redux';

const { DATA_COLLECTOR, OPEN_DATA_COLLECTOR, OPEN_DATA_COLLECTOR_BETA } = USER_ROLES;

const { getUserRoleType } = AuthEntity.selectors;

const dataCollectorList = [DATA_COLLECTOR, OPEN_DATA_COLLECTOR, OPEN_DATA_COLLECTOR_BETA];

function useIsDataCollectorCreator() {
  const myUserRoleType = useSelector(getUserRoleType);

  const isDataCollectorCreator = (visitCreatorRole) => {
    if (myUserRoleType !== CAMPAIGN_MANAGER_ROLE) return true;
    if (dataCollectorList.includes(visitCreatorRole)) return false;
    return true;
  };

  return [isDataCollectorCreator];
}

export default useIsDataCollectorCreator;
