import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';

const InvalidValue = (validValue) => {
  if (!validValue) {
    return css`
      border: 1px solid var(--danger);
    `;
  }
  return css`
    border: 1px solid var(--grey-border);
  `;
};

export const InputWrap = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  position: relative;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '45%')};
  padding: 0px;
  margin: ${({ isFullWidth }) => (isFullWidth ? '0 10px 0 0' : '0 10px')};
  margin-left: ${({ isMargin }) => isMargin && '0'};

  @media ${DEVICES.tablet} {
    ${({ type }) =>
      type === 'license' &&
      css`
        margin: 10px 0;
      `}
    ${({ type }) =>
      type === 'addSuspension' &&
      css`
        width: 100%;
        margin: 20px 0 0;
      `}
  }
`;

export const ErrorMessage = styled.span.attrs(() => ({
  ariaRequired: true,
  role: 'alert',
}))`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(--danger);
`;

export const InputField = styled.input.attrs(() => ({
  ariaLable: 'input-field-lable',
  ariaRequired: true,
}))`
  width: 100%;
  height: ${({ height }) => height || `34px`};
  padding-left: 11px;
  ${({ validValue }) => InvalidValue(validValue)};
  box-sizing: border-box;
  position: relative;
  margin-bottom: 5px;
  border: 1px solid ${({ isError, borderColor }) => (isError ? 'var(--danger)' : borderColor || '')};
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  ${(props) => {
    if (props.isActive !== undefined && !props.isActive) {
      return css`
        color: var(--grey-border);
        border: 1px solid var(--grey);
        background: var(--soft-grey);
        pointer-events: none;
      `;
    }
    return '';
  }};

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--grey-border);
      border: 1px solid var(--grey);
      background: var(--soft-grey);
      pointer-events: none;
    `}
`;

export const Span = styled.span`
  color: transparent;
  height: 1px;
  padding: 0;
  width: 1px;
  display: inherit;
`;

export const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;
