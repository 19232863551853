/* eslint-disable */
import React, { useMemo, useState } from 'react';
import { isEmpty, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'ui-kit';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { FilterEntity } from '_entities';
import { ChipList } from './components/ChipsList';
import { DesktopFilterView } from './devices/DesktopFilterView';
import { MobileFilterView } from './devices/MobileFilterView';
import * as Styled from './styles';

const { applyFilters, cancelFilters } = FilterEntity.actions;

function FilterModal({ onClose, open, menu, filterMapping, filterType, getApi, setCurrentPage }) {
  const { width: viewportWidth } = useWindowDimensions();
  const initTabFilter = useMemo(() => menu.find((item) => item.isActive).name, [menu]);
  const [activeTab, setActiveTab] = useState(initTabFilter);
  const {
    filter: { shadowFilters, filterErrors },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const menuItems = useMemo(
    () => menu.reduce((acc, item) => (item.isActive ? acc.concat(item.name) : acc), []),
    [menu],
  );

  const handleCloseButton = () => {
    dispatch(cancelFilters());
    onClose();
  };

  const handleAcceptButton = () => {
    const filters = { ...shadowFilters };

    const newFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => (isEmpty(value) ? acc : { ...acc, [key]: value }),
      {},
    );
    dispatch(applyFilters({ ...newFilters, page: 1 }));
    getApi({ filters: newFilters, page: 1 });
    setCurrentPage(1);
    onClose();
  };

  const handleActiveTab = (tab) => setActiveTab(tab);
  const handleKeyDown = (keyCode, item) => {
    if (keyCode === 13) {
      setActiveTab(item);
    }
  };

  const handleCloseModal = () => {
    onClose();
  };

  if (!open) return null;

  return (
    <Modal open={open} onClose={handleCloseModal} isHideElements isFullScreen>
      <Styled.Wrapper>
        <Styled.Sidebar>
          <Styled.SideBarHeader>Filter</Styled.SideBarHeader>
          <Styled.SidebarMenuList>
            {menuItems.map((item, index) => (
              <Styled.SidebarMenuItem
                onClick={() => handleActiveTab(item)}
                onKeyDown={({ keyCode }) => handleKeyDown(keyCode, item)}
                key={uniqueId()}
                id={index === 0 ? 'firstDesktopItem' : ''}
                aria-label={`item menu ${item}`}
                tabIndex={0}
                isActive={item === activeTab}
              >
                {item}
              </Styled.SidebarMenuItem>
            ))}
          </Styled.SidebarMenuList>
        </Styled.Sidebar>
        <Styled.MainContent>
          <Styled.MobileTitle>Filter</Styled.MobileTitle>
          <ChipList filterMapping={filterMapping} />
          <Styled.FilterContent>
            {viewportWidth > 769 ? (
              <DesktopFilterView tabIndex={0} filterType={filterType} activeTab={activeTab} />
            ) : (
              <MobileFilterView tabIndex={0} filterType={filterType} menu={menu} />
            )}
          </Styled.FilterContent>
          <Styled.FilterFooter>
            <Styled.DarkButton onClick={handleCloseButton} aria-label="Cancel">
              Cancel
            </Styled.DarkButton>
            <Styled.DangerButton disabled={!isEmpty(filterErrors)} onClick={handleAcceptButton} aria-label="Apply">
              Apply
            </Styled.DangerButton>
          </Styled.FilterFooter>
        </Styled.MainContent>
      </Styled.Wrapper>
    </Modal>
  );
}

export default FilterModal;
