import { DEFAULT_SORT } from '_constants';

export const sortOptions = {
  sortBy: DEFAULT_SORT.retailerVisits.prop,
  sortOrder: DEFAULT_SORT.retailerVisits.order,
};

export const COLUMNS = [
  {
    label: 'Retailer name',
    value: 'retailerName',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'City',
    value: 'city',
  },
  {
    label: 'Zip code"',
    value: 'zipCode',
  },
  {
    label: 'Data collector',
    value: 'dataCollector',
  },
  {
    label: 'Visit type',
    value: 'visit',
  },
  {
    label: 'Agency',
    value: 'agency',
  },
  {
    label: 'Campaign name',
    value: 'campaignName',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

export const INITIAL_COLUMNS = [
  'retailerName',
  'address',
  'city',
  'zipCode',
  'dataCollector',
  'visit',
  'agency',
  'campaignName',
  'status',
];
