import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import { BUTTON_TYPES, PERMISSIONS, USER_ROLE_TYPES } from '_constants';
import PropTypes from 'prop-types';
import { RetailerEntity, SurveyEntity, VenueEntity } from '_entities';
import { useWindowSize } from 'hooks';
import { WrapperTables } from 'modules/Modals/old/styles';
import ButtonOrganizer from 'ui-kit/old/buttonOrganizer';
import { Loader } from 'ui-kit';
import { isEmpty } from 'lodash';
import { SurveyModal, AccessControl, HasAccess } from 'modules';
import { VisitTable, MapPopupHeader } from './components';
import { ENTITY_TYPE } from './components/constants';
import * as Styled from './styles';

const { getSurveyFormsData } = SurveyEntity.actions;
const { getRetailerInfo } = RetailerEntity.actions;
const { getProfileData } = RetailerEntity.selectors;
const { getVenueData } = VenueEntity.actions;
const { getVenueInfo } = VenueEntity.selectors;

const { MULTI_ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER, ACCOUNT_MANAGER, DATA_COLLECTOR } = USER_ROLE_TYPES;

const MapPopup = ({ entityId, entityType, setButtonObj, onClose, allowVisits }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTablet, setIsTablet] = useState(true);
  const [entity, setEntity] = useState({});
  const [isOpenSurveyModal, setOpenSurveyModal] = useState(false);
  const [isFormLoad, setIsFormLoad] = useState(false);
  const dispatch = useDispatch();
  const retailer = useSelector(getProfileData);
  const venue = useSelector(getVenueInfo);

  const retailerVisits = useMemo(
    () =>
      retailer.included &&
      retailer.included
        .filter((item) => item.type === 'Visit')
        .sort((a, b) => new Date(b.attributes.submitted) - new Date(a.attributes.submitted)),
    [retailer.included],
  );
  const entityInfo = useMemo(() => entity?.attributes, [entity.id]);

  useEffect(() => {
    if (isEmpty(retailer)) return setEntity(venue);
    return setEntity(retailer);
  }, [retailer, venue]);

  useWindowSize('769', setIsTablet);

  const laptopStyle = {
    width: '476px',
    height: '100%',
    paddingTop: '40px',
  };

  const tabletStyle = {
    width: '100%',
    height: 'calc(100vh - 80px)',
    paddingTop: '40px',
  };

  useEffect(() => {
    (async () => {
      if (!entityId) return;
      try {
        if (entityType === ENTITY_TYPE.RETAILER) {
          await dispatch(getRetailerInfo(entityId));
        }
        if (entityType === ENTITY_TYPE.VENUE) {
          await dispatch(getVenueData(entityId));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [entityId, dispatch, entityType]);

  const closeSurveyModal = () => {
    setOpenSurveyModal(false);
  };

  const OpenSurveyModal = useCallback(
    (isOpen) => {
      setOpenSurveyModal(isOpen);
      (async function getSurveyForms() {
        try {
          setIsFormLoad(true);
          await dispatch(getSurveyFormsData());
        } catch (error) {
          console.log(error);
        } finally {
          setIsFormLoad(false);
        }
      })();
    },
    [dispatch],
  );

  const handleClickZoom = useCallback(
    (type) => {
      // TODO: For backend: rename geom -> latlong
      const latLong = entityInfo?.latlong || entityInfo?.geom;
      setButtonObj({ ...latLong, type });
    },
    [entityInfo?.latlong, entityInfo?.geom, setButtonObj],
  );

  const handleCloseModal = () => {
    onClose((prev) => !prev);
  };

  const isVenue = entity?.type === 'Venue';

  const zoomType = isVenue ? 'venueZoom' : 'retailerZoom';

  const roleRuleSet = {
    secGeogs: [AGENCY_MANAGER, CAMPAIGN_MANAGER, ACCOUNT_MANAGER, DATA_COLLECTOR],
    all: [MULTI_ACCOUNT_MANAGER],
  };

  const canAddVisit = HasAccess(PERMISSIONS.ALLOW_ANY_VISIT, entity.relationships, roleRuleSet);

  const BUTTONS = useMemo(() => {
    const baseButtons = [
      {
        variant: BUTTON_TYPES.DARK,
        onClick: () => handleClickZoom('streetView'),
        text: 'Street view',
        id: 'streetViewIdButton',
        type: 'button',
      },
      {
        variant: BUTTON_TYPES.DARK,
        onClick: () => handleClickZoom(zoomType),
        text: isVenue ? 'Zoom to venue' : 'Zoom to retailer',
        id: 'zoomToRetailerButton',
        type: 'button',
      },
    ];
    const surveyButton = {
      variant: BUTTON_TYPES.DANGER,
      onClick: OpenSurveyModal,
      text: 'Survey this store',
      id: 'zoomToRetailerButton',
      type: 'button',
    };
    if (allowVisits && canAddVisit) return [...baseButtons, surveyButton];
    return baseButtons;
  }, [OpenSurveyModal, handleClickZoom, isVenue, zoomType, entity]);

  if (isLoading) return <Loader />;

  return (
    <Styled.Wrapper>
      <Styled.CloseButton variant="" aria-label="Close" onClick={handleCloseModal} />
      <Scrollbars style={!isTablet ? tabletStyle : laptopStyle}>
        <Styled.WrapperHeaders>
          {entityId && entityInfo?.name && (
            <MapPopupHeader
              entityId={entityId}
              name={entityInfo?.name}
              address={entityInfo?.address}
              city={entityInfo?.city}
              zipcode={entityInfo?.zipcode}
              entityType={entityType}
            />
          )}
          <Styled.FlexDiv>
            <ButtonOrganizer buttons={BUTTONS} />
          </Styled.FlexDiv>
        </Styled.WrapperHeaders>
        {allowVisits && (
          <AccessControl permission={PERMISSIONS.VIEW_VISIT}>
            <WrapperTables isPopup>
              <Styled.SecondTable>
                <Styled.TableTitle>Visit information</Styled.TableTitle>
                <VisitTable data={retailerVisits || []} />
              </Styled.SecondTable>
            </WrapperTables>
          </AccessControl>
        )}
      </Scrollbars>
      {isOpenSurveyModal && (
        <SurveyModal onClose={closeSurveyModal} open={isOpenSurveyModal && !isFormLoad} retailerId={entityId} />
      )}
    </Styled.Wrapper>
  );
};

MapPopup.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
  setButtonObj: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  allowVisits: PropTypes.bool,
};

MapPopup.defaultProps = {
  setButtonObj: {},
  allowVisits: false,
};

export default withRouter(MapPopup);
