import React from 'react';
import PropTypes from 'prop-types';
import { FaFilePdf, FaFileAlt, FaFileWord } from 'react-icons/fa';
import { getFileInfo } from 'utils/custom';
import * as Styled from './styles';

const FILE_ICONS = {
  pdf: <FaFilePdf size={100} color="red" aria-label="PDF file" role="img" />,
  txt: <FaFileAlt size={100} color="9BABB8" aria-label="Text file" role="img" />,
  doc: <FaFileWord size={100} color="1D5B79" aria-label="Word document file" role="img" />,
  docx: <FaFileWord size={100} color="1D5B79" aria-label="Word document file" role="img" />,
};

const FileIconLink = ({ value }) => {
  const { name, path, disabled } = getFileInfo(value);

  if (!name || !path) return null;

  const handleClick = (e, url) => {
    e.preventDefault();
    if (disabled) return;
    window.open(url, '_blank');
  };

  const handleKeyPress = ({ key }) => {
    if (disabled) return;
    if (key === 'Enter') {
      window.open(path, '_blank');
    }
  };

  return (
    <div>
      <Styled.Link
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => handleClick(e, path)}
        onKeyDown={handleKeyPress}
        disabled={disabled}
        tabIndex="0"
        alt={`${name} link`}
      >
        {FILE_ICONS[name.split('.').pop()]}
      </Styled.Link>
      <p>{name}</p>
    </div>
  );
};

FileIconLink.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
};

FileIconLink.defaultProps = {
  value: [],
};

export default FileIconLink;
