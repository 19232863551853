/* eslint-disable no-unused-vars */
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { CHIPS_TYPES, DROPDOWN_DATA_TYPES } from 'modules/Modals/old/FilterModal/components/ChipsList/constants';
import LOCATION_CHIPS from 'modules/Modals/old/FilterModal/common/Location/chips';

const filterMapping = {
  [FILTER_FIELDS.CREATION_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Venue added to database start date:',
      end: 'Venue added to database end date:',
    },
  },
  [FILTER_FIELDS.UPDATE_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Venue last updated start date:',
      end: 'Venue last updated end date:',
    },
  },
  [FILTER_FIELDS.VENUES_NEAR_RETAILER]: {
    chipsType: CHIPS_TYPES.RADIODROPDOWN,
  },
  [FILTER_FIELDS.ALL_SCHOOLS]: {
    chipsType: CHIPS_TYPES.RADIODROPDOWN,
  },
  [FILTER_FIELDS.QUICK_VENUES_TYPE]: {
    chipsType: CHIPS_TYPES.RADIODROPDOWN,
  },
  ...LOCATION_CHIPS,
  [FILTER_FIELDS.VENUES_TYPE]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.VENUE,
    },
    title: 'Venue',
  },
};

export default filterMapping;
