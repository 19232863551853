export const getDashboardInfoSections = (profile = []) => {
  const dashboardInfoSections = [];
  for (let i = 0; i < profile?.length; i += 3) {
    const infoSections = profile
      .slice(i, i + 3)
      .reduce((acc, [key, value]) => ({ ...acc, [key.split('_').pop().toLowerCase()]: value }), {});
    dashboardInfoSections.push(infoSections);
  }
  return dashboardInfoSections;
};
