import React from 'react';
import { Redirect } from 'react-router-dom';
import { URLS } from '_constants';
import { useSelector } from 'react-redux';
import { AuthEntity } from '_entities';

const { getAuthenticatedUser } = AuthEntity.selectors;

function Home() {
  const isAuthenticated = useSelector(getAuthenticatedUser);
  return <Redirect to={isAuthenticated ? URLS.dashboard : URLS.login} />;
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
