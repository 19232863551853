import { baseUrl } from 'lib/baseUrl';

export const MAP_BBOX_USA = [
  [24.396, -124.848],
  [49.384, -66.885],
];
export const MAP_MINZOOM = 4;
export const MAP_MAXZOOM = 18;

export const MAP_BASEMAPS = [
  {
    type: 'xyz',
    label: 'Map',
    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
    attrib:
      'Map tiles by <a target="_blank" href="http://www.mapbox.com">MapBox</a>.<br />Data &copy; <a target="_blank" href="http://openstreetmap.org/copyright" target="_blank">OpenStreetMap contributings</a>',
  },
  {
    type: 'xyz',
    label: 'Photo',
    url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    attrib:
      'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  },
];

export const APIURL_ACCOUNT_BBOX = `${baseUrl}mapping/list/boundingbox/`;
export const APIURL_WMS = `${baseUrl}mapping/mapserver/`;
export const APIURL_SNAPMAPDATA = `${baseUrl}mapping/snapshot/`; // add "layerslug/" to get full URL
export const APIURL_DEMOGRAPHICS = `${baseUrl}mapping/list/demographics/`;
export const APIURL_CALCULABLEPOLYGONS = `${baseUrl}mapping/list/calcpoly/`;
