/* eslint-disable no-prototype-builtins */
import React, { useMemo } from 'react';
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import { CollapsedTab } from 'modules/Modals/old/FilterModal/components/CollapsedTab';
import DatePickerGroup from 'modules/Modals/old/FilterModal/components/DatePickerGroup';
import { IsMassAccount } from 'modules';
import * as Styled from './styles';

function VisitCharacteristics() {
  const isMassAccount = IsMassAccount();
  const VISIT_CHARACTERISTICS_TABS_BASE = [
    {
      type: 'multiple',
      title: 'Inspection outcome',
      id: 1,
      collapsed: [
        {
          id: 2,
          field: FILTER_FIELDS.VIOLATION,
          title: 'Violation',
          options: FILTER_OPTIONS.VISIT_VIOLATION_OPTIONS,
        },
        {
          id: 3,
          title: 'Citation',
          collapsed: [
            {
              field: FILTER_FIELDS.CITATION_CLERK,
              options: FILTER_OPTIONS.CITATION_CLERK_OPTIONS,
              id: 4,
            },
            {
              field: FILTER_FIELDS.CITATION_RETAILER,
              options: FILTER_OPTIONS.CITATION_RETAILER_OPTIONS,
              id: 5,
            },
          ],
        },
        {
          id: 6,
          field: FILTER_FIELDS.SALE_TO_MINOR,
          title: 'Youth / Minor',
          options: FILTER_OPTIONS.VISIT_SALE_TO_MINOR_OPTIONS,
        },
        {
          id: 7,
          field: FILTER_FIELDS.FDA_DECISION,
          title: 'FDA',
          options: FILTER_OPTIONS.VISIT_FDA_OPTIONS,
        },
      ],
    },
    {
      type: 'double',
      title: 'Age verification',
      id: 8,
      collapsed: [
        {
          id: 9,
          field: FILTER_FIELDS.ID_BASED,
          options: FILTER_OPTIONS.ID_BASED_OPTIONS,
        },
        {
          id: 10,
          field: FILTER_FIELDS.ASK_AGE,
          options: FILTER_OPTIONS.ASK_AGE_OPTIONS,
        },
        {
          id: 11,
          field: FILTER_FIELDS.ASKED_FOR_ID,
          options: FILTER_OPTIONS.ASKED_FOR_ID_OPTIONS,
        },
        {
          id: 12,
          field: FILTER_FIELDS.PRESENTED_ID,
          options: FILTER_OPTIONS.PRESENTED_ID_OPTIONS,
        },
        {
          id: 13,
          field: FILTER_FIELDS.EXAMINED_ID,
          options: FILTER_OPTIONS.EXAMINED_ID_OPTIONS,
        },
        {
          id: 14,
          field: FILTER_FIELDS.AGE_VERIFY_EQUIP,
          options: FILTER_OPTIONS.AGE_VERIFY_EQUIP_OPTIONS,
        },
        {
          id: 15,
          field: FILTER_FIELDS.AGE_VERIFY_USED,
          options: FILTER_OPTIONS.AGE_VERIFY_USED_OPTIONS,
        },
      ],
    },
    {
      type: 'multiple',
      title: 'Other visit details',
      id: 16,
      collapsed: [
        {
          id: 17,
          field: FILTER_FIELDS.TOBACCO_PROD,
          title: 'Product',
          options: FILTER_OPTIONS.TOBACCO_PROD_OPTIONS,
        },
        {
          id: 18,
          title: 'Sale',
          collapsed: [
            {
              field: FILTER_FIELDS.TOBACCO_MARKETED,
              options: FILTER_OPTIONS.TOBACCO_MARKETED_OPTIONS,
              id: 19,
            },
            {
              field: FILTER_FIELDS.RECEIPT,
              options: FILTER_OPTIONS.RECEIPT_OPTIONS,
              id: 20,
            },
          ],
        },
      ],
    },
  ];

  const VISIT_CHARACTERISTICS_TABS = useMemo(() => {
    if (isMassAccount) {
      const massAccountFilters = [
        {
          id: 21,
          field: FILTER_FIELDS.VIOLATION_TYPE,
          title: 'Violation type',
          options: FILTER_OPTIONS.VISIT_VIOLATION_TYPE_OPTIONS,
          type: 'single',
        },
        {
          id: 22,
          field: FILTER_FIELDS.ACTION_TYPE,
          title: 'Action type',
          options: FILTER_OPTIONS.VISIT_ACTION_TYPE_OPTIONS,
          type: 'single',
        },
        {
          id: 23,
          field: FILTER_FIELDS.VIOLATION_OPEN_STATUS,
          title: 'Violation case status',
          options: FILTER_OPTIONS.VIOLATION_OPEN_STATUS_OPTIONS,
          type: 'single',
        },
        {
          id: 24,
          field: FILTER_FIELDS.VIOLATION_CLOSED_DATE,
          title: 'Violation case closed date',
          type: 'calendars',
        },
      ];
      return VISIT_CHARACTERISTICS_TABS_BASE.concat(massAccountFilters);
    }
    return VISIT_CHARACTERISTICS_TABS_BASE;
  }, [isMassAccount]);

  const getElement = (item) => {
    switch (item.type) {
      case 'single':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            <CheckedList name={item.field} options={item.options} />
          </CollapsedTab>
        );
      case 'multiple':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            {item.collapsed.map((elem) => (
              <Styled.FiltersContainer>
                <Styled.FiltersTitle>{elem.title}</Styled.FiltersTitle>
                {elem.collapsed ? (
                  elem.collapsed.map((el) => <CheckedList name={el.field} options={el.options} />)
                ) : (
                  <CheckedList name={elem.field} options={elem.options} />
                )}
              </Styled.FiltersContainer>
            ))}
          </CollapsedTab>
        );
      case 'double':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            {item.collapsed.map((elem) => (
              <CheckedList name={elem.field} options={elem.options} />
            ))}
          </CollapsedTab>
        );
      case 'calendars':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            <DatePickerGroup name={item.field} title="" />
          </CollapsedTab>
        );
      default:
        return null;
    }
  };

  return <Styled.Wrapper>{VISIT_CHARACTERISTICS_TABS.map((item) => getElement(item))}</Styled.Wrapper>;
}

export default VisitCharacteristics;
