import { isNull } from 'lodash';
import { formIsValid } from 'utils/validation';
import { validateText, validateZipCode } from 'utils/validation/fields';
import { FORM_ACTION_TYPES, FORM_REQUIRED_FIELDS, formInitialState, formNames, venuesTypes } from './constants';

export const init = (initialState) => {
  return { ...initialState };
};

export const validateData = (action) => {
  switch (action.name) {
    case formNames.zipCode:
      return validateZipCode({
        value: action.payload.trim(),
        name: action.name,
        required: true,
        max: 10,
        min: 5,
      });

    case formNames.type:
    case formNames.city:
      return validateText({
        value: action.payload.value?.trim(),
        name: action.name,
        required: true,
        max: 50,
      });

    default:
      return validateText({
        value: isNull(action.payload.value) ? null : action.payload.trim(),
        name: action.name,
        required: FORM_REQUIRED_FIELDS.includes(action.name),
        max: 50,
      });
  }
};

const checkFormIsValid = (data, errors) => formIsValid(errors, FORM_REQUIRED_FIELDS);

const validateFormData = (data) =>
  FORM_REQUIRED_FIELDS.reduce((acc, name) => {
    acc[name] = validateData({
      payload: data.attributes[name],
      name,
    });
    return acc;
  }, {});

export const formReducer = (formState, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.ENTER_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...formState.data.attributes,
          [action.name]: action.payload,
        },
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: false,
      };

      const shadowErrors = validateFormData(newData);

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newData, shadowErrors),
      };
    }

    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const { data } = formState;

      const newErrors = validateFormData(data);

      return {
        ...formState,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(formState.data, newErrors),
      };
    }

    case FORM_ACTION_TYPES.RESET_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...action.payload,
        },
      };

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: false,
      };
    }

    case FORM_ACTION_TYPES.SET_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...action.payload,
        },
      };

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: true,
      };
    }

    default:
      return {
        ...formInitialState,
      };
  }
};

export const setFormForEdit = (data) => {
  return Object.values(formNames).reduce((acc, item) => {
    switch (item) {
      case formNames.city: {
        acc[item] = {
          value: data[item] || null,
          label: data[item] || 'Select an option',
        };
        return acc;
      }

      case formNames.type: {
        const checkedOption = venuesTypes.find((typeItem) => typeItem.value === data[formNames.type]);
        acc[item] = checkedOption || {
          value: data[item] || null,
          label: data[item] || 'Select an option',
        };
        return acc;
      }

      case formNames.smokeIndoor:
      case formNames.tobaccoIndoor:
      case formNames.vapeIndoor:
      case formNames.smokeGrounds:
      case formNames.tobaccoGrounds:
      case formNames.vapeGrounds: {
        acc[item] = data[item];
        return acc;
      }

      default: {
        acc[item] = data[item] || '';
        return acc;
      }
    }
  }, {});
};
