import styled from 'styled-components';

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
export const ListItem = styled.li`
  &:not(:first-child) {
    margin: 15px 0 0;
  }
`;

export const Wrapper = styled.div``;
export const Label = styled.label``;
