/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isNull } from 'lodash';
import { NotificationEntity } from '_entities';
import PropTypes from 'prop-types';
import DetailsNotificationModal from './DetailsNotificationModal';
import * as Styled from './styles';

const { updateNotificationList } = NotificationEntity.actions;

function NotificationModal({ onClose, notificationList, loadNotifications }) {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [detailModalInfo, setDetailModalInfo] = useState({});
  const dispatch = useDispatch();

  const openModalHandler = async (notification) => {
    const { id, type } = notification;

    const data = { data: { type, id, attributes: { read_date: new Date() } } };

    if (isNull(notification?.attributes?.read_date)) {
      await dispatch(updateNotificationList(id, data));
      loadNotifications();
    }
    setIsDetailModalOpen(() => true);
    setDetailModalInfo(() => notification);
  };

  const closeModalHandler = () => {
    setIsDetailModalOpen(() => false);
    setDetailModalInfo(() => {});
  };

  return (
    <Styled.ModalWrapper>
      <Styled.CancelIconWrapper onClick={onClose} aria-hidden="true" />
      <Styled.ModalTitle>Notifications</Styled.ModalTitle>
      <Styled.List>
        {notificationList.map((notification) => {
          const { attributes } = notification;
          return (
            <Styled.ListItem key={attributes?.notification_id} onClick={() => openModalHandler(notification)}>
              <Styled.ReadCircle hasReadCircle={isNull(attributes?.read_date)} />
              <Styled.ItemText>{attributes?.title}</Styled.ItemText>
            </Styled.ListItem>
          );
        })}
      </Styled.List>
      {isDetailModalOpen && (
        <DetailsNotificationModal
          isOpen={isDetailModalOpen}
          onClose={closeModalHandler}
          notification={detailModalInfo}
        />
      )}
    </Styled.ModalWrapper>
  );
}

NotificationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  notificationList: PropTypes.array,
  loadNotifications: PropTypes.func,
};

NotificationModal.defaultProps = {
  notificationList: [],
  loadNotifications: () => {},
};

export default NotificationModal;
