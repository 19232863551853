export default {
  INSPECTION_TYPE: 'Inspection Type',
  KEY_INSPECTION_TYPE: 'inspectionType',

  PRODUCT_TYPE: 'Product Type',
  KEY_PRODUCT_TYPE: 'productType',

  DISPOSITION: 'Disposition',
  KEY_DISPOSITION: 'disposition',

  PENALTY: 'Penalty',
  KEY_PENALTY: 'penalty',

  AGENCY: 'Agency',
  KEY_AGENCY: 'agency',

  ATTENTION: 'Attention',

  KYE_EDIT_FREE_FIELDS: 'edit_free_fields',
  KEY_INSPECTION_DATE: 'inspectionDate',
  KEY_SUBMITTED_DATE: 'submittedDate',

  RETAILER: 'Retailer',
  USER: 'User',
  VISIT: 'Visit',
  KEY_TIME: 'time',
  START_DATE_SUSPENSION: 'startDateSuspension',
  BLANK: 'Blank',

  ERROR_IS_REQUIRED: 'Field is required',
  ERROR_ZIP_CODE: 'Please enter a valid zip code',

  ID_HEADER: 'idHeader',
  DEFAULT_VALUE: 'Select an option',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  OUT: 'out',
  OUT_CITY: 'outCity',
  GEO: 'geo',
  ID_SURVEY: 'surveyFormId',
  ID_AGENCY: 'agencyAddEnforcementId',
  DUPLICATE: 'duplicate',
  ERROR_RESET: 'errorReset',
  RESET: 'reset',

  CITY: 'city',
  ADDRESS: 'address',
  NAME: 'name',
};
