import * as TYPES from './Auth.types';

export const setToken = (data) => ({
  type: TYPES.SET_TOKEN,
  payload: data,
});

export const startWithoutToken = () => ({
  type: TYPES.START_WITHOUT_TOKEN,
});

export const signIn = (credentials) => ({
  types: [TYPES.SIGN_IN, TYPES.SIGN_IN_SUCCESS, TYPES.SIGN_IN_FAIL],
  promise: (client) => client.post('token-auth/', credentials),
});

export const signOut = () => ({
  types: [TYPES.SIGN_OUT, TYPES.SIGN_OUT_SUCCESS, TYPES.SIGN_OUT_FAIL],
  promise: (client) => client.delete('token-auth/'),
});

export const getProfileData = () => ({
  types: [TYPES.GET_PROFILE_DATA, TYPES.GET_PROFILE_DATA_SUCCESS, TYPES.GET_PROFILE_DATA_FAIL],
  promise: (client) => client.get('profile?include=role,permissions,agencies,sec_geogs'),
});

export const forgotPassword = (email) => ({
  types: [TYPES.FORGOT_PASSWORD, TYPES.FORGOT_PASSWORD_SUCCESS, TYPES.GET_PROFILE_DATA_FAIL],
  promise: (client) => client.post('password/reset/', email),
});

export const resetPassword = (passwords) => ({
  types: [TYPES.SET_NEW_PASSWORD, TYPES.SET_NEW_PASSWORD_SUCCESS, TYPES.SET_NEW_PASSWORD_FAIL],
  promise: (client) => client.post('password/reset/verified/', passwords),
});

export const isValidLink = (code) => ({
  types: [TYPES.IS_VALID_LINK, TYPES.IS_VALID_LINK_SUCCESS, TYPES.IS_VALID_LINK_FAIL],
  promise: (client) => client.get(`password/reset/verify/?code=${code}`),
});

export const getModularityPermissions = () => ({
  types: [
    TYPES.GET_MODULARITY_PERMISSION,
    TYPES.GET_MODULARITY_PERMISSION_SUCCESS,
    TYPES.GET_MODULARITY_PERMISSION_FAIL,
  ],
  promise: (client) => client.get(`permissions/modularity_permissions`),
});
