import React from 'react';
import { PageContainer, Card, AccessControl } from 'modules';
import { baseUrl } from 'lib/baseUrl';
import { store } from 'lib';
import { history } from 'lib/ConfigureStore';
import { URLS, PERMISSIONS } from '_constants';
import { SNAPSHOT_NAVIGATION_DATA } from './constants';
import * as Styled from './styles';

const APIURL_SNAPSHOT_LISTING = `${baseUrl}mapping/snapshot/`;

class Snapshot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snapsForListing: [],
      permissions: store.getState().auth.permissions?.concat(store.getState().auth.modularityPermissions),
    };
  }

  componentDidMount() {
    this.reloadListing();
  }

  reloadListing() {  // eslint-disable-line
    const request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        const accountsnaps = JSON.parse(request.responseText).snapshots;
        const availablemaps = SNAPSHOT_NAVIGATION_DATA.filter((mapinfo) => {
          return accountsnaps.indexOf(mapinfo.slug) !== -1;
        });
        const { permissions } = this.state;
        const permittedAvailablemaps = availablemaps.filter((elem) => {
          return permissions.includes(elem.permission);
        });
        this.setSnapMapListing(permittedAvailablemaps);
      }
      if (request.readyState === 4 && request.status === 403) {
        history.push(URLS.dashboard);
      }
    };
    request.open('GET', APIURL_SNAPSHOT_LISTING);
    request.send();
  }

  setSnapMapListing(newlist) {
    this.setState({
      snapsForListing: newlist,
    });
  }

  render() {
    const { snapsForListing } = this.state;

    return (
      <PageContainer>
        <Styled.Wrapper>
          <AccessControl permission={PERMISSIONS.VIEW_SNAPSHOT_MAPS}>
            <Styled.Title>Snapshot Maps</Styled.Title>
            <Styled.CardList>
              {snapsForListing.map((item) => (
                <Styled.CardListItem key={item.id}>
                  <Card {...item} />
                </Styled.CardListItem>
              ))}
            </Styled.CardList>
          </AccessControl>
        </Styled.Wrapper>
      </PageContainer>
    );
  }
}

export default Snapshot;
