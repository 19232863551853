import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ARROW_RIGHT } from 'assets/icons';
import * as Styled from './styles';

export const CollapsedTab = ({ children, title }) => {
  const [isShowContent, setIsShowContent] = useState(false);

  const toggleContent = () => setIsShowContent((prev) => !prev);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setIsShowContent((prev) => !prev);
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Tab onClick={toggleContent} onKeyDown={handleKeyDown} isActive={isShowContent} tabIndex={0}>
        <img alt="arrow-right" src={ARROW_RIGHT} />
        {title}
      </Styled.Tab>
      {isShowContent && <Styled.Content>{children}</Styled.Content>}
    </Styled.Wrapper>
  );
};

CollapsedTab.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

CollapsedTab.defaultProps = {
  children: null,
};
