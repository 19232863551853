import styled from 'styled-components';
import { DEVICES } from '_constants';
import { Section } from 'ui-kit/Section/styles';

export const MainContainer = styled.div`
  padding: 30px 25px 40px;

  .rt-td:last-of-type {
    overflow: visible;
  }
`;

export const DataCollectorsDropContainer = styled.div`
  display: flex;
  align-items: center;
  label {
    max-width: 400px;
    min-width: 200px;
  }
  button {
    margin: 20px 8px 0;
    min-width: 95px;
  }
`;

export const TopLine = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleWrapper = styled.div`
  > a {
    margin-bottom: 15px;
  }
`;

export const FilterWrapper = styled.div`
  margin: 0 0 15px;
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 22px;
`;

export const H2title = styled.h2`
  font-weight: 100;

  span {
    font-weight: 500;
  }
`;

export const H3title = styled.h3`
  font-weight: 300;
  padding-top: 5px;
`;

export const OddInfoWrapper = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
`;

export const Info = styled.p`
  font-size: 14px;
  color: var(--dim-grey);
`;

export const ButtonWrapper = styled.div`
  > button {
    min-width: 135px;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid var(--grey);
  font-weight: normal;

  @media ${DEVICES.tablet} {
    display: block;
    border: none;
  }

  ${Section} {
    border: none;
    flex: 1 0;

    :first-of-type {
      @media ${DEVICES.tablet} {
        margin-bottom: 25px;
        border: 1px solid var(--grey);
      }
    }

    :nth-child(2) {
      border-left: 1px solid var(--grey);
      border-right: 1px solid var(--grey);

      @media ${DEVICES.tablet} {
        margin-bottom: 25px;
        border: 1px solid var(--grey);
      }
    }

    :last-of-type {
      @media ${DEVICES.tablet} {
        border: 1px solid var(--grey);
      }
    }
  }
`;

export const WrapperSelect = styled.div`
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 25px;
`;

export const Location = styled.div``;

export const LocationLabel = styled.span`
  display: block;
  font-size: 16px;
  color: var(--dim-grey);
  margin-bottom: 8px;
`;

export const LocationText = styled.p`
  font-size: 18px;
  color: var(--dark);
  min-height: 18px;
`;

export const MapWrapper = styled.div`
  padding: 0;
  margin: 0 0 20px;
  width: 100%;
  min-height: 260px;

  @media ${DEVICES.tablet} {
    height: 210px;
  }
`;

export const GisInfoWrapper = styled.div`
  padding: 0 15px;
`;

export const GisInfo = styled.p`
  padding: 0;
  margin: 0 0 10px;
  color: var(--dim-grey);
`;

export const EditWrapper = styled.div`
  padding: 15px;

  > button {
    min-width: 135px;
  }
`;

export const EditDescription = styled.p`
  margin: 0 0 20px;
  color: var(--dark);
`;

export const UpdateLocationButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;

  > button {
    min-width: 135px;

    :not(:last-of-type) {
      margin-right: 15px;
    }
  }
`;

export const Counter = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const RetailerCountContainer = styled.div`
  width: calc(100% + 40px);
  border-bottom: ${({ isBorder }) => isBorder && `1px solid var(--grey)`};
  padding-left: 20px;
  margin: 0 0 20px -20px;
`;

export const RetailerAdditionalInfo = styled.div`
  display: ${({ isShow }) => (isShow ? `none` : `block`)};
  width: 100%;
`;

export const InputSubText = styled.div`
  margin-top: ${({ isError }) => (isError ? `20px` : `0`)};
`;

export const TableContainer = styled.div``;

export const Dl = styled.dl`
  margin: 0;
  padding: 0;
`;

export const Dt = styled.dt`
  margin: 0 0 10px;
  padding: 0;
  font-size: 16px;
  line-height: 1.17;
  color: var(--dim-grey);
`;

export const Dd = styled.dd`
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark);

  :not(:last-of-type) {
    margin-bottom: 25px;
  }

  a {
    color: var(--curious-blue);
  }

  img {
    display: block;
    width: auto;
    max-height: 206px;
    height: 100%;
    object-fit: contain;
  }
`;

export const DropInTableContainer = styled.div`
  width: 120px;
  margin-top: 5px;
  padding: 4px;
`;

export const ManageDataCollectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin: 4px 0 0 6px;
  }
`;

export const IconContainer = styled.div`
  border-radius: 20px;
  height: 22px;
  background-color: var(--lite-grey);
  width: 23px;
  margin-left: 10px;
  cursor: pointer;
`;

export const TableHeaderContainer = styled.div`
  padding: 10px 0 0 20px;
`;

export const EmptyDataCollectors = styled.div``;
