import styled from 'styled-components';

export const Inner = styled.div`
  height: 100%;
  padding: 40px 16px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
`;

export const LeftCol = styled.div`
  font-size: 200px;
  margin-right: 30px;
`;

export const RightCol = styled.div``;

export const Title = styled.h1`
  font-size: 70px;
  font-weight: 500;
`;

export const P = styled.p`
  font-size: 25px;
`;

export const Message = styled.p`
  font-size: 20px;
  text-align: center;
`;
