import React from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { ONLINE, OFFLINE } from 'assets/icons';
import PropTypes from 'prop-types';
import * as Styled from './styles';

function OnlineIndicator() {
  const isOnline = useOnlineStatus();

  const Item = ({ status }) => {
    const itemData = {
      icon: status ? ONLINE : OFFLINE,
      text: status ? 'Online' : 'Offline',
      alt: status ? 'You are online' : 'You are offline',
    };
    return (
      <Styled.Item>
        <Styled.Icon data-html2canvas-ignore src={itemData.icon} alt={itemData.alt} sizes={[12, 12]} />
        <Styled.Text data-html2canvas-ignore isOnline={status}>
          {itemData.text}
        </Styled.Text>
      </Styled.Item>
    );
  };

  Item.propTypes = {
    status: PropTypes.bool.isRequired,
  };

  return (
    <Styled.Wrapper>
      <Item status={isOnline} />
    </Styled.Wrapper>
  );
}

export default OnlineIndicator;
