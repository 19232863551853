import React from 'react';

const FilterIcon = () => (
  <svg
    alt="filter icon"
    focusable="false"
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16667 10H8.83333C9.29167 10 9.66667 9.625 9.66667 9.16667C9.66667 8.70833 9.29167 8.33333 8.83333 8.33333H7.16667C6.70833 8.33333 6.33333 8.70833 6.33333 9.16667C6.33333 9.625 6.70833 10 7.16667 10ZM0.5 0.833333C0.5 1.29167 0.875 1.66667 1.33333 1.66667H14.6667C15.125 1.66667 15.5 1.29167 15.5 0.833333C15.5 0.375 15.125 0 14.6667 0H1.33333C0.875 0 0.5 0.375 0.5 0.833333ZM3.83333 5.83333H12.1667C12.625 5.83333 13 5.45833 13 5C13 4.54167 12.625 4.16667 12.1667 4.16667H3.83333C3.375 4.16667 3 4.54167 3 5C3 5.45833 3.375 5.83333 3.83333 5.83333Z"
      fill="#3A3E43"
    />
  </svg>
);

export default FilterIcon;
