import React, { useState, useCallback } from 'react';
import { isEmpty, reduce } from 'lodash';
import { isValidDate } from 'utils/old/checkSearchDate';
import { useDispatch } from 'react-redux';
import { SetQueryParams } from 'utils/old/setQueryParams';
import queryString from 'query-string';
import processQSParams from 'utils/old/processQSParams';
import ExportDataDropDown from 'ui-kit/old/ExportDataDropDown';
import { CollectEntity } from '_entities';
import { Button, Loader, RadioButtonGroup } from 'ui-kit';
import { BUTTON_TYPES, URLS, PERMISSIONS, MESSAGES, PAGE_TYPES } from '_constants';
import { EXPORT_DATA_OPTIONS_NAMES, EXPORT_CAMPAING_LIST_DATA_OPTIONS } from '_constants/old/_constants/EXPORT_DATA';
import { newSavePageToPdf } from 'utils/custom';
import { CollectTable, AccessControl, PageContainer } from 'modules';
import Filter from 'ui-kit/old/filter';
import { notification } from 'utils/services';
import { useHistory, useLocation } from 'react-router-dom';
import getCampaignColumns from 'pages/Assignments/List/constants/Campaigns/getCampaignColumns';
import { CAMPAIGN_LIST_TABLE_VIEW_MODE, CAMPAIGN_LIST_VIEW_MODE_VALUE, ACTION_BUTTON_TEXT_OPTIONS } from './constants';

import {
  INITIAL_COLUMNS as campaignInitialColumns,
  COLUMNS as campaignColumns,
  sortOptions,
} from '../../constants/Campaigns/constants';
import * as Styled from './styles';

const { getCampaigns } = CollectEntity.selectors;
const { getCampaignList, getCampaignListCSV, archiveCampaigns, restoreCampaigns } = CollectEntity.actions;

function CampaignListTable() {
  const [loading, setLoading] = useState(false);
  const [exportValue, setExportValue] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { page, page_size: perPage, search: querySearch } = queryString.parse(location.search);
  const [campaignListViewMode, setCampaignListViewMode] = useState('active');
  const [searchValue, setSearchValue] = useState(querySearch || '');
  const [checkboxList, setCheckboxList] = useState({});
  const openAddCampaignPage = () => history.push(URLS.campaignPages.add);

  const handleExportDataChange = async ({ value }) => {
    setExportValue(value);

    try {
      switch (value) {
        case EXPORT_DATA_OPTIONS_NAMES.CAMPAIGN_LIST:
          await dispatch(getCampaignListCSV(location.search));
          notification.success(MESSAGES.COLLECT.SUCCESS);
          break;
        case EXPORT_DATA_OPTIONS_NAMES.PRINT:
          setLoading(true);
          // In order to show loader before html2canvas function start working we should wrap function in setTimeout
          setTimeout(() => newSavePageToPdf({ setExportLoading: setLoading, hasFilters: true }, PAGE_TYPES.TABLE), 0);
          break;
        default:
      }
    } catch (err) {
      notification.error(MESSAGES.VISIT.VIOLATION_PDF.ERROR);
      console.log(err);
    }
  };

  const loadData = useCallback(
    async ({ search = searchValue, startPage, campaignListViewMode: viewMode } = {}) => {
      SetQueryParams({
        queryString: {
          page: startPage || page,
          page_size: perPage || 20,
          search: search || null,
          not_archived: CAMPAIGN_LIST_VIEW_MODE_VALUE[viewMode],
        },
        history,
      });
      setLoading(true);
      try {
        const params = processQSParams({
          page: startPage || page,
          perPage,
          search: search || null,
          sortBy: sortOptions.sortBy,
          sortOrder: sortOptions.sortOrder,
          not_archived: CAMPAIGN_LIST_VIEW_MODE_VALUE[viewMode],
        });
        await dispatch(getCampaignList(params));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [searchValue, campaignListViewMode, perPage],
  );

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    loadData({ search: searchString, startPage: 1, campaignListViewMode });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      loadData({ search: searchString, startPage: 1, campaignListViewMode });
    }
  };

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    loadData({ search: '', startPage: 1, campaignListViewMode });
    setSearchValue('');
  };

  const handlecampaignListViewMode = ({ target: { value } }) => {
    setCampaignListViewMode(value);
    const searchString = isValidDate(searchValue);
    loadData({ search: searchString, startPage: 1, campaignListViewMode: value });
  };

  const handleArchiveButton = async () => {
    if (isEmpty(checkboxList) || Object.values(checkboxList).every((value) => !value)) {
      notification.error(
        campaignListViewMode === 'active'
          ? MESSAGES.CAMPAIGN.ARCHIVE_SELECTED.FAIL
          : MESSAGES.CAMPAIGN.UNARCHIVE_SELECTED.FAIL,
      );
      return;
    }
    const idsList = reduce(
      checkboxList,
      (acc, value, key) => {
        if (value) return [...acc, key];
        return acc;
      },
      [],
    );

    try {
      if (campaignListViewMode === 'active') {
        const res = await dispatch(archiveCampaigns({ ids: idsList }));
        loadData({ startPage: 1, campaignListViewMode });
        notification.success(res.data.message);
      } else {
        const res = await dispatch(restoreCampaigns({ ids: idsList }));
        notification.success(res.data.message);
        loadData({ startPage: 1, campaignListViewMode });
      }
    } catch (err) {
      err?.response.data.errors.map((item) => notification.error(item.detail));
    } finally {
      setCheckboxList({});
    }
  };

  return (
    <PageContainer>
      {loading && <Loader />}
      <Styled.Wrapper>
        <Styled.Header className="table-header">
          <Styled.Title>Campaign list</Styled.Title>
          <Styled.HeaderWrapper>
            <ExportDataDropDown
              options={EXPORT_CAMPAING_LIST_DATA_OPTIONS}
              onChange={handleExportDataChange}
              value={exportValue}
              data-html2canvas-ignore
            />
            <Styled.ButtonWrapper>
              <AccessControl permission={PERMISSIONS.ADD_CAMPAIGN}>
                <Button
                  data-html2canvas-ignore
                  onClick={openAddCampaignPage}
                  text="Add campaign"
                  variant={BUTTON_TYPES.DANGER}
                />
              </AccessControl>
            </Styled.ButtonWrapper>
          </Styled.HeaderWrapper>
        </Styled.Header>
        <Styled.FilterWrapper className="table-filters">
          <Filter
            type="null"
            placeholder="Search"
            onChangeInput={handleOnChangeInputSearch}
            handleClickInput={handleClickInput}
            onKeyDown={handleKeyDown}
            onRemoveSearch={handleRemoveSearch}
            searchValue={searchValue}
            isWidth
          />
        </Styled.FilterWrapper>
        <AccessControl permission={PERMISSIONS.CHANGE_CAMPAIGN}>
          <Styled.InputWrapper>
            <RadioButtonGroup
              value={campaignListViewMode}
              onChange={handlecampaignListViewMode}
              name="archived"
              gap="20px"
              options={CAMPAIGN_LIST_TABLE_VIEW_MODE}
            />
            {ACTION_BUTTON_TEXT_OPTIONS[campaignListViewMode] && (
              <Button
                data-html2canvas-ignore
                onClick={handleArchiveButton}
                text={ACTION_BUTTON_TEXT_OPTIONS[campaignListViewMode]}
                variant={BUTTON_TYPES.DANGER}
                style={{ marginRight: '10px', minWidth: '100px' }}
              />
            )}
          </Styled.InputWrapper>
        </AccessControl>
        <CollectTable
          setLoading={setLoading}
          loadAPICall={getCampaignList}
          selector={getCampaigns}
          getInitialColumns={getCampaignColumns}
          sortOptions={sortOptions}
          initialColumns={campaignInitialColumns}
          columns={campaignColumns}
          search={searchValue}
          startPage={page}
          tableViewMode={campaignListViewMode}
          tableVievModeButtonName={ACTION_BUTTON_TEXT_OPTIONS[campaignListViewMode]}
          checkboxList={checkboxList}
          setCheckboxList={setCheckboxList}
          isCampaignList
        />
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default CampaignListTable;
