/* Libs */
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 10px;
  height: 15px;
  margin-right: 8px;
  transform: rotate(${({ isActive, order }) => (isActive && order === 1 ? '180deg' : 0)});

  ${({ isHideSorting }) =>
    isHideSorting &&
    css`
      display: none;
    `}

  ${({ isActive }) =>
    !isActive &&
    css`
      path {
        fill: #bdbdbd;
      }
    `}
`;
