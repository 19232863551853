/* eslint-disable no-unused-vars */
import { useCallback } from 'react';

export default function useSortTable(sortOption, sortHandler) {
  const { sortBy, sortOrder } = sortOption;

  const getOrder = useCallback((order) => {
    if (order === 0) return -1;
    // if (order === 0) return 1;
    return order * -1;
  }, []);

  const handleSort = useCallback(
    (field) => (order) => {
      sortHandler(field, getOrder(order));
    },
    [getOrder, sortHandler],
  );

  return handleSort;
}
