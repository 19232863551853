/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { FilterEntity } from '_entities';
import FilterDropdown from 'modules/Modals/old/FilterModal/components/FilterDropdown';
import DatePickerGroup from 'modules/Modals/old/FilterModal/components/DatePickerGroup';
import { useDispatch, useSelector } from 'react-redux';
import { CheckedList } from 'ui-kit';
import * as Styled from './styles';

const { setShadowFilter } = FilterEntity.actions;

export const DROPDOWN_OPTIONS = [
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Enforcement',
    value: 'Enforcement',
  },
  {
    label: 'Assessment',
    value: 'Assessment',
  },
  {
    label: 'Any',
    value: 'Any',
  },
];

const DROPDOWN_DATE_OPTIONS = [
  {
    label: 'Ever',
    value: 'Ever',
  },
  {
    label: 'Date range',
    value: 'Date range',
  },
];

function HasHadVisitsFilter() {
  const {
    filter: { shadowFilters },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const HAS_HAD_VISITS = [
    {
      id: 1,
      field: FILTER_FIELDS.HAS_HAD_VISITS,
      options: FILTER_OPTIONS.HAS_HAD_VISITS,
      type: 'single',
    },
  ];

  const getDropDownId = (data) => {
    switch (data) {
      case 'Education': {
        return 3;
      }
      case 'Enforcement': {
        return 2;
      }
      case 'Assessment': {
        return 1;
      }
      default:
        return 'any';
    }
  };

  const handleChangeQueryFiltersShowDropDown = (field, { value }) => {
    const newFilters = { ...shadowFilters };
    const hasHadVisitsInDateRange = newFilters[FILTER_FIELDS.HAS_HAD_VISITS];
    let newFilterParam;
    switch (field) {
      case FILTER_FIELDS.HAS_HAD_VISITS_DROP:
        {
          const hasHadVisitsParams =
            typeof hasHadVisitsInDateRange === 'object'
              ? hasHadVisitsInDateRange[0]?.split(',')
              : hasHadVisitsInDateRange?.split(',');
          if (hasHadVisitsParams !== undefined) {
            hasHadVisitsParams[0] = getDropDownId(value);
            newFilterParam = hasHadVisitsParams.join();
          }
        }
        dispatch(
          setShadowFilter({
            ...newFilters,
            ...{ [FILTER_FIELDS.HAS_HAD_VISITS_DROP]: value },
            [FILTER_FIELDS.HAS_HAD_VISITS]: newFilterParam,
          }),
        );
        break;
      case FILTER_FIELDS.HAS_HAD_VISITS_DATE:
        {
          const hasHadVisitsDateParams =
            typeof hasHadVisitsInDateRange === 'object'
              ? hasHadVisitsInDateRange[0]?.split(',')
              : hasHadVisitsInDateRange?.split(',');
          let newHasHadVisits;
          if (value === 'Ever' && hasHadVisitsDateParams !== undefined) {
            newHasHadVisits = `${hasHadVisitsDateParams[0]},${value.toLowerCase()}`;
            dispatch(
              setShadowFilter({
                ...newFilters,
                ...{ [FILTER_FIELDS.HAS_HAD_VISITS]: newHasHadVisits },
                ...{ [FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE]: '' },
                ...{ [FILTER_FIELDS.HAS_HAD_VISITS_DATE]: value },
              }),
            );
          } else {
            dispatch(
              setShadowFilter({
                ...newFilters,
                ...{ [FILTER_FIELDS.HAS_HAD_VISITS_DATE]: value },
                [FILTER_FIELDS.HAS_HAD_VISITS]: hasHadVisitsDateParams.join(),
              }),
            );
          }
        }
        break;
      default:
        break;
    }
  };

  const handleChangeCheckbox = (value) => {
    if (value.values.length === 0) {
      dispatch(
        setShadowFilter({
          ...shadowFilters,
          ...{ [FILTER_FIELDS.HAS_HAD_VISITS_DROP]: '' },
          ...{ [FILTER_FIELDS.HAS_HAD_VISITS]: '' },
          ...{ [FILTER_FIELDS.HAS_HAD_VISITS_CHECKBOX]: '' },
          ...{ [FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE]: '' },
        }),
      );
    } else {
      dispatch(
        setShadowFilter({
          ...shadowFilters,
          ...{ [FILTER_FIELDS.HAS_HAD_VISITS]: value?.values },
          ...{ [FILTER_FIELDS.HAS_HAD_VISITS_CHECKBOX]: ['any,ever'] },
          ...{ [FILTER_FIELDS.HAS_HAD_VISITS_DROP]: 'Any' },
          ...{ [FILTER_FIELDS.HAS_HAD_VISITS_DATE]: 'Ever' },
        }),
      );
    }
  };

  const isDropActive = useMemo(() => shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_CHECKBOX], [shadowFilters]);

  const getElement = (item) => {
    switch (item.type) {
      case 'single':
        return (
          <Styled.Container>
            <CheckedList
              value={shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_CHECKBOX] || []}
              onChange={handleChangeCheckbox}
              name={item.field}
              options={item.options}
            />
            <FilterDropdown
              options={DROPDOWN_OPTIONS}
              inputId={FILTER_FIELDS.HAS_HAD_VISITS_DROP}
              zIndex={isDropActive ? 3 : -1}
              onChange={(data) => {
                handleChangeQueryFiltersShowDropDown(FILTER_FIELDS.HAS_HAD_VISITS_DROP, data);
              }}
              value={shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_DROP] || 'Any'}
            />
            <span>within time period: </span>
            <FilterDropdown
              options={DROPDOWN_DATE_OPTIONS}
              inputId={FILTER_FIELDS.HAS_HAD_VISITS_DATE}
              zIndex={isDropActive ? 3 : -1}
              onChange={(data) => {
                handleChangeQueryFiltersShowDropDown(FILTER_FIELDS.HAS_HAD_VISITS_DATE, data);
              }}
              value={shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_DATE] || 'Ever'}
            />
          </Styled.Container>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    // looking for date range changes and if it change we add this value to another filters value
    if (
      shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE] &&
      shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE] !== undefined
    ) {
      const type = getDropDownId(shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_DROP]);
      const params = `${type},${shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE]}`;
      dispatch(
        setShadowFilter({
          ...shadowFilters,
          [FILTER_FIELDS.HAS_HAD_VISITS]: params,
        }),
      );
    }
  }, [shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE]]);

  return (
    <Styled.Wrapper>
      {HAS_HAD_VISITS.map((item) => getElement(item))}
      <Styled.PickerWrapper isShow={shadowFilters[FILTER_FIELDS.HAS_HAD_VISITS_DATE] === 'Date range'}>
        <DatePickerGroup name={FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE} title="" />
      </Styled.PickerWrapper>
    </Styled.Wrapper>
  );
}

export default HasHadVisitsFilter;
