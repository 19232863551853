export default {
  RETAILERS: 'RETAILERS',
  USERS: 'USERS',
  AUDIT_LOGS: 'AUDIT_LOGS',
  ENFORCEMENT: 'ENFORCEMENT',
  LICENSES: 'LICENSES',
  ASSESSMENT: 'ASSESSMENT',
  EDUCATION: 'EDUCATION',
  VENUES: 'VENUES',
  STORE_MERGER: 'STORE_MERGER',
};
