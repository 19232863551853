/**
 * Validate if value is falsy value
 * @param  {} value
 *
 * @returns {boolean}
 */
const hasFalsyValue = (value) => {
  if (Array.isArray(value)) {
    return !value.length || !value.filter((item) => ![undefined, null, ''].includes(item)).length;
  }

  return [undefined, null, ''].includes(value);
};

export default hasFalsyValue;
