import { openDB } from 'idb';

// Will be changed to uppercase) to do
const dbName = 'post2db';
const additionalData = 'additional_data';
const assignmentsTable = 'assignments';
const profileTable = 'profile';
const readyTable = 'ready';
const retailersTable = 'retailers';
const formsTable = 'forms';
const surveysTable = 'surveys';
const version = 1;

const initiateIDB = async () => {
  const db = await openDB(dbName, version, {
    // eslint-disable-next-line no-unused-vars
    async upgrade(upgrdDB, oldVersion, newVersion, transaction) {
      await upgrdDB.createObjectStore(additionalData);
      await upgrdDB.createObjectStore(assignmentsTable);
      await upgrdDB.createObjectStore(profileTable);
      await upgrdDB.createObjectStore(readyTable);
      await upgrdDB.createObjectStore(retailersTable);
      await upgrdDB.createObjectStore(formsTable);
      await upgrdDB.createObjectStore(surveysTable);
    },
  });
  return db;
};

const deleteIDB = async () => {
  const db = await initiateIDB();
  await db.transaction(additionalData, 'readwrite').objectStore(additionalData).clear();
  await db.transaction(assignmentsTable, 'readwrite').objectStore(assignmentsTable).clear();
  await db.transaction(profileTable, 'readwrite').objectStore(profileTable).clear();
  await db.transaction(readyTable, 'readwrite').objectStore(readyTable).clear();
  await db.transaction(retailersTable, 'readwrite').objectStore(retailersTable).clear();
  await db.transaction(formsTable, 'readwrite').objectStore(formsTable).clear();
};

export default {
  initiateIDB,
  deleteIDB,
};
