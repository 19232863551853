export default {
  EDIT_STORE_TYPE: 'Edit Store type',
  EDIT_IN_BUSINESS: 'Edit In business',
  EDIT_CLOSE_DATE: 'Edit Closed date',
  EDIT_TOBACCO: 'Edit Tobacco',
  VIEW_VIOLATION: 'Enforcement activity information',
  VIEW_LICENSES: 'License information',
  CONFIRM_DUPLICATES: 'Confirm duplicates',
  CONFIRM_HISTORIC: 'Confirm historic',
  CONFIRM_SEPARATE: 'Confirm separate',
};
