export const CHIPS_TYPES = {
  CHECKBOX: 'CHECKBOX',
  RADIOBUTTON: 'RADIOBUTTON',
  RADIODROPDOWN: 'RADIODROPDOWN',
  MULTIDROPDOWN: 'MULTIDROPDOWN',
  DATETIME: 'DATETIME',
  TEXTINPUT: 'TEXTINPUT',
  QUERYSTRING: 'QUERYSTRING',
};

export const DROPDOWN_DATA_TYPES = {
  JURISDICTIONS: 'JURISDICTIONS',
  CITY: 'CITY',
  COUNTRY: 'COUNTRY',
  SUPERVISORY: 'SUPERVISORY',
  AGENCIES: 'AGENCIES',
  COUNTY: 'COUNTY',
  TOWN: 'TOWN',
  ENTIRE_STATE: 'ENTIRE',
  AGENCY: 'AGENCY',
  USER: 'USER',
  CORPORATION: 'CORPORATION',
  COLLABORATIVE: 'COLLABORATIVE',
  VENUE: 'VENUE',
  CSB: 'CSB',
  DHEC_REGION: 'DHEC_REGION',
  ZIP_CODE: 'ZIPCODE',
};
