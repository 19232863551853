/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';

export const dropDownClickOutside = (id, func) => {
  const handleClickOutside = (e) => {
    const element = document.getElementById(id);
    if (element?.contains(e?.target)) {
      return;
    }
    func(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
};
