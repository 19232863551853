import { API_URLS } from '_constants';
import * as TYPES from './Infographics.types';

export const getCalcpolyList = () => {
  return {
    types: [TYPES.GET_CALCPOLY, TYPES.GET_CALCPOLY_SUCCESS, TYPES.GET_CALCPOLY_FAIL],
    promise: (client) => client.get(API_URLS.calcPoly),
  };
};

export const getDisparateBurden = (params) => {
  return {
    types: [TYPES.GET_DISPARATEBURDEN, TYPES.GET_DISPARATEBURDEN_SUCCESS, TYPES.GET_DISPARATEBURDEN_FAIL],
    promise: (client) => client.get(`${API_URLS.disparateBurden}?${params}`),
  };
};

export const getEnforcementOverview = (params) => {
  return {
    types: [
      TYPES.GET_ENFORCEMENT_OVERVIEW,
      TYPES.GET_ENFORCEMENT_OVERVIEW_SUCCESS,
      TYPES.GET_ENFORCEMENT_OVERVIEW_FAIL,
    ],
    promise: (client) => client.get(`${API_URLS.enforcementOverview}?${params}`),
  };
};

export const getPosLandscape = (params) => {
  return {
    types: [TYPES.GET_POSLANDSCAPE, TYPES.GET_POSLANDSCAPE_SUCCESS, TYPES.GET_POSLANDSCAPE_FAIL],
    promise: (client) => client.get(`${API_URLS.posLandscape}?${params}`),
  };
};

export const getRetailerReduction = (params) => {
  return {
    types: [TYPES.GET_RETAILER_REDUCTION, TYPES.GET_RETAILER_REDUCTION_SUCCESS, TYPES.GET_RETAILER_REDUCTION_FAIL],
    promise: (client) => client.get(`${API_URLS.retailerReduction}?${params}`),
  };
};

export const getYouthAppeal = (params) => {
  return {
    types: [TYPES.GET_YOUTHAPPEAL, TYPES.GET_YOUTHAPPEAL_SUCCESS, TYPES.GET_YOUTHAPPEAL_FAIL],
    promise: (client) => client.get(`${API_URLS.youthAppeal}?${params}`),
  };
};

export const setChosenGeography = (geography) => {
  return {
    type: TYPES.SET_CHOSEN_GEOGRAPHY,
    payload: geography,
  };
};
