import { isEmpty } from 'lodash';

const getCalcpolyToDelete = (allValues, valuesToSave) => {
  if (allValues?.length > valuesToSave?.length) {
    return allValues.filter((el) => !valuesToSave.includes(el));
  }
  return [];
};

export const getCalcpoly = (shadowFilters, values, field) => {
  if (isEmpty(shadowFilters?.calcpoly)) return values;
  const valueToDelete = getCalcpolyToDelete(shadowFilters[field], values);

  return shadowFilters?.calcpoly
    ?.concat(values.filter((el) => !shadowFilters?.calcpoly.includes(el)))
    .filter((el) => !valueToDelete.includes(el));
};
