import * as actions from './Filters.actions';
import reducer from './Filters.reducer';
import * as types from './Filters.types';
import * as selectors from './Filters.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
