import * as actions from './Collect.actions';
import reducer from './Collect.reducer';
import * as types from './Collect.types';
import * as selectors from './Collect.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
