/* eslint-disable react/prop-types */
import React from 'react';
import * as Styled from './styles';
import MenuItems from '../MenuItems';

const MenuDesktop = ({ routes }) => (
  <Styled.MenuContainer role="navigation" aria-label="Main navigation">
    <MenuItems routes={routes} />
  </Styled.MenuContainer>
);

export default MenuDesktop;
