import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { CHIPS_TYPES } from '../../components/ChipsList/constants';

export default {
  [FILTER_FIELDS.RETAILER_BUSINESS_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.STORE_TYPE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Retailer type: ${label}` }),
      {},
    ),
  },
};
