import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BaseSelect, Modal, Button } from 'ui-kit';
import { BUTTON_TYPES, URLS } from '_constants';
import { SurveyEntity } from '_entities';
import * as Styled from './styles';

const { getSurveyForms } = SurveyEntity.selectors;

export default function SurveyModal({ open, onClose, retailerId }) {
  const [selectedForm, setSelectedForm] = useState('');
  const listOfForms = useSelector(getSurveyForms);
  const [isValid, setIsValid] = useState(true);
  const history = useHistory();

  const options = useMemo(
    () =>
      listOfForms &&
      listOfForms.map((elem) => ({
        value: elem.attributes.name,
        label: elem.attributes.name,
        key: elem.id,
      })),
    [listOfForms],
  );

  const closeModal = () => {
    setSelectedForm('');
    setIsValid(true);
    onClose();
  };

  const openSurveyPage = () => {
    if (!selectedForm) {
      setIsValid(false);
      return null;
    }
    return history.push(`${URLS.survey}/${retailerId}/${selectedForm.key}`);
  };

  const handleChangeSelect = (val) => {
    setSelectedForm(val);
    setIsValid(true);
  };

  if (!open) return null;
  return (
    <Modal autoWidth withoutPadding open={open} onClose={closeModal} preventDesktopOverflow>
      <Styled.Wrapper>
        <>
          <Styled.Title>Survey this store</Styled.Title>
          <Styled.Description>Please choose a survey form below:</Styled.Description>
          <Styled.DropWrapper>
            <BaseSelect
              value={selectedForm}
              onChange={handleChangeSelect}
              inputId="surveyModalDrop"
              options={options}
              error={!isValid && 'The form is required'}
              className={!isValid && 'error'}
            />
          </Styled.DropWrapper>
        </>
        <Styled.Footer>
          <Button onClick={closeModal} text="Cancel" variant={BUTTON_TYPES.DARK} />
          <Button text="Proceed" variant={BUTTON_TYPES.DANGER} onClick={openSurveyPage} />
        </Styled.Footer>
      </Styled.Wrapper>
    </Modal>
  );
}

SurveyModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  retailerId: PropTypes.number.isRequired,
};

SurveyModal.defaultProps = {
  open: false,
};
