export default {
  cities: 'cities',
  retailers: 'retailers',
  counties: 'counties',
  supervisoryDistricts: 'supervisory-districts',
  visits: 'visits',
  filterLocation: 'mapping/list/calcpoly',
  agencies: 'agencies',
  users: 'users',
  retailerCorporation: 'retailers_corporations',
  assessments: 'assessments',
  assignments: 'assignments',
  educations: 'educations',
  campaigns: 'campaigns',
  revisits: 'retailers/revisits_needed',
  youth: 'youth',
  auditLogs: 'audit-logs',
  auditCSV: 'audit-logs-csv',
  revisitsCSV: 'retailer_revisits_csv',
  campaignCSV: 'campaigns_csv',
  assignmentsCSV: 'assignments_csv',
  roles: 'roles',
  secGeogs: 'sec-geogs',
  accounts: 'accounts',
  enforcementCSV: 'enforcements_csv',
  educationCSV: 'educations_csv',
  assessmentCSV: 'assessments_csv',
  mapping: 'mapping/validation/boundary/',
  venues: 'venues',
  venuesCSV: 'venues_csv',
  usersCSV: 'users_csv',
  calcPoly: 'mapping/list/calcpoly/',
  areaReport: 'reports/area-summary-report',
  enforcementReport: 'reports/enforcement-overview-report',
  educationReport: 'reports/education-overview-report',
  mySecGeogs: 'my-sec-geogs',
  youthAppeal: 'reports/youthappeal',
  disparateBurden: 'reports/disparateburden',
  enforcementOverview: 'reports/enforcementoverview',
  posLandscape: 'reports/poslandscape',
  retailerReduction: 'reports/retailerreduction',
  enforcementViolations: 'enforcement_violations',
  educationViolations: 'education_violations',
  userNotifications: 'user-notifications/',
};
