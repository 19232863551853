/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import TextCell from '../TextCell';
import LinkCell from '../LinkCell';

const FORMAT_DATES = {
  DEFAULT: 'MM/DD/YYYY',
  FULL: 'MM/DD/YYYY, hh:mm',
  ATTRIBUTE: 'YYYY-MM-DD',
};

const TimeCell = ({ data, to, isFullDate, id }) => {
  const formatDateToDisplay = useCallback(
    (date) => moment(date).format(isFullDate ? FORMAT_DATES.FULL : FORMAT_DATES.DEFAULT),
    [isFullDate],
  );
  const formatDateToAttr = useCallback((date) => moment(date).format(FORMAT_DATES.ATTRIBUTE), []);

  const displayedContent = useMemo(() => {
    if (isEmpty(data) || data === 'Invalid date') return <TextCell text="-" />;
    if (to) {
      return (
        <Styled.TimeElement dateTime={formatDateToAttr(data)}>
          <LinkCell id={id} to={to} text={formatDateToDisplay(data)} />
        </Styled.TimeElement>
      );
    }

    return <Styled.TimeElement dateTime={formatDateToAttr(data)}>{formatDateToDisplay(data)}</Styled.TimeElement>;
  }, [data, formatDateToAttr, formatDateToDisplay, id, to]);

  return <Styled.Wrapper>{displayedContent}</Styled.Wrapper>;
};

TimeCell.propTypes = {
  isFullDate: PropTypes.bool,
};

TimeCell.defaultProps = {
  isFullDate: false,
};

export default TimeCell;
