export const userLogin = {
  login: 'USER_LOGIN',
  logout: 'USER_LOGOUT',
  loginError: 'USER_LOGIN_ERROR',
  resetPassword: 'RESET_PASSWORD',
  resetPasswordSuccessActionConst: 'RESET_PASSWORD_SUCCESS',
  setNewPasswordError: 'SET_NEW_PASSWORD_ERROR',
  setNewPasswordSuccess: 'SET_NEW_PASSWORD_SUCCESS',
  setCodeForPassword: 'SET_CODE_PASSWORD',
  isCheckPassword: 'CHECK_PASSWORD',
  updatePassword: 'UPDATE_PASSWORD',
  lockedAccount: 'ACCOUNT_LOCKED',
  setFirstPassword: 'SET_FIRST_PASSWORD',
  isFirstPasswordSet: 'IS_FIRST_PASSWORD',
  loginUserData: 'USER_LOGIN_DATA',
  currentPassword: 'SET_CURRENT_PASSWORD',
  setUsers: 'SET_USERS',
  setTableItemLocked: 'SET_TABLE_ITEM_LOCKED',
  setUserRole: 'SET_USER_ROLE',
  isWasValidated: 'IS_WAS_VALIDATED',
};

const setUserRole = (payload) => ({
  type: userLogin.setUserRole,
  payload,
});

const isWasValidated = (payload) => ({
  type: userLogin.isWasValidated,
  payload,
});

const loginUserData = (payload) => ({
  type: userLogin.loginUserData,
  payload: payload.username,
});

const login = (payload) => ({
  type: userLogin.login,
  payload,
});

const setUsersData = (payload) => ({
  type: userLogin.setUsers,
  payload,
});

const loginError = (payload) => ({
  type: userLogin.loginError,
  payload,
});

const resetPasswordError = (payload) => ({
  type: userLogin.resetPassword,
  payload,
});

const resetPasswordSuccessAction = (payload) => ({
  type: userLogin.resetPasswordSuccessActionConst,
  payload,
});

const setNewPasswordError = (payload) => ({
  type: userLogin.setNewPasswordError,
  payload,
});

const isCheckPassword = (payload) => ({
  type: userLogin.isCheckPassword,
  payload,
});

const setNewPasswordSuccess = (payload) => ({
  type: userLogin.setNewPasswordSuccess,
  payload,
});

const setCodeForPassword = (payload) => ({
  type: userLogin.setCodeForPassword,
  payload,
});

const updatePassword = (payload) => ({
  type: userLogin.updatePassword,
  payload,
});

const lockedAccountUser = (payload) => ({
  type: userLogin.lockedAccount,
  payload,
});

const setFirstPassword = (payload) => ({
  type: userLogin.setFirstPassword,
  payload,
});

const isFirstPasswordSet = (payload) => ({
  type: userLogin.isFirstPasswordSet,
  payload,
});

const setCurrentPassword = (payload) => ({
  type: userLogin.currentPassword,
  payload,
});

const setTableItemLocked = (payload) => ({
  type: userLogin.setTableItemLocked,
  payload,
});

export {
  login,
  loginError,
  resetPasswordError,
  resetPasswordSuccessAction,
  setNewPasswordError,
  setNewPasswordSuccess,
  setCodeForPassword,
  isCheckPassword,
  updatePassword,
  lockedAccountUser,
  setFirstPassword,
  isFirstPasswordSet,
  loginUserData,
  setCurrentPassword,
  setUsersData,
  setTableItemLocked,
  setUserRole,
  isWasValidated,
};
