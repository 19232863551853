const COLUMNS = [
  {
    label: 'Agency',
    value: 'agencyName',
  },
  {
    label: 'Violations',
    value: 'violations',
  },
  {
    label: 'Total Visits',
    value: 'totalVisits',
  },
  {
    label: 'Violation Rate',
    value: 'violationRate',
  },
];

const INITIAL_COLUMNS = ['agencyName', 'violations', 'totalVisits', 'violationRate'];

export { COLUMNS, INITIAL_COLUMNS };
