import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom';
import { DEVICES } from '_constants';

export const Link = styled(DefaultLink).attrs(() => ({ ariaRequired: true }))`
  font-size: 14px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  white-space: normal;
  word-break: break-all;
  width: 100%;
  color: #3576bb;
  flex: 1 0 auto;
`;

export const AdditionalText = styled.span``;
export const Wrapper = styled.div`
  width: 100%;
  vertical-align: middle;
  display: flex;
  flex-direction: column;

  @media ${DEVICES.tableView} {
    display: block;
  }
`;
