export const PAGE_TYPE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  ADD: 'ADD',
};

export const SURVEY_QUESTION_TYPE = {
  TEXT: 'text',
  RADIOGROUP: 'radiogroup',
  HTML: 'html',
  CHECKBOX: 'checkbox',
  COMMENT: 'comment',
  FILE: 'file',
  NUMBER: 'number',
  DATE: 'date',
  DROPDOWN: 'dropdown',
};
