/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isString, isFunction } from 'lodash';
import { FilterEntity, AuthEntity } from '_entities';
import CheckBox from 'ui-kit/old/checkbox';
import { isUserJurisdictions } from 'utils/old/isUserJurisdictions';
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { Fieldset } from '../RadioButtonsGroup/styles';
import * as Styled from './styles';

const { setShadowFilter } = FilterEntity.actions;
const { getAgencies } = AuthEntity.selectors;

export const CheckedList = ({
  title,
  options,
  withSelectAll,
  name,
  isQueryString,
  onChange,
  isVisitStatus,
  isSubcategory,
}) => {
  const { shadowFilters } = useSelector((state) => state.oldFilter);
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState([]);
  const jurisdictionsList = useSelector(getAgencies);

  const handleOptionChange = (pickedValue) => () => {
    if (selectedValues.includes(pickedValue)) {
      const newValues = [...selectedValues];
      const idIndex = newValues.indexOf(pickedValue);
      newValues.splice(idIndex, 1);
      setSelectedValues(newValues);
      const formattedValues = isQueryString ? newValues.join(',') : newValues;
      const newFilters = { ...shadowFilters, [name]: formattedValues };
      if (onChange && isFunction(onChange)) {
        onChange(newFilters, pickedValue);
        return;
      }
      // eslint-disable-next-line camelcase
      if (newFilters?.corp_flag?.length === 0) {
        // eslint-disable-next-line camelcase
        const cloneSecGeogs = cloneDeep(shadowFilters?.jurisdictions || shadowFilters?.sec_geogs);
        const whichFilter = shadowFilters.jurisdictions ? FILTER_FIELDS.JURISDICTIONS : FILTER_FIELDS.SEC_GEOGS;
        newFilters[whichFilter] = cloneSecGeogs?.filter((item) => isUserJurisdictions(item, jurisdictionsList));
      }
      dispatch(setShadowFilter(newFilters));
    } else {
      const filteredValues = selectedValues.filter((item) => item);
      const newValues = [...filteredValues, pickedValue];
      setSelectedValues(newValues);
      const formattedValues = isQueryString ? newValues.join(',') : newValues;
      dispatch(setShadowFilter({ ...shadowFilters, [name]: formattedValues }));
    }
  };

  const handleAllSelect = (e, { checked }) => {
    if (checked) {
      const newValues = options.map((option) => option.value);
      if (isVisitStatus && selectedValues.length) {
        selectedValues.forEach((value) => {
          if (!newValues.includes(value)) {
            newValues.push(value);
          }
        });
      }
      setSelectedValues(newValues);
      dispatch(setShadowFilter({ ...shadowFilters, [name]: newValues }));
    } else {
      const cleanedValues = isVisitStatus
        ? selectedValues.filter((el) => el === FILTER_OPTIONS.SURVEY_WHY_OPTIONS[0]?.value)
        : [];
      setSelectedValues(cleanedValues);
      dispatch(setShadowFilter({ ...shadowFilters, [name]: cleanedValues }));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-nested-ternary
    const defaultValues =
      typeof shadowFilters[name] === 'string'
        ? isQueryString
          ? shadowFilters[name].split(',')
          : [shadowFilters[name]]
        : shadowFilters[name] || [];
    setSelectedValues(defaultValues);
  }, [shadowFilters]);

  return (
    <Styled.Wrapper isGroupOfCheckboxes={options.length > 1}>
      <Fieldset isSubcategory={isSubcategory}>
        {title && <Styled.Title>{title}</Styled.Title>}
        {!title && <Styled.Legend hidden>group checkboxes</Styled.Legend>}
        <Styled.UL>
          {withSelectAll && (
            <Styled.LI>
              <CheckBox
                labelText={isString(withSelectAll) ? withSelectAll : 'All'}
                name={isString(withSelectAll) ? withSelectAll : 'All'}
                isEnforcement
                isChecked={
                  isVisitStatus
                    ? options.length ===
                      selectedValues.filter((el) => el !== FILTER_OPTIONS.SURVEY_WHY_OPTIONS[0]?.value).length
                    : options.length === selectedValues.length
                }
                onChange={handleAllSelect}
              />
            </Styled.LI>
          )}
          {options.map(({ label, value: optionValue }) => (
            <Styled.LI withSelectAll={withSelectAll}>
              <CheckBox
                key={label}
                name={label}
                labelText={label}
                isEnforcement
                isChecked={selectedValues.includes(optionValue)}
                onChange={handleOptionChange(optionValue)}
              />
            </Styled.LI>
          ))}
        </Styled.UL>
      </Fieldset>
    </Styled.Wrapper>
  );
};

CheckedList.defaultProps = {
  title: '',
  options: [],
  withSelectAll: false,
  isQueryString: false,
};
