import {
  EDUCATION,
  ENFORCEMENT,
  POLICY_TESTER,
  PRODUCT_AVAILABILITY,
  PRODUCT_PRICE,
  PRODUCT_PROMOTIONS,
  RETAILER_DENSITY,
  SCHOOLS,
} from 'assets/icons/snapshot';
import { URLS, PERMISSIONS } from '_constants';

export const SNAPSHOT_NAVIGATION_DATA = [
  {
    id: 1,
    title: 'Retailer Density',
    slug: 'density',
    description:
      'This map shows the number of retailers relative to population. A ratio of more than 3 retailers per 1,000 residents, is considered a "tobacco swamp".',
    url: URLS.snapshot.retailerDensity,
    icon: RETAILER_DENSITY,
    permission: PERMISSIONS.VIEW_SNAPSHOT_DENSITY,
  },
  {
    id: 2,
    slug: 'policy',
    title: 'Policy Tester',
    description: 'This map shows the proportion of retailers which fit certain substance-abuse prevention policies.',
    url: URLS.snapshot.policyTester,
    icon: POLICY_TESTER,
    permission: PERMISSIONS.VIEW_SNAPSHOT_POLICY,
  },
  {
    id: 3,
    slug: 'schools',
    title: 'Schools',
    description: 'This map shows the proportion of schools which are near tobacco retailers.',
    url: URLS.snapshot.schools,
    icon: SCHOOLS,
    permission: PERMISSIONS.VIEW_SNAPSHOT_SCHOOLS,
  },
  {
    id: 4,
    slug: 'availability',
    title: 'Product Availability',
    description:
      'This map shows the proportion of store assessments, at which a retailer was noted as having a particular item for sale.',
    url: URLS.snapshot.productAvailability,
    icon: PRODUCT_AVAILABILITY,
    permission: PERMISSIONS.VIEW_SNAPSHOT_AVAILABILITY,
  },
  {
    id: 5,
    slug: 'promotion',
    title: 'Product Promotions',
    description:
      'This map shows the proportion of store assessments, at which a retailer was noted as having a promotion.',
    url: URLS.snapshot.productPromotions,
    icon: PRODUCT_PROMOTIONS,
    permission: PERMISSIONS.VIEW_SNAPSHOT_PROMOTION,
  },
  {
    id: 6,
    slug: 'price',
    title: 'Product Price',
    description: 'This map shows store assessments indicating the average price of certain items.',
    url: URLS.snapshot.productPrice,
    icon: PRODUCT_PRICE,
    permission: PERMISSIONS.VIEW_SNAPSHOT_PRICE,
  },
  {
    id: 7,
    slug: 'education',
    title: 'Education',
    description:
      'This map shows the percentage of retailer education visits in which a violation was found, and the percentage of retailers who have had education visits.',
    url: URLS.snapshot.education,
    icon: EDUCATION,
    permission: PERMISSIONS.VIEW_SNAPSHOT_EDUCATION,
  },
  {
    id: 8,
    slug: 'enforcement',
    title: 'Enforcement',
    description:
      'This map shows the percentage of inspections in which a violation was found, and the percentage of retailers which have been inspected.',
    url: URLS.snapshot.enforcement,
    icon: ENFORCEMENT,
    permission: PERMISSIONS.VIEW_SNAPSHOT_ENFORCEMENT,
  },
];
