import styled from 'styled-components';
import { DEVICES } from '_constants';

const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  width: 100%;
  height: 100%;
  background: var(--opacity-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: wait;
`;
const Container = styled.div.attrs(() => ({ ariaRequired: true }))`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0px 0 0 0px;
  span {
    margin-top: 47px;
    display: block;
    text-align: center;
  }
  img {
    display: block;
    animation: spin 2s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  @media ${DEVICES.tablet} {
    top: calc(50% - 100px);
    left: calc(50% - 57px);
  }
  @media ${DEVICES.mobileL} {
    top: calc(50% - 100px);
    left: calc(50% - 53px);
  }
`;
export { Wrapper, Container };
