import { createInitialValues } from 'utils/custom';

export const formNames = {
  name: 'name',
  address: 'address',
  city: 'city',
  zipCode: 'zipcode',
  county: 'county_name',
  state: 'state',
  type: 'type',
  smokeIndoor: 'smokefree_indoor_smoking',
  smokeGrounds: 'smokefree_grounds_smoking',
  tobaccoIndoor: 'smokefree_indoor_tobacco',
  tobaccoGrounds: 'smokefree_grounds_tobacco',
  vapeIndoor: 'smokefree_indoor_vape',
  vapeGrounds: 'smokefree_grounds_vape',
  latlong: 'geom',
  addrUpdated: 'addr_updated',
};

export const FORM_ACTION_TYPES = {
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
  GET_CITY: 'GET_CITY',
  RESET_DATA: 'RESET_DATA',
  SET_DATA: 'SET_DATA',
};

export const FORM_REQUIRED_FIELDS = [
  formNames.name,
  formNames.address,
  formNames.city,
  formNames.zipCode,
  formNames.type,
];

export const selectsList = [formNames.city, formNames.type];
export const checkboxList = [
  { name: formNames.smokeIndoor, label: 'Smoke-free indoor' },
  { name: formNames.tobaccoIndoor, label: 'Tobacco-free indoor' },
  { name: formNames.vapeIndoor, label: 'Vape-free indoor' },
  { name: formNames.smokeGrounds, label: 'Smoke-free grounds' },
  { name: formNames.tobaccoGrounds, label: 'Tobacco-free grounds' },
  { name: formNames.vapeGrounds, label: 'Vape-free grounds' },
];

export const getInitialValue = (name) => {
  const findName = (arr, inputName) => arr.includes(inputName) && inputName;

  switch (name) {
    case findName(selectsList, name): {
      return {
        id: 0,
        label: name === formNames.city ? '' : 'Select an option',
        value: null,
        name,
      };
    }
    case formNames.latlong: {
      return {
        latitude: '',
        longitude: '',
      };
    }
    case formNames.smokeIndoor:
    case formNames.tobaccoIndoor:
    case formNames.vapeIndoor:
    case formNames.smokeGrounds:
    case formNames.tobaccoGrounds:
    case formNames.vapeGrounds: {
      return null;
    }
    default:
      return '';
  }
};

const defaultRetailerState = Object.values(formNames).reduce(
  (newObj, value) => ({ ...newObj, [value]: getInitialValue(value) }),
  {},
);

export const formInitialState = {
  data: { attributes: defaultRetailerState, type: 'Venue' },
  errors: createInitialValues(formNames, null),
  canBeSubmitted: false,
};

export const venuesTypes = [
  { name: formNames.type, value: 'College/University', label: 'College/University' },
  { name: formNames.type, value: 'Government Building', label: 'Government building' },
  { name: formNames.type, value: 'Hospital/Clinic', label: 'Hospital/Clinic' },
  { name: formNames.type, value: 'Multi-unit Housing', label: 'Multi-unit Housing' },
  { name: formNames.type, value: 'Park/Rec Center', label: 'Park/Rec Center' },
  { name: formNames.type, value: 'Private School', label: 'Private School' },
  { name: formNames.type, value: 'Public School', label: 'Public School' },
];

export const PAGE_COLUMNS = 'three_columns';
