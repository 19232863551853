import { isEmpty, isEqual, reduce } from 'lodash';

export const checkIfFormUnchanged = (startFormData, updatedFormData, timeZone) => {
  if (isEmpty(updatedFormData)) return true;

  const startSubmittedDate = new Date(startFormData.submitted).toLocaleString('en-US', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const startSubmittedTime = new Date(startFormData.submitted).toLocaleTimeString('en-US', {
    timeZone,
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  });

  const startDataCopy = {
    ...startFormData,
    submitted_time: startSubmittedTime,
    submitted: startSubmittedDate,
  };

  const reduceResult = reduce(
    { ...updatedFormData },
    (result, value, key) => {
      switch (key) {
        case 'submitted':
          return {
            ...result,
            [key]: new Date(updatedFormData.submitted).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }),
          };

        default:
          return { ...result, [key]: value };
      }
    },
    {},
  );

  return isEqual(reduceResult, startDataCopy);
};
