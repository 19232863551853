import { API_URLS } from '_constants';
import * as TYPES from './Filters.types';

export const getLocationMapping = () => ({
  types: [TYPES.GET_LOCATION, TYPES.GET_LOCATION_SUCCESS, TYPES.GET_LOCATION_FAIL],
  promise: (client) => client.get(`${API_URLS.filterLocation}/?exclude_empty_retailers=true`),
});

export const getVisitsAgency = (visitType) => ({
  types: [TYPES.GET_ENFORCEMENT_AGENCY, TYPES.GET_ENFORCEMENT_AGENCY_SUCCESS, TYPES.GET_ENFORCEMENT_AGENCY_FAIL],
  promise: (client) => client.get(`${API_URLS.agencies}${visitType ? `?hasvisits_type=${visitType}` : ''}`),
});

export const getVisitsUser = () => ({
  types: [TYPES.GET_ENFORCEMENT_USER, TYPES.GET_ENFORCEMENT_USER_SUCCESS, TYPES.GET_ENFORCEMENT_USER_FAIL],
  promise: (client) => client.get(`${API_URLS.users}/filter_list?active_user=true`),
});

export const getCorporation = () => ({
  types: [TYPES.GET_RETAILER_CORPORATION, TYPES.GET_RETAILER_CORPORATION_SUCCESS, TYPES.GET_RETAILER_CORPORATION_FAIL],
  promise: (client) => client.get(API_URLS.retailerCorporation),
});
