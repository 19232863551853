/* eslint-disable react/prop-types */
/* Libs */
import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

const RadioButton = ({ value, id, name, label, onChange, disabled, checked, small, ...inputProps }, ref) => {
  const handleRadioChange = (e) =>
    onChange(() => {
      return { target: { name: e.target.name, value: e.target.checked } };
    });

  return (
    <Styled.Wrapper isActive={value}>
      <Styled.Input
        {...inputProps}
        type="radio"
        ref={ref}
        checked={value}
        disabled={disabled}
        onChange={handleRadioChange}
        id={label + name}
        name={name}
      />
      <Styled.Label htmlFor={label + name} aria-label={label} disabled={disabled}>
        {label}
      </Styled.Label>
    </Styled.Wrapper>
  );
};

const Radio = memo(forwardRef(RadioButton));

const RadioButtonGroup = ({
  options,
  direction,
  disabled: allDisabled,
  title,
  small,
  error,
  value,
  gap,
  onChange,
  pageColumns,
  hasBorder,
  ...radioProps
}) => {
  const handleChange = (val, name) => () => {
    onChange({ target: { value: val, name } });
  };
  return (
    <Styled.RadioGroupWrapper pageColumns={pageColumns}>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.OptionsWrapper gap={gap} direction={direction} className={error ? 'error' : ''}>
        {options.map(({ label, value: optionValue, disabled }) => (
          <Radio
            {...radioProps}
            id={label?.toLowerCase()}
            label={label}
            value={value === optionValue}
            onChange={handleChange(optionValue, radioProps.name)}
            disabled={disabled || allDisabled}
            small={small}
            key={label}
          />
        ))}
      </Styled.OptionsWrapper>
      {!allDisabled && error && <Styled.Error aria-label="error">{error}</Styled.Error>}
      {hasBorder && <Styled.HorizontalBorder />}
    </Styled.RadioGroupWrapper>
  );
};

Radio.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  pageColumns: PropTypes.string,
  hasBorder: PropTypes.bool,
};

Radio.defaultProps = {
  disabled: false,
  label: '',
  pageColumns: '',
  hasBorder: false,
};

export default RadioButtonGroup;
