import { isNull } from 'lodash';

export const getMaxStartDate = (startDate, endDate) => {
  const currentDate = new Date();

  if (isNull(endDate) || isNull(startDate)) {
    return currentDate;
  }

  const endDateTime = endDate.getTime();

  if (endDateTime > startDate.getTime() && endDateTime > currentDate.getTime()) {
    return currentDate;
  }

  return endDate;
};
