/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { PageContainer } from 'modules';
import BackPageButton from 'modules/BackPageButton';
import * as Styled from './styles';

const Policy = () => {
  return (
    <PageContainer>
      <Styled.Main>
        <Styled.PolicyContainer>
          <BackPageButton />
          <Styled.TitleContainer type="policy">
            <Styled.TitleH1>Terms of Use & Privacy Policy</Styled.TitleH1>
          </Styled.TitleContainer>
          <Styled.TextContainer>
            <Styled.TitleH2>Acceptance of terms of use and amendments</Styled.TitleH2>
            <Styled.ContentText>
              Each time you use or cause access to the Point of Sale Toolkit (POST) site, located at and referenced
              hereafter as POST, you agree to be bound by these Terms of Use, as amended from time to time with or
              without notice to you. In addition, if you are using a particular service on POST, or accessed via POST,
              you will be subject to any rules or guidelines applicable to those services, and they will be incorporated
              by reference within these Terms of Use. Please read the site's Privacy Policy, which is incorporated
              within these Terms of Use by reference.
            </Styled.ContentText>
            <Styled.TitleH2>The site administrator's service</Styled.TitleH2>
            <Styled.ContentText>
              POST and the services provided to you on and via POST are provided on an "AS IS" basis. You agree that the
              site administrator reserves the right to modify or discontinue provision of POST and its services, and to
              remove the data you provide, either temporarily or permanently, at any time, without notice and without
              any liability towards you. The site administrator will not be held responsible or liable for timeliness,
              removal of information, failure to store information, inaccuracy of information, or improper delivery of
              information.
            </Styled.ContentText>
            <Styled.TitleH2>Your responsibilities and registration obligations</Styled.TitleH2>
            <Styled.ContentText>
              In order to use POST or certain parts of it, you may be required to register for a user account on POST;
              in this case, you agree to provide truthful information when requested, and -- if a minimum age is
              required for eligibility for a user account -- you undertake that you are at least the required age. By
              registering for a user account, you explicitly agree to this site's Terms of Use, including any amendments
              made by the site administrator that are published herein.
            </Styled.ContentText>
            <Styled.TitleH2>Privacy Policy</Styled.TitleH2>
            <Styled.ContentText>
              Registration data and other personally identifiable information that the site may collect is subject to
              the terms of the site administrator's Privacy Policy.
            </Styled.ContentText>
            <Styled.TitleH2>Registration and password</Styled.TitleH2>
            <Styled.ContentText>
              You are responsible for maintaining the confidentiality of your password, and you will be responsible for
              all usage of your user account and/or user name, whether authorized or not authorized by you. You agree to
              immediately notify the site administrator of any unauthorized use of your user account, user name or
              password.
            </Styled.ContentText>
            <Styled.TitleH2>Your conduct</Styled.TitleH2>
            <Styled.ContentText>
              You agree that all information or data of any kind, whether text, software, code, music or sound,
              photographs or graphics, video or other materials ("content"), made available publicly or privately, will
              be under the sole responsibility of the person providing the said content, or of the person whose user
              account is used. You agree that POST may expose you to content that may be objectionable or offensive. The
              site administrator will not be responsible to you in any way for content displayed on POST, nor for any
              error or omission. By using POST or any service provided, you explicitly agree that: (a) you will not
              provide any content or conduct yourself in any way that may be construed as: unlawful; illegal;
              threatening; harmful; abusive; harassing; stalking; tortious; defamatory; libelous; vulgar; obscene;
              offensive; objectionable; pornographic; designed to interfere with or disrupt the operation of POST or any
              service provided; infected with a virus or other destructive or deleterious programming routine; giving
              rise to civil or criminal liability; or in violation of an applicable local, national or international
              law; (b) you will not impersonate or misrepresent your association with any person or entity; you will not
              forge or otherwise seek to conceal or misrepresent the origin of any content provided by you; (c) you will
              not collect or harvest any information about other users; (d) you will not provide, and you will not use
              POST to provide, any content or service in any commercial manner, or in any manner that would involve junk
              mail, spam, chain letters, pyramid schemes, or any other form of unauthorized advertising or commerce; you
              will not use POST to promote or operate any service or content without the site administrator's prior
              written consent; (e) you will not provide any content that may give rise to the site administrator being
              held civilly or criminally liable, or that may be considered a violation of any local, national or
              international law, including -- but not limited to -- laws relating to copyrights, trademarks, patents, or
              trade secrets.
            </Styled.ContentText>
            <Styled.TitleH2>Submission of content on POST</Styled.TitleH2>
            <Styled.ContentText>
              By providing any content to POST, you agree to submit content in a format determined by the site
              administrator, and not in a manner that undermines the integrity of the content itself. You agree to
              follow Counter Tools’ formatting and content guidelines for data submission as well as the restrictions
              specified above in section 6.
            </Styled.ContentText>
            <Styled.TitleH2>Indemnification</Styled.TitleH2>
            <Styled.ContentText>
              You agree to indemnify and hold harmless the site administrator and the site administrator's
              representatives, subsidiaries, affiliates, related parties, officers, directors, employees, agents,
              independent contractors, advertisers, partners, and co-branders, from any claim or demand, including
              reasonable legal fees, that may be filed by any third party, arising out of your conduct or connection
              with POST or service, your provision of content, your violation of these Terms of Use, or any other
              violation by you of the rights of another person or party.
            </Styled.ContentText>
            <Styled.TitleH2>Termination</Styled.TitleH2>
            <Styled.ContentText>
              If you violate any provision of these Terms of Use, your permission to use this Site, the Content or the
              Materials shall automatically terminate. The site administrator may change, suspend or terminate your
              account or your use of all or part of this Site at any time as the site administrator deems appropriate.
            </Styled.ContentText>
            <Styled.TitleH2>Reservation of rights</Styled.TitleH2>
            <Styled.ContentText>
              The site administrator reserves all of the site administrator's rights, including but not limited to any
              and all copyrights, trademarks, patents, trade secrets, and any other proprietary right that the site
              administrator may have in respect of POST, its content, and goods and services that may be provided. The
              use of the site administrator's rights. and property requires the site administrator's prior written
              consent. By making services available to you, the site administrator is not providing you with any implied
              or express licenses or rights, and you will have no rights to make any commercial use of POST or provided
              services without the site administrator's prior written consent.
            </Styled.ContentText>
            <Styled.TitleH2>Notification of copyright infringement</Styled.TitleH2>
            <Styled.ContentText>
              If you believe that your property has been used in any way that could be considered a copyright
              infringement or a violation of your intellectual property rights, the site administrator's copyright agent
              may be contacted via: e-mail to the site administrator at help@countertools.org.
            </Styled.ContentText>
            <Styled.TitleH2>Applicable law</Styled.TitleH2>
            <Styled.ContentText>
              You agree that these Terms of Use and any dispute arising out of your use of POST or products or services
              provided will be governed by and construed in accordance with local laws applicable at the site
              administrator's domicile, notwithstanding any differences between the said applicable legislation and
              legislation in force at your location. By registering for a user account on POST, or by using POST and the
              services it provides, you accept that jurisdiction is granted to the courts having jurisdiction over the
              site administrator's domicile, and that any disputes will be heard by the said courts.
            </Styled.ContentText>
            <Styled.TitleH2>Miscellaneous information</Styled.TitleH2>
            <Styled.ContentText>
              (i) In the event that any provision of these Terms of Use is deemed to conflict with legislation by a
              court with jurisdiction over the parties, the said provision will be interpreted to reflect the original
              intentions of the parties in accordance with applicable law, and the remainder of these Terms of use will
              remain valid and applicable; (ii) The failure of either party to assert any right under these Terms of use
              will not be considered to be a waiver of that party's right, and the said right will remain in full force
              and effect; (iii) You agree that any claim or cause in respect of POST or its services must be filed
              within one (1) year after such claim or cause arose, or the said claim or cause will be forever barred,
              without regard to any contrary legislation; (iv) The site administrator may assign the site
              administrator's rights and obligations under these Terms of Use; in this event, the site administrator
              will be relieved of any further obligation.
            </Styled.ContentText>
            <Styled.TitleH2>PRIVACY POLICY</Styled.TitleH2>
            <Styled.ContentText>
              Thank you for visiting our web site. This privacy policy tells you how we use personal information
              collected at this site.
            </Styled.ContentText>
            <Styled.ContentText>
              Please read this privacy policy before using the site or submitting any personal information. By using the
              site, you are accepting the practices described in this privacy policy. These practices may be changed,
              but any changes will be posted and changes will only apply to activities and information on a going
              forward, not retroactive basis. You are encouraged to review the privacy policy whenever you visit the
              site to make sure that you understand how any personal information you provide will be used. Note: the
              privacy practices set forth in this privacy policy are for this web site only. If you link to other web
              sites, please review the privacy policies posted at those sites.
            </Styled.ContentText>
            <Styled.TitleH2>Collection of Information</Styled.TitleH2>
            <Styled.ContentText>
              We collect personally identifiable information, like names, email addresses, etc., when voluntarily
              submitted by our visitors. The information you provide is used to fulfill your specific request. This
              information is only used to fulfill your specific request, for example to create an account in POST.
            </Styled.ContentText>
            <Styled.TitleH2>Cookie/Tracking Technology</Styled.TitleH2>
            <Styled.ContentText>
              The Site may use cookie and tracking technology depending on the features offered. Cookie and tracking
              technologies are useful for gathering information such as browser type and operating system, tracking the
              number of visitors to the Site, and understanding how visitors use the Site. Cookies can also help
              customize the Site for visitors. Personal information cannot be collected via cookies and other tracking
              technology; however, if you previously provided personally identifiable information, cookies may be tied
              to such information.
            </Styled.ContentText>
            <Styled.TitleH2>Distribution of Information</Styled.TitleH2>
            <Styled.ContentText>
              We may share information with governmental agencies or other companies assisting us in fraud prevention or
              investigation. We may do so when: (1) permitted or required by law; or, (2) trying to protect against or
              prevent actual or potential fraud or unauthorized transactions; or, (3) investigating fraud which has
              already taken place. The information is not provided to these companies for marketing purposes.
            </Styled.ContentText>
            <Styled.TitleH2>Commitment to Data Security</Styled.TitleH2>
            <Styled.ContentText>
              Your personally identifiable information is kept secure. Only authorized employees, agents and contractors
              (who have agreed to keep information secure and confidential) have access to this information.
            </Styled.ContentText>
            <Styled.TitleH2>Privacy Contact Information</Styled.TitleH2>
            <Styled.ContentText>
              If you have any questions, concerns, or comments about our privacy policy you may contact us using the
              information below: By e-mail: help@countertools.org. We reserve the right to make changes to this policy.
              Any changes to this policy will be posted.
            </Styled.ContentText>
          </Styled.TextContainer>
        </Styled.PolicyContainer>
      </Styled.Main>
    </PageContainer>
  );
};

export default Policy;
