import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100px;
`;

export const Inner = styled.div`
  > button {
    min-width: 130px;
  }
`;

export const ImageWrapper = styled.div`
  > img {
    max-width: 205px;
    display: block;
    width: auto;
    height: 100%;
    max-height: 205px;
    margin-bottom: 15px;
  }
`;

export const ImageTitle = styled.p`
  font-size: 14px;
  color: var(--dim-grey);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 20px;

  > button {
    min-width: 100px;

    :not(:last-of-type) {
      margin-right: 15px;
    }
  }
`;
