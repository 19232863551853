import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  position: relative;
  z-index: ${({ zIndex }) => zIndex || 1};

  .BaseSelect {
    &__control {
      border-radius: 0;
      width: 42px;
      min-height: 26px;
      background: var(--lite-grey);
      border: 0.3px solid var(--grey-border);
    }

    &__indicator-separator {
      display: none;
    }

    &__indicators {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      & > div {
        display: flex;
        align-items: center;
      }
    }

    &__value-container {
      padding: 2px;
    }

    &__menu {
      border-radius: 0;

      &-list {
        max-height: 185px;
        background: var(--lite-grey);
        border: 0.3px solid var(--grey-border);

        @media ${DEVICES.laptop} {
          max-height: 120px;
        }

        @media ${DEVICES.mobileXL} {
          max-height: 75px;
        }
      }
    }
  }
`;
