/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState } from 'react';
import { compact } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { TableWrapper, ActionCell } from 'ui-kit';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { URLS } from '_constants';
import useSortTable from 'utils/old/useSortTable';
import { COLUMNS, INITIAL_COLUMNS } from '../constants';

const getInitialColumns = ({ handleSort, sortOption, dumpedActiveColumns, editCellParams }) =>
  compact([
    dumpedActiveColumns.type && {
      Header: () => <HeaderCell col="Type" sortBy="type" sortOption={sortOption} handleSort={handleSort('type')} />,
      minWidth: 140,
      accessor: 'type',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.name && {
      Header: () => <HeaderCell col="Name" sortBy="name" sortOption={sortOption} handleSort={handleSort('name')} />,
      minWidth: 140,
      accessor: 'name',
      Cell: ({ value, original }) => <LinkCell id={original.id} text={value} to={`${URLS.venues}/${original.id}`} />,
    },
    dumpedActiveColumns.address && {
      Header: () => (
        <HeaderCell col="Address" sortBy="address" sortOption={sortOption} handleSort={handleSort('address')} />
      ),
      minWidth: 140,
      accessor: 'address',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.city && {
      Header: () => <HeaderCell col="City" sortBy="city" sortOption={sortOption} handleSort={handleSort('city')} />,
      minWidth: 140,
      accessor: 'city',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.zipcode && {
      Header: () => (
        <HeaderCell col="Zip code" sortBy="zipcode" sortOption={sortOption} handleSort={handleSort('zipcode')} />
      ),
      minWidth: 140,
      accessor: 'zipcode',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    {
      Header: () => {},
      width: 56,
      accessor: 'editPen',
      Cell: ({ original }) => <ActionCell original={original} {...editCellParams} />,
    },
  ]);

const VenuesTable = ({ data, sortHandler, sortOption }) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [activeColumns, setActiveColumns] = useState(INITIAL_COLUMNS);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();
  const getToast = () => getToastLock('Enforcement', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );
  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns: COLUMNS,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: ' venues',
      route: URLS.venuesPages.edit,
      getToast,
      onlyEdit: true,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitialColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams, // eslint-disable-next-line
      }),
    [dumpedActiveColumns, sortOption, tableControlParams, handleSort],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
    />
  );
};

VenuesTable.defaultProps = {};

export default memo(VenuesTable);
