import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-day-picker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FILTER_OPTIONS, FILTER_FIELDS, FILTER_TYPES } from '_constants/old/filters';
import { OldFilterEntity, NewFiltersEntity } from '_entities';
import { FormGroupWrapper } from 'modules/Modals/old/FilterModal/components/FormGroupWrapper';
import { BaseSelect } from 'ui-kit';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import FilterDropdown from 'modules/Modals/old/FilterModal/components/FilterDropdown';
import { CollapsedTab } from 'modules/Modals/old/FilterModal/components/CollapsedTab';
import DatePickerGroup from 'modules/Modals/old/FilterModal/components/DatePickerGroup';
import PropTypes from 'prop-types';
import { HasAccess } from 'modules';
import { PERMISSIONS } from '_constants';
import { capitalizeFirstLetter } from 'utils/custom';
import * as Styled from './styles';

const { setShadowFilter } = OldFilterEntity.actions;
const { getAgencyOptions, getUsersOptions } = NewFiltersEntity.selectors;

const getFilterField = (filterType) => {
  switch (filterType) {
    case FILTER_TYPES.ENFORCEMENT:
      return FILTER_FIELDS.ENFORCEMENT_COMPLETED;

    case FILTER_TYPES.EDUCATION:
      return FILTER_FIELDS.EDUCATION_COMPLETED;

    default:
      return null;
  }
};

const DROPDOWN_OPTIONS = [
  {
    label: 'Not completed',
    value: 'Not completed',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'All',
    value: 'All',
  },
];

const DROPDOWN_VALUES = { t: 'Completed', f: 'Not completed' };
const COMPLETED_STATUSES = { Completed: 't', 'Not completed': 'f' };

export const VisitDateAgencyStatus = ({ filterType }) => {
  const {
    filter: { shadowFilters },
  } = useSelector((state) => state);
  const [selectValue, setSelectValue] = useState({});
  const dispatch = useDispatch();
  const agencyOptions = useSelector(getAgencyOptions);
  const usersOptions = useSelector(getUsersOptions);

  const isDropActive = useMemo(() => shadowFilters[FILTER_FIELDS.SURVEY_WHY]?.includes('1'), [shadowFilters]);

  const ENFORCEMENT_QUICK_QUERY = [
    {
      id: 1,
      field: FILTER_FIELDS.AGENCY_ID,
      title: `Agency Conducting ${capitalizeFirstLetter(filterType.toLowerCase())} Visit`,
      subTitle: 'Agency',
      options: agencyOptions,
      type: 'multiselect',
      isShow: true,
    },
    {
      id: 2,
      field: FILTER_FIELDS.VISIT_DATE,
      title: 'Visit date',
      type: 'calendars',
    },
    {
      id: 3,
      field: FILTER_FIELDS.VISIT_USER,
      title: 'Users',
      subTitle: 'Users',
      options: usersOptions,
      type: 'multiselect',
      isShow: HasAccess(PERMISSIONS.CHANGE_USER),
    },
    {
      type: 'double',
      title: 'Visit Completion Status',
      id: 4,
      collapsed: [
        {
          id: 5,
          field: FILTER_FIELDS.SURVEY_WHY,
          options: FILTER_OPTIONS.SURVEY_WHY_OPTIONS.slice(1),
          isShow: true,
        },
      ],
    },
  ];

  const handleChangeSelect = (value, field) => {
    setSelectValue({ ...selectValue, [field]: value });
    dispatch(setShadowFilter({ ...shadowFilters, [field]: value.map((elem) => elem.value) }));
  };

  const getDropDefaultSelectedValues = (array = [], options) => {
    if (!options) return null;
    if (Array.isArray(array)) return options.filter((item) => array?.includes(item.value));
    return options.filter((item) => item.value === array);
  };

  useEffect(() => {
    const agencyId = getDropDefaultSelectedValues(shadowFilters?.agency_id, agencyOptions);
    const userId = getDropDefaultSelectedValues(shadowFilters?.user_id, usersOptions);
    setSelectValue({ agency_id: agencyId, user_id: userId });
  }, [shadowFilters.user_id, shadowFilters?.agency_id, agencyOptions, usersOptions]);

  const handleChangeQueryFiltersShowDropDown = (field, { value }) => {
    const newFilters = { ...shadowFilters };
    switch (value) {
      case 'All':
        dispatch(
          setShadowFilter({
            ...newFilters,
            ...{ [field]: '' },
          }),
        );
        break;

      default:
        dispatch(
          setShadowFilter({
            ...newFilters,
            ...{ [field]: COMPLETED_STATUSES[value] },
          }),
        );
        break;
    }
  };

  const completedStatus = useMemo(() => getFilterField(filterType), [filterType]);

  const handleChangeCheckbox = (newFilters) => {
    dispatch(
      setShadowFilter({
        ...newFilters,
        ...{ [completedStatus]: '' },
      }),
    );
  };

  const getElement = (title, field, options, type, id, subTitle, collapsed, isShowDrop) => {
    switch (type) {
      case 'checkbox':
        return <CheckedList title={title} name={field} options={options} />;
      case 'multiselect':
        return (
          isShowDrop && (
            <CollapsedTab title={title} key={id}>
              <BaseSelect
                onChange={(value) => {
                  handleChangeSelect(value, field);
                }}
                isMulti
                labelText={subTitle}
                name={title}
                options={options}
                // eslint-disable-next-line no-prototype-builtins
                value={selectValue.hasOwnProperty(field) && selectValue[field]}
              />
            </CollapsedTab>
          )
        );
      case 'calendars':
        return (
          <CollapsedTab title={title} key={id}>
            <DatePickerGroup name={field} title="" />
          </CollapsedTab>
        );
      case 'double':
        return (
          <CollapsedTab title={title} key={id}>
            <Styled.DropDownWrapper>
              <CheckedList
                onChange={handleChangeCheckbox}
                name={FILTER_FIELDS.SURVEY_WHY}
                options={[FILTER_OPTIONS.SURVEY_WHY_OPTIONS[0]]}
              />
              {completedStatus && (
                <>
                  <span>with {filterType.toLowerCase()} component status: </span>
                  <FilterDropdown
                    options={DROPDOWN_OPTIONS}
                    inputId={completedStatus}
                    zIndex={isDropActive ? 3 : -1}
                    onChange={(data) => {
                      handleChangeQueryFiltersShowDropDown(completedStatus, data);
                    }}
                    value={DROPDOWN_VALUES[shadowFilters[completedStatus]] || 'All'}
                  />
                </>
              )}
            </Styled.DropDownWrapper>
            {collapsed.map(
              (elem) =>
                elem.isShow && (
                  <CheckedList
                    name={elem.field}
                    options={elem.options}
                    withSelectAll="Survey was not performed"
                    isVisitStatus
                  />
                ),
            )}
          </CollapsedTab>
        );
      default:
        return null;
    }
  };

  return (
    <Styled.Wrapper>
      <FormGroupWrapper>
        {ENFORCEMENT_QUICK_QUERY.map((item) =>
          getElement(
            item.title,
            item.field,
            item.options,
            item.type,
            item.id,
            item?.subTitle,
            item?.collapsed,
            item?.isShow,
          ),
        )}
      </FormGroupWrapper>
    </Styled.Wrapper>
  );
};

VisitDateAgencyStatus.propTypes = {
  filterType: PropTypes.string.isRequired,
};
