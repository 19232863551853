/* eslint-disable react/prop-types */
import React from 'react';
import { uniqueId } from 'lodash';
import { RemoveAllOptions, WrapperADAText } from 'ui-kit/BaseSelect/styles';
import { Img } from 'ui-kit';
import { REMOVE_ICON } from 'assets/icons';

const CustomRemoveAllOptions = ({ setIsFocus, ...props }) => {
  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      setIsFocus(true);
      props.clearValue();
    }
  };

  const { clearValue } = props;

  return (
    <RemoveAllOptions {...props} onClick={clearValue} tabIndex={0} id={uniqueId()} onKeyDown={handleKeyDown}>
      <Img src={REMOVE_ICON} size={[12, 12]} />
      <WrapperADAText>Clear button</WrapperADAText>
    </RemoveAllOptions>
  );
};

export default CustomRemoveAllOptions;
