import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIsReadyAndOffline } from 'hooks';
import { AuthEntity, CollectEntity, VisitEntity, FilterEntity } from '_entities';
import { PageContainer, OnlineIndicator } from 'modules';
import { Loader, Button } from 'ui-kit';
import ExportDataDropDown from 'ui-kit/old/ExportDataDropDown';
import { EXPORT_DATA_OPTIONS_NAMES, EXPORT_RETAILERS_VISITS_DATA_OPTIONS } from '_constants/old/_constants/EXPORT_DATA';
import { newSavePageToPdf } from 'utils/custom';
import { BUTTON_TYPES, DEFAULT_SORT, MESSAGES, PAGE_TYPES } from '_constants';
import { SetQueryParams } from 'utils/old/setQueryParams';
import { WHITE_CHECK, RETURN } from 'assets/icons';
import processQSParams from 'utils/old/processQSParams';
import { notification, idbService } from 'utils/services';
import { useHistory, useLocation } from 'react-router';
import { SurveyList, RetailerList } from './components';
import * as Styled from './styles';

const { getAssignmentsList, getRevisitsCSV, getAssignmentsCSV } = CollectEntity.actions;
const { getOfflineAssigmentsList, setCollectCount } = CollectEntity.actions;
const { getProfileData } = AuthEntity.actions;
const { createVisit } = VisitEntity.actions;
const { applyFilters } = FilterEntity.actions;

const getForms = (result) => {
  const filledForms = result.included.filter((item) => item.type === 'Form');
  const toReturn = filledForms.map((form) => {
    const assignment = result.data.find((item) => {
      return item.relationships.form && item.relationships.form?.data.id === form.id;
    });
    const campaign = result.included.find(
      (item) => item.type === 'Campaign' && item.id === assignment.relationships.campaign.data.id,
    );
    return {
      data: { ...form },
      included: [
        {
          attributes: {
            name: campaign.attributes.agency_name,
          },
          id: form.relationships.agency.data.id,
          type: 'Agency',
        },
      ],
    };
  });
  return toReturn;
};

function AssignmentsList() {
  const [loading, setLoading] = useState(false);
  const [exportValue, setExportValue] = useState(null);
  const dispatch = useDispatch();
  const isReadyAndOffline = useIsReadyAndOffline();
  const [readyForOffline, setReadyForOffline] = useState(false);
  const [surveyOfflineData, setSurveyOfflineData] = useState([]);

  const history = useHistory();
  const { search } = useLocation();

  const handleExportDataChange = async ({ value }) => {
    setExportValue(value);

    try {
      switch (value) {
        case EXPORT_DATA_OPTIONS_NAMES.OPEN_ASSIGNMENTS:
          await dispatch(getAssignmentsCSV(search));
          notification.success(MESSAGES.COLLECT.SUCCESS);
          break;
        case EXPORT_DATA_OPTIONS_NAMES.REVISITS_NEEDED:
          await dispatch(getRevisitsCSV(search));
          notification.success(MESSAGES.COLLECT.SUCCESS);
          break;
        case EXPORT_DATA_OPTIONS_NAMES.PRINT:
          setLoading(true);
          // In order to show loader before html2canvas function start working we should wrap function in setTimeout
          setTimeout(() => newSavePageToPdf({ setExportLoading: setLoading, hasFilters: true }, PAGE_TYPES.COLLECT), 0);
          break;
        default:
      }
    } catch (err) {
      notification.error(MESSAGES.VISIT.VIOLATION_PDF.ERROR);
      console.log(err);
    }
  };

  const buttonState = {
    true: {
      icon: WHITE_CHECK,
      text: 'Ready to go offline',
    },
    false: {
      icon: RETURN,
      text: 'Use offline',
    },
  };

  const handleOnlineSwitch = async () => {
    if (!readyForOffline) {
      setLoading(true);
      await dispatch(getOfflineAssigmentsList()).then(async (result) => {
        // eslint-disable-next-line no-shadow
        const saveToLocalDb = async (result) => {
          const additionalData = 'additional_data';
          const assignmentsTable = 'assignments';
          const profileTable = 'profile';
          const readyTable = 'ready';
          const retailersTable = 'retailers';
          const formsTable = 'forms';
          const formsData = getForms(result);
          const retailerData = result.included
            .filter((item) => item.type === 'Retailer')
            .map((item) => {
              return { data: item };
            });
          const profileInfo = await dispatch(getProfileData());
          const db = await idbService.initiateIDB();
          const tx = db.transaction(
            [additionalData, assignmentsTable, profileTable, readyTable, formsTable, retailersTable],
            'readwrite',
          );
          const additionalDataStore = await tx.objectStore(additionalData);
          const formsDataStore = await tx.objectStore(formsTable);
          const retailersDataStore = await tx.objectStore(retailersTable);
          const store = await tx.objectStore(assignmentsTable);
          const userStore = await tx.objectStore(profileTable);
          const readyStore = await tx.objectStore(readyTable);
          await Promise.all(result.included.map(async (item) => additionalDataStore.put(item, item.id)));
          await Promise.all(formsData.map(async (item) => formsDataStore.put(item, item.data.id)));
          await Promise.all(retailerData.map(async (item) => retailersDataStore.put(item, item.data.id)));
          await store.put(result, 'response');
          await userStore.put(profileInfo, 'profile');
          await readyStore.put({ isReady: true, readyAt: new Date() }, 'ready');
          await tx.done;
          setReadyForOffline(true);
          setLoading(false);
        };
        await saveToLocalDb(result);
      });
    }
  };

  const getOfflineSurveys = async () => {
    const db = await idbService.initiateIDB();
    const keys = await db.transaction('surveys').objectStore('surveys').getAllKeys();
    const result = await Promise.all(
      keys.map(async (item) => {
        const value = await db.transaction('surveys').objectStore('surveys').get(item);
        return {
          text: item,
          data: value,
        };
      }),
    );
    return result;
  };

  // TODO: this function will be moved to utils function on next release, to do
  const updateOfflineSurveyCount = async () => {
    const db = await idbService.initiateIDB();
    const count = await db.transaction('surveys').objectStore('surveys').count();
    await dispatch(setCollectCount(count));
  };

  const handleSendSurvey = async (key, data) => {
    try {
      setLoading(true);
      const db = await idbService.initiateIDB();
      await dispatch(createVisit(data));
      await db.transaction('surveys', 'readwrite').objectStore('surveys').delete(key);
      const newOfflineSurveys = await getOfflineSurveys();
      setSurveyOfflineData(newOfflineSurveys);
      await resetAssigmentsTableData();
      updateOfflineSurveyCount();
      notification.success(MESSAGES.VISIT.CREATE.SUCCESS);
    } catch (err) {
      setLoading(false);
      notification.error(MESSAGES.COMMON_ERROR);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSurvey = async (key) => {
    const db = await idbService.initiateIDB();
    await db.transaction('surveys', 'readwrite').objectStore('surveys').delete(key);
    const newOfflineSurveys = await getOfflineSurveys();
    setSurveyOfflineData(newOfflineSurveys);
    await resetAssigmentsTableData();
    updateOfflineSurveyCount();
    notification.success(MESSAGES.VISIT.DELETE.SUCCESS);
  };

  const resetAssigmentsTableData = async () => {
    const assignmentsParams = processQSParams({
      page: 1,
      perPage: 20,
      sortBy: DEFAULT_SORT.retailerVisits.prop,
      sortOrder: DEFAULT_SORT.retailerVisits.order,
    });
    await dispatch(getAssignmentsList(assignmentsParams));
  };

  // setup initial redux state for manage assignment page
  useEffect(() => {
    SetQueryParams({
      queryString: {
        page: 1,
        page_size: 20,
      },
      history,
    });
    dispatch(applyFilters({ page: 1, page_size: 20 }));
  }, []);

  useEffect(() => {
    (async () => {
      // check if we already have some data in indexdb so we can set state of the button
      try {
        const db = await idbService.initiateIDB();
        const readyData = await db.transaction('ready').objectStore('ready').get('ready');
        setReadyForOffline(!!readyData?.isReady);
      } catch (e) {
        setReadyForOffline(false);
      }
      const newOfflineSurveys = await getOfflineSurveys();
      const baseAssignmentsParams = {
        page: 1,
        perPage: 20,
        sortBy: DEFAULT_SORT.retailerVisits.prop,
        sortOrder: DEFAULT_SORT.retailerVisits.order,
      };
      if (newOfflineSurveys.length > 0) {
        const offlineSurveyList = newOfflineSurveys
          .map((item) => item.data.data.relationships.assignment.data.id)
          .join(',');
        baseAssignmentsParams.exclude = offlineSurveyList;
      }
      const assignmentsParams = processQSParams(baseAssignmentsParams);
      try {
        const offlineSurveysData = await getOfflineSurveys();
        setSurveyOfflineData(offlineSurveysData);
        if (isReadyAndOffline) {
          dispatch(getAssignmentsList(assignmentsParams));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(() => false);
      }
    })();
  }, []);

  return (
    <PageContainer>
      {loading && <Loader />}
      <Styled.Wrapper>
        <Styled.TopLine className="table-header">
          <Styled.TopLineLeft>
            <Styled.Title>Assignments</Styled.Title>
            <OnlineIndicator />
          </Styled.TopLineLeft>
          {!isReadyAndOffline && (
            <Styled.TopLineRight>
              <ExportDataDropDown
                options={EXPORT_RETAILERS_VISITS_DATA_OPTIONS}
                onChange={handleExportDataChange}
                value={exportValue}
                data-html2canvas-ignore
              />
              <Button
                onClick={handleOnlineSwitch}
                icon={buttonState[readyForOffline].icon}
                text={buttonState[readyForOffline].text}
                variant={BUTTON_TYPES.DARK}
                data-html2canvas-ignore
              />
            </Styled.TopLineRight>
          )}
        </Styled.TopLine>
        <SurveyList
          data={surveyOfflineData}
          showSyncButton={!isReadyAndOffline}
          handleSendSurvey={handleSendSurvey}
          handleDeleteSurvey={handleDeleteSurvey}
        />
        <RetailerList setLoading={setLoading} />
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default AssignmentsList;
