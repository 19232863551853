import { setIsNotification } from '_entities/old/Retailers/retailers.action';
import { notification } from 'utils/services';
import { MESSAGES } from '_constants';
import { getAuditCsv, postAuditRevert } from './Audit.actions';

const auditCSV = async (dispatch, queryParams, getToast) => {
  try {
    dispatch(setIsNotification(false));
    const response = await dispatch(getAuditCsv(queryParams));
    getToast(response);
    dispatch(setIsNotification(true));
  } catch (e) {
    console.log(e);
  }
};

const auditRevert = async (dispatch, id, setFocus, getToastRevert) => {
  try {
    dispatch(setIsNotification(false));
    const response = await dispatch(postAuditRevert(id));
    getToastRevert(response.data.data);
    dispatch(setIsNotification(true));
  } catch (e) {
    console.log(e);
    notification.error(e?.response?.data?.errors[0]?.detail || MESSAGES.VISIT.VIOLATION_PDF.ERROR);
  } finally {
    setFocus();
  }
};

export { auditCSV, auditRevert };
