import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'ui-kit';
import { removeToken } from 'utils/services/token';
import { BUTTON_TYPES, URLS } from '_constants';
import { useDispatch } from 'react-redux';
import { signOut } from '_entities/Auth/Auth.actions';
import * as Styled from './styles';

function LogOutPopup({ isOpenPopup, setIsOpenPopup }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setIsOpenPopup(false);
  }, [setIsOpenPopup]);

  const handleLogOut = async () => {
    try {
      await dispatch(signOut());
    } catch (e) {
      console.log(e);
    } finally {
      removeToken();
      history.push(URLS.login);
    }
  };

  return (
    <Modal open={isOpenPopup} onClose={() => setIsOpenPopup(false)}>
      <Styled.ModalTitle>Are you sure you want to log out?</Styled.ModalTitle>
      <Styled.ButtonWrapper>
        <Button onClick={handleClose} type="button" text="Cancel" variant={BUTTON_TYPES.DARK} />
        <Button onClick={handleLogOut} type="button" text="Log out" variant={BUTTON_TYPES.DANGER} />
      </Styled.ButtonWrapper>
    </Modal>
  );
}

LogOutPopup.propTypes = {
  isOpenPopup: PropTypes.bool,
  setIsOpenPopup: PropTypes.func,
};

LogOutPopup.defaultProps = {
  isOpenPopup: false,
  setIsOpenPopup: () => {},
};

export default LogOutPopup;
