import React from 'react';
import { Img } from 'ui-kit';
import { LOADER_ICON } from 'assets/icons';
import * as Styled from './styles';

const Loader = () => (
  <Styled.Wrapper data-html2canvas-ignore>
    <Styled.Container>
      <Img src={LOADER_ICON} alt="loader" />
    </Styled.Container>
  </Styled.Wrapper>
);

export default Loader;
