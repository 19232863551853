import * as TYPES from './filter.types';

export const setShadowFilter = (payload) => ({
  type: TYPES.SET_SHADOW_FILTER,
  payload,
});

export const applyFilters = (payload) => ({
  type: TYPES.APPLY_FILTERS,
  payload,
});

export const setQueryData = (payload) => ({
  type: TYPES.SET_QUERY_DATA,
  payload,
});
export const setWasSearch = (payload) => ({
  type: TYPES.SET_WAS_SEARCH,
  payload,
});
export const setVisitPath = (payload) => ({
  type: TYPES.SET_VISIT_PATH,
  payload,
});

export const setIsVisitBack = (payload) => ({
  type: TYPES.SET_IS_VISIT_BACK,
  payload,
});

export const setFilterError = (payload) => ({
  type: TYPES.SET_FILTER_ERROR,
  payload,
});

export const cancelFilters = () => ({
  type: TYPES.CANCEL_FILTERS,
});

export const clearAllFilters = () => ({
  type: TYPES.CLEAR_ALL_FILTERS,
});

export const getJurisdictions = () => ({
  type: [TYPES.GET_JURISDICTIONS, TYPES.GET_JURISDICTIONS_SUCCESS, TYPES.GET_JURISDICTIONS_FAIL],
  promise: (client) => client.get('/sec-geogs'),
});

export const getAgencies = () => ({
  type: [TYPES.GET_AGENCIES, TYPES.GET_AGENCIES_SUCCESS, TYPES.GET_AGENCIES_FAIL],
  promise: (client) => client.get('/agencies/agencies_dropdown'),
});
