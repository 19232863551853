export const FORM_ACTION_TYPES = {
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  SET_INITIAL_ERROR: 'SET_INITIAL_ERROR',
  SET_YOUTH_DATA: 'SET_YOUTH_DATA',
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
};

export const formNames = {
  submitted: 'submitted',
  submittedTime: 'submitted_time',
  youthId: 'youth_id',
  youthGender: 'youth_gender',
  youthBirthdate: 'youth_birthdate',
};

export const FORM_REQUIRED_FIELDS = [formNames.submitted, formNames.submittedTime];

export const formInitialState = {
  data: {},
  errors: {
    [formNames.submitted]: false,
    [formNames.submittedTime]: false,
  },
  canBeSubmitted: true,
};

export const INPUT_TYPES = {
  TEXT: 'text',
  RADIOGROUP: 'radiogroup',
  HTML: 'html',
  CHECKBOX: 'checkbox',
  COMMENT: 'comment',
  FILE: 'file',
  DROPDOWN: 'dropdown',
  NUMBER: 'number',
};

export const VISIT_TYPES = {
  ENFORCEMENT: 'enforcement',
  EDUCATION: 'education',
  ASSESSMENT: 'assessment',
};

export const PAGE_COLUMNS = 'one_column_with_submission';
export const SUBMISSION_COLUMN = 'submission_column';
