import styled from 'styled-components';
import { Container } from 'ui-kit/Loader/styles';

export const Wrapper = styled.div`
  padding: 10px 20px;
  font-size: 14px;
`;

export const FiltersContainer = styled.div`
  margin-top: 25px;
`;

export const FiltersTitle = styled.span`
  font-size: 14px;
  margin: 20px 0;
  display: block;
  margin-left: ${({ isSubcategory }) => (isSubcategory ? '30px' : '0px')};
`;

export const SelectWrapper = styled.div`
  margin-top: 20px;
`;
export const FilterContainer = styled.div`
  ${Container} {
    left: 35%;
  }
`;
