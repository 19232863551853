import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { CHIPS_TYPES, DROPDOWN_DATA_TYPES } from 'modules/Modals/old/FilterModal/components/ChipsList/constants';

const filterMapping = {
  [FILTER_FIELDS.MY_GEOGS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.MY_GEOGS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SEC_GEOGS]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.JURISDICTIONS,
    },
    title: 'User location',
  },
};

export default filterMapping;
