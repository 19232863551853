import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { TableSizeControls } from 'ui-kit';
// import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
// import processQSParams from 'utils/old/processQSParams';
import VisitTableBase from './components/VisitTableBase';
import * as Styled from './styles';
import { COLUMNS, INITIAL_COLUMNS } from './components/VisitTableBase/constants';

function VisitTable({ getInitialColumns, selector, caption }) {
  const { data } = useSelector(selector);

  return (
    <Styled.Wrapper>
      <VisitTableBase
        getInitColumns={getInitialColumns}
        data={data}
        initialColumns={INITIAL_COLUMNS}
        columns={COLUMNS}
      />
      <Styled.P>{caption}</Styled.P>
    </Styled.Wrapper>
  );
}

VisitTable.propTypes = {
  getInitialColumns: PropTypes.object.isRequired,
  selector: PropTypes.func.isRequired,
  caption: PropTypes.string.isRequired,
};

export default VisitTable;
