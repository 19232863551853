/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuItem from './menuItem';
import * as Styled from './styles';

function RouteListItems({ subRoutes, setIsOpenDropDown, isOpenDropDown }) {
  const [subHeaderFocus, setSubHeaderFocus] = useState(1);

  const tabsRefs = subRoutes.reduce((acc, item, index) => ({ ...acc, [index + 1]: createRef() }), {});
  useEffect(() => {
    isOpenDropDown && tabsRefs[1]?.current?.focus();
  }, [isOpenDropDown]);

  const handleNextTab = (firstTabInRound, nextTab, lastTabInRound) => {
    const tabToSelect = subHeaderFocus === lastTabInRound ? firstTabInRound : nextTab;
    setSubHeaderFocus(tabToSelect);
    tabsRefs[tabToSelect].current.focus();
  };

  const handleKeyPress = (e) => {
    const tabCount = subRoutes.length;

    if (e.keyCode === 38) {
      e.preventDefault();
      const last = tabCount;
      const next = subHeaderFocus - 1;
      handleNextTab(last, next, 1);
    }
    if (e.keyCode === 40) {
      e.preventDefault();
      const first = 1;
      const next = subHeaderFocus + 1;
      handleNextTab(first, next, tabCount);
    }
    if ([37, 39].includes(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <>
      <Styled.DropDownContent isDropDownOpen={isOpenDropDown} onKeyDown={handleKeyPress}>
        {subRoutes.map(
          (subRoute, index) =>
            subRoute.isShow && (
              <MenuItem
                subRoute={subRoute}
                key={subRoute.id}
                setIsOpenDropDown={setIsOpenDropDown}
                index={index + 1}
                tabRef={tabsRefs[index + 1]}
                subHeaderFocus={subHeaderFocus}
              />
            ),
        )}
      </Styled.DropDownContent>
    </>
  );
}

RouteListItems.propTypes = {
  subRoutes: PropTypes.array.isRequired,
  setIsOpenDropDown: PropTypes.func.isRequired,
  isOpenDropDown: PropTypes.bool.isRequired,
};

export default RouteListItems;
