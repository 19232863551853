import { lt, gt, isNull } from 'lodash';
import { COMMON_ERRORS } from '_constants/errors';
import { hasFalsyValue, isValidZipCode } from '..';

/**
 * Validate zipCode object and returns error string
 * @param  {string} {value
 * @param  {string} name='zipCode'
 * @param  {boolean} required=true}
 * @param  {number} min=null
 * @param  {number} max=null
 *
 * @returns {string} error
 */
export default ({ value, name = 'email', required = false, max, min }) => {
  let error =
    (hasFalsyValue(value) && COMMON_ERRORS.isRequired(name)) ||
    (!isValidZipCode(value) && COMMON_ERRORS.invalidZipCode) ||
    (!isNull(min) && lt(value.length, min) && COMMON_ERRORS.lessThan(name, min)) ||
    (!isNull(max) && gt(value.length, max) && COMMON_ERRORS.moreThan(name, max));
  error = !required && hasFalsyValue(value) ? null : error;

  return error;
};
