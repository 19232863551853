export default {
  login: '/login',
  dashboard: '/dashboard',
  policy: '/policy',
  assignments: '/assignments',
  campaign_list: '/campaign-list',
  retailers: '/retailers',
  storeMerger: '/store-merger',
  assessments: '/assessment',
  education: '/education',
  enforcement: '/enforcement',
  reports: '/reports',
  venues: '/venues',
  youth: '/youth',
  auditLogs: '/audit-logs',
  snapshotMaps: '/snapshot-maps',
  disparateBurden: '/infographics/disparateburden',
  enforcementOverview: '/infographics/enforcementoverview',
  posLandscape: '/infographics/poslandscape',
  retailerReduction: '/infographics/retailerreduction',
  youthAppeal: '/infographics/youthappeal',
  users: '/users',
  logout: '/logout',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  setNewPassword: '/set-new-password',
  linkExpiration: '/password-reset',
  campaign: '/campaign',
  survey: '/survey',
  notFound: '/not-found',
  snapshot: {
    retailerDensity: '/snapshot-maps/retailer-density',
    policyTester: '/snapshot-maps/policy-tester',
    schools: '/snapshot-maps/schools',
    productAvailability: '/snapshot-maps/product-availability',
    productPromotions: '/snapshot-maps/product-promotions',
    productPrice: '/snapshot-maps/product-price',
    education: '/snapshot-maps/education',
    enforcement: '/snapshot-maps/enforcement',
  },
  retailersPages: {
    add: '/retailers-add',
    edit: '/retailers-edit',
    view: '/retailers/:id',
  },
  enforcementPages: {
    view: '/enforcement/:id',
    edit: '/enforcement/edit',
    add: '/enforcement/add',
    violationsEdit: '/enforcement/edit/violations',
  },
  assessmentsPages: {
    view: '/assessment/:id',
    edit: '/assessment/edit',
    add: '/assessment/add',
    violationsEdit: '/assessment/edit/violations',
  },
  educationPages: {
    view: '/education/:id',
    edit: '/education/edit',
    add: '/education/add',
    violationsEdit: '/education/edit/violations',
  },
  campaignPages: {
    view: '/campaign/:id',
    edit: '/campaign-edit',
    add: '/campaign-add',
    manageAssignments: '/manage-assignments',
  },
  userPages: {
    view: '/users/:id',
    edit: '/users-edit',
    add: '/users-add',
  },
  youthPages: {
    add: '/youth-add',
    edit: '/youth-edit',
    view: '/youth/:id',
  },
  venuesPages: {
    add: '/venues-add',
    edit: '/venues-edit',
    view: '/venues/:id',
  },
  reportsPages: {
    area: '/reports/area',
    enforcement: '/reports/enforcement',
    education: '/reports/education',
  },
};
