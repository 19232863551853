/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-day-picker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FILTER_OPTIONS, FILTER_RETAILERS, FILTER_FIELDS } from '_constants/old/filters';
import RadioButtonsOrganizer from 'ui-kit/old/radioButton/radioButtonsOrganizer';
import { OldFilterEntity, NewFiltersEntity } from '_entities';
import { reduce } from 'lodash';
import { FormGroupWrapper } from 'modules/Modals/old/FilterModal/components/FormGroupWrapper';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import FilterDropdown from 'modules/Modals/old/FilterModal/components/FilterDropdown';
import { BaseSelect } from 'ui-kit';
import DatePickerGroup from 'modules/Modals/old/FilterModal/components/DatePickerGroup';
import * as Styled from './styles';

const { setShadowFilter } = OldFilterEntity.actions;
const { getVisitsAgency } = NewFiltersEntity.actions;
const { getAgencyOptions } = NewFiltersEntity.selectors;

const DROPDOWN_OPTIONS = Array.from({ length: 9 }, (_, index) => ({
  label: (index + 1).toString(),
  value: (index + 1).toString(),
}));

export const RetailersQuickQuery = () => {
  const {
    filter: { shadowFilters, generalFilters },
  } = useSelector((state) => state);
  const [isNoVisitDataRange, setIsNoVisitDataRange] = useState(
    shadowFilters?.[FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL] === 'date',
  );
  const [defaultValue, setDefaultValue] = useState({ [FILTER_FIELDS.AGENCY_ID]: [] });
  const [selectValue, setSelectValue] = useState({});
  const agencyOptions = useSelector(getAgencyOptions);
  const dispatch = useDispatch();

  const handleChangeSelect = (value, field) => {
    setSelectValue({ ...selectValue, [field]: value });
    dispatch(setShadowFilter({ ...shadowFilters, [field]: value.map((elem) => elem.value) }));
  };

  useEffect(() => {
    dispatch(getVisitsAgency());
  }, []);

  const filters = {
    [FILTER_FIELDS.TOBACCO]: null,
    [FILTER_FIELDS.ALCOHOL]: null,
    [FILTER_FIELDS.NEAR_SCHOOL]: null,
    [FILTER_FIELDS.NO_VISIT_TYPE]: null,
    [FILTER_FIELDS.NO_VISIT_TYPE_TYPE]: null,
    [FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE]: null,
    [FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL]: null,
    [FILTER_FIELDS.VIOLATION_COUNT_GRT]: null,
    [FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT]: null,
  };

  const noVisitType = shadowFilters[FILTER_FIELDS.NO_VISIT_TYPE_TYPE];
  const noVisitDateLabel = shadowFilters[FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL];
  const noVisitDateRange = shadowFilters[FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE];

  const handleIsNoVisitDateRage = () => {
    setIsNoVisitDataRange(true);
  };

  const handleDefaultDateRanges = () => {
    setIsNoVisitDataRange(false);
  };

  // DropDown Handlers
  const handleChangeQueryFiltersShowDropDown = (field, { value }) => {
    const newFilters = { ...shadowFilters };

    switch (field) {
      case FILTER_FIELDS.VIOLATION_COUNT_GRT:
        handleDefaultDateRanges();
        dispatch(
          setShadowFilter({
            ...newFilters,
            ...{ [FILTER_FIELDS.VIOLATION_COUNT_GRT]: value },
          }),
        );
        break;
      case FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT:
        handleDefaultDateRanges();
        dispatch(
          setShadowFilter({
            ...newFilters,
            ...{ [FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT]: value },
          }),
        );
        break;
      case FILTER_FIELDS.NO_VISIT_TYPE_TYPE:
        {
          const queryVisitType = FILTER_OPTIONS.RETAILER_VISIT_TYPE.find((item) => item.value === value).id || 'any';
          const queryValue = `${queryVisitType},${noVisitDateRange || 'ever'}`;
          dispatch(
            setShadowFilter({
              ...newFilters,
              ...{ [FILTER_FIELDS.NO_VISIT_TYPE_TYPE]: value },
              ...{ [FILTER_FIELDS.NO_VISIT_TYPE]: queryValue },
            }),
          );
        }
        break;
      case FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL:
        {
          dispatch(
            setShadowFilter({
              ...newFilters,
              ...{ [FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL]: value },
            }),
          );
          if (value === 'date') {
            handleIsNoVisitDateRage();
          } else {
            handleDefaultDateRanges();
            dispatch(
              setShadowFilter({
                ...newFilters,
                ...{ [FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE]: null },
                ...{ [FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL]: value },
              }),
            );
          }
        }
        break;
      default:
        break;
    }
  };

  const RADIO_BUTTONS = [
    {
      value: FILTER_RETAILERS.RETAILERS_QUICK_QUERY_MAPPING[FILTER_FIELDS.TOBACCO],
      checked: shadowFilters[FILTER_FIELDS.TOBACCO] === 't',
    },
    {
      value: FILTER_RETAILERS.RETAILERS_QUICK_QUERY_MAPPING[FILTER_FIELDS.ALCOHOL],
      checked: shadowFilters[FILTER_FIELDS.ALCOHOL] === 't',
    },
    {
      value: FILTER_RETAILERS.RETAILERS_QUICK_QUERY_MAPPING[FILTER_FIELDS.NEAR_SCHOOL],
      checked: shadowFilters[FILTER_FIELDS.NEAR_SCHOOL] === 't',
    },
    {
      value: FILTER_RETAILERS.RETAILERS_QUICK_QUERY_MAPPING[FILTER_FIELDS.NO_VISIT_TYPE].split(',')[0],
      valueEnd: FILTER_RETAILERS.RETAILERS_QUICK_QUERY_MAPPING[FILTER_FIELDS.NO_VISIT_TYPE].split(',')[1],
      checked: shadowFilters[FILTER_FIELDS.NO_VISIT_TYPE],
      component: (
        <FilterDropdown
          options={FILTER_OPTIONS.RETAILER_VISIT_TYPE}
          inputId={FILTER_FIELDS.NO_VISIT_TYPE_TYPE}
          zIndex={3}
          onChange={(data) => {
            handleChangeQueryFiltersShowDropDown(FILTER_FIELDS.NO_VISIT_TYPE_TYPE, data);
          }}
          value={noVisitType}
        />
      ),
      componentEnd: (
        <FilterDropdown
          options={FILTER_OPTIONS.RETAILER_VISIT_DATE_RANGE}
          inputId={FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL}
          zIndex={3}
          onChange={(data) => {
            handleChangeQueryFiltersShowDropDown(FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL, data);
          }}
          value={noVisitDateLabel}
        />
      ),
    },
    {
      value: FILTER_RETAILERS.RETAILERS_QUICK_QUERY_MAPPING[FILTER_FIELDS.VIOLATION_COUNT_GRT],
      checked: shadowFilters[FILTER_FIELDS.VIOLATION_COUNT_GRT],
      component: (
        <FilterDropdown
          options={DROPDOWN_OPTIONS}
          inputId={FILTER_FIELDS.VIOLATION_COUNT_GRT}
          zIndex={3}
          onChange={(data) => {
            handleChangeQueryFiltersShowDropDown(FILTER_FIELDS.VIOLATION_COUNT_GRT, data);
          }}
          value={shadowFilters[FILTER_FIELDS.VIOLATION_COUNT_GRT]}
        />
      ),
    },
    {
      value: FILTER_RETAILERS.RETAILERS_QUICK_QUERY_MAPPING[FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT],
      checked: shadowFilters[FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT],
      component: (
        <FilterDropdown
          options={DROPDOWN_OPTIONS}
          inputId={FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT}
          onChange={(data) => {
            handleChangeQueryFiltersShowDropDown(FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT, data);
          }}
          value={shadowFilters[FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT]}
          zIndex={2}
        />
      ),
    },
  ];

  const handleRadioButton = (value) => {
    switch (value) {
      case FILTER_RETAILERS.RETAILERS_QUICK_QUERY_MAPPING[FILTER_FIELDS.NO_VISIT_TYPE].split(',')[0]:
        {
          filters[FILTER_FIELDS.NO_VISIT_TYPE_TYPE] = 'any type';
          filters[FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE_LABEL] = 'ever';
          filters[FILTER_FIELDS.NO_VISIT_TYPE] = 'any,ever';
        }
        break;
      default:
        handleDefaultDateRanges();
        break;
    }

    switch (value) {
      case FILTER_RETAILERS.RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_TOBACCO:
        filters[FILTER_FIELDS.TOBACCO] = 't';
        break;
      case FILTER_RETAILERS.RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_ALCOHOL:
        filters[FILTER_FIELDS.ALCOHOL] = 't';
        break;
      case FILTER_RETAILERS.RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_NEAR_SCHOOL:
        filters[FILTER_FIELDS.NEAR_SCHOOL] = 't';
        break;
      case FILTER_RETAILERS.RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_MORE_ENFORCEMENT_VIOLATION:
        filters[FILTER_FIELDS.VIOLATION_COUNT_GRT] = '1';
        break;
      case FILTER_RETAILERS.RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_LAST_YEAR_VIOLATIONS:
        filters[FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT] = '1';
        break;
      default:
        break;
    }
    dispatch(setShadowFilter({ ...shadowFilters, ...filters }));
  };

  useEffect(() => {
    let result = null;
    let visitType = noVisitType;
    let visitDateRange = noVisitDateRange || 'ever';
    if (visitType !== 'any') {
      visitType = FILTER_OPTIONS.RETAILER_VISIT_TYPE.find((item) => item.value === visitType)?.id || 'any';
    }
    if (visitDateRange === 'date') {
      visitDateRange = noVisitDateRange;
    }
    result = `${visitType},${visitDateRange}`;
    if (noVisitType === noVisitDateRange) {
      result = null;
    }
    dispatch(
      setShadowFilter({
        ...shadowFilters,
        ...{ [FILTER_FIELDS.NO_VISIT_TYPE]: result },
      }),
    );
  }, [noVisitType, noVisitDateLabel, noVisitDateRange]);

  const getDropDownValues = (agencyObject) => {
    return reduce(
      agencyObject,
      (acc, { value, label }) => {
        if (generalFilters?.agency_id?.includes(value)) return acc.concat({ value, label });
        return acc;
      },
      [],
    );
  };

  useEffect(() => {
    setDefaultValue({
      [FILTER_FIELDS.AGENCY_ID]: getDropDownValues(agencyOptions),
    });
  }, [generalFilters, agencyOptions]);

  return (
    <Styled.Wrapper>
      <FormGroupWrapper>
        <FormGroup title="Show">
          <RadioButtonsOrganizer
            hideResetButton
            radioButtons={RADIO_BUTTONS}
            onChange={({ value }) => handleRadioButton(value)}
            name="sellsTobacco"
            isColumn
          />

          {isNoVisitDataRange && <DatePickerGroup name={FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE} title="" />}
        </FormGroup>

        <FormGroup title="Status">
          <CheckedList name={FILTER_FIELDS.IS_CURRENT} options={FILTER_OPTIONS.BUSINESS_OPTIONS} />
        </FormGroup>

        <FormGroup>
          <BaseSelect
            onChange={(value) => {
              handleChangeSelect(value, FILTER_FIELDS.AGENCY_ID);
            }}
            isMulti
            labelText="Visits by Agency"
            name={FILTER_FIELDS.AGENCY_ID}
            options={agencyOptions}
            value={
              selectValue.hasOwnProperty(FILTER_FIELDS.AGENCY_ID)
                ? selectValue[FILTER_FIELDS.AGENCY_ID]
                : defaultValue[FILTER_FIELDS.AGENCY_ID]
            }
          />
        </FormGroup>
      </FormGroupWrapper>
    </Styled.Wrapper>
  );
};
