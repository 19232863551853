import React from 'react';
import { URLS } from '_constants';
import { VisitEdit } from 'modules';

function EducationEdit() {
  return (
    <VisitEdit
      headerTitle="Edit education visit"
      sectionTitle="Education information"
      editViolationUrl={URLS.educationPages.violationsEdit}
      submitUrl={URLS.education}
    />
  );
}

export default EducationEdit;
