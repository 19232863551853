import * as actions from './Campaign.actions';
import * as types from './Campaign.types';
import * as selectors from './Campaign.selectors';
import reducer from './Campaign.reducer';

export default {
  actions,
  reducer,
  types,
  selectors,
};
