/* eslint-disable no-fallthrough */
import React from 'react';
import { FILTER_TYPES } from '_constants/old/filters';
import UsersQuickQuery from 'pages/User/List/Filters/UsersQuickQuery';
import { RetailersQuickQuery } from 'pages/old/RetailerList/filters/RetailersQuickQuery';
import { VisitsQuickQuery } from 'modules/VisitsListPage/Filters/VisitsQuickQuery';
import { VenuesQuickQuery } from 'pages/Venues/List/Filters/VenuesQuickQuery';
import { AuditLogsQuickQuery } from 'pages/Audit/old/filters/AuditLogsQuickQuery';

const QuickQuery = ({ filterType }) => {
  const displayContent = () => {
    switch (filterType) {
      case FILTER_TYPES.USERS:
        return <UsersQuickQuery />;
      case FILTER_TYPES.RETAILERS:
        return <RetailersQuickQuery />;
      case FILTER_TYPES.AUDIT_LOGS:
        return <AuditLogsQuickQuery />;
      case FILTER_TYPES.ENFORCEMENT:
        return <VisitsQuickQuery />;
      case FILTER_TYPES.ASSESSMENT:
        return <VisitsQuickQuery />;
      case FILTER_TYPES.EDUCATION:
        return <VisitsQuickQuery />;
      case FILTER_TYPES.VENUES:
        return <VenuesQuickQuery />;
      default:
        return null;
    }
  };

  return displayContent();
};

export default QuickQuery;
