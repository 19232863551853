import { lt, gt, isNull } from 'lodash';
import { COMMON_ERRORS } from '_constants/errors';
import { hasFalsyValue, isValidEmail } from '..';

/**
 * Validate email object and returns error string
 * @param  {string} {value
 * @param  {string} name='email'
 * @param  {boolean} required=false}
 * @param  {number} min=null
 * @param  {number} max=null
 *
 * @returns {string} error
 */
// NOTE: These validation error messages used on Login and Reset Password page;
// TODO: Add props isLogins;
export default ({ value, name = 'email', required = false, max, min }) => {
  let error =
    ((hasFalsyValue(value) || !isValidEmail(value)) && COMMON_ERRORS.isInValidLogin(name)) ||
    (!isNull(min) && lt(value.length, min) && COMMON_ERRORS.lessThan(name, min)) ||
    (!isNull(max) && gt(value.length, max) && COMMON_ERRORS.moreThan(name, max));
  error = !required && hasFalsyValue(value) ? null : error;

  return error;
};
