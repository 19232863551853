import React, { useEffect, useState } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store, history } from 'lib';
import { Home } from 'pages';
import useOnlineStatus from '@rehooks/online-status';
import { AuthEntity } from '_entities';
import { URLS } from '_constants';
import { ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';
import { PrivateRoute } from './modules';
import { tokenService, idbService } from './utils/services';
import { Loader } from './ui-kit';
import 'assets/styles/reset.scss';
import 'assets/styles/fonts.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'assets/styles/react-toastify.scss';
import 'assets/styles/base.scss';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes';

const { getAppCanStart, getPermissionStatus } = AuthEntity.selectors;
const { getModularityPermissions } = AuthEntity.actions;

function App() {
  const [isAppLoading, setIsAppLoading] = useState(false);
  const dispatch = useDispatch();
  const isAppCanStart = useSelector(getAppCanStart);
  const isPermissionReady = useSelector(getPermissionStatus);
  const isOnline = useOnlineStatus();

  useEffect(() => {
    (async () => {
      try {
        setIsAppLoading(true);
        await tokenService.startApp();
      } catch (e) {
        tokenService.removeToken();
      } finally {
        setIsAppLoading(false);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getModularityPermissions());
      } catch (err) {
        history.push(URLS.notFound);
      }
    })();
  }, []);

  useEffect(() => {
    if (isOnline) {
      idbService.deleteIDB();
    }
  }, []);

  if (isAppLoading || !isAppCanStart || !isPermissionReady) return <Loader coverElement />;

  return (
    <>
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path="/" component={Home} />
            {PRIVATE_ROUTES.map((route) => (
              <PrivateRoute
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
                {...route}
              />
            ))}
            {PUBLIC_ROUTES.map((route) => (
              <Route key={route.path} exact={route.exact} path={route.path} component={route.component} {...route} />
            ))}
            <Redirect to={URLS.dashboard} />
          </Switch>
        </QueryParamProvider>
      </Router>
      <ToastContainer newestOnTop limit={5} />
    </>
  );
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default AppWrapper;
