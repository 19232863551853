import React from 'react';
import { FILTER_FIELDS } from '_constants/old/filters';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import { InputWrapper } from 'modules/Modals/old/FilterModal/components/InputWrapper';
import * as Styled from './styles';

export const EnforcementCharacteristics = () => {
  const FIELDS = [
    {
      id: 1,
      label: 'Retailer name',
      name: FILTER_FIELDS.VISIT_CHAR_RETAILER_NAME,
    },
    {
      id: 2,
      label: 'Retailer address',
      name: FILTER_FIELDS.VISIT_CHAR_RETAILER_ADDRESS,
    },
  ];

  return (
    <Styled.Wrapper>
      <FormGroup>
        <Styled.List>
          {FIELDS.map((field) => (
            <Styled.ListItem key={field.id}>
              <InputWrapper name={field.name} title={field.label} />
            </Styled.ListItem>
          ))}
        </Styled.List>
      </FormGroup>
    </Styled.Wrapper>
  );
};
