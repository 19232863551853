import styled from 'styled-components';
import { DEVICES } from '_constants';

export const ModalTitle = styled.h2`
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 15px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;

  > button {
    min-width: 132px;
    flex: 1 0;

    :first-child {
      margin-right: 10px;

      @media ${DEVICES.mobileXL} {
        margin: 0 10px 10px 0;
      }
    }

    @media ${DEVICES.mobileL} {
      min-width: 80px;
    }
  }
`;
