export const GET_RETAILER_COUNT = 'GET_RETAILER_COUNT';
export const GET_RETAILER_COUNT_SUCCESS = 'GET_RETAILER_COUNT_SUCCESS';
export const GET_RETAILER_COUNT_FAIL = 'GET_RETAILER_COUNT_FAIL';

export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_FAIL = 'ADD_CAMPAIGN_FAIL';

export const VIEW_CAMPAIGN = 'VIEW_CAMPAIGN';
export const VIEW_CAMPAIGN_SUCCESS = 'VIEW_CAMPAIGN_SUCCESS';
export const VIEW_CAMPAIGN_FAIL = 'VIEW_CAMPAIGN_FAIL';

export const GET_ASSIGNMENTS_LIST = 'GET_ASSIGNMENTS_LIST';
export const GET_ASSIGNMENTS_LIST_SUCCESS = 'GET_ASSIGNMENTS_LIST_SUCCESS';
export const GET_ASSIGNMENTS_LIST_FAIL = 'GET_ASSIGNMENTS_LIST_FAIL';

export const ADD_DATA_COLLECTOR = 'ADD_DATA_COLLECTOR';
export const ADD_DATA_COLLECTOR_SUCCESS = 'ADD_DATA_COLLECTOR_SUCCESS';
export const ADD_DATA_COLLECTOR_FAIL = 'ADD_DATA_COLLECTOR_FAIL';

export const EDIT_ASSIGNMENTS_LIST = 'EDIT_ASSIGNMENTS_LIST';
export const EDIT_ASSIGNMENTS_LIST_SUCCESS = 'EDIT_ASSIGNMENTS_LIST_SUCCESS';
export const EDIT_ASSIGNMENTS_LIST_FAIL = 'EDIT_ASSIGNMENTS_LIST_FAIL';

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAIL = 'DELETE_CAMPAIGN_FAIL';

export const REMOVE_ASSIGNMENTS = 'REMOVE_ASSIGNMENTS';
export const REMOVE_ASSIGNMENTS_SUCCESS = 'REMOVE_ASSIGNMENTS_SUCCESS';
export const REMOVE_ASSIGNMENTS_FAIL = 'REMOVE_ASSIGNMENTS_FAIL';

export const CAMPAIGN_RETAILERS = 'CAMPAIGN_RETAILERS';
export const CAMPAIGN_RETAILERS_SUCCESS = 'CAMPAIGN_RETAILERS_SUCCESS';
export const CAMPAIGN_RETAILERS_FAIL = 'CAMPAIGN_RETAILERS_FAIL';

export const DATA_COLLECTORS = 'DATA_COLLECTORS';
export const DATA_COLLECTORS_SUCCESS = 'DATA_COLLECTORS_SUCCESS';
export const DATA_COLLECTORS_FAIL = 'DATA_COLLECTORS_FAIL';

export const GET_MY_SEC_GEOGS = 'GET_MY_SEC_GEOGS';
export const GET_MY_SEC_GEOGS_SUCCESS = 'GET_MY_SEC_GEOGS_SUCCESS';
export const GET_MY_SEC_GEOGS_FAIL = 'GET_MY_SEC_GEOGS_FAIL';

export const GET_MANAGE_ASSIGNMENTS_LIST = 'GET_MANAGE_ASSIGNMENTS_LIST';
export const GET_MANAGE_ASSIGNMENTS_LIST_SUCCESS = 'GET_MANAGE_ASSIGNMENTS_LIST_SUCCESS';
export const GET_MANAGE_ASSIGNMENTS_LIST_FAIL = 'GET_MANAGE_ASSIGNMENTS_LIST_FAIL';

export const ARCHIVE_CAMPAIGN = 'ARCHIVE_CAMPAIGN';
export const ARCHIVE_CAMPAIGN_SUCCESS = 'ARCHIVE_CAMPAIGN_SUCCESS';
export const ARCHIVE_CAMPAIGN_FAIL = 'ARCHIVE_CAMPAIGN_FAIL';
