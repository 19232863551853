export default {
  TOBACCO: 'tobacco',
  DISPOSITION: 'disposition',
  SUSPESIONS_EVER: 'suspesions_ever',
  ENFORCEMENT_EVER: 'enforcement_ever',
  ENFORCEMENT_COUNT_GRT: 'enforcement_count_grt',
  HAS_HAD_VISITS_CHECKBOX: 'had_visits_in_date_range_checkbox',
  HAS_HAD_VISITS_DROP: 'had_visits_in_date_range_drop',
  HAS_HAD_VISITS_DATE: 'had_visits_in_date_range_date',
  HAS_HAD_VISITS: 'had_visits_in_date_range',
  VIOLATION_COUNT_GRT_DATA_RANGE: 'violation_count_grt_data_range',
  VIOLATION_COUNT_GRT_DATEPICKER_RANGE: 'violation_count_grt_datepicker_range',
  VIOLATION_COUNT_GRT: 'violation_count_grt',
  NO_ENFORCEMENT_DATE_RANGE: 'no_enforcements_date_range',
  ADDRESS: 'address',
  NAME: 'name',
  CITY: 'city',
  CITIES: 'cities',
  ZIPCODE: 'zipcode',
  Q_MHHINC: 'q_mhhinc',
  Q_PCTYOUTH: 'q_pctyouth',
  Q_PCTURBAN: 'q_pcturban',
  Q_PCTHISP: 'q_pcthisp',
  Q_PCTBLACK: 'q_pctblack',
  Q_PCTAMERIND: 'q_pctamerind',
  Q_PCTASHAWPI: 'q_pctashawpi',
  Q_PCTWHITE: 'q_pctwhite',
  Q_PCTOTHERRACE: 'q_pctotherrace',
  Q_PCTEDUCOLLEGEGRAD: 'q_pcteducollegegrad',
  Q_PCTEDUSOMECOLLEGE: 'q_pctedusomecollege',
  Q_PCTEDUHIGHTSCHOOL: 'q_pcteduhighschool',
  Q_PCTEDUNOHIGHTSCHOOL: 'q_pctedunohighschool',
  Q_EDATTAIN: 'q_edattain',
  Q_POPDENS: 'q_popdens',
  NEAR_SCHOOL: 'near_school',
  NEAR_RETAILER: 'near_retailer',
  PHARMACY: 'pharmacy',
  ENFORCEMENT_DATE: 'enforcement_date',
  INTERNET_SALES: 'internet_sales',
  LATEST_ENFORCEMENT_DATE: 'latest_enforcement_date',
  LATESTENFORCE_SURVEY_WHY: 'latestenforce_survey_why',
  LATESTENFORCE_ENFORCE_COMPLETED: 'latestenforce_enforce_completed',
  LATESTENFORCEMENT_VIOLATION: 'latestenforce_violation',
  LATESTENFORCE_CITATION_CLERK: 'latestenforce_citation_clerk',
  LATESTENFORCE_CITATION_RETAILER: 'latestenforce_citation_retailer',
  LATESTENFORCE_SALE_TO_MINOR: 'latestenforce_sale_to_minor',
  LATESTENFORCE_YOUTH_INVOLVED: 'latestenforce_youth_involved',
  FDA: 'fda',
  LATESTENFORCE_ID_BASED: 'latestenforce_id_based',
  LATESTENFORCE_ASK_AGE: 'latestenforce_ask_age',
  LATESTENFORCE_ASKED_FOR_ID: 'latestenforce_asked_for_id',
  LATESTENFORCE_PRESENTED_ID: 'latestenforce_presented_id',
  LATESTENFORCE_EXAMINED_ID: 'latestenforce_examined_id',
  LATESTENFORCE_AGE_VERIFY_EQUIP: 'latestenforce_age_verify_equip',
  LATESTENFORCE_AGE_VERIFY_USED: 'latestenforce_age_verify_used',
  LATESTENFORCE_TOBACCO_PROD: 'latestenforce_tobacco_prod',
  LATESTENFORCE_EMPLOYEE_GENDER: 'latestenforce_employee_gender',
  LATESTENFORCE_EMPLOYEE_AGE_RANGE: 'latestenforce_employee_age_range',
  INCORP_AREA: 'incorp_area',
  LOCATION_COUNTY: 'location_county',
  COUNTY: 'county',
  SUPERVISORY_DISTRICTS: 'supervisory_districts',
  SEC_GEOGS: 'sec_geogs',
  CREATION_DATE: 'creation_date',
  UPDATE_DATE: 'update_date',
  DATE_JOINED: 'date_joined',
  LAST_MODIFIED_DATE: 'last_modified_date',
  CLOSED_DATE: 'closed_date',
  MERGE_DATE: 'merge_date',
  G_PRECISION_GOOD: 'g_precision_good',
  ADDRESS_VALIDATED: 'address_validated',
  NEAR_SCHOOL500FT: 'near_school_500ft',
  NEAR_SCHOOL1000FT: 'near_school_1000ft',
  ACTIVE_USERS: 'active_user',
  RETAILER_BUSINESS_TYPE: 'store_type',
  ACTION_TIME_RANGE: 'action_time_range',
  HAS_HAD_VISITS_DATE_RANGE: 'has_had_visits_date_range',
  RETAILER_CHAR_RETAILER_NAME: 'retailer_char_retailer_name',
  RETAILER_CHAR_RETAILER_ADDRESS: 'retailer_char_retailer_address',
  LICENSE_CHAR_RETAILER_NAME: 'license_char_retailer_name',
  LICENSE_CHAR_RETAILER_ADDRESS: 'license_char_retailer_address',
  VISIT_CHAR_RETAILER_NAME: 'visit_char_retailer_name',
  VISIT_CHAR_RETAILER_ADDRESS: 'visit_char_retailer_address',
  STORE_MERGER_RETAILER_NAME: 'store_merger_retailer_name',
  STORE_MERGER_RETAILER_ADDRESS: 'store_merger_retailer_address',
  ENFORCEMENT_INSPECTION_TYPE: 'enforcement_inspection_type',
  ENFORCEMENT_INSPECTION_DATE_RANGE: 'inspection_date_range',
  ENFORCEMENT_PENALTY: 'enforcement_penalty',
  PRODUCT_TYPE: 'product_type',
  RETAILER_NAME: 'retailer_name',
  RETAILER_ADDRESS: 'retailer_address',
  RETAILER_ZIPCODE: 'retailer_zipcode',
  RETAILER_CITY: 'retailer_city',
  RETAILER_FILTER_CITY: 'city',
  LOCATION_CITY: 'location_city',
  NO_LICENSES: 'no_licenses',
  SUSPESIONS_DATE_RANGE: 'suspesions_date_range',
  CORP_FLAG: 'corp_flag',
  AGENCY_ID: 'agency_id',
  SALE_TO_MINOR: 'sale_to_minor',
  YOUTH_INVOLVED: 'youth_involved',
  LICENSES_STATUS: 'license_status',
  CREATED_DATE_RANGE: 'created_date_range',
  LICENSE_HOLDER_ID: 'license_holder_id',
  LICENSE_HOLDER_NAME: 'license_holder_name',
  AGENCIES: 'agencies',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  REVOCATION_DATE: 'revocation_date',
  SUSP_DATE: 'susp_date',
  ACTIVATION_SUSPENSION: 'active_suspension',
  IS_CURRENT: 'is_current',
  OBJECT_TYPE: 'object_type',
  ACTION_FLAG: 'action_flag',
  JURISDICTIONS: 'jurisdictions',
  INCORPORATED_AREA: 'incorporated_area',
  ALCOHOL: 'alcohol',

  ENFOCEMENT_VIOLATION_FOUND: 'has_violation_enforcement_visits',
  EDUCATION_VIOLATION_FOUND: 'has_violation_education_visits',
  ENFOCEMENT_VIOLATION_CASES: 'enforcement_violation_open_status',
  EDUCATION_VIOLATION_CASES: 'education_violation_open_status',
  ENFORCEMENT_NUMBER_OF_VIOLATION: 'enforcement_violation_cases_count',
  EDUCATION_NUMBER_OF_VIOLATION: 'education_violation_cases_count',
  ENFORCEMENT_VIOLATION_TYPE: 'enforcement_violation_type',
  EDUCATION_VIOLATION_TYPE: 'education_violation_type',
  ENFORCEMENT_ACTION_TYPE: 'enforcement_action_type',
  EDUCATION_ACTION_TYPE: 'education_action_type',
  NO_VISIT_TYPE: 'had_not_visits_in_date_range',
  NO_VISIT_TYPE_DATE_RANGE: 'had_not_visits_in_date_range_date',
  NO_VISIT_TYPE_DATE_RANGE_LABEL: 'had_not_visits_in_date_range_date_label',
  NO_VISIT_TYPE_TYPE: 'had_not_visits_in_date_range_type',
  MINOR_VIOLATION_COUNT_GRT: 'sales_to_minor_violations_count',
};
