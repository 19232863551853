/* eslint-disable import/prefer-default-export */
/* eslint-disable radix */
import processQSParams from 'utils/old/processQSParams';
import parseRetailers from 'utils/old/parseTableData/parseRetailers';
import { fetchConstructor } from '../api/api';
import apiData from '../api/config';
import { retailersTableData, setIsNotification } from './retailers.action';

const fetchRetailers = async ({ page = 1, perPage, sortBy, sortOrder, ...qsParams }, dispatch) => {
  try {
    const params = processQSParams({
      page,
      perPage,
      sortOrder,
      sortBy,
      ...qsParams,
    });
    const response = await fetchConstructor('get', `${apiData.retailersTableData}?${params}`);

    const { data, meta } = response.data;
    const newData = parseRetailers(data);

    dispatch(retailersTableData({ data: newData, pagination: meta?.pagination }));
  } catch (e) {
    console.log(e);
  }
};
const retailersHistoricalGetData = async (token, dispatch) => {
  try {
    const response = await fetchConstructor(
      'get',
      `${apiData.retailersTableData}?ordering=name&page=1&page_size=1000000000`,
    );

    dispatch(retailersTableData(response.data));
  } catch (e) {
    console.log(e);
  }
};

const retailersCSV = async (dispatch, queryParams, addToast) => {
  try {
    const token = sessionStorage.getItem('token');
    dispatch(setIsNotification(false));
    const headers = {
      Authorization: `Token ${token}`,
      accept: 'application/vnd.api+json',
    };
    const detectedTz = `tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    let params = `?${detectedTz}`;
    if (queryParams) {
      params += `&${queryParams.substr(1)}`;
    }
    // eslint-disable-next-line no-unused-vars
    const response = queryParams && (await fetchConstructor('get', `${apiData.retailerCSV}${params}`, headers));
    addToast(response.data);
    dispatch(setIsNotification(true));
  } catch (e) {
    console.log(e);
  }
};

export { fetchRetailers, retailersCSV, retailersHistoricalGetData };
