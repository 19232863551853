import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100px;
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 20px;

  > button {
    min-width: 100px;

    :not(:last-of-type) {
      margin-right: 15px;
    }
`;

export const Input = styled.input`
  display: none;
`;

export const IconWrapper = styled.div`
  display: flex;
  max-width: 200px;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;
