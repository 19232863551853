const USERS_SAME_GEOGRAPHY = [
  {
    label: 'Users in my geography',
    value: 't',
  },
];

const USERS_ACTIVE_OPTIONS = [
  {
    label: 'Active users',
    value: 't',
  },
  {
    label: 'Inactive users',
    value: 'f',
  },
];

const SALES_SUSPENSION_OPTIONS = [
  {
    label: 'Both',
    value: null,
  },
  {
    label: 'Sales suspended',
    value: 't',
  },
  {
    label: 'Sales not suspended',
    value: 'f',
  },
];

const CORPORATE_OPTIONS = [
  {
    label: 'Corporate flag = Yes',
    value: 't',
  },
];

const INCORPORATED_CITY_OPTIONS = [
  {
    label: 'Is in incorporated city area',
    value: 't',
  },
  {
    label: 'Is outside incorporated city area',
    value: 'f',
  },
];

const BUSINESS_OPTIONS = [
  {
    label: 'In business',
    value: 't',
  },
  {
    label: 'Not in business',
    value: 'f',
  },
];

// Demographics

const INCOME_OPTIONS = [
  {
    label: 'Very Low Income',
    value: '1',
  },
  {
    label: 'Low Income',
    value: '2',
  },
  {
    label: 'Medium Income',
    value: '3',
  },
  {
    label: 'High Income',
    value: '4',
  },
  {
    label: 'Very High Income',
    value: '5',
  },
];

const UNDER_AGE_18_OPTIONS = [
  {
    label: 'Very Low Youth',
    value: '1',
  },
  {
    label: 'Low Youth',
    value: '2',
  },
  {
    label: 'Medium Youth',
    value: '3',
  },
  {
    label: 'High Youth',
    value: '4',
  },
  {
    label: 'Very High Youth',
    value: '5',
  },
];

const URBAN_RURAL_OPTIONS = [
  {
    label: 'Entirely Rural',
    value: '1',
  },
  {
    label: 'Mostly Rural',
    value: '2',
  },
  {
    label: 'Mixed Urban and Rural',
    value: '3',
  },
  {
    label: 'Mostly Urban',
    value: '4',
  },
  {
    label: 'Entirely Urban',
    value: '5',
  },
];

const ETHNICITY_HISPANIC_OPTIONS = [
  {
    label: 'Very Low Hispanic Population',
    value: '1',
  },
  {
    label: 'Low Hispanic Population',
    value: '2',
  },
  {
    label: 'Medium Hispanic Population',
    value: '3',
  },
  {
    label: 'High Hispanic Population',
    value: '4',
  },
  {
    label: 'Very High Hispanic Population',
    value: '5',
  },
];

const AFRICAN_BLACK_OPTIONS = [
  {
    label: 'Very Low Black Population',
    value: '1',
  },
  {
    label: 'Low Black Population',
    value: '2',
  },
  {
    label: 'Medium Black Population',
    value: '3',
  },
  {
    label: 'High Black Population',
    value: '4',
  },
  {
    label: 'Very High Black Population',
    value: '5',
  },
];

const NATIVE_AMERICAN_OPTIONS = [
  {
    label: 'Very Low Native American Population',
    value: '1',
  },
  {
    label: 'Low Native American Population',
    value: '2',
  },
  {
    label: 'Medium Native American Population',
    value: '3',
  },
  {
    label: 'High Native American Population',
    value: '4',
  },
  {
    label: 'Very High Native American Population',
    value: '5',
  },
];

const ASIAN_PACIFIC_OPTIONS = [
  {
    label: 'Very Low Asian/Pacific Population',
    value: '1',
  },
  {
    label: 'Low Asian/Pacific Population',
    value: '2',
  },
  {
    label: 'Medium Asian/Pacific Population',
    value: '3',
  },
  {
    label: 'High Asian/Pacific Population',
    value: '4',
  },
  {
    label: 'Very High Asian/Pacific Population',
    value: '5',
  },
];

const RACE_WHITE_OPTIONS = [
  {
    label: 'Very Low White Population',
    value: '1',
  },
  {
    label: 'Low White Population',
    value: '2',
  },
  {
    label: 'Medium White Population',
    value: '3',
  },
  {
    label: 'High White Population',
    value: '4',
  },
  {
    label: 'Very High White Population',
    value: '5',
  },
];

const RACE_OTHER_OPTIONS = [
  {
    label: 'Very Low Other Race Population',
    value: '1',
  },
  {
    label: 'Low Other Race Population',
    value: '2',
  },
  {
    label: 'Medium Other Race Population',
    value: '3',
  },
  {
    label: 'High Other Race Population',
    value: '4',
  },
  {
    label: 'Very High Other Race Population',
    value: '5',
  },
];

const COLLEGE_GRADUATE_OPTIONS = [
  {
    label: 'Very Low College Degree Education',
    value: '1',
  },
  {
    label: 'Low College Degree Education',
    value: '2',
  },
  {
    label: 'Medium College Degree Education',
    value: '3',
  },
  {
    label: 'High College Degree Education',
    value: '4',
  },
  {
    label: 'Very High College Degree Education',
    value: '5',
  },
];

const SOME_COLLEGE_OPTIONS = [
  {
    label: 'Very Low Some College Education',
    value: '1',
  },
  {
    label: 'Low Some College Education',
    value: '2',
  },
  {
    label: 'Medium Some College Education',
    value: '3',
  },
  {
    label: 'High Some College Education',
    value: '4',
  },
  {
    label: 'Very High Some College Education',
    value: '5',
  },
];

const HIGH_SCHOOL_OPTIONS = [
  {
    label: 'Very Low High School Education Only',
    value: '1',
  },
  {
    label: 'Low High School Education Only',
    value: '2',
  },
  {
    label: 'Medium High School Education Only',
    value: '3',
  },
  {
    label: 'High High School Education Only',
    value: '4',
  },
  {
    label: 'Very High High School Education Only',
    value: '5',
  },
];

const NO_HIGH_SCHOOL_OPTIONS = [
  {
    label: 'Very Low No High School Diploma',
    value: '1',
  },
  {
    label: 'Low No High School Diploma',
    value: '2',
  },
  {
    label: 'Medium No High School Diploma',
    value: '3',
  },
  {
    label: 'High No High School Diploma',
    value: '4',
  },
  {
    label: 'Very High No High School Diploma',
    value: '5',
  },
];

const EDUCATION_ACHIEVEMENT = [
  {
    label: 'Very Low Educational Achievement',
    value: '1',
  },
  {
    label: 'Low Educational Achievement',
    value: '2',
  },
  {
    label: 'Medium Educational Achievement',
    value: '3',
  },
  {
    label: 'High Educational Achievement',
    value: '4',
  },
  {
    label: 'Very High Educational Achievement',
    value: '5',
  },
];

const POPULATION_DENSITY = [
  {
    label: 'Very Low Population Density',
    value: '1',
  },
  {
    label: 'Low Population Density',
    value: '2',
  },
  {
    label: 'Medium Population Density',
    value: '3',
  },
  {
    label: 'High Population Density',
    value: '4',
  },
  {
    label: 'Very High Population Density',
    value: '5',
  },
];

const STORE_TYPE_OPTIONS = [
  {
    label: 'Convenience store with or without gas',
    value: '1',
  },
  {
    label: 'Drug store or pharmacy',
    value: '2',
  },
  {
    label: 'Beer, wine, liquor store',
    value: '3',
  },
  {
    label: 'Grocery store',
    value: '4',
  },
  {
    label: 'Mass merchandiser',
    value: '5',
  },
  {
    label: 'Tobacco shop',
    value: '6',
  },
  {
    label: 'Hookah lounge',
    value: '7',
  },
  {
    label: 'E-cigarette/Vape shop',
    value: '8',
  },
  {
    label: 'Bar or Restaurant',
    value: '9',
  },
  {
    label: 'Smoking bar',
    value: '10',
  },
  {
    label: 'Other store, not listed',
    value: '99',
  },
];

const NEAR_SCHOOL_OPTIONS = [
  {
    label: 'Within 1,000’ of a school',
    value: 't',
  },
  {
    label: 'Not within 1,000’ of a school',
    value: 'f',
  },
];

const VIOLATION_FOUND_OPTIONS = [
  {
    label: 'Yes',
    value: 't',
  },
  {
    label: 'No',
    value: 'f',
  },
];

const VIOLATION_CASES_OPTIONS = [
  {
    label: 'Open',
    value: 't',
  },
  {
    label: 'Closed',
    value: 'f',
  },
];

const VIOLATION_NUMBER_OPTIONS = [
  {
    label: '1-3',
    value: '1t3',
  },
  {
    label: '4-6',
    value: '4t6',
  },
  {
    label: '7-9',
    value: '7t9',
  },
  {
    label: '>10',
    value: 'gte10',
  },
];

const NEAR_RETAILER_OPTIONS = [
  {
    label: 'Within 500’ of another retailer',
    value: 't',
  },
  {
    label: 'Not within 500’ of another retailer',
    value: 'f',
  },
];

const HAS_PHARMACY_OPTIONS = [
  {
    label: 'Has a pharmacy counter',
    value: 't',
  },
  {
    label: 'Does not have a pharmacy counter',
    value: 'f',
  },
  {
    label: 'Has a pharmacy counter is unknown',
    value: 'null',
  },
];

const YES_NO_OPTIONS = [
  {
    label: 'Both',
    value: null,
  },
  {
    label: 'Yes',
    value: 't',
  },
  {
    label: 'No',
    value: 'f',
  },
];

const RETAILER_LOCATION_ACCURACY_OPTIONS = [
  {
    label: 'Has high-accuracy geocode',
    value: 't',
  },
  {
    label: 'Does not have high-accuracy geocode',
    value: 'f',
  },
];

const RETAILER_ADDRESS_VALIDATED_OPTIONS = [
  {
    label: 'Has validated address',
    value: 't',
  },
  {
    label: 'Does not have a validated address',
    value: 'f',
  },
];

const OBJECT_TYPE_OPTIONS = [
  {
    label: 'Retailer',
    value: 'retailer',
  },
  {
    label: 'Enforcement',
    value: 'visit',
  },
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Agency',
    value: 'agency',
  },
  {
    label: 'Store merger',
    value: 'storemerger',
  },
  {
    label: 'Unsuccessful login',
    value: 'unsuccessfullogin',
  },
  {
    label: 'Successful login',
    value: 'successfullogin',
  },
  {
    label: 'Log access',
    value: 'logaccess',
  },
];

const ACTION_TYPE_OPTIONS = [
  {
    label: 'Deleted',
    value: 'deleted',
  },
  {
    label: 'Edited',
    value: 'edited',
  },
  {
    label: 'Created',
    value: 'created',
  },
  {
    label: 'Restored',
    value: 'restored',
  },
  {
    label: 'Log access',
    value: 'logaccess',
  },
  {
    label: 'Log in',
    value: 'login',
  },
  {
    label: 'Historic',
    value: 'historic',
  },
  {
    label: 'Separate',
    value: 'separate',
  },
  {
    label: 'Duplicate',
    value: 'duplicate',
  },
];

const INSPECTION_TYPE_OPTIONS = [
  {
    label: 'Undercover Buy',
    value: '1',
  },
  {
    label: 'Advertising limitations',
    value: '3',
  },
  {
    label: 'Failure to display license',
    value: '4',
  },
  {
    label: 'Failure to verify age',
    value: '5',
  },
  {
    label: 'Flavored tobacco',
    value: '6',
  },
  {
    label: 'Hookah',
    value: '7',
  },
  {
    label: 'Internet/Mail violation',
    value: '8',
  },
  {
    label: 'Non-MSA inventory',
    value: '9',
  },
  {
    label: 'Open Packages/Singles',
    value: '10',
  },
  {
    label: 'Package size',
    value: '11',
  },
  {
    label: 'Sale to a minor',
    value: '12',
  },
  {
    label: 'Self-Service',
    value: '13',
  },
  {
    label: 'Shoulder Tap',
    value: '14',
  },
  {
    label: 'Signage Check',
    value: '2',
  },
  {
    label: 'Unlicensed',
    value: '15',
  },
  {
    label: 'Untaxed product',
    value: '16',
  },
  {
    label: 'Other',
    value: '17',
  },
];

const VIOLATION_OPTIONS = [
  {
    label: 'Violation was found',
    value: '3',
  },
  {
    label: 'Violation was not found',
    value: '1',
  },
  {
    label: 'Violation status not determined',
    value: '2',
  },
];
const SALE_TO_MINOR_OPTIONS = [
  {
    label: 'Sale was made to a minor',
    value: 't',
  },
  {
    label: 'Sale WAS NOT made to a minor',
    value: 'f',
  },
  {
    label: 'Sale minor status unknown',
    value: 'none',
  },
];

const YOUTH_INVOLVED_OPTIONS = [
  {
    label: 'Youth was involved',
    value: 't',
  },
  {
    label: 'Youth WAS NOT involved',
    value: 'f',
  },
  {
    label: 'Youth involvement unknown',
    value: 'none',
  },
];

const PENALTY_OPTIONS = [
  {
    label: 'Warning',
    value: '1',
  },
  {
    label: 'Fine',
    value: '2',
  },
  {
    label: 'Suspension/NTSO',
    value: '4',
  },
  {
    label: 'Revocation',
    value: '3',
  },
  {
    label: 'Other',
    value: '5',
  },
];

const PRODUCTS_OPTIONS = [
  {
    label: 'Cigarettes in a package',
    value: '1',
  },
  {
    label: 'Cigars',
    value: '2',
  },
  {
    label: 'Electronic Device',
    value: '3',
  },
  {
    label: 'ENDS/E-liquid',
    value: '4',
  },
  {
    label: 'Hookah tobacco',
    value: '5',
  },
  {
    label: 'Leaf Tobacco, Cigarettes',
    value: '6',
  },
  {
    label: 'Little Cigars & Cigarillos',
    value: '7',
  },
  {
    label: 'Papers/Cigarette',
    value: '8',
  },
  {
    label: 'Paraphernalia',
    value: '9',
  },
  {
    label: 'Pipe Tobacco',
    value: '10',
  },
  {
    label: 'RYO - Cigarette tobacco',
    value: '11',
  },
  {
    label: 'Single cigarette',
    value: '12',
  },
  {
    label: 'Smokeless tobacco/Chew',
    value: '13',
  },
  {
    label: 'Snus & Dissolvables',
    value: '14',
  },
  {
    label: 'Flavors',
    value: '17',
  },
  {
    label: 'Other',
    value: '15',
  },
];

const ENFORCEMENT_STATUS = [
  {
    label: 'Violation',
    value: '3',
  },
  {
    label: 'No Violation',
    value: '1',
  },
  {
    label: 'Pending',
    value: '2',
  },
];
const LICENCE_STATUS_OPTIONS = [
  {
    label: 'Valid',
    value: 'VALID',
  },
  {
    label: 'Revoked',
    value: 'REVOKED',
  },
  {
    label: 'Suspended',
    value: 'SUSPENDED',
  },
  {
    label: 'Ended',
    value: 'ENDED',
  },
  {
    label: 'Ends Soon (next 30 days)',
    value: 'END_SOON',
  },
];

const LICENCE_SUSPENDED_OPTIONS = [
  {
    label: 'License suspended',
    value: 't',
  },
  {
    label: 'License not suspended',
    value: 'f',
  },
];

const VIOLATION_TYPE_OPTIONS = [
  {
    label: 'None',
    value: '1',
  },
  {
    label: 'Loose cigarettes sold',
    value: '2',
  },
  {
    label: 'Flavored products or flavor enhancers (including mint/menthol flavors) sold',
    value: '3',
  },
  {
    label: 'Missing or incomplete manufacturer document certifying products are not flavored',
    value: '4',
  },
  {
    label: 'Vape product with nicotine content over 35 mg/ml',
    value: '5',
  },
  {
    label: 'Missing or incomplete manufacturer document displaying nicotine content of available vape products',
    value: '6',
  },
  {
    label: 'Missing local BOH and DOR permit',
    value: '7',
  },
  {
    label: 'Missing signage',
    value: '8',
  },
  {
    label: 'Advertising for products not sold in store',
    value: '9',
  },
  {
    label: 'Tobacco products sold on or in front of the counter',
    value: '10',
  },
  {
    label: 'Noncompliance with local cigar packaging/pricing regulation',
    value: '11',
  },
  {
    label: 'Other (please specify)',
    value: '12',
  },
];

const VIOLATION_ACTION_TYPE_OPTIONS = [
  {
    label: 'Educational warning',
    value: '1',
  },
  {
    label: '$1,000 fine',
    value: '2',
  },
  {
    label: '$2,000 fine',
    value: '3',
  },
  {
    label: '$5,000 fine',
    value: '4',
  },
  {
    label: 'Other fine',
    value: '5',
  },
  {
    label: 'Citation issued (or will be issued) to clerk',
    value: '6',
  },
  {
    label: 'Citation issued (or will be issued) to establishment',
    value: '7',
  },
  {
    label: 'Referral given to another agency',
    value: '8',
  },
  {
    label: 'Citation issued (or will be issued) to establishment: No fine',
    value: '9',
  },
  {
    label: 'Citation issued (or will be issued) to establishment: $1,000 fine',
    value: '10',
  },
  {
    label: 'Citation issued (or will be issued) to establishment: $2,000 fine',
    value: '11',
  },
  {
    label: 'Citation issued (or will be issued) to establishment: $5,000 fine',
    value: '12',
  },
  {
    label: 'Citation issued (or will be issued) to establishment: Custom fine',
    value: '13',
  },
  {
    label: 'Citation issued (or will be issued) to clerk: No fine',
    value: '14',
  },
  {
    label: 'Citation issued (or will be issued) to clerk: $1,000 fine',
    value: '15',
  },
  {
    label: 'Citation issued (or will be issued) to clerk: $2,000 fine',
    value: '16',
  },
  {
    label: 'Citation issued (or will be issued) to clerk: $5,000 fine',
    value: '17',
  },
  {
    label: 'Citation issued (or will be issued) to clerk: Custom fine',
    value: '18',
  },
];

const SELLS_TOBACCO_OPTIONS = [
  {
    label: 'Sells tobacco',
    value: 't',
  },
  {
    label: 'Does not sell tobacco',
    value: 'f',
  },
  {
    label: 'Sells tobacco is unknown',
    value: 'null',
  },
];

const SELLS_CIGARETTES_OPTIONS = [
  {
    label: 'Sells cigarettes',
    value: 't',
  },
  {
    label: 'Does not sell cigarettes',
    value: 'f',
  },
  {
    label: 'Sells cigarettes is unknown',
    value: 'null',
  },
];

const SELLS_CIGARS_OPTIONS = [
  {
    label: 'Sells cigars',
    value: 't',
  },
  {
    label: 'Does not sell cigars',
    value: 'f',
  },
  {
    label: 'Sells cigars is unknown',
    value: 'null',
  },
];

const SELLS_ECIG_OPTIONS = [
  {
    label: 'Sells e-cigarettes',
    value: 't',
  },
  {
    label: 'Does not sell e-cigarettes',
    value: 'f',
  },
  {
    label: 'Sells e-cigarettes is unknown',
    value: 'null',
  },
];

const SELLS_SMOKELESS_OPTIONS = [
  {
    label: 'Sells smokeless tobacco products',
    value: 't',
  },
  {
    label: 'Does not sell smokeless tobacco products',
    value: 'f',
  },
  {
    label: 'Sells smokeless tobacco products is unknown',
    value: 'null',
  },
];

const SELLS_ALCOHOL_OPTIONS = [
  {
    label: 'Sells alcohol',
    value: 't',
  },
  {
    label: 'Does not sell alcohol',
    value: 'f',
  },
  {
    label: 'Sells alcohol is unknown',
    value: 'null',
  },
];

const SELLS_TICKETS_OPTIONS = [
  {
    label: 'Sells lottery tickets',
    value: 't',
  },
  {
    label: 'Does not sell lottery tickets',
    value: 'f',
  },
  {
    label: 'Sells lottery tickets is unknown',
    value: 'null',
  },
];

const HAS_GAMING_MACHINES_OPTIONS = [
  {
    label: 'Has gaming machines',
    value: 't',
  },
  {
    label: 'Does not have gaming machines',
    value: 'f',
  },
  {
    label: 'Has gaming machines is unknown',
    value: 'null',
  },
];

const HAS_HAD_VISITS = [
  {
    label: 'Has had visit types:',
    value: 'any,ever',
  },
];
const RETAILER_VISIT_TYPE = [
  {
    id: 0,
    label: 'any',
    value: 'any',
  },
  {
    id: 1,
    label: 'assessment',
    value: 'assessment',
  },
  {
    id: 2,
    label: 'enforcement',
    value: 'enforcement',
  },
  {
    id: 3,
    label: 'education',
    value: 'education',
  },
];

const RETAILER_VISIT_DATE_RANGE = [
  {
    label: 'ever',
    value: 'ever',
  },
  {
    label: 'date',
    value: 'date',
  },
];

const SUBMITTED_BY_ME_OPTIONS = [
  {
    label: 'All visits submitted by me',
    value: 't',
  },
];

const SURVEY_WHY_OPTIONS = [
  {
    label: 'Survey was performed',
    value: '1',
  },
  {
    label: 'Survey not performed: Store does not exist or is permanently closed',
    value: '2',
  },
  {
    label: 'Survey not performed: Store closed at time of visit',
    value: '3',
  },
  {
    label: 'Survey not performed: Temporary/short-term or seasonal closure',
    value: '4',
  },
  {
    label: 'Survey not performed: Under 18 not allowed',
    value: '5',
  },
  {
    label: 'Survey not performed: Members only',
    value: '6',
  },
  {
    label: 'Survey not performed: Unsafe',
    value: '7',
  },
  {
    label: 'Survey not performed: Other reason not listed',
    value: '8',
  },
];

const ENFORCEMENT_COMPLETED_OPTIONS = [
  {
    label: 'Enforcement component was completed',
    value: 't',
  },
  {
    label: 'Enforcement component was not completed',
    value: 'f',
  },
];

const EDUCATION_COMPLETED_OPTIONS = [
  {
    label: 'Education component was completed',
    value: 't',
  },
  {
    label: 'Education component was not completed',
    value: 'f',
  },
];

const VISIT_VIOLATION_OPTIONS = [
  {
    label: 'Violation was found',
    value: 't',
  },
  {
    label: 'Violation was not found',
    value: 'f',
  },
];

const VISIT_SALE_TO_MINOR_OPTIONS = [
  {
    label: 'Sale was made to a minor',
    value: 't',
  },
  {
    label: 'Sale was not made to a minor',
    value: 'f',
  },
];

const VISIT_FDA_OPTIONS = [
  {
    label: 'No violation observed',
    value: '4',
  },
  {
    label: 'Warning letter issued',
    value: '3',
  },
  {
    label: 'Civil penalty not available',
    value: '1',
  },
  {
    label: 'Civil penalty',
    value: '2',
  },
];

const ID_BASED_OPTIONS = [
  {
    label: 'Inspection was ID-based check',
    value: 't',
  },
  {
    label: 'Inspection was not ID-based check',
    value: 'f',
  },
];

const ASK_AGE_OPTIONS = [
  {
    label: 'Clerk asked inspector to confirm age',
    value: 't',
  },
  {
    label: 'Clerk did not asked inspector to confirm age',
    value: 'f',
  },
];

const ASKED_FOR_ID_OPTIONS = [
  {
    label: 'Clerk asked for ID',
    value: 't',
  },
  {
    label: 'Clerk did not ask for ID',
    value: 'f',
  },
];

const PRESENTED_ID_OPTIONS = [
  {
    label: 'Inspector presented ID',
    value: 't',
  },
  {
    label: 'Inspector did not present ID',
    value: 'f',
  },
];

const EXAMINED_ID_OPTIONS = [
  {
    label: 'Clerk examined ID',
    value: 't',
  },
  {
    label: 'Clerk did not examine ID',
    value: 'f',
  },
];

const AGE_VERIFY_EQUIP_OPTIONS = [
  {
    label: 'Age-verification equipment was available',
    value: 't',
  },
  {
    label: 'Age-verification equipment was not available',
    value: 'f',
  },
];

const AGE_VERIFY_USED_OPTIONS = [
  {
    label: 'Age-verification equipment was used',
    value: 't',
  },
  {
    label: 'Age-verification equipment was not used',
    value: 'f',
  },
];

const TOBACCO_PROD_OPTIONS = [
  {
    label: 'Cigarette',
    value: '1',
  },
  {
    label: 'Cigarillo',
    value: '2',
  },
  {
    label: 'Cigar',
    value: '3',
  },
  {
    label: 'Smokeless tobacco',
    value: '4',
  },
  {
    label: 'E-Cigarette',
    value: '5',
  },
  {
    label: 'Other tobacco product',
    value: '6',
  },
];

const TOBACCO_MARKETED_OPTIONS = [
  {
    label: 'Tobacco sold by clerk over counter',
    value: '1',
  },
  {
    label: 'Tobacco sold by vending machine',
    value: '2',
  },
  {
    label: 'Tobacco sold by other means',
    value: '3',
  },
];

const RECEIPT_OPTIONS = [
  {
    label: 'Receipt was issued',
    value: 't',
  },
  {
    label: 'Receipt was not issued',
    value: 'f',
  },
];

const VISIT_VIOLATION_TYPE_OPTIONS = [
  {
    label: 'Loose cigarettes sold',
    value: '2',
  },
  {
    label: 'Flavored products or flavor enhancers (including mint/menthol flavors) sold',
    value: '3',
  },
  {
    label: 'Missing or incomplete manufacturer document certifying products are not flavored',
    value: '4',
  },
  {
    label: 'Vape product with nicotine content over 35 mg/ml',
    value: '5',
  },
  {
    label: 'Missing or incomplete manufacturer document displaying nicotine content of available vape products',
    value: '6',
  },
  {
    label: 'Missing local BOH and DOR permits',
    value: '7',
  },
  {
    label: 'Missing signage',
    value: '8',
  },
  {
    label: 'Advertising for products not sold in store',
    value: '9',
  },
  {
    label: 'Tobacco products sold on or in front of the counter',
    value: '10',
  },
  {
    label: 'Noncompliance with local cigar packaging/pricing regulation',
    value: '11',
  },
  {
    label: 'Other',
    value: '12',
  },
];

const VISIT_ACTION_TYPE_OPTIONS = [
  {
    label: 'Educational warning',
    value: '1',
  },
  {
    label: '$1,000 fine',
    value: '2',
  },
  {
    label: '$2,000 fine',
    value: '3',
  },
  {
    label: '$5,000 fine',
    value: '4',
  },
  {
    label: 'Other fine',
    value: '5',
  },
  {
    label: 'Citation issued to clerk',
    value: '6',
  },
  {
    label: 'Citation issued to establishment',
    value: '7',
  },
  {
    label: 'Citation issued to establishment: No Fine',
    value: '9',
  },
  {
    label: 'Citation issued to establishment: $1,000 fine',
    value: '10',
  },
  {
    label: 'Citation issued to establishment: $2,000 fine',
    value: '11',
  },
  {
    label: 'Citation issued to establishment: $5,000 fine',
    value: '12',
  },
  {
    label: 'Citation issued to establishment: Custom fine',
    value: '13',
  },
  {
    label: 'Referral given to another agency',
    value: '8',
  },
  {
    label: 'Citation issued to clerk: No Fine',
    value: '14',
  },
  {
    label: 'Citation issued to clerk: $2,000 fine',
    value: '16',
  },
  {
    label: 'Citation issued to clerk: $5,000 fine',
    value: '17',
  },

  {
    label: 'Citation issued to clerk: Custom fine',
    value: '18',
  },
];

const VIOLATION_OPEN_STATUS_OPTIONS = [
  {
    label: 'Open violation',
    value: 't',
  },
  {
    label: 'Closed violation',
    value: 'f',
  },
];

const CITATION_CLERK_OPTIONS = [
  {
    label: 'Citation issued to clerk',
    value: 't',
  },
  {
    label: 'Citation not issued to clerk',
    value: 'f',
  },
];

const CITATION_RETAILER_OPTIONS = [
  {
    label: 'Citation issued to retailer/store',
    value: 't',
  },
  {
    label: 'Citation not issued to retailer/store',
    value: 'f',
  },
];

const CHAIN_OPTIONS = [
  {
    label: 'Is a chain store',
    value: 't',
  },
  {
    label: 'Is not a chain store',
    value: 'f',
  },
  {
    label: 'Is a chain store is unknown',
    value: 'null',
  },
];

const WIC_OPTIONS = [
  {
    label: 'Accept WIC',
    value: 't',
  },
  {
    label: 'Does not accept WIC',
    value: 'f',
  },
  {
    label: 'Accept WIC is unknown',
    value: 'null',
  },
];

const SNAP_OPTIONS = [
  {
    label: 'Accepts SNAP',
    value: 't',
  },
  {
    label: 'Does not accept SNAP',
    value: 'f',
  },
  {
    label: 'Accept SNAP is unknown',
    value: 'null',
  },
];

const RESIDENTIAL_LOCATION_OPTIONS = [
  {
    label: 'Is a residential location',
    value: 't',
  },
  {
    label: 'Is not a residential location',
    value: 'f',
  },
  {
    label: 'Residential location status unknown',
    value: 'null',
  },
];

const HAS_AVC_AGREEMENT_OPTIONS = [
  {
    label: 'Has AVC agreement',
    value: 't',
  },
  {
    label: 'Does not have AVC agreement',
    value: 'f',
  },
  {
    label: 'Has AVC agreement is unknown',
    value: 'null',
  },
];

const INTERNET_SALES_OPTIONS = [
  {
    label: 'Has internet sales',
    value: 't',
  },
  {
    label: 'Does not have internet sales',
    value: 'f',
  },
  {
    label: 'Has internet sales is unknown',
    value: 'null',
  },
];

const CIGARETTES_SOLD_OPTIONS = [
  {
    label: 'Cigarettes available for sale',
    value: 't',
  },
];

const PROMO_CIG_OPTIONS = [
  {
    label: 'Cigarette promotions available',
    value: 't',
  },
];

const MENTHOL_SOLD_OPTIONS = [
  {
    label: 'Menthol cigarettes available for sale',
    value: 't',
  },
];

const PROMO_MENTHOL_OPTIONS = [
  {
    label: 'Menthol cigarette promotions available',
    value: 't',
  },
];

const NEWPORT_SOLD_OPTIONS = [
  {
    label: 'Newport brand available for sale',
    value: 't',
  },
];

const LCC_SOLD_OPTIONS = [
  {
    label: 'LCCs available for sale',
    value: 't',
  },
];

const SINGLE_LCC_SOLD_OPTIONS = [
  {
    label: 'Single LCCs available for sale',
    value: 't',
  },
];

const FLAV_LCC_SOLD_OPTIONS = [
  {
    label: 'Flavored LCCs available for sale',
    value: 't',
  },
];

const PROMO_LCC_SOLD_OPTIONS = [
  {
    label: 'LCCs promotions available',
    value: 't',
  },
];

const CIGAR_SOLD_OPTIONS = [
  {
    label: 'Large premium cIgars available for sale',
    value: 't',
  },
];

const FLAV_CIGAR_SOLD_OPTIONS = [
  {
    label: 'Flavored large premium cigars available for sale',
    value: 't',
  },
];

const SMOKELESS_SOLD_OPTIONS = [
  {
    label: 'Smokeless tobacco available for sale',
    value: 't',
  },
];

const FLAV_SMOKELESS_SOLD_OPTIONS = [
  {
    label: 'Flavored smokeless tobacco available for sale',
    value: 't',
  },
];

const PROMO_SMOKELESS_OPTIONS = [
  {
    label: 'Smokeless tobacco promotions available',
    value: 't',
  },
];

const ECIG_SOLD_OPTIONS = [
  {
    label: 'E-cigarettes available for sale',
    value: 't',
  },
];

const FLAV_ECIG_SOLD_OPTIONS = [
  {
    label: 'Flavored e-cigarettes available for sale',
    value: 't',
  },
];

const PROMO_ECIG_OPTIONS = [
  {
    label: 'E-cigarette promotions available',
    value: 't',
  },
];

const CHECKED_PERMIT_OPTIONS = [
  {
    label: 'Retailer permit was checked',
    value: 't',
  },
  {
    label: 'Retailer permit was not checked',
    value: 'f',
  },
];

const EDUCATION_VIOLATION_OPTIONS = [
  {
    label: 'Violation was found',
    value: 't',
  },
  {
    label: 'Violation was not found',
    value: 'f',
  },
];

const POSTED_BOH_OPTIONS = [
  {
    label: 'BOH license was posted',
    value: 't',
  },
  {
    label: 'BOH license was not posted',
    value: 'f',
  },
];

const POSTED_TAX_OPTIONS = [
  {
    label: 'Tax license was posted',
    value: 't',
  },
  {
    label: 'Tax license was not posted',
    value: 'f',
  },
];

const POSTED_STATE_OPTIONS = [
  {
    label: 'State signage was posted',
    value: 't',
  },
  {
    label: 'State signage was not posted',
    value: 'f',
  },
];

const NO_SMOKING_SIGN_OPTIONS = [
  {
    label: 'No smoking sign was posted',
    value: 't',
  },
  {
    label: 'No smoking sign was not posted',
    value: 'f',
  },
];

const SELF_SERVICE_ANY_OPTIONS = [
  {
    label: 'Tobacco available via self-service',
    value: 't',
  },
  {
    label: 'Tobacco not available via self-service',
    value: 'f',
  },
];

const SELF_SERVICE_LEGAL_OPTIONS = [
  {
    label: 'Tobacco self-service met legal standards',
    value: 't',
  },
  {
    label: 'Tobacco self-service did not meet legal standards',
    value: 'f',
  },
];

const HUMIDORS_PRESENT_OPTIONS = [
  {
    label: 'Humidors are present',
    value: 't',
  },
  {
    label: 'Humidors are not present',
    value: 'f',
  },
];

const HUMIDORS_LOCKED_OPTIONS = [
  {
    label: 'All humidors were locked',
    value: 't',
  },
  {
    label: 'All humidors were not locked',
    value: 'f',
  },
];

const VENDING_PRESENT_OPTIONS = [
  {
    label: 'Vending machine was present',
    value: 't',
  },
  {
    label: 'Vending machine was not present',
    value: 'f',
  },
];

const VENDING_LEGAL_OPTIONS = [
  {
    label: 'Vending machine was in compliance',
    value: 't',
  },
  {
    label: 'Vending machine was not in compliance',
    value: 'f',
  },
];

const FLAV_CIG_SOLD_OPTIONS = [
  {
    label: 'Flavored cigarettes sold',
    value: 't',
  },
  {
    label: 'Flavored cigarettes not sold',
    value: 'f',
  },
];

const LOW_MILD_SOLD_OPTIONS = [
  {
    label: 'Cigarettes sold as ‘low’ or ‘mild’',
    value: 't',
  },
  {
    label: 'Cigarettes not sold as ‘low’ or ‘mild’',
    value: 'f',
  },
];

const VENDING_NON_ADULT_OPTIONS = [
  {
    label: 'Vending machines in non-adult-only establishment',
    value: 't',
  },
  {
    label: 'Vending machines absent / adult-only establishment',
    value: 'f',
  },
];

const PACK_SIZE_20_OPTIONS = [
  {
    label: 'Cigarettes sold only in packs of 20 (no loosies)',
    value: 't',
  },
  {
    label: 'Cigarettes sold individually (loosies)',
    value: 'f',
  },
];

const SMOKELESS_ADS_OPTIONS = [
  {
    label: 'Ads for smokeless tobacco present',
    value: 't',
  },
  {
    label: 'Ads for smokeless tobacco absent',
    value: 'f',
  },
];

const DISCUSSED_LAWS_OPTIONS = [
  {
    label: 'Reviewed regulations/tobacco sales laws with merchant',
    value: 't',
  },
  {
    label: 'Did not review regulations/tobacco sales laws with merchant',
    value: 'f',
  },
];

const DISCUSSED_RESP_OPTIONS = [
  {
    label: 'Discussed retailer responsibilities',
    value: 't',
  },
  {
    label: 'Did not discuss retailer responsibilities',
    value: 'f',
  },
];

const DISCUSSED_ID_OPTIONS = [
  {
    label: 'Discussed proper ID',
    value: 't',
  },
  {
    label: 'Did not discuss proper ID',
    value: 'f',
  },
];

const DISCUSSED_ENFORCE_OPTIONS = [
  {
    label: 'Discussed enforcement checks',
    value: 't',
  },
  {
    label: 'Did not discuss enforcement checks',
    value: 'f',
  },
];

const DISCUSSED_PENALTIES_OPTIONS = [
  {
    label: 'Discussed penalties',
    value: 't',
  },
  {
    label: 'Did not discuss penalties',
    value: 'f',
  },
];

const DISCUSSED_TRAINING_OPTIONS = [
  {
    label: 'Discussed trainings',
    value: 't',
  },
  {
    label: 'Did not discuss trainings',
    value: 'f',
  },
];

const DISCUSSED_SIGN_OPTIONS = [
  {
    label: 'Discussed required signs',
    value: 't',
  },
  {
    label: 'Did not discuss required signs',
    value: 'f',
  },
];

const DISCUSSED_SMOKEFREE_OPTIONS = [
  {
    label: 'Discussed smokefree workplace',
    value: 't',
  },
  {
    label: 'Did not discuss smokefree workplace',
    value: 'f',
  },
];

const DISCUSSED_KIT_OPTIONS = [
  {
    label: 'Discussed retailer kit materials',
    value: 't',
  },
  {
    label: 'Did not discuss retailer kit materials',
    value: 'f',
  },
];

const GIVEN_REGS_OPTIONS = [
  {
    label: 'Copy of regulations was given',
    value: 't',
  },
  {
    label: 'Copy of regulations was not given',
    value: 'f',
  },
];

const GIVEN_STATE_OPTIONS = [
  {
    label: 'State signage was given',
    value: 't',
  },
  {
    label: 'State signage was not given',
    value: 'f',
  },
];

const GIVEN_LOCAL_OPTIONS = [
  {
    label: 'Local signage was given',
    value: 't',
  },
  {
    label: 'Local signage was not given',
    value: 'f',
  },
];

const GIVEN_NO_SMOKE_OPTIONS = [
  {
    label: 'No-smoking sign was given',
    value: 't',
  },
  {
    label: 'No-smoking sign was not given',
    value: 'f',
  },
];

const GIVEN_CIGAR_OPTIONS = [
  {
    label: 'Cigar health warning sign was given',
    value: 't',
  },
  {
    label: 'Cigar health warning sign was not given',
    value: 'f',
  },
];

const GIVEN_KIT_OPTIONS = [
  {
    label: 'Retailer kit or training materials was given',
    value: 't',
  },
  {
    label: 'Retailer kit or training materials was not given',
    value: 'f',
  },
];

const GIVEN_EMPLOY_OPTIONS = [
  {
    label: 'Sample employment agreement was given',
    value: 't',
  },
  {
    label: 'Sample employment agreement was not given',
    value: 'f',
  },
];

const EDUCATE_REASON_OPTIONS = [
  {
    label: 'Routine education visit',
    value: '1',
  },
  {
    label: 'Complaint received',
    value: '2',
  },
  {
    label: 'Failed compliance check follow-up',
    value: '3',
  },
  {
    label: 'Suspension of permit',
    value: '4',
  },
  {
    label: 'Retailer requested education',
    value: '5',
  },
  {
    label: 'Other',
    value: '6',
  },
];

const EMPLOYEE_TITLE_OPTIONS = [
  {
    label: 'Visit was with Owner',
    value: '1',
  },
  {
    label: 'Visit was with Manager',
    value: '2',
  },
  {
    label: 'Visit was with Supervisor',
    value: '3',
  },
  {
    label: 'Visit was with Clerk',
    value: '4',
  },
];

const EDUCATION_ACTION_TYPE_OPTIONS = [
  {
    label: 'Educational warning',
    value: '1',
  },
  {
    label: '$1,000 fine',
    value: '2',
  },
  {
    label: '$2,000 fine',
    value: '3',
  },
  {
    label: '$5,000 fine',
    value: '4',
  },
  {
    label: 'Other fine',
    value: '5',
  },
  {
    label: 'Cease and desist order issued',
    value: '6',
  },
  {
    label: 'Establishment referred for suspension',
    value: '7',
  },
  {
    label: 'Referral given to another agency',
    value: '8',
  },
  {
    label: 'Citation issued to establishment: No Fine',
    value: '9',
  },
  {
    label: 'Citation issued to establishment: $1,000 fine',
    value: '10',
  },
  {
    label: 'Citation issued to establishment: $2,000 fine',
    value: '11',
  },
  {
    label: 'Citation issued to establishment: $5,000 fine',
    value: '12',
  },
  {
    label: 'Citation issued to establishment: Custom fine',
    value: '13',
  },
];

const MY_GEOGS_OPTIONS = [
  {
    label: 'Users in my geography',
    value: 't',
  },
];

const VENUES_TYPE_OPTIONS = [
  {
    label: 'Public School',
    value: 'Public School',
  },
  {
    label: 'Private School',
    value: 'Private School',
  },
  {
    label: 'College/University',
    value: 'College/University',
  },
  {
    label: 'Government Building',
    value: 'Government Building',
  },
  {
    label: 'Hospital/Clinic',
    value: 'Hospital/Clinic',
  },
  {
    label: 'Multi-unit Housing',
    value: 'Multi-unit Housing',
  },
  {
    label: 'Park/Rec Center',
    value: 'Park/Rec Center',
  },
];

export default {
  VENUES_TYPE_OPTIONS,
  MY_GEOGS_OPTIONS,
  EDUCATION_ACTION_TYPE_OPTIONS,
  EMPLOYEE_TITLE_OPTIONS,
  EDUCATE_REASON_OPTIONS,
  GIVEN_EMPLOY_OPTIONS,
  GIVEN_KIT_OPTIONS,
  GIVEN_CIGAR_OPTIONS,
  GIVEN_NO_SMOKE_OPTIONS,
  GIVEN_LOCAL_OPTIONS,
  GIVEN_STATE_OPTIONS,
  GIVEN_REGS_OPTIONS,
  DISCUSSED_KIT_OPTIONS,
  DISCUSSED_SMOKEFREE_OPTIONS,
  DISCUSSED_SIGN_OPTIONS,
  DISCUSSED_TRAINING_OPTIONS,
  DISCUSSED_PENALTIES_OPTIONS,
  DISCUSSED_ENFORCE_OPTIONS,
  DISCUSSED_ID_OPTIONS,
  DISCUSSED_RESP_OPTIONS,
  DISCUSSED_LAWS_OPTIONS,
  SMOKELESS_ADS_OPTIONS,
  PACK_SIZE_20_OPTIONS,
  VENDING_NON_ADULT_OPTIONS,
  LOW_MILD_SOLD_OPTIONS,
  FLAV_CIG_SOLD_OPTIONS,
  VENDING_LEGAL_OPTIONS,
  VENDING_PRESENT_OPTIONS,
  HUMIDORS_LOCKED_OPTIONS,
  HUMIDORS_PRESENT_OPTIONS,
  SELF_SERVICE_LEGAL_OPTIONS,
  SELF_SERVICE_ANY_OPTIONS,
  NO_SMOKING_SIGN_OPTIONS,
  POSTED_STATE_OPTIONS,
  POSTED_TAX_OPTIONS,
  POSTED_BOH_OPTIONS,
  EDUCATION_VIOLATION_OPTIONS,
  CHECKED_PERMIT_OPTIONS,
  PROMO_ECIG_OPTIONS,
  FLAV_ECIG_SOLD_OPTIONS,
  ECIG_SOLD_OPTIONS,
  PROMO_SMOKELESS_OPTIONS,
  FLAV_SMOKELESS_SOLD_OPTIONS,
  SMOKELESS_SOLD_OPTIONS,
  FLAV_CIGAR_SOLD_OPTIONS,
  CIGAR_SOLD_OPTIONS,
  PROMO_LCC_SOLD_OPTIONS,
  FLAV_LCC_SOLD_OPTIONS,
  SINGLE_LCC_SOLD_OPTIONS,
  LCC_SOLD_OPTIONS,
  NEWPORT_SOLD_OPTIONS,
  PROMO_MENTHOL_OPTIONS,
  MENTHOL_SOLD_OPTIONS,
  PROMO_CIG_OPTIONS,
  CIGARETTES_SOLD_OPTIONS,
  USERS_ACTIVE_OPTIONS,
  SALES_SUSPENSION_OPTIONS,
  CORPORATE_OPTIONS,
  BUSINESS_OPTIONS,
  INCORPORATED_CITY_OPTIONS,
  INCOME_OPTIONS,
  UNDER_AGE_18_OPTIONS,
  URBAN_RURAL_OPTIONS,
  ETHNICITY_HISPANIC_OPTIONS,
  AFRICAN_BLACK_OPTIONS,
  NATIVE_AMERICAN_OPTIONS,
  ASIAN_PACIFIC_OPTIONS,
  RACE_WHITE_OPTIONS,
  RACE_OTHER_OPTIONS,
  COLLEGE_GRADUATE_OPTIONS,
  SOME_COLLEGE_OPTIONS,
  HIGH_SCHOOL_OPTIONS,
  NO_HIGH_SCHOOL_OPTIONS,
  NEAR_SCHOOL_OPTIONS,
  NEAR_RETAILER_OPTIONS,
  HAS_PHARMACY_OPTIONS,
  YES_NO_OPTIONS,
  RETAILER_LOCATION_ACCURACY_OPTIONS,
  RETAILER_ADDRESS_VALIDATED_OPTIONS,
  OBJECT_TYPE_OPTIONS,
  ACTION_TYPE_OPTIONS,
  INSPECTION_TYPE_OPTIONS,
  VIOLATION_OPTIONS,
  SALE_TO_MINOR_OPTIONS,
  YOUTH_INVOLVED_OPTIONS,
  PENALTY_OPTIONS,
  PRODUCTS_OPTIONS,
  ENFORCEMENT_STATUS,
  LICENCE_STATUS_OPTIONS,
  LICENCE_SUSPENDED_OPTIONS,
  EDUCATION_ACHIEVEMENT,
  POPULATION_DENSITY,
  VIOLATION_FOUND_OPTIONS,
  VIOLATION_CASES_OPTIONS,
  VIOLATION_NUMBER_OPTIONS,
  VIOLATION_TYPE_OPTIONS,
  VIOLATION_ACTION_TYPE_OPTIONS,
  STORE_TYPE_OPTIONS,
  SELLS_TOBACCO_OPTIONS,
  SELLS_CIGARETTES_OPTIONS,
  SELLS_CIGARS_OPTIONS,
  SELLS_ECIG_OPTIONS,
  SELLS_SMOKELESS_OPTIONS,
  SELLS_ALCOHOL_OPTIONS,
  HAS_HAD_VISITS,
  SELLS_TICKETS_OPTIONS,
  HAS_GAMING_MACHINES_OPTIONS,
  RETAILER_VISIT_TYPE,
  RETAILER_VISIT_DATE_RANGE,
  SUBMITTED_BY_ME_OPTIONS,
  SURVEY_WHY_OPTIONS,
  ENFORCEMENT_COMPLETED_OPTIONS,
  VISIT_VIOLATION_OPTIONS,
  VISIT_SALE_TO_MINOR_OPTIONS,
  VISIT_FDA_OPTIONS,
  ID_BASED_OPTIONS,
  ASK_AGE_OPTIONS,
  ASKED_FOR_ID_OPTIONS,
  PRESENTED_ID_OPTIONS,
  EXAMINED_ID_OPTIONS,
  AGE_VERIFY_EQUIP_OPTIONS,
  AGE_VERIFY_USED_OPTIONS,
  TOBACCO_PROD_OPTIONS,
  TOBACCO_MARKETED_OPTIONS,
  RECEIPT_OPTIONS,
  VISIT_VIOLATION_TYPE_OPTIONS,
  VISIT_ACTION_TYPE_OPTIONS,
  VIOLATION_OPEN_STATUS_OPTIONS,
  CITATION_CLERK_OPTIONS,
  CITATION_RETAILER_OPTIONS,
  CHAIN_OPTIONS,
  WIC_OPTIONS,
  SNAP_OPTIONS,
  EDUCATION_COMPLETED_OPTIONS,
  USERS_SAME_GEOGRAPHY,
  HAS_AVC_AGREEMENT_OPTIONS,
  INTERNET_SALES_OPTIONS,
  RESIDENTIAL_LOCATION_OPTIONS,
};
