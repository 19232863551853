import styled from 'styled-components';
import { deviceLess } from '_constants/DEVICES';

export const HorizontalBorder = styled.div`
  @media ${deviceLess.laptop} {
    border-bottom: 1px solid #e0e0e0;
    position: absolute;
    height: 100%;
    left: -20px;
    width: calc(100% + 40px);
    z-index: 1;
    filter: opacity(0.4);
  }
`;
