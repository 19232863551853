import React, { useCallback, useState } from 'react';
import { Img } from 'ui-kit';
import { ARROW_RIGHT } from 'assets/icons/';
import PropTypes from 'prop-types';

/* Styles */
import * as Styled from './styles';
import MenuItems from './MenuItems';

function HeaderCollapsedTab({ routes, title, setIsBurgerMenu, routeRef, index, focusedTab, setFocusedTab, burgerRef }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setIsMenuOpen((prev) => !prev), []);

  const handleSetFocus = () => setFocusedTab(index);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 32 && !isMenuOpen) {
        e.preventDefault();
        toggleMenu();
      }
    },
    [isMenuOpen, toggleMenu],
  );

  return (
    <Styled.RoutesListItem role="menuitem">
      <Styled.Tab
        onClick={toggleMenu}
        isActive={isMenuOpen}
        ref={routeRef}
        onKeyDown={handleKeyDown}
        aria-haspopup="true"
        aria-expanded={isMenuOpen}
        tabIndex={focusedTab === index ? 0 : -1}
      >
        {title}
        <Img alt="arrow-right" src={ARROW_RIGHT} size={[7, 12]} />
      </Styled.Tab>

      {isMenuOpen && (
        <MenuItems
          routes={routes}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          setIsBurgerMenu={setIsBurgerMenu}
          parentRef={routeRef}
          handleSetParentFocus={handleSetFocus}
          burgerRef={burgerRef}
        />
      )}
    </Styled.RoutesListItem>
  );
}

HeaderCollapsedTab.propTypes = {
  routes: PropTypes.array.isRequired,
  setIsBurgerMenu: PropTypes.func.isRequired,
  burgerRef: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  focusedTab: PropTypes.number.isRequired,
  setFocusedTab: PropTypes.func.isRequired,
  routeRef: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default HeaderCollapsedTab;
