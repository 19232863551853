/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-bracket-spacing */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import styles from '_constants/old/_constants/index';
import { Button } from '../styles/button.style';
import RadioButton from './radioButton';
import { WrapperRadioGroup } from './radioButton.style';

const {
  BUTTON_STYLES: { RESET },
} = styles;

const RadioButtonsOrganizer = ({
  radioButtons,
  onChange,
  isColumn,
  name,
  isMerger = false,
  selectedValue,
  isReset = false,
  isActive = true,
  title = '',
  id,
}) => {
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    const data = radioButtons?.map((button) => {
      if (String(button.value) === String(selectedValue)) {
        return { value: button.value, checked: true };
      }
      return button;
    });
    setButtons(data);
  }, [radioButtons, selectedValue]);

  const handleClick = (labelText) => {
    if (labelText) {
      setButtons(
        buttons.map((item) => {
          if (item.value === labelText) {
            onChange({ ...item, checked: true, name });
            return { ...item, checked: true };
          }
          return { ...item, checked: false };
        }),
      );
    } else {
      setButtons(buttons.map((item) => ({ ...item, checked: false })));
      onChange({ value: 'Unknown', checked: false, name });
    }
  };

  const handleClickRadio = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleClick('');
  };

  return (
    <>
      <WrapperRadioGroup aria-label={`${title} - radio buttons group`} isMerger={isMerger}>
        {buttons.map((button) => (
          <>
            <RadioButton
              id={id}
              key={button.value}
              onChange={() => handleClick(button.value)}
              label={button.value}
              labelEnd={button.valueEnd}
              isMerger={isMerger}
              isColumn={isColumn}
              isActive={isActive}
              value={button.checked}
              component={button.component}
              componentEnd={button.componentEnd}
              name={name}
            />
          </>
        ))}
        {isReset && (
          <Button onClick={(e) => handleClickRadio(e)} buttonType={RESET} id={uniqueId()}>
            Reset
          </Button>
        )}
      </WrapperRadioGroup>
    </>
  );
};

export default RadioButtonsOrganizer;
