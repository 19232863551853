/* Libs */
import React, { memo, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { INPUT_TYPE, KEY_CODE } from '_constants';
// import Loader from '../Loader';

import * as Styled from './styles';

function Input(
  {
    required,
    placeholder,
    title,
    type,
    name,
    value,
    error,
    hideErrorText,
    className,
    loading,
    preventSpacebar,
    floatingLabel,
    disabled,
    onKeyDown,
    onFocus,
    hasError: inputHasError,
    autoFocus,
    isHorizontalLayout,
    pageColumns,
    hasBorder,
    ...inputProps
  },
  ref,
) {
  const handlePreventSpace = (e) => {
    if (
      (preventSpacebar && e.keyCode === KEY_CODE.SPACE && e.target.selectionStart === 0) ||
      (e.keyCode === KEY_CODE.SPACE && type === INPUT_TYPE.EMAIL)
    ) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  // const additionalAttributes = useMemo(
  //   () =>
  //     type === INPUT_TYPE.EMAIL
  //       ? {
  //           autoCapitalize: 'off',
  //           autoComplete: 'off',
  //           autoCorrect: 'off',
  //           spellCheck: 'off',
  //         }
  //       : {},
  //   [type],
  // );

  const hasError = useMemo(() => {
    if (!value && disabled) return false;
    return !disabled && (inputHasError || error);
  }, [value, disabled, inputHasError, error]);

  return (
    <Styled.MainInputWrapper
      className={className}
      hasError={hasError}
      disabled={disabled}
      isHorizontalLayout={isHorizontalLayout}
      pageColumns={pageColumns}
    >
      {floatingLabel && !isEmpty(title) && (
        <Styled.Title htmlFor={name} disabled={disabled}>
          {title}
          {required && <Styled.RequiredDot> *</Styled.RequiredDot>}
        </Styled.Title>
      )}

      <Styled.InputWrapper disabled={disabled}>
        <Styled.StyledInput
          {...inputProps}
          ref={ref}
          id={name}
          name={name}
          placeholder={placeholder || ''}
          type={type === 'number' ? '' : type}
          value={value}
          onFocus={onFocus}
          onKeyDown={handlePreventSpace}
          disabled={disabled}
          hasError={hasError}
          aria-invalid={hasError}
          autoFocus={autoFocus}
        />
        <Styled.Error aria-label="error" pageColumns={pageColumns}>
          {!disabled && error && !hideErrorText && error?.replace(/_/g, ' ')}
        </Styled.Error>
      </Styled.InputWrapper>
      {hasBorder && <Styled.HorizontalBorder />}
    </Styled.MainInputWrapper>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password']),
  title: PropTypes.string,
  optional: PropTypes.bool,
  hideErrorText: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(INPUT_TYPE)),
  error: PropTypes.string,
  success: PropTypes.string,
  icon: PropTypes.string,
  clearIcon: PropTypes.node,
  imgSize: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  preventSpacebar: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
  hasError: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isHorizontalLayout: PropTypes.bool,
  pageColumns: PropTypes.string,
  hasBorder: PropTypes.bool,
};

/* Input default props */

Input.defaultProps = {
  className: undefined,
  required: false,
  autoComplete: 'new-password',
  type: INPUT_TYPE.TEXT,
  optional: false,
  value: '',
  title: '',
  placeholder: '',
  hideErrorText: false,
  hasError: false,
  error: null,
  success: null,
  onBlur: () => {},
  onFocus: () => {},
  icon: '',
  imgSize: null,
  loading: false,
  preventSpacebar: false,
  floatingLabel: true,
  disabled: false,
  clearIcon: undefined,
  onKeyDown: undefined,
  autoFocus: false,
  isHorizontalLayout: false,
  pageColumns: '',
  hasBorder: false,
};

export default memo(forwardRef(Input));
