const COLUMNS = [
  {
    label: 'First Name',
    value: 'first_name',
    disabled: true,
  },
  {
    label: 'Last Name',
    value: 'last_name',
  },
  {
    label: 'Email address',
    value: 'email',
  },
  {
    label: 'Status',
    value: 'is_active',
  },
  {
    label: 'User role',
    value: 'role_name',
  },
  {
    label: 'Agencies',
    value: 'agencies_order',
  },
];

const INITIAL_COLUMNS = ['first_name', 'last_name', 'email', 'is_active', 'role_name', 'agencies_order'];

export { COLUMNS, INITIAL_COLUMNS };
