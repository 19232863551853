import React, { memo, useRef } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { Button, FileIconLink } from 'ui-kit';
import { BUTTON_TYPES } from '_constants';
import * as Styled from './styles';

const PdfFileUploader = ({ onChange, value, name, onClean }) => {
  const fileInputRef = useRef(null);

  const inputId = uniqueId();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Styled.Wrapper>
      <FileIconLink value={value} />
      <Styled.InputWrapper>
        <Button text="Choose file" variant={BUTTON_TYPES.GREY} onClick={handleButtonClick} />
        <Styled.Input
          key={inputId}
          id={inputId}
          ref={fileInputRef}
          type="file"
          onChange={(e) => onChange(e, name)}
          accept=".pdf,.docx,.doc,.txt"
        />
        <Button text="Clean" variant={BUTTON_TYPES.GREY} onClick={() => onClean(name)} />
      </Styled.InputWrapper>
    </Styled.Wrapper>
  );
};

PdfFileUploader.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClean: PropTypes.func.isRequired,
};

PdfFileUploader.defaultProps = {
  value: null,
};

export default memo(PdfFileUploader);
