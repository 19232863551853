import React from 'react';
import { FILTER_TYPES } from '_constants/old/filters';
import { RetailersQueryBuilder } from 'pages/old/RetailerList/filters/RetailersQueryBuilder';
import { VisitsQueryBuilder } from 'modules/VisitsListPage/Filters/VisitsQueryBuilder';
import Location from 'modules/Modals/FilterPages/Location';

export const QueryBuilder = ({ filterType }) => {
  const displayContent = () => {
    switch (filterType) {
      case FILTER_TYPES.RETAILERS:
        return <RetailersQueryBuilder />;
      case FILTER_TYPES.ENFORCEMENT:
      case FILTER_TYPES.ASSESSMENT:
      case FILTER_TYPES.EDUCATION:
        return <VisitsQueryBuilder filterType={filterType} />;
      case FILTER_TYPES.VENUES:
        return <Location venue />;
      case FILTER_TYPES.STORE_MERGER:
        return <Location />;
      default:
        return null;
    }
  };

  return displayContent();
};
