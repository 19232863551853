/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState } from 'react';

import { TableWrapper } from 'ui-kit';
import useSortTable from 'utils/old/useSortTable';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { URLS } from '_constants';
import { useIsDataCollectorCreator } from 'hooks';
import { COLUMNS, INITIAL_COLUMNS } from './constants';

const VisitsTable = ({
  data,
  sortHandler,
  sortOption,
  getInitColumns,
  visitType,
  tableViewMode,
  tableVievModeButtonName,
  checkboxList,
  handleCheckbox,
  hasAccessToMultiDeleteRestore,
}) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [activeColumns, setActiveColumns] = useState(INITIAL_COLUMNS);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();

  const [isDataCollectorCreator] = useIsDataCollectorCreator();

  const getToast = () => getToastLock('Enforcement', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );

  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns: COLUMNS,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: visitType.toLowerCase(),
      route: URLS.enforcementPages.edit,
      getToast,
      isDataCollectorCreator,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams,
        getToast, // eslint-disable-next-line
        tableViewMode,
        checkboxList,
        handleCheckbox,
        hasAccessToMultiDeleteRestore,
      }),
    [
      dumpedActiveColumns,
      sortOption,
      tableControlParams,
      handleSort,
      tableViewMode,
      checkboxList,
      handleCheckbox,
      hasAccessToMultiDeleteRestore,
    ],
  );

  const checkboxCellParams = useMemo(
    () => ({
      tableViewMode,
      handleCheckbox,
      checkboxList,
      hasAccess: hasAccessToMultiDeleteRestore,
    }),
    [tableViewMode, handleCheckbox, checkboxList],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data || []}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
      checkboxCellParams={checkboxCellParams}
      tableVievModeButtonName={tableVievModeButtonName}
    />
  );
};

export default memo(VisitsTable);
