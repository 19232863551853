import * as actions from './Audit.actions';
import * as services from './Audit.services';
import * as selectors from './Audit.selectors';
import reducer from './Audit.reducer';

export default {
  actions,
  services,
  reducer,
  selectors,
};
