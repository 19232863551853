import React, { useMemo } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import isIncludes from 'modules/AccessControl/isIncludes';
import { AuthEntity } from '_entities';
import { useSelector } from 'react-redux';
import { URLS } from '../../_constants';

const { getProfilePermissions, getModularityPermissions } = AuthEntity.selectors;
function PrivateRoute({ path, component, permission, isAllAllow, isVisit, ...rest }) {
  const location = useLocation();
  const permissions = useSelector(getProfilePermissions);
  const modularityPermissions = useSelector(getModularityPermissions);
  const userAndModularityPermissions = permissions?.concat(modularityPermissions);

  const hasRights = useMemo(
    () => isAllAllow || isIncludes(userAndModularityPermissions, permission, isVisit),
    [isAllAllow, permission, userAndModularityPermissions, isVisit],
  );

  return hasRights ? (
    location.pathname === URLS.login ? (
      <Redirect to={URLS.dashboard} />
    ) : (
      <Route path={path} component={component} {...rest} />
    )
  ) : (
    <Redirect to="/" />
  );
}

PrivateRoute.propTypes = {
  // eslint-disable-next-line react/require-default-props
  path: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  permission: PropTypes.string,
  isAllAllow: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
  isVisit: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  permission: '',
  isAllAllow: false,
  isVisit: false,
};

export default PrivateRoute;
