import React from 'react';

const RemoveSearch = () => (
  <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="10" height="10">
    <path fill="none" d="M-1-1h12v12H-1z" />
    <g>
      <path
        stroke="null"
        fill="#0D0D0D"
        d="M9.215.792a.666.666 0 00-.944 0L5 4.056 1.728.785a.666.666 0 00-.943 0 .666.666 0 000 .943L4.056 5 .786 8.27a.666.666 0 000 .944.666.666 0 00.942 0L5 5.942l3.271 3.273a.666.666 0 00.944 0 .666.666 0 000-.944L5.943 5l3.272-3.272a.67.67 0 000-.936z"
      />
    </g>
  </svg>
);

export default RemoveSearch;
