import { uniqueId } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BACK_ARROW } from 'assets/icons';
import { Img } from 'ui-kit';
import * as Styled from './styles';

function BackPageButton({ title }) {
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <Styled.BackButtonLink href="#" aria-label={title} id={uniqueId()} onClick={handleClick}>
      <Img aria-hidden src={BACK_ARROW} size={[6, 12]} />
      <Styled.BackButtonText>{title}</Styled.BackButtonText>
    </Styled.BackButtonLink>
  );
}

BackPageButton.propTypes = {
  title: PropTypes.string,
};

BackPageButton.defaultProps = {
  title: 'Back',
};

export default BackPageButton;
