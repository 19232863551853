import styled from 'styled-components';
import { Button } from 'ui-kit';
import { DEVICES } from '_constants';

export const ModalWrapper = styled.div``;

export const ModalTitle = styled.h1`
  font-size: 20px;
  line-height: 1.6;
  font-weight: 500;
  text-align: left;
  margin-bottom: 17px;
`;

export const ModalDescription = styled.ul`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
  margin-bottom: 26px;
`;

export const ModalTips = styled.li``;

export const ModalButtonWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-flow: row nowrap;

  button:not(:last-child) {
    margin-right: 10px;
  }
`;

const ModalButton = styled(Button)`
  font-size: 14px;
  line-height: 1.2;
  color: var(--white);
  min-width: 135px;

  @media ${DEVICES.tablet} {
    padding: 0;

    > span {
      padding: 0 10px;
    }
  }
`;

export const ModalButtonCancel = styled(ModalButton)`
  min-width: 135px;

  @media ${DEVICES.mobileM} {
    min-width: 90px;

    > span {
      padding: 0 5px;
    }
  }
`;

export const ModalButtonSet = styled(ModalButton)``;

export const SelectWrapper = styled.div`
  margin: 20px 0;
`;
