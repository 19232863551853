import React from 'react';
import { PageContainer } from 'modules';
import * as Styled from './styles';

function NotFound() {
  return (
    <PageContainer>
      <Styled.Inner>
        <Styled.Content>
          <Styled.LeftCol>404</Styled.LeftCol>
          <Styled.RightCol>
            <Styled.Title>Error!</Styled.Title>
            <Styled.P>Page Not Found</Styled.P>
          </Styled.RightCol>
        </Styled.Content>
        <Styled.Message>Sorry, the page you were looking for could not be found.</Styled.Message>
      </Styled.Inner>
    </PageContainer>
  );
}

export default NotFound;
