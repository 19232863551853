import { createInitialValues } from 'utils/custom';

export const inputTypes = {
  email: 'email',
};

export const FORM_ACTION_TYPES = {
  RESET_TYPE: 'RESET_TYPE',
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
};

export const FORM_REQUIRED_FIELDS = ['email'];

export const formInitialState = {
  data: createInitialValues(inputTypes, ''),
  errors: createInitialValues(inputTypes, null),
  canBeSubmitted: false,
};
