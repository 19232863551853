import { SUPER_USER_ROLE } from '_constants';
import isIncludes from 'modules/AccessControl/isIncludes';
import { AuthEntity } from '_entities';
import { useSelector } from 'react-redux';

const { getUserRoleType, getUserId, getUserSubdomain, getAgenciesList, getSecGeogList } = AuthEntity.selectors;

function useIsUserCanEditHook() {
  const myUserRoleType = useSelector(getUserRoleType);
  const myUserId = useSelector(getUserId);
  const myUserState = useSelector(getUserSubdomain);
  const myUserAgenciesData = useSelector(getAgenciesList);
  const myUserSecGeogsData = useSelector(getSecGeogList);
  const myUserAgencies = myUserAgenciesData?.map((item) => item.id) || [];
  const myUserSecGeogs = myUserSecGeogsData?.map((item) => item.id) || [];

  const isUserCanEdit = (state, userId, userRoleType, listOfUserAgencies = [], listOfUserSecGeogs = []) => {
    if (myUserState !== state) {
      return false;
    }
    if (myUserId === userId) {
      return false;
    }
    if (myUserRoleType === SUPER_USER_ROLE) return true;
    if (myUserRoleType <= userRoleType) {
      return false;
    }

    return isIncludes(listOfUserAgencies, myUserAgencies) && isIncludes(listOfUserSecGeogs, myUserSecGeogs);
  };

  return [isUserCanEdit];
}

export default useIsUserCanEditHook;
