import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const getUserData = (itemId, users) => {
  const userItem = users.find(({ id }) => id === itemId);
  const { email } = userItem.attributes;
  return {
    email,
    id: itemId,
  };
};

const getUsersData = (arrayId, users) => {
  return users.filter((item) => arrayId?.includes(item.id));
};

const getCampaignData = (itemId, campaigns) => {
  const campaign = campaigns.find(({ id }) => id === itemId);
  const { agency_name: agencyName, campaign_name: campaignName } = campaign.attributes;
  return {
    agencyName,
    campaignName,
    id: itemId,
  };
};

const getRetailerData = (retailerId, retailers, assignmentsId, formId) => {
  const retailer = retailers.find(({ id }) => id === retailerId);
  const { name, address, city, zipcode } = retailer?.attributes;
  return {
    name: capitalizeFirstLetter(name),
    url: `${formId}/${retailerId}/${assignmentsId}`,
    address,
    zipcode,
    city,
  };
};

const getVisitType = (formId, formList) => {
  const form = formList.find(({ id }) => id === formId);
  const { form_type: formType } = form?.attributes;
  const visitTypeMap = {
    1: 'Assessment',
    2: 'Enforcement',
    3: 'Education',
  };
  return visitTypeMap[formType];
};

export const parseAssignmentsData = ({ data, included }) => {
  const userList = included?.filter(({ type }) => type === 'User');
  const retailerList = included?.filter(({ type }) => type === 'Retailer');
  const campaignList = included?.filter(({ type }) => type === 'Campaign');
  const formList = included?.filter(({ type }) => type === 'Form');

  const assignments = data.map((item) => {
    const { attributes, relationships } = item;
    const { is_follow_up: status } = attributes;
    const {
      campaign: {
        data: { id: campaignID },
      },
      users: {
        data: [{ id: userId }],
      },
      form: {
        data: { id: formId },
      },
      retailer: {
        data: { id: retailerId },
      },
    } = relationships;

    const usersIDs = item.relationships.users.data.map((elem) => elem.id);

    const assignmentItem = {
      retailer: getRetailerData(retailerId, retailerList, item.id, formId),
      user: getUserData(userId, userList),
      users: getUsersData(usersIDs, userList),
      visitType: getVisitType(formId, formList),
      campaign: getCampaignData(campaignID, campaignList),
      status,
    };
    return assignmentItem;
  });

  return assignments;
};
