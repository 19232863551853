import React, { useEffect } from 'react';
import { AuthEntity } from '_entities';
import { PageContainer } from 'modules';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { URLS } from '_constants';
import { Loader } from 'ui-kit';
import queryString from 'query-string';

const { isValidLink } = AuthEntity.actions;

function LinkExpiration() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const { code } = queryString.parse(location.search);
    if (typeof code !== 'string') {
      history.push(URLS.resetPassword);
      return;
    }
    (async function isLinkValid() {
      try {
        await dispatch(isValidLink(code));
        // TODO push to set new password page when it's will be finished
        history.push(URLS.login);
      } catch (e) {
        console.log(e);
        history.push(URLS.resetPassword);
      }
    })();
  });

  return (
    <PageContainer>
      <Loader />
    </PageContainer>
  );
}

export default LinkExpiration;
