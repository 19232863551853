/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../ListItem';
import * as Styled from './styles';

function List({ title, fields }) {
  return (
    <Styled.ListWrapper>
      <Styled.ListTitle>{title}</Styled.ListTitle>
      <Styled.ListInner>
        {fields.map((props) => {
          return <ListItem key={props.id} {...props} />;
        })}
      </Styled.ListInner>
    </Styled.ListWrapper>
  );
}

List.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
};

export default List;
