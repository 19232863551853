/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import FilterArrow from 'assets/icons/old/filterArrowTable';
import * as Styled from './styles';

function FilterArrows({ order, isActive, isHideSorting }) {
  return (
    <Styled.Wrapper isHideSorting={isHideSorting} isActive={isActive} order={order}>
      <FilterArrow />
    </Styled.Wrapper>
  );
}

FilterArrows.propTypes = {
  order: PropTypes.number,
};

FilterArrows.defaultProps = {
  order: '',
};

export default FilterArrows;
