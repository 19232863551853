import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  &:not(:first-child) {
    margin: 26px 0 0;
  }
`;

export const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))``;
