export const GET_CALCPOLY = 'GET_CALCPOLY';
export const GET_CALCPOLY_SUCCESS = 'GET_CALCPOLY_SUCCESS';
export const GET_CALCPOLY_FAIL = 'GET_CALCPOLY_FAIL';

export const GET_DISPARATEBURDEN = 'GET_DISPARATEBURDEN';
export const GET_DISPARATEBURDEN_SUCCESS = 'GET_DISPARATEBURDEN_SUCCESS';
export const GET_DISPARATEBURDEN_FAIL = 'GET_DISPARATEBURDEN_FAIL';

export const GET_ENFORCEMENT_OVERVIEW = 'GET_ENFORCEMENT_OVERVIEW';
export const GET_ENFORCEMENT_OVERVIEW_SUCCESS = 'GET_ENFORCEMENT_OVERVIEW_SUCCESS';
export const GET_ENFORCEMENT_OVERVIEW_FAIL = 'GET_ENFORCEMENT_OVERVIEW_FAIL';

export const GET_POSLANDSCAPE = 'GET_POSLANDSCAPE';
export const GET_POSLANDSCAPE_SUCCESS = 'GET_POSLANDSCAPE_SUCCESS';
export const GET_POSLANDSCAPE_FAIL = 'GET_POSLANDSCAPE_FAIL';

export const GET_RETAILER_REDUCTION = 'GET_RETAILER_REDUCTION';
export const GET_RETAILER_REDUCTION_SUCCESS = 'GET_RETAILER_REDUCTION_SUCCESS';
export const GET_RETAILER_REDUCTION_FAIL = 'GET_RETAILER_REDUCTION_FAIL';

export const GET_YOUTHAPPEAL = 'GET_YOUTHAPPEAL';
export const GET_YOUTHAPPEAL_SUCCESS = 'GET_YOUTHAPPEAL_SUCCESS';
export const GET_YOUTHAPPEAL_FAIL = 'GET_YOUTHAPPEAL_FAIL';

export const SET_CHOSEN_GEOGRAPHY = 'SET_CHOSEN_GEOGRAPHY';
