import styled from 'styled-components';

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  height: 50px;
  padding: 0 18px;
  background: var(--white-smoke);
  color: var(--dark);
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);

  > img {
    transform: rotate(${({ isActive }) => (isActive ? '90deg' : '0deg')});
  }
`;

export const Content = styled.div``;

export const Wrapper = styled.div``;
