/* eslint-disable */
// a control meant to pair with a BigLayerPanel, to provide a separate legend in tandem with the selections in the BigLayerPanel
// the use case is specifically contrived for this one need: to display a legend when the main panel is absent, notably when printing or exportig the map as a PNG

L.biglegendpanel = function (options) {
    return new L.Control.BigLegendPanel(options);
};

L.Control.BigLegendPanel = L.Control.extend({
    options: {
        position: 'bottomleft',
    },
    initialize: function(options) {
        L.setOptions(this,options);

        if (! this.options.layerpanel) throw new Error("L.Control.BigLegendPanel requires 'layerpanel' specifying a BigLegendPanel");
    },
    onAdd: function (map) {
        this._map = map;
        this._container = L.DomUtil.create('div', 'leaflet-biglegend-control leaflet-control');

        // lay out our HTML... well, no, see updateLegend() where we do that
        this._container.innerHTML = ``;

        // keep mouse events from falling through to the map: don't drag-pan or double-click the map on accident
        L.DomEvent.on(this._container, 'mousedown dblclick', L.DomEvent.stopPropagation);
        L.DomEvent.on(this._container, 'click', L.DomEvent.stopPropagation);

        // all done; hand back the container DIV
        return this._container;
    },

    //
    // the big function which reads from the layerpanel, and composes a legend to match
    // this is highly contrived to the structure and use case of the BigLayerPanel, itself highly custoized to this one client's use case
    // don't expect that this will drop in to other maps without significant reworking
    //
    updateLegend: function () {
        this._container.innerHTML = '';

        // first, the demographic layer, if any
        const demoglegend = this.options.layerpanel.getCurrentDemographicLegendInfo();
        if (demoglegend) {
            const htmlblock = `
                <div>
                    ${demoglegend.title}
                    <br/>
                    ${demoglegend.areaname}
                    <br/>
                    <div class="leaflet-biglegend-swatch leaflet-biglegend-swatch-square" style="background-color: ${demoglegend.colors[0]};"></div> ${demoglegend.minlabel}<br/>
                    <div class="leaflet-biglegend-swatch leaflet-biglegend-swatch-square" style="background-color: ${demoglegend.colors[1]};"></div><br/>
                    <div class="leaflet-biglegend-swatch leaflet-biglegend-swatch-square" style="background-color: ${demoglegend.colors[2]};"></div><br/>
                    <div class="leaflet-biglegend-swatch leaflet-biglegend-swatch-square" style="background-color: ${demoglegend.colors[3]};"></div><br/>
                    <div class="leaflet-biglegend-swatch leaflet-biglegend-swatch-square" style="background-color: ${demoglegend.colors[4]};"></div> ${demoglegend.maxlabel}<br/>
                    <br/>
                </div>
            `;
            this._container.innerHTML += htmlblock;
        }

        // now the regular layers with their single-icon inline legends
        const legendentries = this.options.layerpanel.activeLayerLegends();
        legendentries.forEach((layerinfo) => {
            let htmlblock;
            switch (layerinfo.swatch.type) {
                case 'square':
                case 'circle':
                case 'line':
                    htmlblock = `
                    <div>
                        <div class="leaflet-biglegend-swatch leaflet-biglegend-swatch-${layerinfo.swatch.type}" style="background-color: ${layerinfo.swatch.color};"></div> ${layerinfo.title}
                    </div>
                    `;
                    break;
                case 'image':
                    htmlblock = `
                    <div>
                        <div class="leaflet-biglegend-swatch leaflet-biglegend-swatch-${layerinfo.swatch.type}" style='background-image: ${layerinfo.swatch.image};'></div> ${layerinfo.title}
                    </div>
                    `;
                    break;
            }

            this._container.innerHTML += htmlblock;
        });
    },
});
