import React from 'react';

const ArrowRight = () => (
  <svg
    alt="arrow right"
    width="7"
    focusable="false"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46559 5.14066C6.77085 5.43277 6.77085 5.90638 6.46559 6.19849L1.49118 10.9587C1.18593 11.2508 0.691011 11.2508 0.385756 10.9587C0.0805015 10.6666 0.0805021 10.193 0.385757 9.90089L4.80746 5.66957L0.385764 1.43825C0.0805098 1.14614 0.0805103 0.672529 0.385765 0.380417C0.69102 0.0883052 1.18593 0.088305 1.49119 0.380417L6.46559 5.14066Z"
      fill="#0D0D0D"
    />
  </svg>
);

export default ArrowRight;
