import { DEFAULT_SORT } from '_constants';

export const sortOptions = {
  sortBy: DEFAULT_SORT.revisits.prop,
  sortOrder: DEFAULT_SORT.revisits.order,
};

export const COLUMNS = [
  {
    label: 'Retailer name',
    value: 'retailerName',
  },
  {
    label: 'Violation date',
    value: 'violationDate',
  },
  {
    label: 'City',
    value: 'address',
  },
  {
    label: 'Address',
    value: 'zipCode',
  },
  {
    label: 'City',
    value: 'city',
  },
  {
    label: 'Zip code',
    value: 'zipCode',
  },
  {
    label: 'Visit type',
    value: 'visit',
  },
];

export const INITIAL_COLUMNS = ['retailerName', 'violationDate', 'address', 'city', 'zipCode', 'visit'];
