import { isEmpty } from 'lodash';

export const getProfilePermissions = ({ data, included, modularityPermissions }) => {
  const {
    relationships: {
      permissions: { data: userPermissionData },
    },
  } = data[0];

  const corePermissions = userPermissionData.reduce((acc, item) => {
    const permissionObj = included.find(({ type, id }) => item.type === type && item.id === id);
    return isEmpty(permissionObj) ? acc : acc.concat(permissionObj?.attributes?.codename);
  }, []);

  const permissions = corePermissions.concat(modularityPermissions);
  return {
    permissions,
  };
};
