import React from 'react';

export default () => (
  <svg focusable="false" width="8" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.055.667v8.78l-1.92-1.92a.664.664 0 10-.94.94l3.06 3.06c.26.26.68.26.94 0l3.06-3.06a.664.664 0 10-.94-.94l-1.927 1.92V.667C4.388.3 4.088 0 3.722 0c-.367 0-.667.3-.667.667z"
      fill="#0D0D0D"
    />
  </svg>
);
