export default {
  campaignList: {
    prop: 'campaign_name',
    order: 1,
  },
  retailerVisits: {
    prop: 'retailer__name',
    order: 1,
  },
  youthList: {
    prop: 'youth_id',
    order: 1,
  },
  revisits: {
    prop: 'revisit_date',
    order: 1,
  },
};
