import * as actions from './Survey.actions';
import reducer from './Survey.reducer';
import * as types from './Survey.types';
import * as selectors from './Survey.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
