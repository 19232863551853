import styled from 'styled-components';
import { UiColors } from 'assets/styles/old/global';
import { DEVICES } from '_constants';

const Wrapper = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  width: 218px;
  padding: 0px;
  height: 40px;
  font-size: 14px;
  display: flex;
  color: ${({ isActive }) => (isActive ? UiColors.notificationBackgroundColor : UiColors.fontColor)};
  background: ${({ isActive }) => (isActive ? UiColors.disabledButtonColor : UiColors.footerBackgroundColor)};
  border: 1px solid ${({ isActive }) => (isActive ? UiColors.activeBorder : UiColors.hoverInput)};

  @media ${DEVICES.mobileL} {
    width: ${({ isWidth }) => (isWidth ? 'calc(100vw - 240px)' : '115px')};
    height: 42px;
    padding-right: 5px;
    box-sizing: border-box;
  }

  > svg {
    path: ${UiColors.silverFillColor};
    width: 14px;
    height: 14px;
    margin: 14px 8px 0 8px;
    display: inline;
    box-sizing: border-box;

    @media ${DEVICES.mobileL} {
      margin: 10px 8px 0 8px;
    }
  }

  &[disabled] > svg > path {
    fill: ${UiColors.silverFillColor};
  }

  input {
    border: none;
    background-color: ${UiColors.footerBackgroundColor};
    padding: 0;
    font-family: Roboto;
    font-size: 14px;
    width: 100%;

    &::-ms-clear {
      display: none;
    }
  }

  input::placeholder {
    color: ${UiColors.fontColor};
  }

  input:focus {
    outline-style: none;
    outline-width: 0px !important;
    outline-color: none !important;
  }

  &:hover,
  &:hover > input {
    cursor: pointer;
    background-color: ${UiColors.strokeColor};
    border-color: ${UiColors.strokeColor};
    color: ${UiColors.notificationBackgroundColor};
  }

  &:focus {
    border: 1px solid ${({ isActive }) => (isActive ? UiColors.activeBorder : UiColors.focusColor)};
    outline-color: ${UiColors.focusColor} !important;
  }

  input:focus {
    border: none;
    outline: none;
    outline-offset: 0;
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${UiColors.silverFillColor};
    border: 1px solid ${UiColors.strokeColor};
    background: ${UiColors.footerBackgroundColor};
  }

  input[disabled],
  input[disabled]::placeholder {
    cursor: not-allowed;
    background: ${UiColors.footerBackgroundColor};
    border: none;
  }
  input[disabled]::placeholder {
    color: ${UiColors.silverFillColor};
  }
`;

export const RemoveWrapper = styled.button.attrs(() => ({
  ariaRequired: true,
  type: 'button',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  padding: 0 8px;
  cursor: pointer;
`;

export const LabelAda = styled.label``;
export const Input = styled.input``;
export default Wrapper;
