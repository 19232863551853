export const getFullYearAge = (date) => {
  let ageDifMs = null;
  if (typeof date === 'object') ageDifMs = Date.now() - date.getTime();
  if (date && typeof date === 'string') {
    const newDate = new Date(`${date}:12:12:12`);
    ageDifMs = Date.now() - newDate.getTime();
  }
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
