import React, { useCallback, useMemo, createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BaseSelect } from 'ui-kit';
import { useWindowDimensions } from 'hooks';
import { Wrapper } from './tab.style';
import Tab from './tab';

const TabOrganizer = ({ tabsTitle, selectedValue, useActiveTab, inputId }) => {
  const [tabs, setTabs] = useState(
    tabsTitle.map((title) => {
      if (selectedValue === title) {
        return { isActive: true, title };
      }
      return { isActive: false, title };
    }),
  );

  // temporary solution
  useEffect(() => {
    setTabs(
      tabsTitle.map((title) => {
        if (selectedValue === title) {
          return { isActive: true, title };
        }
        return { isActive: false, title };
      }),
    );
  }, [tabsTitle]);

  const [focusedTab, setFocusedTab] = useState(1);
  const [dropValue, setDropValue] = useState({
    label: tabsTitle[0],
    value: tabsTitle[0],
  });
  const { width: viewportWidth } = useWindowDimensions();
  const isTabs = viewportWidth > 769;

  const tabsRefs = tabs.reduce((acc, item, index) => ({ ...acc, [index + 1]: createRef() }), {});

  const dropOptions = useMemo(
    () =>
      tabs.map((tab) => ({
        label: tab.title,
        value: tab.title,
      })),
    [tabs],
  );

  const handleOnChange = useCallback(
    (selectedTab) => {
      setTabs(
        tabs.map((tab) => {
          if (tab.title === selectedTab.value) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useActiveTab(selectedTab.value);
            return { isActive: true, title: tab.title };
          }
          return { isActive: false, title: tab.title };
        }),
      );
      setDropValue(selectedTab);
    },
    [tabs, useActiveTab],
  );

  const handleNextTab = (firstTabInRound, nextTab, lastTabInRound) => {
    const tabToSelect = focusedTab === lastTabInRound ? firstTabInRound : nextTab;
    setFocusedTab(tabToSelect);
    tabsRefs[tabToSelect].current.focus();
    const selectedTab = { value: tabs[tabToSelect - 1].title, label: tabs[tabToSelect - 1].title };
    handleOnChange(selectedTab);
  };

  const handleKeyPress = (e) => {
    if (!isTabs) return;
    const tabCount = tabs.length;

    if ([37, 38].includes(e.keyCode)) {
      e.preventDefault();
      const last = tabCount;
      const next = focusedTab - 1;
      handleNextTab(last, next, 1);
    }
    if ([39, 40].includes(e.keyCode)) {
      e.preventDefault();
      const first = 1;
      const next = focusedTab + 1;
      handleNextTab(first, next, tabCount);
    }
  };
  const activeTab = tabs.find((tab) => tab.isActive === true);
  return (
    <Wrapper activeTab={activeTab} onKeyDown={handleKeyPress}>
      {tabs.map((tab, index) => (
        <Tab
          isActive={tab.isActive}
          key={tab.title}
          onChange={handleOnChange}
          title={tab.title}
          aria-label={tab.title.value}
          tabRef={tabsRefs[index + 1]}
          index={index + 1}
          focusedTab={focusedTab}
          setFocusedTab={setFocusedTab}
        />
      ))}
      {!isTabs && (
        <BaseSelect
          value={dropValue}
          onChange={handleOnChange}
          options={dropOptions}
          isSearchable={false}
          inputId={inputId}
          aria-label={inputId}
        />
      )}
    </Wrapper>
  );
};

TabOrganizer.propTypes = {
  tabsTitle: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  inputId: PropTypes.number.isRequired,
  useActiveTab: PropTypes.func.isRequired,
};

export default TabOrganizer;
