export const GET_AUDIT_LOGS = 'GET_AUDIT_LOGS';
export const GET_AUDIT_LOGS_SUCCESS = 'GET_AUDIT_LOGS_SUCCESS';
export const GET_AUDIT_LOGS_FAIL = 'GET_AUDIT_LOGS_FAIL';

export const GET_AUDIT_CSV = 'GET_AUDIT_CSV';
export const GET_AUDIT_CSV_SUCCESS = 'GET_AUDIT_CSV_SUCCESS';
export const GET_AUDIT_CSV_FAIL = 'GET_AUDIT_CSV_FAIL';

export const POST_AUDIT_REVERT = 'POST_AUDIT_REVERT';
export const POST_AUDIT_REVERT_SUCCESS = 'POST_AUDIT_REVERT_SUCCESS';
export const POST_AUDIT_REVERT_FAIL = 'POST_AUDIT_REVERT_FAIL';

export const SET_ID_REVERT = 'SET_ID_REVERT';
