import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Title = styled.div`
  margin: 0 0 0 8px;
  display: none;
`;

export const CellWrapper = styled.div`
  border: none;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background: transparent;
  cursor: pointer;

  @media ${DEVICES.tableView} {
    width: max-content;
    margin: 0 0 0 auto;

    ${Title} {
      display: block;
    }
  }
`;
