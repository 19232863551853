import { parseAssignmentsCampaignData, parseAssignmentsViewCampaign } from 'utils/custom';
import * as TYPES from './Campaign.types';

const initialState = {
  count: 0,
  view: {},
  assignmentsTableData: {},
  manageAssignmentsData: {},
  campaignRetailer: [],
  dataCollectors: [],
  mySecGeogs: [],
  assignmentsData: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_RETAILER_COUNT_SUCCESS: {
      const {
        result: {
          meta: {
            pagination: { count },
          },
        },
      } = action;
      return {
        ...state,
        count,
      };
    }
    case TYPES.GET_RETAILER_COUNT_FAIL: {
      return {
        ...state,
        count: 0,
      };
    }
    case TYPES.VIEW_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        view: action.result,
      };
    }
    case TYPES.GET_MANAGE_ASSIGNMENTS_LIST_SUCCESS: {
      const { data, meta } = action?.result;

      const newData = parseAssignmentsCampaignData({
        campaignRetailers: data,
      });

      return {
        ...state,
        campaignRetailer: data,
        campaignRetailerPagination: meta?.pagination,
        assignmentsTableData: {
          data: newData,
          pagination: meta?.pagination,
        },
      };
    }
    case TYPES.GET_ASSIGNMENTS_LIST_SUCCESS: {
      const { data: assignmentsData, included: assignmentsIncluded, meta: assignmentsMeta } = action?.result;
      const assignmentsWithDataCollector = parseAssignmentsViewCampaign({
        data: assignmentsData,
        included: assignmentsIncluded,
      });

      const assignData = { data: assignmentsWithDataCollector, pagination: assignmentsMeta?.pagination };

      return {
        ...state,
        assignmentsData: assignData,
      };
    }
    case TYPES.DATA_COLLECTORS_SUCCESS: {
      return {
        ...state,
        dataCollectors: action.result.data,
      };
    }
    case TYPES.GET_MY_SEC_GEOGS_SUCCESS: {
      return {
        ...state,
        mySecGeogs: action.result.data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
