import React from 'react';
import { URLS } from '_constants';
import { VisitEdit } from 'modules';

function EnforcementEdit() {
  return (
    <VisitEdit
      headerTitle="Edit enforcement visit"
      sectionTitle="Enforcement information"
      submitUrl={URLS.enforcement}
    />
  );
}

export default EnforcementEdit;
