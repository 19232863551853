import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  height: 100%;
  padding: 30px 24px 60px;
`;

export const Title = styled.h1`
  font-size: 22px;
  line-height: 1.17;
  font-weight: 500;
  margin-bottom: 35px;
`;

export const CardList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-gap: 20px;

  @media ${DEVICES.header} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${DEVICES.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const CardListItem = styled.li``;
