import styled, { css } from 'styled-components';
import { deviceLess } from '_constants/DEVICES';

export const CheckboxInputWrapper = styled.div`
  display: block;
  position: relative;
  height: 20px;
`;

const getCheckmarkURL = () =>
  // Encoded svg code. https://yoksel.github.io/url-encoder
  `url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.4987 9.5013L1.9987 6.0013L0.832031 7.16797L5.4987 11.8346L15.4987 1.83464L14.332 0.667969L5.4987 9.5013Z' fill='black'/%3E%3C/svg%3E%0A")`;

export const CheckboxInput = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ hasError, disabled }) => (hasError && !disabled ? 'var(--danger)' : 'var(--black)')};
  background: var(--white);
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  font-size: 14px;

  &:after {
    content: ${() => getCheckmarkURL()};
    display: block;
    width: 16px;
    height: 13px;
    left: 1px;
    top: 2px;
    position: absolute;
    opacity: var(--o, 0);
    transition: opacity var(--d-o, 0.2s);
  }

  &:checked {
    background: var(--white);
    box-shadow: 0px 0px 1px 1px var(--white);
    opacity: 1;
    --o: 1;
    --d-o: 0.3s;
  }

  &:focus-visible {
    box-shadow: ${({ hasError, disabled }) =>
      disabled ? 'none' : hasError ? '0px 0px 1px 2px var(--danger)' : '0px 0px 1px 2px var(--sky)'};
  }

  &:disabled {
    opacity: 0.35;
    cursor: not-allowed;
    &:checked {
      cursor: not-allowed;
      opacity: 1;
      background: var(--grey);
      box-shadow: 0px 0px 0 1px var(--grey);
    }
  }
`;

export const HtmlLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  /* TODO: remove when styles logic will be fixed */
  padding: 0;
  margin: 0;
`;

export const CheckboxLabel = styled.div`
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  line-height: 12px;
  color: ${({ disabled, hasError }) => {
    if (disabled) return 'var(--grey)';
    if (hasError) return 'var(--danger)';
    return 'var(--black)';
  }};
  user-select: none;
  margin-left: 8px;
`;

const columnsStyles = {
  two_columns: css`
    @media ${deviceLess.laptop} {
      padding-left: 0;
    }
  `,
  one_column: css`
    @media ${deviceLess.laptop} {
      padding-left: 70px;
    }
  `,
  '': ``,
};

export const CheckboxWrapper = styled.div`
  width: 100%;
  position: relative;
  margin: auto;

  ${({ pageColumns }) => pageColumns && columnsStyles[pageColumns]}

  ${({ isManageAssignment }) =>
    isManageAssignment &&
    css`
      @media (min-width: 900px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        label {
          width: 20px;
        }
      }
    `};
`;

export const CheckboxMessage = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc(100% + 2px);
  left: 24px;
  width: 100%;
`;

export const Popup = styled.div`
  position: absolute;
  top: 10%;
  left: 100%;
  background-color: var(--soft-dark);
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 11111;
`;

export const CheckboxError = styled(CheckboxMessage)`
  color: var(--danger);
`;
