import { FILTER_FIELDS } from '_constants/old/filters';

export const VENUES_QUICK_QUERY_OPTIONS = {
  OPTION_CURRENT_VENUES_NEAR_RETAILER: 'Venues within 1,000’ of retailers',
  OPTION_CURRENT_ALL_SCHOOLS: 'All schools',
  OPTION_CURRENT_TYPE: 'Schools within 1,000’ of retailers',
};

export const VENUES_QUICK_QUERY_MAPPING = {
  [FILTER_FIELDS.VENUES_NEAR_RETAILER]: VENUES_QUICK_QUERY_OPTIONS.OPTION_CURRENT_VENUES_NEAR_RETAILER,
  [FILTER_FIELDS.ALL_SCHOOLS]: VENUES_QUICK_QUERY_OPTIONS.OPTION_CURRENT_ALL_SCHOOLS,
  [FILTER_FIELDS.QUICK_VENUES_TYPE]: VENUES_QUICK_QUERY_OPTIONS.OPTION_CURRENT_TYPE,
};
