/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, uniqueId, cloneDeep } from 'lodash';
import { Img } from 'ui-kit';
import { REMOVE_ICON } from 'assets/icons';
import { FilterEntity, FilterRetailerEntity, AuthEntity, UserEntity } from '_entities';
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { isUserJurisdictions } from 'utils/old/isUserJurisdictions';
import { initialState } from '_entities/old/Filter/filter.reducer';
import { CHIPS_TYPES, DROPDOWN_DATA_TYPES } from './constants';
import * as Styled from './styles';

const { setAllCities } = FilterRetailerEntity.actions;
const { applyFilters, clearAllFilters, setFilterError, setShadowFilter, getJurisdictions } = FilterEntity.actions;
const { getSecGeogs } = UserEntity.selectors;
const MIN_HEIGHT_CHIPS_LIST = 61;

const ChipList = ({ filterMapping, withoutScroll, getApi, setCurrentPage }) => {
  const [isShowAll, setIsShowAll] = useState(true);
  const [chipsListHeight, setChipsListHeight] = useState(0);
  const [agencies, setAgencies] = useState([]);
  const [jurisdictions, setJurisdictions] = useState([]);
  const { getAgencies } = AuthEntity.selectors;
  const jurisdictionsUserList = useSelector(getAgencies);

  const secGeogsList = useSelector(getSecGeogs);

  const geogOptions = useMemo(
    () =>
      secGeogsList?.map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      }),
    [secGeogsList],
  );

  const toggleIsShowAll = () => setIsShowAll((prev) => !prev);

  const isChipListDisabled = useMemo(() => chipsListHeight < MIN_HEIGHT_CHIPS_LIST, [chipsListHeight]);

  const {
    filter: { shadowFilters, generalFilters, filterErrors },
  } = useSelector((state) => state);
  const { cities, counties, supervisory } = useSelector((state) => state.oldRetailerView);
  const newLocation = useSelector((state) => state.newFilters.LocationObj);
  const agencyList = useSelector((state) => state.newFilters.agencyObj);
  const userList = useSelector((state) => state.newFilters.userObj);
  const corporationList = useSelector((state) => state.newFilters.corpObj);
  const userId = useSelector((state) => state.auth.userId);
  const userEmail = useSelector((state) => state.auth.email);

  const locationTitles = newLocation?.map((el) => el.title);

  const locationFilters = locationTitles?.reduce(
    (acc, title) => ({
      ...acc,

      [title]: {
        title,
        meta: { dataType: title.toUpperCase() },
        chipsType: CHIPS_TYPES.MULTIDROPDOWN,
      },
    }),
    {},
  );

  const filtersList = { ...filterMapping, ...locationFilters };

  const dispatch = useDispatch();

  const supervisoryOptions = useMemo(
    () =>
      supervisory?.map((item) => ({
        value: item.label.name,
        label: item.label.name,
        id: item.label.id,
      })),
    [supervisory],
  );

  useEffect(() => {
    if (!userList.some((item) => item.label === userEmail)) {
      userList?.push({ value: userId, label: userEmail });
    }
  }, [userList]);

  useEffect(async () => {
    try {
      await dispatch(setAllCities());
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleClearAllFilters = () => {
    if (withoutScroll) {
      dispatch(applyFilters(initialState.generalFilters));
      getApi({ filters: initialState.generalFilters, page: 1 });
      setCurrentPage(1);
      return;
    }
    dispatch(clearAllFilters());
  };

  const dropdownValues = useMemo(
    () => ({
      [DROPDOWN_DATA_TYPES.JURISDICTIONS]: geogOptions,
      [DROPDOWN_DATA_TYPES.SUPERVISORY]: supervisoryOptions,
      [DROPDOWN_DATA_TYPES.COUNTRY]: counties,
      [DROPDOWN_DATA_TYPES.AGENCY]: agencyList,
      [DROPDOWN_DATA_TYPES.USER]: userList,
      [DROPDOWN_DATA_TYPES.CORPORATION]: corporationList,
      [DROPDOWN_DATA_TYPES.VENUE]: FILTER_OPTIONS.VENUES_TYPE_OPTIONS,
    }),
    [
      geogOptions,
      supervisoryOptions,
      counties,
      agencyList,
      userList,
      corporationList,
      FILTER_OPTIONS.VENUES_TYPE_OPTIONS,
    ],
  );

  const refList = useRef(null);
  const [chips, setChips] = useState([]);
  const filtersDataObject = withoutScroll ? generalFilters : shadowFilters;

  const chipsMapping = {
    [CHIPS_TYPES.CHECKBOX]: {
      // TODO: need to add the exception for the situation that we don't have value in our mapping
      getValue: (key, value) => {
        if (value && !value.length) return null;
        const checkBoxValue = Array.isArray(value) ? value : [value];
        const result = checkBoxValue.map((chipValue) => ({
          key,
          value: chipValue,
          chipLabel: [filtersList[key].title[chipValue]],
          filterObject: filtersList[key],
        }));
        return result;
      },
    },
    [CHIPS_TYPES.RADIOBUTTON]: {
      // TODO: need to add the exception for the situation that we don't have value in our mapping
      getValue: (key, value) => {
        if (!value) return null;
        const result = {
          key,
          value,
          chipLabel: [filtersList[key].title[value]],
          filterObject: filtersList[key],
        };
        return result;
      },
    },
    [CHIPS_TYPES.RADIODROPDOWN]: {
      // TODO: need to add the exception for the situation that we don't have value in our mapping
      getValue: (key, value) => {
        if (!value) return null;
        let chipLabel = '';
        switch (key) {
          case FILTER_FIELDS.NO_VISIT_TYPE_TYPE:
            // replace used for value 'any type' because this value will be set to dropdown, and other values must be without word 'type'
            chipLabel = `Retailers with no visits of ${value.replace(' type', '')} type`;
            break;
          case FILTER_FIELDS.VENUES_NEAR_RETAILER:
            chipLabel = `Venues within 1,000’ of retailers`;
            break;
          case FILTER_FIELDS.QUICK_VENUES_TYPE:
            chipLabel = `Schools within 1,000’ of retailers`;
            break;
          case FILTER_FIELDS.ALL_SCHOOLS:
            chipLabel = `All schools`;
            break;
          case FILTER_FIELDS.VIOLATION_COUNT_GRT:
            chipLabel = `Retailers with ${value}+ Enforcement violations`;
            break;
          case FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT:
            chipLabel = `Retailers with ${value}+ sale to minor violations within one year of each other`;
            break;
          default:
            chipLabel = `Retailers with ${value}+ Enforcement violations`;
            break;
        }
        const result = {
          key,
          value,
          chipLabel,
          filterObject: filtersList[key],
        };
        return result;
      },
    },
    [CHIPS_TYPES.TEXTINPUT]: {
      // TODO: need to add the exception for the situation that we don't have value in our mapping
      getValue: (key, value) => {
        if (!value) return null;
        const result = {
          key,
          value,
          chipLabel: `${filtersList[key].title}: ${value}`,
          filterObject: filtersList[key],
        };
        return result;
      },
    },
    [CHIPS_TYPES.QUERYSTRING]: {
      getValue: (key, value) => {
        if (!value.length) return null;
        const newValues = value.split(',').map((item) => ({
          key,
          value: item,
          chipLabel: `${filtersList[key].title}: ${item}`,
          filterObject: filtersList[key],
        }));
        return newValues;
      },
    },
    [CHIPS_TYPES.MULTIDROPDOWN]: {
      // TODO: need to add the exception for the situation that we don't have value in our mapping
      getValue: (key, value, dataType) => {
        let newValue = [];
        const valueArray = Array.isArray(value) ? value : [value];
        const locationField = locationTitles?.find((el) => el === key) || FILTER_FIELDS.CITY;
        switch (key) {
          case FILTER_FIELDS.JURISDICTIONS:
          case FILTER_FIELDS.SUPERVISORY_DISTRICTS:
            newValue = valueArray.reduce((acc, item) => {
              const matchItem = [FILTER_FIELDS.SUPERVISORY_DISTRICTS, FILTER_FIELDS.AGENCIES].includes(key)
                ? +item
                : item;
              const currentItem = dropdownValues[dataType].find((option) => option.id === matchItem);
              return currentItem
                ? acc.concat({
                    key,
                    value: currentItem.value,
                    id: currentItem.id,
                    chipLabel: `${filtersList[key].title}: ${currentItem.value}`,
                    filterObject: filtersList[key],
                  })
                : acc;
            }, []);
            break;
          case FILTER_FIELDS.SEC_GEOGS:
          case FILTER_FIELDS.AGENCY_ID:
          case FILTER_FIELDS.CORPORATION:
          case FILTER_FIELDS.VISIT_USER:
            newValue = valueArray.reduce((acc, item) => {
              const currentItem = dropdownValues[dataType]?.find(
                (option) => option.value.toLowerCase() === item.toLowerCase(),
              );
              return currentItem
                ? acc.concat({
                    key,
                    value: currentItem.value,
                    id: currentItem.value,
                    chipLabel: `${filtersList[key].title}: ${currentItem.label}`,
                    filterObject: filtersList[key],
                  })
                : acc;
            }, []);
            break;
          case locationField:
            newValue = valueArray.reduce((acc, item) => {
              return acc.concat({
                key,
                value: item,
                id: `${key}:${value}`,
                chipLabel: `${key}: ${
                  newLocation.find((obj) => obj.title === key)?.options.find((el) => el.value === item)?.label
                }`,
                filterObject: { chipsType: 'MULTIDROPDOWN', meta: { dataType: key }, title: key },
              });
            }, []);
            break;
          case FILTER_FIELDS.CITIES:
          case FILTER_FIELDS.LOCATION_COUNTY:
          case FILTER_FIELDS.LOCATION_CITY:
            newValue = valueArray.reduce((acc, item) => {
              const currentItem = dropdownValues[dataType]?.find(
                (option) => option.value.toLowerCase() === item.toLowerCase(),
              );

              return currentItem
                ? acc.concat({
                    key,
                    value: currentItem.value,
                    id: currentItem.id,
                    chipLabel: `${filtersList[key].title}: ${currentItem.label}`,
                    filterObject: filtersList[key],
                  })
                : acc.concat({
                    key,
                    value,
                    id: `${key}:${value}`,
                    chipLabel: `${filtersList[key].title}: ${value}`,
                    filterObject: filtersList[key],
                  });
            }, []);
            break;
          case FILTER_FIELDS.VENUES_TYPE:
            newValue = valueArray.reduce((acc, item) => {
              const currentItem = dropdownValues[dataType]?.find(
                (option) => option.value.toLowerCase() === item.toLowerCase(),
              );
              return currentItem
                ? acc.concat({
                    key,
                    value: currentItem.value,
                    id: currentItem.id,
                    chipLabel: `${filtersList[key].title}: ${currentItem.label}`,
                    filterObject: filtersList[key],
                  })
                : acc;
            }, []);
            break;
          default:
            break;
        }
        return newValue;
      },
    },
    [CHIPS_TYPES.DATETIME]: {
      getValue: (key, value) => {
        const [startDate, endDate] = value.split(',');
        const result = [];
        if (startDate) {
          result.push({
            key,
            value: startDate,
            chipLabel: `${filtersList[key].title.start} ${startDate}`,
            meta: { type: 'startDate' },
            filterObject: filtersList[key],
          });
        }
        if (endDate) {
          result.push({
            key,
            value: endDate,
            chipLabel: `${filtersList[key].title.end} ${endDate}`,
            meta: { type: 'endDate' },
            filterObject: filtersList[key],
          });
        }
        return result;
      },
    },
  };

  useEffect(() => {
    if (isEmpty(refList)) return;
    setChipsListHeight(refList.current.getBoundingClientRect().height || 0);
  }, [chips, setChipsListHeight, refList]);

  useEffect(() => {
    if (isEmpty(filterMapping)) return;

    try {
      setChips(
        Object.entries(filtersDataObject)
          .reduce((acc, [key, value]) => {
            const itemTypeObject = filtersList[key];
            if (itemTypeObject && value && value !== 'Any') {
              switch (itemTypeObject.chipsType) {
                case CHIPS_TYPES.CHECKBOX:
                  if (key === FILTER_FIELDS.RESIDENTIAL_LOCATION && !Array.isArray(value)) {
                    const chipsValuesArray = [];
                    const queryValuesArray = value.split(',');
                    queryValuesArray.forEach((residentialValue) => {
                      chipsValuesArray.push(chipsMapping[CHIPS_TYPES.CHECKBOX].getValue(key, residentialValue));
                    });
                    return acc.concat(...chipsValuesArray);
                  }
                  return acc.concat(chipsMapping[CHIPS_TYPES.CHECKBOX].getValue(key, value));
                case CHIPS_TYPES.RADIOBUTTON:
                  return acc.concat(chipsMapping[CHIPS_TYPES.RADIOBUTTON].getValue(key, value));
                case CHIPS_TYPES.RADIODROPDOWN:
                  return acc.concat(chipsMapping[CHIPS_TYPES.RADIODROPDOWN].getValue(key, value));
                case CHIPS_TYPES.TEXTINPUT:
                  return acc.concat(chipsMapping[CHIPS_TYPES.TEXTINPUT].getValue(key, value));
                case CHIPS_TYPES.MULTIDROPDOWN:
                  return acc.concat(
                    chipsMapping[CHIPS_TYPES.MULTIDROPDOWN].getValue(key, value, itemTypeObject.meta.dataType),
                  );
                case CHIPS_TYPES.DATETIME:
                  return acc.concat(chipsMapping[CHIPS_TYPES.DATETIME].getValue(key, value));
                case CHIPS_TYPES.QUERYSTRING:
                  return acc.concat(chipsMapping[CHIPS_TYPES.QUERYSTRING].getValue(key, value));
                default:
                  return acc;
              }
            }
            return acc;
          }, [])
          .flat(),
      );
    } catch (e) {
      console.log(e);
    }
  }, [
    filtersDataObject,
    jurisdictions,
    supervisoryOptions,
    agencies,
    cities,
    counties,
    newLocation,
    filterMapping,
    dropdownValues,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const [resJurisdictions, resAgencies] = await Promise.all([
          dispatch(getJurisdictions()),
          dispatch(getAgencies()),
        ]);
        const jurisdictionsList = resJurisdictions.then((res) =>
          res.data.map((item) => ({
            value: item.attributes.name,
            label: item.attributes.name,
            id: item.id,
            type: 'AggregationPolygon',
          })),
        );
        const agenciesList = resAgencies.then((res) =>
          res.data.map((item) => ({
            value: item.name,
            label: item.name,
            id: item.id,
            type: 'Agency',
          })),
        );
        setJurisdictions(jurisdictionsList);
        setAgencies(agenciesList);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const removeDatePickerError = (name) => {
    const newFilterErrors = Object.entries(filterErrors).reduce(
      (acc, [key, errorValue]) => (key === name ? acc : { ...acc, [key]: errorValue }),
      {},
    );
    dispatch(setFilterError(newFilterErrors || {}));
  };

  const deleteCalcpoly = (arr, item) => {
    return Array.isArray(arr) ? arr?.filter((e) => e !== item) : [];
  };

  // TODO: delete func for queryString
  const deleteChips = (item, index) => {
    if (item.key === FILTER_FIELDS.HAS_HAD_VISITS_CHECKBOX) {
      filtersDataObject[FILTER_FIELDS.HAS_HAD_VISITS] = '';
      filtersDataObject[FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE] = '';
      filtersDataObject[FILTER_FIELDS.HAS_HAD_VISITS_DROP] = '';
    }

    if (item.key === FILTER_FIELDS.NO_VISIT_TYPE_TYPE) {
      filtersDataObject[FILTER_FIELDS.NO_VISIT_TYPE] = '';
    }
    const setFiltersData = (filtersObject) => {
      const filters = { ...filtersObject };

      const newFilters = Object.entries(filters).reduce(
        (acc, [key, value]) => (isEmpty(value) ? acc : { ...acc, [key]: value }),
        {},
      );
      if (locationTitles?.includes(item.key)) {
        newFilters.calcpoly = deleteCalcpoly(newFilters?.calcpoly, item.value);
      }
      dispatch(applyFilters(newFilters));
      if (withoutScroll) {
        getApi({ filters: newFilters, page: 1 });
        setCurrentPage(1);
      } else {
        dispatch(setShadowFilter(filtersObject));
      }
    };

    switch (item.filterObject.chipsType) {
      case CHIPS_TYPES.CHECKBOX:
        if (item.key === FILTER_FIELDS.CORP_FLAG) {
          // eslint-disable-next-line camelcase
          const cloneSecGeogs = cloneDeep(filtersDataObject?.jurisdictions || filtersDataObject?.sec_geogs);
          const whichFilter = filtersDataObject.jurisdictions ? FILTER_FIELDS.JURISDICTIONS : FILTER_FIELDS.SEC_GEOGS;
          filtersDataObject[whichFilter] = Array.isArray(cloneSecGeogs)
            ? cloneSecGeogs?.filter((elem) => isUserJurisdictions(elem, jurisdictionsUserList))
            : isUserJurisdictions(cloneSecGeogs, jurisdictionsUserList) && cloneSecGeogs;
        }
        if (item.key === FILTER_FIELDS.RESIDENTIAL_LOCATION && !Array.isArray(filtersDataObject[item.key])) {
          const newValue = filtersDataObject[item.key]
            .split(',')
            .filter((qsItem) => qsItem !== item.value)
            .join(',');
          setFiltersData({ ...filtersDataObject, ...{ [item.key]: newValue } });
        }
        if (Array.isArray(filtersDataObject[item.key])) {
          const newArray = filtersDataObject[item.key].filter((qsItem) => qsItem !== item.value);
          setFiltersData({ ...filtersDataObject, ...{ [item.key]: newArray } });
        } else if (item.value === filtersDataObject[item.key]) {
          setFiltersData({ ...filtersDataObject, ...{ [item.key]: null } });
        }
        break;
      // return acc.concat(chipsMapping[CHIPSTYPES.CHECKBOX].getValue(key, value));
      case CHIPS_TYPES.MULTIDROPDOWN:
        if (Array.isArray(filtersDataObject[item.key])) {
          const newArray = filtersDataObject[item.key].filter((qsItem) => {
            const newTypeQsItem = [FILTER_FIELDS.SUPERVISORY_DISTRICTS, FILTER_FIELDS.AGENCIES].includes(item.key)
              ? +qsItem
              : qsItem;
            return ![item.id, item.value].includes(newTypeQsItem);
          });
          setFiltersData({ ...filtersDataObject, ...{ [item.key]: newArray } });
        } else {
          setFiltersData({ ...filtersDataObject, ...{ [item.key]: null } });
        }
        break;
      case CHIPS_TYPES.RADIOBUTTON:
      case CHIPS_TYPES.RADIODROPDOWN:
        if (filtersDataObject[item.key]) {
          setFiltersData({ ...filtersDataObject, ...{ [item.key]: null } });
        }
        break;
      case CHIPS_TYPES.TEXTINPUT:
        if (filtersDataObject[item.key]) {
          setFiltersData({ ...filtersDataObject, ...{ [item.key]: null } });
        }
        break;
      case CHIPS_TYPES.DATETIME:
        if (typeof filtersDataObject[item.key] === 'string') {
          const [startDate, endDate] = filtersDataObject[item.key].split(',');
          if (startDate && endDate && endDate !== startDate) {
            if (item.key === FILTER_FIELDS.VIOLATION_COUNT_GRT_DATEPICKER_RANGE) {
              setFiltersData({
                ...filtersDataObject,
                ...{
                  [item.key]: filtersDataObject[item.key].replace(item.value, ''),
                  violation_count_grt_data_range: filtersDataObject.violation_count_grt_data_range.replace(
                    item.value,
                    '',
                  ),
                },
              });
            } else {
              setFiltersData({
                ...filtersDataObject,
                ...{
                  [item.key]: filtersDataObject[item.key].replace(item.value, ''),
                },
              });
            }
          } else {
            if (startDate) {
              const newDate = filtersDataObject[item.key].slice(10).replace(',', '');
              if (isEmpty(newDate)) removeDatePickerError(item.key);
              if (item.key === FILTER_FIELDS.VIOLATION_COUNT_GRT_DATEPICKER_RANGE) {
                setFiltersData({
                  ...filtersDataObject,
                  ...{
                    [item.key]: newDate,
                    violation_count_grt_data_range: newDate,
                  },
                });
              } else {
                setFiltersData({
                  ...filtersDataObject,
                  ...{
                    [item.key]: newDate,
                  },
                });
              }
            }
            if (endDate) {
              const newDate = filtersDataObject[item.key].slice(0, -10).replace(',', '');
              if (isEmpty(newDate)) removeDatePickerError(item.key);
              if (item.key === FILTER_FIELDS.VIOLATION_COUNT_GRT_DATEPICKER_RANGE) {
                setFiltersData({
                  ...filtersDataObject,
                  ...{
                    [item.key]: newDate,
                    violation_count_grt_data_range: newDate,
                  },
                });
              } else {
                setFiltersData({
                  ...filtersDataObject,
                  ...{
                    [item.key]: newDate,
                  },
                });
              }
            }
          }
        }
        break;
      case CHIPS_TYPES.QUERYSTRING:
        if (filtersDataObject[item.key]) {
          if (isEmpty(filtersDataObject[item.key])) {
            setFiltersData({ ...filtersDataObject, ...{ [item.key]: null } });
          } else {
            const newValues = filtersDataObject[item.key]
              .split(',')
              .filter((queryItem) => queryItem !== item.value)
              .join(',');
            setFiltersData({ ...filtersDataObject, ...{ [item.key]: newValues } });
          }
        }
        break;
      default:
    }

    // TODO: it's fast win solution. refactor it
    const els = document.querySelectorAll(`${withoutScroll ? '' : '#filter-body'} #chips`);
    if (els.length <= 1) return;
    const el = els[index + 1];
    if (chips.length > 1 && isEmpty(el)) {
      els[index - 1].focus();
      return;
    }
    el.focus();
  };

  return (
    <Styled.Wrapper isShowAll={isShowAll} withoutScroll={withoutScroll}>
      <Styled.ChipListWrapper ref={refList}>
        <Styled.Title data-html2canvas-ignore>Selected filtering:</Styled.Title>
        {chips.some((item) => item !== null) && (
          <Styled.ClearFilters data-html2canvas-ignore onClick={handleClearAllFilters} id={uniqueId()}>
            Clear All
          </Styled.ClearFilters>
        )}
        {!isChipListDisabled && (
          <Styled.ToggleShowFilters
            data-html2canvas-ignore
            onClick={toggleIsShowAll}
            disabled={isChipListDisabled}
            aria-label={isShowAll ? 'Show less' : 'Show more'}
            id={uniqueId()}
          >
            {isShowAll ? 'Show less' : 'Show more'}
          </Styled.ToggleShowFilters>
        )}
        {chips
          .filter((item) => item !== null)
          .map((item, index) => (
            <Styled.ChipListWrapper key={item.chipLabel}>
              <Styled.Chip aria-label={`filter by ${item.chipLabel}`}>
                <Styled.ChipText>{item.chipLabel}</Styled.ChipText>
                <Styled.DeleteChip
                  data-html2canvas-ignore
                  id={uniqueId()}
                  onClick={() => deleteChips(item, index)}
                  aria-label={`Delete filter by ${item.chipLabel}`}
                >
                  <Img size={[11, 11]} src={REMOVE_ICON} aria-hidden />
                  <Styled.HiddenText>close</Styled.HiddenText>
                </Styled.DeleteChip>
              </Styled.Chip>
            </Styled.ChipListWrapper>
          ))}
      </Styled.ChipListWrapper>
    </Styled.Wrapper>
  );
};

ChipList.defaultProps = {
  withoutScroll: false,
};
export { ChipList };
