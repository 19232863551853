import React, { useState, useEffect } from 'react';
import { reduce } from 'lodash';
import { BaseSelect, Loader } from 'ui-kit';
import FILTER_FIELDS from '_constants/old/filters/FILTER_FIELDS';
import FILTER_OPTIONS from '_constants/old/filters/FILTER_OPTIONS';
import { useDispatch, useSelector } from 'react-redux';
import { FilterEntity, NewFiltersEntity } from '_entities';
import { getStringWithOutSpaces, getCalcpoly } from 'utils/custom';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const { setShadowFilter } = FilterEntity.actions;

const { getLocationMapping } = NewFiltersEntity.actions;

const { getLocationObj } = NewFiltersEntity.selectors;

function Location({ venue }) {
  const {
    filter: { shadowFilters, generalFilters },
  } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValue, setDefaultValue] = useState({});
  const [selectValue, setSelectValue] = useState({});
  const [venues, setVenues] = useState();
  const locationObj = useSelector(getLocationObj);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function getDataForLocation() {
      try {
        await dispatch(getLocationMapping());
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleChangeSelect = (value, field) => {
    setSelectValue({ ...selectValue, [field]: value });
    const values = value.map((elem) => elem.value);

    dispatch(
      setShadowFilter({
        ...shadowFilters,
        [field]: values,
        calcpoly: getCalcpoly(shadowFilters, values, field),
      }),
    );
  };
  const handleChangeVenue = (value, field) => {
    setSelectValue({ ...selectValue, [field]: value });
    setVenues(value);
    dispatch(
      setShadowFilter({
        ...shadowFilters,
        type: value.map((elem) => elem.value),
      }),
    );
  };

  const getDropDownValues = (locationObject, filters) => {
    return reduce(
      locationObject,
      (acc, { title, options }) => {
        return { ...acc, [title]: options.filter((item) => filters[title]?.includes(item.value)) || [] };
      },
      {},
    );
  };

  useEffect(() => {
    const venueResult = FILTER_OPTIONS.VENUES_TYPE_OPTIONS.filter((item) => generalFilters?.type?.includes(item.value));

    setVenues(venueResult);
    setDefaultValue({
      ...getDropDownValues(locationObj, generalFilters),
    });
  }, [generalFilters, locationObj]);

  useEffect(() => {
    setSelectValue({
      ...getDropDownValues(locationObj, shadowFilters),
    });
  }, [shadowFilters]);

  return (
    <Styled.LocationContainer>
      {isLoading && <Loader />}
      <Styled.Wrapper>
        {locationObj &&
          locationObj.map((elem) => {
            return (
              <Styled.SelectWrapper>
                <BaseSelect
                  onChange={(value) => {
                    handleChangeSelect(value, elem.title);
                  }}
                  isMulti
                  labelText={elem.title}
                  name={elem.title}
                  options={elem.options}
                  value={
                    // eslint-disable-next-line no-prototype-builtins
                    selectValue.hasOwnProperty(elem.title) ? selectValue[elem.title] : defaultValue[elem.title]
                  }
                />
              </Styled.SelectWrapper>
            );
          })}
        <Styled.SelectWrapper>
          {venue && (
            <BaseSelect
              onChange={(value) => {
                handleChangeVenue(value, getStringWithOutSpaces(FILTER_FIELDS.VENUES_TYPE));
              }}
              isMulti
              labelText="Venue type"
              name={getStringWithOutSpaces(FILTER_FIELDS.VENUES_TYPE)}
              options={FILTER_OPTIONS.VENUES_TYPE_OPTIONS}
              value={venues}
              menuPlacement="top"
            />
          )}
        </Styled.SelectWrapper>
      </Styled.Wrapper>
    </Styled.LocationContainer>
  );
}

Location.propTypes = {
  venue: PropTypes.bool,
};

Location.defaultProps = {
  venue: false,
};

export default Location;
