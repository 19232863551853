import styled from 'styled-components';

export const PolicyContainer = styled.div.attrs(() => ({ ariaRequired: true }))`
  padding: 24px 36px 50px;
`;

export const Main = styled.main``;

export const TitleH1 = styled.h1`
  font-size: 26px;
  text-align: center;
  margin: 0;
`;

export const TitleContainer = styled.div.attrs(() => ({ ariaRequired: true }))`
  display: flex;
  margin: 24px 0 33px 0;
  font-weight: 700;
`;

export const TextContainer = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: var(--dark);
`;

export const TitleH2 = styled.h2`
  font-size: 18px;
  margin: 50px 0 15px;
`;

export const ContentText = styled.p``;
