export const GET_RETAILER_CITY = 'GET_RETAILER_CITY';
export const GET_RETAILER_CITY_SUCCESS = 'GET_RETAILER_CITY_SUCCESS';
export const GET_RETAILER_CITY_FAIL = 'GET_RETAILER_CITY_FAIL';

export const ADD_RETAILER = 'ADD_RETAILER';
export const ADD_RETAILER_SUCCESS = 'ADD_RETAILER_SUCCESS';
export const ADD_RETAILER_FAIL = 'ADD_RETAILER_FAIL';

export const GET_RETAILER = 'GET_RETAILER';
export const GET_RETAILER_SUCCESS = 'GET_RETAILER_SUCCESS';
export const GET_RETAILER_FAIL = 'GET_RETAILER_FAIL';

export const EDIT_RETAILER = 'EDIT_RETAILER';
export const EDIT_RETAILER_SUCCESS = 'EDIT_RETAILER_SUCCESS';
export const EDIT_RETAILER_FAIL = 'EDIT_RETAILER_FAIL';

export const CHECK_RETAILER_LOCATION = 'CHECK_RETAILER_LOCATION';
export const CHECK_RETAILER_LOCATION_SUCCESS = 'CHECK_RETAILER_LOCATION_SUCCESS';
export const CHECK_RETAILER_LOCATION_FAIL = 'CHECK_RETAILER_LOCATION_FAIL';

export const RESET_RETAILER_DATA = 'RESET_RETAILER_DATA';

export const DELETE_RETAILERS = 'DELETE_RETAILERS';
export const DELETE_RETAILERS_SUCCESS = 'DELETE_RETAILERS_SUCCESS';
export const DELETE_RETAILERS_FAIL = 'DELETE_RETAILERS_FAIL';

export const CONFIRM_DELETE_RETAILERS = 'CONFIRM_DELETE_RETAILERS';
export const CONFIRM_DELETE_RETAILERS_SUCCESS = 'CONFIRM_DELETE_RETAILERS_SUCCESS';
export const CONFIRM_DELETE_RETAILERS_FAIL = 'CONFIRM_DELETE_RETAILERS_FAIL';

export const RESTORE_RETAILERS = 'RESTORE_RETAILERS';
export const RESTORE_RETAILERS_SUCCESS = 'RESTORE_RETAILERS_SUCCESS';
export const RESTORE_RETAILERS_FAIL = 'RESTORE_RETAILERS_FAIL';

export const RETAILERS_IMPORT_PROGRESS = 'RETAILERS_IMPORT_PROGRESS';
export const RETAILERS_IMPORT_PROGRESS_SUCCESS = 'RETAILERS_IMPORT_PROGRESS_SUCCESS';
export const RETAILERS_IMPORT_PROGRESS_FAIL = 'RETAILERS_IMPORT_PROGRESS_FAIL';

export const UPLOAD_RETAILERS = 'UPLOAD_RETAILERS';
export const UPLOAD_RETAILERS_SUCCESS = 'UPLOAD_RETAILERS_SUCCESS';
export const UPLOAD_RETAILERS_FAIL = 'UPLOAD_RETAILERS_FAIL';

export const RESET_RETAILER_IMPORT_DATA = 'RESET_RETAILER_IMPORT_DATA';
export const SET_RETAILER_TOAST_ID = 'SET_RETAILER_TOAST_ID';
