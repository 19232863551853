import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import MenuItem from './MenuItem';

function MenuItems({ routes, setIsMenuOpen, setIsBurgerMenu, parentRef, isMenuOpen, handleSetParentFocus, burgerRef }) {
  const [focusedTab, setFocusedTab] = useState(1);

  const routesRefs = routes.reduce((acc, item, index) => ({ ...acc, [index + 1]: createRef() }), {});

  const handleNextTab = (firstTabInRound, nextTab, lastTabInRound) => {
    const tabToSelect = focusedTab === lastTabInRound ? firstTabInRound : nextTab;
    setFocusedTab(tabToSelect);
    routesRefs[tabToSelect].current.focus();
  };

  const handleKeyPress = (e) => {
    e.stopPropagation();
    if (!isMenuOpen) return;
    const tabCount = routes.length;

    if ([38].includes(e.keyCode)) {
      e.preventDefault();
      const last = tabCount;
      const next = focusedTab - 1;
      handleNextTab(last, next, 1);
    }
    if ([40].includes(e.keyCode)) {
      e.preventDefault();
      const first = 1;
      const next = focusedTab + 1;
      handleNextTab(first, next, tabCount);
    }
  };

  useEffect(() => {
    if (!isMenuOpen) return;
    routesRefs[1].current?.focus();
    // eslint-disable-next-line
  }, [isMenuOpen]);

  return (
    <>
      <Styled.List role="menu" aria-label="Nested navigation" onKeyDown={handleKeyPress}>
        {routes.map(
          (route, index) =>
            route.isShow && (
              <MenuItem
                route={route}
                key={route.id}
                setIsMenuOpen={setIsMenuOpen}
                setIsBurgerMenu={setIsBurgerMenu}
                parentRef={parentRef}
                routeRef={routesRefs[index + 1]}
                index={index + 1}
                focusedTab={focusedTab}
                setFocusedTab={setFocusedTab}
                handleSetParentFocus={handleSetParentFocus}
                burgerRef={burgerRef}
              />
            ),
        )}
      </Styled.List>
    </>
  );
}

MenuItems.propTypes = {
  routes: PropTypes.array.isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
  setIsBurgerMenu: PropTypes.func.isRequired,
  parentRef: PropTypes.object.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  handleSetParentFocus: PropTypes.func.isRequired,
  burgerRef: PropTypes.object.isRequired,
};

export default MenuItems;
