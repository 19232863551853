import * as actions from './Youth.actions';
import * as services from './Youth.services';
import * as selectors from './Youth.selectors';
import reducer from './Youth.reducer';

export default {
  actions,
  reducer,
  services,
  selectors,
};
