/* eslint-disable react/prop-types */
import React from 'react';
import styles from '_constants/old/_constants/index';
import { BaseSelect } from 'ui-kit';
import { getArraySelect } from 'utils/old/getArraySelect';
import { Wrapper, WrapperBlock, WrapperSelect } from './filter.style';
import FilterIcon from './icon/filterIcon';
import SearchBlock from './searchBlock/searchBlock';
import { Button } from '../styles/button.style';

const {
  BUTTON_STYLES: { FILTER },
} = styles;

function getFilterComponent(type, handleClick, value, onChange, options) {
  let filterComponent;
  if (type === 'button') {
    filterComponent = (
      <Button
        onClick={handleClick}
        type="button"
        buttonType={FILTER}
        role="button"
        aria-label="Filter"
        id="filterButton"
      >
        <FilterIcon />
        Filter
      </Button>
    );
  } else if (type === 'null') {
    filterComponent = <></>;
  } else {
    filterComponent = (
      <>
        <WrapperSelect>
          <BaseSelect
            inputId="filterDropDown"
            isSearchable={false}
            value={{ label: value }}
            onChange={(data) => onChange(data)}
            options={getArraySelect(options)}
          />
        </WrapperSelect>
      </>
    );
  }
  return filterComponent;
}

const Filter = ({
  placeholder,
  type,
  value,
  options,
  onChange,
  onChangeInput,
  handleClickInput,
  handleClick,
  onKeyDown,
  onRemoveSearch,
  searchValue,
  isWidth = false,
}) => (
  <Wrapper>
    <WrapperBlock type="button">
      <SearchBlock
        placeholder={placeholder}
        onChangeInput={onChangeInput}
        handleClick={handleClickInput}
        onKeyDown={onKeyDown}
        isWidth={isWidth}
        searchValue={searchValue}
        onRemoveSearch={onRemoveSearch}
      />
      {getFilterComponent(type, handleClick, value, onChange, options)}
    </WrapperBlock>
  </Wrapper>
);

export default Filter;
