import styled, { css } from 'styled-components';

export const Link = styled.div`
  ${({ disabled }) => css`
    width: 100px;
    height: 100px;
    position: relative;
    z-index: 2;

    ${!disabled &&
    css`
      :hover {
        cursor: pointer;
      }
    `};
  `}
`;
