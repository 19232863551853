import { useState, useEffect } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { idbService } from 'utils/services';

const useIsReadyAndOffline = () => {
  const isOnline = useOnlineStatus();
  const [isReadyAndOffline, setIsReadyAndOffline] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isOnline) {
        try {
          const db = await idbService.initiateIDB();
          const readyData = await db.transaction('ready').objectStore('ready').get('ready');
          setIsReadyAndOffline(!!readyData?.isReady);
        } catch (e) {
          setIsReadyAndOffline(false);
        }
      } else {
        setIsReadyAndOffline(false);
      }
    })();
  }, [isOnline]);

  return isReadyAndOffline;
};

export default useIsReadyAndOffline;
