import React, { memo, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { INPUT_TYPE } from '_constants';

import * as Styled from './styles';

function TextArea(
  {
    required,
    placeholder,
    title,
    type,
    name,
    value,
    error,
    hideErrorText,
    className,
    loading,
    floatingLabel,
    disabled,
    onKeyDown,
    onFocus,
    hasError: inputHasError,
    autoFocus,
    ...inputProps
  },
  ref,
) {
  const hasError = useMemo(() => {
    if (!value && disabled) return false;
    return !disabled && (inputHasError || error);
  }, [value, disabled, inputHasError, error]);

  return (
    <Styled.MainInputWrapper className={className} hasError={hasError} disabled={disabled}>
      {floatingLabel && !isEmpty(title) && (
        <Styled.Title htmlFor={name} disabled={disabled}>
          {title}
          {required && <Styled.RequiredDot> *</Styled.RequiredDot>}
        </Styled.Title>
      )}

      <Styled.InputWrapper disabled={disabled}>
        <Styled.TextArea
          {...inputProps}
          ref={ref}
          id={name}
          name={name}
          placeholder={placeholder || ''}
          type={type === 'number' ? '' : type}
          value={value}
          onFocus={onFocus}
          disabled={disabled}
          hasError={hasError}
          aria-invalid={hasError}
          autoFocus={autoFocus}
        />
      </Styled.InputWrapper>
      <Styled.Error aria-label="error">{!disabled && error && !hideErrorText && error}</Styled.Error>
    </Styled.MainInputWrapper>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password']),
  title: PropTypes.string,
  optional: PropTypes.bool,
  hideErrorText: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(INPUT_TYPE)),
  error: PropTypes.string,
  success: PropTypes.string,
  icon: PropTypes.string,
  clearIcon: PropTypes.node,
  imgSize: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
  hasError: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

/* Input default props */

TextArea.defaultProps = {
  className: undefined,
  required: false,
  autoComplete: 'new-password',
  type: INPUT_TYPE.TEXT,
  optional: false,
  value: '',
  title: '',
  placeholder: '',
  hideErrorText: false,
  hasError: false,
  error: null,
  success: null,
  onBlur: () => {},
  onFocus: () => {},
  icon: '',
  imgSize: null,
  loading: false,
  floatingLabel: true,
  disabled: false,
  clearIcon: undefined,
  onKeyDown: undefined,
  autoFocus: false,
};

export default memo(forwardRef(TextArea));
