import { DEFAULT_SORT } from '_constants';

export const sortOptions = {
  sortBy: DEFAULT_SORT.campaignList.prop,
  sortOrder: DEFAULT_SORT.campaignList.order,
};

const COLUMNS = [
  {
    label: 'Campaign name ',
    value: 'campaign_name',
  },
  {
    label: 'Assignments',
    value: 'count_assignments',
  },
  {
    label: 'Assignments completed',
    value: 'completed_assignments',
  },
  {
    label: 'Agency',
    value: 'agency_name',
  },
  {
    label: 'Campaign manager',
    value: 'campaign_manager__first_name',
  },
  {
    label: 'Secondary geography',
    value: 'sec_geog_name',
  },
  {
    label: 'Form',
    value: 'form__name',
  },
];

const INITIAL_COLUMNS = [
  'checkbox',
  'archive',
  'campaign_name',
  'assignments',
  'assignments_completed',
  'agency',
  'manager',
  'form',
  'secondary_geography',
];

export { COLUMNS, INITIAL_COLUMNS };
