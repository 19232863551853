import styled from 'styled-components';
import { DEVICES } from '_constants';
import { Wrapper as DropDownWrapper } from 'ui-kit/old/ExportDataDropDown/styles';

export const Wrapper = styled.div`
  height: 100%;
  padding: 40px 25px 50px;

  @media ${DEVICES.tablet} {
    padding: 24px 16px;
  }
`;

export const TopLine = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 25px;

  @media ${DEVICES.tablet} {
    display: block;
    margin-bottom: 35px;
  }
`;

export const TopLineLeft = styled.div`
  @media ${DEVICES.tablet} {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
`;

export const TopLineRight = styled.div`
  display: flex;
  flex-flow: row nowrap;

  @media ${DEVICES.tablet} {
    display: block;
  }

  ${DropDownWrapper} {
    min-width: 242px;
    margin-right: 16px;

    .BaseSelect__control {
      width: 100%;
    }

    @media ${DEVICES.tablet} {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  > button {
    min-width: 170px;
    padding: 0 10px;

    @media ${DEVICES.tablet} {
      width: 100%;
    }
  }
`;
