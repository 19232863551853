/* eslint-disable react/prop-types */
import React from 'react';
import VisitsLists from 'modules/VisitsListPage';
import { VisitEntity, AuthEntity } from '_entities';
import processQSParams from 'utils/old/processQSParams';
import { useSelector } from 'react-redux';
import VISIT_TYPE from '_constants/VISIT_TYPE';
import { FILTER_TYPES } from '_constants/old/filters';
import getInitialColumns from './getInitColumns';

const ASSESSMENT_HEADER = 'Assessment visits';

const { getAssesmentList } = VisitEntity.actions;
const { getTimeZone } = AuthEntity.selectors;
const { getAssessmentTableData } = VisitEntity.selectors;

const { ASSESSMENT } = VISIT_TYPE;

const AssessmentContainerList = ({ location }) => {
  const tableData = useSelector(getAssessmentTableData);
  const timeZone = useSelector(getTimeZone);

  const getAssesment = async ({ page = 1, perPage, sortBy, sortOrder, ...qsParams }, dispatch) => {
    const params = processQSParams({
      page,
      perPage,
      sortOrder,
      sortBy,
      ...qsParams,
    });
    await dispatch(getAssesmentList(params, timeZone));
  };

  return (
    <VisitsLists
      tableAPIcall={getAssesment}
      titleHeader={ASSESSMENT_HEADER}
      tableData={tableData}
      visitType={ASSESSMENT}
      getInitColumns={getInitialColumns}
      filterType={FILTER_TYPES.ASSESSMENT}
      search={location.search}
    />
  );
};

export default AssessmentContainerList;
