import styled from 'styled-components';
import { Content } from 'ui-kit/Section/styles';
import { ReactPickerWrapper } from 'ui-kit/Calendar/styles';
import { InputWrapper as TextInputWrapper } from 'ui-kit/Input/styles';
import { InputWrapper as TextAreaWrapper } from 'ui-kit/TextArea/styles';
import { ErrorWrapper } from 'ui-kit/BaseSelect/styles';
import { RadioGroupWrapper } from 'ui-kit/RadioButton/styles';
import { RowWrapper } from 'modules/VisitView/styles';

export const Header = styled.header`
  margin-bottom: 40px;
`;

export const TitleCol = styled.div`
  > a {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  > button {
    min-width: 160px;
  }

  :first-of-type {
    align-items: center;
    margin-bottom: 25px;
  }
`;

export const RowWrapperEdit = styled(RowWrapper)`
  ${TextAreaWrapper},
  ${ErrorWrapper},
  ${TextInputWrapper},
  ${ReactPickerWrapper} {
    max-width: 320px;
  }

  section {
    height: 100% !important;
  }

  ${Content} {
    :last-of-type {
      ${RadioGroupWrapper} {
        margin-bottom: 40px;
      }
    }
  }
`;

export const Text = styled.p`
  flex: 1 0;
  font-size: 16px;
  font-weight: normal;

  :first-of-type {
    margin-right: 20px;
  }
`;

export const TextValue = styled.span`
  font-weight: 500;
`;

export const DL = styled.dl`
  padding: 25px 20px;
  :not(:last-of-type) {
    border-bottom: 1px solid var(--grey);
  }
`;

export const DT = styled.dt`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const DD = styled.dd`
  :not(:first-of-type) {
    margin-top: 35px;
  }
`;
