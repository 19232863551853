import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserEntity, YouthEntity } from '_entities';
import { Loader } from 'ui-kit';
import AddYouth from 'pages/Youth/Add';
import { getTimeZone } from '_entities/Auth/Auth.selectors';

const { getAgenciesList, getSecGeoList } = UserEntity.actions;
const { getYouth } = YouthEntity.actions;

function EditYouth() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const timeZone = useSelector(getTimeZone);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([dispatch(getYouth(id, timeZone)), dispatch(getAgenciesList()), dispatch(getSecGeoList())]);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(() => false);
      }
    })();
  }, []);

  if (isLoading) return <Loader />;

  return <AddYouth isEditPage />;
}

export default EditYouth;
