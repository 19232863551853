import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { ButtonTab } from './tab.style';

const Tab = ({ title, isActive, onChange, index, focusedTab, tabRef, setFocusedTab }) => {
  const [isClick, setIsClick] = useState(isActive);

  useEffect(() => {
    setIsClick(isActive);
  }, [isActive]);

  const handleClick = useCallback(() => {
    setIsClick(!isClick);
    setFocusedTab(index);
    onChange({ value: title, label: title });
  }, [index, isClick, onChange, setFocusedTab, title]);

  return (
    <ButtonTab
      className="table-button"
      isClicked={isClick}
      onClick={handleClick}
      tabIndex={focusedTab === index ? 0 : -1}
      aria-label={title}
      ref={tabRef}
      id={uniqueId()}
    >
      {title}
    </ButtonTab>
  );
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.object.isRequired,
  focusedTab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  tabRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]).isRequired,
  onChange: PropTypes.func.isRequired,
  setFocusedTab: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  isActive: false,
};

export default Tab;
