const COLUMNS = [
  {
    label: 'Retailer name ',
    value: 'retailer__name',
  },
  {
    label: 'Address',
    value: 'retailer__address',
  },
  {
    label: 'City',
    value: 'retailer__city',
  },
  {
    label: 'Zip code',
    value: 'retailer__zipcode',
  },
  {
    label: 'Data collector',
    value: 'usersList',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

const INITIAL_COLUMNS = [
  'retailer__name',
  'retailer__address',
  'retailer__city',
  'retailer__zipcode',
  'usersList',
  'visits',
  'hasViolation',
  'status',
];

export { COLUMNS, INITIAL_COLUMNS };
