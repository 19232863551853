import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  .BaseSelect__control {
    width: 136px;
  }

  span {
    padding: 5px;
  }

  .styled-label {
    padding: 6px;
  }
`;

export const PickerWrapper = styled.div`
  display: ${({ isShow }) => !isShow && 'none'};
`;
