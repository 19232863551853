import FILTER_FIELDS from '_constants/old/filters/FILTER_FIELDS';
import FILTER_OPTIONS from '_constants/old/filters/FILTER_OPTIONS';
import { CHIPS_TYPES, DROPDOWN_DATA_TYPES } from '../../components/ChipsList/constants';

export default {
  [FILTER_FIELDS.SEC_GEOGS]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.JURISDICTIONS,
    },
    title: 'Jurisdictions',
  },
  [FILTER_FIELDS.JURISDICTIONS]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.JURISDICTIONS,
    },
    title: 'Jurisdictions',
  },
  [FILTER_FIELDS.INCORP_AREA]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.INCORPORATED_CITY_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.INCORPORATED_AREA]: {
    chipsType: CHIPS_TYPES.checkbox,
    title: FILTER_OPTIONS.INCORPORATED_CITY_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CITIES]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.CITY,
    },
    title: 'City',
  },
  [FILTER_FIELDS.LOCATION_CITY]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.CITY,
    },
    title: 'City',
  },
  [FILTER_FIELDS.LOCATION_COUNTY]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.COUNTRY,
    },
    title: 'County',
  },
  [FILTER_FIELDS.COUNTY]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.COUNTY,
    },
    title: 'County',
  },
  [FILTER_FIELDS.TOWN]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.TOWN,
    },
    title: 'Town',
  },
  [FILTER_FIELDS.COLLABORATIVE]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.COLLABORATIVE,
    },
    title: 'Collaborative',
  },
  [FILTER_FIELDS.ENTIRE_STATE]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.ENTIRE_STATE,
    },
    title: 'Entire State',
  },
  [FILTER_FIELDS.CITY]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.CITY,
    },
    title: 'City',
  },
  [FILTER_FIELDS.CSB]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.CSB,
    },
    title: 'CSB',
  },
  [FILTER_FIELDS.ZIP_CODE]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.ZIP_CODE,
    },
    title: 'Zip code',
  },
  [FILTER_FIELDS.DHEC_REGION]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.DHEC_REGION,
    },
    title: 'DHEC Region',
  },
  [FILTER_FIELDS.SUPERVISORY_DISTRICTS]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.SUPERVISORY,
    },
    title: 'Supervisory district',
  },
};
