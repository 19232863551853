/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState } from 'react';

import { TableWrapper } from 'ui-kit';
import useSortTable from 'utils/old/useSortTable';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { URLS, PERMISSIONS } from '_constants';
import HasAccess from 'modules/AccessControl/hasAccess';

const CollectTableBase = ({
  data,
  sortHandler,
  sortOption,
  getInitColumns,
  initialColumns,
  columns,
  tableViewMode,
  tableVievModeButtonName,
  handleCheckbox,
  checkboxList,
}) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [activeColumns, setActiveColumns] = useState(initialColumns);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();
  const hasAccess = HasAccess(PERMISSIONS.CHANGE_CAMPAIGN);

  const getToast = () => getToastLock('Campaign', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );

  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: 'campaign',
      route: URLS.campaignPages.edit,
      getToast,
      onlyEdit: true,
    }),
    [],
  );

  const checkboxCellParams = useMemo(
    () => ({
      tableViewMode,
      handleCheckbox,
      checkboxList,
      hasAccess,
    }),
    [tableViewMode, handleCheckbox, checkboxList],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams,
        getToast, // eslint-disable-next-line
        tableViewMode,
        hasAccess,
        handleCheckbox,
        checkboxList,
      }),
    [dumpedActiveColumns, sortOption, tableControlParams, handleSort, checkboxList, hasAccess],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data || []}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
      checkboxCellParams={checkboxCellParams}
      tableVievModeButtonName={tableVievModeButtonName}
    />
  );
};

export default memo(CollectTableBase);
