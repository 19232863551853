/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import { deviceLess } from '_constants/DEVICES';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ centerContent }) =>
    centerContent &&
    css`
      @media ${deviceLess.laptop} {
        margin: auto;
      }
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;

  svg {
    width: 13px;
    height: 13px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: normal;
  ${({ isAssigned }) =>
    isAssigned &&
    css`
      padding: ${() => (isAssigned === 'Yes' ? '10px' : '10px 20px')};
      background-color: ${() => (isAssigned === 'Yes' ? 'var(--soft-green)' : 'var(--soft-grey)')};
    `}
  color: #0d0d0d;
`;
