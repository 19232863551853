/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import { CollapsedTab } from 'modules/Modals/old/FilterModal/components/CollapsedTab';
import { NewFiltersEntity } from '_entities';
import { IsVaAccount, HasAccess } from 'modules';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'ui-kit';
import Location from 'modules/Modals/FilterPages/Location';
import { PERMISSIONS } from '_constants';
import { getEnforcementRetailerCharacteristics } from './enforcementRetailerCharacteristicsTabs';
import * as Styled from './styles';

const { getCorporation } = NewFiltersEntity.actions;
const { getRetailerCorporationOptions } = NewFiltersEntity.selectors;

function VisitsRetailerCharacteristics() {
  const [isLoading, setIsLoading] = useState(true);
  const corporationOptions = useSelector(getRetailerCorporationOptions);
  const isVaAccount = IsVaAccount();
  const hasResidentialModule = HasAccess(PERMISSIONS.RESIDENTIAL_LOCATION);
  const dispatch = useDispatch();
  useEffect(() => {
    (async function getDataForCorporation() {
      try {
        await dispatch(getCorporation());
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  const tabs = getEnforcementRetailerCharacteristics(corporationOptions, isVaAccount, hasResidentialModule);

  const getElement = (item) => {
    switch (item.type) {
      case 'single':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            <CheckedList name={item.field} options={item.options} />
          </CollapsedTab>
        );
      case 'multiple':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            {item.collapsed.map((elem) => (
              <Styled.FiltersContainer>
                <Styled.FiltersTitle isSubcategory={elem?.isSubcategory}>{elem.title}</Styled.FiltersTitle>
                <CheckedList name={elem.field} options={elem.options} isSubcategory={elem?.isSubcategory} />
              </Styled.FiltersContainer>
            ))}
          </CollapsedTab>
        );
      case 'double':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            {item.collapsed.map((elem) => (
              <CheckedList name={elem.field} options={elem.options} />
            ))}
          </CollapsedTab>
        );
      default:
        return null;
    }
  };

  return (
    <Styled.FilterContainer>
      {isLoading && <Loader />}
      <Styled.Wrapper>
        {tabs.map((item) => getElement(item))}
        <CollapsedTab title="Location" key="152">
          <Location />
        </CollapsedTab>
      </Styled.Wrapper>
    </Styled.FilterContainer>
  );
}

export default VisitsRetailerCharacteristics;
