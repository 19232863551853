/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-indent */
/* eslint-disable-next-line no-shadow */

import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { InputWrap, UiColors } from 'assets/styles/old/global';
// eslint-disable-next-line import/no-cycle

const modalType = (type, style, alternativeStyle) => {
  switch (type) {
    case 'Attention':
    case 'PII':
      return style;
    case 'closeFreeTextFields':
      return style;
    default:
      return alternativeStyle;
  }
};

const WrapperDropdown = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  margin-bottom: 27px;
  ${({ isRow }) =>
    isRow &&
    css`
      display: flex;
      align-items: center;
    `};

  @media ${DEVICES.tablet} {
    width: 100%;
    margin-bottom: 15px;
    ${({ isMerger }) =>
      isMerger &&
      css`
        margin-bottom: 10px;
      `}
  }

  @media ${DEVICES.mobileL} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

const modalCloseType = () => {
  // if (type !== "close" || type !== "copyUser") {
  //   return css`
  //     display: none;
  //   `;
  // }
};

const CloseWindowContainer = styled.div`
  position: relative;
  float: right;
  margin: 10px 10px 5px 10px;
  z-index: 802;
`;

const CloseWindow = styled.button.attrs(() => ({
  ariaRequired: true,
  ariaPressed: 'false',
}))`
  display: block;
  font-size: 17px;
  cursor: pointer;
  position: relative;
  border: none;
  background: transparent;
  float: right;
  margin: 10px 10px 5px 10px;
  z-index: 99999;

  ${({ isPopUpWithCross }) =>
    isPopUpWithCross &&
    css`
      position: absolute;
      right: 15px;
      top: 0;
    `};

  @media ${DEVICES.mobileL} {
    margin: 12px 15px 0 0;
    ${({ isAgencies }) =>
      isAgencies &&
      css`
        margin: 0;
        padding: 16px;
      `}
  }
  @media ${DEVICES.tablet} {
    ${({ isAgencies }) =>
      isAgencies &&
      css`
        margin: 0;
        padding: 16px;
      `}
  }

  ${({ type }) =>
    type === 'mergerEditPopup' &&
    css`
      @media ${DEVICES.tablet} {
        margin: 5px 10px 0px 0;
        height: 15px;
        display: flex;
        img {
          width: 8px;
          height: 8px;
        }
      }
      @media ${DEVICES.mobileL} {
        /* top: 5px; */
      }
    `};
  ${({ type }) =>
    type === 'mergerPopup' &&
    css`
      @media ${DEVICES.tablet} {
        margin-top: 0px;
        z-index: 99;
        position: absolute;
        top: -3px;
        left: calc(100vw - 17px);
        img {
          width: 8px;
          height: 8px;
        }
      }
      @media ${DEVICES.mobileL} {
        margin: -7px 11px 0 10px;
        top: 2px;
        left: calc(100vw - 25px);
      }
    `};
  ${({ type }) =>
    type === 'popupMap' &&
    css`
      padding: 0;
      margin-top: 10px;

      img {
        height: 13px;
        width: 13px;
      }
      /* @supports (display:block){
        margin-top: 56px;
      } */
      @media ${DEVICES.tablet} {
        margin-top: 0px;
        z-index: 99;
        position: absolute;
        top: 17px;
        left: calc(100vw - 39px);
      }
      @media ${DEVICES.mobileL} {
        margin: 0;
        margin-top: 0px;
      }
    `};

  ${({ type }) => type === 'mergerEditPopup' && css``};
  ${({ type }) =>
    type === 'popupAgencies' &&
    css`
      display: block;
      @media ${DEVICES.tablet} {
        display: block;
        margin: 12px 15px 0 0;
        padding: 10px 16px;
        img {
          height: 13px;
          width: 13px;
        }
      }
      @media ${DEVICES.mobileL} {
        margin: 12px 15px 0 0;
        padding: 0px 10px;

        img {
          height: 10px;
          width: 10px;
        }
      }
    `};

  ${({ headerType }) => modalCloseType(headerType)};

  ${({ type }) =>
    type === 'AddRetailerInfoButtons' &&
    css`
      @media ${DEVICES.tablet} {
        margin: 5px 15px 0 0;
      }
    `};

  ${({ type }) =>
    ['close', 'discard'].includes(type) &&
    css`
      @media ${DEVICES.tablet} {
        margin: 0;
        img {
          height: 10px;
          width: 10px;
        }
      }
      @media ${DEVICES.mobileL} {
        img {
          height: 10px;
          width: 10px;
        }
        margin: 0;
      }
    `};

  ${({ type }) =>
    type === 'closeRetailer' &&
    css`
      margin: 10px 16px 0 0;
      @media ${DEVICES.tablet} {
        margin: 0px 10px 0 0;
        img {
          height: 10px;
          width: 10px;
        }
      }
      @media ${DEVICES.mobileL} {
        img {
          height: 10px;
          width: 10px;
        }
        margin-top: 10px;
        margin: 0px 20px 0 0;
      }
    `};
  ${({ type }) =>
    type === 'agenciesInfo' &&
    css`
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      @media ${DEVICES.tablet} {
        margin-top: 10px;
      }
    `};

  ${({ scrollY }) =>
    scrollY > 56 &&
    css`
      margin-top: 20px;
      transition: all 0.5s;
    `};
`;

const WrapperContainer = styled.div`
  ${({ type }) =>
    type === 'popupAgencies' &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `};

  .react-datepicker-popper {
    @media ${DEVICES.header} {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  .react-datepicker {
    &__triangle {
      @media ${DEVICES.header} {
        display: none;
      }
    }
  }
`;

const WrapperModal = styled.div.attrs(() => ({
  role: 'dialog',
}))`
  position: ${({ type }) => (type === 'popupAgencies' ? 'static' : 'relative')};
  //Don't change this value!!!
  z-index: 801;
  top: ${({ type }) => type === 'AddRetailerInfoButtons' && '10px'};

  @supports (display: grid) {
    max-height: none;
    padding: 0;
    overflow-y: hidden;

    top: 0;
    overflow-y: ${({ type }) => ['mergerEditPopup', 'addRetailerInfo'].includes(type) && `visible`};

    ${WrapperContainer} {
      height: ${({ type }) => type === 'mergerPopup' && `100%`};
    }
    ${({ type }) =>
      type === 'mergerPopup' &&
      css`
        ${WrapperContainer} {
          max-height: 100%;
        }
      `};
  }
  -ms-overflow-style: ${({ type }) => type === 'PII' && 'scrollbar'};
  overflow-y: ${({ type }) => type === 'PII' && 'auto'};
  background: ${({ type }) => (type === 'popupMap' ? 'none' : 'white')};

  p {
    margin: 0;
    padding: 0;
  }

  overflow-y: ${({ type }) => ['mergerEditPopup', 'addRetailerInfo'].includes(type) && `visible`};

  @media ${DEVICES.tablet} {
    max-height: 250px;
    ${WrapperContainer} {
      max-height: 250px;
    }
    @supports (display: grid) {
      max-height: none;
      padding: 0;
      overflow-y: hidden;
      overflow-y: ${({ type }) => ['mergerEditPopup', 'addRetailerInfo'].includes(type) && `visible`};
      ${WrapperContainer} {
        max-height: none;
      }
    }
  }

  @media ${DEVICES.mobileL} {
    width: 95%;
    ${({ type }) =>
      type === 'popupMap' &&
      css`
        width: 100% !important;
      `}
  }
`;

const modalPaddingType = (type, scrollY) => {
  if (type === 'AddRetailerInfoButtons') {
    return css`
      @media ${DEVICES.mobileL} {
        height: 220px;
        overflow-y: auto;
        span {
          font-size: 14px;
        }
      }
      @media ${DEVICES.mobileL} {
        span {
          font-size: 18px;
        }
      }
    `;
  }
  if (['close', 'discard'].includes(type)) {
    return css`
      padding: 36px 63px 27px 43px;
      width: 460px;
      margin: 0 auto;
      box-sizing: border-box;
      z-index: 9;

      @media ${DEVICES.tablet} {
        padding: 23px 23px 14px;
        width: 327px;
        img {
          width: 10px;
          height: 10px;
        }
      }
      @media ${DEVICES.mobileL} {
        padding: 23px 16px 14px;
        width: 300px;
      }
    `;
  }
  if (type === 'closeRetailer') {
    return css`
      padding: 46px 63px 27px 43px;
      width: 410px;
      margin: 0 auto;
      box-sizing: border-box;

      @media ${DEVICES.tablet} {
        padding: 23px 23px 14px;
        width: 327px;
        img {
          width: 10px;
          height: 10px;
        }
      }
      @media ${DEVICES.mobileL} {
        width: 310px;
      }
    `;
  }

  if (type === 'mergerEditPopup') {
    return css`
      padding: 30px 40px;
      width: 547px;
      margin: 0 auto;
      box-sizing: border-box;

      @media ${DEVICES.tablet} {
        padding: 0px 20px 0px 20px;
        width: 100%;
        height: 200px;
        width: 100%;
        overflow-y: auto;
      }
    `;
  }
  if (type === 'AddRetailerInfoButtons') {
    return css`
      padding: 20px;
      @supports (display: block) {
        @media ${DEVICES.tablet} {
          div {
            div {
              padding-top: 0;
            }
          }
        }
      }
      @media ${DEVICES.mobileL} {
        div {
          div {
            padding-top: 0;
          }
        }
      }
    `;
  }
  if (type === 'mergerPopup') {
    return css`
      padding: 52px 0px 0px 0px;
      width: 703px;
      box-sizing: border-box;
      @media ${DEVICES.tablet} {
        padding: 37px 0px 0px 0px;
        width: 100%;
        height: 100vh;
      }

      @media ${DEVICES.mobileL} {
        width: 100%;
        height: 100vh;
      }
    `;
  }

  if (type === 'copyUser') {
    return css`
      padding: 46px 63px 27px 43px;
      width: 460px;
      margin: 0 auto;
      box-sizing: border-box;

      @media ${DEVICES.tablet} {
        width: 100%;
        padding: 35px 25px 25px;
      }
    `;
  }

  if (type === 'license') {
    return css`
      padding: 46px 63px 27px 43px;
      width: 460px;
      margin: 0 auto;
      box-sizing: border-box;

      @media ${DEVICES.mobileL} {
        width: 100%;
        padding: 25px 15px 11px;
      }
    `;
  }
  if (type === 'discard') {
    return css`
      font-size: 20px;
      max-width: 440px;
      box-sizing: border-box;
      padding: 46px 63px 27px 43px;
      line-height: 28px;

      div {
        line-height: 28px;
        font-size: 20px;
      }

      @media ${DEVICES.tablet} {
        max-width: none;
      }

      @media ${DEVICES.mobileL} {
        div {
          line-height: 24px;
          font-size: 18px;
        }
      }
    `;
  }
  if (type === 'agenciesInfo') {
    return css`
      display: none;

      @media ${DEVICES.tablet} {
        width: 440px;
        box-sizing: border-box;
        padding: 46px 63px 27px 43px;
        line-height: 28px;

        div {
          line-height: 28px;
          font-size: 16px;
          color: ${UiColors.dimGreyFillColor};
        }

        button {
        }
      }

      @media ${DEVICES.mobileL} {
        div {
          line-height: 24px;
          font-size: 18px;
        }
      }
    `;
  }
  if (type === 'closeFreeTextFields') {
    return css`
      width: 636px;
      padding: 46px 63px 15px 47px;
      box-sizing: border-box;

      @media ${DEVICES.tablet} {
        width: 400px;
        padding: 15px;
      }
    `;
  }
  if (type === 'popupMap') {
    return css`
      width: 502px;

      @supports (display: block) {
        width: 490px;
      }

      height: 646px;
      height: calc(100vh + 56px);

      ${scrollY > 56 &&
      css`
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      `};
      padding: 40px 0px 15px 0px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: 6px 0px 7px rgba(0, 0, 0, 0.07);
      box-shadow: 6px 0px 7px rgba(0, 0, 0, 0.07);

      @media ${DEVICES.tablet} {
        margin: 0;
        width: 100vw;
        height: 100vh;
      }
    `;
  }
  if (type === 'popupAgencies') {
    return css`
      @media ${DEVICES.tablet} {
        display: block;
        box-sizing: border-box;
        box-shadow: 6px 0px 7px rgba(0, 0, 0, 0.07);
      }
    `;
  }
  if (type === 'closeModalEndEdit' || type === 'addRetailerInfo') {
    return css`
      padding: 34px 43px 27px 43px;
      @media ${DEVICES.tablet} {
        padding: 17px 15px 20px 16px;
        width: 100%;
      }
    `;
  }

  return css`
    padding: 34px 43px 27px 43px;
  `;
};

const ModalBody = styled.div.attrs(() => ({
  ariaRequired: true,
  role: 'main',
}))`
  background: ${UiColors.fillWhiteColor};
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  ${({ type, scrollY }) => modalPaddingType(type, scrollY)};
  position: relative;
  @supports (display: grid) {
    position: static;
  }

  @media ${DEVICES.mobileL} {
    ${({ type }) =>
      modalType(
        type,
        css`
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          overflow-y: auto;
          padding: 35px 20px;
        `,
        css`
          width: 100%;
          margin: 0;
        `,
      )};
  }

  ${({ type }) =>
    type === 'AddRetailerInfoButtons' &&
    css`
      @media ${DEVICES.tablet} {
        padding: 0px 18px 15px;
      }
    `};
  ${({ type }) =>
    type === 'popupAgencies' &&
    css`
      display: block;
      @media ${DEVICES.mobileL} {
        display: block;
        padding: 0;
      }
    `};

  ${({ type }) =>
    type === 'addRetailerInfo' &&
    css`
      @media ${DEVICES.tablet} {
        min-height: 100vh;
        padding: 80px 15px 120px;
        box-sizing: border-box;

        ${WrapperDropdown} {
          margin-top: 26px;
        }
      }
      @media ${DEVICES.tablet} {
        padding: 80px 25px 175px;

        ${InputWrap} {
          margin: 0 20px 15px 0;
        }
      }

      @media ${DEVICES.mobileL} {
        padding: 80px 10px 175px;
      }
    `};
`;

const CloseWindowTablet = styled.button.attrs(() => ({
  ariaRequired: true,
  ariaPressed: 'false',
}))`
  display: none;
  border: none;
  background: transparent;
  ${({ headerType }) => modalCloseType(headerType)};
  ${({ type }) =>
    type === 'popupMap' &&
    css`
      margin-top: 46px;
      z-index: 99;
      img {
        height: 13px;
        z-index: 99;
        width: 13px;

        @media ${DEVICES.mobileL} {
          margin: 15px 0 0 20px;
        }
      }
    `};
  ${({ type }) =>
    type === 'mergerPopup' &&
    css`
      @media ${DEVICES.mobileL} {
        top: 5px;
      }
    `};

  ${({ scrollY }) =>
    scrollY > 56 &&
    css`
      margin-top: 10px;
      transition: all 0.5s;
    `};
`;

const WrapperContent = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  height: 100%;
`;

const BackgroundModal = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  ${({ type }) =>
    type !== 'popupMap' &&
    `
  width: 100%;
  height: 100%;`};
  background: rgba(0, 0, 0, 0.29);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 801;
  top: 0;
  left: 0;

  ${({ type }) =>
    type === 'popupMap' &&
    css`
      margin-top: 54px;
      z-index: 799;
      @media ${DEVICES.tablet} {
        width: 100%;
        margin-top: 0;
        z-index: 801;
      }
    `};

  @media ${DEVICES.tablet} {
    ${({ type }) =>
      modalType(
        type,
        css`
          @media ${DEVICES.tablet} {
            position: absolute;
            display: block;
            top: 54px;

            ${WrapperModal} {
              width: 100%;
              border-radius: 0;
            }
          }
        `,
        css`
          @media ${DEVICES.tablet} {
            ${WrapperModal} {
              width: 70%;
              ${
                type === 'closeRetailer' &&
                css`
                  width: 327px;
                `
              }
  ${
    type === 'mergerEditPopup' &&
    css`
      @media ${DEVICES.tablet} {
        padding-bottom: 20px;
        width: auto;
      }
    `
  }

              ${
                type === 'close' &&
                css`
                width: 400px;
                font:size:14px;
              `
              }
               ${
                 ['popupAgencies', 'popupMap', 'mergerPopup'].includes(type) &&
                 css`
                   width: 100vw;
                   height: 100vh;
                   padding-left: 0;
                 `
               };
              border-radius: 0;

              ${
                type === 'AddRetailerInfoButtons' &&
                css`
                  height: 220px;
                  span {
                    font-size: 14px;
                  }
                  div {
                    div {
                      padding-top: 0;
                    }
                  }

                  @supports (display: block) {
                    height: auto;
                    span: {
                      font-size: 14px;
                    }
                    div {
                      div {
                        padding-top: 0px;
                      }
                    }
                  }
                `
              }
            }
            }
          }

          
          @media ${DEVICES.mobileL} {

            ${WrapperModal} {
              width: 95%;
              ${
                ['popupAgencies', 'mergerPopup'].includes(type) &&
                css`
                  width: 100%;
                  height: 100vh;
                `
              }
              ${
                ['mergerEditPopup'].includes(type) &&
                css`
                  width: 95%;
                  padding-bottom: 20px;
                `
              }

              min-width: auto;

            ${
              type === 'AddRetailerInfoButtons' &&
              css`
                height: 220px;

                span {
                  font-size: 14px;
                }
                div {
                  div {
                    padding-top: 0;
                  }
                }
                @supports (display: block) {
                  span {
                    font-size: 14px;
                  }
                  height: 220px;
                }
              `
            }
            }
          }
        `,
      )};

    ${({ type }) =>
      type === 'license' &&
      css`
        ${WrapperModal} {
          width: calc(480px - 20px);
          border-radius: 0;
        }
      `};
  }

  ${({ type }) =>
    type === 'popupMap' &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
      background: none;
      position: fixed;
    `};

  @media ${DEVICES.mobileL} {
    ${({ type }) =>
      type === 'license' &&
      css`
        ${WrapperModal} {
          width: calc(370px - 20px);
          border-radius: 0;
        }
      `};
    ${({ type }) =>
      type === 'PII' &&
      css`
        position: absolute;
        display: block;
        height: calc(100% - 54px);
        top: 54px;
        overflow-y: auto;

        ${WrapperModal} {
          width: 100%;
          border-radius: 0;
        }

        ${CloseWindow} {
          position: absolute;
          margin-left: calc(100% - 40px);
        }
      `};

    ${({ type }) =>
      modalType(
        type,
        css`
          position: absolute;
          display: block;
          top: 54px;
          overflow-y: auto;

          ${WrapperModal} {
            width: 100%;
            border-radius: 0;
          }
        `,
      )};
  }

  @media ${DEVICES.tablet} {
    ${({ type }) =>
      type === 'addRetailerInfo' &&
      css`
        ${WrapperModal} {
          width: 100%;
          min-height: 100vh;
          position: absolute;
          box-sizing: border-box;
        }

        ${CloseWindow} {
          position: relative;
          width: 100%;
          height: 56px;
          background: ${UiColors.disabledButtonColor};
          margin: 0;

          &:before {
            position: absolute;
            content: '';
            top: 14px;
            left: 10px;
            width: 72px;
            height: 29px;
            background-size: contain;
          }

          button {
            position: absolute;
            right: 20px;
            top: 17px;
          }
        }
      `};

    ${({ type }) =>
      (type === 'Password' || type === 'copyUser') &&
      css`
        min-height: 100%;
        height: auto;
        position: absolute;

        ${WrapperModal} {
          padding: 20px 0;
        }
      `};

    ${({ type }) =>
      type === 'AddRetailerInfoButtons' &&
      css`
        min-height: 100%;
        height: auto;
        position: absolute;
      `};

    ${({ type }) =>
      type === 'addRetailerInfo' &&
      css`
        min-height: 100%;
        height: auto;
        position: absolute;
        display: block;
      `};
  }
`;

const CloseRetailerWrapper = styled.button.attrs(() => ({
  ariaRequired: true,
  ariaPressed: 'false',
}))`
  border: none;
  background: transparent;
`;

export {
  BackgroundModal,
  ModalBody,
  WrapperContent,
  modalType,
  CloseWindow,
  WrapperModal,
  CloseWindowTablet,
  CloseRetailerWrapper,
  WrapperContainer,
  CloseWindowContainer,
};
