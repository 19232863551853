export const SET_ALL_CITIES = 'SET_ALL_CITIES';
export const SET_ALL_CITIES_SUCCESS = 'SET_ALL_CITIES_SUCCESS';
export const SET_ALL_CITIES_FAIL = 'SET_ALL_CITIES_FAIL';

export const SET_ALL_COUNTIES = 'SET_ALL_COUNTIES';
export const SET_ALL_COUNTIES_SUCCESS = 'SET_ALL_COUNTIES_SUCCESS';
export const SET_ALL_COUNTIES_FAIL = 'SET_ALL_COUNTIES_FAIL';

export const SET_ALL_SUPERVISORY = 'SET_ALL_SUPERVISORY';
export const SET_ALL_SUPERVISORY_SUCCESS = 'SET_ALL_SUPERVISORY_SUCCESS';
export const SET_ALL_SUPERVISORY_FAIL = 'SET_ALL_SUPERVISORY_FAIL';

export const SET_ALL_JURISDICTIONS = 'SET_ALL_JURISDICTIONS';
export const SET_ALL_JURISDICTIONS_SUCCESS = 'SET_ALL_JURISDICTIONS_SUCCESS';
export const SET_ALL_JURISDICTIONS_FAIL = 'SET_ALL_JURISDICTIONS_FAIL';
