import React, { memo } from 'react';
import { Chart as ChartJS } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import * as Styled from './styles';

ChartJS.register(ChartDataLabels);

/**
 * @example
 * data={[
 *   { label: 'label1', value: value1, color: '#737373' },
 *   { label: 'label2', value: value2: '#0e0e0e' },
 * ]}
 */

const PieChart = (props) => {
  const { title, chartData } = props;
  const getValueList = (name) => chartData.map((item) => item[name]);

  const labels = getValueList('label');
  const dataValues = getValueList('value');
  const backgroundColor = getValueList('color');

  const dataBase = {
    datasets: [
      {
        label: title,
        data: dataValues,
        backgroundColor,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    hover: {
      animationDuration: 0,
    },
  };

  return (
    <Styled.Wrapper>
      <Styled.Legend>
        {labels.map((item, i) => (
          <Styled.LegendValues
            key={item}
            color={backgroundColor[i]}
          >{`${dataValues[i]}% - ${item}`}</Styled.LegendValues>
        ))}
      </Styled.Legend>
      <Styled.Chart>
        <Pie data={dataBase} options={options} />
      </Styled.Chart>
    </Styled.Wrapper>
  );
};

PieChart.propTypes = {
  title: PropTypes.string.isRequired,
  chartData: PropTypes.array.isRequired,
};

PieChart.defaultProps = {};

export default memo(PieChart);
