import styled, { css } from 'styled-components';

export const Section = styled.section`
  position: relative;
  border: 1px solid var(--grey);
  font-weight: normal;
`;

export const Title = styled.h2`
  position: relative;
  z-index: 2;
  border-bottom: 1px solid var(--grey);
  height: 50px;
  background: var(--lite-grey);
  font-size: 14px;
  font-weight: 500;
  line-height: 50px;
  padding: 0 20px;
`;

export const Content = styled.div`
  position: relative;
  padding: 20px;
  height: 100%;

  ${({ isSubmission }) =>
    isSubmission &&
    css`
      padding: 10px 20px;
    `}

  > div:last-of-type {
    margin-bottom: 0;
  }
`;

export const VerticalBorder = styled.div.attrs(() => ({}))`
  ${({ left }) =>
    css`
      border-right: 1px solid #e0e0e0;
      position: absolute;
      left: ${left};
      height: 100%;
      z-index: 1;
    `};
`;
