/* eslint-disable no-param-reassign */
const initialValuesFields = [
  {
    title: `Additional information #1`,
    value: '',
    checked: true,
    labelText: 'Visible (default)',
  },
  {
    title: `Additional information #2`,
    value: '',
    checked: true,
    labelText: 'Visible (default)',
  },
  {
    title: `Additional information #3`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #4`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #5`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #6`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #7`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #8`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #9`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
];

const initUser = {
  status: 'loading',
  isPrint: false,
  isNotification: false,
  typeSort: JSON.parse(sessionStorage.getItem('typeSort')) || [],
  messageRetailerDelete: false,
  retailersTableData: {
    links: {
      first: '',
      last: '',
      next: '',
      prev: null,
    },
    data: [
      {
        type: 'Retailer',
        id: '1',
        attributes: {
          name: '',
          address: '',
          address2: '',
          city: '',
          has_assignments: false,
          state: '',
          zipcode: '',
          latlong: {
            latitude: 0,
            longitude: 0,
          },
          place_id: '',
          aka: [],
          is_current: true,
          is_historic: null,
          historic_last_merge_date: null,
          merge_date: null,
          closed_date: null,
          metadata: {
            county: '',
          },
          lic_num: '1',
          tobacco: false,
          alcohol: false,
          marijuana: false,
          guns: null,
          store_type: 2,
          chain: true,
          corporation: '',
          wic: true,
          snap: false,
          near_school: false,
          near_retailer: true,
          pharmacy: true,
          ss_precision: '',
          address_validated: true,
          g_precision: '',
          is_gps: true,
          loc_updated: null,
          addr_updated: null,
          q_pcthisp: 5,
          q_pctblack: 5,
          q_pctamerind: 5,
          q_pctashawpi: 3,
          q_pctwhite: 1,
          q_pctotherrace: 4,
          q_pctyouth: 1,
          q_mhhinc: 1,
          q_pcturban: 1,
          q_pcteducollegegrad: 1,
          q_pctedusomecollege: 5,
          q_pcteduhighschool: 3,
          q_pctedunohighschool: 5,
          created: '',
          modified: '',
          other1: 'test 1',
          other2: 'test 2',
        },
        relationships: {
          new_retailer: {
            data: null,
          },
          role: {
            data: {
              id: 6,
            },
          },
          historic: {
            data: [],
          },
          similar_stores: {
            data: [],
            meta: {
              count: 0,
            },
          },
          non_duplicate_stores: {
            data: [],
            meta: {
              count: 0,
            },
          },
          visits: {
            data: [
              {
                type: 'Visit',
                id: '1',
              },
            ],
          },
          aggregationpolygon_set: {
            data: [
              {
                type: 'AggregationPolygon',
                id: '494',
              },
            ],
            meta: {
              pagination: { count: 1 },
            },
          },
        },
        links: {
          self: 'https://post.ctstaging.net/api/retailers/221300',
        },
      },
    ],
    meta: {
      pagination: {
        page: 1,
        pages: 1,
        count: 20,
      },
    },
  },

  retailersEnforcementData: {
    inspectionDate: null,
    inspectionType: [
      {
        name: 'Undercover Buy',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Signage Check',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Advertising limitations',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Failure to display license',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Failure to verify age',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Flavored tobacco',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Hookah',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Internet/Mail violation',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Non-MSA inventory',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Open Packages/Singles',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Package size',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Sale to a minor',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Self-Service',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Shoulder Tap',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
      {
        name: 'Unlicensed',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },

      {
        name: 'Untaxed product',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },

      {
        name: 'Other',
        isChecked: false,
        disposition: null,
        penalty: null,
        productType: [],
        additionalFields: initialValuesFields,
      },
    ],
    isChangsSaved: false,
    counter: 1,
  },
};

const retailersTableData = (state, action) => ({
  ...state,
  retailersTableData: action.payload,
  status: 'done',
});

const setTypeSort = (state, action) => ({
  ...state,
  typeSort: action.payload,
});

const retailersEnforcementDate = (state, action) => ({
  ...state,
  retailersEnforcementData: {
    inspectionDate: action.payload,
    inspectionType: state.retailersEnforcementData.inspectionType,
    isChangesSaved: false,
    counter: state.retailersEnforcementData.counter,
  },
});

const retailersEnforcementType = (state, action) => ({
  ...state,
  retailersEnforcementData: {
    inspectionDate: state.retailersEnforcementData.inspectionDate,
    inspectionType: action.payload,
    isChangesSaved: false,
    counter: state.retailersEnforcementData.counter,
  },
});

const setCounterInspection = (state, action) => ({
  ...state,
  retailersEnforcementData: {
    inspectionDate: state.retailersEnforcementData.inspectionDate,
    inspectionType: state.retailersEnforcementData.inspectionType,
    isChangesSaved: state.retailersEnforcementData.isChangesSaved,
    counter: action.payload,
  },
});

const saveEnforcementData = (state, action) => ({
  ...state,
  retailersEnforcementData: {
    inspectionDate: initUser.retailersEnforcementData.inspectionDate,
    inspectionType: initUser.retailersEnforcementData.inspectionType,
    isChangesSaved: action.payload,
  },
});

const setMessageRetailerDelete = (state, action) => ({
  ...state,
  messageRetailerDelete: action.payload,
});
const setIsPrint = (state, action) => ({
  ...state,
  isPrint: action.payload,
});

const setIsNotification = (state, action) => ({
  ...state,
  isNotification: action.payload,
});

const setInitSurveyData = (state, action) => ({
  ...state,
  retailersEnforcementData: action.payload,
});

const setTabView = (state, action) => ({
  ...state,
  tabView: action.payload,
});

const retailers = (state = initUser, action) => {
  switch (action.type) {
    case 'RETAILERS_TABLE_DATA':
      return retailersTableData(state, action);
    case 'SET_ENFORCEMENT_INSPETION_DATE':
      return retailersEnforcementDate(state, action);
    case 'SET_ENFORCEMENT_INSPETION_TYPE':
      return retailersEnforcementType(state, action);
    case 'SAVE_ENFORCEMENT_DATA':
      return saveEnforcementData(state, action);
    case 'SET_COUNTER_INSPECTION':
      return setCounterInspection(state, action);
    case 'RETAILER_DELETE':
      return setMessageRetailerDelete(state, action);
    case 'SET_TYPE_SORT':
      return setTypeSort(state, action);
    case 'SET_IS_NOTIFICATION':
      return setIsNotification(state, action);
    case 'ENFORCEMENT_MODAL_CLEAR':
      return setInitSurveyData(state, action);
    case 'SET_TAB_VIEW':
      return setTabView(state, action);
    case 'SET_IS_PRINT':
      return setIsPrint(state, action);
    default:
      return state;
  }
};

export default retailers;
