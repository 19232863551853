import { formIsValid } from 'utils/validation';
import { validateText } from 'utils/validation/fields';
import { COMMON_ERRORS } from '_constants/errors';
import { FORM_ACTION_TYPES, FORM_REQUIRED_FIELDS, formInitialState, formNames } from './constants';

export const init = (initialState) => {
  return { ...initialState };
};

export const validateData = (action) => {
  return validateText({
    value: action.payload.trim(),
    name: action.name,
    required: FORM_REQUIRED_FIELDS.includes(action.name),
    max: 50,
    min: 8,
  });
};

const checkFormIsValid = (data, errors) => formIsValid(errors, FORM_REQUIRED_FIELDS);

const setIsValidRules = (arr, errorObj) => {
  arr.reduce((acc, item) => {
    if (Array.isArray(item.errorMessage)) {
      item.isValid = !item.errorMessage.some((innerItem) => errorObj[innerItem]); // eslint-disable-line no-param-reassign
    } else {
      item.isValid = !errorObj[item.errorMessage]; // eslint-disable-line no-param-reassign
    }
    acc.push(item);
    return acc;
  }, []);
};

export const formReducer = (formState, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const newData = {
        ...formState.data,
        [action.name]: action.payload,
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: validateData(action),
      };

      return {
        ...formState,
        data: newData,
        errors: newErrors,
      };
    }

    case FORM_ACTION_TYPES.TRIM_DATA:
      return {
        ...formState,
        data: {
          ...formState.data,
          [action.name]: typeof action.payload === 'string' ? action.payload.trim() : action.payload,
        },
      };

    case FORM_ACTION_TYPES.IS_EQUAL: {
      const newState = Object.keys(formNames).reduce(
        (acc, item) => {
          if (formState.data.password !== formState.data.confirmPassword) {
            acc.data[item] = '';
            acc.errors[item] = COMMON_ERRORS.unEqualPassword;
          } else {
            acc.data[item] = formState.data[item];
            acc.errors[item] = formState.errors[item];
          }
          return acc;
        },
        { data: {}, errors: {} },
      );

      return {
        ...formState,
        data: newState.data,
        errors: newState.errors,
        canBeSubmitted: checkFormIsValid(newState.data, newState.errors),
      };
    }

    case FORM_ACTION_TYPES.RESET_INVALID: {
      const newState = Object.keys(formNames).reduce(
        (acc, item) => {
          acc.data[item] = '';
          acc.errors[item] = COMMON_ERRORS.inappropriatePassword;
          return acc;
        },
        { data: {}, errors: {} },
      );

      const newPasswordRulesState = [...formState.passwordRules];

      const passwordErrors = action.payload.reduce((acc, item) => {
        acc[item] = item;
        return acc;
      }, {});

      newPasswordRulesState.forEach((item) => {
        const items = item.fields;
        setIsValidRules(items, passwordErrors);
      });

      return {
        ...formState,
        data: newState.data,
        errors: newState.errors,
        canBeSubmitted: checkFormIsValid(newState.data, newState.errors),
        passwordRules: newPasswordRulesState,
      };
    }

    case FORM_ACTION_TYPES.ENTER_DATA: {
      const newData = {
        ...formState.data,
        [action.name]: action.payload,
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: false,
      };

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newData, newErrors),
      };
    }

    case FORM_ACTION_TYPES.RESET_TYPE:
      return init(action.payload);
    default:
      return {
        ...formInitialState,
      };
  }
};
