import * as TYPES from './Survey.types';

export const getSurveyFormsData = () => ({
  types: [TYPES.GET_SURVEY_FORMS_DATA, TYPES.GET_SURVEY_FORMS_DATA_SUCCESS, TYPES.GET_SURVEY_FORMS_DATA_FAIL],
  promise: (client) => client.get('forms?page_size=1000'),
});

export const getSurveySingleFormData = (id, retailerId, assignmentId) => ({
  types: [TYPES.GET_SURVEY_SINGLE_FORM_DATA, TYPES.GET_SURVEY_SINGLE_FORM_SUCCESS, TYPES.GET_SURVEY_SINGLE_FORM_FAIL],
  promise: (client) => client.get(`forms/${id}?include=agency&retailer=${retailerId}&assignment=${assignmentId}`),
});
