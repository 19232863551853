import styled, { css } from 'styled-components';

export const Wrapper = styled.article`
  font-weight: 500;
  padding: 18px 0;

  ${({ boxPadding }) => boxPadding && 'padding: 18px 15px;'}

  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid var(--grey);

      & > h3 {
        font-weight: 400;
      }
    `};
`;

export const Value = styled.span``;

export const Chart = styled.div`
  position: relative;
  max-height: 900px;
  width: 100%;
  margin: 0 auto;

  ${Value} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 24px;

  ::first-letter {
    text-transform: uppercase;
  }

  ${({ titlePadding }) => titlePadding && 'margin-left: 14px;'}
  ${({ hideTitleMargin }) => hideTitleMargin && 'margin-bottom: 0;'}
`;

export const Visits = styled.p`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  margin-top: 28px;
`;
