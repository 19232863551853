/* eslint-disable react/prop-types */
import React, { memo, useMemo } from 'react';
import { uniqueId } from 'lodash';
import * as Styled from './styles';
import TableDropDown from '../TableDropDown';

function PageCount({ value, pages, handlePageChange }) {
  const options = useMemo(
    () =>
      Array.from({ length: pages }, (_, index) => ({
        label: index + 1,
        value: index + 1,
      })),
    [pages],
  );

  const idADA = `PageID${uniqueId()}`;

  return (
    <Styled.Wrapper>
      <Styled.Title htmlFor={idADA}>Page:</Styled.Title>
      <TableDropDown inputId={idADA} value={value} options={options} onChange={handlePageChange} isSearchable />
    </Styled.Wrapper>
  );
}

PageCount.defaultProps = {
  pages: 1,
};

export default memo(PageCount);
