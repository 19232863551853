import React, { useReducer, useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, debounce } from 'lodash';
import { PageContainer } from 'modules';
import { useWindowDimensions } from 'hooks';
import BackPageButton from 'modules/BackPageButton';
import { useDispatch, useSelector } from 'react-redux';
import { RetailerEntity, AuthEntity, VenueEntity } from '_entities';
import { Button, Input, BaseSelect, LoadedSelect, Checkbox, Section } from 'ui-kit';
import { notification } from 'utils/services';
import PropTypes from 'prop-types';
import { BUTTON_TYPES, URLS, MESSAGES } from '_constants';
import SingleVenueMap from '../View/components/MainInfo/singleVenueMap';
import { formNames, formInitialState, FORM_ACTION_TYPES, venuesTypes, checkboxList, PAGE_COLUMNS } from './constants';
import { formReducer, init, setFormForEdit } from './_utils';
import * as Styled from './styles';

const { fetchCityList, checkNewRetailerLocation } = RetailerEntity.actions; // TODO: check for venues on edit page
const { addVenue, editVenue, resetVenue } = VenueEntity.actions;
const { getVenueInfo: getVenueData } = VenueEntity.selectors;
const { getCityList } = RetailerEntity.selectors;
const { getUserState } = AuthEntity.selectors;

const VenueAdd = ({ isEditPage }) => {
  const [isActiveUpdateLocation, setIsActiveUpdateLocation] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const venueInfo = useSelector(getVenueData);
  const cityList = useSelector(getCityList);
  const userStateAddress = useSelector(getUserState);
  const [state, formDispatch] = useReducer(formReducer, formInitialState, init);
  const formStateData = state.data.attributes;
  const formStateErrors = state.errors;
  const citySelectRef = useRef();
  const { width: viewportWidth } = useWindowDimensions();
  const isHorizontalLayout = viewportWidth >= 1124;
  const loadSelectTextType = isEditPage ? 'editCity' : 'addCity';

  const handleSubmit = async (e) => {
    e.preventDefault();

    formDispatch({ type: FORM_ACTION_TYPES.VALIDATE_DATA });

    if (!state.canBeSubmitted) return;

    const venueData = Object.keys(formStateData).reduce((acc, item) => {
      switch (item) {
        case formNames.type: {
          if (!isEmpty(formStateData[item].value)) {
            acc[item] = formStateData[item].value || null;
          }
          return acc;
        }

        case formNames.city: {
          acc[item] = formStateData[item].value;
          return acc;
        }

        case formNames.latlong: {
          if (!isEmpty(formStateData[item])) {
            acc[item] = formStateData[item];
          }
          return acc;
        }

        default: {
          acc[item] = formStateData[item];
          return acc;
        }
      }
    }, {});

    const updatedVenue = {
      data: {
        ...state.data,
        attributes: { ...venueData },
      },
    };
    if (isEditPage) {
      updatedVenue.data.id = venueInfo.id;
      updatedVenue.data.relationships = venueInfo.relationships;
    }
    try {
      if (isEditPage) {
        await dispatch(editVenue(venueInfo.id, updatedVenue));
        notification.success(MESSAGES.VENUE.EDIT.SUCCESS);
        history.push(`${URLS.venues}/${venueInfo.id}`);
      } else {
        const {
          data: { id },
        } = await dispatch(addVenue(updatedVenue));
        notification.success(MESSAGES.VENUE.CREATE.SUCCESS);
        history.push(`${URLS.venues}/${id}`);
      }
    } catch (err) {
      const errors = err.response?.data?.errors;
      if (Array.isArray(errors)) {
        notification.error(err.response?.data?.errors[0].detail);
      } else {
        notification.error(MESSAGES.COMMON_ERROR);
      }
    }
  };

  const handleChange = useCallback(({ target: { name, value } }) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
    // Set update address flag
    if (name === formNames.addrUpdated) {
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: true });
    }
  }, []);

  const handleSelectChange = useCallback((obj) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: obj.name, payload: obj });
  }, []);

  const handleCityChange = useCallback((obj) => {
    let result = obj;
    if (!result) {
      result = {
        value: '',
        label: '',
        name: formNames.city,
      };
    }
    handleSelectChange(result);
  }, []);

  const cityOptions = useCallback((arr) => {
    let selectOptions = [];
    if (arr.length) {
      selectOptions = arr.map((item) => {
        return {
          value: item[0],
          label: item[0],
          name: formNames.city,
        };
      });
    }
    return selectOptions;
  }, []);

  const pageTitle = isEditPage ? 'Edit venue' : 'Add venue';
  const submitButtonTitle = isEditPage ? 'Save changes' : 'Confirm';

  const handleUpdateLocation = useCallback(() => {
    setIsActiveUpdateLocation((prev) => !prev);
  }, []);

  const onChangeLocation = (newLatLong) => {
    venueInfo.attributes.geom.latitude = newLatLong.lat;
    venueInfo.attributes.geom.longitude = newLatLong.lng;
  };

  /**
   * @param value = {latitude: latitudeValue, longitude: longitudeValue}
   *
   */
  const handleConfirmUpdatedLocation = useCallback(() => {
    if (!isEditPage) return false;
    setIsActiveUpdateLocation((prev) => !prev);

    const getNotificationMessage = (secGeo, account) => {
      if (!secGeo && !account) {
        return MESSAGES.UPDATE_LOCATION.FAIL.BOTH;
      }
      if (!secGeo) {
        return MESSAGES.UPDATE_LOCATION.FAIL.GEOGRAPHY;
      }
      if (!account) {
        return MESSAGES.UPDATE_LOCATION.FAIL.ACCOUNT;
      }
      return MESSAGES.UPDATE_LOCATION.SUCCESS;
    };

    return (async () => {
      const newLatLong = venueInfo.attributes.geom; // see onChangeLocation()
      const params = `latitude=${newLatLong.latitude}&longitude=${newLatLong.longitude}`;
      try {
        const { isWithinAccountBoundaries, isWithinSecondaryGeographies } = await dispatch(
          checkNewRetailerLocation(params),
        );
        if (!isWithinSecondaryGeographies && !isWithinAccountBoundaries) {
          const message = getNotificationMessage(isWithinSecondaryGeographies, isWithinAccountBoundaries);
          return notification.error(message);
        }
        notification.success(MESSAGES.UPDATE_LOCATION.SUCCESS);
        formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: 'loc_updated', payload: true });
        return formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: formNames.latlong, payload: newLatLong });
      } catch (err) {
        console.log(err);
        return notification.error(MESSAGES.COMMON_ERROR);
      }
    })();
  }, [dispatch, isEditPage]);

  const handleCancelUpdateLocation = useCallback(() => {
    setIsActiveUpdateLocation((prev) => !prev);
  }, []);

  const handleLoadOptions = useCallback((inputValue, callback) => {
    const newData = { ...formStateData[formNames.city], value: inputValue, label: inputValue };
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: formNames.city, payload: newData });
    const fetchCities = debounce(() => {
      try {
        dispatch(fetchCityList(inputValue)).then((arr) => {
          callback(cityOptions(arr));
        });
      } catch (err) {
        console.log(err);
      }
    }, 300);

    if (inputValue.length > 2) {
      fetchCities();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(venueInfo?.attributes)) {
      const newFormData = setFormForEdit(venueInfo.attributes);
      formDispatch({ type: FORM_ACTION_TYPES.SET_DATA, payload: newFormData });
    }
  }, [venueInfo]);

  useEffect(() => {
    if (!isEditPage) {
      formDispatch({ type: FORM_ACTION_TYPES.RESET_DATA, payload: formInitialState.data.attributes });
      dispatch(resetVenue());
    }
    return () => {
      dispatch(resetVenue());
    };
  }, []);

  const latitude = isEditPage ? formStateData[formNames.latlong]?.latitude : 'none';
  const longitude = isEditPage ? formStateData[formNames.latlong]?.longitude : 'none';
  const stateAddress = isEditPage ? formStateData[formNames.state] : userStateAddress;
  formStateData[formNames.state] = stateAddress;

  return (
    <PageContainer>
      <Styled.MainContainer>
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>{pageTitle}</Styled.Title>
            <Styled.Info>* - required fields</Styled.Info>
          </Styled.TitleWrapper>
          <Styled.ButtonWrapper>
            <Button onClick={handleSubmit} variant={BUTTON_TYPES.DANGER} text={submitButtonTitle} />
          </Styled.ButtonWrapper>
        </Styled.TopLine>
        <Styled.Inner>
          <Styled.SectionGroup>
            <Section title="Characteristics" isHorizontalLayout={isHorizontalLayout} pageColumns={3}>
              <Styled.InputWrapper>
                <Input
                  name={formNames.name}
                  title="Name"
                  onChange={handleChange}
                  error={formStateErrors[formNames.name]}
                  value={formStateData[formNames.name]}
                  required
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  name={formNames.address}
                  title="Address"
                  onChange={handleChange}
                  error={formStateErrors[formNames.address]}
                  value={formStateData[formNames.address]}
                  required
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <LoadedSelect
                  loadOptions={handleLoadOptions}
                  labelText="City"
                  value={formStateData[formNames.city]}
                  onChange={handleCityChange}
                  inputId={formNames.city}
                  error={formStateErrors[formNames.city]}
                  className={formStateErrors[formNames.city] ? 'error' : ''}
                  isClearable
                  ref={citySelectRef}
                  required
                  pageColumns={PAGE_COLUMNS}
                  loadSelectTextType={loadSelectTextType}
                  optionsArray={cityList}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Input
                  name={formNames.zipCode}
                  title="Zip Code"
                  onChange={handleChange}
                  value={formStateData[formNames.zipCode]}
                  error={formStateErrors[formNames.zipCode]}
                  required
                  floatingLabel
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Styled.Location isHorizontalLayout>
                  <Styled.LocationLabel id="state">County</Styled.LocationLabel>
                  <Styled.LocationText aria-labelledby="state">{formStateData[formNames.county]}</Styled.LocationText>
                  <Styled.HorizontalBorder />
                </Styled.Location>
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <Styled.Location isHorizontalLayout>
                  <Styled.LocationLabel id="state">State</Styled.LocationLabel>
                  <Styled.LocationText aria-labelledby="state">{stateAddress}</Styled.LocationText>
                  <Styled.HorizontalBorder />
                </Styled.Location>
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <BaseSelect
                  name={formNames.type}
                  labelText="Venue type"
                  options={venuesTypes}
                  value={formStateData[formNames.type]}
                  error={formStateErrors[formNames.type]}
                  onChange={handleSelectChange}
                  inputId={formNames.type}
                  placeholder="Select an option"
                  required
                  pageColumns={PAGE_COLUMNS}
                />
              </Styled.InputWrapper>
            </Section>
            <Section title="Smoke-free policy">
              {checkboxList.map((item) => (
                <Styled.InputWrapper key={item.name}>
                  <Checkbox
                    onChange={handleChange}
                    name={item.name}
                    value={formStateData[item.name]}
                    label={item.label}
                  />
                </Styled.InputWrapper>
              ))}
            </Section>
          </Styled.SectionGroup>
          <Section title="Location">
            <Styled.MapWrapper>
              <SingleVenueMap
                isDraggable={isActiveUpdateLocation}
                venueDotTitle="Venue location"
                venueData={venueInfo}
                onChangeLocation={onChangeLocation}
              />
            </Styled.MapWrapper>
            <Styled.GisInfoWrapper>
              <Styled.GisInfo>Latitude: {latitude}</Styled.GisInfo>
              <Styled.GisInfo>Longitude: {longitude}</Styled.GisInfo>
            </Styled.GisInfoWrapper>
            {isEditPage && !venueInfo.attributes.has_polygeom && (
              <Styled.EditWrapper>
                <Styled.EditDescription>
                  To manually update the location, click Update Location below, click on the point on the map and drag
                  it to the desired location. Then click Save new location and Save changes.
                </Styled.EditDescription>

                {!isActiveUpdateLocation && (
                  <Button variant={BUTTON_TYPES.DARK} onClick={handleUpdateLocation} text="Update location" />
                )}

                {isActiveUpdateLocation && (
                  <Styled.UpdateLocationButtonWrapper>
                    <Button
                      variant={BUTTON_TYPES.DANGER}
                      onClick={handleConfirmUpdatedLocation}
                      text="Save new location"
                    />
                    <Button variant={BUTTON_TYPES.DARK} onClick={handleCancelUpdateLocation} text="Cancel" />
                  </Styled.UpdateLocationButtonWrapper>
                )}
              </Styled.EditWrapper>
            )}
          </Section>
        </Styled.Inner>
      </Styled.MainContainer>
    </PageContainer>
  );
};

VenueAdd.propTypes = {
  isEditPage: PropTypes.bool,
};

VenueAdd.defaultProps = {
  isEditPage: false,
};

export default VenueAdd;
