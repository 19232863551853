import * as actions from './User.actions';
import reducer from './User.reducer';
import * as types from './User.types';
import * as selectors from './User.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
