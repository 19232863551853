/* eslint-disable react/prop-types */
import React from 'react';
import { compact, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { URLS } from '_constants';

const getAssignmentsColumns = ({ handleSort, sortOption, dumpedActiveColumns }) =>
  compact([
    dumpedActiveColumns.retailerName && {
      Header: () => (
        <HeaderCell
          col="Retailer name"
          sortBy="retailer__name"
          sortOption={sortOption}
          handleSort={handleSort('retailer__name')}
        />
      ),
      minWidth: 175,
      accessor: 'retailer',
      Cell: ({ value: { name, url } }) => <LinkCell id={uniqueId()} text={name} to={`${URLS.survey}/${url}`} />,
    },
    dumpedActiveColumns.address && {
      Header: () => (
        <HeaderCell
          col="Address"
          sortBy="retailer__address"
          sortOption={sortOption}
          handleSort={handleSort('retailer__address')}
        />
      ),
      minWidth: 200,
      accessor: 'retailer.address',
      Cell: ({ value }) => <TextCell text={`${value}`} />,
    },
    dumpedActiveColumns.city && {
      Header: () => (
        <HeaderCell
          col="City"
          sortBy="retailer__city"
          sortOption={sortOption}
          handleSort={handleSort('retailer__city')}
        />
      ),
      minWidth: 100,
      accessor: 'retailer.city',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.zipCode && {
      Header: () => (
        <HeaderCell
          col="Zip code"
          sortBy="retailer__zipcode"
          sortOption={sortOption}
          handleSort={handleSort('retailer__zipcode')}
        />
      ),
      minWidth: 100,
      accessor: 'retailer.zipcode',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.dataCollector && {
      Header: () => (
        <HeaderCell
          col="Data collector"
          sortBy="mult_email"
          sortOption={sortOption}
          handleSort={handleSort('mult_email')}
        />
      ),
      minWidth: 200,
      accessor: 'users',
      Cell: ({ value }) => {
        return (
          <div style={{ margin: 'auto 0' }}>
            {value.map((elem) => {
              return <LinkCell id={uniqueId()} text={`${elem.attributes.email}`} to={`${URLS.users}/${elem.id}`} />;
            })}
          </div>
        );
      },
    },
    dumpedActiveColumns.visit && {
      Header: () => (
        <HeaderCell
          col="Visit type"
          sortBy="campaign__form__form_type"
          sortOption={sortOption}
          handleSort={handleSort('campaign__form__form_type')}
        />
      ),
      minWidth: 100,
      accessor: 'visitType',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.agency && {
      Header: () => (
        <HeaderCell
          col="Agency"
          sortBy="campaign__agency__name"
          sortOption={sortOption}
          handleSort={handleSort('campaign__agency__name')}
        />
      ),
      minWidth: 150,
      accessor: 'campaign.agencyName',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.campaignName && {
      Header: () => (
        <HeaderCell
          col="Campaign name"
          sortBy="campaign__campaign_name"
          sortOption={sortOption}
          handleSort={handleSort('campaign__campaign_name')}
        />
      ),
      minWidth: 150,
      accessor: 'campaign',
      Cell: ({ value: { campaignName, id } }) => (
        <LinkCell id={uniqueId()} text={campaignName} to={`${URLS.campaign}/${id}`} />
      ),
    },
    dumpedActiveColumns.status && {
      Header: () => (
        <HeaderCell
          col="Status"
          sortBy="previous_violation__id"
          sortOption={sortOption}
          handleSort={handleSort('previous_violation__id')}
        />
      ),
      minWidth: 130,
      accessor: 'status',
      Cell: ({ value }) => {
        const status = value ? 'Had Violation' : 'Not Started';
        return <TextCell text={`${status}`} />;
      },
    },
  ]);
export default getAssignmentsColumns;
