import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LinkElem } from 'modules/Header/styles';
import PropTypes from 'prop-types';
import { URLS } from '_constants';
import { CollectEntity } from '_entities';
import CollectItem from '../../MenuItems/components/CollectItem';
import * as Styled from '../styles';

const { getCollectCount } = CollectEntity.selectors;

function MobileRouteItem({ route, setIsOpenMenu, routeRef, index, focusedTab, setFocusedTab, burgerRef }) {
  const location = useLocation();
  const history = useHistory();
  const collectCount = useSelector(getCollectCount);

  const handleLinkClick = useCallback(
    (e, path) => {
      if (path === location.pathname) {
        e.preventDefault();
        return;
      }
      setIsOpenMenu(false);
      setFocusedTab(index);
    },
    [index, location.pathname, setFocusedTab, setIsOpenMenu],
  );

  const handleKeyDown = useCallback(
    (e, path) => {
      if (path === location.pathname && ![27, 9].includes(e.keyCode)) {
        e.preventDefault();
        return;
      }
      if ([32, 13].includes(e.keyCode)) {
        e.preventDefault();
        setIsOpenMenu(false);
        history.push(path);
        burgerRef?.current?.focus();
      }
      if ([27].includes(e.keyCode)) {
        e.preventDefault();
        setIsOpenMenu(false);
        burgerRef?.current?.focus();
      }
    },
    [burgerRef, history, location.pathname, setIsOpenMenu],
  );

  return (
    <Styled.RoutesListItem role="menuitem">
      <LinkElem
        key={route.title}
        to={route.path}
        aria-label={route.title}
        name={route.path}
        onClick={(e) => handleLinkClick(e, route.path)}
        onKeyDown={(e) => handleKeyDown(e, route.path)}
        ref={routeRef}
        tabIndex={focusedTab === index ? 0 : -1}
      >
        {route.title}
      </LinkElem>
      {route.path === URLS.campaign_list && collectCount > 0 && <CollectItem count={collectCount} />}
    </Styled.RoutesListItem>
  );
}

MobileRouteItem.propTypes = {
  route: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  setIsOpenMenu: PropTypes.func.isRequired,
  setFocusedTab: PropTypes.func.isRequired,
  focusedTab: PropTypes.number.isRequired,
  routeRef: PropTypes.object.isRequired,
  burgerRef: PropTypes.object.isRequired,
};

export default MobileRouteItem;
