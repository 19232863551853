/* eslint-disable react/prop-types */
/* Libs */
import React, { useCallback, memo, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const CheckboxBase = (
  {
    isAnswer,
    value,
    name,
    label,
    onChange,
    onBlur,
    disabled,
    preselectDisabled,
    error,
    hasError,
    hideErrorText,
    bold,
    isManageAssignmentsTable,
    pageColumns,
    ...inputProps
  },
  ref,
) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleChange = useCallback(
    (e) => {
      onChange({ target: { name: e.target.name, value: e.target.checked } });
    },
    [onChange],
  );

  return (
    <Styled.CheckboxWrapper
      disabled={disabled}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isManageAssignment={isManageAssignmentsTable}
      className="input-wrapper"
      pageColumns={pageColumns}
    >
      <Styled.HtmlLabel htmlFor={name} disabled={disabled}>
        <Styled.CheckboxInputWrapper>
          <Styled.CheckboxInput
            {...inputProps}
            ref={ref}
            id={name}
            name={name}
            type="checkbox"
            checked={(disabled && preselectDisabled) || value}
            disabled={disabled}
            onChange={handleChange}
            onBlur={onBlur}
            hasError={hasError}
          />
        </Styled.CheckboxInputWrapper>
        <Styled.CheckboxLabel className="styled-label" disabled={disabled} hasError={hasError} bold={bold}>
          {label}
        </Styled.CheckboxLabel>
      </Styled.HtmlLabel>
      <Styled.CheckboxError aria-label="error">{!disabled && error && !hideErrorText && error}</Styled.CheckboxError>
      {isHovered && disabled && isManageAssignmentsTable && (
        <Styled.Popup>
          You cannot edit assignments if they are completed or if the retailer has already closed
        </Styled.Popup>
      )}
    </Styled.CheckboxWrapper>
  );
};

const Checkbox = memo(forwardRef(CheckboxBase));

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isAnswer: PropTypes.bool,
  preselectDisabled: PropTypes.bool,
  bold: PropTypes.bool,
  isManageAssignmentsTable: PropTypes.bool,
  pageColumns: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  isAnswer: false,
  label: '',
  name: '',
  preselectDisabled: false,
  onBlur: undefined,
  bold: false,
  isManageAssignmentsTable: false,
  pageColumns: '',
};

export default Checkbox;
