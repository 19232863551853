import { getBaseUrl } from './getBaseUrl';

const apiData = {
  baseUrl: getBaseUrl(),
  audit: 'audit-logs',
  auditCSV: 'audit-logs-csv',
  resetPassword: 'password/reset/',
  login: 'token-auth/',
  setNewPassword: 'password/reset/verified/',
  validationPassword: 'password/reset/verified/?validation=soft',
  setNewPasswordVerify: 'password/reset/verify/?code=',
  logout: 'token-auth/logout/',
  verifiedToken: 'token-auth/verify/',
  getProfileData: 'profile',
  setPasswordProfile: 'password/reset/verified/',
  getRetailerData: 'retailers',
  retailersTableData: 'retailers',
  addNewRetailer: 'retailers',
  retailerVisits: 'visits',
  deleteRetailer: 'retailers',
  updateRetailer: 'retailers',
  searchRetailer: 'retailers',
  retailerDuplicate: 'retailers',
  cities: 'cities',
  retailer: 'retailer',
  names: 'retailers_autocomplete',
  enforcementTableData: 'enforcements',
  forms: 'forms',
  merger: 'merger',
  updateLocation: 'update_location',
  licenses: 'licenses',
  retailerLicenses: 'licenses',
  users: 'users',
  agencies: 'agencies',
  getListOfAgencies: 'agencies/agencies_dropdown',
  searchJurisdictions: 'sec-geogs?search=',
  getJurisdictions: 'sec-geogs',
  searchAgencies: 'agencies?search=',
  searchRoles: 'roles?search=',
  getRolesList: 'roles/roles_dropdown',
  retailerCSV: 'retailers_csv',
  alternateCSV: 'alternate-info-csv?jurisdiction_id=',
  licensesReportCSV: 'jurisdiction/licenses-csv?',
  enforcementReportCSV: 'jurisdiction/enforcements-csv?',
  usersCSV: 'users_csv',
  agenciesCSV: 'agencies_csv',
  licensesCSV: 'licenses_csv',
  visitsCSV: 'visits_csv',
  locationCSVretailers: 'location/retailers-csv',
  locationCSVlicenses: 'location/licenses-csv',
  locationCSVenforcements: 'location/enforcements-csv',
  location: 'location/',
  jurisdiction: 'jurisdiction',
  alternate: 'alternate-info',
  counties: 'counties',
  supervisory: 'supervisory-districts',
  addresses: 'addresses',
  suspensions: 'suspensions',
  healthcheck: '',
  usefulLinks: 'dashboard-useful-links',
  faqs: 'dashboard-faqs',
  dashboardEnforcement: 'dashboard-enforcements',
  dashboardSuspensions: 'dashboard-suspensions',
  dashboardLicenses: 'dashboard-licenses',
  dashboardNotifications: 'dashboard-notifications',
  dashboardBlockPermissions: 'dashboard-block-permissions',
  editManageFreeTextFields: 'free-text-fields',
  getManageFreeTextFields: 'free-text-fields',
  addNewYouth: 'youth',
  youthTableData: 'youth',
  searchYouth: 'youth',
  getYouthData: 'youth',
  updateYouth: 'youth',
  deleteYouth: 'youth',
  youthCSV: 'youth_csv',
};

export default apiData;
