export const GET_YOUTH_LIST = 'GET_YOUTH_LIST';
export const GET_YOUTH_LIST_SUCCESS = 'GET_YOUTH_LIST_SUCCESS';
export const GET_YOUTH_LIST_FAIL = 'GET_YOUTH_LIST_FAIL';

export const ADD_YOUTH = 'ADD_YOUTH';
export const ADD_YOUTH_SUCCESS = 'ADD_YOUTH_SUCCESS';
export const ADD_YOUTH_FAIL = 'ADD_YOUTH_FAIL';

export const EDIT_YOUTH = 'EDIT_YOUTH';
export const EDIT_YOUTH_SUCCESS = 'EDIT_YOUTH_SUCCESS';
export const EDIT_YOUTH_FAIL = 'EDIT_YOUTH_FAIL';

export const GET_YOUTH = 'GET_YOUTH';
export const GET_YOUTH_SUCCESS = 'GET_YOUTH_SUCCESS';
export const GET_YOUTH_FAIL = 'GET_YOUTH_FAIL';
