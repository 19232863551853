import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { TableSizeControls, Loader } from 'ui-kit';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import processQSParams from 'utils/old/processQSParams';
import { useHistory } from 'react-router-dom';
import { AuthEntity } from '_entities';
import { SetQueryParams } from 'utils/old/setQueryParams';
import { CAMPAIGN_LIST_VIEW_MODE_VALUE } from 'pages/Assignments/List/components/CampaignListTable/constants';
import CollectTableBase from './components/CollectTableBase';
import * as Styled from './styles';

const { getTimeZone } = AuthEntity.selectors;

function CollectTable({
  setLoading,
  getInitialColumns,
  sortOptions,
  loadAPICall,
  selector,
  initialColumns,
  columns,
  search,
  tableViewMode,
  tableVievModeButtonName,
  checkboxList,
  setCheckboxList,
  isCampaignList,
}) {
  const { pagination, data } = useSelector(selector);
  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const timeZone = useSelector(getTimeZone);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableLoad, setTableLoad] = useState(false);
  const [sortOption, setSortOption] = useState(sortOptions);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCheckbox = ({ target: { name, value } }) => {
    setCheckboxList((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    setCurrentPage(1);
    setCurrentPerPage(20);
  }, [search]);

  const loadData = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
    } = {}) => {
      setTableLoad(true);

      SetQueryParams({
        queryString: {
          page: page || 1,
          page_size: perPage || 20,
          search: search || null,
          not_archived: CAMPAIGN_LIST_VIEW_MODE_VALUE[tableViewMode],
        },
        history,
      });

      try {
        const params = processQSParams({
          page,
          perPage,
          sortOrder,
          sortBy,
          search,
          not_archived: CAMPAIGN_LIST_VIEW_MODE_VALUE[tableViewMode],
        });
        await dispatch(loadAPICall(params, timeZone));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        setTableLoad(false);
      }
    },
    [currentPage, dispatch, currentPerPage, sortOption, search, tableViewMode],
  );

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setCheckboxList({});
  }, [tableViewMode]);

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadData({ page: value });
    },
    [loadData, setCurrentPage],
  );

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadData({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadData],
  );

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadData({ sortBy, sortOrder });
    },
    [loadData, setSortOption],
  );

  if (tableLoad) return <Loader />;

  return (
    <Styled.Wrapper>
      {isCampaignList && (
        <Styled.TabsPanelWrapper>
          <Styled.AmountText>{`${pagination?.count || 0} items`}</Styled.AmountText>
        </Styled.TabsPanelWrapper>
      )}
      <CollectTableBase
        getInitColumns={getInitialColumns}
        data={data}
        sortOption={sortOption}
        sortHandler={sortHandler}
        initialColumns={initialColumns}
        columns={columns}
        tableViewMode={tableViewMode}
        tableVievModeButtonName={tableVievModeButtonName}
        handleCheckbox={handleCheckbox}
        checkboxList={checkboxList}
      />
      <TableSizeControls
        itemsPerPageProps={{
          handlePerPageChange,
          value: currentPerPage,
        }}
        pageCountProps={{
          value: currentPage || 1,
          pages: pagination?.pages || 1,
          handlePageChange,
        }}
        paginationInfoProps={{
          total: pagination?.count || 0,
          currentPage,
          perPage: currentPerPage || 20,
        }}
        paginationNavProps={{
          currentPage,
          handlePageChange,
          pages: pagination?.pages || 1,
          titleTable: 'Campaign list',
        }}
      />
    </Styled.Wrapper>
  );
}

CollectTable.propTypes = {
  setLoading: PropTypes.func.isRequired,
  tableViewMode: PropTypes.string,
  tableVievModeButtonName: PropTypes.string,
  getInitialColumns: PropTypes.func.isRequired,
  sortOptions: PropTypes.object.isRequired,
  loadAPICall: PropTypes.func.isRequired,
  selector: PropTypes.func.isRequired,
  initialColumns: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  checkboxList: PropTypes.object,
  setCheckboxList: PropTypes.func,
  search: PropTypes.string.isRequired,
  isCampaignList: PropTypes.bool,
};

CollectTable.defaultProps = {
  isCampaignList: false,
  tableViewMode: '',
  tableVievModeButtonName: '',
  checkboxList: {},
  setCheckboxList: () => {},
};
export default CollectTable;
