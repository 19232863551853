import React from 'react';
import { FILTER_FIELDS } from '_constants/old/filters';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import DatePickerGroup from 'modules/Modals/old/FilterModal/components/DatePickerGroup';
import * as Styled from './styles';

const FIELDS = [
  {
    id: 1,
    label: 'Visit added to database',
    name: FILTER_FIELDS.CREATION_DATE,
  },
  {
    id: 2,
    label: 'Visit last updated',
    name: FILTER_FIELDS.UPDATE_DATE,
  },
];

export const VisitsAdvancedFilter = () => (
  <Styled.Wrapper>
    <FormGroup>
      <Styled.List>
        {FIELDS.map((field) => (
          <Styled.ListItem key={field.id}>
            <DatePickerGroup name={field.name} title={field.label} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </FormGroup>
  </Styled.Wrapper>
);
