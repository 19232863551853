export const SET_TOKEN = 'SET_TOKEN';

export const START_WITHOUT_TOKEN = 'START_WITHOUT_TOKEN';

export const LOG_OUT = 'LOG_OUT';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAIL = 'SIGN_OUT_FAIL';

export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const GET_PROFILE_DATA_SUCCESS = 'GET_PROFILE_DATA_SUCCESS';
export const GET_PROFILE_DATA_FAIL = 'GET_PROFILE_DATA_FAIL';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAIL = 'SET_NEW_PASSWORD_FAIL';

export const IS_VALID_LINK = 'IS_VALID_LINK';
export const IS_VALID_LINK_SUCCESS = 'IS_VALID_LINK_SUCCESS';
export const IS_VALID_LINK_FAIL = 'IS_VALID_LINK_FAIL';

export const GET_MODULARITY_PERMISSION = 'GET_MODULARITY_PERMISSION';
export const GET_MODULARITY_PERMISSION_SUCCESS = 'GET_MODULARITY_PERMISSION_SUCCESS';
export const GET_MODULARITY_PERMISSION_FAIL = 'GET_MODULARITY_PERMISSION_FAIL';
