import styled from 'styled-components';
import { DEVICES } from '_constants';
import { Link } from 'react-router-dom';

export const Card = styled.article`
  padding: 15px;
  border: 1px solid var(--grey-border);
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${DEVICES.tablet} {
    padding: 15px;
  }
`;

export const IconWrapper = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: var(--lite-grey);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${DEVICES.tablet} {
    width: 52px;
    height: 52px;
  }
`;

export const Icon = styled.img`
  width: 28px;
  height: 28px;

  @media ${DEVICES.tablet} {
    width: 20px;
    height: 20px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 16px;
  line-height: 1.17;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const CardDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: var(--dim-grey);
  margin-bottom: 12px;
`;

export const CardLink = styled(Link)`
  margin-top: auto;
  min-width: 112px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: var(--white);
  background: var(--danger);
  text-align: center;

  :hover {
    background: var(--persian-red);
  }
`;
