/* eslint-disable react/prop-types */
import React from 'react';
import { compact, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import TimeCell from 'modules/old/cells/TimeCell';
import { URLS } from '_constants';

const getRevisitsColumns = ({ handleSort, sortOption, dumpedActiveColumns }) =>
  compact([
    dumpedActiveColumns.retailerName && {
      Header: () => (
        <HeaderCell col="Retailer name " sortBy="name" sortOption={sortOption} handleSort={handleSort('name')} />
      ),
      minWidth: 150,
      accessor: 'attributes',
      Cell: ({ value: { name, id } }) => {
        return <LinkCell id={uniqueId()} text={name} to={`${URLS.retailers}/${id}`} />;
      },
    },
    dumpedActiveColumns.violationDate && {
      Header: () => (
        <HeaderCell
          col="Violation date"
          sortBy="revisit_date"
          sortOption={sortOption}
          handleSort={handleSort('revisit_date')}
        />
      ),
      minWidth: 100,
      accessor: 'attributes',
      Cell: ({ value: { date, visit_id: visitId, visit_type: visitType } }) => {
        const getVisitType = (type) => {
          switch (type) {
            case 'Enforcement':
              return URLS.enforcement;
            case 'Education':
              return URLS.education;
            default:
              return URLS.assessments;
          }
        };
        return <TimeCell id={visitId} data={date} to={`${getVisitType(visitType)}/${visitId}`} />;
      },
    },
    dumpedActiveColumns.address && {
      Header: () => (
        <HeaderCell
          col="Address"
          sortBy="revisit_address"
          sortOption={sortOption}
          handleSort={handleSort('revisit_address')}
        />
      ),
      minWidth: 150,
      accessor: 'attributes.address',
      Cell: ({ value }) => <TextCell text={`${value}`} />,
    },
    dumpedActiveColumns.city && {
      Header: () => (
        <HeaderCell col="City" sortBy="revisit_city" sortOption={sortOption} handleSort={handleSort('revisit_city')} />
      ),
      minWidth: 150,
      accessor: 'attributes.city',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.zipCode && {
      Header: () => (
        <HeaderCell col="Zip code" sortBy="zipcode" sortOption={sortOption} handleSort={handleSort('zipcode')} />
      ),
      minWidth: 120,
      accessor: 'attributes.zipcode',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.visit && {
      Header: () => (
        <HeaderCell
          col="Visit type"
          sortBy="revisit_type"
          sortOption={sortOption}
          handleSort={handleSort('revisit_type')}
        />
      ),
      minWidth: 250,
      accessor: 'attributes.visit_type',
      Cell: ({ value }) => <TextCell text={value} />,
    },
  ]);

export default getRevisitsColumns;
