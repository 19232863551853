/* eslint-disable */
import axios from 'axios';
import { manageToken, getToken, removeToken } from './authService';
import { tokenService } from 'utils/services';
const httpClient = axios.create({
  // withCredentials: true
  headers: {
    accept: 'application/vnd.api+json, application/json',
    'X-Version': '2.0',
  },
});

httpClient.interceptors.response.use(
  (response) => {
    if (response?.data?.token && (response.config.url.includes('auth') || response.config.url.includes('bootstrap'))) {
      tokenService.manageToken(response.data.token);
    }
    return Promise.resolve(response);
  },
  (error) => {
    // if (
    //   error.response.status === 401 &&
    //   window.location.pathname !== "/login"
    // ) {
    //   removeToken();
    //   window.location = "/login";
    // }
    return Promise.resolve(error.response);
  },
);

/**
 * Add API_URL to request url before sending request.
 */
httpClient.interceptors.request.use((config) => {
  const authToken = tokenService.getToken();

  if (authToken) config.headers.Authorization = `Token ${authToken}`;
  // config.url = `${ process.env.REACT_APP_API_URL }${ config.url }`;
  // config.withCredentials = true;
  return config;
});

export default httpClient;
