import styled from 'styled-components';

export const Error = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 11px;
  color: red;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.li`
  &:not(:first-child) {
    margin: 12px 0 0;
  }
`;

export const Wrapper = styled.div``;

export const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  margin-left: ${({ isSubcategory }) => (isSubcategory ? '30px' : '0px')};
`;

export const Legend = styled.legend`
  margin: 0;
  padding: 0;
`;
