import * as TYPES from './Venue.types';

const initialState = {
  venues: {
    data: [],
    pagination: {},
  },
  venueInfo: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_VENUE_LIST_SUCCESS: {
      const {
        data,
        meta: { pagination },
      } = action?.result;
      const newData = data.map((item) => {
        const { name, type, zipcode, city, address } = item.attributes;
        const { aggregationpolygon_set: secGeogs } = item.relationships;
        return {
          name,
          id: item.id,
          type,
          zipcode,
          city,
          address,
          sec_geogs: secGeogs,
        };
      });
      return {
        ...state,
        venues: {
          data: newData,
          pagination,
        },
      };
    }

    case TYPES.GET_VENUE_DATA_SUCCESS: {
      const { data, included } = action?.result;
      return {
        ...state,
        venueInfo: { ...data, included },
      };
    }

    case TYPES.RESET_VENUE: {
      return {
        ...state,
        venueInfo: {},
      };
    }

    default:
      return state;
  }
};

export default reducer;
