/* eslint-disable no-unused-vars */
import React, { lazy } from 'react';
import { LazyLoad } from 'ui-kit';
import { URLS } from '_constants';

const Login = lazy(() => import('pages/Login'));
const Policy = lazy(() => import('pages/Policy'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const SetNewPassword = lazy(() => import('pages/SetNewPassword'));
const LinkExpiration = lazy(() => import('pages/LinkExpiration'));
const Snapshot = lazy(() => import('pages/Snapshot'));
const SnapshotMapsEnforcement = lazy(() => import('pages/SnapshotMaps/Enforcement'));
const SnapshotMapsEducation = lazy(() => import('pages/SnapshotMaps/Education'));
const SnapshotMapsProductPrice = lazy(() => import('pages/SnapshotMaps/ProductPrice'));
const SnapshotMapsProductPromotions = lazy(() => import('pages/SnapshotMaps/ProductPromotions'));
const SnapshotMapsProductAvailability = lazy(() => import('pages/SnapshotMaps/ProductAvailability'));
const SnapshotMapsSchools = lazy(() => import('pages/SnapshotMaps/Schools'));
const SnapshotMapsPolicyTester = lazy(() => import('pages/SnapshotMaps/PolicyTester'));
const SnapshotMapsRetailerDensity = lazy(() => import('pages/SnapshotMaps/RetailerDensity'));
const NotFound = lazy(() => import('pages/NotFound'));

const LazyLogin = (props) => <LazyLoad component={Login} {...props} />;
const LazyPolicy = (props) => <LazyLoad component={Policy} {...props} />;
const LazyForgotPassword = (props) => <LazyLoad component={ForgotPassword} {...props} />;
const LazyResetPassword = (props) => <LazyLoad component={ResetPassword} {...props} />;
const LazySetNewPassword = (props) => <LazyLoad component={SetNewPassword} {...props} />;
const LazyLinkExpiration = (props) => <LazyLoad component={LinkExpiration} {...props} />;
const LazySnapshot = (props) => <LazyLoad component={Snapshot} {...props} />;
const LazySnapshotMapsEnforcement = (props) => <LazyLoad component={SnapshotMapsEnforcement} {...props} />;
const LazySnapshotMapsEducation = (props) => <LazyLoad component={SnapshotMapsEducation} {...props} />;
const LazySnapshotMapsProductPrice = (props) => <LazyLoad component={SnapshotMapsProductPrice} {...props} />;
const LazySnapshotMapsProductPromotions = (props) => <LazyLoad component={SnapshotMapsProductPromotions} {...props} />;
const LazySnapshotMapsSchools = (props) => <LazyLoad component={SnapshotMapsSchools} {...props} />;
const LazySnapshotMapsPolicyTester = (props) => <LazyLoad component={SnapshotMapsPolicyTester} {...props} />;
const LazySnapshotMapsRetailerDensity = (props) => <LazyLoad component={SnapshotMapsRetailerDensity} {...props} />;
const LazySnapshotMapsProductAvailability = (props) => (
  <LazyLoad component={SnapshotMapsProductAvailability} {...props} />
);
const LazyNotFound = (props) => <LazyLoad component={NotFound} {...props} />;

export default [
  {
    component: LazyLogin,
    exact: true,
    path: URLS.login,
  },
  {
    component: LazyPolicy,
    exact: true,
    path: URLS.policy,
  },
  {
    component: LazyForgotPassword,
    exact: true,
    path: URLS.forgotPassword,
  },
  {
    component: LazyResetPassword,
    exact: true,
    path: URLS.resetPassword,
  },
  {
    component: LazySetNewPassword,
    exact: true,
    path: URLS.setNewPassword,
  },
  {
    component: LazyLinkExpiration,
    exact: true,
    path: URLS.linkExpiration,
  },
  {
    component: LazySnapshot,
    exact: true,
    path: URLS.snapshotMaps,
  },
  {
    component: LazySnapshotMapsEnforcement,
    exact: true,
    path: URLS.snapshot.enforcement,
  },
  {
    component: LazySnapshotMapsEducation,
    exact: true,
    path: URLS.snapshot.education,
  },
  {
    component: LazySnapshotMapsProductPrice,
    exact: true,
    path: URLS.snapshot.productPrice,
  },
  {
    component: LazySnapshotMapsProductPromotions,
    exact: true,
    path: URLS.snapshot.productPromotions,
  },
  {
    component: LazySnapshotMapsProductAvailability,
    exact: true,
    path: URLS.snapshot.productAvailability,
  },
  {
    component: LazySnapshotMapsSchools,
    exact: true,
    path: URLS.snapshot.schools,
  },
  {
    component: LazySnapshotMapsPolicyTester,
    exact: true,
    path: URLS.snapshot.policyTester,
  },
  {
    component: LazySnapshotMapsRetailerDensity,
    exact: true,
    path: URLS.snapshot.retailerDensity,
  },
  {
    component: LazyNotFound,
    exact: true,
    path: URLS.notFound,
  },
];
