import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';

export const VISIT_CHARACTERISTICS_TABS = [
  {
    type: 'double',
    title: 'Cigarettes',
    id: 1,
    collapsed: [
      {
        id: 2,
        field: FILTER_FIELDS.CIGARETTES_SOLD,
        options: FILTER_OPTIONS.CIGARETTES_SOLD_OPTIONS,
      },
      {
        id: 3,
        field: FILTER_FIELDS.PROMO_CIG,
        options: FILTER_OPTIONS.PROMO_CIG_OPTIONS,
      },
      {
        id: 3,
        field: FILTER_FIELDS.MENTHOL_SOLD,
        options: FILTER_OPTIONS.MENTHOL_SOLD_OPTIONS,
      },
      {
        id: 4,
        field: FILTER_FIELDS.PROMO_MENTHOL,
        options: FILTER_OPTIONS.PROMO_MENTHOL_OPTIONS,
      },
      {
        id: 5,
        field: FILTER_FIELDS.NEWPORT_SOLD,
        options: FILTER_OPTIONS.NEWPORT_SOLD_OPTIONS,
      },
    ],
  },
  {
    type: 'double',
    title: 'Cigarillos, little cigars and blunts (LCCs)',
    id: 6,
    collapsed: [
      {
        id: 7,
        field: FILTER_FIELDS.LCC_SOLD,
        options: FILTER_OPTIONS.LCC_SOLD_OPTIONS,
      },
      {
        id: 8,
        field: FILTER_FIELDS.SINGLE_LCC_SOLD,
        options: FILTER_OPTIONS.SINGLE_LCC_SOLD_OPTIONS,
      },
      {
        id: 9,
        field: FILTER_FIELDS.FLAV_LCC_SOLD,
        options: FILTER_OPTIONS.FLAV_LCC_SOLD_OPTIONS,
      },
      {
        id: 10,
        field: FILTER_FIELDS.PROMO_LCC,
        options: FILTER_OPTIONS.PROMO_LCC_SOLD_OPTIONS,
      },
    ],
  },
  {
    type: 'double',
    title: 'Large premium cigars',
    id: 11,
    collapsed: [
      {
        id: 12,
        field: FILTER_FIELDS.CIGAR_SOLD,
        options: FILTER_OPTIONS.CIGAR_SOLD_OPTIONS,
      },
      {
        id: 13,
        field: FILTER_FIELDS.FLAV_CIGAR_SOLD,
        options: FILTER_OPTIONS.FLAV_CIGAR_SOLD_OPTIONS,
      },
    ],
  },
  {
    type: 'double',
    title: 'Smokeless tobacco',
    id: 14,
    collapsed: [
      {
        id: 15,
        field: FILTER_FIELDS.SMOKELESS_SOLD,
        options: FILTER_OPTIONS.SMOKELESS_SOLD_OPTIONS,
      },
      {
        id: 16,
        field: FILTER_FIELDS.FLAV_SMOKELESS_SOLD,
        options: FILTER_OPTIONS.FLAV_SMOKELESS_SOLD_OPTIONS,
      },
      {
        id: 17,
        field: FILTER_FIELDS.PROMO_SMOKELESS,
        options: FILTER_OPTIONS.PROMO_SMOKELESS_OPTIONS,
      },
    ],
  },
  {
    type: 'double',
    title: 'E-cigarettes',
    id: 18,
    collapsed: [
      {
        id: 19,
        field: FILTER_FIELDS.ECIG_SOLD,
        options: FILTER_OPTIONS.ECIG_SOLD_OPTIONS,
      },
      {
        id: 20,
        field: FILTER_FIELDS.FLAV_ECIG_SOLD,
        options: FILTER_OPTIONS.FLAV_ECIG_SOLD_OPTIONS,
      },
      {
        id: 21,
        field: FILTER_FIELDS.PROMO_ECIG,
        options: FILTER_OPTIONS.PROMO_ECIG_OPTIONS,
      },
    ],
  },
];
