import { createInitialValues } from 'utils/custom';

export const formNames = {
  campaignName: 'campaign_name',
  secGeogs: 'sec_geogs',
  forms: 'forms',
  sellTobacco: 'tobacco',
  sellAlcohol: 'alcohol',
  includeRetailer: 'include_all_retailers',
  sampleSize: 'sample_size',
  overSamplingPercentage: 'oversampling_percentage',
  type: 'retailer_types',
  privateCampaign: 'private',
};

export const FORM_ACTION_TYPES = {
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
  GET_CITY: 'GET_CITY',
  RESET_DATA: 'RESET_DATA',
  SET_ALL_OPTIONS: 'SET_ALL_OPTIONS',
};

export const FORM_REQUIRED_FIELDS = [formNames.campaignName, formNames.forms];
export const FORM_REQUIRED_FIELDS_FULL_LIST = [
  formNames.campaignName,
  formNames.forms,
  formNames.sampleSize,
  formNames.overSamplingPercentage,
];

export const radioButtonDefaultValues = [
  {
    id: 2,
    value: true,
    label: 'Yes, I want to include all retailer options',
  },
  {
    id: 3,
    value: false,
    label: 'No, I only want to include some retailers',
  },
];

export const checkboxDefaultValues = [
  {
    id: 1,
    value: 'null',
    label: 'Unknown',
  },
  {
    id: 2,
    value: 't',
    label: 'Yes',
  },
  {
    id: 3,
    value: 'f',
    label: 'No',
  },
];

export const radioButtonsList = [formNames.includeRetailer];

export const selectsList = [formNames.city, formNames.type, formNames.ageRestriction];

export const getInitialValue = (name) => {
  const findName = (arr, inputName) => arr.includes(inputName) && inputName;
  switch (name) {
    case findName(radioButtonsList, name): {
      return radioButtonDefaultValues[0].value;
    }
    case formNames.overSamplingPercentage:
      return '20';
    case formNames.type:
    case formNames.sellTobacco:
    case formNames.sellAlcohol:
      return [];
    case formNames.privateCampaign:
      return false;
    default:
      return '';
  }
};

const defaultCampaignState = Object.values(formNames).reduce(
  (newObj, value) => ({ ...newObj, [value]: getInitialValue(value) }),
  {},
);

export const formInitialState = {
  data: { attributes: defaultCampaignState, type: 'Campaign' },
  errors: createInitialValues(formNames, null),
  canBeSubmitted: false,
};

export const textInputs = [
  {
    id: 1,
    name: formNames.corporation,
    title: 'Corporation',
  },
  {
    id: 2,
    name: formNames.license,
    title: 'License number',
  },
  {
    id: 3,
    name: formNames.other,
    title: 'Other',
  },
  {
    id: 4,
    name: formNames.other2,
    title: 'Other',
  },
];

export const radioButtonsGroup = [
  {
    title: 'Sells tobacco',
    name: formNames.sellTobacco,
  },
  {
    title: 'Sells alcohol',
    name: formNames.sellAlcohol,
  },
  {
    title: 'Has pharmacy counter',
    name: formNames.hasPharmacy,
  },
  {
    title: 'Has AVC agreement',
    name: formNames.hasAVC,
  },
  {
    title: 'Accepts WIC',
    name: formNames.acceptsWIC,
  },
  {
    title: 'Accepts SNAP',
    name: formNames.acceptsSNAP,
  },
  {
    title: 'Internet Sales',
    name: formNames.internetSales,
  },
];

export const retailerTypeOptions = [
  {
    id: 1,
    value: 1,
    label: 'Convenience store (with or without gas)',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 2,
    value: 2,
    label: 'Drug store/Pharmacy',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 3,
    value: 3,
    label: 'Beer, wine, liquor store',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 4,
    value: 4,
    label: 'Grocery store',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 5,
    value: 5,
    label: 'Mass merchandiser',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 6,
    value: 6,
    label: 'Tobacco shop',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 7,
    value: 7,
    label: 'Hookah lounge',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 8,
    value: 8,
    label: 'E-cig / Vape shop',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 9,
    value: 9,
    label: 'Bar or restaurant',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 10,
    value: 10,
    label: 'Smoking bar',
    key: 'cdph_business_type',
    name: formNames.type,
  },
  {
    id: 99,
    value: 99,
    label: 'Other not listed',
    key: 'cdph_business_type',
    name: formNames.type,
  },
];

export const ageRestrictedFacilityOptions = [
  {
    id: 1,
    value: 1,
    label: '18',
    name: formNames.ageRestriction,
  },
  {
    id: 2,
    value: 2,
    label: '21',
    name: formNames.ageRestriction,
  },
  {
    id: 3,
    value: 3,
    label: 'Other',
    name: formNames.ageRestriction,
  },
];

export const ageRestriction = ageRestrictedFacilityOptions.reduce((acc, item) => {
  acc[item.value] = item.label;
  return acc;
}, {});

export const PAGE_COLUMNS = 'one_column';
