import React from 'react';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import * as Styled from './styles';
import { VISIT_CHARACTERISTICS_TABS } from './constant';

function AssessmentCharacteristics() {
  return (
    <Styled.Wrapper>
      <Styled.FiltersTitle>Assessment Results by Product Type </Styled.FiltersTitle>
      {VISIT_CHARACTERISTICS_TABS.map((item) => (
        <>
          <Styled.FiltersTitle>{item.title}</Styled.FiltersTitle>
          {item.collapsed.map((elem) => (
            <CheckedList name={elem.field} options={elem.options} />
          ))}
        </>
      ))}
    </Styled.Wrapper>
  );
}

export default AssessmentCharacteristics;
