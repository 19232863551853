/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterEntity } from '_entities';
import { InputField } from '../../old/styles';
import * as Styled from './styles';

const { setShadowFilter } = FilterEntity.actions;

export const InputWrapper = ({ title, placeholder, name }) => {
  const {
    filter: { shadowFilters },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const input = useRef(null);

  const handleChange = ({ target: { value } }) => {
    setInputValue(value);
    dispatch(setShadowFilter({ ...shadowFilters, [name]: value }));
  };

  useEffect(() => {
    setInputValue(shadowFilters[name] || '');
  }, [shadowFilters[name]]);

  return (
    <Styled.Wrapper>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Label hidden htmlFor={name} id={`inputId${name}`} aria-label={`input ${name}`}>
        {`input ${name}`}
      </Styled.Label>
      <InputField
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        name={name}
        id={name}
        tabIndex={0}
        ref={input}
        type="text"
        aria-labelledby={`inputId${name}`}
      />
    </Styled.Wrapper>
  );
};

InputWrapper.defaultProps = {
  title: '',
  placeholder: '',
  name: '',
};
