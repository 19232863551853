import PropTypes from 'prop-types';
import HasAccess from './hasAccess';

// data it's relationships of object
// TODO update data to make it all data obj with includes if needed
function AccessControl({ permission, children, data, roleRuleSet }) {
  return HasAccess(permission, data, roleRuleSet) && children;
}

AccessControl.propTypes = {
  permission: PropTypes.string.isRequired || PropTypes.array.isRequired || PropTypes.bool,
  children: PropTypes.node.isRequired,
  data: PropTypes.object,
  roleRuleSet: PropTypes.object,
};

AccessControl.defaultProps = {
  permission: null,
  data: {},
  roleRuleSet: {},
};

export default AccessControl;
