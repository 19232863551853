import React, { useEffect, useRef, useState } from 'react';
import { toast, Slide, Zoom } from 'react-toastify';
import { SUCCESS_ICON, WARNING_ICON, ERROR_ICON } from 'assets/icons/notifications';
import { UserEntity, RetailerEntity } from '_entities';
import { useSelector } from 'react-redux';
import { Img } from 'ui-kit';
import { NOTIFICATION_TYPES } from '_constants';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import './styles.css';

const { getRetailerToastId } = RetailerEntity.selectors;
const { getUserToastId } = UserEntity.selectors;

const IMPORT_NOTFICATIONS = {
  VALIDATION_FAILED: {
    message: (filename) => (
      <p>
        Validation of <span>{filename}</span> failed. Check your email for errors in the file that must be resolved.
        Once errors are corrected, submit the fixed file via the upload tool.
      </p>
    ),
    type: NOTIFICATION_TYPES.VALIDATION_ERROR,
  },
  FAILED: {
    message: (filename) => (
      <p>
        Failed to upload <span>{filename}</span>. Check your email for errors in the file that must be resolved. Once
        errors are corrected, submit the fixed file via the upload tool.`
      </p>
    ),
    type: NOTIFICATION_TYPES.ERROR,
  },
  SUCCESS: {
    message: (filename) => (
      <p>
        <span>{filename} was uploaded successfully.</span>
      </p>
    ),
    type: NOTIFICATION_TYPES.SUCCESS,
  },
};

const DEFAULT_NOTIFICATION = {
  transition: Slide,
  position: 'bottom-center',
  draggable: false,
  hideProgressBar: true,
  autoClose: 7000,
  closeOnClick: true,
};

const INFO_NOTIFICATION = {
  transition: Zoom,
  position: 'top-right',
  draggable: false,
  hideProgressBar: true,
  autoClose: 3000,
  closeOnClick: true,
  className: 'notification_bell_messages',
};

const INFO_LIST_NOTIFICATION = {
  transition: Zoom,
  closeOnClick: false,
  closeButton: false,
  autoClose: false,
  position: 'bottom-center',
  draggable: false,
};

const copyToClipboard = (element) => {
  const range = document.createRange();
  range.selectNodeContents(element);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);

  document.execCommand('copy');

  window.getSelection().removeAllRanges();
};

const InfoListNotification = ({ notificationTitle, closeToast, notificationList, listRef }) => {
  const modalRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }

    const handleOutsideClickOrFocus = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeToast();
      }
    };

    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeToast();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        closeToast();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('focusin', () => handleOutsideClickOrFocus);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('focusin', () => handleOutsideClickOrFocus);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [closeToast]);

  const handleCopyClick = () => {
    copyToClipboard(listRef.current);
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  };

  return (
    <Styled.InfoListWrapper ref={modalRef} tabIndex="-1">
      <Img className="Toastify__toast-icon" size={[20, 20]} src={WARNING_ICON} />
      <Styled.CopyButtonWrapper>
        {isVisible && <Styled.CopyText>Users list copied to your clipboard</Styled.CopyText>}
        <Styled.CopyButton type="button" onClick={handleCopyClick} />
      </Styled.CopyButtonWrapper>
      <Styled.CancelButton type="button" onClick={closeToast} />
      <Styled.InfoListTitle>{notificationTitle}</Styled.InfoListTitle>
      {!isEmpty(notificationList) && (
        <Styled.InfoList ref={listRef}>
          {notificationList.map((userInfo, index) => (
            <li key={index}>{userInfo}</li>
          ))}
        </Styled.InfoList>
      )}
    </Styled.InfoListWrapper>
  );
};

const ImportNotification = ({ closeProgressBar, status, id, filename, type }) => {
  const { heading, defaultIcon } = getNotificationPayload(IMPORT_NOTFICATIONS[status].type);
  const retailerToastId = useSelector(getRetailerToastId);
  const userToastId = useSelector(getUserToastId);
  const toastId = type === 'user' ? userToastId : retailerToastId;

  const handleCloseToast = (barImportId) => {
    closeProgressBar(barImportId, toastId);
  };

  return (
    <Styled.ImportNotificiationWrapper tabIndex="-1">
      <Styled.CancelButton key={toastId} type="button" onClick={() => handleCloseToast(id)} />
      <Styled.ImportHeadingWrapper>
        <Img className="Toastify__toast-icon" size={[20, 20]} src={defaultIcon} />
        <h3 className="Toastify__toast-text-heading">{heading}</h3>
      </Styled.ImportHeadingWrapper>
      <Styled.InfoList>{IMPORT_NOTFICATIONS[status].message(filename)}</Styled.InfoList>
    </Styled.ImportNotificiationWrapper>
  );
};

const getNotificationPayload = (type) => {
  switch (type) {
    case NOTIFICATION_TYPES.SUCCESS:
      return {
        heading: 'Success',
        defaultIcon: SUCCESS_ICON,
      };
    case NOTIFICATION_TYPES.WARNING:
      return {
        heading: 'Warning',
        defaultIcon: WARNING_ICON,
      };
    case NOTIFICATION_TYPES.ERROR:
      return {
        heading: 'Error',
        defaultIcon: ERROR_ICON,
      };
    case NOTIFICATION_TYPES.VALIDATION_ERROR:
      return {
        heading: 'Validation Error',
        defaultIcon: ERROR_ICON,
      };
    default:
      return {};
  }
};

const notificationBody = (type, text, icon) => {
  const { heading, defaultIcon } = getNotificationPayload(type);

  return (
    <div className="Toastify__toast-content">
      {icon && <Img className="Toastify__toast-icon" size={[20, 20]} src={icon || defaultIcon} />}
      <div className="Toastify__toast-text-wrapper">
        <h3 className="Toastify__toast-text-heading">{heading}</h3>
        <p className="Toastify__toast-text-content">{text}</p>
      </div>
    </div>
  );
};

const info = ({ notification, icon, onClick }) => {
  toast.dismiss();

  return toast.info(
    notificationBody(NOTIFICATION_TYPES.INFO, notification, icon, onClick),
    icon ? DEFAULT_NOTIFICATION : { ...INFO_NOTIFICATION, onClick },
  );
};
const success = (notification, icon) =>
  toast.success(notificationBody(NOTIFICATION_TYPES.SUCCESS, notification, icon), DEFAULT_NOTIFICATION);
const warning = (notification, icon) =>
  toast.warning(notificationBody(NOTIFICATION_TYPES.WARNING, notification, icon), DEFAULT_NOTIFICATION);
const error = (notification, icon, props = {}) =>
  toast.error(notificationBody(NOTIFICATION_TYPES.ERROR, notification, icon), {
    ...DEFAULT_NOTIFICATION,
    ...props,
  });
const importStatus = ({ closeProgressBar, status, filename, id, type }) => {
  return toast.info(
    ({ closeToast }) => (
      <ImportNotification
        status={status}
        closeToast={closeToast}
        closeProgressBar={closeProgressBar}
        filename={filename}
        id={id}
        type={type}
      />
    ),
    INFO_LIST_NOTIFICATION,
  );
};

const infoList = (notification, list) => {
  if (isEmpty(list)) return;
  const listRef = React.createRef();
  toast.info(
    ({ closeToast }) => (
      <InfoListNotification
        notificationTitle={notification}
        closeToast={closeToast}
        notificationList={list}
        listRef={listRef}
      />
    ),
    INFO_LIST_NOTIFICATION,
  );
};

const custom = (notification, props = {}) =>
  toast(notification, {
    ...DEFAULT_NOTIFICATION,
    autoClose: false,
    ...props,
  });
const removeAllNotifications = () => toast.dismiss();

export default {
  info,
  infoList,
  importStatus,
  success,
  warning,
  error,
  custom,
  removeAllNotifications,
  getNotificationPayload,
};

InfoListNotification.propTypes = {
  notificationTitle: PropTypes.string,
  closeToast: PropTypes.func,
  notificationList: PropTypes.array,
  listRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

InfoListNotification.defaultProps = {
  notificationTitle: '',
  closeToast: () => {},
  notificationList: [],
  listRef: { current: null },
};

ImportNotification.propTypes = {
  filename: PropTypes.string,
  status: PropTypes.string,
  closeProgressBar: PropTypes.func,
  type: PropTypes.string,
  id: PropTypes.number,
};

ImportNotification.defaultProps = {
  filename: '',
  status: '',
  closeProgressBar: () => {},
  type: '',
  id: 0,
};
