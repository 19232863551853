import React, { useMemo } from 'react';
import { compact, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { BaseTable } from 'ui-kit';
import PropTypes from 'prop-types';
import { URLS } from '_constants';
import { INITIAL_COLUMNS } from './constants';

/* eslint-disable */
const getInitialColumns = ({ columns }) => {
  return compact([
    columns.date && {
      Header: () => <HeaderCell col="Date" />,
      minWidth: 120,
      accessor: 'attributes.visit_time',
      Cell: ({ value, original }) => {
        const modifiedDate = new Date(value).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        const visitType = original.attributes.visit_type_text;
        const url = () => {
          switch (visitType) {
            case 'Enforcement':
              return URLS.enforcement;
            case 'Education':
              return URLS.education;
            default:
              return URLS.assessments;
          }
        };
        return <LinkCell id={uniqueId()} text={modifiedDate} to={`${url()}/${original.id}`} target="_blank" />;
      },
    },
    columns.type && {
      Header: () => <HeaderCell col="Type" />,
      minWidth: 110,
      accessor: 'attributes.visit_type_text',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    columns.agency && {
      Header: () => <HeaderCell col="Agency" />,
      minWidth: 110,
      accessor: 'attributes.agency_name',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    columns.violation && {
      Header: () => <HeaderCell col="Violation" />,
      minWidth: 120,
      accessor: 'attributes.violation_text',
      Cell: ({ value }) => <TextCell text={value} />,
    },
  ]);
};
/* eslint-enable */

const VisitTable = ({ data }) => {
  const columns = useMemo(
    () => INITIAL_COLUMNS.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [INITIAL_COLUMNS],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitialColumns({
        columns,
      }),
    [columns],
  );

  return <BaseTable isEdit={false} columns={wrappedColumns} data={data} />;
};

VisitTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VisitTable;
