/* eslint new-cap: ["error", { "newIsCap": false }] */
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { notification } from 'utils/services';
import { PAGE_TYPES } from '_constants';
import moment from 'moment';

const CANVAS_MAX_HEIGHT = {
  IE: 8192,
  OTHER: 32767,
};

const generatePage = ({ doc, imgWidth, imagesHights, imagesArray }) => {
  const xPosition = 10;
  let totalPages = 1;
  let startPosition = 5;
  let pageCount = 1;
  let yPosition = 5;
  doc.setFontSize(7);
  doc.text(`Printed on ${moment(new Date()).format('MM/DD/YYYY').toString()}`, 180, 4);

  for (let index = 0; index < imagesHights.length; index += 1) {
    if (startPosition + imagesHights[index] > 291) {
      totalPages += 1;
      startPosition = 5;
    }
    startPosition += imagesHights[index];
  }

  doc.text(`Page ${pageCount.toString()} of ${totalPages.toString()}`, 190, 292);

  for (let index = 0; index < imagesHights.length; index += 1) {
    if (yPosition + imagesHights[index] > 291) {
      doc.addPage();
      pageCount += 1;
      doc.text(`Page ${pageCount.toString()} of ${totalPages.toString()}`, 190, 292);
      yPosition = 5;
    }

    doc.addImage(imagesArray[index], 'png', xPosition, yPosition, imgWidth, imagesHights[index], undefined, 'FAST');

    yPosition += imagesHights[index];
  }
};

const getChildElements = (pageType) => {
  const isTablet = !document.querySelector('.rt-tr-group');
  switch (pageType) {
    case PAGE_TYPES.TABLE: {
      const domArray = isTablet
        ? document.querySelectorAll('.table-li')
        : document.querySelectorAll('.react-table__wrapper');

      const [header, filter, info, footer] = [
        document.querySelector('.table-header'),
        document.querySelector('.table-filters'),
        document.querySelector('.table-info'),
        document.querySelector('.table-footer'),
      ];

      return [header, filter, info, ...domArray, footer];
    }
    case PAGE_TYPES.COLLECT: {
      const domArray = isTablet ? document.querySelectorAll('ul') : document.querySelectorAll('.table-wrapper');
      const activeTable = Array.from(document.querySelectorAll('.table-button')).findIndex((el) => el.tabIndex === 0);
      const indexes = [activeTable, domArray.length - 1];
      const [aciveTab] = indexes;

      const [header, filter, info, footer] = [
        document.querySelectorAll('.table-header'),
        document.querySelector('.table-filters'),
        document.querySelector('.table-info'),
        document.querySelectorAll('.table-footer'),
      ];

      return [header[0], filter, info, ...domArray[aciveTab].children, footer[aciveTab]];
    }
    case PAGE_TYPES.INFOGRAPHIC: {
      return Array.from(document.querySelector('#html-canvas2').children);
    }

    default:
      return [];
  }
};

export const newSavePageToPdf = async ({ setExportLoading, hasFilters = false } = {}, pageType) => {
  try {
    const page = document.querySelector('#html-canvas');
    const elementHeight = page.getBoundingClientRect().height;
    document.body.scrollTo({ top: 0 }); // this line need to correct mobile layout
    // Keep this block here. All browsers have different behavior.
    if (elementHeight > CANVAS_MAX_HEIGHT.OTHER) {
      notification.error(
        `To print the page, reduce amount of table rows ${hasFilters && 'and click "Show less" on filter sections'}`,
      );
      setExportLoading(false);
      return;
    }

    const childElements = getChildElements(pageType);

    const promises = childElements.map((elem) => html2canvas(elem, { scale: 1 }));

    const resultsArray = await Promise.allSettled(promises);

    const canvasesArray = resultsArray
      .filter(({ value }) => value)
      .map(({ value }) => value)
      .filter((canvas) => canvas.height);

    const imagesArray = canvasesArray.map((canvas) => canvas.toDataURL('image/png'));

    const imgWidth = 190;

    const doc = new jsPDF('p', 'mm', 'a4', true);

    const imagesHights = canvasesArray.map((canvas) => {
      const imageHight = (canvas.height * imgWidth) / canvas.width;
      if (imageHight > 255) {
        return 255;
      }
      return imageHight;
    });

    generatePage({
      doc,
      imgWidth,
      imagesHights,
      imagesArray,
    });
    doc.save('file.pdf');
    setExportLoading(false);
  } catch (e) {
    console.log(e);
    setExportLoading(false);
  }
};
