import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  padding: 13px 20px;

  @media ${DEVICES.tablet} {
    padding: 10px 18px;
  }
`;

export const Title = styled.legend`
  font-size: 16px;
  line-height: 26px;
  color: var(--dark);
  font-weight: bold;
  margin: 0 0 15px;

  @media ${DEVICES.tablet} {
    line-height: 22px;
  }
`;

export const Legend = styled.legend``;
