import styled, { css } from 'styled-components';
import { deviceLess } from '_constants/DEVICES';
import { ButtonWrapper } from '../Button/styles';

export const Error = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 11px;
  color: var(--danger);
`;

export const Title = styled.span`
  display: block;
  margin-bottom: 12px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  > :not(:first-child) {
    margin-top: ${({ direction }) => (direction === 'column' ? '8px' : '0')};
  }
  > :not(:last-child) {
    margin-right: ${({ direction }) => (direction === 'row' ? '8px' : '0')};
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ButtonWrapper} {
    height: auto;
    padding: 0;
    opacity: 0;
    transition: opacity 0.2s;
    border-radius: 0;
    font-size: 12px;
    line-height: 1.4;
    color: var(--grey);
    text-decoration: underline;
    margin: 0 0 0 10px;
    width: 60px;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    ${ButtonWrapper} {
      opacity: 1;
    }
  }
`;

export const SelectAll = styled.div`
  color: var(--sky);
  font-weight: 700;
  cursor: pointer;
  margin: 0 0 10px;
`;

const columnsStyles = {
  one_column: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${Title} {
        min-width: 200px;
        display: inline-block;
        word-wrap: break-word;
      }

      ${OptionsWrapper} {
        padding-left: 20px;
        width: 100%;
        max-width: 600px;
        min-width: 200px;

        > div {
          margin-right: 30px;
        }
      }

      ${Error} {
        left: 200px;
        padding-left: 20px;
        width: 100%;
        max-width: 600px;
        min-width: 200px;
      }
    }
  `,
  '': ``,
};

export const Wrapper = styled.div`
  ${({ pageColumns }) => pageColumns && columnsStyles[pageColumns]}
`;
