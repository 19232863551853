/* eslint-disable */
/* eslint-disable radix */
import { isEmpty } from 'lodash';
import { fetchConstructor } from '../api/api';
import apiData from '../api/config';
import {
  enforcementTableData,
  enforcementDetailsData,
  enforcementAdded,
  enforcementEdited,
  enforcementDeleted,
  enforcementStartLoad,
} from './enforcement.action';
import { setIsNotification } from '_entities/old/Retailers/retailers.action';
import processQSParams from 'utils/old/processQSParams';
import parseEnforcements from 'utils/old/parseTableData/parseEnforcements';

const fetchEnforcements = async ({ page = 1, perPage, sortBy, sortOrder, timeZone, ...qsParams }, dispatch) => {
  try {
    const token = localStorage.getItem('Authorization');
    const headers = { Authorization: `Token ${token}` };

    const params = processQSParams({
      page,
      perPage,
      sortOrder,
      sortBy,
      ...qsParams,
    });

    const response = await fetchConstructor(
      'get',
      `${apiData.enforcementTableData}?${params}&include=retailer,agency,campaign,user`,
      headers,
    );

    const { data, included, meta } = response.data;

    const newData = parseEnforcements({ data, included }, timeZone);
    dispatch(enforcementTableData({ data: newData, pagination: meta?.pagination }));
    return response.data.data;
  } catch (e) {
    console.log(e);
  }
};

const enforcementDetails = async (dispatch, token, id, history, setIsData = () => {}) => {
  try {
    setIsData(false);
    dispatch(enforcementStartLoad());
    const headers = { Authorization: `Token ${token}` };
    const response = await fetchConstructor(
      'get',
      `${apiData.enforcementTableData}/${id}?include=retailer,agency,last_modified_by,user,last_modified_by_agency`,
      headers,
    );
    if (!isEmpty(response.data.errors)) {
      history.push('/error-page');
    } else {
      dispatch(enforcementDetailsData(response.data));
      setIsData(true);
    }
    return response.data.data;
  } catch (e) {
    console.log(e);
  }
};

const getEnforcementDataPure = async (id) => {
  const token = sessionStorage.getItem('token');

  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/vnd.api+json',
  };
  const response =
    id &&
    (await fetchConstructor(
      'get',
      `${apiData.enforcementTableData}/${id}?include=retailer,agency,last_modified_by,user,last_modified_by_agency`,
      headers,
    ));
  if (response?.status === 404) {
    return undefined;
  }
  if (response?.status === 200) {
    return response.data.data;
  }
  return undefined;
};

const addEnforcement = async (dispatch, history, payload, getToast) => {
  try {
    const token = sessionStorage.getItem('token');
    dispatch(enforcementAdded(false));
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };

    const data = await fetchConstructor('post', apiData.enforcementTableData, headers, payload);

    getToast();
    dispatch(enforcementAdded(true));
  } catch (e) {
    console.log(e);
  }
};

const editEnforcement = async (dispatch, history, payload, id, shouldPush = true, getToast = () => {}) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };

    const data = await fetchConstructor('patch', `${apiData.enforcementTableData}/${id}`, headers, payload);
    getToast();
    dispatch(enforcementAdded(true));
    shouldPush && dispatch(enforcementEdited(true));
    shouldPush && history.push(`/enforcement/${id}`);
  } catch (e) {
    console.log(e);
  } finally {
    setTimeout(() => {
      dispatch(enforcementAdded(false));
    }, 5000);
  }
};

const deleteEnforcement = async (dispatch, history, id, getToast) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };

    const data = await fetchConstructor('delete', `${apiData.enforcementTableData}/${id}`, headers);
    getToast();
    dispatch(enforcementDeleted(true));
    history.push('/enforcement');
  } catch (e) {
    console.log(e);
  } finally {
    setTimeout(() => {
      dispatch(setIsNotification(false));
    }, 5000);
  }
};

const visitsCSV = async (dispatch, queryParams, getToast) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const params = queryParams;
    const response =
      queryParams &&
      (await fetchConstructor('get', `${apiData.visitsCSV}${params}&include=aggregationpolygon_set`, headers));

    getToast();
    dispatch(setIsNotification(true));
  } catch (e) {
    console.log(e);
  }
};

export {
  fetchEnforcements,
  enforcementDetails,
  addEnforcement,
  editEnforcement,
  deleteEnforcement,
  visitsCSV,
  getEnforcementDataPure,
};
