export const splitTableIntoParts = (data, columns, loading, defaultPageSize, pdfNotRequered) => {
  if (pdfNotRequered)
    return [
      {
        data,
        columns,
        filterable: false,
        resizable: false,
        showPagination: false,
        loading,
        minRows: data.length || 1,
        defaultPageSize: !data.length ? defaultPageSize : 1,
        pageSize: data.length,
        className: 'react-table__wrapper',
        sortable: false,
      },
    ];

  const reactTable = [];

  for (let index = 0; index < data.length; index += 50) {
    reactTable.push({
      data: data.slice(index, index + 50),
      columns,
      filterable: false,
      resizable: false,
      showPagination: false,
      loading,
      minRows: data.slice(index, index + 50).length || 1,
      defaultPageSize: !data.length ? defaultPageSize : 1,
      pageSize: data.slice(index, index + 50).length,
      className: index === 0 ? 'react-table__wrapper' : 'react-table__wrapper extra-table',
      sortable: false,
    });
  }
  return reactTable;
};
