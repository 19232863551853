/* eslint-disable react/prop-types */
import React from 'react';
import { Fieldset } from '../../old/styles';
import * as Styled from './styles';

export const FormGroup = ({ title, children, legendText }) => (
  <Styled.Wrapper>
    <Fieldset>
      {!title && legendText && <Styled.Legend hidden>{legendText}</Styled.Legend>}
      {title && <Styled.Title>{title}</Styled.Title>}
      {children}
    </Fieldset>
  </Styled.Wrapper>
);
