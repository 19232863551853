/* eslint-disable */
/* react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty, uniqueId } from 'lodash';
import { FilterEntity } from '_entities';
import { Picker } from 'ui-kit';
import { ErrorMessage } from '../../old/styles';
import * as Styled from './styles';

const { setShadowFilter, setFilterError } = FilterEntity.actions;
const InputError = (message) => <ErrorMessage>{message}</ErrorMessage>;

const DEFAULT_DATES = {
  start: '',
  end: '',
};

const DEFAULT_ERRORS = {
  start: '',
  end: '',
};

function DatePickerGroup({ title, name }) {
  const dispatch = useDispatch();
  const {
    filter: { shadowFilters, filterErrors },
  } = useSelector((state) => state);
  const [dates, setDates] = useState(DEFAULT_DATES);
  const [errors, setErrors] = useState(DEFAULT_ERRORS);

  const convertDate = (value) => {
    if (value) {
      return moment(value).format('YYYY-MM-DD');
    }
    return '';
  };

  const validateDates = useCallback(
    (start, end) => {
      if (!isEmpty(start) && !isEmpty(end) && moment(start).isAfter(moment(end))) {
        setErrors(() => ({
          end: '',
          start: 'Start date cannot be greater than end date',
        }));
        // need to discuss with Georgy
        dispatch(setFilterError({ ...filterErrors, [name]: true }));
        return;
      }
      if (!isEmpty(start) && moment(start).isAfter(moment())) {
        setErrors(() => ({
          end: '',
          start: 'Start date cannot be greater than current date',
        }));
        dispatch(setFilterError({ ...filterErrors, [name]: true }));
        return;
      }
      if (!isEmpty(start) && isEmpty(end)) {
        setErrors((prev) => ({
          ...prev,
          end: 'End date is required',
        }));
        // need to discuss with Georgy
        dispatch(setFilterError({ ...filterErrors, [name]: true }));
        return;
      }
      if (isEmpty(start) && !isEmpty(end)) {
        setErrors((prev) => ({
          ...prev,
          start: 'Start date is required',
        }));
        // need to discuss with Georgy
        dispatch(setFilterError({ ...filterErrors, [name]: true }));
      }
    },
    [name, dispatch],
  );

  const getDate = (value) => {
    if (value) {
      return new Date(moment(value).format());
    }
    return '';
  };

  const fieldCheck = (field, position) => {
    if (field) return field.split(',')[position];
    return field;
  };

  const handleChangeDate = (value, item) => {
    let newDate = null;

    if (item === 'start') {
      newDate = `${convertDate(value) || ''},${dates.end || ''}`;
    }
    if (item === 'end') {
      newDate = `${dates.start || ''},${convertDate(value) || ''}`;
    }

    if (newDate === ',') newDate = '';
    const newFilterErrors = Object.entries(filterErrors).reduce(
      (acc, [key, errorValue]) => (key === name ? acc : { ...acc, [key]: errorValue }),
      {},
    );
    setErrors(DEFAULT_ERRORS);
    dispatch(setFilterError(newFilterErrors || {}));
    dispatch(setShadowFilter({ ...shadowFilters, [name]: newDate }));
  };

  useEffect(() => {
    const shadowFiltersDates = shadowFilters[name]?.split(',');
    if (isEmpty(shadowFiltersDates) || (shadowFiltersDates.length === 1 && shadowFiltersDates[0] === '')) {
      setDates(DEFAULT_DATES);
      setErrors(DEFAULT_ERRORS);
      return;
    }
    setDates({
      start: isEmpty(shadowFiltersDates[0]) ? '' : shadowFiltersDates[0],
      end: isEmpty(shadowFiltersDates[1]) ? '' : shadowFiltersDates[1],
    });
    validateDates(shadowFiltersDates[0], shadowFiltersDates[1]);
  }, [shadowFilters]);

  return (
    <Styled.Wrapper>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.PickerWrapper>
        <Picker
          label="Start date (mm/dd/yyyy)"
          selected={getDate(dates.start)}
          onChange={(date) => handleChangeDate(date, 'start', name)}
          showMonthYearDropdown
          error={errors.start}
          required
          field={fieldCheck(shadowFilters[name], 0)}
          inputError={InputError(errors.start)}
          idInput={`getDataPickerFilter${uniqueId()}`}
          inputFor={`closeDataFilter${uniqueId()}`}
        />
        <Picker
          label="End date (mm/dd/yyyy)"
          selected={getDate(dates.end)}
          onChange={(date) => handleChangeDate(date, 'end', name)}
          dateFormatCalendar="MMMM yyyy"
          showMonthYearDropdown
          error={errors.end}
          required
          field={fieldCheck(shadowFilters[name], 1)}
          inputError={InputError(errors.end)}
          idInput={`getDataPickerEndFilter${uniqueId()}`}
          inputFor={`endDataFilter${uniqueId()}`}
        />
      </Styled.PickerWrapper>
    </Styled.Wrapper>
  );
}

DatePickerGroup.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default DatePickerGroup;
