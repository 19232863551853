/* eslint-disable no-shadow */
import React, { useState, createRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import RouteItem from './components/RouteItem';
import CollapsedItem from './components/CollapsedItem';

function MenuItems({ routes }) {
  const [headerFocus, setHeaderFocus] = useState(1);
  const tabsRefs = routes.reduce((acc, item, index) => ({ ...acc, [index + 1]: createRef() }), {});
  const handleNextTab = (firstTabInRound, nextTab, lastTabInRound) => {
    const tabToSelect = headerFocus === lastTabInRound ? firstTabInRound : nextTab;
    setHeaderFocus(tabToSelect);
    tabsRefs[tabToSelect].current.focus();
  };
  const handleKeyPress = (e) => {
    const tabCount = routes.length;
    if (e.keyCode === 37) {
      e.preventDefault();
      const last = tabCount;
      const next = headerFocus - 1;
      handleNextTab(last, next, 1);
    }
    if (e.keyCode === 39) {
      e.preventDefault();
      const first = 1;
      const next = headerFocus + 1;
      handleNextTab(first, next, tabCount);
    }
  };

  const location = useLocation();

  const handleLinkClick = useCallback(
    (e, path) => {
      if (path === location.pathname) {
        e.preventDefault();
      }
    },
    [location.pathname],
  );

  const getANewFocus = (index) => {
    tabsRefs[index]?.current?.focus();
  };
  return (
    <Styled.RouteList onKeyDown={handleKeyPress} role="menu">
      {routes.reduce((acc, route, index) => {
        if (!route.isShow) return acc;

        return route.path
          ? acc.concat(
              <RouteItem
                route={route}
                key={route.id}
                onClick={handleLinkClick}
                index={index + 1}
                tabRef={tabsRefs[index + 1]}
                headerFocus={headerFocus}
              />,
            )
          : acc.concat(
              <CollapsedItem
                key={route.id}
                index={index + 1}
                headerFocus={headerFocus}
                route={route}
                tabRef={tabsRefs[index + 1]}
                getANewFocus={getANewFocus}
              />,
            );
      }, [])}
    </Styled.RouteList>
  );
}

MenuItems.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default MenuItems;
