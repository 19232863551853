import { isString } from 'lodash';
import styled from 'styled-components';

export const Error = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 11px;
  color: red;
`;

export const Title = styled.legend`
  display: block;
  margin-bottom: 12px;
`;

export const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  margin-bottom: ${({ isGroupOfCheckboxes }) => (isGroupOfCheckboxes ? '15px' : '0px')};
`;

export const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

export const LI = styled.li`
  list-style-type: none;
  padding-left: ${({ withSelectAll }) => (isString(withSelectAll) ? '15px' : '0px')};
`;

export const Legend = styled.legend``;
