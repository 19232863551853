/* eslint-disable */
import React, { useState, useCallback, memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { INFO_DARK_ICON, INFO_ICON } from 'assets/icons';
import { Img } from 'ui-kit';
import FilterArrows from 'ui-kit/old/FilterArrows';
import { useWindowDimensions } from 'hooks';
import * as Styled from './styles';

const DESKTOP_SCREEN_SIZE = 900;

const TOOLTIP_INFO = {
  description: {
    distance: ['Distance between retailers'],
    similarity_name: ['How similar are retailer names'],
    similarity: ['How similar are retailer names + address'],
    visitId: [
      '"completed visit" - link to the visit that closed this assignment',
      '"completed visit with a violation" - link to the visit with a violation that created this reassignment',
    ],
  },
  positionX: {
    distance: '80%',
    similarity_name: '80%',
    similarity: '80%',
    visitId: '80%',
  },
};

const HeaderCell = ({
  col,
  sortBy,
  handleSort,
  sortOption,
  isFilterArrows,
  limitedWidth = false,
  isHideSorting,
  hasPopUp,
}) => {
  const [order, setOrder] = useState(-1);
  const isActive = useMemo(() => sortOption.sortBy === sortBy, [sortBy, sortOption.sortBy]);
  const { width: viewportWidth } = useWindowDimensions();
  const isArrowVisible = viewportWidth > DESKTOP_SCREEN_SIZE;

  useEffect(() => {
    if (!isFilterArrows) return;
    setOrder(isActive ? sortOption.sortOrder : 0);
  }, [isActive, isFilterArrows, sortBy, sortOption.sortBy, sortOption.sortOrder]);

  const handleClick = handleSort && useCallback(() => handleSort(order), [handleSort, order]);

  return (
    <Styled.CellWrapper
      id={uniqueId()}
      limitedWidth={limitedWidth}
      disabled={!isArrowVisible}
      onClick={handleClick}
      tabIndex={isFilterArrows ? 0 : -1}
    >
      <Styled.CellTitle aria-label={`${col} title column`}>{col}</Styled.CellTitle>
      {hasPopUp && (
        <Styled.TooltipWrapper>
          <Styled.ImageWrapper>
            <Img tabIndex={0} size={[20, 20]} colour="black" src={INFO_DARK_ICON} aria-label={col} alt={col} />
          </Styled.ImageWrapper>
          <Styled.TooltipContainer positionX={TOOLTIP_INFO.positionX[sortBy]}>
            <Styled.TooltipContent>
              <Styled.ToolTipSection>
                {TOOLTIP_INFO.description[sortBy].map((text, index) => (
                  <p key={index}>{text}</p>
                ))}
              </Styled.ToolTipSection>
            </Styled.TooltipContent>
          </Styled.TooltipContainer>
        </Styled.TooltipWrapper>
      )}
      {isArrowVisible && isFilterArrows && (
        <FilterArrows isHideSorting={isHideSorting} isActive={isActive} order={sortOption.sortOrder} />
      )}
    </Styled.CellWrapper>
  );
};

HeaderCell.propTypes = {
  col: PropTypes.string.isRequired,
  sortOption: PropTypes.shape({}),
  isFilterArrows: PropTypes.bool,
  isHideSorting: PropTypes.bool,
  hasPopUp: PropTypes.bool,
};

HeaderCell.defaultProps = {
  isFilterArrows: true,
  sortOption: {},
  isHideSorting: false,
  hasPopUp: false,
};

export default memo(HeaderCell);
