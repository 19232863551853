/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { uniqueId } from 'lodash';
import ArrowLeftPause from '../../icons/arrowLeftPause';
import ArrowLeft from '../../icons/arrowLeft';
import ArrowRight from '../../icons/arrowRight';
import ArrowRightPause from '../../icons/arrowRightPause';
import * as Styled from './styles';

function PaginationNavigation({ currentPage, handlePageChange, pages, titleTable = '' }) {
  return (
    <Styled.Wrapper>
      {currentPage !== 1 && (
        <>
          <Styled.Button
            type="button"
            className="ArrowRightPause"
            disabled={currentPage <= 1}
            onClick={() => handlePageChange({ value: 1 })}
            aria-label={`first ${titleTable} page`}
            id={uniqueId()}
          >
            <span className="sr-only">Close</span>
            <ArrowLeftPause />
          </Styled.Button>
          <Styled.Button
            type="button"
            disabled={currentPage <= 1}
            onClick={() => handlePageChange({ value: currentPage - 1 })}
            aria-label={`previous ${titleTable} page`}
            id={uniqueId()}
          >
            <span className="sr-only">Close</span>
            <ArrowLeft />
          </Styled.Button>
        </>
      )}

      {currentPage !== pages && (
        <>
          <Styled.Button
            type="button"
            disabled={currentPage >= pages}
            onClick={() => handlePageChange({ value: currentPage + 1 })}
            aria-label={`next ${titleTable} page`}
            id={uniqueId()}
          >
            <span className="sr-only">Close</span>
            <ArrowRight />
          </Styled.Button>
          <Styled.Button
            type="button"
            className="ArrowRightPause"
            disabled={currentPage >= pages}
            onClick={() => handlePageChange({ value: pages })}
            aria-label={`last ${titleTable} page`}
            id={uniqueId()}
          >
            <span className="sr-only">Close</span>
            <ArrowRightPause />
          </Styled.Button>
        </>
      )}
    </Styled.Wrapper>
  );
}

export default memo(PaginationNavigation);
