import React from 'react';

const ArrowLeftPause = () => (
  <svg
    alt="arrow left pause"
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.30925 7.19864C3.004 6.90652 3.004 6.43292 3.30925 6.14081L8.28372 1.38057C8.58897 1.08846 9.08389 1.08846 9.38915 1.38057C9.69441 1.67268 9.69441 2.14629 9.38915 2.4384L4.96741 6.66972L9.38914 10.901C9.6944 11.1932 9.6944 11.6668 9.38914 11.9589C9.08389 12.251 8.58897 12.251 8.28371 11.9589L3.30925 7.19864Z"
      fill="#0D0D0D"
    />
    <rect y="1.73083" width="1.47009" height="10.5509" rx="0.735046" fill="#0D0D0D" />
  </svg>
);

export default ArrowLeftPause;
