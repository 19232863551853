/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useWindowDimensions } from 'hooks';
import { isEmpty } from 'lodash';
import { Wrapper, Label, ErrorWrapper, ErrorMessage } from '../BaseSelect/styles';
import {
  CustomRemoveOption,
  CustomRemoveAllOptions,
  CustomInput,
  CustomDropdownIndicator,
  Option,
} from '../BaseSelect/_components';
import * as Styled from './styles';

const TIP_MESSAGES = {
  addCity: 'Enter at least three letters of the city name to select it',
  editCity: 'Enter at least three letters of the new city name to select it',
};

// TODO: Rewrite using ref
const SelectContainer = ({ inputId, isFocus, ...props }) => {
  const { isFocused } = props;

  useEffect(() => {
    if (!isFocused || !inputId?.length) return;
    const el = document.querySelector(`#${inputId}`);
    el.scrollIntoView({ block: 'center' });
    el.focus();
  }, [inputId, isFocused]);

  return <components.SelectContainer autoFocus={isFocus} {...props} />;
};

const LoadedSelect = (props) => {
  const [isFocus, setIsFocus] = useState(false);
  const { width: viewportWidth } = useWindowDimensions();
  const {
    isDisabled,
    objStyles,
    menuPlacement,
    inputId,
    autoFocus,
    smallDrop,
    labelText,
    required,
    pageColumns,
    error,
    onChange,
    hasBorder,
    optionsArray,
    loadSelectTextType,
  } = props;

  const selectMenuPlacement = useMemo(
    () => (viewportWidth > 550 ? menuPlacement : 'bottom'),
    [menuPlacement, viewportWidth],
  );

  const ref = useRef();

  useEffect(() => {
    if (!autoFocus) return;
    setIsFocus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    ...objStyles,
  };

  const propsSet = {
    defaultMenuIsOpen: false,
    tabSelectsValue: false,
    isDisabled,
    menuShouldScrollIntoView: true,
    classNamePrefix: 'LoadedSelect',
    noOptionsMessage: () => {
      let tipMessage = 'No options';
      const {
        value: { value: inputValue },
      } = props;
      if (Array.isArray(optionsArray) && !isEmpty(optionsArray)) {
        tipMessage = TIP_MESSAGES[loadSelectTextType];
      }
      if (Array.isArray(optionsArray) && isEmpty(optionsArray)) {
        tipMessage = 'No options';
      }
      if (inputValue === '' && Array.isArray(optionsArray) && isEmpty(optionsArray)) {
        tipMessage = TIP_MESSAGES[loadSelectTextType] || 'No options';
      }
      return tipMessage;
    },
    components: {
      MultiValueRemove: CustomRemoveOption,
      ClearIndicator: (props) => <CustomRemoveAllOptions setIsFocus={setIsFocus} {...props} />,
      DropdownIndicator: CustomDropdownIndicator,
      Option,
      Input: (props) => <CustomInput isFocus={isFocus} {...props} />,
      SelectContainer: (props) => <SelectContainer inputId={inputId} {...props} />,
    },
    menuPlacement: selectMenuPlacement,
    onBlur: () => setIsFocus(false),
  };

  const wrapperPropsSet = {
    ref,
    smallDrop: smallDrop && smallDrop,
  };

  return (
    <Wrapper {...wrapperPropsSet} pageColumns={pageColumns}>
      {labelText && (
        <Label>
          {labelText} {required && ' *'}
        </Label>
      )}
      <ErrorWrapper>
        <AsyncSelect {...props} {...propsSet} styles={customStyles} onChange={onChange} />
        <ErrorMessage aria-label="error">{error && error}</ErrorMessage>
      </ErrorWrapper>
      {hasBorder && <Styled.HorizontalBorder />}
    </Wrapper>
  );
};

LoadedSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  pageColumns: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hasBorder: PropTypes.bool,
  optionsArray: PropTypes.array,
  loadSelectTextType: PropTypes.string,
};

LoadedSelect.defaultProps = {
  isFocused: false,
  menuPlacement: 'bottom',
  autoFocus: false,
  required: false,
  labelText: '',
  pageColumns: '',
  error: '',
  hasBorder: false,
  optionsArray: [],
  loadSelectTextType: '',
};

export default memo(LoadedSelect);
