import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import * as Styled from './styles';

const ErrorSection = ({ errors }) => {
  if (isEmpty(errors)) return null;
  return (
    <Styled.Section>
      <Styled.Title>Fix the following error(s) before submitting:</Styled.Title>
      <ul>
        {Object.entries(errors).map(([errorName, errorText]) => (
          <Styled.ListItem key={errorName}>{`* ${errorName}:   ${errorText}`}</Styled.ListItem>
        ))}
      </ul>
    </Styled.Section>
  );
};

ErrorSection.propTypes = {
  errors: PropTypes.object.isRequired,
};

export default memo(ErrorSection);
