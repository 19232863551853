import { createInitialValues } from 'utils/custom';

export const formNames = {
  password: 'password',
  confirmPassword: 'confirmPassword',
};

export const FORM_ACTION_TYPES = {
  RESET_TYPE: 'RESET_TYPE',
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
  IS_EQUAL: 'IS_EQUAL',
  RESET_INVALID: 'RESET_INVALID',
};

export const PASSWORD_ERROR_VALIDATION = {
  TOO_SHORT: 'password_too_short',
  HAS_NO_UPPERCASE_CHARACTER: 'password_has_no_uppercase_character',
  HAS_NO_LOWERCASE_CHARACTER: 'password_has_no_lowercase_character',
  HAS_NO_DIGIT: 'password_has_no_digit',
  HAS_NO_SPECIAL_CHAR: 'password_has_no_special_char',
  IS_DICTIONARY_WORD: 'password_is_dictionary_word',
  TOO_SIMILAR_TO_USER_ATTRIBUTES: 'password_too_similar_to_user_attributes',
  TOO_SIMILAR: 'password_too_similar',
  HAS_SEQUENCE_OF_SYMBOLS: 'password_has_sequence_of_symbols',
  SAME_AS_10_PREVIOUS: 'password_same_as_10_previous',
  TOO_COMMON: 'password_too_common',
};

export const REQUIREMENTS = [
  {
    title: 'Password must:',
    fields: [
      {
        id: 1,
        title: 'Be at least 8 characters long',
        isValid: false,
        errorMessage: PASSWORD_ERROR_VALIDATION.TOO_SHORT,
      },
      {
        id: 2,
        title: 'Contain characters from 3 of the 4 following categories:',
        isValid: false,
        errorMessage: [
          PASSWORD_ERROR_VALIDATION.HAS_NO_UPPERCASE_CHARACTER,
          PASSWORD_ERROR_VALIDATION.HAS_NO_LOWERCASE_CHARACTER,
          PASSWORD_ERROR_VALIDATION.HAS_NO_DIGIT,
          PASSWORD_ERROR_VALIDATION.HAS_NO_SPECIAL_CHAR,
        ],
        description: [
          'Uppercase letters (A, B, C, etc.)',
          'Lowercase letters (a, b, c, etc.)',
          'Standard numbers (0123456789)',
          'Special characters (!, $, #, etc)',
        ],
      },
    ],
  },
  {
    title: 'Password must not:',
    fields: [
      {
        id: 3,
        title: 'Be a dictionary word or proper name.',
        isValid: false,
        errorMessage: PASSWORD_ERROR_VALIDATION.IS_DICTIONARY_WORD,
      },
      {
        id: 4,
        title: 'Be the same as the email address or contain the user’s first name or last name.',
        isValid: false,
        errorMessage: [PASSWORD_ERROR_VALIDATION.TOO_SIMILAR_TO_USER_ATTRIBUTES, PASSWORD_ERROR_VALIDATION.TOO_SIMILAR],
      },
      {
        id: 5,
        title: 'Include 3 or more sequential or repetitive characters (e.g. abc, 123, aaa, 111, TaaaB, T123B)',
        isValid: false,
        errorMessage: PASSWORD_ERROR_VALIDATION.HAS_SEQUENCE_OF_SYMBOLS,
      },
      {
        id: 6,
        title: 'Be identical to any of the previous 10 passwords used.',
        isValid: false,
        errorMessage: PASSWORD_ERROR_VALIDATION.SAME_AS_10_PREVIOUS,
      },
      {
        id: 7,
        title: 'Be easily guessed (e.g. P@$$w0rd, Gue$$1fY0uC@n, 12345678, letmein!, 66666666.)',
        isValid: false,
        errorMessage: PASSWORD_ERROR_VALIDATION.TOO_COMMON,
      },
    ],
  },
];

export const FORM_REQUIRED_FIELDS = ['password', 'confirmPassword'];

export const formInitialState = {
  data: createInitialValues(formNames, ''),
  errors: createInitialValues(formNames, null),
  canBeSubmitted: false,
  passwordRules: REQUIREMENTS,
};
