import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-day-picker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FILTER_OPTIONS, FILTER_FIELDS } from '_constants/old/filters';
import { OldFilterEntity, UserEntity } from '_entities';
import { FormGroupWrapper } from 'modules/Modals/old/FilterModal/components/FormGroupWrapper';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import { BaseSelect } from 'ui-kit';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import * as Styled from './styles';

const { setShadowFilter } = OldFilterEntity.actions;
const { getSecGeogs } = UserEntity.selectors;

export const UsersQuickQuery = () => {
  const {
    filter: { shadowFilters },
  } = useSelector((state) => state);
  const [selectValue, setSelectValue] = useState({});
  const dispatch = useDispatch();
  const secGeogsList = useSelector(getSecGeogs);

  const geogOptions = useMemo(
    () =>
      secGeogsList.map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      }),
    [secGeogsList],
  );

  const USERS_QUICK_QUERY = [
    {
      id: 1,
      field: FILTER_FIELDS.MY_GEOGS,
      title: 'Users in my geography',
      options: FILTER_OPTIONS.MY_GEOGS_OPTIONS,
      type: 'checkbox',
    },
    {
      id: 2,
      field: FILTER_FIELDS.SEC_GEOGS,
      title: 'User location',
      options: geogOptions,
      type: 'multiselect',
    },
  ];

  const handleChangeSelect = (value, field) => {
    setSelectValue({ ...selectValue, [field]: value });
    dispatch(setShadowFilter({ ...shadowFilters, [field]: value.map((elem) => elem.value) }));
  };

  const getDropDefaultSelectedValues = (array) => {
    if (!secGeogsList) return null;
    return secGeogsList.filter((item) => array?.includes(item.value));
  };

  useEffect(() => {
    const geogsArray = getDropDefaultSelectedValues(shadowFilters?.sec_geogs);
    const formattedArray = geogsArray?.map((item) => {
      return {
        label: item.label,
        value: item.value,
      };
    });
    setSelectValue({ sec_geogs: formattedArray });
  }, []);

  useEffect(() => {
    const secGeogs = getDropDefaultSelectedValues(shadowFilters?.sec_geogs);

    setSelectValue({
      sec_geogs: secGeogs,
    });
  }, [shadowFilters]);

  const getElement = (title, field, options, type) => {
    switch (type) {
      case 'checkbox':
        return <CheckedList name={field} options={options} />;
      case 'multiselect':
        return (
          <Styled.SelectWrapper>
            <BaseSelect
              onChange={(value) => {
                handleChangeSelect(value, field);
              }}
              isMulti
              labelText={title}
              name={field}
              options={options}
              // eslint-disable-next-line no-prototype-builtins
              value={selectValue.hasOwnProperty(field) && selectValue[field]}
              menuPlacement="top"
            />
          </Styled.SelectWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <Styled.Wrapper>
      <FormGroupWrapper>
        <FormGroup title="Show">
          {USERS_QUICK_QUERY.map((item) => getElement(item.title, item.field, item.options, item.type))}
        </FormGroup>
      </FormGroupWrapper>
    </Styled.Wrapper>
  );
};
