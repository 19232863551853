import { FilterEntity } from '_entities';
import removeEmptinessFromFilters from './removeEmptinessFromFilters';

const { applyFilters } = FilterEntity.actions;

export default ({ search = '', generalFilters, getApi, dispatch } = {}) => {
  const filters = { ...generalFilters, search };

  const newFilters = removeEmptinessFromFilters(filters);

  getApi({ page: 1, filters: newFilters });
  dispatch(applyFilters(newFilters));
};
