import * as TYPES from './Report.types';

const initialState = {
  type: [],
  area: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_CALC_POLY_SUCCESS: {
      const data = action?.result;
      const newTypes = data.reduce((acc, item) => {
        if (!item.polygons.length) return acc;
        const newType = {
          label: item.plural,
          value: item.plural,
        };
        acc.push(newType);
        return acc;
      }, []);

      const newArea = data.reduce((acc, item) => {
        if (!item.polygons.length) return acc;
        const newPolygons = item.polygons.map(({ name, id }) => ({ label: name, value: id }));
        acc[item.plural] = newPolygons;
        return acc;
      }, {});

      return {
        ...state,
        type: newTypes,
        area: newArea,
      };
    }

    case TYPES.RESET_CALC_POLY: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default reducer;
