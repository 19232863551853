import styled from 'styled-components';
import { INVALID_ICON, CHECKED_ICON } from 'assets/icons';

export const ListItem = styled.li`
  margin-bottom: 15px;
  color: var(--dark);
  background-image: ${({ isValid }) => `url(${isValid ? CHECKED_ICON : INVALID_ICON})`};
  background-size: 16px;
  background-position: 0 3px;
  background-repeat: no-repeat;
  padding-left: 30px;

  > p {
    font-size: 14px;
    line-height: 1.5;
  }
`;

export const ListItemIcon = styled.img`
  display: inline-block;
  width: 16px;
  margin-right: 13px;
`;

export const InnerList = styled.ul`
  flex: 1 0 100%;
  margin-left: 25px;
`;

export const InnerListItem = styled.li`
  margin: 5px 0;
  list-style-type: disc;
`;
