import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const getAddress = (address, address2) => {
  if (address2) {
    return `${address}, ${address2}`;
  }
  return address;
};

export const parseAssignmentsCampaignData = ({ campaignRetailers }) => {
  const assignments = campaignRetailers.results.map((item) => {
    const assignmentsItem = {
      retailer__name: capitalizeFirstLetter(item.name),
      address: getAddress(item.address, item.address2),
      city: item.city,
      zipcode: item.zipcode,
      id: item.assignment_id,
      retailerID: item.id,
      revisitNeeded: item.is_follow_up,
      status: item.status || 0,
      usersList: item.users,
      assignmentId: item.assignment_id,
      assigned: item.assigned,
      visitId: item.visit_id,
      visitType: item.visit_type,
      visit_violation: item.visit_violation,
      closedDate: item.closed_date,
      residential: item.address_residential,
    };
    return assignmentsItem;
  });

  return assignments;
};
