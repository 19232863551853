export const parseViolationStatus = (violationData) => {
  const violationAttributes = { ...violationData.data.attributes };
  const penaltyCaptions = violationAttributes.penalty_captions;
  const payStatuses = violationAttributes.fine_pay_statuses;
  Object.values(penaltyCaptions).reduce((acc, item) => {
    Object.keys(item).forEach((penaltyItem) => {
      if (item[penaltyItem].type === 'dropdown') {
        if (violationAttributes[penaltyItem]) {
          const dropdownItem = {
            label: payStatuses[violationAttributes[penaltyItem]],
            value: violationAttributes[penaltyItem],
          };
          violationAttributes[penaltyItem] = dropdownItem;
          return violationAttributes;
        }
      }
      return acc;
    });
    return acc;
  }, {});

  return violationAttributes;
};
