import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Section = ({ title, children, isHorizontalLayout, pageColumns, isSubmission }) => (
  <Styled.Section>
    {isHorizontalLayout && (
      <div
        style={{
          borderRight: '1px solid #E0E0E0',
          position: 'absolute',
          left: pageColumns === 3 ? '47%' : '500px',
          height: '100%',
          zIndex: 1,
        }}
      />
    )}
    <Styled.Title>{title}</Styled.Title>
    <Styled.Content isSubmission={isSubmission}>{children}</Styled.Content>
  </Styled.Section>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  isHorizontalLayout: PropTypes.bool,
  isSubmission: PropTypes.bool,
  pageColumns: PropTypes.string,
};

Section.defaultProps = {
  isHorizontalLayout: false,
  pageColumns: '',
  isSubmission: false,
};

export default memo(Section);
