import { isNull } from 'lodash';
import moment from 'moment';
import { formIsValid } from 'utils/validation';
import { validateText } from 'utils/validation/fields';
import {
  FORM_ACTION_TYPES,
  FORM_REQUIRED_FIELDS_BASE,
  FORM_REQUIRED_FIELDS,
  formInitialState,
  formNames,
} from './constants';

export const init = (initialState) => {
  return { ...initialState };
};
const { dateStart, dateEnd } = formNames;
const DATE_FIELDS = [dateStart, dateEnd];

const getErrors = (data, errors, name) => {
  const start = data[dateStart];
  const end = data[dateEnd];

  if (!DATE_FIELDS.includes(name))
    return {
      ...errors,
      [name]: false,
    };

  if (!isNull(start) && isNull(end)) {
    return {
      ...errors,
      [dateEnd]: 'End date is required',
    };
  }
  if (isNull(start) && !isNull(end)) {
    return {
      ...errors,
      [dateStart]: 'Start date is required',
    };
  }
  if (moment(start).isAfter(moment(end))) {
    return {
      [dateEnd]: false,
      [dateStart]: 'Start date cannot be greater than end date',
    };
  }
  return {
    ...errors,
    [name]: false,
  };
};

export const validateData = (action) => {
  const value = action.payload?.value ? action.payload?.value : action.payload;

  switch (action.name) {
    case dateStart: {
      return isNull(action.payload) ? 'Start date is required' : false;
    }

    case dateEnd: {
      return isNull(action.payload) ? 'End date is required' : false;
    }

    default:
      return validateText({
        value,
        name: 'field',
        required: FORM_REQUIRED_FIELDS.includes(action.name),
        min: 4,
      });
  }
};

const checkFormIsValid = (errors) => formIsValid(errors, FORM_REQUIRED_FIELDS);

const validateFormData = (data, requiredFields) =>
  requiredFields.reduce((acc, name) => {
    acc[name] = validateData({
      payload: data[name],
      name,
    });
    return acc;
  }, {});

export const formReducer = (formState, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const { data } = formState;
      const requiredFields = action.shortForm ? FORM_REQUIRED_FIELDS_BASE : FORM_REQUIRED_FIELDS;

      const newErrors = validateFormData(data, requiredFields);

      return {
        ...formState,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newErrors),
      };
    }

    case FORM_ACTION_TYPES.ENTER_DATA: {
      const requiredFields = action.shortForm ? FORM_REQUIRED_FIELDS_BASE : FORM_REQUIRED_FIELDS;

      const newData = {
        ...formState.data,
        [action.name]: action.payload,
      };

      const newErrors = getErrors(newData, formState.errors, action.name);

      const shadowErrors = validateFormData(newData, requiredFields);

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(shadowErrors),
      };
    }

    case FORM_ACTION_TYPES.SET_ERROR: {
      const newErrors = {
        ...formState.errors,
        [action.name]: action.payload,
      };

      return {
        ...formState,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newErrors),
      };
    }

    default:
      return {
        ...formInitialState,
      };
  }
};
