import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Loader, Button, BaseSelect, Picker } from 'ui-kit';
import { ReportEntity } from '_entities';
import { BUTTON_TYPES, MESSAGES, URLS } from '_constants';
import BackPageButton from 'modules/BackPageButton';
import { notification } from 'utils/services';
import { formNames, formInitialState, FORM_ACTION_TYPES } from './constants';
import { formReducer, init } from './utils';
import * as Styled from './styles';

const { getCalcPoly } = ReportEntity.actions;
const { getReportsType, getReportsAreas } = ReportEntity.selectors;

function ReportForm({ title, apiCall, noComparison }) {
  const [state, formDispatch] = useReducer(formReducer, formInitialState, init);
  const [isLoading, setIsLoading] = useState(true);
  const reportsType = useSelector(getReportsType);
  const reportsArea = useSelector(getReportsAreas);
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: formStateData, errors: formStateErrors, canBeSubmitted } = state;

  const { interestType, interestArea, comparisonType, comparisonArea, dateStart, dateEnd } = formNames;
  const getAreaOptions = useCallback((type) => reportsArea[type?.value], [reportsArea]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getCalcPoly());
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleSelectChange = useCallback(
    (obj, name) => {
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: obj, shortForm: noComparison });
      if (name === interestType) {
        formDispatch({
          type: FORM_ACTION_TYPES.ENTER_DATA,
          name: interestArea,
          payload: null,
          shortForm: noComparison,
        });
      }
      if (name === comparisonType) {
        formDispatch({
          type: FORM_ACTION_TYPES.ENTER_DATA,
          name: comparisonArea,
          payload: null,
          shortForm: noComparison,
        });
      }
    },
    [formDispatch, noComparison],
  );

  const handlePickerChange = useCallback(
    (date, name) => {
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: date, shortForm: noComparison });
    },
    [formDispatch, noComparison],
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    (async () => {
      await formDispatch({ type: FORM_ACTION_TYPES.VALIDATE_DATA, shortForm: noComparison });

      if (!canBeSubmitted) {
        setIsLoading(false);
        return;
      }

      const {
        interest_area: newInterestArea,
        comparison_area: newComparisonArea,
        date_start: newDateStart,
        date_end: newDateEnd,
      } = formStateData;
      const formatDate = (date) => moment(date).format('YYYY-MM-DD');

      const getParams = (isShort) => {
        if (isShort) {
          return `calcpoly1=${newInterestArea.value}&end_date=${formatDate(newDateEnd)}&start_date=${formatDate(
            newDateStart,
          )}`;
        }
        return `calcpoly1=${newInterestArea.value}&calcpoly2=${newComparisonArea.value}&end_date=${formatDate(
          newDateEnd,
        )}&start_date=${formatDate(newDateStart)}`;
      };

      try {
        await dispatch(apiCall(getParams(noComparison)));
        notification.success(MESSAGES.REPORT.SUCCESS);
        history.push(URLS.reports);
      } catch (err) {
        console.log(err);
        notification.error(MESSAGES.COMMON_ERROR);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  if (isLoading) return <Loader />;

  return (
    <Styled.MainWrapper>
      <BackPageButton />
      <Styled.Title>{title}</Styled.Title>
      <Styled.SubTitle>* - required fields</Styled.SubTitle>
      <Styled.Form>
        <Styled.FormRow>
          <BaseSelect
            name={interestType}
            labelText="Select an area of interest type"
            options={reportsType}
            value={formStateData[interestType]}
            error={formStateErrors[interestType]}
            onChange={(options) => handleSelectChange(options, interestType)}
            placeholder="Select an option"
            inputId={interestType}
            required
          />
          <BaseSelect
            name={interestArea}
            labelText="Select an area of interest"
            options={getAreaOptions(formStateData[interestType])}
            value={formStateData[interestArea]}
            error={formStateErrors[interestArea]}
            onChange={(options) => handleSelectChange(options, interestArea)}
            placeholder="Select an option"
            inputId={interestArea}
            required
          />
        </Styled.FormRow>
        {!noComparison && (
          <Styled.FormRow>
            <BaseSelect
              name={comparisonType}
              labelText="Select a comparison type"
              options={reportsType}
              value={formStateData[comparisonType]}
              error={formStateErrors[comparisonType]}
              onChange={(options) => handleSelectChange(options, comparisonType)}
              placeholder="Select an option"
              inputId={comparisonType}
              required
            />
            <BaseSelect
              name={comparisonArea}
              labelText="Select a comparison area"
              options={getAreaOptions(formStateData[comparisonType])}
              value={formStateData[comparisonArea]}
              error={formStateErrors[comparisonArea]}
              onChange={(options) => handleSelectChange(options, comparisonArea)}
              placeholder="Select an option"
              inputId={comparisonArea}
              required
            />
          </Styled.FormRow>
        )}
        <Styled.FormRow>
          <Picker
            label="Start date (mm/dd/yyyy)*"
            showMonthYearDropdown
            idInput={dateStart}
            onChange={(date) => handlePickerChange(date, dateStart)}
            selected={formStateData[dateStart] || null}
            error={formStateErrors[dateStart]}
            inputFor={dateStart}
            isFullWidth
            name={dateStart}
            required
          />
          <Picker
            label="End date (mm/dd/yyyy)*"
            showMonthYearDropdown
            idInput={dateEnd}
            onChange={(date) => handlePickerChange(date, dateEnd)}
            selected={formStateData[dateEnd] || null}
            error={formStateErrors[dateEnd]}
            inputFor={dateEnd}
            isFullWidth
            name={dateEnd}
            required
          />
        </Styled.FormRow>
        <Button variant={BUTTON_TYPES.DANGER} text="Generate report" type="submit" onClick={handleSubmit} />
      </Styled.Form>
    </Styled.MainWrapper>
  );
}

ReportForm.propTypes = {
  title: PropTypes.string.isRequired,
  apiCall: PropTypes.func.isRequired,
  noComparison: PropTypes.bool,
};

ReportForm.defaultProps = {
  noComparison: false,
};

export default ReportForm;
