const COLUMNS = [
  {
    label: 'Assigned',
    value: 'assigned',
  },
  {
    label: 'Retailer name ',
    value: 'retailer__name',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'City',
    value: 'city',
  },
  {
    label: 'Zip code',
    value: 'zipcode',
  },
  {
    label: 'Data collector',
    value: 'usersList',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

const INITIAL_COLUMNS = [
  'assigned',
  'retailer__name',
  'address',
  'city',
  'zipcode',
  'usersList',
  'hasViolation',
  'visits',
  'status',
];

export { COLUMNS, INITIAL_COLUMNS };
