import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from '_constants';
import * as Styled from './styles';

const Footer = () => {
  const disableScrollRestoration = () => {
    document.body.scrollTo({
      top: 0,
    });
  };

  return (
    <Styled.FooterContainer data-html2canvas-ignore>
      <Styled.FooterContent>
        © 2016-{new Date().getFullYear()} University of North Carolina and Counter Tools. All rights reserved.{' '}
        <Link onClick={disableScrollRestoration} to={URLS.policy}>
          {' '}
          Terms of Use & Privacy Policy
        </Link>
      </Styled.FooterContent>
    </Styled.FooterContainer>
  );
};

export default Footer;
