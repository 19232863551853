/* eslint-disable camelcase */
import { formatDateTimeZone } from 'utils/custom';

const dataFields = [
  { name: 'Latlong', key: 'latlong' },
  { name: 'City', key: 'city' },
  { name: 'Address', key: 'address' },
  { name: 'Name', key: 'name' },
  { name: 'Zip Code', key: 'zipcode' },
  { name: 'County', key: 'county_name' },
  { name: 'State', key: 'state' },
  { name: 'Phone Number', key: 'phone' },
  { name: 'Alternate Retailer Info', key: 'alternate_info' },
  { name: 'Modified by agency', key: 'modified_by_agency' },
  { name: 'Retailer Type', key: 'cdph_business_type' },
  { name: 'In Business', key: 'is_current' },
  { name: 'Closed Date', key: 'closed_date' },
  { name: 'Age Restricted Facility Flag', key: 'age_restricted_flag' },
  { name: 'Corporate Flag', key: 'corp_flag' },
  { name: 'Entity Type', key: 'entity_type' },
  { name: 'Sells Tobacco', key: 'tobacco' },
  { name: 'Has Pharmacy Counter', key: 'pharmacy' },
  { name: 'Internet Sales', key: 'internet_sales' },
  { name: 'Address validated', key: 'address_validated' },
  { name: 'URL', key: 'url' },
  { name: 'Address manualy updated', key: 'addr_updated' },
  { name: 'SmartyStreet Address validation status', key: 'address_validated' },
  { name: 'Coordinates manually updated', key: 'loc_updated' },
  { name: 'Coordinates set', key: 'latlong' },
  { name: 'Google Location Precision', key: 'g_precision' },
  { name: 'SmartyStreet Address Precision', key: 'ss_precision' },
  { name: 'Additional information #1', key: 'additional_field_1' },
  { name: 'Additional information #2', key: 'additional_field_2' },
  { name: 'Additional information #3', key: 'additional_field_3' },
  { name: 'Additional information #4', key: 'additional_field_4' },
  { name: 'Additional information #5', key: 'additional_field_5' },
  { name: 'Additional information #6', key: 'additional_field_6' },
  { name: 'Additional information #7', key: 'additional_field_7' },
  { name: 'Additional information #8', key: 'additional_field_8' },
  { name: 'Additional information #9', key: 'additional_field_9' },
  { name: 'Additional information #10', key: 'additional_field_10' },
  { name: 'Penalty', key: 'enforcement_penalty' },
  { name: 'Inspection type', key: 'enforcement_inspection_type' },
  { name: 'Product Types', key: 'enforcement_product_types' },
  { name: 'Disposition', key: 'disposition' },
  { name: 'Inspection date', key: 'inspection_date' },
  { name: 'Addition fields', key: 'metadata' },
  { name: 'User', key: 'user' },
  { name: 'Contact information', key: 'contact_info' },
  { name: 'Agency type', key: 'agency_type' },
  { name: 'Agency abbreviation', key: 'abbreviation' },
  { name: 'Jurisdictions', key: 'sec_geogs' },
  { name: 'Phone', key: 'sms_number' },
  { name: 'User ID', key: 'email' },
  { name: 'User role', key: 'role' },
  { name: 'Default agency', key: 'agency_default' },
  { name: 'Agencies', key: 'agencies' },
  { name: 'Last name', key: 'last_name' },
  { name: 'Submitted date', key: 'submitted_date' },
  { name: 'Submitted time', key: 'submitted_time' },
  { name: 'Revocation date', key: 'revocation_date' },
  { name: 'Suspension end date', key: 'susp_end' },
  { name: 'Suspension start date', key: 'susp_start' },
  { name: 'License end date', key: 'end_date' },
  { name: 'License start date', key: 'start_date' },
  { name: 'Agency Account Number', key: 'license_holder_id' },
  { name: 'License Number', key: 'license_number' },
  { name: 'License Holder Name', key: 'license_holder_name' },
  { name: 'Agency', key: 'agency' },
  { name: 'First name', key: 'first_name' },
];

const getTitle = (item) => {
  const fieldItem = dataFields.find((field) => field.key?.trim() === item?.trim())?.name;
  return fieldItem || item?.trim();
};

export default (data, timeZone) => {
  const auditLogs = data?.map((item) => {
    const { attributes } = item;
    const {
      object_type: objectType,
      record_id: recordId,
      field_name: fieldName,
      action_flag: actionFlag,
      previous_value_display: previousValue,
      new_value_display: newValue,
      action_datetime: actionDatetime,
      restored,
      retailer_deleted: retailerDeleted,
      import_action: importAction,
    } = attributes;

    const auditLogItem = {
      id: item.id,
      user__id: attributes?.user?.id,
      user__email: attributes?.user?.email,
      user__agency_default__name: attributes?.agency?.abbreviation,
      full_name: attributes?.user?.full_name,
      object_type: objectType,
      record_id: recordId,
      field_name: getTitle(fieldName),
      action_flag: actionFlag,
      previous_value_display: previousValue,
      new_value_display: newValue,
      action_datetime: formatDateTimeZone(actionDatetime, timeZone),
      import_action: importAction ? 'Import' : 'Manual',
      restored,
      retailer_deleted: retailerDeleted,
    };
    return auditLogItem;
  });

  return auditLogs;
};
