export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST';
export const GET_CAMPAIGN_LIST_SUCCESS = 'GET_CAMPAIGN_LIST_SUCCESS';
export const GET_CAMPAIGN_LIST_FAIL = 'GET_CAMPAIGN_LIST_FAIL';

export const GET_CAMPAIGN_LIST_CSV = 'GET_CAMPAIGN_LIST_CSV';
export const GET_CAMPAIGN_LIST_CSV_SUCCESS = 'GET_CAMPAIGN_LIST_CSV_SUCCESS';
export const GET_CAMPAIGN_LIST_CSV_FAIL = 'GET_CAMPAIGN_LIST_CSV_FAIL';

export const GET_ASSIGNMENT_LIST = 'GET_ASSIGNMENT_LIST';
export const GET_ASSIGNMENT_LIST_SUCCESS = 'GET_ASSIGNMENT_LIST_SUCCESS';
export const GET_ASSIGNMENT_LIST_FAIL = 'GET_ASSIGNMENT_LIST_FAIL';

export const GET_REVISITS_LIST = 'GET_REVISITS_LIST';
export const GET_REVISITS_LIST_SUCCESS = 'GET_REVISITS_LIST_SUCCESS';
export const GET_REVISITS_LIST_FAIL = 'GET_REVISITS_LIST_FAIL';

export const GET_REVISITS_CSV = 'GET_REVISITS_CSV';
export const GET_REVISITS_CSV_SUCCESS = 'GET_REVISITS_CSV_SUCCESS';
export const GET_REVISITS_CSV_FAIL = 'GET_REVISITS_CSV_FAIL';

export const GET_ASSIGNMENTS_CSV = 'GET_ASSIGNMENTS_CSV';
export const GET_ASSIGNMENTS_CSV_SUCCESS = 'GET_ASSIGNMENTS_CSV_SUCCESS';
export const GET_ASSIGNMENTS_CSV_FAIL = 'GET_ASSIGNMENTS_CSV_FAIL';

export const GET_ALL_ASSIGNMENTS_LIST = 'GET_ALL_ASSIGNMENTS_LIST';
export const GET_ALL_ASSIGNMENTS_LIST_SUCCESS = 'GET_ALL_ASSIGNMENTS_LIST_SUCCESS';
export const GET_ALL_ASSIGNMENTS_LIST_FAIL = 'GET_ALL_ASSIGNMENTS_LIST_FAIL';
export const SET_COLLECT_COUNT = 'SET_COLLECT_COUNT';

export const ARCHIVE_CAMPAIGNS = 'ARCHIVE_CAMPAIGNS';
export const ARCHIVE_CAMPAIGNS_SUCCESS = 'ARCHIVE_CAMPAIGNS_SUCCESS';
export const ARCHIVE_CAMPAIGNS_FAIL = 'ARCHIVE_CAMPAIGNS_FAIL';

export const RESTORE_CAMPAIGNS = 'RESTORE_CAMPAIGNS';
export const RESTORE_CAMPAIGNS_SUCCESS = 'RESTORE_CAMPAIGNS_SUCCESS';
export const RESTORE_CAMPAIGNS_FAIL = 'RESTORE_CAMPAIGNS_FAIL';

export const GET_ASSIGNMENT_SEARCH = 'GET_ASSIGNMENT_SEARCH';
export const GET_ASSIGNMENT_SEARCH_SUCCESS = 'GET_ASSIGNMENT_SEARCH_SUCCESS';
export const GET_ASSIGNMENT_SEARCH_FAIL = 'GET_ASSIGNMENT_SEARCH_FAIL';
