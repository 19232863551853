export const userAction = {
  retailersTableData: 'RETAILERS_TABLE_DATA',
  retailerModalView: 'SET_MODAL_STATUS',
  retailerAddEnforcementDate: 'SET_ENFORCEMENT_INSPETION_DATE',
  retailerAddEnforcementType: 'SET_ENFORCEMENT_INSPETION_TYPE',
  retailerFilterNameAddress: 'RETAILERS_FILTER_NAME_ADDRESS',
  saveEnforcementData: 'SAVE_ENFORCEMENT_DATA',
  setCounterInspection: 'SET_COUNTER_INSPECTION',
  clearAllFilters: 'CLEAR_ALL_FILTERS',
  retailerAdvancedFilter: 'RETAILERS_ADVANCED_FILTER',
  retailerAdvancedFilterDatabaseCreation: 'RETAILERS_ADVANCED_FILTER_DATABASE_CREATION',
  retailersQuickQuery: 'RETAILERS_QUICK_QUERY',
  retailersQueryBuilderLocation: 'RETAILERS_QUERY_BUILDER_LOCATION',
  retailerDeleted: 'RETAILER_DELETE',
  setTypeSort: 'SET_TYPE_SORT',
  setRetailersFilter: 'SET_RETAILERS_FILTER',
  setShadowRetailersFilter: 'SET_SHADOW_RETAILERS_FILTER',
  setIsNotification: 'SET_IS_NOTIFICATION',
  setTabView: 'SET_TAB_VIEW',
  retailerIsPrint: 'SET_IS_PRINT',
};

const retailersTableData = (payload) => ({
  type: userAction.retailersTableData,
  payload,
});
const setIsPrint = (payload) => ({
  type: userAction.retailerIsPrint,
  payload,
});

const setIsNotification = (payload) => ({
  type: userAction.setIsNotification,
  payload,
});

const setRetailersFilter = (payload) => ({
  type: userAction.setRetailersFilter,
  payload,
});

const setShadowRetailersFilter = (payload) => ({
  type: userAction.setShadowRetailersFilter,
  payload,
});

const setTypeSort = (payload) => ({
  type: userAction.setTypeSort,
  payload,
});

const setTabViews = (payload) => ({
  type: userAction.setTypeSort,
  payload,
});

const retailerDelete = (payload) => ({
  type: userAction.retailerDeleted,
  payload,
});

const retailerModalView = (payload) => ({
  type: userAction.retailerModalView,
  payload,
});

const retailerEnforcementInspectionDate = (payload) => ({
  type: userAction.retailerAddEnforcementDate,
  payload,
});

const retailerEnforcementInspectionType = (payload) => ({
  type: userAction.retailerAddEnforcementType,
  payload,
});

const retailerFilterNameAddress = (payload) => ({
  type: userAction.retailerFilterNameAddress,
  payload,
});

const retailerAdvancedFilter = (item, value) => ({
  type: userAction.retailerAdvancedFilter,
  item,
  value,
});

const saveEnforcementData = (payload) => ({
  type: userAction.saveEnforcementData,
  payload,
});

const retailerAdvancedFilterDatabaseCreation = (valueStart, valueFinish, item) => ({
  type: userAction.retailerAdvancedFilterDatabaseCreation,
  valueStart,
  valueFinish,
  item,
});

export {
  retailersTableData,
  retailerModalView,
  retailerEnforcementInspectionDate,
  retailerEnforcementInspectionType,
  retailerFilterNameAddress,
  saveEnforcementData,
  retailerAdvancedFilter,
  retailerAdvancedFilterDatabaseCreation,
  retailerDelete,
  setTypeSort,
  setRetailersFilter,
  setShadowRetailersFilter,
  setIsNotification,
  setTabViews,
  setIsPrint,
};
