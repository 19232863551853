import React, { memo, useState, forwardRef } from 'react';
import MaskedTextInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const Picker = ({
  label,
  selected,
  required = false,
  onChange: handleDateChange,
  dateFormatCalendar,
  maxDate,
  minDate,
  showMonthYearDropdown,
  error,
  isFullWidth,
  isMargin = false,
  idInput = '',
  inputFor = '',
  isActive = false,
  pageColumns,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleKeyPress = (e, onClick) => {
    // eslint-disable-next-line no-unused-expressions
    e.keyCode === 13 && onClick();
  };

  const handleCalendarClose = () => {
    setIsFocus(true);
  };

  // eslint-disable-next-line react/prop-types
  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <Styled.CustomInputWrapper>
      <MaskedTextInput
        type="text"
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        value={value}
        onChange={onChange}
        onClick={onClick}
        tabIndex={0}
        onKeyDown={(e) => handleKeyPress(e, onClick)}
        disabled={isActive}
        readOnly={isActive}
        id={idInput}
        autoFocus={isFocus}
        aria-labelledby={inputFor}
        onBlur={() => setIsFocus(false)}
        ref={ref}
      />
    </Styled.CustomInputWrapper>
  ));

  return (
    <Styled.InputWrap isMargin={isMargin} isFullWidth={isFullWidth} pageColumns={pageColumns}>
      <Styled.CalendarLabel htmlFor={idInput} id={inputFor} aria-label={label}>
        {label}
      </Styled.CalendarLabel>
      <Styled.ReactPickerWrapper isError={required && error}>
        <DatePicker
          required={required}
          selected={selected}
          onChange={handleDateChange}
          dateFormatCalendar={dateFormatCalendar}
          maxDate={maxDate}
          minDate={minDate}
          onCalendarClose={handleCalendarClose}
          showMonthYearDropdown={showMonthYearDropdown}
          customInput={<ExampleCustomInput />}
          {...props}
        />
        {error && <Styled.Error>{error}</Styled.Error>}
      </Styled.ReactPickerWrapper>
    </Styled.InputWrap>
  );
};

Picker.propTypes = {
  idInput: PropTypes.string.isRequired,
  inputFor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  dateFormatCalendar: PropTypes.string,
  showMonthYearDropdown: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isFullWidth: PropTypes.bool,
  isMargin: PropTypes.bool,
  isActive: PropTypes.bool,
  pageColumns: PropTypes.string,
};

Picker.defaultProps = {
  minDate: new Date('01-01-1980'.replace(/-/g, '/')),
  maxDate: new Date(),
  label: '',
  required: false,
  dateFormatCalendar: 'MMMM yyyy',
  showMonthYearDropdown: false,
  error: false,
  isFullWidth: false,
  isMargin: false,
  isActive: false,
  selected: null,
  pageColumns: '',
};

export default memo(Picker);
