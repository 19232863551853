import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'ui-kit';
import { BUTTON_TYPES, URLS } from '_constants';
import * as Styled from '../styles';

function LoginModal({ isOpen, onClose }) {
  const history = useHistory();

  const historyHandler = useCallback(() => history.push(URLS.setNewPassword), [history]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Styled.ModalWrapper>
        <Styled.ModalTitle>Set new password</Styled.ModalTitle>
        <Styled.ModalDescription>Please create your password to access the system.</Styled.ModalDescription>
        <Styled.ModalButtonWrapper>
          <Styled.ModalButtonCancel onClick={onClose} text="Cancel" variant={BUTTON_TYPES.DARK} aria-label="Cancel" />
          <Styled.ModalButtonSet
            onClick={historyHandler}
            text="Set new password"
            variant={BUTTON_TYPES.DANGER}
            aria-label="Set new password"
          />
        </Styled.ModalButtonWrapper>
      </Styled.ModalWrapper>
    </Modal>
  );
}

LoginModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LoginModal;
