import { parseCollectData, parseAssignmentsData, parseRevisitData } from 'utils/custom';
import * as TYPES from './Collect.types';

const initialState = {
  campaigns: {},
  assignments: {},
  revisit: {},
  offlineInfo: null,
  collectCount: 0,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_CAMPAIGN_LIST_SUCCESS: {
      const { data, included, meta } = action?.result;
      const newData = parseCollectData({ data, included });
      return {
        ...state,
        campaigns: {
          data: newData,
          pagination: meta?.pagination,
        },
      };
    }
    case TYPES.GET_ASSIGNMENT_LIST_SUCCESS: {
      const { data, included, meta } = action?.result;
      const newData = parseAssignmentsData({ data, included });
      return {
        ...state,
        assignments: { data: newData, pagination: meta?.pagination },
      };
    }
    case TYPES.GET_REVISITS_LIST_SUCCESS: {
      const { data, meta } = action?.result;
      const newData = parseRevisitData({ data }, action?.timeZone);
      return {
        ...state,
        revisit: { data: newData, pagination: meta?.pagination },
      };
    }
    case TYPES.GET_ALL_ASSIGNMENTS_LIST_SUCCESS: {
      const { data, included } = action?.result;
      return {
        ...state,
        offlineInfo: {
          assignments: data,
          additionalData: included,
        },
      };
    }
    case TYPES.SET_COLLECT_COUNT: {
      return {
        ...state,
        collectCount: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
