import styled from 'styled-components';

export const HeaderContainer = styled.div`
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BodyContainer = styled.main`
  flex-grow: 1;
`;

export const FooterContainer = styled.div`
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
