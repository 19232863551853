/* eslint-disable react/prop-types */
import React from 'react';
import { uniqueId } from 'lodash';
import { STORE_MERGER_MODAL_TYPES as MODAL_TYPES } from '_constants';
import { CONFIRM_DUPLICATES_ICON, CONFIRM_SEPARATE_ICON, CONFIRM_HISTORIC_ICON } from 'assets/icons';
import { Img } from 'ui-kit';
import {
  infoTableRightSideTitle,
  infoTableRightSideSubTitle,
  infoHistorical,
  infoExample,
  infoSeparate,
  infoKroger,
} from './initData';
import * as Styled from './styles';

const BUTTON_ICONS = {
  'Confirm duplicates': CONFIRM_DUPLICATES_ICON,
  'Confirm historic': CONFIRM_HISTORIC_ICON,
  'Confirm separate': CONFIRM_SEPARATE_ICON,
};

export default function ConfirmSidebar({ handleOpenMergeModal, mergersItems, isActionCell }) {
  const buttonMapper = [
    {
      title: MODAL_TYPES.CONFIRM_DUPLICATES,
      type: MODAL_TYPES.CONFIRM_DUPLICATES,
      desc: infoTableRightSideTitle,
      subDesc: infoTableRightSideSubTitle,
    },
    {
      title: MODAL_TYPES.CONFIRM_HISTORIC,
      type: MODAL_TYPES.CONFIRM_HISTORIC,
      desc: infoHistorical,
      subDesc: infoExample,
    },
    {
      title: MODAL_TYPES.CONFIRM_SEPARATE,
      type: MODAL_TYPES.CONFIRM_SEPARATE,
      desc: infoSeparate,
      subDesc: infoKroger,
    },
  ];
  const buttonContainers = () =>
    buttonMapper.map((item) => (
      <Styled.ListItem key={item.title}>
        <Styled.Title>{item.desc}</Styled.Title>
        <Styled.SubTitle>{item.subDesc}</Styled.SubTitle>
        <Styled.ButtonSideBar
          onClick={(event) => handleOpenMergeModal(event, { type: item.type, mergersItems })}
          aria-label={item.title}
          id={uniqueId()}
        >
          {item.title}
        </Styled.ButtonSideBar>
      </Styled.ListItem>
    ));

  const smallButtonContainers = () =>
    buttonMapper.map((item, index) => {
      return (
        <Styled.TooltipWrapper key={item.title}>
          <Styled.ImageWrapper>
            <Img
              tabIndex={0}
              id={uniqueId()}
              onClick={(event) => handleOpenMergeModal(event, { type: item.type, mergersItems })}
              onKeyDown={(event) =>
                event.keyCode === 13 && handleOpenMergeModal(event, { type: item.type, mergersItems })
              }
              size={[20, 20]}
              src={BUTTON_ICONS[item.title]}
              aria-label={item.title}
              alt={item.title}
            />
          </Styled.ImageWrapper>
          <Styled.TooltipContainer buttonIndex={index}>
            <Styled.TooltipContent>
              <Styled.ToolTipSection>{item.desc}</Styled.ToolTipSection>
              <Styled.ToolTipSection isSubDesc>{item.subDesc}</Styled.ToolTipSection>
            </Styled.TooltipContent>
          </Styled.TooltipContainer>
        </Styled.TooltipWrapper>
      );
    });

  if (isActionCell) return <Styled.ButtonCell>{smallButtonContainers()}</Styled.ButtonCell>;

  return <Styled.List>{buttonContainers()}</Styled.List>;
}
