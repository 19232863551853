/* eslint-disable no-return-assign */
import { isEmpty } from 'lodash';

/* eslint-disable no-param-reassign */
export const getArraySelect = (arr, name, key) =>
  !isEmpty(arr)
    ? arr?.map(
        (item) =>
          (item = {
            label: item,
            value: item,
            name: name || item,
            key: key || item,
          }),
      )
    : arr;
