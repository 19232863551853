import { formNames } from 'pages/User/Add/constants';
import { parseUserProfileData } from 'utils/custom';
import * as TYPES from './User.types';
import { userLogin } from '../old/Users/user.action';

const initialState = {
  users: {
    data: [],
    pagination: {},
  },
  roles: [],
  agencies: [],
  secGeogs: [],
  accounts: [],
  subDomains: [],
  profile: {},
  lastUserImport: {},
  toastId: null,
};

const createOptions = (data, name) => {
  const newData = data.map((item) => ({
    label: item.attributes.name,
    value: item.id,
    name,
    roleType: item.attributes.role_type,
  }));

  return newData;
};

const createSubdomainArray = (data) => {
  return data.map((item) => item.attributes.subdomain);
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case userLogin.setUsers: {
      const { data, pagination } = action.payload;
      return {
        ...state,
        users: {
          data,
          pagination,
        },
      };
    }

    case TYPES.GET_USER_ROLE_SUCCESS: {
      const { data } = action?.result;
      const roles = createOptions(data, formNames.role);
      return {
        ...state,
        roles,
      };
    }

    case TYPES.GET_SEC_GEO_SUCCESS: {
      const { data } = action?.result;
      const secGeogs = createOptions(data, formNames.secGeogs);
      return {
        ...state,
        secGeogs,
      };
    }

    case TYPES.GET_USER_AGENCIES_SUCCESS: {
      const { data } = action?.result;
      const agencies = createOptions(data, formNames.agency);
      return {
        ...state,
        agencies,
      };
    }

    case TYPES.GET_USER_ACCOUNTS_SUCCESS: {
      const { data } = action?.result;
      const accounts = createOptions(data, formNames.account);
      const subDomains = createSubdomainArray(data);
      return {
        ...state,
        accounts,
        subDomains,
      };
    }

    case TYPES.GET_USER_INFO_SUCCESS: {
      const { data, included } = action?.result;
      const profile = parseUserProfileData({ data, included }, action?.timeZone);
      return {
        ...state,
        profile,
      };
    }

    case TYPES.RESET_USER_INFO: {
      return {
        ...state,
        profile: {},
      };
    }

    case TYPES.USERS_IMPORT_PROGRESS_SUCCESS: {
      const lastImportObject = action?.result?.data?.reduce((maxObj, currentObj) => {
        return parseInt(currentObj.id) > parseInt(maxObj.id) ? currentObj : maxObj;
      });

      return {
        ...state,
        lastUserImport: lastImportObject,
      };
    }

    case TYPES.RESET_USER_IMPORT_INFO: {
      return {
        ...state,
        lastUserImport: {},
      };
    }

    case TYPES.SET_USER_TOAST_ID: {
      return {
        ...state,
        toastId: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
