/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState, createContext } from 'react';
import ToastList from './index';

const ToastContext = createContext();

export default ToastContext;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        setToasts(() => []);
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [toasts]);

  const addToast = useCallback((toast) => setToasts(() => [toast]), [setToasts]);

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <ToastList toasts={toasts} />
    </ToastContext.Provider>
  );
}
