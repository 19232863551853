/* eslint-disable radix */
import processQSParams from 'utils/old/processQSParams';
import { parseUserListData } from 'utils/custom';
import { fetchConstructor } from '../api/api';
import apiData from '../api/config';
import { setUsersData } from './user.action';

const fetchUsers = async ({ page = 1, perPage, sortBy, sortOrder, ...qsParams }, dispatch) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };

    const params = processQSParams({
      page,
      perPage,
      sortOrder,
      sortBy,
      ...qsParams,
    });
    const response = await fetchConstructor(
      'get',
      `${apiData.users}?${params}&fields%5BUser%5D=first_name,last_name,email,account,role,agencies,is_active,sec_geogs&include=account,role`,
      headers,
    );
    const { data, included, meta } = response.data;
    const newData = parseUserListData({ data, included });

    dispatch(setUsersData({ data: newData, pagination: meta.pagination }));
  } catch (e) {
    console.log(e);
  }
};

const usersCSV = async (queryParams, getToast = () => {}) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const params = queryParams;
    if (queryParams) {
      await fetchConstructor('get', `${apiData.usersCSV}${params}&include=aggregationpolygon_set`, headers);
    }
    getToast();
  } catch (e) {
    console.log(e);
  }
};

export { fetchUsers, usersCSV };
