import * as actions from './Report.actions';
import reducer from './Report.reducer';
import * as types from './Report.types';
import * as selectors from './Report.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
