import { createInitialValues } from 'utils/custom';

export const formNames = {
  youthId: 'youth_id',
  birthday: 'birthdate',
  gender: 'gender',
  isActive: 'is_active',
  dateJoined: 'date_joined',
  geographies: 'geographies',
  agencies: 'agencies',
  modified: 'modified',
  isOlderThanYouthAge: 'is_older_than_youth_age',
};

export const errorLabels = {
  [formNames.youthId]: 'id',
  [formNames.birthday]: 'birthdate',
  [formNames.gender]: 'gender',
  [formNames.isActive]: 'status',
  [formNames.geographies]: 'geography',
  [formNames.agencies]: 'agency',
};

export const FORM_ACTION_TYPES = {
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
  RESET_DATA: 'RESET_DATA',
  SET_DATA: 'SET_DATA',
};

export const FORM_REQUIRED_FIELDS = [
  formNames.youthId,
  formNames.birthday,
  formNames.gender,
  formNames.isActive,
  formNames.geographies,
  formNames.agencies,
  formNames.isOlderThanYouthAge,
];

export const genderOptions = [
  {
    label: 'Female',
    value: 1,
  },
  {
    label: 'Male',
    value: 2,
  },
  {
    label: 'Other',
    value: 3,
  },
];

export const radioButtonDefaultValues = [
  {
    id: 1,
    value: true,
    label: 'Active',
  },
  {
    id: 2,
    value: false,
    label: 'Inactive',
  },
];

export const multiSelectsList = [formNames.geographies, formNames.agencies];

export const getInitialValue = (name) => {
  const findName = (arr, inputName) => arr.includes(inputName) && inputName;

  switch (name) {
    case findName(multiSelectsList, name): {
      return [];
    }
    case formNames.gender: {
      return {
        id: 0,
        label: 'Select an option',
        value: null,
        name,
      };
    }
    case formNames.isActive: {
      return true;
    }
    case formNames.isOlderThanYouthAge: {
      return false;
    }
    default:
      return '';
  }
};

const defaultUserState = FORM_REQUIRED_FIELDS.reduce(
  (newObj, value) => ({ ...newObj, [value]: getInitialValue(value) }),
  {},
);

export const formInitialState = {
  data: { attributes: defaultUserState, type: 'User' },
  errors: createInitialValues(formNames, null),
  canBeSubmitted: false,
};

export const textInputs = [
  {
    id: 1,
    name: formNames.youth_id,
    title: 'Youth ID',
    required: true,
  },
];

export const PAGE_COLUMNS = 'youth_columns';
