import React from 'react';

const ArrowRightPause = () => (
  <svg
    alt="arrow right pause"
    width="16"
    height="13"
    focusable="false"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3552 6.14072C12.6605 6.43283 12.6605 6.90644 12.3552 7.19855L7.38083 11.9588C7.07557 12.2509 6.58066 12.2509 6.2754 11.9588C5.97015 11.6667 5.97015 11.1931 6.27541 10.901L10.6971 6.66963L6.27541 2.43831C5.97016 2.1462 5.97016 1.67259 6.27541 1.38048C6.58067 1.08837 7.07558 1.08837 7.38084 1.38048L12.3552 6.14072Z"
      fill="#0D0D0D"
    />
    <rect x="14.5303" y="1.73083" width="1.47009" height="10.5509" rx="0.735046" fill="#0D0D0D" />
  </svg>
);

export default ArrowRightPause;
