import { isEmpty } from 'lodash';

export const sliceGraphs = (graphArray) => {
  if (isEmpty(graphArray)) return [];
  const result = [];

  for (let index = 0; index < graphArray.length; index += 2) {
    result.push(graphArray.slice(index, index + 2));
  }
  return result;
};
