import { useMemo } from 'react';
import { AuthEntity } from '_entities';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import isIncludes from './isIncludes';
import isContainArray from './isContainArray';

// Example of roleRuleSet
// const roleRuleSet = {
//   creator: ['Campaign Manager'],
//   all: ['Account Managers', 'Multi-Account Manager'],
// };

const {
  getProfilePermissions,
  getSecGeogList,
  getUserId,
  getAgenciesList,
  getCreatedCampaignByMe,
  getUserState,
  getUserRoleType,
  getModularityPermissions,
} = AuthEntity.selectors;

function HasAccess(permission, data, roleRuleSet = {}) {
  const userPermissions = useSelector(getProfilePermissions);
  const modularityPermissions = useSelector(getModularityPermissions);
  const userAndModularityPermissions = userPermissions?.concat(modularityPermissions);
  const userSecGeogFullList = useSelector(getSecGeogList);
  const myCampaignList = useSelector(getCreatedCampaignByMe);
  const myStateAddress = useSelector(getUserState);
  const secGeography = data?.secGeog || data?.aggregationpolygon_set?.data[0]?.id;
  const creatorId = data?.user?.data?.id || data?.campaign_manager?.data?.id;
  const agency = data?.agency?.data?.id || data?.agency;
  const agencyId = data?.agency?.data?.id || data?.agencyId;
  const entityStateAddress = data?.state;
  const campaignId = Number(data?.campaign?.data?.id);
  const roleType = useSelector(getUserRoleType);
  const userId = useSelector(getUserId);
  const userAgencies = useSelector(getAgenciesList);
  const userSecGeogIdList = userSecGeogFullList?.map((item) => item.id);
  const userAgenciesIdList = userAgencies?.map((item) => item.id);
  const dataCollectorList = useMemo(() => data?.data_collector || [], [data?.data_collector]);
  const retailerCampaignlist = useMemo(() => data?.campaignSet || [], [data?.campaignSet]);

  const findRule = Object.keys(roleRuleSet).find((item) => roleRuleSet[item]?.includes(roleType));

  const checkRoles = useMemo(() => {
    switch (findRule) {
      case 'creator':
        return creatorId === userId;
      case 'restricted':
      case 'secGeogs':
        return isIncludes(userSecGeogIdList, secGeography);
      case 'agency':
        return isIncludes(userAgenciesIdList, agencyId);
      case 'agencySecGeogs':
        return isIncludes(userAgenciesIdList, agency) && isIncludes(userSecGeogIdList, secGeography);
      case 'campaign':
        return isIncludes(myCampaignList, campaignId);
      case 'assigned':
        return isIncludes(dataCollectorList, userId) || creatorId === userId;
      case 'account':
        return myStateAddress === entityStateAddress;
      case 'campaignSet':
        return isContainArray(myCampaignList, retailerCampaignlist);
      case 'creatorOrCampaign':
        return isIncludes(myCampaignList, campaignId) || creatorId === userId;
      case 'all':
        return true;

      default:
        return false;
    }
  }, [
    findRule,
    creatorId,
    userId,
    secGeography,
    userSecGeogIdList,
    agency,
    agencyId,
    campaignId,
    dataCollectorList,
    myCampaignList,
    userAgenciesIdList,
    myStateAddress,
    entityStateAddress,
    retailerCampaignlist,
  ]);

  const hasRights = useMemo(() => {
    if (permission === null) {
      return true;
    }
    return Array.isArray(permission)
      ? permission.every((item) => userAndModularityPermissions.includes(item))
      : userAndModularityPermissions.includes(permission);
  }, [permission, userAndModularityPermissions]);

  if (isEmpty(roleRuleSet)) return hasRights;

  return hasRights && checkRoles;
}

export default HasAccess;
