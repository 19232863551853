/* eslint-disable no-prototype-builtins */
import React, { useMemo } from 'react';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import { CollapsedTab } from 'modules/Modals/old/FilterModal/components/CollapsedTab';
import DatePickerGroup from 'modules/Modals/old/FilterModal/components/DatePickerGroup';
import { IsMassAccount } from 'modules';
import * as Styled from './styles';
import { VISIT_CHARACTERISTICS_TABS_BASE, VISIT_CHARACTERISTICS_TABS_MASS } from './constant';

function EducationCharacteristics() {
  const isMassAccount = IsMassAccount();

  const VISIT_CHARACTERISTICS_TABS = useMemo(() => {
    if (isMassAccount) {
      return VISIT_CHARACTERISTICS_TABS_BASE.concat(VISIT_CHARACTERISTICS_TABS_MASS);
    }
    return VISIT_CHARACTERISTICS_TABS_BASE;
  }, [isMassAccount]);

  const getElement = (item) => {
    switch (item.type) {
      case 'single':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            <CheckedList name={item.field} options={item.options} />
          </CollapsedTab>
        );
      case 'multiple':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            {item.collapsed.map((elem) => (
              <Styled.FiltersContainer>
                <Styled.FiltersTitle>{elem.title}</Styled.FiltersTitle>
                {elem.collapsed ? (
                  elem.collapsed.map((el) => <CheckedList name={el.field} options={el.options} />)
                ) : (
                  <CheckedList name={elem.field} options={elem.options} />
                )}
              </Styled.FiltersContainer>
            ))}
          </CollapsedTab>
        );
      case 'double':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            {item.collapsed.map((elem) => (
              <CheckedList name={elem.field} options={elem.options} />
            ))}
          </CollapsedTab>
        );
      case 'calendars':
        return (
          <CollapsedTab title={item.title} key={item.id}>
            <DatePickerGroup name={item.field} title="" />
          </CollapsedTab>
        );
      default:
        return null;
    }
  };

  return <Styled.Wrapper>{VISIT_CHARACTERISTICS_TABS.map((item) => getElement(item))}</Styled.Wrapper>;
}

export default EducationCharacteristics;
