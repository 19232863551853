import React from 'react';
import PropTypes from 'prop-types';
import { URLS } from '_constants';
import { ENTITY_TYPE } from './constants';
import * as Styled from '../styles';

function mapPopupHeader({ entityId, name, address, city, zipcode, entityType }) {
  const entityUrls = {
    [ENTITY_TYPE.RETAILER]: {
      url: URLS.retailers,
      label: 'retailer',
    },
    [ENTITY_TYPE.VENUE]: {
      url: URLS.venues,
      label: 'venue',
    },
  };
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        hidden
        htmlFor={`${entityUrls[entityType].url}/${entityId}`}
        id="entityViewOpen"
        aria-label={`open ${entityUrls[entityType].label} view page`}
        target="_blank"
      >
        {`open ${entityUrls[entityType].label} view page`}
      </label>

      <Styled.WrapperName
        tabIndex={0}
        id={`${entityUrls[entityType].url}/${entityId}`}
        aria-labelledby="entityViewOpen"
        aria-label={`open ${name} info page`}
        to={`${entityUrls[entityType].url}/${entityId}`}
        target="_blank"
      >
        {name}
      </Styled.WrapperName>
      <Styled.StyleAddress>{`${address}, ${city}, ${zipcode}`}</Styled.StyleAddress>
    </>
  );
}

mapPopupHeader.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
};

mapPopupHeader.defaultProps = {
  address: '',
  city: '',
  zipcode: '',
};

export default mapPopupHeader;
