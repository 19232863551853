/* eslint-disable react/prop-types */
import React from 'react';

const LoupeIcon = ({ height = '18', width = '18' }) => (
  <svg
    alt="search icon"
    focusable="false"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 10.9999H11.71L11.43 10.7299C12.63 9.32989 13.25 7.41989 12.91 5.38989C12.44 2.60989 10.12 0.389893 7.32001 0.0498932C3.09001 -0.470107 -0.469985 3.08989 0.0500152 7.31989C0.390015 10.1199 2.61002 12.4399 5.39002 12.9099C7.42002 13.2499 9.33002 12.6299 10.73 11.4299L11 11.7099V12.4999L15.25 16.7499C15.66 17.1599 16.33 17.1599 16.74 16.7499C17.15 16.3399 17.15 15.6699 16.74 15.2599L12.5 10.9999ZM6.50002 10.9999C4.01002 10.9999 2.00002 8.98989 2.00002 6.49989C2.00002 4.00989 4.01002 1.99989 6.50002 1.99989C8.99002 1.99989 11 4.00989 11 6.49989C11 8.98989 8.99002 10.9999 6.50002 10.9999Z"
      fill="#3A3E43"
    />
  </svg>
);

export default LoupeIcon;
