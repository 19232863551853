import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { deviceLess } from '_constants/DEVICES';
import { Section } from 'ui-kit/Section/styles';

export const MainContainer = styled.div`
  padding: 30px 25px 40px;
  @media ${deviceLess.desktopHd} {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
  }
`;

export const TopLine = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleWrapper = styled.div`
  > a {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 22px;
`;

export const Info = styled.p`
  font-size: 14px;
  color: var(--dim-grey);
`;

export const ButtonWrapper = styled.div`
  > button {
    min-width: 215px;
    padding: 0 20px;
  }
`;

export const Inner = styled.div`
  border: 1px solid var(--grey);
  font-weight: normal;
  position: relative;

  @media ${DEVICES.tablet} {
    display: block;
    border: none;
  }

  ${Section} {
    border: none;
    flex: 1 0;

    @media ${DEVICES.tablet} {
      border: 1px solid var(--grey);
    }
  }
`;

export const WrapperSelect = styled.div`
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  margin-bottom: 25px;
  position: relative;

  @media ${DEVICES.tablet} {
    max-width: 100%;
  }

  @media ${deviceLess.laptop} {
    height: 50px;
    min-height: 50px;

    ${({ canGrow }) =>
      canGrow &&
      css`
        height: 100%;
      `};
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  position: relative;
  height: 50px;

  > div {
    margin: 0;
  }

  @media ${deviceLess.laptop} {
    flex-flow: row nowrap;
  }

  label {
    display: inline-flex;
  }
`;

export const CheckboxTitle = styled.p`
  min-width: 230px;
  @media ${deviceLess.laptop} {
    margin-bottom: 10px;
    min-width: 400px;
  }
  font-size: 14px;
`;

export const EditWrapper = styled.div`
  padding: 15px;

  > button {
    min-width: 135px;
  }
`;

export const EditDescription = styled.p`
  margin: 0 0 20px;
  color: var(--dark);
`;

export const VerticalBorder = styled.div`
  @media ${deviceLess.laptop} {
    border-right: 1px solid #e0e0e0;
    position: absolute;
    width: 400px;
    min-width: 400px;
    height: 100%;
    z-index: 1;
  }
`;

export const HorizontalBorder = styled.div`
  border-bottom: 1px solid #e0e0e0;
  position: absolute;
  top: calc(100% + 10px);
  left: -20px;
  width: calc(100% + 40px);
  z-index: 1;
  filter: opacity(0.4);

  @media ${deviceLess.laptop} {
    border-bottom: 1px solid #e0e0e0;
    position: absolute;
    top: calc(100% + 5px);
    left: -20px;
    width: calc(100% + 40px);
    z-index: 1;
    filter: opacity(0.4);
  }
`;
