import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LinkElem } from 'modules/Header/styles';
import PropTypes from 'prop-types';
import { URLS } from '_constants';
import LogOutPopup from '../../../LogOutPopup';
import * as Styled from './styles';

function MenuItem({
  route,
  setIsMenuOpen,
  setIsBurgerMenu,
  parentRef,
  index,
  focusedTab,
  setFocusedTab,
  routeRef,
  handleSetParentFocus,
  burgerRef,
}) {
  const location = useLocation();
  const history = useHistory();
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const handleSelect = useCallback(
    (e, path) => {
      if (path === location.pathname) {
        e.preventDefault();
        return;
      }
      e.stopPropagation();
      setIsMenuOpen(false);
      setIsBurgerMenu(false);
      setFocusedTab(index);
      burgerRef?.current?.focus();
    },
    [burgerRef, index, location.pathname, setFocusedTab, setIsBurgerMenu, setIsMenuOpen],
  );

  const handleSelectByKeyboard = useCallback(
    (e, path, isHelp) => {
      if ((path === location.pathname && e.keyCode !== 27) || e.keyCode === 9) {
        setIsMenuOpen(false);
        document.getElementById('burgerMenuMobileId').focus();
        e.preventDefault();
        return;
      }
      if ([27].includes(e.keyCode)) {
        setIsMenuOpen(false);
        parentRef?.current?.focus();
        handleSetParentFocus();
      }
      if ([32, 13].includes(e.keyCode) && !isHelp) {
        e.preventDefault();
        setIsBurgerMenu(false);
        history.push(path);
        burgerRef?.current?.focus();
      }
    },
    [burgerRef, handleSetParentFocus, history, location.pathname, parentRef, setIsBurgerMenu, setIsMenuOpen],
  );

  const handleLogOut = useCallback(() => {
    setIsOpenPopup(true);
  }, [setIsOpenPopup]);

  if (route.isDevider)
    return (
      <Styled.ListItem>
        <Styled.DeviderText>{route.title}</Styled.DeviderText>
      </Styled.ListItem>
    );

  return (
    <>
      {route.devider && (
        <Styled.ListItem>
          <Styled.DeviderText>{route.devider}</Styled.DeviderText>
        </Styled.ListItem>
      )}
      <Styled.ListItem border={route.border} borderAll={route.borderAll} key={route.id} role="menuitem">
        {route.path !== URLS.logout ? (
          <LinkElem
            onClick={(e) => handleSelect(e, route.path)}
            onKeyDown={(e) => handleSelectByKeyboard(e, route.path, route?.isHelp)}
            to={route.isHelp ? { pathname: route.href } : route.path}
            name={route.path}
            aria-label={route.title}
            ref={routeRef}
            target={route.isHelp && '_blank'}
            tabIndex={focusedTab === index ? 0 : -1}
          >
            {route.title}
          </LinkElem>
        ) : (
          <>
            <Styled.LogOut
              onClick={handleLogOut}
              aria-label={route.title}
              tabIndex={focusedTab === index ? 0 : -1}
              ref={routeRef}
            >
              {route.title}
            </Styled.LogOut>
            <LogOutPopup isOpenPopup={isOpenPopup} setIsOpenPopup={setIsOpenPopup} />
          </>
        )}
      </Styled.ListItem>
    </>
  );
}

MenuItem.propTypes = {
  route: PropTypes.shape({
    path: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.number,
    devider: PropTypes.string,
    border: PropTypes.bool,
    isHelp: PropTypes.bool,
    isDevider: PropTypes.bool,
    borderAll: PropTypes.bool,
  }).isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
  setIsBurgerMenu: PropTypes.func.isRequired,
  parentRef: PropTypes.object.isRequired,
  handleSetParentFocus: PropTypes.func.isRequired,
  burgerRef: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  focusedTab: PropTypes.number.isRequired,
  setFocusedTab: PropTypes.func.isRequired,
  routeRef: PropTypes.object.isRequired,
};

export default MenuItem;
