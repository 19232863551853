import { formIsValid } from 'utils/validation';
import { validateEmail, validateText } from 'utils/validation/fields';
import { FORM_ACTION_TYPES, FORM_REQUIRED_FIELDS, formInitialState, inputTypes } from './constants';

export const init = (initialState) => {
  return { ...initialState };
};

export const validateData = (action) => {
  switch (action.name) {
    case inputTypes.email:
      return validateEmail({
        value: action.payload.trim(),
        name: action.name,
        required: FORM_REQUIRED_FIELDS.includes(action.name),
        max: 50,
      });
    default:
      return validateText({
        value: action.payload.trim(),
        name: action.name,
        required: FORM_REQUIRED_FIELDS.includes(action.name),
        max: 50,
      });
  }
};

const checkFormIsValid = (data, errors) => formIsValid(errors, FORM_REQUIRED_FIELDS);

export const formReducer = (formState, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.ENTER_DATA: {
      const newData = {
        ...formState.data,
        [action.name]: action.payload,
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: validateData(action),
      };

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: checkFormIsValid(newData, newErrors),
      };
    }
    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const newData = {
        ...formState.data,
        [action.name]: action.payload,
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: validateData(action),
      };

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newData, newErrors),
      };
    }
    case FORM_ACTION_TYPES.RESET_TYPE:
      return init(action.payload);
    default:
      return {
        ...formInitialState,
      };
  }
};
