import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

function Card({ icon, title, description, url, linkText }) {
  return (
    <Styled.Card>
      {icon && (
        <Styled.IconWrapper>
          <Styled.Icon src={icon} alt="" role="presentation" />
        </Styled.IconWrapper>
      )}
      <Styled.CardTitle>{title}</Styled.CardTitle>
      <Styled.CardDescription>{description}</Styled.CardDescription>
      <Styled.CardLink to={url} aria-label={title}>
        {linkText}
      </Styled.CardLink>
    </Styled.Card>
  );
}

Card.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  linkText: PropTypes.string,
};

Card.defaultProps = {
  icon: '',
  linkText: 'View',
};

export default Card;
