export default {
  DISPOSITION_NO_VIOLATION: '1',
  DISPOSITION_PENDING: '2',
  DISPOSITION_VIOLATION: '3',

  ENFORCEMENTS_VIOLATION: 'Enforcements with Violation',
  NO_VIOLATION: 'No violation',
  PENDING: 'Pending',

  SUSPENDED_LICENSES: 'Suspended licenses',
  REVOKED_LICENSES: 'Revoked licenses',
  EXPIRED_LICENSES: 'Ended licenses',

  EXPIRED_T: 'expired=t',
  REVOKED_T: 'revoked=t',
  SUSPENDED_T: 'suspended=t',

  SALES_SUSPENSIONS: 'Sales suspensions',

  KEY_NAME: 'name',
  KEY_RETAILER: 'retailer',
  KEY_ADDRESS: 'address',
  KEY_AGENCY: 'agency',
  KEY_DATE: 'date',
  KEY_INSPECTION_DATE: 'inspection_date',
  KEY_REVOCATION_DATE: 'revocation_date',
  KEY_END_DATE: 'end_date',
  KEY_SUSP_START: 'susp_start',
  KEY_SUSP_END: 'susp_end',
  KEY_LICENSE_NUMBER: 'license_number',

  VIEW_HISTORICAL: 'View historic info',

  RETAILER: 'Retailer ',
  ENFORCEMENT_WITH_VIOLATION: 'Visit',
  LICENSE_REVOKED: 'License',
  LICENSES_ID: 'License ID: ',
  ENFORCEMENT_ACTIVITIES: 'Enforcement activities: ',

  REVOKED: 'has been revoked',
  SUSPENDED: 'has been suspended',
  VIOLATION: 'has violation',

  PERMISSIONS: {
    USEFUL_LINKS: 1,
    LICENSES_TABLE: 2,
    ENFORCEMENTS_TABLE: 3,
    SUSPENSIONS_TABLE: 4,
    NOTIFICATIONS: 5,
  },
};
