import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../styles';

function CollectItem({ count }) {
  return <Styled.CountItem>{count}</Styled.CountItem>;
}

CollectItem.propTypes = {
  count: PropTypes.number.isRequired,
};

export default CollectItem;
