import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';

export const getEnforcementRetailerCharacteristics = (corpOptions, isVaAccount, hasResidentialModule) => [
  {
    id: 1,
    field: FILTER_FIELDS.IS_CURRENT,
    title: 'Open for business',
    options: FILTER_OPTIONS.BUSINESS_OPTIONS,
    type: 'single',
  },
  {
    type: 'multiple',
    title: 'Products sold',
    id: 2,
    collapsed: [
      {
        id: 3,
        title: 'Tobacco',
        field: FILTER_FIELDS.SELLS_TOBACCO,
        options: FILTER_OPTIONS.SELLS_TOBACCO_OPTIONS,
      },
      {
        id: 4,
        title: 'Sells cigarettes',
        field: FILTER_FIELDS.SELL_CIGARETTES,
        options: FILTER_OPTIONS.SELLS_CIGARETTES_OPTIONS,
        isSubcategory: true,
      },
      {
        id: 5,
        title: 'Sells cigars',
        field: FILTER_FIELDS.SELL_CIGARS,
        options: FILTER_OPTIONS.SELLS_CIGARS_OPTIONS,
        isSubcategory: true,
      },
      {
        id: 6,
        title: 'Sells e-cigarettes',
        field: FILTER_FIELDS.SELL_ECIG,
        options: FILTER_OPTIONS.SELLS_ECIG_OPTIONS,
        isSubcategory: true,
      },
      {
        id: 7,
        title: 'Sells smokeless tobacco products',
        field: FILTER_FIELDS.SELL_SMOKELESS,
        options: FILTER_OPTIONS.SELLS_SMOKELESS_OPTIONS,
        isSubcategory: true,
      },
      {
        id: 8,
        title: 'Alcohol',
        field: FILTER_FIELDS.SELLS_ALCOHOL,
        options: FILTER_OPTIONS.SELLS_ALCOHOL_OPTIONS,
      },
    ],
  },
  isVaAccount && {
    type: 'multiple',
    title: 'Gambling',
    id: 9,
    collapsed: [
      {
        id: 10,
        title: 'Sells lottery tickets',
        field: FILTER_FIELDS.SELLS_TICKETS,
        options: FILTER_OPTIONS.SELLS_TICKETS_OPTIONS,
      },
      {
        id: 11,
        title: 'Has gaming machines',
        field: FILTER_FIELDS.HAS_GAMING_MACHINES,
        options: FILTER_OPTIONS.HAS_GAMING_MACHINES_OPTIONS,
      },
    ],
  },
  {
    id: 12,
    field: FILTER_FIELDS.RETAILER_BUSINESS_TYPE,
    title: 'Store type',
    options: FILTER_OPTIONS.STORE_TYPE_OPTIONS,
    type: 'single',
  },
  {
    type: 'double',
    title: 'Store ownership',
    id: 13,
    collapsed: [
      {
        id: 14,
        field: FILTER_FIELDS.CHAIN,
        options: FILTER_OPTIONS.CHAIN_OPTIONS,
      },
      {
        id: 15,
        field: FILTER_FIELDS.CORPORATION,
        options: corpOptions,
      },
    ],
  },
  {
    type: 'multiple',
    title: 'Proximity',
    id: 16,
    collapsed: [
      {
        id: 17,
        field: FILTER_FIELDS.NEAR_SCHOOL,
        title: 'Near schools',
        options: FILTER_OPTIONS.NEAR_SCHOOL_OPTIONS,
      },
      {
        id: 18,
        field: FILTER_FIELDS.NEAR_RETAILER,
        title: 'Near retailers',
        options: FILTER_OPTIONS.NEAR_RETAILER_OPTIONS,
      },
    ],
  },
  {
    type: 'double',
    title: 'Other retailer details',
    id: 19,
    collapsed: [
      {
        id: 20,
        field: FILTER_FIELDS.WIC,
        options: FILTER_OPTIONS.WIC_OPTIONS,
      },
      {
        id: 21,
        field: FILTER_FIELDS.SNAP,
        options: FILTER_OPTIONS.SNAP_OPTIONS,
      },
      {
        id: 22,
        field: FILTER_FIELDS.PHARMACY,
        options: FILTER_OPTIONS.HAS_PHARMACY_OPTIONS,
      },
      {
        id: 23,
        field: FILTER_FIELDS.HAS_AVC_AGREEMENT,
        options: FILTER_OPTIONS.HAS_AVC_AGREEMENT_OPTIONS,
      },
      {
        id: 24,
        field: FILTER_FIELDS.INTERNET_SALES_VISTIS,
        options: FILTER_OPTIONS.INTERNET_SALES_OPTIONS,
      },
      hasResidentialModule && {
        id: 25,
        field: FILTER_FIELDS.RESIDENTIAL_LOCATION,
        options: FILTER_OPTIONS.RESIDENTIAL_LOCATION_OPTIONS,
      },
    ],
  },
];
