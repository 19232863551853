import { formNames } from '../Add/constants';

export const listedItems = [
  {
    name: formNames.geographies,
    title: 'Geographies',
  },
  {
    name: formNames.agencies,
    title: 'Agencies',
  },
];

export const genderOptions = {
  1: 'Female',
  2: 'Male',
  3: 'Other',
};
