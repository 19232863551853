import styled from 'styled-components';

export const Wrapper = styled.div`
  .BaseSelect {
    &__control {
      border-radius: 0;
      width: 150px;
      img {
        margin-top: 14px;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      border-radius: 0;
    }
  }
  .BaseSelect__placeholder {
    padding-top: 10px;
  }
  div.BaseSelect__single-value {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .BaseSelect__menu,
  .BaseSelect__menu-list {
    /**
    * Item height 40px remove fixed height and set max item count is 4 
    */
    height: auto;
    max-height: 200px;
    overflow: auto;
  }

  div {
    height: 40px;
  }
`;
