import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import * as Styled from './styles';

const ImageZoom = ({ zoom, alt, ...rest }) => {
  const [click, setClick] = useState(false);

  const handleCLick = () => {
    setClick((prev) => !prev);
  };

  const handleKeyPress = ({ key }) => {
    switch (key) {
      case 'Enter':
        setClick(true);
        break;
      case 'Escape':
        setClick(false);
        break;

      default:
        break;
    }
  };

  if (!zoom) return <Styled.DefaultImage {...rest} />;
  return (
    <>
      <Styled.DefaultImage tabIndex="0" alt={alt} {...rest} onClick={handleCLick} onKeyDown={handleKeyPress} />
      {click && (
        <FocusTrap>
          <Styled.ImageContainer onClick={handleCLick}>
            <Styled.Image tabIndex="0" onKeyDown={handleKeyPress} {...rest} />
          </Styled.ImageContainer>
        </FocusTrap>
      )}
    </>
  );
};

/* Img type of props */

ImageZoom.propTypes = {
  alt: PropTypes.string,
  zoom: PropTypes.bool,
};

/* Img default props */

ImageZoom.defaultProps = {
  zoom: false,
  alt: '',
};

export default ImageZoom;
