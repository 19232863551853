import styled from 'styled-components';

export const DropZone = styled.div`
  border: 2px dashed #cccccc;
  border-radius: 4px;
  padding: 20px;
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-wrap: wrap;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const ChosenFileText = styled.p`
  margin: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
`;

export const FileInput = styled.input`
  display: none;
`;

export const ChooseFileButton = styled.button`
  background: var(--rgb-grey);
  padding: 2px 10px;
  min-height: 30px;
  min-width: 100px;
`;

export const ModalDescription = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  align-items: center;

  button {
    margin-right: 20px;
  }
`;

export const List = styled.ul`
  font-size: 18px;
  margin: 0 35px 15px;

  li {
    display: list-item;
    list-style: disc;
  }
`;
