import React, { memo, useEffect, createRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../styles';
import HeaderCollapsedTab from './HeaderCollapsedTab';
import MobileRouteItem from './MobileRouteItem';

function MenuList({ routes, setIsOpenMenu, isOpenMenu, burgerRef }) {
  const [focusedTab, setFocusedTab] = useState(1);

  const routesRefs = routes.reduce((acc, item, index) => ({ ...acc, [index + 1]: createRef() }), {});

  const handleNextTab = (firstTabInRound, nextTab, lastTabInRound) => {
    const tabToSelect = focusedTab === lastTabInRound ? firstTabInRound : nextTab;
    setFocusedTab(tabToSelect);
    routesRefs[tabToSelect].current.focus();
  };

  const handleKeyPress = (e) => {
    if (!isOpenMenu) return;
    const tabCount = routes.length;

    if ([9].includes(e.keyCode)) {
      document.getElementById('burgerMenuMobileId').focus();
      e.preventDefault();
    }

    if ([38].includes(e.keyCode)) {
      e.preventDefault();
      const last = tabCount;
      const next = focusedTab - 1;
      handleNextTab(last, next, 1);
    }
    if ([40].includes(e.keyCode)) {
      e.preventDefault();
      const first = 1;
      const next = focusedTab + 1;
      handleNextTab(first, next, tabCount);
    }
  };

  useEffect(() => {
    if (!isOpenMenu) return;
    routesRefs[1].current?.focus();
    // eslint-disable-next-line
  }, [isOpenMenu]);

  return (
    <Styled.RoutesWrapper role="navigation" aria-label="Main navigation" onKeyDown={handleKeyPress}>
      <Styled.RoutesList role="menu">
        {routes.reduce((acc, route, index) => {
          if (!route.isShow) return acc;
          return route.path
            ? acc.concat(
                <MobileRouteItem
                  route={route}
                  key={route.id}
                  isOpenMenu={isOpenMenu}
                  setIsOpenMenu={setIsOpenMenu}
                  routeRef={routesRefs[index + 1]}
                  index={index + 1}
                  focusedTab={focusedTab}
                  setFocusedTab={setFocusedTab}
                  burgerRef={burgerRef}
                />,
              )
            : acc.concat(
                <HeaderCollapsedTab
                  title={route.title}
                  key={route.id}
                  routes={route.subRoutes}
                  setIsBurgerMenu={setIsOpenMenu}
                  routeRef={routesRefs[index + 1]}
                  index={index + 1}
                  focusedTab={focusedTab}
                  setFocusedTab={setFocusedTab}
                  burgerRef={burgerRef}
                />,
              );
        }, [])}
      </Styled.RoutesList>
    </Styled.RoutesWrapper>
  );
}

MenuList.propTypes = {
  routes: PropTypes.array.isRequired,
  setIsOpenMenu: PropTypes.func.isRequired,
  isOpenMenu: PropTypes.bool.isRequired,
  burgerRef: PropTypes.object.isRequired,
};

export default memo(MenuList);
