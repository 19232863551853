import { lt, gt, isNull, isNaN } from 'lodash';
import { COMMON_ERRORS } from '_constants/errors';
import { hasFalsyValue } from '..';
/**
 * Validate input text object and returns error string
 * @param  {string} {value
 * @param  {string} name
 * @param  {number} min=null
 * @param  {number} max=null
 * @param  {boolean} required=false}
 *
 * @returns {string} error | ''
 */
export default ({ value, name, min = null, max = null, required = false }) => {
  let error =
    (hasFalsyValue(value) && COMMON_ERRORS.isRequired(name)) ||
    (isNaN(+value) && COMMON_ERRORS.isNotValidDigits(name, 6)) ||
    (!isNull(min) && lt(value.length, min) && COMMON_ERRORS.lessThan(name, min, 'digits')) ||
    (!isNull(max) && gt(value.length, max) && COMMON_ERRORS.moreThan(name, max, 'digits'));
  error = !required && hasFalsyValue(value) ? null : error;
  return error;
};
