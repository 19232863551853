import styled from 'styled-components';
import { DEVICES } from '_constants';

export const ContentBox = styled.div``;

export const Link = styled.div.attrs(() => ({ ariaRequired: true }))`
  text-decoration: underline;
  color: var(--curious-blue);
  cursor: pointer;
  margin: 0 5px 0 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  border-bottom: 1px solid var(--whisper);
`;

export const Body = styled.div``;

export const TabList = styled.ul.attrs(() => ({ ariaRequired: true, role: 'menu' }))`
  padding: 20px;
  margin: 0;
  list-style: none;
`;

export const TabItem = styled.li.attrs(() => ({ ariaRequired: true, role: `menuitem` }))`
  max-width: 341px;
  width: 100%;
  height: 42px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: var(--grey-smoke);
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;

  &:not(:first-child) {
    margin: 14px 0 0;
  }

  @media ${DEVICES.tablet} {
    max-width: 100%;
  }
`;

export const Wrapper = styled.div``;
export const MenuItem = styled.div.attrs(() => ({ ariaRequired: true }))``;
