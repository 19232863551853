import { API_URLS } from '_constants';
import * as TYPES from './Notification.types';

export const getNotificationList = () => ({
  types: [TYPES.GET_NOTIFICATION_LIST, TYPES.GET_NOTIFICATION_LIST_SUCCESS, TYPES.GET_NOTIFICATION_LIST_FAIL],
  promise: (client) => client.get(`${API_URLS.userNotifications}`),
});

export const updateNotificationList = (id, data) => {
  return {
    types: [TYPES.UPDATE_NOTIFICATION, TYPES.UPDATE_NOTIFICATION_SUCCESS, TYPES.UPDATE_NOTIFICATION_FAIL],
    promise: (client) => client.patch(`${API_URLS.userNotifications}${id}`, data),
  };
};
