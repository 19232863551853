import styled from 'styled-components';

export const ModalWrapper = styled.div`
  max-height: 90vh;
  width: 80vw;
`;

export const ModalTitle = styled.h1`
  line-height: 1.6;
  font-weight: 500;
  text-align: left;
  margin-bottom: 17px;
`;

export const ModalDescription = styled.div`
  max-height: 80%;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
  padding: 20px;
  overflow: auto;
  margin-bottom: 26px;

  ol,
  ul {
    margin-left: 30px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr {
    margin-bottom: 10px;
  }

  li {
    padding-left: 5px;
    list-style: initial;
  }
`;

export const Attachments = styled.div`
  height: 120px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding-top: 10px;
  border-radius: 5px;
  width: fit-content;

  :hover {
    cursor: pointer;
    background: #e0e0e0;
  }
`;

export const FileTitle = styled.div`
  padding: 5px;
`;
