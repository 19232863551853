export const getVisitLinkText = (assignmentsStatus, visitId, revisitStatus, visitViolation) => {
  let visitText = '';
  let violationText = '';
  if ([0, 2].includes(assignmentsStatus) && revisitStatus) {
    violationText = 'completed visit with a violation';
  }
  if ([0, 2].includes(assignmentsStatus) && !revisitStatus) {
    visitText = '';
  }
  if (assignmentsStatus === 1 && revisitStatus && visitId !== revisitStatus) {
    visitText = 'completed visit';
    violationText = 'completed visit with a violation';
  }
  if (assignmentsStatus === 1 && visitId && !revisitStatus) {
    visitText = 'completed visit';
  }
  if (assignmentsStatus === 1 && visitViolation) {
    visitText = 'completed visit';
  }
  if (assignmentsStatus === 1 && revisitStatus && visitId !== revisitStatus && visitViolation) {
    visitText = 'completed visit';
    violationText = 'completed visit with a violation';
  }

  return { visitText, violationText };
};
