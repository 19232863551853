import { REQUEST_HEADERS, API_URLS } from '_constants';
import * as TYPES from './Retailer.types';

export const fetchCityList = (str) => ({
  types: [TYPES.GET_RETAILER_CITY, TYPES.GET_RETAILER_CITY_SUCCESS, TYPES.GET_RETAILER_CITY_FAIL],
  promise: (client) => client.get(`${API_URLS.cities}?name=${str}`),
});

export const addRetailer = (data) => ({
  types: [TYPES.ADD_RETAILER, TYPES.ADD_RETAILER_SUCCESS, TYPES.ADD_RETAILER_FAIL],
  promise: (client) => client.post(API_URLS.retailers, data, { headers: { ...REQUEST_HEADERS } }),
});

export const getRetailerInfo = (retailerId, isSurvey) => ({
  types: [TYPES.GET_RETAILER, TYPES.GET_RETAILER_SUCCESS, TYPES.GET_RETAILER_FAIL],
  promise: (client) => client.get(`${API_URLS.retailers}/${retailerId}${isSurvey ? '' : '?include=visits'}`),
});

export const editRetailer = (id, data) => ({
  types: [TYPES.EDIT_RETAILER, TYPES.EDIT_RETAILER_SUCCESS, TYPES.EDIT_RETAILER_FAIL],
  promise: (client) => client.patch(`${API_URLS.retailers}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
});

export const checkNewRetailerLocation = (params) => ({
  types: [TYPES.CHECK_RETAILER_LOCATION, TYPES.CHECK_RETAILER_LOCATION_SUCCESS, TYPES.CHECK_RETAILER_LOCATION_FAIL],
  promise: (client) => client.get(`${API_URLS.mapping}?${params}`),
});

export const resetRetailerData = () => ({
  type: TYPES.RESET_RETAILER_DATA,
});

export const bulkDeleteRetailers = (data) => {
  return {
    types: [TYPES.DELETE_RETAILERS, TYPES.DELETE_RETAILERS_SUCCESS, TYPES.DELETE_RETAILERS_FAIL],
    promise: (client) => client.delete(`${API_URLS.retailers}/delete`, { data }),
  };
};

export const confirmBulkDeleteRetailers = (data) => {
  return {
    types: [
      TYPES.CONFIRM_DELETE_RETAILERS,
      TYPES.CONFIRM_DELETE_RETAILERS_SUCCESS,
      TYPES.CONFIRM_DELETE_RETAILERS_FAIL,
    ],
    promise: (client) => client.delete(`${API_URLS.retailers}/delete?confirmed=t`, { data }),
  };
};

export const bulkRestoreRetailers = (data) => {
  return {
    types: [TYPES.RESTORE_RETAILERS, TYPES.RESTORE_RETAILERS_SUCCESS, TYPES.RESTORE_RETAILERS_FAIL],
    promise: (client) => client.post(`${API_URLS.retailers}/restore`, data),
  };
};

export const getRetailersImportProgress = () => {
  return {
    types: [
      TYPES.RETAILERS_IMPORT_PROGRESS,
      TYPES.RETAILERS_IMPORT_PROGRESS_SUCCESS,
      TYPES.RETAILERS_IMPORT_PROGRESS_FAIL,
    ],
    promise: (client) => client.get(`${API_URLS.retailers}/import`),
  };
};

export const postRetailersImport = (data) => {
  return {
    types: [TYPES.UPLOAD_RETAILERS, TYPES.UPLOAD_RETAILERS_SUCCESS, TYPES.UPLOAD_RETAILERS_FAIL],
    promise: (client) => client.post(`${API_URLS.retailers}/import`, data, { headers: { ...REQUEST_HEADERS } }),
  };
};

export const resetRetailerImportData = () => ({
  type: TYPES.RESET_RETAILER_IMPORT_DATA,
});

export const setRetailerToastId = (id) => ({
  type: TYPES.SET_RETAILER_TOAST_ID,
  payload: id,
});
