import React from 'react';
import { URLS } from '_constants';
import { VisitView } from 'modules';

function AssessmentView() {
  return (
    <VisitView
      headerTitle="View assessment visit"
      sectionTitle="Assessment information"
      editPageUrl={URLS.assessmentsPages.edit}
      noViolation
      viewUrl={URLS.assessments}
    />
  );
}

export default AssessmentView;
