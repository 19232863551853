import { API_URLS } from '_constants';
import * as TYPES from './Collect.types';

export const getCampaignList = (params) => ({
  types: [TYPES.GET_CAMPAIGN_LIST, TYPES.GET_CAMPAIGN_LIST_SUCCESS, TYPES.GET_CAMPAIGN_LIST_FAIL],
  promise: (client) =>
    client.get(`${API_URLS.campaigns}?${params}&include=account,agency,campaign_manager,form&status=0`),
});

export const getAssignmentsList = (params) => ({
  types: [TYPES.GET_ASSIGNMENT_LIST, TYPES.GET_ASSIGNMENT_LIST_SUCCESS, TYPES.GET_ASSIGNMENT_LIST_FAIL],
  promise: (client) =>
    client.get(`${API_URLS.assignments}?${params}&include=retailer,form,users,campaign&is_active=true&status=0`),
});

// attach to campaign
export const getAssignmnetsAttachedToCampaign = (params) => ({
  types: [TYPES.GET_ASSIGNMENT_SEARCH, TYPES.GET_ASSIGNMENT_SEARCH_SUCCESS, TYPES.GET_ASSIGNMENT_SEARCH_FAIL],
  promise: (client) => client.get(`${API_URLS.campaigns}?${params}&not_archived=true&include=agency`),
});

export const getRevisitsList = (params, timeZone) => ({
  types: [TYPES.GET_REVISITS_LIST, TYPES.GET_REVISITS_LIST_SUCCESS, TYPES.GET_REVISITS_LIST_FAIL],
  promise: (client) => client.get(`${API_URLS.revisits}?${params}&status=0`),
  timeZone,
});

export const getRevisitsCSV = (params) => ({
  types: [TYPES.GET_REVISITS_CSV, TYPES.GET_REVISITS_CSV_SUCCESS, TYPES.GET_REVISITS_CSV_FAIL],
  promise: (client) => client.get(`${API_URLS.revisitsCSV}${params}&not_archived=true&status=0`),
});

export const getCampaignListCSV = (params) => ({
  types: [TYPES.GET_CAMPAIGN_LIST_CSV, TYPES.GET_CAMPAIGN_LIST_CSV_SUCCESS, TYPES.GET_CAMPAIGN_LIST_CSV_FAIL],
  promise: (client) => client.get(`${API_URLS.campaignCSV}${params}`),
});

export const getAssignmentsCSV = (params) => ({
  types: [TYPES.GET_ASSIGNMENTS_CSV, TYPES.GET_ASSIGNMENTS_CSV_SUCCESS, TYPES.GET_ASSIGNMENTS_CSV_FAIL],
  promise: (client) => client.get(`${API_URLS.assignmentsCSV}${params}&not_archived=true&is_active=true&status=0`),
});

export const getOfflineAssigmentsList = ({ params = '' } = {}) => ({
  types: [TYPES.GET_ALL_ASSIGNMENTS_LIST, TYPES.GET_ALL_ASSIGNMENTS_LIST_SUCCESS, TYPES.GET_ALL_ASSIGNMENTS_LIST_FAIL],
  promise: (client) =>
    client.get(
      `${API_URLS.assignments}?${params}&ordering=retailer__name&include=retailer,form,users,campaign&is_active=true&page_size=10000&status=0&offline=true`,
    ),
});

export const setCollectCount = (payload) => ({
  type: TYPES.SET_COLLECT_COUNT,
  payload,
});

export const archiveCampaigns = (data) => {
  return {
    types: [TYPES.ARCHIVE_CAMPAIGNS, TYPES.ARCHIVE_CAMPAIGNS_SUCCESS, TYPES.ARCHIVE_CAMPAIGNS_FAIL],
    promise: (client) => client.patch(`${API_URLS.campaigns}/archive`, data),
  };
};

export const restoreCampaigns = (data) => {
  return {
    types: [TYPES.RESTORE_CAMPAIGNS, TYPES.RESTORE_CAMPAIGNS_SUCCESS, TYPES.RESTORE_CAMPAIGNS_FAIL],
    promise: (client) => client.patch(`${API_URLS.campaigns}/restore`, data),
  };
};
