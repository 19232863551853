export default {
  STORE_MERGER: '/store-merger',
  EDIT_AGENCIES: `/edit-agencies/:id`,
  ADD_AGENCIES: `/add-agencies`,
  EDIT_ENFORCEMENT: `/enforcement/edit/:id`,
  RETAILERS: `/retailers`,
  LICENSES: `/licenses`,
  ENFORCEMENT: `/enforcement`,
  LOCATION_REPORT: `/location-report`,
  AGENCIES: `/agencies`,
  AGENCIES_VIEW: `/agencies/:id`,
  ALTERNATE_REPORT: `/alternate-report`,
  ALTERNATE_REPORT_VIEW: `/alternate-report/:id`,
  LICENSES_REPORT: `/jurisdiction-report-licenses`,
  LICENSES_REPORT_VIEW: `/jurisdiction-report-licenses/:id`,
  ENFORCEMENT_REPORT: `/jurisdiction-report-enforcement`,
  ENFORCEMENT_REPORT_VIEW: `/jurisdiction-report-enforcement/:id`,
  PRIVACY: `/privacy`,
  DASHBOARD: `/dashboard`,
  LOGIN: `/login`,
  RESET: `/reset`,
  PROFILE: `/profile`,
  VIEW_RETAILER: `/retailers/:id`,
  PASSWORD_RESET: `/password-reset`,
  ADD_ENFORCEMENT: `/enforcement/survey/:step`,
  VIEW_ENFORCEMENT: `/enforcement/:id`,
  ADD_LICENSE: `/add-licenses/:id`,
  EDIT_LICENSE: `/edit-licenses/:id`,
  VERIFICATION_FAILED: `/verification-failed`,
  ADD_RETAILER: `/add-retailer`,
  EDIT_RETAILER: `/edit-retailer/:id`,
  VIEW_LICENSES: `/licenses/:id`,
  HEALTH_CHECK: `/healthcheck`,
  REPORTS: `/reports`,
  ERROR_PAGE: `/error-page`,
  VIEW_HISTORICAL_INFO: `/historical-info/:id`,
  VIEW_USER: `/users/:id`,
  USERS: `/users`,
  ADD_USER: `/user-add`,
  COPY_USER: `/user-copy/:id`,
  EDIT_USER: `/user-edit/:id`,
  FAQ: `/faq`,
  RESET_PASSWORD_LINK: `/rest-password-link`,
  RESET_PASSWORD_NO_VALID_LINK: `/rest-password-link-no-valid`,
};
