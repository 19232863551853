import { formIsValid } from 'utils/validation';
import { validateText } from 'utils/validation/fields';
import { FORM_ACTION_TYPES, FORM_REQUIRED_FIELDS, formInitialState } from './constants';

export const validateData = (action) => {
  return validateText({
    value: action.payload?.trim(),
    name: action.name,
    required: FORM_REQUIRED_FIELDS.includes(action.name),
    max: 50,
  });
};

export const init = (initialState) => {
  return { ...initialState };
};

const checkFormIsValid = (data, errors) => formIsValid(errors, FORM_REQUIRED_FIELDS);

export const formReducer = (formState, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.SET_INITIAL_DATA: {
      const newData = {
        ...formState.data,
        ...action.payload,
      };

      return {
        ...formState,
        data: newData,
        canBeSubmitted: checkFormIsValid(newData),
      };
    }
    case FORM_ACTION_TYPES.ENTER_DATA: {
      const newData = {
        ...formState.data,
        [action.name]: action.payload,
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: false,
      };

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newData, newErrors),
      };
    }

    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const newErrors = {
        ...formState.errors,
        [action.name]: validateData(action),
      };

      return {
        ...formState,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(formState.data, newErrors),
      };
    }

    case FORM_ACTION_TYPES.RESET_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...action.payload,
        },
      };

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: false,
      };
    }

    default:
      return {
        ...formInitialState,
      };
  }
};

export const getRadioValue = (val) => {
  if (val === false) return false;
  if (val) return true;
  return null;
};
