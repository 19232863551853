export default {
  LOGIN: {
    SUCCESS: {
      SIGN_IN: 'Welcome to the system!',
    },
  },
  FORGOT: {
    MATCH: 'If this email address matches an existing account, a reset password link has been sent to you.',
  },
  RESET: {
    SUCCESS: 'The password was successfully reset. Now, try to log in.',
  },
  USER: {
    VERIFIED: 'User verified.',
    CREATE: {
      SUCCESS: 'User has been added.',
    },
    UPDATE: {
      SUCCESS: 'User has been updated.',
    },
    RESET_BUTTON: {
      SUCCESS: 'Password reset email was successfully sent.',
    },
  },
  VISIT: {
    VIOLATION_PDF: {
      SUCCESS: 'The PDF is being prepared and will be emailed to you',
      ERROR: ' Something went wrong. Please try again later',
    },
    CREATE: {
      SUCCESS: 'Visit successfully created',
    },
    DELETE: {
      SUCCESS: 'Visit successfully deleted',
    },
    EDIT: {
      SUCCESS: 'Visit successfully edited',
    },
    VIOLATION: {
      SUCCESS: 'Violation successfully edited',
    },
    DELETE_SELECTED: {
      SUCCESS: 'Visits were successfully deleted',
      FAIL: 'Unable to delete visit(s) because no visit(s) were selected',
    },
    RESTORE_SELECTED: {
      SUCCESS: 'Visits were successfully restored',
      FAIL: 'Unable to restore visit(s) because no visit(s) were selected',
    },
  },
  RETAILER: {
    CREATE: {
      SUCCESS: 'The retailer was successfully created',
    },
    UPDATE: {
      SUCCESS: 'The retailer was successfully updated',
    },
    DELETE_SELECTED: {
      SUCCESS: 'Retailer(s) were successfully deleted',
      FAIL: 'Unable to delete retailer(s) because no retailer(s) were selected',
    },
    RESTORE_SELECTED: {
      SUCCESS: 'Retailer(s) were successfully restored',
      FAIL: 'Unable to restore retailer(s) because no retailer(s) were selected',
    },
  },
  COLLECT: {
    SUCCESS: 'The CSV is being prepared and will be emailed to you.',
  },
  COMMON_ERROR: ' Something went wrong. Please try again later',
  CAMPAIGN: {
    CREATE: {
      SUCCESS: 'The campaign was successfully created',
    },
    EDIT: {
      SUCCESS: 'All changes are saved',
    },
    ARCHIVE_SELECTED: {
      SUCCESS: 'Campaigns were successfully archived',
      FAIL: 'Unable to archive campaign(s) because no campaign(s) were selected',
    },
    UNARCHIVE_SELECTED: {
      SUCCESS: 'Campaigns were successfully unarchived',
      FAIL: 'Unable to unarchive campaign(s) because no campaign(s) were selected',
    },
    ARCHIVE: { SUCCESS: 'The campaign was successfully archived' },
    UNARCHIVE: { SUCCESS: 'The campaign was successfully unarchived' },
    DELETE: {
      SUCCESS: 'The campaign was successfully deleted',
    },
  },
  VENUE: {
    CREATE: {
      SUCCESS:
        'Venue has been added. This venue will not be available for edit while mapping calculation are being performed.',
    },
    EDIT: {
      SUCCESS: 'Venue has been updated.',
    },
  },
  UPDATE_LOCATION: {
    SUCCESS: 'Update successful: Remember to click Save Changes to save the new location',
    FAIL: {
      ACCOUNT: "Update failed: This location is outside of the account's boundaries.",
      GEOGRAPHY: 'Update failed: This location is outside of the secondary geography.',
      BOTH: "Update failed: This location is outside of the secondary geography and account's boundaries.",
    },
  },
  REPORT: {
    SUCCESS: 'The report will be generated and then emailed to you.',
  },
  YOUTH: {
    CREATE: {
      SUCCESS: 'Youth has been added.',
    },
    EDIT: {
      SUCCESS: 'Youth has been updated.',
    },
  },
  SURVEY: {
    UPLOAD_IMAGES: 'Uploading of photos may take some time, please wait',
  },
};
