import React, { useState, useMemo, useEffect } from 'react';
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import { RadioButtonGroup, BaseSelect } from 'ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { FilterEntity } from '_entities';
import HasHadVisitsFilter from 'pages/old/RetailerList/filters/RetailersBuilderSections/VisitsHistory/component/HasHadVisits';
import { IsMassAccount } from 'modules';
import * as Styled from './styles';

const { setShadowFilter } = FilterEntity.actions;

function VisitsHistory() {
  const {
    filter: { shadowFilters, generalFilters },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const isMassAccount = IsMassAccount();
  const [radioChecks, setRadioChecks] = useState({});
  const [selectValue, setSelectValue] = useState({});
  const [defaultValue, setDefaultValue] = useState({});
  const [defaultRadio, setDefaultRadio] = useState({});
  const ENFORCEMENT_VISIT_HISTORY_TABS_BASE = [
    {
      id: 1,
      field: FILTER_FIELDS.ENFOCEMENT_VIOLATION_FOUND,
      title: 'Violation found',
      options: FILTER_OPTIONS.VIOLATION_FOUND_OPTIONS,
      type: 'checkbox',
    },
  ];

  const EDUCATION_VISIT_HISTORY_TABS_BASE = [
    {
      id: 1,
      field: FILTER_FIELDS.EDUCATION_VIOLATION_FOUND,
      title: 'Violation found',
      options: FILTER_OPTIONS.VIOLATION_FOUND_OPTIONS,
      type: 'checkbox',
    },
  ];

  const ENFORCEMENT_VISIT_HISTORY_TABS = useMemo(() => {
    if (isMassAccount) {
      const massAccountsFilters = [
        {
          id: 2,
          field: FILTER_FIELDS.ENFOCEMENT_VIOLATION_CASES,
          title: 'Violation cases',
          options: FILTER_OPTIONS.VIOLATION_CASES_OPTIONS,
          type: 'checkbox',
        },
        {
          id: 3,
          field: FILTER_FIELDS.ENFORCEMENT_NUMBER_OF_VIOLATION,
          title: 'Number of violations',
          options: FILTER_OPTIONS.VIOLATION_NUMBER_OPTIONS,
          type: 'radio',
        },
        {
          id: 4,
          field: FILTER_FIELDS.ENFORCEMENT_VIOLATION_TYPE,
          title: 'Violation Type',
          options: FILTER_OPTIONS.VIOLATION_TYPE_OPTIONS,
          type: 'multiselect',
        },
        {
          id: 5,
          field: FILTER_FIELDS.ENFORCEMENT_ACTION_TYPE,
          title: 'Action type',
          options: FILTER_OPTIONS.VIOLATION_ACTION_TYPE_OPTIONS,
          type: 'multiselect',
        },
      ];
      const NEW_ENFORCEMENT_VISIT_HISTORY_TABS = ENFORCEMENT_VISIT_HISTORY_TABS_BASE.concat(massAccountsFilters);
      return NEW_ENFORCEMENT_VISIT_HISTORY_TABS;
    }
    return ENFORCEMENT_VISIT_HISTORY_TABS_BASE;
  }, [isMassAccount]);

  const EDUCATION_VISIT_HISTORY_TABS = useMemo(() => {
    if (isMassAccount) {
      const massAccountsFilters = [
        {
          id: 2,
          field: FILTER_FIELDS.EDUCATION_VIOLATION_CASES,
          title: 'Violation cases',
          options: FILTER_OPTIONS.VIOLATION_CASES_OPTIONS,
          type: 'checkbox',
        },
        {
          id: 3,
          field: FILTER_FIELDS.EDUCATION_NUMBER_OF_VIOLATION,
          title: 'Number of violations',
          options: FILTER_OPTIONS.VIOLATION_NUMBER_OPTIONS,
          type: 'radio',
        },
        {
          id: 4,
          field: FILTER_FIELDS.EDUCATION_VIOLATION_TYPE,
          title: 'Violation Type',
          options: FILTER_OPTIONS.VIOLATION_TYPE_OPTIONS,
          type: 'multiselect',
        },
        {
          id: 5,
          field: FILTER_FIELDS.EDUCATION_ACTION_TYPE,
          title: 'Action type',
          options: FILTER_OPTIONS.VIOLATION_ACTION_TYPE_OPTIONS,
          type: 'multiselect',
        },
      ];
      const NEW_EDUCATION_VISIT_HISTORY_TABS = EDUCATION_VISIT_HISTORY_TABS_BASE.concat(massAccountsFilters);
      return NEW_EDUCATION_VISIT_HISTORY_TABS;
    }
    return EDUCATION_VISIT_HISTORY_TABS_BASE;
  }, [isMassAccount]);

  const handleChangeRadio = ({ target: { value } }, field) => {
    setRadioChecks({ ...radioChecks, [field]: value });
    dispatch(setShadowFilter({ ...shadowFilters, [field]: value }));
  };

  const handleChangeSelect = (value, field) => {
    setSelectValue({ ...selectValue, [field]: value });
    dispatch(setShadowFilter({ ...shadowFilters, [field]: value.map((elem) => elem.value) }));
  };

  const getDropDefaultSelectedValues = (optionArray, array) => {
    return optionArray.filter((item) => array?.includes(item.value));
  };

  useEffect(() => {
    const enforcementViolationType = getDropDefaultSelectedValues(
      FILTER_OPTIONS.VIOLATION_TYPE_OPTIONS,
      generalFilters?.enforcement_violation_type,
    );
    const enforcementActionType = getDropDefaultSelectedValues(
      FILTER_OPTIONS.VIOLATION_ACTION_TYPE_OPTIONS,
      generalFilters?.enforcement_action_type,
    );
    const educationViolationType = getDropDefaultSelectedValues(
      FILTER_OPTIONS.VIOLATION_TYPE_OPTIONS,
      generalFilters?.education_violation_type,
    );
    const educationActionType = getDropDefaultSelectedValues(
      FILTER_OPTIONS.VIOLATION_ACTION_TYPE_OPTIONS,
      generalFilters?.education_action_type,
    );
    setDefaultValue({
      enforcement_action_type: enforcementActionType,
      enforcement_violation_type: enforcementViolationType,
      education_violation_type: educationViolationType,
      education_action_type: educationActionType,
    });

    const educationRadio = generalFilters?.education_violation_cases_count;
    const enforcementRadio = generalFilters?.enforcement_violation_cases_count;

    setDefaultRadio({
      education_violation_cases_count: educationRadio,
      enforcement_violation_cases_count: enforcementRadio,
    });
  }, [generalFilters]);

  useEffect(() => {
    const enforcementViolationType = getDropDefaultSelectedValues(
      FILTER_OPTIONS.VIOLATION_TYPE_OPTIONS,
      shadowFilters?.enforcement_violation_type,
    );
    const enforcementActionType = getDropDefaultSelectedValues(
      FILTER_OPTIONS.VIOLATION_ACTION_TYPE_OPTIONS,
      shadowFilters?.enforcement_action_type,
    );
    const educationViolationType = getDropDefaultSelectedValues(
      FILTER_OPTIONS.VIOLATION_TYPE_OPTIONS,
      shadowFilters?.education_violation_type,
    );
    const educationActionType = getDropDefaultSelectedValues(
      FILTER_OPTIONS.VIOLATION_ACTION_TYPE_OPTIONS,
      shadowFilters?.education_action_type,
    );
    setDefaultValue({
      enforcement_action_type: enforcementActionType,
      enforcement_violation_type: enforcementViolationType,
      education_violation_type: educationViolationType,
      education_action_type: educationActionType,
    });

    const educationRadio = shadowFilters?.education_violation_cases_count;
    const enforcementRadio = shadowFilters?.enforcement_violation_cases_count;

    setDefaultRadio({
      education_violation_cases_count: educationRadio,
      enforcement_violation_cases_count: enforcementRadio,
    });
  }, [shadowFilters]);

  const getElement = (title, field, options, type) => {
    switch (type) {
      case 'checkbox':
        return <CheckedList title={title} name={field} options={options} />;
      case 'radio':
        return (
          <RadioButtonGroup
            onChange={(value) => {
              handleChangeRadio(value, field);
            }}
            // to use hasOwnPropetry from object
            // eslint-disable-next-line no-prototype-builtins
            value={radioChecks.hasOwnProperty(field) ? radioChecks[field] : defaultRadio[field]}
            title={title}
            name={field}
            options={options}
            direction="column"
          />
        );
      case 'multiselect':
        return (
          <Styled.SelectWrapper>
            <BaseSelect
              onChange={(value) => {
                handleChangeSelect(value, field);
              }}
              isMulti
              labelText={title}
              name={field}
              options={options}
              // eslint-disable-next-line no-prototype-builtins
              value={selectValue.hasOwnProperty(field) ? selectValue[field] : defaultValue[field]}
            />
          </Styled.SelectWrapper>
        );
      default:
        return null;
    }
  };

  return (
    <Styled.Wrapper>
      <HasHadVisitsFilter />
      <Styled.FiltersTitle>Enforcement visit violations</Styled.FiltersTitle>
      <Styled.FiltersContainer>
        {ENFORCEMENT_VISIT_HISTORY_TABS.map((item) => getElement(item.title, item.field, item.options, item.type))}
      </Styled.FiltersContainer>
      <Styled.FiltersTitle>Education visit violations</Styled.FiltersTitle>
      <Styled.FiltersContainer>
        {EDUCATION_VISIT_HISTORY_TABS.map((item) => getElement(item.title, item.field, item.options, item.type))}
      </Styled.FiltersContainer>
    </Styled.Wrapper>
  );
}

export default VisitsHistory;
