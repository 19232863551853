import { API_URLS } from '_constants';
import * as TYPES from './Audit.types';

export const getAuditList = (params, timeZone) => {
  return {
    types: [TYPES.GET_AUDIT_LOGS, TYPES.GET_AUDIT_LOGS_SUCCESS, TYPES.GET_AUDIT_LOGS_FAIL],
    promise: (client) => client.get(`${API_URLS.auditLogs}?${params}`),
    timeZone,
  };
};

export const getAuditCsv = (params) => {
  return {
    types: [TYPES.GET_AUDIT_CSV, TYPES.GET_AUDIT_CSV_SUCCESS, TYPES.GET_AUDIT_CSV_FAIL],
    promise: (client) => client.get(`${API_URLS.auditCSV}?${params}`),
  };
};

export const postAuditRevert = (id) => {
  return {
    types: [TYPES.POST_AUDIT_REVERT, TYPES.POST_AUDIT_REVERT_SUCCESS, TYPES.POST_AUDIT_REVERT_FAIL],
    promise: (client) => client.post(`${API_URLS.auditLogs}/${id}/revert`),
  };
};

export const setIdRevert = (payload) => ({
  type: TYPES.SET_ID_REVERT,
  payload,
});
