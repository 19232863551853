import { createInitialValues } from 'utils/custom';

export const formNames = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  phone: 'sms_number',
  role: 'role',
  isActive: 'is_active',
  isStaff: 'is_staff',
  isSuperUser: 'is_superuser',
  secGeogs: 'sec_geogs',
  agency: 'agency',
  account: 'accounts_managed',
};

export const errorLabels = {
  [formNames.firstName]: 'first name',
  [formNames.lastName]: 'last name',
  [formNames.role]: 'user role',
  [formNames.isActive]: 'status',
  [formNames.secGeogs]: 'geography',
  [formNames.agency]: 'agency',
};

export const FORM_ACTION_TYPES = {
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
  RESET_DATA: 'RESET_DATA',
  SET_DATA: 'SET_DATA',
};

export const FORM_REQUIRED_FIELDS = [
  formNames.firstName,
  formNames.lastName,
  formNames.email,
  formNames.role,
  formNames.isActive,
  formNames.secGeogs,
  formNames.agency,
];

export const radioButtonDefaultValues = [
  {
    id: 1,
    value: true,
    label: 'Active',
  },
  {
    id: 2,
    value: false,
    label: 'Inactive',
  },
];

export const checkedListDefaultValues = [
  {
    id: 1,
    value: false,
    label: 'Staff',
    name: formNames.isStaff,
  },
  {
    id: 2,
    value: false,
    label: 'Superuser',
    name: formNames.isSuperUser,
  },
];

export const multiSelectsList = [formNames.secGeogs, formNames.agency, formNames.account];

export const getInitialValue = (name) => {
  const findName = (arr, inputName) => arr.includes(inputName) && inputName;

  switch (name) {
    case findName(multiSelectsList, name): {
      return [];
    }
    case formNames.role: {
      return {
        id: 0,
        label: 'Select an option',
        value: null,
        name,
      };
    }
    case formNames.isActive: {
      return true;
    }
    default:
      return '';
  }
};

const defaultUserState = Object.values(formNames).reduce(
  (newObj, value) => ({ ...newObj, [value]: getInitialValue(value) }),
  {},
);

export const formInitialState = {
  data: { attributes: defaultUserState, type: 'User' },
  errors: createInitialValues(formNames, null),
  canBeSubmitted: false,
};

export const textInputs = [
  {
    id: 1,
    name: formNames.firstName,
    title: 'First name',
    required: true,
  },
  {
    id: 2,
    name: formNames.lastName,
    title: 'Last name',
    required: true,
  },
  {
    id: 3,
    name: formNames.email,
    title: 'Email address',
    required: true,
  },
  {
    id: 4,
    name: formNames.phone,
    title: 'Phone',
    required: false,
  },
];

export const PAGE_COLUMNS = 'youth_columns';
