import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Title = styled.div`
  color: rgb(13, 13, 13);
  font-weight: 600;
  max-width: 400px;
  font-size: 26px;
  margin: 0 0 10px;
`;

export const Description = styled.div`
  margin: 0 0 10px;
`;

export const DropWrapper = styled.div`
  min-width: 320px;
`;

export const Footer = styled.div`
  display: flex;
  button {
    margin-top: 25px;
    min-width: 120px;
    &:last-child {
      margin-left: 10px;
    }
  }
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 35px 56px;
  max-width: 440px;
  width: 100%;

  @media ${DEVICES.tablet} {
    padding: 10px 15px;
  }
`;
