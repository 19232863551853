import { IsMassAccount, IsVaAccount, HasAccess } from 'modules';
import { PERMISSIONS } from '_constants';
import { MASS_CHIPS, BASE_CHIPS, VA_CHIPS, RESIDENTIAL_CHIPS } from './constants';

const getFilterMappingObj = (filterType) => {
  const isMassAccount = IsMassAccount();
  const isVaAccount = IsVaAccount();
  const hasResidentialModule = HasAccess(PERMISSIONS.RESIDENTIAL_LOCATION);

  let RESULT_CHIPS = { ...BASE_CHIPS };

  if (isMassAccount) RESULT_CHIPS = { ...RESULT_CHIPS, ...MASS_CHIPS(filterType) };
  if (isVaAccount) RESULT_CHIPS = { ...RESULT_CHIPS, ...VA_CHIPS() };
  if (hasResidentialModule) RESULT_CHIPS = { ...RESULT_CHIPS, ...RESIDENTIAL_CHIPS() };

  return RESULT_CHIPS;
};

export default getFilterMappingObj;
