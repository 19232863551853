import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import { isEmpty } from 'lodash';
import { splitTableIntoParts } from 'utils/custom';
import { PERMISSIONS } from '_constants';
import ConfirmSidebar from 'pages/old/Mergers/components/ConfirmSidebar';
import { AccessControl } from 'modules';
import * as Styled from './styles';
import NoDataComponent from './components/NoData';

function BaseTable({
  data,
  columns,
  defaultPageSize,
  loading,
  isEdit,
  subColumns,
  subData,
  expanded,
  setExpanded,
  handleOpenMergeModal,
  isTablet,
  pdfNotRequered,
  getTrPropsFunctions,
}) {
  const toggleRowExpansion = (event, rowIndex) => {
    if (event.target.classList.contains('rt-expander') || event.target.classList.contains('rt-expandable')) {
      setExpanded((prevExpanded) => ({
        ...prevExpanded,
        [rowIndex]: prevExpanded[rowIndex] ? false : {},
      }));
    }
  };

  return (
    <Styled.Wrapper className="main-wrapper" noData={!data.length} isEdit={isEdit}>
      {data.length ? (
        // In order to generate pdf on front-end, we should split the table into smaller tables, max 50 row per part, so the pdf will look nice and generate 10 times quicker.
        <div className="table-wrapper">
          {splitTableIntoParts(data, columns, loading, defaultPageSize, pdfNotRequered).map((props, index) => {
            return (
              <div style={{ position: 'relative' }}>
                <ReactTable
                  key={index}
                  {...props}
                  showPagination={false}
                  SubComponent={
                    !isEmpty(subColumns) &&
                    !isTablet &&
                    pdfNotRequered &&
                    function (row) {
                      return (
                        <>
                          <Styled.TableWrapper>
                            <Styled.Table>
                              <thead>
                                <Styled.TableRow>
                                  {subColumns.map((column) => (
                                    <Styled.TableHeader key={column.accessor}>{column.Header()}</Styled.TableHeader>
                                  ))}
                                </Styled.TableRow>
                              </thead>
                              <Styled.TableBody>
                                <Styled.TableRow>
                                  {subColumns.map((column) => {
                                    return (
                                      <Styled.TableCell key={column.accessor}>
                                        {column.Cell({
                                          value: subData[row.original.rowIndex][0][column.accessor],
                                          id: subData[row.original.rowIndex][0].id,
                                        })}
                                      </Styled.TableCell>
                                    );
                                  })}
                                </Styled.TableRow>
                                <Styled.TableRow>
                                  {subColumns.map((column) => {
                                    return (
                                      <Styled.TableCell key={column.accessor}>
                                        {column.Cell({
                                          value: subData[row.original.rowIndex][1][column.accessor],
                                          id: subData[row.original.rowIndex][1].id,
                                        })}
                                      </Styled.TableCell>
                                    );
                                  })}
                                </Styled.TableRow>
                              </Styled.TableBody>
                            </Styled.Table>
                            <AccessControl permission={PERMISSIONS.VIEW_SIMILARSTORES}>
                              <ConfirmSidebar
                                handleOpenMergeModal={handleOpenMergeModal}
                                mergersItems={subData[row.original.rowIndex]}
                              />
                            </AccessControl>
                          </Styled.TableWrapper>
                        </>
                      );
                    }
                  }
                  getTrProps={(state, rowInfo) => {
                    if (isEmpty(getTrPropsFunctions)) return {};
                    return {
                      ...getTrPropsFunctions?.getTrProps(state, rowInfo),
                      onMouseEnter: (event) => getTrPropsFunctions?.onMouseEnter(event, rowInfo),
                      onMouseLeave: () => getTrPropsFunctions?.onMouseLeave(),
                    };
                  }}
                  getTdProps={(state, rowInfo) => ({
                    onClick: (event) => toggleRowExpansion(event, rowInfo.original.rowIndex),
                  })}
                  expanded={expanded}
                  onExpandedChange={(newExpanded) => setExpanded(newExpanded)}
                />
                {!isEmpty(getTrPropsFunctions?.tooltipContent) && (
                  <div
                    style={{
                      ...getTrPropsFunctions.tooltipStyle,
                      backgroundColor: 'var(--soft-dark)',
                      color: '#fff',
                      padding: '5px',
                      borderRadius: '5px',
                      zIndex: 9999,
                    }}
                  >
                    {getTrPropsFunctions.tooltipContent}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <NoDataComponent />
      )}
    </Styled.Wrapper>
  );
}

BaseTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPageSize: PropTypes.number,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  subColumns: PropTypes.array,
  subData: PropTypes.array,
  expanded: PropTypes.object,
  setExpanded: PropTypes.func,
  handleOpenMergeModal: PropTypes.func,
  isTablet: PropTypes.bool,
  pdfNotRequered: PropTypes.bool,
  getTrPropsFunctions: PropTypes.object,
};

BaseTable.defaultProps = {
  defaultPageSize: 20,
  loading: false,
  isEdit: false,
  subColumns: [],
  subData: [],
  expanded: {},
  setExpanded: () => {},
  handleOpenMergeModal: () => {},
  isTablet: false,
  pdfNotRequered: false,
  getTrPropsFunctions: {},
};

export default memo(BaseTable);
