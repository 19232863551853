import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PageContainer } from 'modules';
import { URLS } from '_constants';
import { AuthEntity } from '_entities';
import * as Styled from './styles';

const { getIsValidLink } = AuthEntity.selectors;

function ResetPassword() {
  const isValidLinkMessage = useSelector(getIsValidLink);
  const isExpired = useMemo(() => isValidLinkMessage, [isValidLinkMessage]);

  const descriptionText = isExpired ? (
    <>
      It's been 4 days since you received the reset password link. Try to reset it again by clicking:
      <Link to={URLS.forgotPassword}>Reset password</Link>
    </>
  ) : (
    "You could have received several set password links. Make sure you're using the last one sent."
  );

  return (
    <PageContainer>
      <Styled.Wrapper>
        <Styled.TextContainer>
          <Styled.Title>The reset password link is no longer active.</Styled.Title>
          <Styled.Description>{descriptionText}</Styled.Description>
        </Styled.TextContainer>
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default ResetPassword;
