export default {
  dashboardSuspensions: {
    prop: 'retailer__name',
    order: 1,
  },
  dashboardLicenses: {
    prop: 'end_date',
    susp: 'active_susp_end',
    order: 1,
  },
  dashboardEnforcements: {
    prop: 'inspection_date',
    order: -1,
  },
  reportLocationRetailers: {
    prop: 'name',
    order: 1,
  },
  reportLocationEnforcements: {
    prop: 'license_number',
    order: 1,
  },
  reportLocationSuspension: {
    prop: 'retailer__name',
    order: 1,
  },
  reportLocationLicenses: {
    prop: 'inspection_date',
    order: 1,
  },
  reportAlternate: {
    prop: 'zipcode',
    order: 1,
  },
  reportJurisdictionLicenses: {
    prop: 'retailer__name',
    order: 1,
  },
  reportJurisdictionEnforcements: {
    prop: 'retailer__name',
    order: 1,
  },
  retailerViewLicenses: {
    prop: 'license_number',
    order: 1,
  },
  retailerViewEnforcements: {
    prop: 'visit_time',
    order: -1,
  },
  retailerViewSuspensions: {
    prop: 'agency__name',
    order: 1,
  },
  users: {
    prop: 'first_name',
    order: 1,
  },
  agencies: {
    prop: 'name',
    order: 1,
  },
  retailers: {
    prop: 'name',
    order: 1,
  },
  licenses: {
    prop: 'license_number',
    order: 1,
  },
  enforcements: {
    prop: 'inspection_date',
    order: -1,
  },
  audits: {
    prop: 'user_id',
    order: 1,
  },
  historicalLicenses: {
    prop: 'license_number',
    order: 1,
  },
};
