import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { Button } from '../styles/button.style';

const WrapperLabel = styled.label`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 8px 0 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  ${({ isActive }) =>
    !isActive &&
    css`
      pointer-events: none;
    `};
`;

const SpanIcon = styled.span`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  top: 0;
  display: inline-block;
  line-height: 1;
  vertical-align: sub;
  outline: none;
  cursor: pointer;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--danger);
    border-radius: 50%;
    visibility: hidden;
    -webkit-animation: antRadioEffect 0.36s ease-in-out;
    animation: antRadioEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    content: '';
    ${({ isActive }) =>
      !isActive &&
      css`
        color: var(--grey-border);
        border: var(--grey);
        background: var(--soft-grey);
        pointer-events: none;
      `};
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    display: inline;

    ${({ isActive }) =>
      !isActive &&
      css`
        color: var(--grey-border);
        border: var(--grey);
        background: var(--soft-grey);
        pointer-events: none;
      `};
  }

  input[type='radio'],
  input[type='checkbox'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    ${({ isActive }) =>
      !isActive &&
      css`
        color: var(--grey-border);
        border: var(--grey);
        background: var(--soft-grey);
        pointer-events: none;
      `};
  }

  span {
    word-break: break-word;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: (--white);
    border-color: var(--gainsboro);
    border-style: solid;
    border-width: 1px;
    border-radius: 100px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-color: ${({ checked }) => (checked ? 'var(--danger)' : 'var(--dark)')};

    ${({ isActive }) =>
      !isActive &&
      css`
        color: var(--grey-border);
        border: var(--grey);
        background: var(--soft-grey);
        pointer-events: none;
      `};

    &:after {
      ${({ isActive }) =>
        !isActive &&
        css`
          color: var(--grey-border);
          border: var(--grey);
          background: var(--soft-grey);
          pointer-events: none;
          background-color: var(--soft-grey);
        `};
      ${({ checked }) =>
        checked &&
        css`
          position: absolute;
          top: 3px;
          left: 3px;
          display: table;
          width: 10px;
          height: 10px;
          background-color: var(--danger);
          border-top: 0;
          border-left: 0;
          border-radius: 8px;
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
          -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          content: ' ';
          webkit-transform: scale(1);
          transform: scale(1);
          opacity: 1;
        `}
      ${(props) =>
        props.checked &&
        !props.isActive &&
        css`
          color: var(--grey-border);
          border: var(--grey);
          background: var(--soft-grey);
          pointer-events: none;
          width: 0px;
          height: 0px;
        `}
    }
  }
`;

const SpanTitle = styled.span`
  padding-left: 8px;
  ${({ isMerger }) =>
    isMerger &&
    css`
      max-width: 424px;
    `}
  word-wrap: break-word;
  word-break: break-word;
  display: flex;
  align-items: center;
  transform: translateY(-5px);

  &:focus {
    outline: none;
  }
`;

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  position: absolute;
  left: 115px;
  top: -5px;

  @media ${DEVICES.tablet} {
    top: -3px;
  }

  &:focus {
    outline: none;
  }
`;

const ComponentEndWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  position: absolute;
  left: 10px;
  top: -5px;

  @media ${DEVICES.tablet} {
    top: -3px;
  }

  &:focus {
    outline: none;
  }
`;

const StyleButtonReset = styled(Button)`
  margin: 0 !important;
  margin-left: 20px !important;
  display: inline-block !important;
  width: 65px;

  @media ${DEVICES.laptopL} {
    display: block !important;
    margin-left: 0px !important;
  }
`;

const WrapperRadioGroup = styled.div.attrs(() => ({ ariaRequired: true, role: 'radiogroup' }))`
  display: inline-block;
  margin-left: 0px !important;
  margin-bottom: 10px;
  button {
    display: inline-block;
  }

  label {
    margin: 0 0 15px 0;
  }

  @media ${DEVICES.laptopL} {
    display: block !important;
  }
  @media ${DEVICES.tablet} {
    ${({ isMerger }) =>
      isMerger &&
      css`
        display: flex !important;
        margin-bottom: 0;
      `};
  }
`;

const WrapperRadio = styled.div.attrs(() => ({ ariaRequired: true, role: 'radio' }))`
  position: relative;

  &:focus {
    ${ComponentWrapper} {
      top: 6px;
    }
  }
`;

const Span = styled.span`
  height: 1px;
  color: transparent;
  padding: 0;
  margin: 0;
  display: inline;
  background: transparent;
  overflow: hidden;
`;

const Label = styled.span`
  .BaseSelect__control {
    width: 115px !important;
  }
`;

const LabelInner = styled.div`
  display: flex;
  flex-flow: row nowrap;

  :first-of-type {
    position: relative;
    z-index: 5;
  }
`;

const LabelText = styled.label`
  margin: 0 8px 0 0;
  padding: 0;
  color: var(--black);
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
`;

const ComponentWrap = styled.span`
  position: relative;
  z-index: 4;
`;

export {
  WrapperLabel,
  SpanIcon,
  SpanTitle,
  StyleButtonReset,
  WrapperRadioGroup,
  Span,
  ComponentWrapper,
  ComponentEndWrapper,
  WrapperRadio,
  Label,
  LabelInner,
  ComponentWrap,
  LabelText,
};
