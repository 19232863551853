import * as actions from './Venue.actions';
import reducer from './Venue.reducer';
import * as types from './Venue.types';
import * as selectors from './Venue.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
