import { API_URLS } from '_constants';
import * as TYPES from './Report.types';

export const getCalcPoly = () => ({
  types: [TYPES.GET_CALC_POLY, TYPES.GET_CALC_POLY_SUCCESS, TYPES.GET_CALC_POLY_FAIL],
  promise: (client) => client.get(`${API_URLS.calcPoly}?exclude_empty_retailers=true&exclude_empty_venues=true`),
});

export const getAreaReport = (params) => ({
  types: [TYPES.GET_AREA_REPORT, TYPES.GET_AREA_REPORT_SUCCESS, TYPES.GET_AREA_REPORT_FAIL],
  promise: (client) => client.get(`${API_URLS.areaReport}?${params}`),
});

export const getEnforcementReport = (params) => ({
  types: [TYPES.GET_ENFORCEMENT_REPORT, TYPES.GET_ENFORCEMENT_REPORT_SUCCESS, TYPES.GET_ENFORCEMENT_REPORT_FAIL],
  promise: (client) => client.get(`${API_URLS.enforcementReport}?${params}`),
});

export const getEducationReport = (params) => ({
  types: [TYPES.GET_EDUCATION_REPORT, TYPES.GET_EDUCATION_REPORT_SUCCESS, TYPES.GET_EDUCATION_REPORT_FAIL],
  promise: (client) => client.get(`${API_URLS.educationReport}?${params}`),
});

export const resetCalcPoly = () => ({
  type: TYPES.RESET_CALC_POLY,
});
