import React from 'react';
import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import { CheckedList } from 'modules/Modals/old/FilterModal/components/CheckboxGroup';
import DatePickerGroup from 'modules/Modals/old/FilterModal/components/DatePickerGroup';
import * as Styled from './styles';

const FIELDS = [
  {
    id: 1,
    label: 'Added to database',
    name: FILTER_FIELDS.CREATION_DATE,
  },
  {
    id: 2,
    label: 'Last updated',
    name: FILTER_FIELDS.UPDATE_DATE,
  },
  {
    id: 3,
    label: 'Ceased business',
    name: FILTER_FIELDS.CLOSED_DATE,
  },
  {
    id: 4,
    label: 'Store Merger relationship was defined',
    name: FILTER_FIELDS.MERGE_DATE,
  },
];

export const RetailersAdvancedFilter = () => (
  <Styled.Wrapper>
    <FormGroup>
      <Styled.List>
        {FIELDS.map((field) => (
          <Styled.ListItem key={field.id}>
            <DatePickerGroup name={field.name} title={field.label} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </FormGroup>
    <FormGroup>
      <CheckedList name={FILTER_FIELDS.G_PRECISION_GOOD} options={FILTER_OPTIONS.RETAILER_LOCATION_ACCURACY_OPTIONS} />
      <CheckedList name={FILTER_FIELDS.ADDRESS_VALIDATED} options={FILTER_OPTIONS.RETAILER_ADDRESS_VALIDATED_OPTIONS} />
    </FormGroup>
  </Styled.Wrapper>
);
