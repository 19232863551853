import React from 'react';
import { Img } from 'ui-kit';
import { NO_DATA } from 'assets/icons';
import * as Styled from './styles';

const NoDataComponent = () => (
  <div className="react-table__nodata-wrapper">
    <div className="react-table__nodata">
      <Img src={NO_DATA} alt="icon - No data" />
      <Styled.TitleLabel> No data</Styled.TitleLabel>
    </div>
  </div>
);

export default NoDataComponent;
