import styled from 'styled-components';

export const Wrapper = styled.div``;
/* eslint-enable */

export const Button = styled.button.attrs(() => ({
  ariaRequired: true,
}))`
  background: transparent;
  border: none;
  cursor: pointer;

  .sr-only {
    display: none;
  }

  ${({ disabled }) => disabled && `pointer-events: none;`}
`;
