export const getRetailerNameLink = ({ retailerName, closeDate, isResidential }) => {
  if (closeDate && isResidential) {
    return `${retailerName} (closed: ${closeDate}, residential location)`;
  }
  if (closeDate && !isResidential) {
    return `${retailerName} (closed: ${closeDate})`;
  }
  if (!closeDate && isResidential) {
    return `${retailerName} (residential location)`;
  }
  return retailerName;
};
