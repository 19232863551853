import FILTER_FIELDS from '_constants/old/filters/FILTER_FIELDS';
import FILTER_OPTIONS from '_constants/old/filters/FILTER_OPTIONS';
import { CHIPS_TYPES } from '../../components/ChipsList/constants';

export default {
  [FILTER_FIELDS.Q_MHHINC]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.INCOME_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Income: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_PCTYOUTH]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.UNDER_AGE_18_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Under Age 18: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_PCTURBAN]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.URBAN_RURAL_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Urban/Rural: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_PCTHISP]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.ETHNICITY_HISPANIC_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Ethnicity/Hispanic: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_PCTBLACK]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.AFRICAN_BLACK_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Race, African/Black: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_PCTAMERIND]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.NATIVE_AMERICAN_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Race, Native American: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_PCTASHAWPI]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.ASIAN_PACIFIC_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Race, Asian/Pacific: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_PCTWHITE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.RACE_WHITE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Race, White: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_PCTOTHERRACE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.RACE_OTHER_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Race, Other: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_EDATTAIN]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.EDUCATION_ACHIEVEMENT.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Educational Achievement: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.Q_POPDENS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.POPULATION_DENSITY.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Population Density: ${label}` }),
      {},
    ),
  },
};
