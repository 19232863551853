import styled from 'styled-components';
import { OptionsWrapper } from 'ui-kit/RadioButton/styles';

export const Wrapper = styled.div`
  padding: 10px 20px;
  font-size: 14px;
`;

export const FiltersContainer = styled.div`
  margin-top: 25px;
  ${OptionsWrapper} {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
`;

export const FiltersTitle = styled.span`
  font-size: 16px;
  margin-top: 25px;
  display: block;
`;

export const SelectWrapper = styled.div`
  margin-top: 20px;
`;
