/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
/* eslint-disable */

import { ca } from 'date-fns/locale';
import { fetchConstructor } from '../api/api';
import apiData from '../api/config';
import { initArrayEnforcement, initialValuesFields } from './initalData';
import {
  retailerData,
  retailerDataStartLoad,
  enforcementModal,
  enforcementModalClear,
  retailerSuspension,
  retailerEnforcement,
  retailerLicense,
  matchedCities,
  matchedNames,
  suspensionSelectedAgencyModal,
  suspensionSelectedStartModal,
  suspensionSelectedEndModal,
  enforcementForms,
  setStatusMessageRetailerCreated,
  setRetailerStatusMessage,
  checkingDuplicate,
  setStatusMessageLocation,
  setErrorLocation,
  setRetailerLicenses,
  setRequiredField,
  setSuspensionChanges,
  setSuspensionAdded,
  setSuspensionDeleted,
  isData,
} from './retailer.action';
import { retailersTableData, retailerDelete } from '../Retailers/retailers.action';
import processQSParams from 'utils/old/processQSParams';
import parseRetailerViewEnforcements from 'utils/old/parseTableData/parseRetailerViewEnforcements';
import getSimpleArray from 'utils/old/getSimpleArray';

const getRetailerData = async (dispatch, history, id, setIsLoading = () => {}) => {
  setIsLoading(true);
  dispatch(retailerDataStartLoad());
  dispatch(isData(false));

  const data =
    id &&
    (await fetchConstructor(
      'get',
      `${apiData.getRetailerData}/${id}?include=modified_by,similar_stores,historic,new_retailer,aggregationpolygon_set,non_duplicate_stores`,
    ));

  if (data?.status === 401) {
    setIsLoading(false);
    history.push('/error-page');
  }
  if (data?.status === 404) {
    setIsLoading(false);
    history.push('/error-page');
    dispatch(retailerData({ data: {}, included: [] }));
  }
  if (data?.status === 200) {
    dispatch(retailerData({ data: data.data.data, included: data.data.included }));
    dispatch(isData(true));
    setIsLoading(false);
    return data.data.data;
  }
};

const getRetailerDataPure = async (id) => {
  const token = sessionStorage.getItem('token');

  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/vnd.api+json',
  };
  const data = id && (await fetchConstructor('get', `${apiData.getRetailerData}/${id}`, headers));
  if (data?.status === 404) {
    return undefined;
  }
  if (data?.status === 200) {
    return data.data.data;
  }
  return undefined;
};

const getCities = async (dispatch, history, city) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };

    const data = await fetchConstructor('get', `${apiData.cities}?name=${city}`, headers);

    dispatch(matchedCities(getSimpleArray(data.data)));
  } catch (e) {
    history.push('/login');
  }
};

const getNames = async (dispatch, history, name) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };

    const data = await fetchConstructor('get', `${apiData.names}?name=${name}`, headers);

    dispatch(matchedNames(data.data));
  } catch (e) {
    history.push('/login');
  }
};

const addNewRetailer = async (
  dispatch,
  history,
  payload,
  setIsLoading,
  setEdited = () => {},
  getToast = () => {},
  getToastError = () => {},
  setShowInvalidAdress,
  getToasWithMessage,
  setShowModalAttention,
) => {
  const token = sessionStorage.getItem('token');
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/vnd.api+json',
  };
  setIsLoading(true);
  setShowInvalidAdress(false);
  const data = await fetchConstructor('post', apiData.addNewRetailer, headers, payload);

  if (data.status === 200 || data.status === 201) {
    getToast();
    dispatch(setStatusMessageRetailerCreated(true));
    setIsLoading(false);
    setEdited(false);

    history.push(`/retailers/${data.data.data.id}`);
    setTimeout(() => {
      dispatch(setStatusMessageRetailerCreated(false));
    }, 5000);
  }
  if (data.status === 400) {
    const dataError = data && data.data.errors[0].detail.split(':');
    const duplicateError = 'Submission name and address matched existing retailer with ID';
    if (data && dataError[0] === duplicateError) {
      dispatch(checkingDuplicate(true));
      setShowModalAttention(true);
      setIsLoading(false);
    }
  }
  if ([400, 500, 404].includes(Number(data.status))) {
    Number(data.status) === 400 &&
      data.data.errors[0].detail === 'Cannot create Retailer outside of your secondary geographies' &&
      getToastError();
    data.data.errors[0].detail === 'Address validation is not passed' && setShowInvalidAdress(true);
    (data.data.errors[0].code === 'google_validation_failed' ||
      data.data.errors[0].detail === 'Cannot create Retailer with an address outside of CA') &&
      getToasWithMessage(data.data.errors[0].detail);
    setIsLoading(false);
  }
};

const checkDuplicate = async (dispatch, history, payload, setIsLoading) => {
  const token = sessionStorage.getItem('token');
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/vnd.api+json',
  };
  const data = await fetchConstructor('post', apiData.retailerDuplicate, headers, payload);

  if (data.status === 400) {
    const dataError = data && data.data.errors[0].detail.split(':');
    const duplicateError = 'Submission name and address matched existing retailer with ID';
    if (data && dataError[0] === duplicateError) {
      dispatch(checkingDuplicate(true));
    }
    if (data && dataError[0] !== duplicateError) {
      dispatch(
        setRequiredField(
          data.data.errors.map((item) => ({
            key: item.source.pointer.split('/')[item.source.pointer.split('/').length - 1],
            error: true,
            message: item.detail,
          })),
        ),
      );
    }
  } else if (data.status === 200 || data.status === 201) {
    dispatch(checkingDuplicate(false));
  }
};

const createRetailerDuplicate = async (dispatch, token, history, id, payload, setIsLoading) => {
  try {
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };

    setIsLoading(true);
    const data = await fetchConstructor('post', apiData.retailerDuplicate, headers, payload);

    dispatch(setStatusMessageRetailerCreated(true));

    history.push(`/retailers/${data.data.data.id}`);
  } catch (e) {
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};

const deleteRetailer = async (dispatch, history, id, setIsLoading = () => {}, getToast = () => {}) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };

    setIsLoading(true);

    const data = await fetchConstructor('delete', `${apiData.deleteRetailer}/${id}`, headers);
    getToast();
    history.push(`/retailers`);
    dispatch(retailerDelete(true));
  } catch (e) {
    console.log(e);
    history.push('/login');
  } finally {
    setIsLoading(false);
  }
};

const updateRetailer = async (
  dispatch,
  history,
  id,
  payload,
  setIsLoading = () => {},
  updateRetailerData,
  retailer,
  shouldPush = true,
  setEdited = () => {},
  getToast = () => {},
  getToastError = () => {},
  setShowInvalidAdress = () => {},
  getToasWithMessage,
) => {
  const token = sessionStorage.getItem('token');
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/vnd.api+json',
  };
  setShowInvalidAdress(false);
  setIsLoading(true);
  const data = await fetchConstructor('patch', `${apiData.updateRetailer}/${id}`, headers, payload);

  if (data.status === 200 || data.status === 201) {
    shouldPush && dispatch(setRetailerStatusMessage(true));
    getToast();
    getRetailerData(dispatch, history, id);
    setIsLoading(false);
    setEdited(false);

    shouldPush && history.push(`/retailers/${data.data.data.id}`);

    setTimeout(() => {
      dispatch(setRetailerStatusMessage(false));
    }, 5000);
  }

  if ([400, 500, 404].includes(Number(data.status))) {
    Number(data.status) === 400 &&
      data.data.errors[0].detail === 'Cannot update Retailer outside of your secondary geographies' &&
      getToastError();
    data.data.errors[0].detail === 'Address validation is not passed' && setShowInvalidAdress(true);
    (data.data.errors[0].code === 'google_validation_failed' ||
      data.data.errors[0].detail === 'Cannot update Retailer using an address outside of CA') &&
      getToasWithMessage(data.data.errors[0].detail);
    setIsLoading(false);
  }
};

const searchRetailer = async (dispatch, token, history, searchValue, setIsLoading) => {
  try {
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };
    setIsLoading(true);
    const data = await fetchConstructor('get', `${apiData.searchRetailer}?search=${searchValue}`, headers);

    history.push('/login');

    dispatch(retailersTableData(data.data));
  } catch (e) {
    console.log(e);
    history.push('/login');
  } finally {
    setIsLoading(false);
  }
};

const saveEnforcementModal = (dispatch, history, formType, agency, id, formTypeId, retailer) => {
  dispatch(
    enforcementModal({
      formType,
      agency,
      id,
      formTypeId,
      retailer,
    }),
  );

  const arrData = initArrayEnforcement.map((item) => ({
    name: item,
    isChecked: false,
    disposition: null,
    penalty: null,
    productType: [],
    additionalFields: initialValuesFields,
  }));

  dispatch(
    enforcementModalClear({
      inspectionDate: null,
      inspectionType: arrData,
      isChangsSaved: false,
      counter: 1,
    }),
  );
  sessionStorage.removeItem('enforcement-data');
  history.push('/enforcement/survey/1');
};

const setSuspensionAgency = (dispatch, selectedAgency) => {
  dispatch(suspensionSelectedAgencyModal(selectedAgency));
};

const setSuspensionStart = (dispatch, startDate) => {
  dispatch(suspensionSelectedStartModal(startDate));
};

const setSuspensionEnd = (dispatch, endDate) => {
  dispatch(suspensionSelectedEndModal(endDate));
};

const addSuspension = async (dispatch, id, payload, setIsSuspensionFormReady, history, getToast) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };

    setIsSuspensionFormReady(false);

    const data = await fetchConstructor('post', `${apiData.getRetailerData}/${id}/suspensions/`, headers, payload);

    getToast();
    dispatch(setSuspensionAdded(true));

    history.push(`/retailers/${id}`);
  } catch (e) {
    console.log(e);
  }
};

const editSuspension = async (
  dispatch,
  payload,
  id,
  suspensionId,
  setIsSuspensionFormReady,
  setIsSuspensionEditFormReady,
  history,
  getToast = () => {},
) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };
    payload.data.id = suspensionId;
    setIsSuspensionFormReady(false);
    setIsSuspensionEditFormReady(false);

    const data = await fetchConstructor('patch', `${apiData.suspensions}/${suspensionId}`, headers, payload);

    getToast();
    dispatch(setSuspensionChanges(true));
    history.push(`/retailers/${id}`);
  } catch (e) {
    console.log(e);
  }
};

const clearSuspensionChanges = async (dispatch) => {
  dispatch(setSuspensionChanges(false));
};

const deleteSuspension = async (dispatch, history, id, suspensionId, getToast) => {
  try {
    const token = sessionStorage.getItem('token');
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/vnd.api+json',
    };

    const data = await fetchConstructor('delete', `${apiData.suspensions}/${suspensionId}`, headers);
    getToast();
    dispatch(setSuspensionDeleted(true));
    history.push(`/retailers/${id}`);
  } catch (e) {
    console.log(e);
    console.log('erro');
    //    history.push("/login");
  } finally {
    setTimeout(() => {
      dispatch(setSuspensionDeleted(false));
    }, 5000);
  }
};

const getRetailerEnforcement = async (
  dispatch,
  { id, page, pageSize, sortOrder, sortBy, ...qsParams },
  setIsLoading = () => {},
  userRoleName,
  timeZone,
) => {
  try {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };

    const params = processQSParams({
      page,
      perPage: pageSize,
      sortOrder,
      sortBy,
      ...qsParams,
    });

    const response = await fetchConstructor(
      'get',
      `${apiData.retailerVisits}?retailer=${id}&include=campaign,user&${params}`,
      headers,
    );
    const { data, meta, included } = response.data;
    const newData = parseRetailerViewEnforcements(data, included, timeZone);

    dispatch(
      retailerEnforcement({
        data: newData,
        pagination: meta.pagination,
      }),
    );
    return data?.data?.data;
  } catch (e) {
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};

const getRetailerLicense = async (dispatch, token, history, id, setIsLoading) => {
  try {
    const headers = { Authorization: `Token ${token}` };
    const data = await fetchConstructor('get', `${apiData.getRetailerData}/${id}/licenses`, headers);

    dispatch(
      retailerLicense({
        data: data.data.data,
        meta: data.data.meta,
        links: data.data.links,
      }),
    );
  } catch (e) {
    console.log(e);
  } finally {
    history.push('/login');
    setIsLoading(false);
  }
};

const enforcementDetails = async (dispatch, token, id) => {
  try {
    const headers = { Authorization: `Token ${token}` };
    const response = await fetchConstructor('get', `${apiData.enforcementTableData}/${id}`, headers);
  } catch (e) {
    console.log(e);
  }
};

const getEnforcementForms = async (dispatch, token, history) => {
  try {
    const headers = { Authorization: `Token ${token}` };
    const data = await fetchConstructor('get', apiData.forms, headers);

    dispatch(enforcementForms(data.data.data));
  } catch (e) {
    console.log(e);
  }
};

const updateGEOLocation = async (
  dispatch,
  history,
  id,
  payload,
  setShowEditLocationButtons = () => {},
  getToast = () => {},
  getToastError = () => {},
) => {
  const token = sessionStorage.getItem('token');
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/vnd.api+json',
  };

  const data = await fetchConstructor('patch', `${apiData.updateRetailer}/${id}/update_location`, headers, payload);

  if (data.status === 401 || data.status === 400) {
    dispatch(setErrorLocation(true));
    getToastError();
    setShowEditLocationButtons(false);
    setTimeout(() => {
      dispatch(setErrorLocation(false));
    }, 5000);
  } else if (data.status === 200 || data.status === 201) {
    getToast();
    dispatch(setStatusMessageLocation(true));
    setTimeout(() => {
      dispatch(setStatusMessageLocation(false));
    }, 5000);
  }
};

export {
  getRetailerData,
  addNewRetailer,
  updateRetailer,
  deleteRetailer,
  saveEnforcementModal,
  // getRetailerSuspension,
  getRetailerEnforcement,
  getRetailerLicense,
  getCities,
  searchRetailer,
  getNames,
  setSuspensionAgency,
  setSuspensionStart,
  setSuspensionEnd,
  addSuspension,
  enforcementDetails,
  getEnforcementForms,
  checkDuplicate,
  createRetailerDuplicate,
  updateGEOLocation,
  // getRetailerLicenses,
  editSuspension,
  deleteSuspension,
  clearSuspensionChanges,
  getRetailerDataPure,
};
