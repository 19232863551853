/**
 * Validate if value is object
 * @param  {} value
 * @param {} name
 * @returns {string}
 */
const isDropValid = (value, name) => {
  if (typeof value === 'object') {
    return false;
  }
  return `The ${name} is required`;
};

export default isDropValid;
