import { API_URLS, REQUEST_HEADERS } from '_constants';
import * as TYPES from './User.types';

export const getUserList = (params) => ({
  types: [TYPES.GET_USER_LIST, TYPES.GET_USER_LIST_SUCCESS, TYPES.GET_USER_LIST_FAIL],
  promise: (client) =>
    client.get(
      `${API_URLS.users}?${params}&fields%5BUser%5D=first_name,last_name,email,account,role,agencies,is_active&include=account,role,agencies`,
    ),
});

export const getRoleList = () => ({
  types: [TYPES.GET_USER_ROLE, TYPES.GET_USER_ROLE_SUCCESS, TYPES.GET_USER_ROLE_FAIL],
  promise: (client) => client.get(API_URLS.roles),
});

export const getAgenciesList = () => ({
  types: [TYPES.GET_USER_AGENCIES, TYPES.GET_USER_AGENCIES_SUCCESS, TYPES.GET_USER_AGENCIES_FAIL],
  promise: (client) => client.get(API_URLS.agencies),
});

export const getSecGeoList = () => ({
  types: [TYPES.GET_SEC_GEO, TYPES.GET_SEC_GEO_SUCCESS, TYPES.GET_SEC_GEO_FAIL],
  promise: (client) => client.get(API_URLS.secGeogs),
});

export const getAccountsList = () => ({
  types: [TYPES.GET_USER_ACCOUNTS, TYPES.GET_USER_ACCOUNTS_SUCCESS, TYPES.GET_USER_ACCOUNTS_FAIL],
  promise: (client) => client.get(API_URLS.accounts),
});

export const addNewUser = (data) => ({
  types: [TYPES.ADD_USER_ACCOUNT, TYPES.ADD_USER_ACCOUNT_SUCCESS, TYPES.ADD_USER_ACCOUNT_FAIL],
  promise: (client) => client.post(API_URLS.users, data, { headers: { ...REQUEST_HEADERS } }),
});

export const editUserInfo = (id, data) => ({
  types: [TYPES.EDIT_USER_INFO, TYPES.EDIT_USER_INFO_SUCCESS, TYPES.EDIT_USER_INFO_FAIL],
  promise: (client) => client.patch(`${API_URLS.users}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
});

export const getUserInfo = (id, timeZone) => ({
  types: [TYPES.GET_USER_INFO, TYPES.GET_USER_INFO_SUCCESS, TYPES.GET_USER_INFO_FAIL],
  promise: (client) =>
    client.get(
      `${API_URLS.users}/${id}?include=account,accounts_managed,permissions,role,sec_geogs,created_by,agencies,last_modified_by`,
    ),
  timeZone,
});

export const resetUserInfo = () => ({
  type: TYPES.RESET_USER_INFO,
});

export const usersCSV = (queryParams) => ({
  types: [TYPES.GET_USERS_CSV, TYPES.GET_USERS_CSV_SUCCESS, TYPES.GET_USERS_CSV_FAIL],
  promise: (client) => {
    const detectedTz = `tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    let params = `?${detectedTz}`;
    if (queryParams) {
      params += `&${queryParams.substr(1)}`;
    }
    return client.get(`${API_URLS.usersCSV}${params}`);
  },
});

export const getUsersImportProgress = () => {
  return {
    types: [TYPES.USERS_IMPORT_PROGRESS, TYPES.USERS_IMPORT_PROGRESS_SUCCESS, TYPES.USERS_IMPORT_PROGRESS_FAIL],
    promise: (client) => client.get(`${API_URLS.users}/import`),
  };
};

export const postUsersImport = (data) => {
  return {
    types: [TYPES.UPLOAD_USERS, TYPES.UPLOAD_USERS_SUCCESS, TYPES.UPLOAD_USERS_FAIL],
    promise: (client) => client.post(`${API_URLS.users}/import`, data, { headers: { ...REQUEST_HEADERS } }),
  };
};

export const resetUserImportInfo = () => ({
  type: TYPES.RESET_USER_IMPORT_INFO,
});

export const setUserToastId = (id) => ({
  type: TYPES.SET_USER_TOAST_ID,
  payload: id,
});
