import { isEmpty } from 'lodash';
import { capitalizeFirstLetter, formatDateTimeZone } from 'utils/custom';

const getFormattedDate = (date, timeZone) => {
  return isEmpty(date) ? null : formatDateTimeZone(date, timeZone, 'MM/DD/YYYY');
};

const getRetailerData = (itemId, retailers) => {
  const retailerItem = retailers.find(({ id }) => id === itemId);
  const { name, address, address2, city, zipcode } = retailerItem.attributes;
  return {
    name: capitalizeFirstLetter(name),
    address,
    address2,
    city,
    zipcode,
  };
};

const getAgencyData = (itemId, agencyList) => {
  const agencyItem = agencyList.find(({ id }) => id === itemId);
  const { name } = agencyItem.attributes;
  return {
    name,
    data: {
      id: itemId, // For permissions
    },
  };
};

const getCampaignManagerData = (itemId, campaignList) => {
  if (!itemId || !campaignList) return null;
  const campaignItem = campaignList.find(({ id }) => id === itemId);
  const campaignManager = campaignItem?.relationships?.campaign_manager;
  return campaignManager;
};

const getAddress = (address, address2) => {
  if (address2) {
    return `${address}, ${address2}`;
  }
  return address;
};

const getRoleName = (data, itemId) => {
  if (!itemId || !data) return null;
  const userItem = data.find(({ id }) => id === itemId);
  const roleName = userItem.attributes.role_name;
  return roleName;
};

export default ({ data, included }, timeZone) => {
  const enforcements = data.map((item) => {
    const { attributes, relationships } = item;
    const { visit_time: visitDate, aggregationpolygon_set: secGeog } = attributes;
    const {
      retailer: {
        data: { id: retailerID },
      },
      agency: {
        data: { id: agencyId },
      },
      user: {
        data: { id: userId },
      },
      campaign,
    } = relationships;

    const retailers = included.filter(({ type }) => type === 'Retailer');
    const agencyList = included.filter(({ type }) => type === 'Agency');
    const campaignList = included.filter(({ type }) => type === 'Campaign');
    const userList = included.filter(({ type }) => type === 'User');

    const enforcementItem = {
      agency: getAgencyData(agencyId, agencyList),
      visitDate: getFormattedDate(visitDate, timeZone),
      retailer__name: getRetailerData(retailerID, retailers).name,
      address: getAddress(
        getRetailerData(retailerID, retailers).address,
        getRetailerData(retailerID, retailers).address2,
      ),
      city: getRetailerData(retailerID, retailers).city,
      zipcode: getRetailerData(retailerID, retailers).zipcode,
      id: item.id,
      retailerID,
      sec_geogs: secGeog?.map((geog) => `${geog}`),
      campaign,
      campaign_manager: getCampaignManagerData(campaign.data?.id, campaignList),
      dataCollectors: campaign.data ? [userId] : [],
      roleName: getRoleName(userList, userId),
      user: item.relationships.user,
    };
    return enforcementItem;
  });

  return enforcements;
};
