import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Loader } from 'ui-kit';
import { BUTTON_TYPES, URLS, MESSAGES } from '_constants';
import { notification } from 'utils/services';
import { CampaignEntity, VisitEntity } from '_entities';
import * as Styled from '../styles';

const { getDeleteCampaign } = CampaignEntity.actions;
const { getAssignmentsData } = CampaignEntity.selectors;
const { getVisitListData } = VisitEntity.selectors;

function DeleteCampaignModal({ isOpen, onClose, dispatch }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { data: assignmentsData } = useSelector(getAssignmentsData);
  const { data: visitData } = useSelector(getVisitListData);
  const assignmentCount = assignmentsData?.length || 0;
  const visitCount = visitData?.length || 0;
  const canBeDeleted = !assignmentCount && !visitCount;

  const handleDelete = (e) => {
    e.preventDefault();
    (async () => {
      setLoading(true);
      try {
        await dispatch(getDeleteCampaign(id));
        notification.success(MESSAGES.CAMPAIGN.DELETE.SUCCESS);
        history.push(URLS.campaign_list);
      } catch (err) {
        const { errors } = err?.response?.data;
        const message = errors.reduce((acc, item) => {
          acc += `${item.detail} `;
          return acc;
        }, '');
        history.push(`${URLS.campaignPages.edit}/${id}`);
        notification.error(message);
      } finally {
        setLoading(false);
      }
    })();
  };

  const modalTitle = useMemo(() => {
    if (canBeDeleted) return 'Confirm campaign deletion';
    return 'Cannot delete campaign';
  }, [canBeDeleted]);

  const description = useMemo(() => {
    if (canBeDeleted) return 'Are you sure you want to delete this campaign?';
    const introString = 'This campaign cannot be deleted because it has';
    const visitString = visitCount === 1 ? 'a visit' : visitCount > 1 ? `${visitCount} visits` : '';
    const assignmentString =
      assignmentCount === 1 ? 'an assignment' : assignmentCount > 1 ? `${assignmentCount} assignments` : '';
    return `${introString} ${visitString} ${assignmentCount && visitCount ? 'and ' : ''}${assignmentString}`;
  }, [canBeDeleted, visitCount, assignmentCount]);

  const cancelButtonText = canBeDeleted ? 'Cancel' : 'Close';

  if (loading) return <Loader />;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Styled.ModalWrapper>
        <Styled.ModalTitle>{modalTitle}</Styled.ModalTitle>
        <Styled.ModalDescription>{description}</Styled.ModalDescription>
        <Styled.ModalButtonWrapper>
          <Styled.ModalButtonCancel
            onClick={onClose}
            text={cancelButtonText}
            variant={BUTTON_TYPES.DARK}
            aria-label={cancelButtonText}
          />
          {canBeDeleted && (
            <Styled.ModalButtonSet
              onClick={handleDelete}
              text="Delete"
              variant={BUTTON_TYPES.DANGER}
              aria-label="Delete"
            />
          )}
        </Styled.ModalButtonWrapper>
      </Styled.ModalWrapper>
    </Modal>
  );
}

DeleteCampaignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default DeleteCampaignModal;
