import styled from 'styled-components';
import { BackButtonLink } from 'modules/BackPageButton/styles';

export const MainWrapper = styled.div`
  margin: 30px 20px 45px;

  ${BackButtonLink} {
    margin-bottom: 16px;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const SubTitle = styled.p`
  font-size: 14px;
  color: var(--dim-grey);
  margin-bottom: 28px;
`;

export const Form = styled.form`
  > button {
    min-width: 160px;
  }
`;

export const FormRow = styled.div`
  max-width: 475px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 28px;

  > * {
    flex: 1 0;
    max-width: calc(50% - 8px);
    margin: 0;
  }
`;
