import styled from 'styled-components';

export const BackButtonLink = styled.a`
  display: inline-flex;
  align-items: center;
  &:focus {
    box-shadow: 0 0 0 2px var(--focus);
  }
`;

export const BackButtonText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: var(--danger);
  margin-left: 6px;
`;
