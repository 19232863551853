import styled, { css } from 'styled-components';
import { DEVICES, INDENTS } from '_constants';

export const Block = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `};
`;

export const StyledMenu = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  position: fixed;
  background: var(--white);
  width: 100vw;
  top: 54px;
  bottom: 0;
  z-index: 9;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  @media ${DEVICES.tablet} {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
`;

export const StyledBurger = styled.button.attrs(() => ({
  ariaRequired: true,
  ariaPressed: 'false',
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 70px;
  margin: 0;
  @supports (display: grid) {
    width: 38px;
    margin: 0 0 0 22px;
  }
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  position: relative;

  @media print {
    display: none !important;
  }

  @media (min-width: 951px) {
    display: none;
  }

  @-moz-document url-prefix() {
    & {
      &:focus {
        border: dotted 1px black;
      }
    }
  }
`;

export const BurgerStick = styled.div.attrs(() => ({}))`
  width: 32px;
  height: 4px;
  background: ${({ open }) => (open ? 'transparent' : 'var(--dark)')};
  border-radius: 10px;
  position: absolute;

  &:after,
  &:before {
    content: '';
    width: 32px;
    height: 4px;

    left: 0;

    background: var(--dark);
    border-radius: 10px;
    transition: transform 0.3s linear;
    position: absolute;
  }

  &:before {
    margin: ${({ open }) => (open ? '0' : '-10px 0 0')};
    transform: rotate(${({ open }) => (open ? '135deg' : 0)});
  }

  &:after {
    margin: ${({ open }) => (open ? '0' : '10px 0 0')};
    transform: rotate(${({ open }) => (open ? '-135deg' : 0)});
  }
`;

export const Logout = styled.div.attrs(() => ({ ariaRequired: true }))`
  color: var(--danger);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  cursor: pointer;

  @media ${DEVICES.header} {
    padding: 0 0 0 ${INDENTS.TABLET_INDENT};
  }

  @media ${DEVICES.mobileL} {
    padding: 0 0 0 ${INDENTS.MOBILE_INDENT};
  }
`;

export const EmailMenu = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  font-size: 16px;
  line-height: 19px;
  color: var(--soft-dark);
  margin: 0 34px 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${DEVICES.header} {
    padding: 41px 0 0 ${INDENTS.TABLET_INDENT};
  }
  @media ${DEVICES.mobileL} {
    padding: 32px 0 0 ${INDENTS.MOBILE_INDENT};
  }
`;

export const BurgerContainer = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 0;
  margin-left: ${({ open }) => (open ? 'auto' : null)};
  @media ${DEVICES.tablet} {
    margin-left: ${({ open }) => (open ? 'auto' : 'auto')};
  }
`;
