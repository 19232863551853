export const getVisitData = (state) => state.visit.visitInfo;
export const getVisitInfo = (state) => state.visit.info;
export const getViolationData = (state) => state.visit.violation;
export const getAssessmentTableData = (state) => state.visit.assessmentTableData;
export const getEducationTableData = (state) => state.visit.educationTableData;
export const getEnforcementTableData = (state) => state.visit.enforcementTableData;
export const getVisitListData = (state) => state.visit.visits;
export const getVisitForms = (state) => state.visit.forms;
export const getEnforcementViolations = (state) => state.visit.enforcementViolations;
export const getEducationViolations = (state) => state.visit.educationViolations;
