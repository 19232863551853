/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Filter from 'ui-kit/old/filter';
import { PageContainer, AccessControl } from 'modules';
import BackPageButton from 'modules/BackPageButton';
import { CampaignEntity, FilterEntity, AuthEntity } from '_entities';
import { isEmpty, isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { BUTTON_TYPES, DEFAULT_SORT, VISITOR_USER_ROLE } from '_constants';
import { TableSizeControls, TableTabsWrapper, Button, BaseSelect, Loader } from 'ui-kit';
import { isValidDate } from 'utils/old/checkSearchDate';
import { SetQueryParams } from 'utils/old/setQueryParams';
import processQSParams from 'utils/old/processQSParams';
import ManageAssignmentsTable from 'pages/ManageAssignments/ManageAssignmentsTable';
import ITEMS_PER_PAGE_OPTIONS from './constants';
import sortParam from 'utils/old/sortParam';
import { notification } from 'utils/services';
import * as Styled from './styles';
import queryString from 'query-string';
import RemoveDataCollectors from './RemoveDataCollectors';
import { UsersAssigningModal } from 'modules/Modals';

const {
  getAssignmentsList,
  updateAssignments,
  bulkAssignments,
  removeBulkAssignments,
  getDataCollectorsList,
  getCampaignData,
  getManageAssignments,
} = CampaignEntity.actions;
const {
  getAssignmentsTableData,
  getAssignmentsData,
  getRetailerAssignmentsTableData,
  getViewCampaignData,
  getDataCollectors,
} = CampaignEntity.selectors;

const { getUserId } = AuthEntity.selectors;
const { applyFilters } = FilterEntity.actions;

const noDataCollectorsSelected = 'Unable to create assignment(s) because no data collector(s) were selected.';
const noRetailersSelected = 'Unable to create assignment(s) because no retailer(s) were selected.';
const assignmentsCreated = 'Assignments were successfully created';
const assignmentsDeleted = 'Assignments were successfully deleted';
const statusUpdated = 'Assignments status was successfully updated';

const pageTitle = 'Manage assignments';

const MODAL_NAMES = {
  FILTER_MODAL: 'FILTER_MODAL',
};

const ManageAssignments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { search: searchParam } = queryString.parse(location.search);

  const assignmentParams = `page_size=1000&campaign=${id}`;

  const { data: tableData } = useSelector(getAssignmentsTableData);
  const { meta } = useSelector(getRetailerAssignmentsTableData);
  const userId = useSelector(getUserId);

  const pagination = meta?.pagination;

  const viewCampaign = useSelector(getViewCampaignData);

  const campaignRetailer = useSelector(getRetailerAssignmentsTableData);

  const geogId = viewCampaign?.data?.relationships?.sec_geog?.data?.id;
  const agencyId = viewCampaign?.data?.relationships?.agency.data.id;
  const formId = viewCampaign?.data?.relationships?.form.data.id;

  const {
    campaign_name: campaignName,
    retailers_count: retailersCount,
    retailers_active_count: retailersActiveCount,
    retailers_duplicate_count: retailersDuplicateCount,
    retailers_historic_count: retailerHistoricCount,
    retailers_assigned: retailersAssigned,
    retailers_not_assigned: retailersNotAssigned,
    visits_done: visitsDone,
    sec_geog_name: secGeogName,
    num_assign_submitted_violation: numberOfCompletedAssignmentsViolation,
  } = viewCampaign?.data?.attributes || {};
  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState(searchParam || '');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [setModalType] = useState(null);
  const [isLoad, setIsLoad] = useState();
  const [tableCheckboxValues, setTableCheckboxValues] = useState({});
  const [selectedCollectors, setSelectedCollectors] = useState('');
  const [assignmentModal, setAssignmentModal] = useState({});
  const [isModalOpen, setIsModalOpen] = useState({ modalStatus: false, modalType: '' });
  const [sortOption, setSortOption] = useState({
    sortBy: DEFAULT_SORT.retailerVisits.prop,
    sortOrder: DEFAULT_SORT.retailerVisits.order,
  });

  const getAssignments = async ({ page = 1, perPage, sortBy, sortOrder, ...qsParams }) => {
    const params = processQSParams({ page, perPage, sortOrder, sortBy, ...qsParams });

    try {
      setIsLoad(true);
      await dispatch(getManageAssignments(id, params));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const params = processQSParams({
          perPage: 20,
          ordering: 'name',
          sortBy: sortOption.sortBy,
          sortOrder: sortOption.sortOrder,
          manage_assignments_search: searchValue,
        });
        await dispatch(getCampaignData(id));
        await dispatch(getManageAssignments(id, params));
      } catch (err) {
        console.log('err: ', err);
      }
    })();
  }, []);

  const dataCollectorsList = useSelector(getDataCollectors);

  const {
    oldFilter: { generalFilters },
  } = useSelector((state) => state);

  const loadAssignments = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
      filters = generalFilters,
      manage_assignments_search = searchValue,
      ...rest
    } = {}) => {
      try {
        await getAssignments({
          ...filters,
          ...rest,
          page,
          perPage,
          sortBy,
          sortOrder,
          manage_assignments_search,
        });
        await dispatch(getCampaignData(id));
        SetQueryParams({
          queryString: {
            ...filters,
            ...rest,
            page,
            page_size: '1000',
            search: manage_assignments_search || null,
            ordering: sortParam({ sortBy, sortOrder }),
          },
          history,
        });
      } catch (e) {
        console.log('e', e);
      }
    },
    [currentPage, dispatch, currentPerPage, SetQueryParams, {}, sortOption, geogId, searchValue],
  );

  const handleModalType = (type) => setModalType(type);

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadAssignments({ sortBy, sortOrder });
    },
    [loadAssignments, setSortOption],
  );

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadAssignments({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadAssignments],
  );

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadAssignments({ page: value });
    },
    [loadAssignments, setCurrentPage],
  );

  useEffect(() => {
    (async () => {
      try {
        SetQueryParams({
          queryString: {
            page: currentPage,
            page_size: currentPerPage,
            ...(searchValue ? { search: searchValue } : {}),
          },
          history,
        });
        dispatch(applyFilters({ page: currentPage, page_size: currentPerPage }));
      } catch (err) {
        console.log('err: ', err);
      }
    })();
  }, [geogId, id]);

  const openFilterModal = () => {
    handleModalType(MODAL_NAMES.FILTER_MODAL);
  };

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    setCurrentPage(1);
    loadAssignments({ page: 1, manage_assignments_search: searchString.trim() });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      setCurrentPage(1);
      loadAssignments({ page: 1, manage_assignments_search: searchString.trim() });
    }
  };

  const onKeyDownFilter = (e) => {
    if (e.keyCode === 13) {
      handleModalType(MODAL_NAMES.FILTER_MODAL);
      e.preventDefault();
    }
  };

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    loadAssignments({ manage_assignments_search: '' });
    setSearchValue('');
  };

  const handleChangeSelect = (val) => {
    setSelectedCollectors(val);
  };

  const options = useMemo(
    () =>
      dataCollectorsList &&
      dataCollectorsList
        .map((elem) => ({
          value: elem.attributes.email,
          label: elem.attributes.email,
          key: elem.id,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [dataCollectorsList],
  );

  const handleChangeStatusSelect = async (val, assignmentId) => {
    const assignmentsSendData = tableData.find((item) => item.id === assignmentId);
    if (!assignmentsSendData)
      return notification.error(`You can't update status if assignments has not any data collectors`);
    assignmentsSendData.status = val;
    const sendData = { attributes: assignmentsSendData, id: assignmentId, type: 'Assignment' };

    try {
      setIsLoad(true);
      const res = await dispatch(updateAssignments(assignmentId, { data: sendData }));
      if (res.data.message.length > 0) {
        notification.success(statusUpdated);
      }
    } catch (err) {
      err?.response?.data?.errors?.map((item) => notification.error(item.detail));
    } finally {
      setIsLoad(false);
      loadAssignments({ page: currentPage, perPage: currentPerPage });
    }
  };

  const handleHeaderCheckboxChange = (itemValue) => {
    const {
      target: { value, name },
    } = itemValue;
    setTableCheckboxValues({ ...tableCheckboxValues, [name]: value });
  };

  const toggleSelectAllCheckboxes = (itemValue) => {
    const {
      target: { value },
    } = itemValue;
    const selectAllValues = campaignRetailer.results
      .filter((el) => el?.status !== 1 && !el?.closed_date)
      .reduce((o, key) => ({ ...o, [`${key.id}-${key.assignment_id}`]: true }), {});
    setTableCheckboxValues(value ? selectAllValues : {});
  };

  const assignDataCollectors = async () => {
    if (selectedCollectors.length === 0) {
      return notification.error(noDataCollectorsSelected);
    }
    const selectedRetailers = Object.keys(tableCheckboxValues)
      .filter((k) => tableCheckboxValues[k] === true)
      .map((elem) => {
        return {
          id: elem.split('-')[0],
          type: 'Retailer',
        };
      });
    if (selectedRetailers.length === 0) {
      return notification.error(noRetailersSelected);
    }
    const users = selectedCollectors?.map((item) => {
      return {
        id: item.key,
        type: 'User',
      };
    });

    const bulkData = {
      relationships: {
        retailers: {
          data: selectedRetailers,
        },
        campaign: {
          data: {
            id: id,
            type: 'Campaign',
          },
        },
        users: {
          data: users,
        },
      },
      type: 'Assignment',
    };

    try {
      setIsLoad(true);
      const res = await dispatch(bulkAssignments({ data: bulkData }));
      if (res.data.message.length > 0) {
        notification.success(assignmentsCreated);
      }
      if (!isEmpty(res?.data?.user_can_not_be_assigned_warning)) {
        notification.infoList(
          'The following users can not be assigned to the retailers: ',
          res.data.user_can_not_be_assigned_warning,
        );
      }
    } catch (err) {
      err?.response.data.errors.map((item) => notification.error(item.detail));
    } finally {
      setIsLoad(false);
      loadAssignments({ page: currentPage, perPage: currentPerPage });
      setTableCheckboxValues({});
    }
  };

  const deleteAssignments = async () => {
    const selectedRetailers = Object.keys(tableCheckboxValues)
      .filter((k) => tableCheckboxValues[k] === true)
      .map((elem) => {
        return elem.split('-')[0];
      });

    if (!selectedRetailers.length) {
      return notification.error(noRetailersSelected);
    }

    const users = selectedCollectors.length
      ? selectedCollectors.map((item) => {
          return {
            id: item.key,
            type: 'User',
          };
        })
      : [];

    const getSelectedAssignments = tableData.filter((item) =>
      selectedRetailers.includes(Number(item.retailerID).toString()),
    );

    const selectedAssignments = getSelectedAssignments.map((item) => {
      return {
        id: item.id,
        type: 'Assignment',
      };
    });

    const removeBulkData = {
      data: {
        relationships: {
          assignments: {
            data: selectedAssignments,
          },
          campaign: {
            data: {
              id,
              type: 'Campaign',
            },
          },
          users: {
            data: users,
          },
        },
        type: 'Assignment',
      },
    };

    try {
      setIsLoad(true);
      const res = await dispatch(removeBulkAssignments(removeBulkData));
      if (res?.data?.message?.length > 0) {
        notification.success(assignmentsDeleted);
      }
    } catch (err) {
      err?.response.data.errors.map((item) => notification.error(item.detail));
    } finally {
      setIsLoad(false);
      loadAssignments({ page: currentPage, perPage: currentPerPage });
      setTableCheckboxValues({});
    }
  };

  const handleAssignToAll = () => {
    if (!Array.isArray(selectedCollectors) || !selectedCollectors?.length) {
      notification.error(noDataCollectorsSelected);
      return;
    }
    setIsModalOpen(() => ({
      modalStatus: true,
      modalType: 'assign',
    }));
  };

  const handleDeleteAssignToAll = () => {
    if (!Array.isArray(selectedCollectors) || !selectedCollectors?.length) {
      notification.error(noDataCollectorsSelected);
      return;
    }
    setIsModalOpen(() => ({
      modalStatus: true,
      modalType: 'delete',
    }));
  };

  const closeUserAssigningModal = (event) => {
    setIsModalOpen({ modalStatus: false, modalType: '' });
    if (!event) {
      loadAssignments({ page: currentPage, perPage: currentPerPage });
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
    loadAssignments({ page: currentPage, perPage: currentPerPage });
  };

  const openModal = (id) => {
    const assignmentModalData = tableData.find((elem) => elem.id === id);
    setAssignmentModal(assignmentModalData);
    setIsOpenModal(true);
  };

  useEffect(() => {
    if (!agencyId) return;
    const activeUser = true;
    const params = processQSParams({
      active_user: activeUser,
      agencies: agencyId,
      sec_geogs: geogId,
      exclude_user_types: VISITOR_USER_ROLE,
    });
    dispatch(getDataCollectorsList(params));
  }, [agencyId, geogId]);

  return (
    <PageContainer>
      {isLoad && <Loader />}
      <Styled.MainContainer>
        <RemoveDataCollectors open={isOpenModal} onClose={closeModal} assignmentModal={assignmentModal} />
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>{pageTitle}</Styled.Title>
            <Styled.H2title>
              <span>Campaign Name:</span> {campaignName}
            </Styled.H2title>
            <Styled.OddInfoWrapper>
              <Styled.H3title>Secondary Geography: {secGeogName}</Styled.H3title>
              {!isNull(retailersCount) && (
                <>
                  <Styled.H3title>
                    {`Total number of retailers: ${retailersActiveCount} (${retailersCount} initial -
                    ${retailersDuplicateCount} duplicates - ${retailerHistoricCount} historic)`}
                  </Styled.H3title>
                  <Styled.H3title>Retailers already assigned: {retailersAssigned}</Styled.H3title>
                  <Styled.H3title>Retailers left to assign: {retailersNotAssigned}</Styled.H3title>
                  <Styled.H3title>
                    Revisits needed (retailers with a violation): {numberOfCompletedAssignmentsViolation}
                  </Styled.H3title>
                  <Styled.H3title>Total visits done in campaign: {visitsDone}</Styled.H3title>
                </>
              )}
            </Styled.OddInfoWrapper>
          </Styled.TitleWrapper>
        </Styled.TopLine>
        <Styled.FilterWrapper data-html2canvas-ignore>
          <Filter
            type="null"
            placeholder="Search by retailer name, address, city and/or zip code"
            handleClick={openFilterModal}
            onChangeInput={handleOnChangeInputSearch}
            handleClickInput={handleClickInput}
            onKeyDown={handleKeyDown}
            onKeyDownFilter={onKeyDownFilter}
            onRemoveSearch={handleRemoveSearch}
            searchValue={searchValue}
            isWidth
          />
        </Styled.FilterWrapper>
        <Styled.DataCollectorsDropContainer>
          <BaseSelect
            labelText="Data collector(s)"
            value={selectedCollectors}
            onChange={handleChangeSelect}
            inputId="surveyModalDrop"
            options={options}
            isMulti
          />
          <Button text="Assign" onClick={assignDataCollectors} variant={BUTTON_TYPES.DANGER} />
          <Button text="Assign to all" onClick={handleAssignToAll} variant={BUTTON_TYPES.DANGER} />
          <AccessControl>
            <Button text="Delete assignments" onClick={deleteAssignments} variant={BUTTON_TYPES.DARK} />
            <Button text="Delete assignments to all" onClick={handleDeleteAssignToAll} variant={BUTTON_TYPES.DARK} />
          </AccessControl>
        </Styled.DataCollectorsDropContainer>
        <Styled.TopLine />
        <TableTabsWrapper
          hasTabs={false}
          amount={pagination?.count || 0}
          isManageAssignment
          tableComponent={
            <Styled.TableContainer>
              <ManageAssignmentsTable
                data={tableData}
                sortOption={sortOption}
                sortHandler={sortHandler}
                handleChangeStatusSelect={handleChangeStatusSelect}
                handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                tableCheckboxValues={tableCheckboxValues}
                toggleSelectAllCheckboxes={toggleSelectAllCheckboxes}
                openModal={openModal}
                formId={formId}
                userId={userId}
              />
              <TableSizeControls
                itemsPerPageProps={{
                  handlePerPageChange,
                  value: currentPerPage,
                  options: ITEMS_PER_PAGE_OPTIONS,
                }}
                pageCountProps={{
                  value: currentPage,
                  pages: pagination?.pages || 1,
                  handlePageChange,
                }}
                paginationInfoProps={{
                  total: pagination?.count || 0,
                  currentPage,
                  perPage: currentPerPage,
                }}
                paginationNavProps={{
                  currentPage,
                  handlePageChange,
                  pages: pagination?.pages || 1,
                  titleTable: 'Assignments',
                }}
              />
            </Styled.TableContainer>
          }
        />
      </Styled.MainContainer>
      {isModalOpen.modalStatus && (
        <UsersAssigningModal
          type={isModalOpen.modalType}
          isOpen={isModalOpen.modalStatus}
          onClose={closeUserAssigningModal}
          search={searchParam}
          retailersCount={pagination?.count || 0}
          dispatch={dispatch}
          id={id}
          selectedCollectors={selectedCollectors}
        />
      )}
    </PageContainer>
  );
};

export default ManageAssignments;
