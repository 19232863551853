import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { PageContainer, AccessControl } from 'modules';
import BackPageButton from 'modules/BackPageButton';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Button, Section } from 'ui-kit';
import { AuthEntity, YouthEntity } from '_entities';
import { BUTTON_TYPES, PERMISSIONS, URLS, USER_ROLE_TYPES } from '_constants';
import ViewItem from 'pages/User/View/components/ViewItem';
import { formatDateTimeZone } from 'utils/custom';
import moment from 'moment';
import * as Styled from './styles';
import { formNames } from '../Add/constants';
import { genderOptions } from './constants';

const { getYouth } = YouthEntity.actions;
const { getYouthData } = YouthEntity.selectors;
const { getTimeZone } = AuthEntity.selectors;

const { ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER } = USER_ROLE_TYPES;

function YouthView() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const timeZone = useSelector(getTimeZone);
  const history = useHistory();
  const { id } = useParams();
  const youthFullData = useSelector(getYouthData);
  const youthData = youthFullData.attributes;

  const birthDate = youthData && moment(youthData[formNames.birthday]).format('L');
  const youthGender = youthData && genderOptions[youthData[formNames.gender]];
  const youthJoined = youthData && formatDateTimeZone(youthData[formNames.dateJoined], timeZone, 'L');
  const youthCreator = youthData?.created_by;
  const youthModified = youthData?.last_edited_by;

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getYouth(id, timeZone));
      } catch (err) {
        console.log(err);
        history.push(URLS.notFound);
      } finally {
        setIsLoading(() => false);
      }
    })();
  }, []);

  const handleEditUser = () => {
    history.push(`${URLS.youthPages.edit}/${id}`);
  };

  const roleRuleSet = {
    agencySecGeogs: [ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER],
    all: [MULTI_ACCOUNT_MANAGER],
  };

  const youthRelationshipsData = {
    agency: youthData?.agencies.map((item) => item.value),
    secGeog: youthData?.geographies.map((item) => item.value),
  };

  if (isLoading) return <Loader />;

  return (
    <PageContainer>
      <Styled.Wrapper>
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>View youth</Styled.Title>
          </Styled.TitleWrapper>
          <AccessControl permission={PERMISSIONS.CHANGE_YOUTH} roleRuleSet={roleRuleSet} data={youthRelationshipsData}>
            <Styled.ButtonWrapper>
              <Button onClick={handleEditUser} variant={BUTTON_TYPES.DANGER} text="Edit" />
            </Styled.ButtonWrapper>
          </AccessControl>
        </Styled.TopLine>
        <Styled.RowWrapper>
          <Section title="Youth information">
            <Styled.Dl>
              <Styled.VerticalBorder />
              <Styled.ItemWrapper>
                <Styled.Dt>Youth ID</Styled.Dt>
                <Styled.Dd>{youthData[formNames.youthId] || '-'}</Styled.Dd>
                <Styled.HorizontalBorder />
              </Styled.ItemWrapper>
              <Styled.ItemWrapper>
                <Styled.Dt>Birthdate</Styled.Dt>
                <Styled.Dd>{birthDate || '-'}</Styled.Dd>
                <Styled.HorizontalBorder />
              </Styled.ItemWrapper>
              <Styled.ItemWrapper>
                <Styled.Dt>Gender</Styled.Dt>
                <Styled.Dd>{youthGender || '-'}</Styled.Dd>
                <Styled.HorizontalBorder />
              </Styled.ItemWrapper>
              <Styled.ItemWrapper>
                <Styled.Dt>Status</Styled.Dt>
                <Styled.Dd>{youthData[formNames.isActive] ? 'Active' : 'Inactive'}</Styled.Dd>
                <Styled.HorizontalBorder />
              </Styled.ItemWrapper>
              <Styled.ItemWrapper>
                <Styled.Dt>Geographies</Styled.Dt>
                {youthData[formNames.geographies]?.length ? (
                  <div>
                    {youthData[formNames.geographies].map((item) => (
                      <Styled.Dd key={item.value}>{item.label}</Styled.Dd>
                    ))}
                  </div>
                ) : (
                  <Styled.Dd>-</Styled.Dd>
                )}
                <Styled.HorizontalBorder />
              </Styled.ItemWrapper>
              <Styled.ItemWrapper>
                <Styled.Dt>Agencies</Styled.Dt>
                {youthData[formNames.agencies]?.length ? (
                  <div>
                    {youthData[formNames.agencies].map((item) => (
                      <Styled.Dd key={item.value}>{item.label}</Styled.Dd>
                    ))}
                  </div>
                ) : (
                  <Styled.Dd>-</Styled.Dd>
                )}
              </Styled.ItemWrapper>
            </Styled.Dl>
          </Section>
          <Section title="Created & Modified">
            <Styled.Dl>
              <ViewItem title="Date joined" data={youthJoined} />
              <ViewItem>
                <Styled.Dt>Created by</Styled.Dt>
                <Styled.Dd>
                  <Link to={`${URLS.users}/${youthCreator?.id}`}>{youthCreator?.email}</Link>
                </Styled.Dd>
              </ViewItem>
              <ViewItem>
                <Styled.Dt>Last modified user</Styled.Dt>
                <Styled.Dd>
                  <Link to={`${URLS.users}/${youthModified?.id}`}>{youthModified?.email}</Link>
                </Styled.Dd>
              </ViewItem>
              <ViewItem title="Last modified date" data={youthData[formNames.modified]} />
            </Styled.Dl>
          </Section>
        </Styled.RowWrapper>
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default YouthView;
