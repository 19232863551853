import { formNames } from '../Add/constants';

export const viewItemList = [
  {
    name: formNames.firstName,
    title: 'First name',
  },
  {
    name: formNames.lastName,
    title: 'Last name',
  },
  {
    name: formNames.email,
    title: 'Email address',
  },
  {
    name: formNames.phone,
    title: 'Phone',
  },
];

export const listedItems = [
  {
    name: formNames.secGeogs,
    title: 'Geographies',
  },
  {
    name: formNames.agency,
    title: 'Agencies',
  },
  {
    name: formNames.account,
    title: 'Managed accounts',
  },
];
