import React, { lazy } from 'react';
import { LazyLoad } from 'ui-kit';
import { PERMISSIONS, URLS } from '_constants';

const Dashboard = lazy(() => import('pages/Dashboard'));
const RetailerList = lazy(() => import('pages/old/RetailerList'));
const RetailerAdd = lazy(() => import('pages/Retailers/AddRetailer'));
const RetailerEdit = lazy(() => import('pages/Retailers/EditRetailer'));
const RetailerView = lazy(() => import('pages/old/RetailerView'));
const EnforcementContainerList = lazy(() => import('pages/Enforcement/List'));
const EnforcementView = lazy(() => import('pages/Enforcement/View'));
const EnforcementEdit = lazy(() => import('pages/Enforcement/Edit'));
const EnforcementViolationEdit = lazy(() => import('pages/Enforcement/ViolationEdit'));
const Survey = lazy(() => import('pages/Survey'));
const AssessmentContainerList = lazy(() => import('pages/AssessmentList'));
const AssignmentsList = lazy(() => import('pages/Assignments/List'));
const CampaignListTable = lazy(() => import('pages/Assignments/List/components/CampaignListTable'));
const AssessmentView = lazy(() => import('pages/Assessment/View'));
const AssessmentEdit = lazy(() => import('pages/Assessment/Edit'));
const EducationContainerList = lazy(() => import('pages/EducationList'));
const EducationView = lazy(() => import('pages/Education/View'));
const EducationEdit = lazy(() => import('pages/Education/Edit'));
const EducationViolationEdit = lazy(() => import('pages/Education/ViolationEdit'));
const CampaignAdd = lazy(() => import('pages/Campaign/Add'));
const CampaignView = lazy(() => import('pages/Campaign/View'));
const CampaignEdit = lazy(() => import('pages/Campaign/Edit'));
const YouthList = lazy(() => import('pages/Youth/List'));
const AuditLogs = lazy(() => import('pages/Audit/old'));
const YouthAdd = lazy(() => import('pages/Youth/Add'));
const YouthView = lazy(() => import('pages/Youth/View'));
const YouthEdit = lazy(() => import('pages/Youth/Edit'));
const UserList = lazy(() => import('pages/User/List'));
const UserAdd = lazy(() => import('pages/User/Add'));
const UserView = lazy(() => import('pages/User/View'));
const UserEdit = lazy(() => import('pages/User/Edit'));
const ManageAssignments = lazy(() => import('pages/ManageAssignments'));
const VenueList = lazy(() => import('pages/Venues/List'));
const VenueAdd = lazy(() => import('pages/Venues/Add'));
const VenueView = lazy(() => import('pages/Venues/View'));
const VenueEdit = lazy(() => import('pages/Venues/Edit'));
const ReportsList = lazy(() => import('pages/Reports/List'));
const ReportsArea = lazy(() => import('pages/Reports/Area'));
const ReportsEnforcement = lazy(() => import('pages/Reports/Enforcement'));
const ReportsEducation = lazy(() => import('pages/Reports/Education'));
const StoreMerger = lazy(() => import('pages/old/Mergers'));
const InfograficsDisparateBurden = lazy(() => import('pages/Infographics/DisparateBurden'));
const InfograficsEnforcementOverview = lazy(() => import('pages/Infographics/EnforcementOverview'));
const InfograficsRetailerReduction = lazy(() => import('pages/Infographics/RetailerReduction'));
const InfograficsYouthAppeal = lazy(() => import('pages/Infographics/YouthAppeal'));
const InfograficsPosLandscape = lazy(() => import('pages/Infographics/PosLandscape'));

const LazyDashboard = (props) => <LazyLoad component={Dashboard} {...props} />;
const LazyRetailerList = (props) => <LazyLoad component={RetailerList} {...props} />;
const LazyRetailerAdd = (props) => <LazyLoad component={RetailerAdd} {...props} />;
const LazyRetailerEdit = (props) => <LazyLoad component={RetailerEdit} {...props} />;
const LazyRetailerView = (props) => <LazyLoad component={RetailerView} {...props} />;
const LazyEnforcementList = (props) => <LazyLoad component={EnforcementContainerList} {...props} />;
const LazyEnforcementView = (props) => <LazyLoad component={EnforcementView} {...props} />;
const LazyEnforcementEdit = (props) => <LazyLoad component={EnforcementEdit} {...props} />;
const LazyEnforcementViolationEdit = (props) => <LazyLoad component={EnforcementViolationEdit} {...props} />;
const LazySurvey = (props) => <LazyLoad component={Survey} {...props} />;
const LazyAssessmentContainerList = (props) => <LazyLoad component={AssessmentContainerList} {...props} />;
const LazyAssignmentsList = (props) => <LazyLoad component={AssignmentsList} {...props} />;
const LazyCampaignListTable = (props) => <LazyLoad component={CampaignListTable} {...props} />;
const LazyAssessmentView = (props) => <LazyLoad component={AssessmentView} {...props} />;
const LazyAssessmentEdit = (props) => <LazyLoad component={AssessmentEdit} {...props} />;
const LazyEducationContainerList = (props) => <LazyLoad component={EducationContainerList} {...props} />;
const LazyEducationView = (props) => <LazyLoad component={EducationView} {...props} />;
const LazyEducationEdit = (props) => <LazyLoad component={EducationEdit} {...props} />;
const LazyEducationViolationEdit = (props) => <LazyLoad component={EducationViolationEdit} {...props} />;
const LazyCampaignAdd = (props) => <LazyLoad component={CampaignAdd} {...props} />;
const LazyCampaignView = (props) => <LazyLoad component={CampaignView} {...props} />;
const LazyCampaignEdit = (props) => <LazyLoad component={CampaignEdit} {...props} />;
const LazyManageAssignments = (props) => <LazyLoad component={ManageAssignments} {...props} />;
const LazyYouthList = (props) => <LazyLoad component={YouthList} {...props} />;
const LazyAuditLogs = (props) => <LazyLoad component={AuditLogs} {...props} />;
const LazyYouthAdd = (props) => <LazyLoad component={YouthAdd} {...props} />;
const LazyYouthView = (props) => <LazyLoad component={YouthView} {...props} />;
const LazyYouthEdit = (props) => <LazyLoad component={YouthEdit} {...props} />;
const LazyUserList = (props) => <LazyLoad component={UserList} {...props} />;
const LazyUserAdd = (props) => <LazyLoad component={UserAdd} {...props} />;
const LazyUserView = (props) => <LazyLoad component={UserView} {...props} />;
const LazyUserEdit = (props) => <LazyLoad component={UserEdit} {...props} />;
const LazyVenueList = (props) => <LazyLoad component={VenueList} {...props} />;
const LazyVenueAdd = (props) => <LazyLoad component={VenueAdd} {...props} />;
const LazyVenueView = (props) => <LazyLoad component={VenueView} {...props} />;
const LazyVenueEdit = (props) => <LazyLoad component={VenueEdit} {...props} />;
const LazyReportsList = (props) => <LazyLoad component={ReportsList} {...props} />;
const LazyReportsArea = (props) => <LazyLoad component={ReportsArea} {...props} />;
const LazyReportsEnforcement = (props) => <LazyLoad component={ReportsEnforcement} {...props} />;
const LazyReportsEducation = (props) => <LazyLoad component={ReportsEducation} {...props} />;
const LazyStoreMerger = (props) => <LazyLoad component={StoreMerger} {...props} />;
const LazyInfograficsDisparateBurden = (props) => <LazyLoad component={InfograficsDisparateBurden} {...props} />;
const LazyInfograficsEnforcementOverview = (props) => (
  <LazyLoad component={InfograficsEnforcementOverview} {...props} />
);
const LazyInfograficsRetailerReduction = (props) => <LazyLoad component={InfograficsRetailerReduction} {...props} />;
const LazyInfograficsYouthAppeal = (props) => <LazyLoad component={InfograficsYouthAppeal} {...props} />;
const LazyInfograficsPosLandscape = (props) => <LazyLoad component={InfograficsPosLandscape} {...props} />;

export default [
  {
    component: LazyDashboard,
    exact: true,
    path: URLS.dashboard,
    isAllAllow: true,
  },
  {
    component: LazyRetailerList,
    exact: true,
    path: URLS.retailers,
    permission: PERMISSIONS.VIEW_RETAILER,
  },
  {
    component: LazyRetailerAdd,
    exact: true,
    path: URLS.retailersPages.add,
    permission: PERMISSIONS.ADD_RETAILER,
  },
  {
    component: LazyRetailerEdit,
    exact: true,
    path: `${URLS.retailersPages.edit}/:id`,
    permission: PERMISSIONS.CHANGE_RETAILER,
  },
  {
    component: LazyRetailerView,
    exact: true,
    path: URLS.retailersPages.view,
    permission: PERMISSIONS.VIEW_RETAILER,
  },
  {
    component: LazyEnforcementView,
    exact: true,
    path: URLS.enforcementPages.view,
    isVisit: true,
    permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_ENFORCEMENT_VISITS],
  },
  {
    component: LazyEnforcementEdit,
    exact: true,
    path: `${URLS.enforcementPages.edit}/:id`,
    isVisit: true,
    permission: [PERMISSIONS.CHANGE_VISIT, PERMISSIONS.VIEW_ENFORCEMENT_VISITS],
  },
  {
    component: LazyEnforcementList,
    exact: true,
    path: URLS.enforcement,
    isVisit: true,
    permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_ENFORCEMENT_VISITS],
  },
  {
    component: LazyEnforcementViolationEdit,
    exact: true,
    path: `${URLS.enforcementPages.violationsEdit}/:id`,
    isVisit: true,
    permission: [PERMISSIONS.CHANGE_VISIT, PERMISSIONS.VIEW_ENFORCEMENT_VISITS],
  },
  {
    component: LazySurvey,
    exact: true,
    path: `${URLS.survey}/:id/:formId`,
    permission: PERMISSIONS.ADD_VISIT,
  },
  {
    component: LazyUserView,
    exact: true,
    path: URLS.userPages.view,
    permission: PERMISSIONS.VIEW_USER,
  },
  {
    component: LazyAssessmentView,
    exact: true,
    path: URLS.assessmentsPages.view,
    isVisit: true,
    permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_ASSESSMENT_VISITS],
  },
  {
    component: LazyEducationView,
    exact: true,
    path: URLS.educationPages.view,
    isVisit: true,
    permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_EDUCATION_VISITS],
  },
  {
    component: LazyAssessmentContainerList,
    exact: true,
    path: URLS.assessments,
    isVisit: true,
    permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_ASSESSMENT_VISITS],
  },
  {
    component: LazyEducationContainerList,
    exact: true,
    path: URLS.education,
    isVisit: true,
    permission: [PERMISSIONS.VIEW_VISIT, PERMISSIONS.VIEW_EDUCATION_VISITS],
  },
  {
    component: LazyAssignmentsList,
    exact: true,
    path: URLS.assignments,
    permission: PERMISSIONS.VIEW_ASSIGNMENT,
  },
  {
    component: LazyCampaignListTable,
    exact: true,
    path: URLS.campaign_list,
    permission: PERMISSIONS.VIEW_ASSIGNMENT,
  },
  {
    component: LazyAssessmentEdit,
    exact: true,
    path: `${URLS.assessmentsPages.edit}/:id`,
    isVisit: true,
    permission: [PERMISSIONS.CHANGE_VISIT, PERMISSIONS.VIEW_ASSESSMENT_VISITS],
  },
  {
    component: LazyEducationEdit,
    exact: true,
    path: `${URLS.educationPages.edit}/:id`,
    isVisit: true,
    permission: [PERMISSIONS.CHANGE_VISIT, PERMISSIONS.VIEW_EDUCATION_VISITS],
  },
  {
    component: LazyEducationViolationEdit,
    exact: true,
    path: `${URLS.educationPages.violationsEdit}/:id`,
    isVisit: true,
    permission: [PERMISSIONS.CHANGE_VISIT, PERMISSIONS.VIEW_EDUCATION_VISITS],
  },
  {
    component: LazyYouthList,
    exact: true,
    path: URLS.youth,
    permission: PERMISSIONS.VIEW_YOUTH,
  },
  { component: LazyAuditLogs, exact: true, path: URLS.auditLogs, permission: PERMISSIONS.VIEW_AUDIT_LOG },
  {
    component: LazySurvey,
    exact: true,
    path: [`${URLS.survey}/:formId/:id`, `${URLS.survey}/:formId/:id/:assignmentId`],
    permission: PERMISSIONS.ADD_VISIT,
  },
  {
    component: LazyCampaignView,
    exact: true,
    path: URLS.campaignPages.view,
    permission: PERMISSIONS.VIEW_CAMPAIGN,
  },
  {
    component: LazyCampaignAdd,
    exact: true,
    path: `${URLS.campaignPages.add}`,
    permission: PERMISSIONS.ADD_CAMPAIGN,
  },
  {
    component: LazyCampaignEdit,
    exact: true,
    path: `${URLS.campaignPages.edit}/:id`,
    permission: PERMISSIONS.CHANGE_CAMPAIGN,
  },
  {
    component: LazyYouthAdd,
    exact: true,
    path: URLS.youthPages.add,
    permission: PERMISSIONS.ADD_YOUTH,
  },
  {
    component: LazyYouthView,
    exact: true,
    path: URLS.youthPages.view,
    permission: PERMISSIONS.VIEW_YOUTH,
  },
  {
    component: LazyYouthEdit,
    exact: true,
    path: `${URLS.youthPages.edit}/:id`,
    permission: PERMISSIONS.CHANGE_YOUTH,
  },
  {
    component: LazyUserList,
    exact: true,
    path: URLS.users,
    permission: PERMISSIONS.VIEW_USER,
  },
  {
    component: LazyUserAdd,
    exact: true,
    path: URLS.userPages.add,
    permission: PERMISSIONS.ADD_USER,
  },
  {
    component: LazyUserEdit,
    exact: true,
    path: `${URLS.userPages.edit}/:id`,
    permission: PERMISSIONS.CHANGE_USER,
  },
  {
    component: LazyManageAssignments,
    exact: true,
    path: `${URLS.campaignPages.manageAssignments}/:id`,
    permission: PERMISSIONS.CHANGE_ASSIGNMENT,
  },
  {
    component: LazyVenueList,
    exact: true,
    path: URLS.venues,
    permission: PERMISSIONS.VIEW_VENUE,
  },
  {
    component: LazyVenueAdd,
    exact: true,
    path: URLS.venuesPages.add,
    permission: PERMISSIONS.ADD_VENUE,
  },
  {
    component: LazyVenueView,
    exact: true,
    path: URLS.venuesPages.view,
    permission: PERMISSIONS.VIEW_VENUE,
  },
  {
    component: LazyVenueEdit,
    exact: true,
    path: `${URLS.venuesPages.edit}/:id`,
    permission: PERMISSIONS.CHANGE_VENUE,
  },
  {
    component: LazyReportsList,
    exact: true,
    path: URLS.reports,
    permission: PERMISSIONS.VIEW_REPORTS,
  },
  {
    component: LazyReportsArea,
    exact: true,
    path: URLS.reportsPages.area,
    permission: PERMISSIONS.VIEW_REPORTS,
  },
  {
    component: LazyReportsEnforcement,
    exact: true,
    path: URLS.reportsPages.enforcement,
    permission: PERMISSIONS.VIEW_REPORTS,
  },
  {
    component: LazyReportsEducation,
    exact: true,
    path: URLS.reportsPages.education,
    permission: PERMISSIONS.VIEW_REPORTS,
  },
  {
    component: LazyStoreMerger,
    exact: true,
    path: URLS.storeMerger,
    permission: PERMISSIONS.VIEW_SIMILARSTORES,
  },
  {
    component: LazyInfograficsDisparateBurden,
    exact: true,
    path: URLS.disparateBurden,
    permission: PERMISSIONS.DISPARATE_BURDEN_VIEW,
  },
  {
    component: LazyInfograficsEnforcementOverview,
    exact: true,
    path: URLS.enforcementOverview,
    permission: PERMISSIONS.VIEW_ENFOCEMENT_OVERVIEW,
  },
  {
    component: LazyInfograficsRetailerReduction,
    exact: true,
    path: URLS.retailerReduction,
    permission: PERMISSIONS.VIEW_RETAILER_REDUCTION,
  },
  {
    component: LazyInfograficsYouthAppeal,
    exact: true,
    path: URLS.youthAppeal,
    permission: PERMISSIONS.VIEW_YOUTH_APPEAL,
  },
  {
    component: LazyInfograficsPosLandscape,
    exact: true,
    path: URLS.posLandscape,
    permission: PERMISSIONS.VIEW_POST_LANDSCAPE,
  },
];
