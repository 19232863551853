import React, { memo, useRef, useEffect, useMemo } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import * as Styled from './styles';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

/**
 * @example
 *
 *  see ./example.js
 */

const BarChart = ({
  title,
  data,
  entityCount,
  bordered,
  options,
  titlePadding,
  height,
  boxPadding,
  hideTitleMargin,
  ...rest
}) => {
  const chartRef = useRef(null);
  const chart = chartRef.current;

  // Fix height for large data for horizontal bar chart
  const chartHeight = useMemo(() => {
    if (chart && height) {
      const newHeight = chart.currentDevicePixelRatio * height;
      return newHeight;
    }
    return 'auto';
  }, [height, chart]);

  useEffect(() => {
    if (!chartHeight) return;
    chart?.resize(chart.width, chartHeight);
  }, [chartHeight, chart]);

  return (
    <Styled.Wrapper bordered={bordered} boxPadding={boxPadding}>
      <Styled.Title titlePadding={titlePadding} bordered={bordered} hideTitleMargin={hideTitleMargin}>
        {title}
      </Styled.Title>
      <Styled.Chart>
        <Bar data={data} options={options} {...rest} ref={chartRef} />
      </Styled.Chart>
      {entityCount >= 0 && <Styled.Visits>{entityCount} retailer(s)</Styled.Visits>}
    </Styled.Wrapper>
  );
};

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  title: PropTypes.string,
  entityCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bordered: PropTypes.bool,
  titlePadding: PropTypes.bool,
  boxPadding: PropTypes.bool,
  height: PropTypes.number,
  hideTitleMargin: PropTypes.bool,
};

BarChart.defaultProps = {
  title: '',
  entityCount: -1,
  bordered: false,
  titlePadding: false,
  boxPadding: false,
  height: 0,
  hideTitleMargin: false,
};

export default memo(BarChart);
