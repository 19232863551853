/* eslint-disable react/prop-types */
import React, { memo, useMemo } from 'react';
import * as Styled from './styles';

function PaginationPageInfo({ total, currentPage, perPage }) {
  const endItems = useMemo(() => perPage * currentPage, [currentPage, perPage]);
  const numberFirstItem = useMemo(() => endItems - perPage + 1, [endItems, perPage]);
  const numberEndItem = useMemo(() => (endItems > total ? total : endItems), [endItems, total]);

  return (
    <Styled.Wrapper>{total ? `${numberFirstItem} - ${numberEndItem} of ${total} items` : '0 items'}</Styled.Wrapper>
  );
}

export default memo(PaginationPageInfo);
