import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'ui-kit';
import { uniqueId } from 'lodash';
import { capitalizeFirstLetter } from 'utils/custom';
import * as Styled from './styles';

/**
 * @example labeledValue
 * const labeledValue = [
 *   { label: 'label', value: '145%' }
 *   { label: 'label2', value: '145%' },
 * ];
 */

const InfoBox = ({ icon, icon2, title, value, labeledValue }) => {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        {icon && <Img size={[24, 24]} src={icon} role="presentation" />}
        {icon2 && <Img size={[24, 24]} src={icon2} role="presentation" />}
        <Styled.Title>{capitalizeFirstLetter(title)}</Styled.Title>
      </Styled.Header>
      {labeledValue.length ? (
        <Styled.Inner>
          {labeledValue.map(({ label, value: infoValue }) => (
            <Styled.Item key={uniqueId()}>
              <Styled.Value>{infoValue}</Styled.Value>
              <Styled.Label>{label}</Styled.Label>
            </Styled.Item>
          ))}
        </Styled.Inner>
      ) : (
        <Styled.Value>{value || value === 0 ? value : '-'}</Styled.Value>
      )}
    </Styled.Wrapper>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  icon2: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labeledValue: PropTypes.array,
};

InfoBox.defaultProps = {
  icon: false,
  icon2: false,
  value: '',
  labeledValue: [],
};

export default memo(InfoBox);
