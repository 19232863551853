/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { uniqueId } from 'lodash';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import * as Styled from './styles';
import TableDropDown from '../TableDropDown';

function ItemsPerPage({ value, options, handlePerPageChange }) {
  const idAda = `RowsPerPageID${uniqueId()}`;
  return (
    <Styled.Wrapper>
      <Styled.Title htmlFor={idAda}>Rows per page:</Styled.Title>
      <TableDropDown value={value} inputId={idAda} options={options} onChange={handlePerPageChange} isSearchable />
    </Styled.Wrapper>
  );
}

ItemsPerPage.defaultProps = {
  options: ITEMS_PER_PAGE_OPTIONS,
};

export default memo(ItemsPerPage);
