export const userAction = {
  enforcementTableData: 'ENFORCEMENT_TABLE_DATA',
  enforcementDetailsData: 'ENFORCEMENT_DETAILS_DATA',
  enforcementAdded: 'ENFORCEMENT_ADDED',
  enforcementEdited: 'ENFORCEMENT_EDITED',
  enforcementDeleted: 'ENFORCEMENT_DELETED',
  enforcementLocked: 'ENFORCEMENT_LOCKED',
  enforcementStartLoad: 'ENFORCEMENT_START_LOAD',
};
const enforcementTableData = (payload) => ({
  type: userAction.enforcementTableData,
  payload,
});

const enforcementDetailsData = (payload) => ({
  type: userAction.enforcementDetailsData,
  payload,
});

const enforcementAdded = (payload) => ({
  type: userAction.enforcementAdded,
  payload,
});

const enforcementEdited = (payload) => ({
  type: userAction.enforcementEdited,
  payload,
});

const enforcementDeleted = (payload) => ({
  type: userAction.enforcementDeleted,
  payload,
});

const enforcementLocked = (payload) => ({
  type: userAction.enforcementLocked,
  payload,
});

const enforcementStartLoad = (payload) => ({
  type: userAction.enforcementStartLoad,
  payload,
});

export {
  enforcementTableData,
  enforcementDetailsData,
  enforcementDeleted,
  enforcementAdded,
  enforcementEdited,
  enforcementLocked,
  enforcementStartLoad,
};
