import styled from 'styled-components';
import { DEVICES } from '_constants';
import { Wrapper as DropDownWrapper } from 'ui-kit/old/ExportDataDropDown/styles';

export const Wrapper = styled.div`
  padding: 40px 25px 50px;

  @media ${DEVICES.tablet} {
    padding: 24px 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  justify-content: space-between;
  max-width: 390px;
`;

export const HeaderWrapper = styled.div`
display: flex;
flex-flow: row nowrap;
margin-left: 5px;

@media ${DEVICES.tablet} {
  display: block;
}

${DropDownWrapper} {
  min-width: 242px;
  margin-right: 16px;

  .BaseSelect__control {
    width: 100%;
  }

  @media ${DEVICES.tablet} {
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 25px;

  > button {
    min-width: 135px;
  }
`;
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
`;

export const FilterWrapper = styled.div`
  padding: 20px 0;
`;
