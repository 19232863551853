import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Inner = styled.div`
  width: auto;
  text-align: center;

  > img {
    margin-bottom: 20px;
  }
`;

export const Text = styled.p`
  font-size: 18px;
`;
