/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const TextCell = ({ text, Icon, children, centerContent, ...rest }) => (
  <Styled.Wrapper centerContent={centerContent}>
    {Icon && (
      <Styled.IconWrapper>
        <Icon />
      </Styled.IconWrapper>
    )}
    <Styled.Text {...rest}>{children || text || '-'}</Styled.Text>
  </Styled.Wrapper>
);

TextCell.propTypes = {
  text: PropTypes.string,
};

TextCell.defaultProps = {
  text: '-',
};

export default TextCell;
