import styled from 'styled-components';
import { DEVICES } from '_constants';
import { UiColors } from 'assets/styles/old/global';
import { Wrapper as TabsWrapper } from 'ui-kit/Tab/tab.style';

const ButtonTab = styled.button.attrs(() => ({ role: 'button', ariaRequired: true, ariaPressed: 'false' }))`
  height: 44px;
  flex-direction: row;
  flex-shrink: 1;
  text-align: center;
  flex-grow: 1;
  width: 100%;
  font-size: 16px;
  font-family: Roboto;
  background: ${UiColors.fillWhiteColor};
  border: 1px ${UiColors.strokeColor} solid;
  border-bottom: ${({ isClicked }) => (isClicked ? `2px  var(--danger)` : `1px ${UiColors.strokeColor}`)} solid;
  box-sizing: border-box;

  @media ${DEVICES.laptop} {
    font-size: 14px;
  }

  @media ${DEVICES.tablet} {
    display: none;
  }

  & + & {
    border-left: none;
  }

  &:hover {
    background: ${UiColors.footerBackgroundColor};
    border: 1px solid ${UiColors.footerBackgroundColor};
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.22);
  }
  &:focus {
    outline-color: var(--focus) !important;
  }
`;

export const TabsPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: ${({ isMainTab }) => (isMainTab ? '1px solid var(--rgb-grey)' : 'none')};
  border-left: ${({ isMainTab }) => (isMainTab ? '1px solid var(--rgb-grey)' : 'none')};
  border-top: ${({ isMainTab }) => (isMainTab ? '1px solid var(--rgb-grey);' : 'none')};
  box-sizing: border-box;
  padding: ${({ amount }) => (amount ? '0 20px 0 0;' : '20px 0 0;')};
  min-height: ${({ isMainTab }) => (isMainTab ? '100%' : '46px')};
  margin: 0;

  ${ButtonTab} {
    flex-shrink: 0;
  }
  ${TabsWrapper} {
    width: ${({ isVisit }) => (isVisit ? '600px' : '400px')};
  }
`;

export const AmountText = styled.div`
  width: 100px;
  padding: 5px;
  text-align: center;
`;

export const MapWrapper = styled.div`
  /*
  * MAP VIEW BASICS: the map div itself, overriding some overly-broad CSS classes impacting these DIVs
  */

  > div {
    height: 689px;
    max-height: 689px;
  }

  img {
    display: inline; /* work around some global style that sets image to block! */
  }

  div.mapViewMainMap div.leaflet-pane {
    vertical-align: unset; /* work around some global style that sets image alignment, and messes with map tiles */
  }

  div.mapViewMainMap.leaflet-container {
    cursor: crosshair !important;
  }

  div.mapViewMainMap,
  div.mapViewStreetView {
    width: 100%;
    height: 100%;
    border: 1px solid #cccccc;
    z-index: 1;
  }

  div.mapViewStreetView {
    display: none;
  }
  div.mapViewStreetView.streetViewEnabled {
    display: block !important;
  }

  /* when StreetView tool is in use, give them half of the height so they can fit onto the screen together */
  div.mapViewMainMap.streetViewEnabled,
  div.mapViewStreetView.streetViewEnabled {
    height: 50%;
  }

  /* override Leaflet 1.x suppressing the ARIA outline (Leaflet 1.7 and earlier) */
  div.mapViewMainMap :focus,
  div.mapViewMainMap {
    outline: -webkit-focus-ring-color auto thin !important;
    outline: revert !important;
  }

  /*
  * EasyButton creates a button, and thus inherits other button styles form the site
  * make it look more like proper Leaflet controls
  * and some exceptions for some buttons which need auto width instead of icon-only width
  */

  button.easy-button-button {
    background-color: white;
    border: 0 none transparent;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 4px;

    font-family: Roboto;
    font-size: 14px;
  }

  div.leaflet-button-pngexport button,
  div.leaflet-button-print button {
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  div.leaflet-button-pngexport button img,
  div.leaflet-button-print button img {
    vertical-align: text-bottom;
  }

  /*
  * MAP POPUPS showing retailer info
  */

  div.leaflet-popup-content {
    .location-title {
      font-weight: bold;
    }

    .streetview-button {
      float: right;
      cursor: pointer;
      width: 20px;
    }
  }

  /*
  * PRINT MEDIA to hide some map controls (not all, scale still makes sense in a static printout) when the page is printed
  */
  @media print {
    div.mapViewMainMap .leaflet-biglayerpanel-control,
    div.mapViewMainMap .leaflet-control-basemapbar,
    div.mapViewMainMap .leaflet-control-zoom,
    div.mapViewMainMap .leaflet-credits-control,
    div.mapViewMainMap .leaflet-bar.leaflet-control,
    div.mapViewMainMap .leaflet-control-attribution {
      display: none !important;
    }
  }
`;

export const Wrapper = styled.div``;

export const ComponentWrapper = styled.div`
  display: none;

  &.active {
    display: block;
  }
`;
