/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { uniqueId } from 'lodash';

import CheckboxView from './checkboxView';
import { Label, WrapperCheckbox, SpanText } from './checkbox.style';

const CheckBox = (props) => {
  const {
    labelText,
    setShowList = () => {},
    showList = false,
    isChecked,
    onChange,
    colorCheckbox,
    name,
    key,
    titleTable = '',
    length = '',
    idCheckBox = '',
    disabled,
    isEnforcement = false,
    innerRef,
    tabIndex,
    iEdisabled,
  } = props;
  const [checked, setChecked] = useState(isChecked || false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = (e) => {
    if (disabled || iEdisabled) return;
    setChecked((prev) => !prev);
    e.preventDefault();
    const idData = e.target.getAttribute('name') || e.target.getAttribute('for');
    onChange(e, { name: idData, checked: !checked, key: idData });
  };

  const handleEnterPress = (e) => {
    if (!disabled) {
      if (e.keyCode === 32) {
        handleCheckboxChange(e);
      }
    }

    if (e.keyCode === 9 && !e.shiftKey && showList && length === idCheckBox) {
      const elem = document.getElementById(`WrapperSelect${titleTable}`);
      elem?.focus();

      if (showList) setShowList(false);
    }
  };

  return (
    <>
      <WrapperCheckbox
        aria-checked={checked}
        name={name}
        key={key}
        onKeyDown={(e) => handleEnterPress(e)}
        onClick={isEnforcement && handleCheckboxChange}
        id={uniqueId()}
      >
        <Label ref={innerRef} disabled={disabled} htmlFor={name} tabIndex={tabIndex || 0} name={key} aria-label={key}>
          <CheckboxView
            disabled={disabled}
            name={name}
            checked={checked}
            key={key}
            aria-label={labelText}
            aria-checked={checked}
            onChange={handleCheckboxChange}
            onKeyDown={(e) => handleEnterPress(e)}
            color={colorCheckbox}
            iEdisabled={iEdisabled}
          />
          <SpanText disabled={iEdisabled} name={name} key={key}>
            {labelText}
          </SpanText>
        </Label>
      </WrapperCheckbox>
    </>
  );
};

export default CheckBox;
