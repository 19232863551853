/* eslint-disable react/prop-types */
import React, { memo, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { BaseSelect } from 'ui-kit';
import { EXPORT_DATA_OPTIONS } from '_constants/old/_constants/EXPORT_DATA';
import * as Styled from './styles';

function ExportDataDropDown({ value, options, onChange, placeholder }) {
  const dropDownValue = useMemo(() => {
    if (isEmpty(value)) return null;
    return {
      label: value,
      value,
    };
  }, [value]);

  return (
    <Styled.Wrapper data-html2canvas-ignore>
      <BaseSelect
        value={dropDownValue}
        inputId="ExportDataDropDown"
        onChange={onChange}
        options={options}
        isSearchable={false}
        placeholder={placeholder}
      />
    </Styled.Wrapper>
  );
}

ExportDataDropDown.propTypes = {};

ExportDataDropDown.defaultProps = {
  options: EXPORT_DATA_OPTIONS,
  placeholder: 'Export & print',
};

export default memo(ExportDataDropDown);
