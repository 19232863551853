import parseAuditLogs from 'utils/old/parseTableData/parseAuditLogs';
import * as TYPES from './Audit.types';

const initAudit = {
  showMessage: false,
  idRevert: { id: 1, idRec: 1, objType: '' },
  audits: {
    data: [
      {
        attributes: {
          action_datetime: '',
          action_flag: ' ',
          agency: {
            abbreviation: '',
            id: 1,
            name: '',
          },
          change_message: [
            {
              field_name: '',
              new_value: '',
              previous_value: '',
            },
          ],
          import_action: false,
          object_type: '',
          record_id: null,
          restored: false,
          user: {
            email: '',
            full_name: '',
            id: 38,
          },
        },
        id: '1',
        type: 'AuditLog',
      },
    ],
    meta: {
      pagination: {
        page: 0,
        pages: 0,
        count: 0,
      },
    },
  },
  auditTableData: {},
};

const setIdRevert = (state, action) => ({
  ...state,
  idRevert: action.payload,
});

const auditLog = (state = initAudit, action) => {
  switch (action.type) {
    case TYPES.GET_AUDIT_LOGS_SUCCESS: {
      const { data, meta } = action?.result;
      const newData = parseAuditLogs(data, action?.timeZone);
      return {
        ...state,
        auditTableData: { data: newData, pagination: meta?.pagination },
      };
    }
    case TYPES.SET_ID_REVERT:
      return setIdRevert(state, action);
    default:
      return state;
  }
};

export default auditLog;
