import React, { useState } from 'react';
import { FILTER_QUERY_BUILDER_TABS, FILTER_TYPES } from '_constants/old/filters';
import { QueryBuilderContainer } from 'modules/Modals/old/FilterModal/components/QueryBuilderContainer';
import PropTypes from 'prop-types';
import { VisitDateAgencyStatus } from './VisitDateAgencyStatus';
import VisitCharacteristics from './VisitCharacteristic';
import EducationCharacteristics from './EducationCharacteristic';
import AssessmentCharacteristics from './AssessmentCharacteristic';
import VisitsRetailerCharacteristics from './VisitsRetailerCharacteristics';
import * as Styled from './styles';

const TABS = [
  {
    id: 1,
    title: FILTER_QUERY_BUILDER_TABS.VISIT_DATE_AGENCY_STATUS,
  },
  {
    id: 2,
    title: FILTER_QUERY_BUILDER_TABS.VISIT_CHARACTERISTICS,
  },
  {
    id: 3,
    title: FILTER_QUERY_BUILDER_TABS.RETAILER_CHARACTERISTICS,
  },
];

export const VisitsQueryBuilder = ({ filterType }) => {
  const [activeTab, setActiveTab] = useState(null);

  const getVisitCharacterisitcsPage = (type) => {
    switch (type) {
      case FILTER_TYPES.ENFORCEMENT:
        return <VisitCharacteristics />;
      case FILTER_TYPES.ASSESSMENT:
        return <AssessmentCharacteristics />;
      case FILTER_TYPES.EDUCATION:
        return <EducationCharacteristics />;
      default:
        return null;
    }
  };

  const displayContent = () => {
    switch (activeTab) {
      case FILTER_QUERY_BUILDER_TABS.VISIT_DATE_AGENCY_STATUS:
        return <VisitDateAgencyStatus filterType={filterType} />;
      case FILTER_QUERY_BUILDER_TABS.VISIT_CHARACTERISTICS:
        return getVisitCharacterisitcsPage(filterType);
      case FILTER_QUERY_BUILDER_TABS.RETAILER_CHARACTERISTICS:
        return <VisitsRetailerCharacteristics />;
      default:
        return null;
    }
  };

  return (
    <Styled.Wrapper>
      <QueryBuilderContainer tabs={TABS} id="wrapper" activeTab={activeTab} setActiveTab={setActiveTab}>
        {displayContent()}
      </QueryBuilderContainer>
    </Styled.Wrapper>
  );
};

VisitsQueryBuilder.propTypes = {
  filterType: PropTypes.string.isRequired,
};
