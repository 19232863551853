/* eslint-disable react/prop-types */

import React from 'react';
import { Label, LabelInner, LabelText, ComponentWrap } from './radioButton.style';

function CustomLabel({ label, labelEnd, component, componentEnd }) {
  return (
    <Label>
      <LabelInner>
        <LabelText>{label}</LabelText>
        <ComponentWrap>{component}</ComponentWrap>
      </LabelInner>
      <LabelInner>
        <LabelText>{labelEnd}</LabelText>
        <ComponentWrap>{componentEnd}</ComponentWrap>
      </LabelInner>
    </Label>
  );
}

export default CustomLabel;
