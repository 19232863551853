import styled, { css } from 'styled-components';

export const ImageContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity ease 0.4s;
`;

export const Image = styled.img`
  width: 90vw !important;
  height: 80vh !important;
  max-height: 80vh !important;
  object-fit: contain !important;

  * &:focus {
    outline: none;
  }
`;

export const DefaultImage = styled.img`
  ${({ width, height, relative, disabled }) => css`
    height: ${height}${relative ? '%' : 'px'};
    width: ${width}${relative ? '%' : 'px'};
    position: relative;
    z-index: 2;
    ${disabled &&
    css`
      filter: invert(0.8);
      pointer-events: none;
      cursor: default;
    `};
  `}
`;
