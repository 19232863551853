/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { BaseSelect } from 'ui-kit';
import * as Styled from './styles';

function FilterDropdown({ options, onChange, value, zIndex, inputId }) {
  const dropDownValue = useMemo(() => {
    if (isEmpty(value)) return null;
    return {
      label: value,
      value,
    };
  }, [value]);

  return (
    <Styled.Wrapper zIndex={zIndex}>
      <BaseSelect
        inputId={inputId}
        value={dropDownValue}
        onChange={onChange}
        options={options}
        isSearchable={false}
        placeholder=""
        aria-label={zIndex}
      />
    </Styled.Wrapper>
  );
}

FilterDropdown.defaultProps = {
  value: null,
  inputId: null,
};

export default FilterDropdown;
