import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon } from './checkbox.style';

// eslint-disable jsx-props-no-spreading
const CheckboxView = ({ className, checked, disabled, iEdisabled, name, ...props }) => (
  <CheckboxContainer disabled={disabled} className={className} name={name}>
    <HiddenCheckbox disabled={disabled} checked={checked} name={name} {...props} tabIndex="-1" />
    <StyledCheckbox disabled={iEdisabled} checked={checked} name={name}>
      <Icon viewBox="0 0 24 24" name={name} focusable="false">
        <polyline points="20 6 9 17 4 12" name={name} />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

CheckboxView.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  iEdisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

CheckboxView.defaultProps = {
  className: '',
  checked: false,
  disabled: false,
  iEdisabled: false,
};

export default CheckboxView;
