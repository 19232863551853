import styled from 'styled-components';
import { UiColors } from 'assets/styles/old/global';
import { DEVICES } from '_constants';

export const List = styled.ul`
  list-style: none;
  margin: 0;
  left: 0;
`;

export const ListItem = styled.li`
  background: ${UiColors.notificationBackgroundColor};
  font-size: 16px;
  color: ${UiColors.fillWhiteColor};
  max-width: 508px;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  border-radius: 6px;
  position: fixed;
  bottom: ${({ bottom }) => bottom};
  left: 50%;
  transform: translateX(-50%);
  z-index: 901;
  box-sizing: border-box;

  @media ${DEVICES.tablet} {
    max-width: 100%;
    transform: none;
    left: 25px;
    right: 25px;
  }

  @media ${DEVICES.mobileL} {
    left: 10px;
    right: 10px;
    font-size: 14px;
    min-height: 40px;
  }
`;

export const ToastIcon = styled.img`
  margin: 0 18px 0 0;

  @media ${DEVICES.tablet} {
    display: none;
  }
`;

export const Wrapper = styled.div``;
/* eslint-enable */
