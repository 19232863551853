import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LinkElem } from 'modules/Header/styles';
import PropTypes from 'prop-types';
import { URLS } from '_constants';
import { AuthEntity } from '_entities';
import { useSelector } from 'react-redux';
import LogOutPopup from '../LogOutPopup';
import * as Styled from './styles';

const { getAuthenticatedUser } = AuthEntity.selectors;

function MenuItem({ subRoute, index, setIsOpenDropDown, tabRef, subHeaderFocus }) {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const isAuthenticated = useSelector(getAuthenticatedUser);

  const handleSelect = useCallback(
    (e, path) => {
      if (path === URLS.logout) {
        e.preventDefault();
        setIsOpenPopup(true);
        return;
      }
      if (path === location.pathname) {
        return;
      }
      setIsOpenDropDown(false);
    },
    [location.pathname, setIsOpenDropDown, setIsOpenPopup],
  );

  const handleSelectByKeyboard = useCallback(
    (e, path, isHelp) => {
      if ((path === location.pathname && e.keyCode !== 27) || e.keyCode === 9) {
        e.preventDefault();
        return;
      }
      if (path === URLS.logout && e.keyCode === 13) {
        e.preventDefault();
        setIsOpenPopup(() => true);
      }
      if ([27, 13].includes(e.keyCode)) {
        setIsOpenDropDown(false);
      }
      if ([32, 13].includes(e.keyCode) && path !== URLS.logout && !isHelp) history.push(path);
    },
    [history, location.pathname, setIsOpenDropDown],
  );

  // This is for short time and will be deleted when infographics will be done
  const subInfoRoute = isAuthenticated ? `${subRoute.path}?new_ui=true&is_auth=true` : `${subRoute.path}?new_ui=true`;

  if (subRoute.isDevider)
    return (
      <Styled.DropDownContentItem>
        <Styled.DeviderText>{subRoute.title}</Styled.DeviderText>
      </Styled.DropDownContentItem>
    );

  return (
    <>
      {subRoute.devider && (
        <Styled.DropDownContentItem>
          <Styled.DeviderText>{subRoute.devider}</Styled.DeviderText>
        </Styled.DropDownContentItem>
      )}
      <Styled.DropDownContentItem border={subRoute.border} borderAll={subRoute.borderAll}>
        {!subRoute.isInfo && (
          <LinkElem
            to={subRoute.isHelp ? { pathname: subRoute.href } : subRoute.path}
            onClick={(e) => handleSelect(e, subRoute.path)}
            onKeyDown={(e) => handleSelectByKeyboard(e, subRoute.path, subRoute.isHelp)}
            aria-label={subRoute.title}
            name={subRoute.path}
            ref={tabRef}
            target={subRoute.isHelp && '_blank'}
            tabIndex={subHeaderFocus === index ? 0 : -1}
          >
            {subRoute.title}
          </LinkElem>
        )}
        {/* This is for short time and will be deleted when infographics will be done */}
        {subRoute.isInfo && <a href={subInfoRoute}>{subRoute.title}</a>}
        {subRoute.path === URLS.logout && <LogOutPopup isOpenPopup={isOpenPopup} setIsOpenPopup={setIsOpenPopup} />}
      </Styled.DropDownContentItem>
    </>
  );
}

MenuItem.propTypes = {
  subRoute: PropTypes.shape({
    path: PropTypes.string,
    href: PropTypes.string,
    border: PropTypes.bool,
    title: PropTypes.string,
    devider: PropTypes.string,
    isInfo: PropTypes.bool,
    isHelp: PropTypes.bool,
    isDevider: PropTypes.bool,
    borderAll: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  tabRef: PropTypes.object.isRequired,
  setIsOpenDropDown: PropTypes.func.isRequired,
  subHeaderFocus: PropTypes.number.isRequired,
};

export default MenuItem;
