import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty, isNaN } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { EXPORT_DATA_OPTIONS_NAMES } from '_constants/old/_constants/EXPORT_DATA';
import ITEMS_PER_PAGE_OPTIONS from '_constants/old/_constants/ITEMS_PER_PAGE_OPTIONS';
import { BUTTON_TYPES, URLS, DEFAULT_SORT, PERMISSIONS, PAGE_TYPES } from '_constants';

import { FilterEntity, YouthEntity } from '_entities';

import { PageContainer, AccessControl } from 'modules';
import YouthTable from 'modules/YouthTable';

import { isValidDate } from 'utils/old/checkSearchDate';
import qsSearchApplyUtil from 'utils/old/qsSearchApplyUtil';
import { newSavePageToPdf } from 'utils/custom';
import { SetQueryParams } from 'utils/old/setQueryParams';
import sortParam from 'utils/old/sortParam';
import { notification } from 'utils/services';

import Filter from 'ui-kit/old/filter';
import { TableSizeControls, Loader, TableTabsWrapper, Button } from 'ui-kit';
import ExportDataDropDown from 'ui-kit/old/ExportDataDropDown';

import processQSParams from 'utils/old/processQSParams';
import * as Styled from './styles';

const { applyFilters } = FilterEntity.actions;
const { youthCSV } = YouthEntity.services;
const { getYouthList } = YouthEntity.actions;
const { getYouthTableData } = YouthEntity.selectors;

const MODAL_NAMES = {
  FILTER_MODAL: 'FILTER_MODAL',
};

const YouthList = (props) => {
  const {
    location: { search },
  } = props;

  const [exportValue, setExportValue] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentPerPage, setCurrentPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[1].value);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState({
    sortBy: DEFAULT_SORT.youthList.prop,
    sortOrder: DEFAULT_SORT.youthList.order,
  });
  const [loading, setLoading] = useState(false);
  const [setModalType] = useState(null);

  const handleModalType = (type) => setModalType(type);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { pagination, data } = useSelector(getYouthTableData);
  const getYouth = ({ page = 1, perPage, sortBy, sortOrder, ...qsParams }) => {
    const params = processQSParams({ page, perPage, sortOrder, sortBy, ...qsParams });
    dispatch(getYouthList(params));
  };
  const {
    oldFilter: { generalFilters },
  } = useSelector((state) => state);

  const loadYouth = useCallback(
    async ({
      page = currentPage,
      perPage = currentPerPage,
      sortBy = sortOption.sortBy,
      sortOrder = sortOption.sortOrder,
      filters = generalFilters,
      ...rest
    } = {}) => {
      try {
        setLoading(true);
        await getYouth({ ...filters, ...rest, page, perPage, sortBy, sortOrder });
        SetQueryParams({
          queryString: {
            ...filters,
            ...rest,
            page,
            page_size: perPage,
            ordering: sortParam({ sortBy, sortOrder }),
          },
          history,
        });
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoading(false);
      }
    },
    [currentPage, dispatch, currentPerPage, SetQueryParams, {}, sortOption],
  );

  const sortHandler = useCallback(
    (sortBy, sortOrder) => {
      setSortOption({ sortBy, sortOrder });
      loadYouth({ sortBy, sortOrder });
    },
    [loadYouth, setSortOption],
  );

  const handlePageChange = useCallback(
    ({ value }) => {
      if (currentPage === value) return;
      setCurrentPage(value);
      loadYouth({ page: value });
    },
    [loadYouth, setCurrentPage],
  );

  const handlePerPageChange = useCallback(
    ({ value }) => {
      if (currentPerPage === value) return;
      setCurrentPerPage(value);
      setCurrentPage(1);
      loadYouth({ perPage: value, page: 1 });
    },
    [setCurrentPerPage, setCurrentPage, loadYouth],
  );

  useEffect(() => {
    const { page, page_size: pageSize, search: searchQuery, ...rest } = queryString.parse(location.search);
    const queryPage = isNaN(+page) || +page === 0 ? currentPage : +page;
    const queryPageSize = isNaN(+pageSize) || +pageSize === 0 ? currentPerPage : +pageSize;
    setCurrentPage(queryPage);
    setCurrentPerPage(queryPageSize);
    setSearchValue(searchQuery || '');

    const newFilters = {
      ...(searchQuery ? { search: searchQuery } : ''),
      ...rest,
    };
    dispatch(applyFilters({ page, page_size: pageSize, ...newFilters }));
    loadYouth({ filters: newFilters, page, perPage: pageSize });
  }, []);

  const handleRemoveSearch = () => {
    if (isEmpty(searchValue)) return;
    qsSearchApplyUtil({
      dispatch,
      getApi: loadYouth,
    });
    setSearchValue('');
  };

  const handleOnChangeInputSearch = (searchVal) => {
    if (isEmpty(searchVal)) {
      setSearchValue('');
      qsSearchApplyUtil({
        dispatch,
        getApi: loadYouth,
      });
    }
    setSearchValue(searchVal);
  };

  const handleClickInput = () => {
    if (isEmpty(searchValue)) return;
    const searchString = isValidDate(searchValue);
    qsSearchApplyUtil({
      search: searchString,
      dispatch,
      getApi: loadYouth,
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const searchString = isValidDate(searchValue);
      qsSearchApplyUtil({
        search: searchString,
        dispatch,
        getApi: loadYouth,
      });
    }
  };

  const handleExportDataChange = ({ value }) => {
    setExportValue(value);
    switch (value) {
      case EXPORT_DATA_OPTIONS_NAMES.EXPORT:
        youthCSV(dispatch, search, (elem) => notification.success(elem));
        break;
      case EXPORT_DATA_OPTIONS_NAMES.PRINT:
        setLoading(true);
        // In order to show loader before html2canvas function start working we should wrap function in setTimeout
        setTimeout(() => newSavePageToPdf({ setExportLoading: setLoading, hasFilters: true }, PAGE_TYPES.TABLE), 0);
        break;
      default:
    }
  };

  const onKeyDownFilter = (e) => {
    if (e.keyCode === 13) {
      handleModalType(MODAL_NAMES.FILTER_MODAL);
      e.preventDefault();
    }
  };

  const openFilterModal = () => {
    handleModalType(MODAL_NAMES.FILTER_MODAL);
  };

  return (
    <PageContainer>
      {loading && <Loader />}
      <Styled.Wrapper>
        <Styled.Header className="table-header">
          <Styled.H1>Youth list</Styled.H1>
          <Styled.HeaderWrapper>
            <ExportDataDropDown onChange={handleExportDataChange} value={exportValue} />
            <AccessControl permission={PERMISSIONS.ADD_YOUTH}>
              <Styled.HeaderBox>
                <Button
                  data-html2canvas-ignore
                  onClick={() => {
                    history.push(URLS.youthPages.add);
                  }}
                  type="button"
                  text="Add Youth"
                  variant={BUTTON_TYPES.DANGER}
                />
              </Styled.HeaderBox>
            </AccessControl>
          </Styled.HeaderWrapper>
        </Styled.Header>
        <Styled.FilterWrapper className="table-filters">
          <Filter
            type="null"
            placeholder="Search youth"
            handleClick={openFilterModal}
            onChangeInput={handleOnChangeInputSearch}
            handleClickInput={handleClickInput}
            onKeyDown={handleKeyDown}
            onKeyDownFilter={onKeyDownFilter}
            onRemoveSearch={handleRemoveSearch}
            searchValue={searchValue}
            isWidth
          />
        </Styled.FilterWrapper>
        <TableTabsWrapper
          hasTabs={false}
          amount={pagination?.count || 0}
          tableComponent={
            <Styled.TableContainer>
              <YouthTable data={data} sortOption={sortOption} sortHandler={sortHandler} />
              <TableSizeControls
                itemsPerPageProps={{
                  handlePerPageChange,
                  value: currentPerPage,
                }}
                pageCountProps={{
                  value: currentPage,
                  pages: pagination?.pages || 1,
                  handlePageChange,
                }}
                paginationInfoProps={{
                  total: pagination?.count || 0,
                  currentPage,
                  perPage: currentPerPage,
                }}
                paginationNavProps={{
                  currentPage,
                  handlePageChange,
                  pages: pagination?.pages || 1,
                  titleTable: 'Youth',
                }}
              />
            </Styled.TableContainer>
          }
        />
      </Styled.Wrapper>
    </PageContainer>
  );
};

YouthList.propTypes = {
  location: PropTypes.object.isRequired,
};

export default YouthList;
