const EXPORT_DATA_OPTIONS_NAMES = {
  EXPORT: 'Export.csv',
  PRINT: 'Print',
  OPEN_ASSIGNMENTS: 'Export Open Assignments',
  REVISITS_NEEDED: 'Export Revisits Needed',
  CAMPAIGN_LIST: 'Export Campaign List',
};

const EXPORT_DATA_OPTIONS = [
  {
    label: EXPORT_DATA_OPTIONS_NAMES.EXPORT,
    value: EXPORT_DATA_OPTIONS_NAMES.EXPORT,
  },
  {
    label: EXPORT_DATA_OPTIONS_NAMES.PRINT,
    value: EXPORT_DATA_OPTIONS_NAMES.PRINT,
  },
];

const EXPORT_CAMPAING_LIST_DATA_OPTIONS = [
  {
    label: EXPORT_DATA_OPTIONS_NAMES.CAMPAIGN_LIST,
    value: EXPORT_DATA_OPTIONS_NAMES.CAMPAIGN_LIST,
  },
  {
    label: EXPORT_DATA_OPTIONS_NAMES.PRINT,
    value: EXPORT_DATA_OPTIONS_NAMES.PRINT,
  },
];

const EXPORT_RETAILERS_VISITS_DATA_OPTIONS = [
  {
    label: EXPORT_DATA_OPTIONS_NAMES.OPEN_ASSIGNMENTS,
    value: EXPORT_DATA_OPTIONS_NAMES.OPEN_ASSIGNMENTS,
  },
  {
    label: EXPORT_DATA_OPTIONS_NAMES.REVISITS_NEEDED,
    value: EXPORT_DATA_OPTIONS_NAMES.REVISITS_NEEDED,
  },
  {
    label: EXPORT_DATA_OPTIONS_NAMES.PRINT,
    value: EXPORT_DATA_OPTIONS_NAMES.PRINT,
  },
];

export {
  EXPORT_DATA_OPTIONS_NAMES,
  EXPORT_DATA_OPTIONS,
  EXPORT_RETAILERS_VISITS_DATA_OPTIONS,
  EXPORT_CAMPAING_LIST_DATA_OPTIONS,
};
