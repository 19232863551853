import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  padding: 0 0 50px;
  word-break: normal;

  @media ${DEVICES.tablet} {
    padding: 0 0 24px;
    word-break: inherit;

    button {
      padding: 7px 0;
    }
  }

  a {
    word-break: inherit;
  }
`;

export const P = styled.p``;
