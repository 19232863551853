import React from 'react';
import PropTypes from 'prop-types';
import Img from 'ui-kit/Img';
import { REMOVE_ICON } from 'assets/icons';
import { isEmpty } from 'lodash';
import * as Styled from './styles';

const PROGRESS_FINISHED = ['VALIDATION_FAILED', 'SUCCESS', 'FAILED'];
const PROGRESS_TEXT = {
  NOT_STARTED: (filename) => `Uploading of ${filename} is scheduled`,
  VALIDATION_IN_PROGRESS: (filename) => `Validation ${filename} in progress`,
  VALIDATION_SUCCESS: () => `Validation finished (without errors)`,
  VALIDATION_FAILED: (filename) =>
    `Validation of ${filename} failed. Check your email for errors in the file that must be resolved. Once errors are corrected, submit the fixed file via the upload tool.`,
  IN_PROGRESS: (filename) => `Uploading ${filename} in progress`,
  SUCCESS: (filename) => `${filename} was uploaded successfully`,
  FAILED: (filename) =>
    `Failed to upload ${filename}. Check your email for errors in the file that must be resolved. Once errors are corrected, submit the fixed file via the upload tool.`,
};

const ProgressBar = ({ importInfo, onClose }) => {
  if (isEmpty(importInfo)) return null;
  const {
    filename,
    items_count: itemsCount,
    items_processed_count: itemsProcessedCount,
    progress_percentage: progressPercentage,
    status,
  } = importInfo?.attributes;
  const hasCount = itemsProcessedCount && itemsCount;

  return (
    <Styled.ProgressBarWrapper>
      {PROGRESS_FINISHED.includes(status) && (
        <Styled.CancelIconWrapper onClick={() => onClose(importInfo?.id)} aria-hidden="true">
          <Img size={[11, 11]} src={REMOVE_ICON} aria-hidden />
        </Styled.CancelIconWrapper>
      )}
      <Styled.FileName>{PROGRESS_TEXT[status](filename)}</Styled.FileName>
      <Styled.Container>
        <Styled.Label>
          {hasCount ? (
            <Styled.BarText>
              <span>{`${itemsProcessedCount}/${itemsCount}`}</span>
              <span>{`${progressPercentage}%`}</span>
            </Styled.BarText>
          ) : (
            <Styled.BarText>{`${progressPercentage}%`}</Styled.BarText>
          )}
        </Styled.Label>
        <Styled.Filler progress={progressPercentage} />
      </Styled.Container>
    </Styled.ProgressBarWrapper>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  importInfo: PropTypes.object,
  onClose: PropTypes.func,
};

ProgressBar.defaultProps = {
  importInfo: {},
  onClose: () => {},
};
