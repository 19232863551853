/**
 * Remove HTML tags with attributes in string
 *
 * @example removeHtmlTags('<div class="test"><p>test</p>')
 * return 'test'
 *
 * @param {string} string
 *
 * @return {string}
 */
export const removeHtmlTags = (string) => {
  const regex = /(<([^>]+)>*)/gi;
  return string.replace(regex, '');
};
