/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState } from 'react';

import { TableWrapper } from 'ui-kit';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { URLS } from '_constants';

const VisitTableBase = ({ data, sortOption, getInitColumns, initialColumns, columns }) => {
  const [activeColumns, setActiveColumns] = useState(initialColumns);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();

  const getToast = () => getToastLock('Campaign', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );

  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: 'campaign',
      route: URLS.campaignPages.edit,
      getToast,
      onlyEdit: true,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitColumns({
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams,
        getToast, // eslint-disable-next-line
      }),
    [dumpedActiveColumns, sortOption, tableControlParams],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data || []}
      tableControlParams={tableControlParams}
      editCellParams={editCellParams}
    />
  );
};

export default memo(VisitTableBase);
