export const APPLY_FILTERS = 'APPLY_FILTERS';
export const SET_SHADOW_FILTER = 'SET_SHADOW_FILTER';
export const CANCEL_FILTERS = 'CANCEL_FILTERS';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const CHANGE_SUBMIT = 'CHANGE_SUBMIT';
export const SET_QUERY_DATA = 'SET_QUERY_DATA';
export const SET_IS_VISIT_BACK = 'SET_IS_VISIT_BACK';
export const SET_VISIT_PATH = 'SET_VISIT_PATH';
export const SET_FILTER_ERROR = 'SET_FILTER_ERROR';
export const SET_WAS_SEARCH = 'SET_WAS_SEARCH';
export const SET_NEW_CRUMB = 'SET_NEW_CRUMB';
export const CLEAR_CRUMBS = 'CLEAR_CRUMBS';
export const GET_JURISDICTIONS = 'GET_JURISDICTIONS';
export const GET_JURISDICTIONS_SUCCESS = 'GET_JURISDICTIONS_SUCCESS';
export const GET_JURISDICTIONS_FAIL = 'GET_JURISDICTIONS_FAIL';
export const GET_AGENCIES = 'GET_AGENCIES';
export const GET_AGENCIES_SUCCESS = 'GET_AGENCIES_SUCCESS';
export const GET_AGENCIES_FAIL = 'GET_AGENCIES_FAIL';
