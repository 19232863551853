import styled from 'styled-components';

export const Title = styled.div`
  line-height: 22px;
  color: var(--dark);
`;

export const Label = styled.label``;

export const Wrapper = styled.div``;
