import React, { memo } from 'react';
import { NO_DATA } from 'assets/icons';
import Img from '../Img/index';
import * as Styled from './styles';

const NoData = () => {
  return (
    <Styled.Wrapper>
      <Styled.Inner>
        <Img src={NO_DATA} sizes={[35, 35]} />
        <Styled.Text>No data</Styled.Text>
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export default memo(NoData);
