import styled from 'styled-components';
import { DEVICES } from '_constants';
import { UiColors } from 'assets/styles/old/global';
import { Button } from '../styles/button.style';
import InputSearch from '../inputSearch';

const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  width: 100%;
  @media print {
    display: none !important;
  }
`;

const FormWrapper = styled.form.attrs(() => ({
  ariaRequired: true,
}))`
  display: flex;
  margin-right: 18px;
`;

const WrapperSearchingGroup = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  display: flex;
  margin-right: 18px;
`;

const WrapperBlock = styled.div.attrs(() => ({ ariaRequired: true }))`
  display: flex;

  @media ${DEVICES.mobileL} {
    ${({ type }) => type !== 'button' && `flex-direction:column`};
  }
`;

const SearchButton = styled(Button)`
  width: 70px;
  padding: 12px 14px;
  background: ${UiColors.notificationBackgroundColor};
  font-family: Roboto;
  font-size: 14px;
  padding: 12px 14px;
  box-sizing: border-box;

  @media ${DEVICES.mobileL} {
    width: 64px;
    height: 36px;
    font-size: 14px !important;
  }

  &:focus {
    border: 1px solid ${(props) => (props.isActive ? UiColors.activeBorder : UiColors.focusColor)};
    outline-color: ${UiColors.focusColor} !important;
  }
`;

const Input = styled(InputSearch)`
  background: ${UiColors.alabasterFillColor} !important;
  border: 1px solid ${UiColors.alabasterFillColor} !important;

  @media ${DEVICES.mobileL} {
    width: 132px !important;
    padding: 0;
  }
`;

const WrapperSelect = styled.div`
  width: 240px;
`;

export { Wrapper, WrapperBlock, SearchButton, WrapperSearchingGroup, Input, WrapperSelect, FormWrapper };
