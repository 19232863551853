import styled from 'styled-components';
import { DEVICES } from '_constants';
import { TabsPanelWrapper } from 'modules/Tabs/styles';
import { ErrorWrapper, StyledSelect } from 'ui-kit/BaseSelect/styles';
import { Wrapper as TabWrapper, ButtonTab } from 'ui-kit/Tab/tab.style';

export const Wrapper = styled.section`
  width: auto;

  ${TabsPanelWrapper} {
    @media ${DEVICES.tablet} {
      width: 100vw;
      transform: translateX(-16px);
      padding: 0 5px;

      > div {
        :last-of-type {
          padding: 0 16px 0 0;
          white-space: nowrap;
        }
      }

      .BaseSelect__control {
        height: 100%;
        border: none;
      }

      ${ButtonTab},
      ${TabWrapper},
      ${ErrorWrapper} {
        height: 100%;
      }

      ${StyledSelect} {
        height: 100%;
        border-right: 1px solid var(--grey);
      }
    }
  }

  .react-table__wrapper {
    .rt-tbody .rt-td:last-of-type {
      border-left: none;
      padding: 7px 20px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const FilterWrapper = styled.div`
  padding: 20px 0;
`;

export const TableContainer = styled.div``;
