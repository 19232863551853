/* eslint-disable import/prefer-default-export */
/* eslint-disable */
import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import apiData from './config';
import httpClient from './httpClient';
import { removeToken } from './authService';

const { baseUrl } = apiData;

const axiosGet = async (url, headers) =>
  httpClient
    .get(`${baseUrl}${url}`, {
      headers,
    })
    .then((response) => response)
    .catch((error) => error.response);

const axiosGetWithoutHeaders = async (url) =>
  httpClient
    .get(`${baseUrl}${url}`)
    .then((response) => response)
    .catch((error) => error.response);

const axiosPatch = (url, headers, payload) =>
  httpClient
    .patch(`${baseUrl}${url}`, payload, {
      headers,
    })
    .then((response) => response)
    .catch((error) => error.response);

const axiosPut = (url, headers, payload) =>
  httpClient
    .put(`${baseUrl}${url}`, payload, {
      headers,
    })
    .then((response) => response)
    .catch((error) => error.response);

const axiosDelete = (url, headers) =>
  httpClient
    .delete(`${baseUrl}${url}`, {
      headers,
    })
    .then((response) => response)
    .catch((error) => error.response);

// .post(`${ baseUrl }${ url }`, payload, {
//   headers,
// })
const axiosPost = async (url, headers, payload) =>
  httpClient({
    method: 'post',
    url: `${baseUrl}${url}`,
    headers,
    data: payload,
  })
    .then((response) => response)
    .catch((error) => error.response);

const axiosPostWithoutToken = async (url, payload) =>
  httpClient({
    method: 'post',
    url: `${baseUrl}${url}`,
    data: payload,
  })
    .then((response) => response)
    .catch((error) => error.response);

export const fetchConstructor = async (method, url, headers, payload) => {
  let methods = null;
  switch (method) {
    case 'get':
      methods = await axiosGet(url, headers);
      break;
    case 'getNoHeaders':
      methods = await axiosGetWithoutHeaders(url);
      break;
    case 'post':
      methods = await axiosPost(url, headers, payload);
      break;
    case 'postWithOutToken':
      methods = await axiosPostWithoutToken(url, payload);
      break;
    case 'delete':
      methods = await axiosDelete(url, headers, payload);
      break;
    case 'put':
      methods = await axiosPut(url, headers, payload);
      break;
    case 'patch':
      methods = await axiosPatch(url, headers, payload);
      break;
    default:
      methods = await axiosGet(url, headers);
      break;
  }
  return methods;
};

// export default fetchRequest;
