import * as TYPES from './Retailer.types';

const initialState = {
  cityList: [{ value: '', label: '' }],
  retailer: {},
  lastRetailerImport: {},
  toastId: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_RETAILER_CITY_SUCCESS: {
      const cityList = action.result.map((item) => ({ value: item[0], label: item[0] }));

      return {
        ...state,
        cityList,
      };
    }

    case TYPES.ADD_RETAILER_SUCCESS: {
      const retailer = action.result;

      return {
        ...state,
        retailer,
      };
    }

    case TYPES.GET_RETAILER_SUCCESS: {
      const retailer = { ...action.result.data, included: action.result.included };

      return {
        ...state,
        retailer,
      };
    }

    case TYPES.EDIT_RETAILER_SUCCESS: {
      const retailer = action.payload;

      return {
        ...state,
        retailer,
      };
    }

    case TYPES.RESET_RETAILER_DATA: {
      return {
        ...state,
        retailer: {},
      };
    }

    case TYPES.RETAILERS_IMPORT_PROGRESS_SUCCESS: {
      const lastImportObject = action?.result?.data?.reduce((maxObj, currentObj) => {
        return parseInt(currentObj?.id) > parseInt(maxObj?.id) ? currentObj : maxObj;
      });

      return {
        ...state,
        lastRetailerImport: lastImportObject,
      };
    }

    case TYPES.RESET_RETAILER_IMPORT_DATA: {
      return {
        ...state,
        lastRetailerImport: {},
      };
    }

    case TYPES.SET_RETAILER_TOAST_ID: {
      return {
        ...state,
        toastId: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
