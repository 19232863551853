/* eslint-disable no-case-declarations */
import parseEnforcements from 'utils/old/parseTableData/parseEnforcements';
import { parseVisitTableData } from 'utils/custom';
import * as TYPES from './Visit.types';

const initialState = {
  visits: {},
  enforcementTableData: {},
  visitInfo: {},
  violation: {},
  assessmentTableData: {},
  educationTableData: {},
  enforcementViolations: {},
  educationViolations: {},
  info: {},
  forms: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.ENFORCEMENT_TABLE_DATA:
      return {
        ...state,
        enforcementTableData: action.payload,
      };

    case TYPES.SET_VISIT_INFO:
      return {
        ...state,
        info: action.payload,
      };

    case TYPES.GET_VISIT_ASSESSMENT_LIST_SUCCESS:
      const { data, included, meta } = action?.result;
      const newData = parseEnforcements({ data, included }, action.timeZone);
      return {
        ...state,
        assessmentTableData: { data: newData, pagination: meta?.pagination },
      };
    case TYPES.GET_VISIT_EDUCATION_LIST_SUCCESS:
      const { data: educationData, included: educationIncluded, meta: educationMeta } = action?.result;
      const newEducationData = parseEnforcements(
        { data: educationData, included: educationIncluded },
        action?.timeZone,
      );
      return {
        ...state,
        educationTableData: { data: newEducationData, pagination: educationMeta?.pagination },
      };

    case TYPES.GET_VISIT_INFO_SUCCESS:
      return {
        ...state,
        visitInfo: action.result,
      };

    case TYPES.GET_VIOLATION_INFO_SUCCESS:
      return {
        ...state,
        violation: action.result,
      };

    case TYPES.RESET_VIOLATION_INFO:
      return {
        ...state,
        violation: {},
      };

    case TYPES.GET_VISIT_LIST_SUCCESS:
      return {
        ...state,
        visits: action.result,
      };

    case TYPES.GET_VISITS_FORMS_SUCCESS: {
      const forms = action.result?.data;
      return {
        ...state,
        forms,
      };
    }

    case TYPES.RESET_VISIT_DATA: {
      return {
        ...state,
        visitInfo: {},
      };
    }

    case TYPES.GET_ENFOCEMENT_VIOLATION_SUCCESS: {
      const { data: enforcementData } = action?.result || [];
      const newVisitData = parseVisitTableData({ data: enforcementData });
      return {
        ...state,
        enforcementViolations: { data: newVisitData },
      };
    }

    case TYPES.GET_EDUCATION_VIOLATION_SUCCESS: {
      const { data: educationViolationData } = action?.result || [];
      const newVisitData = parseVisitTableData({ data: educationViolationData });
      return {
        ...state,
        educationViolations: { data: newVisitData },
      };
    }

    default:
      return state;
  }
};

export default reducer;
