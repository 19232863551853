import styled from 'styled-components';
import { Wrapper as FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup/styles';
import { Container } from 'ui-kit/Loader/styles';
import { DEVICES } from '_constants';

export const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  position: relative;
  ${Container} {
    left: 35%;
  }
  ${FormGroup} {
    padding: 22px 20px;

    @media ${DEVICES.tablet} {
      padding: 10px 18px;
    }
  }
`;

export const DropDownWrapper = styled.div`
  display: flex;
  font-size: 15px;
  align-items: center;
  .BaseSelect__control {
    width: 136px;
  }

  span {
    padding: 2px 5px 5px 3px;
  }

  .styled-label {
    padding: 6px;
  }
`;

export const SelectWrapper = styled.div`
  margin-top: 20px;
`;
