import styled from 'styled-components';

const CheckboxContainer = styled.div.attrs(() => ({ ariaRequired: true }))`
  display: inline-block;
  vertical-align: middle;
  &:focus {
    border: 1px solid black;
  }
  color: ${({ disabled }) => (disabled ? 'var(--dim-grey)' : 'auto')};
`;

const Label = styled.label`
  font-size: 14px;
  font-family: Roboto;
  color: ${({ disabled }) => (disabled ? 'var(--dim-grey)' : 'auto')};
`;

const Icon = styled.svg`
  fill: none;
  stroke: #253041;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div.attrs(() => ({ ariaRequired: true }))`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 9px 0;
  border: 1px solid ${({ color, disabled }) => (disabled ? 'var(--dim-grey)' : color)};
  transition: all 150ms;

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

const SpanText = styled.span`
  color: ${({ disabled }) => disabled && 'var(--dim-grey)'};
`;

export const WrapperCheckbox = styled.div`
  span {
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px;
  }
`;

export { CheckboxContainer, Icon, HiddenCheckbox, StyledCheckbox, Label, SpanText };
