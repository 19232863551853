import React, { useState } from 'react';
import { FILTER_QUERY_BUILDER_TABS } from '_constants/old/filters';
import { QueryBuilderContainer } from 'modules/Modals/old/FilterModal/components/QueryBuilderContainer';
import { IsVaAccount, HasAccess } from 'modules';
import Location from 'modules/Modals/FilterPages/Location';
import { PERMISSIONS } from '_constants';
import VisitsHistory from '../RetailersBuilderSections/VisitsHistory';
import RetailerCharacteristics from '../RetailersBuilderSections/RetailerCharacteristics';
import * as Styled from './styles';

const TABS = [
  {
    id: 1,
    title: FILTER_QUERY_BUILDER_TABS.LOCATION,
  },
  {
    id: 2,
    title: FILTER_QUERY_BUILDER_TABS.RETAILER_CHARACTERISTICS,
  },
  {
    id: 3,
    title: FILTER_QUERY_BUILDER_TABS.VISITS_HISTORY,
  },
];

export const RetailersQueryBuilder = () => {
  const [activeTab, setActiveTab] = useState(null);
  const isVaAccount = IsVaAccount();
  const hasResidentialModule = HasAccess(PERMISSIONS.RESIDENTIAL_LOCATION);

  const displayContent = () => {
    switch (activeTab) {
      case FILTER_QUERY_BUILDER_TABS.LOCATION:
        return <Location />;
      case FILTER_QUERY_BUILDER_TABS.RETAILER_CHARACTERISTICS:
        return <RetailerCharacteristics isVaAccount={isVaAccount} isResidential={hasResidentialModule} />;
      case FILTER_QUERY_BUILDER_TABS.VISITS_HISTORY:
        return <VisitsHistory />;
      default:
        return null;
    }
  };

  return (
    <Styled.Wrapper>
      <QueryBuilderContainer tabs={TABS} id="wrapper" activeTab={activeTab} setActiveTab={setActiveTab}>
        {displayContent()}
      </QueryBuilderContainer>
    </Styled.Wrapper>
  );
};
