/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React from 'react';
import { RetailerCharacteristics } from 'pages/Audit/old/filters/RetailerCharacteristics';
import { LicenseCharacteristics } from 'pages/Audit/old/filters/LicenseCharacteristics';
import { EnforcementCharacteristics } from 'pages/Audit/old/filters/EnforcementCharacteristics';
import { StoreMerger } from 'pages/Audit/old/filters/StoreMerger';
import { FILTER_TABS } from '_constants/old/filters';
import * as Styled from './styles';
import QuickQuery from '../../sections/QuickQuery';
import { FilterNameAddress } from '../../sections/FilterByAddressName';
import { QueryBuilder } from '../../sections/QueryBuilder';
import { AdvancedFilters } from '../../sections/AdvancedFilters';

export const DesktopFilterView = ({ filterType, activeTab }) => {
  const displayFilter = () => {
    switch (activeTab) {
      case FILTER_TABS.QUICK_QUERY:
        return <QuickQuery filterType={filterType} />;
      case FILTER_TABS.FILTER_BY_NAME_ADDRESS:
        return <FilterNameAddress filterType={filterType} />;
      case FILTER_TABS.QUERY_BUILDER:
        return <QueryBuilder filterType={filterType} />;
      case FILTER_TABS.ADVANCED_FILTERS:
        return <AdvancedFilters filterType={filterType} />;
      case FILTER_TABS.RETAILER_CHARACTERISTICS:
        return <RetailerCharacteristics />;
      case FILTER_TABS.LICENSE_CHARACTERISTICS:
        return <LicenseCharacteristics />;
      case FILTER_TABS.ENFORCEMENT_CHARACTERISTICS:
        return <EnforcementCharacteristics />;
      case FILTER_TABS.STORE_MERGER:
        return <StoreMerger />;
      default:
        break;
    }
  };

  return <Styled.Wrapper aria-label="modalDescription">{displayFilter()}</Styled.Wrapper>;
};
