/* eslint-disable */
export default {
  ACCEPT_AUDIT: 'ACCEPT_AUDIT',
  ACCEPT_MODAL_BIG: 'ACCEPT_MODAL_BIG',
  ACCEPT: 'ACCEPT',
  ACCEPT_MODAL: 'ACCEPT_MODAL',
  CANCEL_MODAL: 'CANCEL_MODAL',
  CANCEL_MODAL_BIG: 'CANCEL_MODAL_BIG',
  CANCEL_AUDIT: 'CANCEL_AUDIT',
  DELETE_MODAL_BIG: 'DELETE_MODAL_BIG',
  DELETE_MODAL_SUSPENSION: 'DELETE_MODAL_SUSPENSION',
  DISCARD: 'DISCARD',
  RESET: 'RESET',
  RETAILERS_LIST: 'RETAILERS_LIST',
  RETAILERS: 'RETAILERS',
  RETAILER_CANCEL: 'RETAILER_CANCEL',
  CANCEL: 'CANCEL',
  FILTER: 'FILTER',
  SEARCH: 'SEARCH',
  SAFARI_BUTTON: 'SAFARI_BUTTON',
  SAFARI_BUTTON_HEADER: 'SAFARI_BUTTON_HEADER',
  SAFARI_BUTTON_DROP: 'SAFARI_BUTTON_DROP',
  SUBMIT: 'SUBMIT',
  CLOSE_FREE_TEXT_FIELDS: 'CLOSE_FREE_TEXT_FIELDS',
  MERGER_CLOSE_MODAL_END_EDIT: 'MERGER_CLOSE_MODAL_END_EDIT',
  ADD_RETAILER: 'ADD_RETAILER',
  ACCEPT_REPORT: 'ACCEPT_REPORT',
  CANCEL_REPORT: 'CANCEL_REPORT',
  CANCEL_MERGER: 'CANCEL_MERGER',
  ACCEPT_REPORT_BIG: 'ACCEPT_REPORT_BIG',
  ACCEPT_AGENCY_BIG: 'ACCEPT_AGENCY_BIG',
  CANCEL_AGENCY_BIG: 'CANCEL_AGENCY_BIG',
  VIEW_LOCATION: 'View location history',
  APPLY_NAME: 'Apply',
  CANCEL_NAME: 'Cancel',
};
