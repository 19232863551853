/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';

const Option = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props?.innerProps;
  const newProps = Object.assign(props, { innerProps: rest });
  return <components.Option {...newProps}>{children}</components.Option>;
};

export default Option;
