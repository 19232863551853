/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React from 'react';
import { ComponentWrapper } from './radioButton.style';
import CustomLabel from './customLabel';
import * as Styled from './styles';

const RadioButton = ({ component, value, name, label, onChange, componentEnd, labelEnd }) => {
  const handleRadioChange = ({ target: { checked } }) => onChange({ target: { name, value: checked } });

  return (
    <Styled.Wrapper isActive={value}>
      <Styled.Input type="radio" checked={value} onChange={handleRadioChange} id={label + name} name={name} />
      <Styled.Label htmlFor={label + name} aria-label={label}>
        {labelEnd ? '' : label}
      </Styled.Label>
      {component && !componentEnd && <ComponentWrapper>{component}</ComponentWrapper>}
      {componentEnd && labelEnd && (
        <CustomLabel
          htmlFor={label + name}
          label={label}
          labelEnd={labelEnd}
          component={component}
          componentEnd={componentEnd}
        />
      )}
    </Styled.Wrapper>
  );
};

export default RadioButton;
