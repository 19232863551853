import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { baseUrl } from 'lib/baseUrl';
import { API_URLS } from '_constants';
import { withRouter } from 'react-router-dom';
import L from 'leaflet';
import {
  MAP_MINZOOM,
  MAP_MAXZOOM,
  MAP_BBOX_USA,
  MAP_BASEMAPS,
  APIURL_ACCOUNT_BBOX,
} from '../../../SnapshotMaps/constants';

import GreenInfoLogoImage from '../../../../assets/greeninfo.png';
import HomeIconImage from '../../../../assets/icons/home.svg';
import StreetViewIconImage from '../../../../assets/icons/pegman.svg';
import PrintIconImage from '../../../../assets/icons/printer.svg';
import WhiteXIcon from '../../../../assets/icons/close-white.png';
import CameraIconImage from '../../../../assets/icons/camera.svg';
import TinyArrowDownIconImage from '../../../../assets/icons/tinyArrowDown.svg';
import TinyArrowUpIconImage from '../../../../assets/icons/tinyArrowUp.svg';
import MapLegendImagePublicSchool from '../../../../assets/icons/maplegend/venue-publicschool.png';
import MapLegendImagePrivateSchool from '../../../../assets/icons/maplegend/venue-privateschool.png';
import MapLegendImagePark from '../../../../assets/icons/maplegend/venue-park.png';
import MapLegendImageCollege from '../../../../assets/icons/maplegend/venue-college.png';
import MapLegendImageMultiHousing from '../../../../assets/icons/maplegend/venue-multihousing.png';
import MapLegendImageGovernmentBuilding from '../../../../assets/icons/maplegend/venue-govbuilding.png';
import MapLegendImageHospital from '../../../../assets/icons/maplegend/venue-hospital.png';

require('leaflet/dist/leaflet.css');
require('../../../../mapcontrols/leaflet-basemapbar');
require('../../../../mapcontrols/leaflet-basemapbar.css');
require('../../../../mapcontrols/leaflet-credits.css');
require('../../../../mapcontrols/leaflet-credits');
require('../../../../mapcontrols/leaflet-zoombar.css');
require('../../../../mapcontrols/leaflet-zoombar');
require('../../../../mapcontrols/leaflet-biglayerpanel');
require('../../../../mapcontrols/leaflet-biglayerpanel.css');
require('../../../../mapcontrols/leaflet-biglegendpanel');
require('../../../../mapcontrols/leaflet-biglegendpanel.css');
require('leaflet-easybutton');
require('leaflet-easyprint');

L.WMS = require('../../../../mapcontrols/leaflet-wms');

const loadGoogleMapsApi = require('load-google-maps-api');

const googleMapsApiConfig = {
  clientId: 'gme-countertools',
};

const mapServerUrl = `${baseUrl}mapping/mapserver/`;
const getFeatureInfoUrl = `${baseUrl}mapping/mapserver/getfeatureinfo/`;
const calculablePolygonTypeListingUrl = `${baseUrl}mapping/list/calcpoly/`;

const mapLayersOnByDefault = [
  'venue-v2-park',
  'venue-v2-privateschool',
  'venue-v2-publicschool',
  'venue-v2-college',
  'venue-v2-multihousing',
  'venue-v2-govbuilding',
  'venue-v2-hospital',
];

class VenuesMapView extends React.Component {
  constructor(props) {
    super(props);

    this.leafletMap = undefined; // the L.Map
    this.mapDiv = React.createRef(); // React ref to the map's DIV
    this.mapDivId = `map-${Math.random().toString(36).substring(2, 15)}`; // L.Map() requires the DIV id= attribute; generate a random one

    this.mapStreetViewDiv = React.createRef(); // React ref to the DIV for Google StreetView

    this.safariScreenshotInstructions = React.createRef(); // React ref to the Safari screenshot instructions

    this.querystring = undefined; // see updateVenuesOnMap() and componentDidUpdate()
    this.autoupdatetimer = undefined; // see componentDidUpdate() and simulateComponentDidUpdate(), as well as the end of componentDidMount()

    this.clickedthing = null; // a Retailer that has been clicked on the map; see passClickedThingUpTheChain() for more info
  }

  componentDidMount() {
    // the tabs work by setting the div to 0x0 size, which wreaks havoc on maps
    // wait to become visible for the first time before we do anything
    const waitforvisibility = setInterval(() => {
      const viz =
        this.mapDiv && this.mapDiv.current && this.mapDiv.current.clientWidth && this.mapDiv.current.clientHeight;
      if (!viz) return;

      clearInterval(waitforvisibility);

      this.initMap();
      this.initGoogleStreetView();
      this.initStateBoundingBox();

      // see notes in simulateComponentDidUpdate() and componentDidUpdate() about why we need to do this
      this.autoupdatetimer = setInterval(() => {
        this.simulateComponentDidUpdate();
      }, 0.5 * 1000);

      // maps hate being non-visible, like when the page starts up; use map.invalidateSize() to refresh its knowlewge of width & height
      this.maprefreshtimer = setInterval(() => {
        if (this.leafletMap && this.mapDiv.current.clientWidth && this.mapDiv.current.clientHeight) {
          this.leafletMap.invalidateSize(false);
          this.leafletMap.on('popupopen', this.handlePopupOpen);
        }
      }, 0.5 * 1000);
    }, 0.25 * 1000);
  }

  componentDidUpdate(oldProps) {
    // see also simulateComponentDidUpdate()
    // this component does not receive any props about the search, so we never see componentDidUpdate() events because of filter changes
    // instead, this.autoupdatetimer is a setInterval() which calls simulateComponentDidUpdate() regularly, checking the URL string for filter changes
    // it's not very React, but it's what we have to work with

    // now for real props...

    // onMapViewPopUpButtonClicked - a request to zoom the map to some location
    const { onMapViewPopUpButtonClicked } = this.props;
    if (onMapViewPopUpButtonClicked && onMapViewPopUpButtonClicked !== oldProps.onMapViewPopUpButtonClicked) {
      const lat = parseFloat(onMapViewPopUpButtonClicked.latitude);
      const lng = parseFloat(onMapViewPopUpButtonClicked.longitude);

      switch (onMapViewPopUpButtonClicked.type) {
        case 'streetView':
          if (this.streetviewActive()) this.streetviewOff();
          else this.streetviewOn(lat, lng);
          break;
        case 'venueZoom':
          this.zoomToLatLng(lat, lng);
          break;
        default:
          throw new Error(`onMapViewPopUpButtonClicked got unexpected type: ${onMapViewPopUpButtonClicked.type}`);
      }
    }
  }

  componentWillUnmount() {
    if (this.autoupdatetimer) {
      clearInterval(this.autoupdatetimer);
      this.autoupdatetimer = undefined;

      this.leafletMap.off('popupopen', this.handlePopupOpen);
      clearInterval(this.maprefreshtimer);
      this.maprefreshtimer = undefined;
    }
  }

  handlePopupOpen = (e) => {
    const links = e.popup.getContent().querySelectorAll('a');

    const handleClick = (event) => {
      // eslint-disable-next-line
      const path = event.target.getAttribute('href');
      if (event.isTrusted) window.open(path, '_blank');
    };

    links.forEach((link) => link.addEventListener('click', (event) => handleClick(event)));
  };

  handleMapClickResultsByShowingDetails(latlng, venuenodes) {
    // two very different behaviors here, and some nuance
    // 1
    // if a venue is in my jurisdiction, then its title is a hyperlink trigger to bring up info
    // otherwise, just a plain title so folks can't bring up detailed info
    // 2
    // if there is only 1 venue and its title is a link (per above) then skip the popup: auto-click the link, triggering a details panel

    const htmlblocks = [...venuenodes].map((venuenode) => {
      const id = (venuenode.querySelector('id') && venuenode.querySelector('id').textContent) || 1;
      const lat = (venuenode.querySelector('lat') && venuenode.querySelector('lat').textContent) || 1;
      const lng = (venuenode.querySelector('lng') && venuenode.querySelector('lng').textContent) || 1;
      const name = (venuenode.querySelector('name') && venuenode.querySelector('name').textContent) || '';
      const address = (venuenode.querySelector('address') && venuenode.querySelector('address').textContent) || ''; // eslint-disable-line
      const city = (venuenode.querySelector('city') && venuenode.querySelector('city').textContent) || '';
      const state = (venuenode.querySelector('state') && venuenode.querySelector('state').textContent) || '';
      const zipcode = (venuenode.querySelector('zipcode') && venuenode.querySelector('zipcode').textContent) || ''; // eslint-disable-line
      const type = (venuenode.querySelector('type') && venuenode.querySelector('type').textContent) || ''; // eslint-disable-line

      return `
      <div>
        <a href="${API_URLS.venues}/${id}" data-venue-id="${id}" data-lat="${lat}" data-lng="${lng}" class="location-title">${name}</a>
        <br/>
        <img src="${StreetViewIconImage}" data-venue-id="${id}" data-lat="${lat}" data-lng="${lng}" class="streetview-button" title="Open StreetView at this location" />
        ${address} ${city} ${state} ${zipcode}
        <br/>
        ${type}
      </div>
      `;
    });

    const contentdiv = document.createElement('DIV');
    contentdiv.innerHTML = htmlblocks.join('<br/>');

    const links = contentdiv.querySelectorAll('a.location-title');
    [...links].forEach((link) => {
      link.addEventListener('click', (event) => {
        // keep the click from clicking; React acts strangely
        event.preventDefault();
        event.stopPropagation();

        // pan the map to this venue's point
        // there is a zoomend handler which closes the popup, and a popupclose handler which clears this.clickedthing
        // so do not zoom here e.g. setView()
        const lat = parseFloat(link.getAttribute('data-lat'));
        const lng = parseFloat(link.getAttribute('data-lng'));
        this.leafletMap.panTo([lat, lng]);

        // cache the ID of the venue we clicked; an outside caller could use getWhatsCurrentlyClicked() to find out
        const venueid = link.getAttribute('data-venue-id');
        this.clickedthing = { type: 'venue', id: venueid };
        this.passClickedThingUpTheChain();
      });
    });

    const svbuttons = contentdiv.querySelectorAll('img.streetview-button');
    [...svbuttons].forEach((svbutton) => {
      svbutton.addEventListener('click', (event) => {
        // keep the click from clicking; React acts strangely
        event.preventDefault();
        event.stopPropagation();

        // open StreetView at the given lat,lng or close if it's already open
        if (this.streetviewActive()) {
          this.streetviewOff();
        } else {
          const lat = parseFloat(svbutton.getAttribute('data-lat'));
          const lng = parseFloat(svbutton.getAttribute('data-lng'));
          this.streetviewOn(lat, lng);
        }
      });
    });

    L.popup().setLatLng(latlng).setContent(contentdiv).addTo(this.leafletMap);

    // if there's only 1 feature in the popup, click it now to open the sidebar
    const justclickit = htmlblocks.length === 1 && links.length === 1;
    if (justclickit) {
      links[0].click();
    }
  }

  handleMapClickResultsByZoomingToExtent(latlng, venuenodes) {
    // find the bounding box of all venue points in the set, then zoom the map to it
    const lats = [];
    const lngs = [];
    venuenodes.forEach((venuenode) => {
      const lat = venuenode.querySelector('lat').textContent;
      const lng = venuenode.querySelector('lng').textContent;
      lats.push(lat);
      lngs.push(lng);
    });

    const w = Math.min(...lngs);
    const s = Math.min(...lats);
    const e = Math.max(...lngs);
    const n = Math.max(...lats);
    const bbox = L.latLngBounds([
      [s, w],
      [n, e],
    ]).pad(0.1); // eslint-disable-line prettier/prettier
    this.leafletMap.fitBounds(bbox);
  }

  initMap() {
    // the L.Map and our basic amenities: nicer zoom bar, GreenInfo credits, scale bar, base map selector
    this.leafletMap = L.map(this.mapDivId, {
      minZoom: MAP_MINZOOM,
      maxZoom: MAP_MAXZOOM,
      keyboard: true, // keyboard basics: arrow keys pan, +- zoom
      scrollWheelZoom: !L.Browser.ie, // IE bug, allow scroll means we can't scroll within other controls e.g. layer panel
      zoomControl: false, // custom zoom control below
      tap: false, // work around Safari double-clicking the map
    }).fitBounds(MAP_BBOX_USA);
    this.mapDiv.current.ariaLabel = 'Map. Pan with arrow keys. Zoom in with + key. Zoom out with - key.'; // eslint-disable-line prettier/prettier

    L.basemapbar({
      layers: MAP_BASEMAPS,
    })
      .addTo(this.leafletMap)
      .selectLayer(MAP_BASEMAPS[0].label);

    this.leafletMap.zoombar = L.zoombar({
      position: 'topright',
      homeBounds: MAP_BBOX_USA, // will be changed when we have the state's bbox
      homeIconUrl: HomeIconImage,
    }).addTo(this.leafletMap);

    L.control
      .scale({
        // eslint-disable-line prettier/prettier
        position: 'bottomright',
        updateWhenIdle: true,
      })
      .addTo(this.leafletMap);

    L.ginfocredits({
      position: 'bottomleft',
      image: GreenInfoLogoImage,
      link: 'http://www.greeninfo.org/',
      text: 'Interactive mapping<br/>by GreenInfo Network',
    }).addTo(this.leafletMap);

    //
    // custom control: print button
    //
    this.leafletMap.printbutton = L.easyButton(
      `<img src="${PrintIconImage}" style="width: 18px; height: 18px;" alt="print icon" title="Print" /> Print`,
      () => {
        window.print();
      },
      {
        position: 'bottomleft',
      },
    ).addTo(this.leafletMap);
    this.leafletMap.printbutton._container.classList.add('leaflet-button-print'); // eslint-disable-line no-underscore-dangle

    window.addEventListener('beforeprint', () => {
      // add the special legend control
      this.leafletMap.addControl(this.leafletMap.legendpanel);
      this.leafletMap.legendpanel.updateLegend();

      // force map's DIV width to fit to USA paper minus presumed margins
      const center = this.leafletMap.getCenter();
      const mapdiv = this.leafletMap.getContainer();
      mapdiv.style.width = '7.205in'; // pixel-fussed to match the results bar
      this.leafletMap.invalidateSize().panTo(center);
    });

    window.addEventListener('afterprint', () => {
      // undo forced width
      const center = this.leafletMap.getCenter();
      const mapdiv = this.leafletMap.getContainer();
      mapdiv.style.removeProperty('width');
      this.leafletMap.invalidateSize().panTo(center);

      // remove custom legend control
      this.leafletMap.removeControl(this.leafletMap.legendpanel);
    });

    //
    // custom control: export PNG button
    //
    this.leafletMap.pngexport = L.easyPrint({
      sizeModes: ['Current'], // no other size really works, we even have to assert WxH in callbacks to make this work
      exportOnly: true,
      hidden: true,
      hideControlContainer: false, // we DO want some controls to be visible in the export, so specify here which ones to hide
      hideClasses: [
        // easyPrint bug, only the 1st hit to any class, is in fact hidden; also, there MUST be 1 element for each class listed or else kaboom
        'leaflet-biglayerpanel-control',
        'leaflet-control-basemapbar',
        'leaflet-control-zoom',
        'leaflet-button-streetview',
        'leaflet-control-attribution',
        'leaflet-credits-control',
        'leaflet-button-print',
        'leaflet-button-pngexport',
      ],
      tileWait: 2 * 1000, // allow a moment for tiles to load
    }).addTo(this.leafletMap);

    this.leafletMap.pngbutton = L.easyButton(
      `<img src="${CameraIconImage}" style="width: 18px; height: 18px;" alt="Export an image of this map" title="Export this map as a PNG image" /> <span>Export .png</span>`,
      () => {
        if (L.Browser.safari) this.printTheMapSafari();
        else this.leafletMap.pngexport.printMap('CurrentSize', 'VenuesMap');
      },
      {
        position: 'bottomleft',
      },
    );
    this.leafletMap.pngbutton.showbusy = (busybool) => {
      const textspan = this.leafletMap.pngbutton._container.querySelector('span > span'); // eslint-disable-line no-underscore-dangle
      const msg = busybool ? 'Please Wait' : 'Export .png';
      textspan.innerText = msg;
    };
    this.leafletMap.pngbutton.addTo(this.leafletMap);
    this.leafletMap.pngbutton._container.classList.add('leaflet-button-pngexport'); // eslint-disable-line no-underscore-dangle

    this.leafletMap.on('easyPrint-start', () => {
      // workaround for a bizarre bug when using heigeo.WMS with React
      // every time one switches away from Map View into List View, something in React/Leaflet/WMS creates a clone of the overlay IMG but with 0x0 size and zero-area bounding box
      // this invalid image is ignored & harmless when simply dragging and seeingthe L.Map, but is fatal to dom2image trying to fetch a copy of those broken images
      // so, hack/workaround is to prune these out
      const wmsimages = this.mapDiv.current.querySelectorAll(
        'div.leaflet-pane.leaflet-overlay-pane img.leaflet-image-layer.leaflet-zoom-animated',
      ); // eslint-disable-line prettier/prettier
      [...wmsimages].forEach((img) => {
        const isphantom = img.style.width === '0px';
        if (isphantom) img.parentElement.removeChild(img);
      });

      // workaround for a bug in easyPrint: set an explicit width & height on the map DIV, so easyPrint will get the size right
      // without this, you get big empty space around the map inside a giant canvas
      // see the easyPrint-finished event handler, which clears these so the map can be responsive again
      const mapsize = this.leafletMap.getSize();
      const mapdiv = this.leafletMap.getContainer();
      mapdiv.style.width = `${mapsize.x}px`;
      mapdiv.style.height = `${mapsize.y}px`;

      this.leafletMap.addControl(this.leafletMap.legendpanel);
      this.leafletMap.legendpanel.updateLegend();
    });
    this.leafletMap.on('easyPrint-finished', () => {
      const mapdiv = this.leafletMap.getContainer();
      mapdiv.style.removeProperty('width');
      mapdiv.style.removeProperty('height');

      this.leafletMap.removeControl(this.leafletMap.legendpanel);
    });

    //
    // custom control: the big layer control in the top-left
    // and  the associated legend control which will come up when we use Print or Export PNG
    // see also the handleWmsGetFeatureInfoResults() callback that we monkey-patch into the biglayerpanel since the panel is also our WMS GetFeatureInfo interface
    // see also updateVenuesOnMap() which will set filtering params on themap.biglayerpanel, to show only retailers matching
    //
    {
      // fetch the list of CalculablePolygons (varies between Accounts) which we present as layers and demographics options in the big layer panel
      const request = new XMLHttpRequest();
      request.onreadystatechange = () => {
        if (request.readyState === 4 && request.status === 200) {
          // remove the Entire State option, that's always on
          // then fix RGB codes to HTML
          let calcpolytypes = JSON.parse(request.responseText);
          calcpolytypes = calcpolytypes.filter((polytype) => polytype.slug !== 'entirestate');
          calcpolytypes.forEach((polytype) => {
            if (polytype.bordercolor.indexOf('#') === 0) return;
            const [r, g, b] = polytype.bordercolor.trim().split(/\s+/);
            polytype.bordercolor = `rgb(${r}, ${g}, ${b})`; // eslint-disable-line no-param-reassign
          });

          this.initAddBigLayerPanelToMap(calcpolytypes);
        } // end of if 200
      }; // end onreadystatechange

      const token = localStorage.getItem('Authorization');
      const apiurl = `${calculablePolygonTypeListingUrl}?exclude_if_no_polygons=true`;
      request.open('GET', apiurl);
      request.setRequestHeader('Authorization', `Token ${token}`);
      request.send();
    }
  }

  initAddBigLayerPanelToMap(calcpolytypes) {
    // add the layer panel & legend panel controls.
    // feeding it the demographic scores, list of layers, etc. which we got from the API
    this.leafletMap.biglayerpanel = L.biglayerpanel({
      collapsedArrowIcon: TinyArrowDownIconImage,
      expandedArrowIcon: TinyArrowUpIconImage,
      wmsbaseurl: mapServerUrl,
      getfeatureinfourl: getFeatureInfoUrl,
      maplegendimages: {
        'venue-publicschool': MapLegendImagePublicSchool,
        'venue-privateschool': MapLegendImagePrivateSchool,
        'venue-park': MapLegendImagePark,
        'venue-college': MapLegendImageCollege,
        'venue-multihousing': MapLegendImageMultiHousing,
        'venue-govbuilding': MapLegendImageGovernmentBuilding,
        'venue-hospital': MapLegendImageHospital,
      },
      calculableBoundaryLayers: calcpolytypes,
    }).addTo(this.leafletMap);

    mapLayersOnByDefault.forEach((layerid) => {
      this.leafletMap.biglayerpanel.toggleLayer(layerid, true);
    });

    this.leafletMap.legendpanel = L.biglegendpanel({
      layerpanel: this.leafletMap.biglayerpanel,
    }); // do not add to the map; see the print & PNG tools below

    // handle WMS GetFeatureInfo results; our biglayerpanel has the WMS interface since it manages layers,
    // but content like popups is a bit bulky to go into a legend control... so let's bring it in here
    // the GetFeatureInfo call will hand us back a GML/XML string, probably containing Venue points
    // two very different behaviors
    // if the map is zoomed in to MAP_MAXZOOM, then display the popup of all venues found
    // else, look over the venues to find the bounding box of all of them, and zoom to that box instead
    this.leafletMap.biglayerpanel.handleWmsGetFeatureInfoResults = (latlng, xmlstring) => {
      const queryresults = new DOMParser().parseFromString(xmlstring, 'application/xml');
      const layerstocheckforvenues = [
        'venue-v2-park-polygon',
        'venue-v2-publicschool-point',
        'venue-v2-publicschool-polygon',
        'venue-v2-privateschool-point',
        'venue-v2-privateschool-polygon',
        'venue-v2-college-point',
        'venue-v2-college-polygon',
        'venue-v2-multihousing-point',
        'venue-v2-multihousing-polygon',
        'venue-v2-govbuilding-point',
        'venue-v2-govbuilding-polygon',
        'venue-v2-hospital-point',
        'venue-v2-hospital-polygon',
      ];
      let venuenodes = [];
      layerstocheckforvenues.forEach((layerid) => {
        const thesefeatures = queryresults.querySelectorAll(`${layerid}_feature`);
        venuenodes = venuenodes.concat(...thesefeatures);
      });
      if (!venuenodes.length) return; // still none = nothing to see

      // de-duplicate the Venue features
      // since both point & polygon are shown and overlap, a click may return both of the selfsame feature
      // we can key by id field, since we know all things here are of the same data type (Venue) so come from the same table with the same numbering
      const alreadyseen = {};
      venuenodes = venuenodes.filter((venuenode) => {
        const id = venuenode.querySelector('id').textContent;
        if (alreadyseen[id]) return false;
        alreadyseen[id] = true;
        return true;
      });

      // should we show the venues' info, or zoom to the exent of the venues?
      // if we're at max zoom or there's only 1 venue, let's go with showing details popup
      // otherwise, calculate the extent of venues and zoom in, e.g. must be a cluster
      const showdetails = this.leafletMap.getZoom() >= MAP_MAXZOOM || venuenodes.length === 1;
      if (showdetails) this.handleMapClickResultsByShowingDetails(latlng, venuenodes);
      else this.handleMapClickResultsByZoomingToExtent(latlng, venuenodes);
    };

    /*
    this.leafletMap.on('popupclose', () => {
      // this.clickedthing is populated by clicking a title in a popup; when the popup closes, clear this
      // see also passClickedThingUpTheChain() for more info
      // see also the zoomend handler below which closes the popup
      this.clickedthing = null;
      this.passClickedThingUpTheChain();
    });
    */

    this.leafletMap.on('zoomend', () => {
      // zooming tends to shift popups slightly since the clicked latlng-to-pixels isn't quite perfect,
      // ESPECIALLY with clustering where the points have in fact moved!
      // so when zoom changes, just give up and clear the popup
      this.leafletMap.closePopup();
    });
  }

  initGoogleStreetView() {
    // custom control: Google Street View
    // button triggers the draggable marker (or clears it)
    // dragging marker updates the GSV, and moving the GSV moves the marker
    this.leafletMap.streetview = {};

    this.leafletMap.streetview.button = L.easyButton(
      `<img src="${StreetViewIconImage}" style="width: 30px; height: 20px;" title="Turn on/off the Google Street View panel" alt="Toggle the Google Street View panel" />`,
      () => {
        // turn the GSV tool on/off
        if (this.streetviewActive()) this.streetviewOff();
        else this.streetviewOn();
      },
      {
        position: 'topright',
      },
    ).addTo(this.leafletMap);
    this.leafletMap.streetview.button._container.classList.add('leaflet-button-streetview'); // eslint-disable-line no-underscore-dangle

    this.leafletMap.streetview.marker = L.marker([0, 0], {
      title: 'Drag to move the Google Street View',
      draggable: true,
      icon: L.icon({
        iconUrl: StreetViewIconImage,
        iconSize: [39, 39],
        iconAnchor: [20, 39],
      }),
      riseOnHover: true,
    }).on('dragend', () => {
      this.streetviewUpdateFromMarker();
    });

    const gmapiloader = loadGoogleMapsApi({
      client: googleMapsApiConfig.clientId,
    });
    gmapiloader.then((googleMaps) => {
      this.leafletMap.streetview.service = new googleMaps.StreetViewService();
      this.leafletMap.streetview.panorama = new googleMaps.StreetViewPanorama(this.mapStreetViewDiv.current, {
        position: new googleMaps.LatLng(0, 0),
        pov: { heading: 0, pitch: 0, zoom: 1 },
        addressControl: false,
        linksControl: true,
        zoomControl: true,
        zoomControlOptions: {
          style: googleMaps.ZoomControlStyle.SMALL,
        },
        enableCloseButton: false, // the map control is to goggle the tool
      });

      googleMaps.event.addListener(this.leafletMap.streetview.panorama, 'position_changed', () => {
        const glatlng = this.leafletMap.streetview.panorama.getPosition();
        const markerlatlng = L.latLng([glatlng.lat(), glatlng.lng()]);

        // place the marker where the StreetView pano is
        // do not fire a dragend to re-request a pano, as that creates a loop; we just got the pano so we know the pano is up to date!
        this.leafletMap.streetview.marker.setLatLng(markerlatlng);
      });
    });
  }

  initStateBoundingBox() {
    const request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        const bbox = JSON.parse(request.responseText);
        const bounds = [
          [bbox.s, bbox.w],
          [bbox.n, bbox.e],
        ]; // eslint-disable-line prettier/prettier
        this.leafletMap.fitBounds(bounds);
        this.leafletMap.zoombar.options.homeBounds = bounds;
      }
    };
    request.open('GET', APIURL_ACCOUNT_BBOX);
    request.send();
  }

  streetviewActive() {
    // is the Street View tool running?
    return this.leafletMap.hasLayer(this.leafletMap.streetview.marker);
  }

  streetviewOn(lat, lng) {
    // start the Street View tool

    // show the DIV
    this.mapDiv.current.classList.add('streetViewEnabled');
    this.mapStreetViewDiv.current.classList.add('streetViewEnabled');

    // add the marker and zoom to it
    // marker is either to [lat,lng] if given, else default to map center
    let latlng = this.leafletMap.getCenter();
    if (!Number.isNaN(parseFloat(lat)) && !Number.isNaN(parseFloat(lng))) latlng = L.latLng(lat, lng);
    this.leafletMap.streetview.marker.setLatLng(latlng).addTo(this.leafletMap);
    this.leafletMap.setView(latlng, MAP_MAXZOOM);

    // hack to please WAVE: give the marker a alt tag, but it can't be the same as the title
    this.leafletMap.streetview.marker._icon.alt = 'Marker showing current location of Street View'; // eslint-disable-line no-underscore-dangle

    // update StreetView to focus on that marker
    this.streetviewUpdateFromMarker();

    // minimize the layer picker panel, as the map is half its usual height and some folks have small screens
    this.leafletMap.biglayerpanel.collapsePanel();
  }

  streetviewOff() {
    // stop the Street View tool

    // hide the panel
    this.mapDiv.current.classList.remove('streetViewEnabled');
    this.mapStreetViewDiv.current.classList.remove('streetViewEnabled');

    // remove the marker from the map
    this.leafletMap.removeLayer(this.leafletMap.streetview.marker);

    // re-expand the layer picker panel, now that we're back to full height
    this.leafletMap.biglayerpanel.expandPanel();
  }

  streetviewUpdateFromMarker() {
    const markerlatlng = this.leafletMap.streetview.marker.getLatLng();
    const glatlng = new google.maps.LatLng(markerlatlng.lat, markerlatlng.lng); /* eslint-disable-line no-undef */
    const pano = this.leafletMap.streetview.panorama;
    const svc = this.leafletMap.streetview.service;

    // look for the closest pano to this latlng, and change the view over to it
    const panooptions = {
      location: glatlng,
      radius: 100, // how far (meters) to look for a panorama at this proposed latlng
      preference: 'nearest', // nearest or best; best is sbujective, we want accurate location
      source: google.maps.StreetViewSource.OUTDOOR, // eslint-disable-line no-undef
    };
    svc.getPanorama(panooptions, (data) => {
      if (!data) return; // no GSV here
      pano.setPosition(data.location.latLng);
      pano.setVisible(true);
    });
  }

  updateVenuesOnMap() {
    // eslint-disable-line
    // given a query params string, send off to the unpaginated view used by the map
    // that special server-side view filter to ALL venues matching the search (not one page)
    // and will return a result-set ID which we pass into our map filters (not a 50 MB list of 100,000 records)

    // prep work: unlike Retailers Map View (Retailers & Visits)
    // this one has most filters in the quick_type URL param string, and some in the URL string as usual; need to decode & compose
    const params = queryString.parse(this.querystring);
    if (params.quick_type) {
      Object.assign(params, queryString.parse(params.quick_type));
      delete params.quick_type;
    }
    const apiquerystring = queryString.stringify(params);

    const apiurl = `${baseUrl}venues_map?postv2=true&${apiquerystring}`; // don't forget POSTv2 flag
    const request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        const results = JSON.parse(request.responseText);

        // race condition: if Venues API is faster than list-polygons API, crash
        const waitforit = setInterval(() => {
          if (!this.leafletMap.biglayerpanel) return;
          clearInterval(waitforit);
          this.leafletMap.biglayerpanel.setVenueFilterSetId(results.retailersetid);
        }, 0.1 * 1000);
      } // end of if 200
    }; // end onreadystatechange

    const token = localStorage.getItem('Authorization');
    request.open('GET', apiurl);
    request.setRequestHeader('Authorization', `Token ${token}`);
    request.send();
  }

  passClickedThingUpTheChain() {
    // call this any time this.clickedthing is set, to pass it to our onMapViewFeatureSelected prop and up the chain to whoever cares
    // use case could be to show some details panel, or navigate to a retailer-deals view, ...
    const { onMapViewFeatureSelected } = this.props;
    if (onMapViewFeatureSelected) {
      onMapViewFeatureSelected(this.clickedthing);
    }
  }

  zoomToLatLng(lat, lng) {
    this.leafletMap.setView([lat, lng], MAP_MAXZOOM);
  }

  printTheMapSafari() {
    // Safari doesn't support html2canvas nor dom-to-image reliably, due to security model when loading remote data
    // and our development process using an API at a different origin than our frontend
    // fortunately, Mac has a screenshot tool, so our workaround was to present instructions for how to use it
    // - put the map into "print mode" with controls hidden and legend showing
    // - show instructions
    // - when instructions close, put the map back out of print mode

    // hide the map controls that shouldn't show up in a print / screenshot sort of output
    const hidethese = [
      this.mapDiv.current.querySelector('div.leaflet-biglayerpanel-control'),
      this.mapDiv.current.querySelector('div.easy-button-container'),
      this.mapDiv.current.querySelector('div.leaflet-control-basemapbar'),
      this.mapDiv.current.querySelector('div.leaflet-control-zoom'),
      this.mapDiv.current.querySelector('div.leaflet-credits-control'),
      this.mapDiv.current.querySelector('div.leaflet-control-attribution'),
      this.mapDiv.current.querySelector('div.leaflet-button-pngexport'),
      this.mapDiv.current.querySelector('div.leaflet-button-print'),
    ];

    hidethese.forEach((element) => {
      element.style.display = 'none'; // eslint-disable-line no-param-reassign
    });

    // add the legend control and update it to the current layers
    this.leafletMap.addControl(this.leafletMap.legendpanel);
    this.leafletMap.legendpanel.updateLegend();

    // show the instructions bar
    // also focus it, because Safari for some reason focuses the map, giving it a blue halo
    this.safariScreenshotInstructions.current.classList.add('showingSafariScreenshotInstructions');
    setTimeout(() => {
      this.safariScreenshotInstructions.current.querySelector('button').focus();
    }, 0.1 * 1000);
  }

  printTheMapSafariStop() {
    // remove the legend control
    this.leafletMap.removeControl(this.leafletMap.legendpanel);

    // hide the instructions bar
    this.safariScreenshotInstructions.current.classList.remove('showingSafariScreenshotInstructions');

    // show those map controls that we hid
    const hidethese = [
      this.mapDiv.current.querySelector('div.leaflet-biglayerpanel-control'),
      this.mapDiv.current.querySelector('div.easy-button-container'),
      this.mapDiv.current.querySelector('div.leaflet-control-basemapbar'),
      this.mapDiv.current.querySelector('div.leaflet-control-zoom'),
      this.mapDiv.current.querySelector('div.leaflet-credits-control'),
      this.mapDiv.current.querySelector('div.leaflet-control-attribution'),
      this.mapDiv.current.querySelector('div.leaflet-button-pngexport'),
      this.mapDiv.current.querySelector('div.leaflet-button-print'),
    ];

    hidethese.forEach((element) => {
      element.style.removeProperty('display'); // eslint-disable-line no-param-reassign
    });
  }

  simulateComponentDidUpdate() {
    // see note in componentDidUpdate() for why we need to do this

    // changes to the search panel would be reflected in the URL string, and that URL string is the same as we would send to DRF to get results
    // well, minus pagination and sorting stuff; so trim those off, and see if our resulting string has in fact changed
    let querystring = window.location.search.match(/\?(.+)$/); // then strip some params that wouldn't affect a map view
    if (querystring) {
      querystring = querystring[1]; // eslint-disable-line prefer-destructuring
      querystring = querystring.replace(/&?ordering=-?\w+/, '');
      querystring = querystring.replace(/&?page=\d+/, '');
      querystring = querystring.replace(/&?page_size=\d+/, '');
    }

    // console.debug([ 'simulateComponentDidUpdate()', querystring, this.querystring, querystring !== this.querystring ]); // eslint-disable-line
    if (querystring !== this.querystring) {
      this.querystring = querystring;
      this.updateVenuesOnMap();
    }
  }

  render() {
    return (
      <div>
        <div ref={this.safariScreenshotInstructions} className="mapViewSafariScreenshotInstructions">
          <button
            type="button"
            onClick={this.printTheMapSafariStop.bind(this)}
            aria-label="Close this and restore the controls on the map"
          >
            <img src={WhiteXIcon} alt="" />
          </button>
          Use Command ⌘-Shift-4 to drag a rectangle around this map and save a PNG.
        </div>
        <div ref={this.mapDiv} id={this.mapDivId} className="mapViewMainMap" />
        <div ref={this.mapStreetViewDiv} className="mapViewStreetView" />
      </div>
    );
  }
}

VenuesMapView.propTypes = {
  onMapViewPopUpButtonClicked: PropTypes.object,
  onMapViewFeatureSelected: PropTypes.func,
};

VenuesMapView.defaultProps = {
  onMapViewPopUpButtonClicked: null,
  onMapViewFeatureSelected: null,
};

export default withRouter(VenuesMapView);
