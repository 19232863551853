import React from 'react';

const ArrowLeft = () => (
  <svg
    alt="arrow left"
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.84593 6.19851C0.540675 5.9064 0.540676 5.4328 0.84593 5.14068L5.82034 0.380447C6.1256 0.0883349 6.62051 0.0883355 6.92577 0.380448C7.23102 0.67256 7.23102 1.14617 6.92577 1.43828L2.50407 5.6696L6.92576 9.90092C7.23101 10.193 7.23101 10.6666 6.92576 10.9588C6.6205 11.2509 6.12559 11.2509 5.82033 10.9588L0.84593 6.19851Z"
      fill="#0D0D0D"
    />
  </svg>
);

export default ArrowLeft;
