import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { deviceLess } from '_constants/DEVICES';

import Calendar from './icons/Calendar.svg';
import arrowLeft from './icons/datepicker-arrow-left.svg';
import arrowRight from './icons/datepicker-arrow-right.svg';

export const CalendarIconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;

export const CalendarLabel = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  font-size: 14px;
  margin-bottom: 5px;
  white-space: nowrap;
`;

export const CustomInputWrapper = styled.div`
  position: relative;

  input::-ms-clear {
    display: none;
  }
`;

export const ReactPickerWrapper = styled.div`
  .react-datepicker-popper {
    z-index: 99999;
    background-color: var(--white);

    @media ${DEVICES.tablet} {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
  .react-datepicker {
    position: relative;
    border: 1px solid var(--dark-gray);
    border-color: ${({ isError }) => isError && 'var(--danger)'};
    width: 388px;
    font-size: 14px;
    padding: 65px 25px 15px;
    border-radius: 0;
    box-sizing: border-box;
    @media ${DEVICES.laptop} {
      width: 350px;
      padding: 32px 8px 5px;
    }
    @media ${DEVICES.mobileXL} {
      font-size: 12px;
      padding: 22px 8px 8px;
    }
    @media ${DEVICES.mobileXL} {
      width: 250px;
    }
    &__day-name,
    &__day,
    &__time-name {
      line-height: 1.75;
    }
    &-wrapper {
      display: block;
    }
    &__header {
      position: relative;
      padding-top: 8px;
      background: #fff;
      border-color: var(--black);
      &__dropdown {
        position: absolute;
        left: 0;
        top: -40px;
        z-index: 1;
        @media ${DEVICES.laptop} {
          top: -19px;
        }
        @media ${DEVICES.mobileL} {
          top: -15px;
          left: -12px;
        }
      }
    }
    &__current-month {
      display: none;
    }
    &__month-container {
      float: none;
      width: 100%;
    }
    &__month-year-read-view {
      &--down-arrow {
        float: right;
        margin-top: 7px;
        @supports (display: grid) {
          margin-right: 0;
        }
        margin-right: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: var(--soft-dark) transparent transparent transparent;
        transform: rotate(0deg);
        &:before {
          display: none;
        }
      }
    }
    &__triangle {
      @media ${DEVICES.tablet} {
        display: none;
      }
    }
    &__navigation {
      width: 12px;
      height: 12px;
      border: none;
      text-indent: -999em;
      top: 28px;
      @media ${DEVICES.laptop} {
        top: 19px;
      }
      @media ${DEVICES.mobileXL} {
        top: 5px;
      }
      &--previous {
        position: absolute;
        left: auto;
        right: 75px;
        background: url(${arrowLeft}) 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;

        @media ${DEVICES.mobileXL} {
          right: 40px;
        }
      }
      &--next {
        position: absolute;
        right: 40px;
        background: url(${arrowRight}) 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        @media ${DEVICES.mobileXL} {
          right: 20px;
        }
      }
      &-icon {
        &--previous,
        &--next {
          &::after,
          &::before {
            content: none;
          }
        }
      }
    }
    &__month-year {
      &-dropdown {
        top: 0;
        left: 0;
        width: 100%;
        font-size: 14px;
        height: 270px;
        overflow: auto;
        background-color: var(--white);
        border-radius: 0;
        border: 1px solid var(--active-calendar-border);
        @media ${DEVICES.mobileL} {
          font-size: 12px;
        }
        &-container {
          min-width: 120px;
          top: 20px;
        }
      }
      &-option {
        padding: 12px 16px;
        text-align: left;
        box-sizing: border-box;
        &:hover {
          background: var(--soft-grey);
        }
        &--selected {
          display: none;
        }
      }
    }
    &__month {
      @media ${DEVICES.mobileXL} {
        margin: 0.4rem 0 0;
      }
    }
    &__day-names,
    &__week {
      display: flex;
      justify-content: space-around;
    }
    &__day {
      font-size: 14px;
      width: 31px;
      height: 29px;
      text-align: center;
      padding-top: 1px;
      @media ${DEVICES.laptop} {
        font-size: 12px;
        width: 25px;
        height: 24px;
      }

      @media ${DEVICES.mobileXL} {
        height: 14px;
        width: 14px;
        line-height: 1;
        font-size: 11px;
      }
      &:hover {
        border-radius: 0;
      }
      &--selected {
        border-radius: 0;
        background-color: var(--danger);
      }
      &--keyboard-selected {
        color: var(--black);
        background: none;
      }
      &--outside-month {
        color: var(--black);
        opacity: 0.7;
      }
    }
    &__day-name {
      font-weight: bold;
      @media ${DEVICES.mobileXL} {
        line-height: 1;
        font-size: 11px;
      }
    }
    &__input-container {
      position: relative;
      input {
        font-family: inherit;
        width: 100%;
        height: 38px;
        font-size: 16px;
        border: 1px solid #e0e0e0;
        border: ${({ isError }) => isError && '1px solid var(--danger)'};
        ${(props) => props.disabled && `background: var(--lite-grey)`};
        padding: 0 25px 0 11px;
        box-sizing: border-box;
        background-image: url(${Calendar});
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: right 10px center;
      }
    }
    &__close-icon {
      &:before,
      &:after {
        position: absolute;
        top: 9px;
        right: 35px;
        content: ' ';
        height: 15px;
        width: 2px;
        background-color: var(--soft-dark);
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        padding: 0;
        border-radius: 0;
        transform: rotate(-45deg);
      }
    }
  }
`;

export const Message = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  user-select: none;
`;

export const Error = styled(Message)`
  color: var(--danger);
`;

const columnsStyles = {
  three_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;
      flex: 2;
      padding-bottom: 20px;

      ${CalendarLabel} {
        flex: 1;
      }

      ${ReactPickerWrapper} {
        flex: 1;
      }
    }
  `,
  two_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${CalendarLabel} {
        min-width: 400px;
      }

      ${ReactPickerWrapper} {
        width: 100%;
        max-width: 1200px;
      }
    }
  `,
  youth_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${CalendarLabel} {
        min-width: 400px;
      }

      ${ReactPickerWrapper} {
        width: 100%;
        max-width: 1200px;
      }
      ${Message} {
        left: 400px;
        max-width: 1200px;
      }
    }
  `,
  one_column_with_submission: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${CalendarLabel} {
        width: 100%;
        min-width: 200px;
        max-width: 50%;
        display: inline-block;
        word-wrap: break-word;
      }

      ${ReactPickerWrapper} {
        padding-left: 60px;
        width: 100%;
        max-width: 1200px;
      }
    }
  `,
  submission_column: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${CalendarLabel} {
        text-wrap: wrap;
        max-width: 130px;
        min-width: 130px;
      }

      ${ReactPickerWrapper} {
        width: 100%;
        max-width: 320px;
        min-width: 150px;
        padding-left: 10px;
      }
    }
  `,
  '': ``,
};

export const InputWrap = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  position: relative;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '45%')};
  padding: 0px;
  margin: ${({ isFullWidth }) => (isFullWidth ? '0 10px 0 0' : '0 10px')};
  @media ${DEVICES.tablet} {
    margin: ${({ isFullWidth }) => (isFullWidth ? '0 10px 0 0' : '10px')};
  }
  margin-left: ${({ isMargin }) => isMargin && '0'};

  ${({ pageColumns }) => pageColumns && columnsStyles[pageColumns]}
`;
