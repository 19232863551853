import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { FILE_DOWNLOAD_ICON } from 'assets/icons';
import PropTypes from 'prop-types';
import { Modal, Img } from 'ui-kit';
import * as Styled from './styles';

function DetailsNotificationModal({ isOpen, onClose, notification }) {
  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  const filePath = notification?.attributes?.file;

  return (
    <Modal open={isOpen} onClose={onClose} autoHeight>
      <Styled.ModalWrapper>
        <Styled.ModalTitle>{notification?.attributes?.title}</Styled.ModalTitle>
        <h4>Description</h4>
        <Styled.ModalDescription>{ReactHtmlParser(notification?.attributes?.description)}</Styled.ModalDescription>
        {filePath && (
          <Styled.Attachments>
            <h4>Attachments :</h4>
            <Styled.ImageWrapper>
              <Img
                tabIndex={0}
                id={1}
                size={[80, 60]}
                onClick={() => handleClick(filePath)}
                src={FILE_DOWNLOAD_ICON}
                aria-label="download file"
                alt="download file"
              />
              <Styled.FileTitle>{filePath && filePath.split('/').pop()}</Styled.FileTitle>
            </Styled.ImageWrapper>
          </Styled.Attachments>
        )}
      </Styled.ModalWrapper>
    </Modal>
  );
}

DetailsNotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  notification: PropTypes.object,
};

DetailsNotificationModal.defaultProps = {
  notification: {},
};

export default DetailsNotificationModal;
