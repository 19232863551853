import moment from 'moment';
import React, { useCallback, useState, useEffect, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { PageContainer } from 'modules';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Section, Loader, RadioButtonGroup, Picker, Input, BaseSelect, TextArea, NoData } from 'ui-kit';
import BackPageButton from 'modules/BackPageButton';
import { BUTTON_TYPES, INPUT_TYPE, MESSAGES, URLS } from '_constants';
import { VisitEntity, AuthEntity } from '_entities';
import { notification } from 'utils/services';
import {
  parseViolationStatus,
  getViolationData as getViolationDataUtil,
  getDataFromInclude,
  pickViolationInfoChecbox,
  formatTimeToUtc,
} from 'utils/custom';
import * as Styled from 'modules/VisitView/styles';
import { isEmpty } from 'lodash';
import { SURVEY_QUESTION_TYPE } from '../VisitView/constants';
import { VIOLATION_STATUS_RADIO_VALUE, FORM_ACTION_TYPES, formInitialState } from './constants';
import { formReducer, init, getRadioValue } from './utils';
import * as ViolationStyled from './styles';

const { getViolationInfo, getVisitInfo, updateViolation } = VisitEntity.actions;
const { getVisitData, getViolationData } = VisitEntity.selectors;
const { getTimeZone } = AuthEntity.selectors;

const getOtherFieldsValues = ({ otherFields, visitAttributes, visitIncluded }) => {
  if (isEmpty(otherFields)) return '';

  return otherFields.reduce((acc, field, index, array) => {
    const newField = visitAttributes?.[field] || visitAttributes?.metadata?.[field] || '';

    const choicesMap = getViolationDataUtil(visitIncluded, field);
    if (Array.isArray(newField) && !isEmpty(newField)) {
      const formattedValues = newField.map((value) => choicesMap[value]).join(', ');
      acc += ` ${formattedValues}`;
    } else {
      acc += ` ${choicesMap?.[newField] || newField}`;
    }

    acc += index !== array.length - 1 && newField !== '' ? ',' : '';
    return acc;
  }, '; ');
};

function ViolationEdit({ submitUrl }) {
  const violation = useSelector(getViolationData);
  const visit = useSelector(getVisitData);
  const timeZone = useSelector(getTimeZone);
  const [state, formDispatch] = useReducer(formReducer, formInitialState, init);
  const [penaltyCaptions, setPenaltyCaptions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const formStateData = state.data;
  const visitData = visit?.data;
  const visitAttributes = visit.data?.attributes;
  const visitTypeText = visitAttributes?.visit_type_text;

  const visitIncluded = visit.included;

  const { violation_action_field: violationActionField = '', violation_type_field: violationTypeField = '' } =
    getDataFromInclude(visitIncluded || [], 'Form')?.attributes || {};

  const [mainViolationTypeField, ...otherViolationFields] = violationTypeField
    .replace(/ /g, '')
    .split(',')
    .filter((el) => el !== '');

  const [mainViolationActionField, ...otherActionsFields] = violationActionField
    .replace(/ /g, '')
    .split(',')
    .filter((el) => el !== '');

  const getPenaltyCaptions = (violationData) => {
    const penalty = violationData.data.attributes.penalty_captions;
    const penaltyList = Object.keys(penalty).reduce((acc, item) => {
      const newPenalty = { option: [] };
      Object.keys(penalty[item]).reduce((innerAcc, innerItem) => {
        const { caption, type } = penalty[item][innerItem];
        // eslint-disable-next-line prefer-const
        let [penaltyTitle, title] = caption.split(': ');
        if (type === 'date') title += ' (mm/dd/yyyy)';
        newPenalty.title = penaltyTitle;
        innerAcc = {
          ...penalty[item][innerItem],
          name: innerItem,
          inputOptions: [],
          title,
        };
        newPenalty.option.push(innerAcc);
        return innerAcc;
      }, {});
      acc[item] = newPenalty;
      return acc;
    }, {});
    setPenaltyCaptions(penaltyList);
  };

  useEffect(() => {
    (async () => {
      try {
        await Promise.allSettled([dispatch(getViolationInfo(id)), dispatch(getVisitInfo(id))]).then((res) => {
          const [resViolation] = res;
          getPenaltyCaptions(resViolation.value);
          return formDispatch({
            type: FORM_ACTION_TYPES.SET_INITIAL_DATA,
            payload: parseViolationStatus(resViolation.value),
          });
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dispatch, id]);

  const actionCheckbox = useMemo(() => {
    if (!visitAttributes) return [];
    const {
      action_checkbox_enforcement: actionCheckboxEnforcement,
      action_checkbox_education: actionCheckboxEducation,
    } = visitAttributes;
    return actionCheckboxEnforcement || actionCheckboxEducation || [];
  }, [visitAttributes]);

  const pickedActionCheckbox = pickViolationInfoChecbox(visitData, mainViolationActionField, actionCheckbox);
  const penaltyValuesOnlyNumbers = [pickedActionCheckbox]
    .flat()
    .filter((el) => typeof el === 'string' && !Number.isNaN(Number(el)));

  const handleChange = useCallback(({ target: { name, value } }) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
  }, []);

  const handlePickerChange = useCallback((date, name) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: date || '' });
  }, []);

  const handleSelectChange = useCallback((obj, name) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: obj });
  }, []);

  const getInputs = useCallback(
    (item) => {
      switch (item.type) {
        case SURVEY_QUESTION_TYPE.RADIOGROUP: {
          return (
            <RadioButtonGroup
              name={item.name}
              title={item.title}
              options={item.choices}
              direction="row"
              value={getRadioValue(formStateData[item.name])}
              onChange={handleChange}
            />
          );
        }
        case SURVEY_QUESTION_TYPE.DATE: {
          const date = formStateData[item.name] ? new Date(formStateData[item.name]) : '';
          return (
            <Picker
              label={item.title}
              showMonthYearDropdown
              idInput={item.name}
              onChange={(data) => handlePickerChange(data, item.name)}
              selected={date}
              inputFor={item.name}
              isFullWidth
              maxDate={new Date(moment().add(1, 'years'))}
              name={item.name}
            />
          );
        }
        case SURVEY_QUESTION_TYPE.DROPDOWN: {
          const { fine_pay_statuses: payOptions } = violation.data?.attributes;
          const finePayOptions = Object.keys(payOptions).map((x) => ({
            value: x,
            label: payOptions[x],
          }));
          return (
            <BaseSelect
              name={item.name}
              labelText={item.title}
              options={finePayOptions}
              value={formStateData[item.name]}
              onChange={(obj) => handleSelectChange(obj, item.name)}
              inputId={item.name}
              placeholder="Select an option"
            />
          );
        }
        case SURVEY_QUESTION_TYPE.TEXT: {
          return (
            <TextArea
              name={item.name}
              value={formStateData[item.name] || ''}
              type={INPUT_TYPE.TEXT}
              floatingLabel
              title={item.title}
              onChange={handleChange}
            />
          );
        }
        case SURVEY_QUESTION_TYPE.NUMBER: {
          return (
            <Input
              name={item.name}
              value={formStateData[item.name] || ''}
              type={INPUT_TYPE.TEXT}
              floatingLabel
              title={item.title}
              onChange={handleChange}
            />
          );
        }
        default:
          return null;
      }
    },
    [formStateData, handleChange, handleSelectChange, handlePickerChange, violation.data?.attributes],
  );

  const getViolation = useCallback(
    (field, isPenalty) => {
      const choicesMap = getViolationDataUtil(visitIncluded, field);

      if (!choicesMap || choicesMap?.length === 0) return '';

      const violationFormField = isPenalty
        ? pickedActionCheckbox
        : visitAttributes?.[field] || visitAttributes?.metadata?.[field] || [];

      const arrayViolationFormFiled = Array.isArray(violationFormField) ? violationFormField : [violationFormField];

      const otherViolationFieldsValues = getOtherFieldsValues({
        otherFields: otherViolationFields,
        visitAttributes,
        visitIncluded,
      });

      const otherPenaltiesFieldsValues = getOtherFieldsValues({
        otherFields: otherActionsFields,
        visitAttributes,
        visitIncluded,
      });

      const res = arrayViolationFormFiled?.reduce((acc, item, i) => {
        const isSaleToMinor =
          field === 'sale_to_minor' && item === true && visitTypeText === 'Enforcement' && !isPenalty;
        const choiceValue = isSaleToMinor ? 'Sale to a minor' : choicesMap[item];

        acc += `${i > 0 ? ', ' : ''}${choiceValue || item}`;
        return acc;
      }, '');

      return isPenalty ? res?.concat(otherPenaltiesFieldsValues) : res?.concat(otherViolationFieldsValues);
    },
    [visit, visitAttributes],
  );

  const violationType = useMemo(() => {
    if (!visitAttributes) return '';
    return getViolation(mainViolationTypeField || 'violation_checkbox');
  }, [visitAttributes, getViolation]);

  const violationPenalty = useMemo(() => {
    if (!visitAttributes) return '';

    if (submitUrl === URLS.enforcement)
      return getViolation(mainViolationActionField || 'action_checkbox_enforcement', true);

    if (submitUrl === URLS.education)
      return getViolation(mainViolationActionField || 'action_checkbox_education', true);
    return '';
  }, [visitAttributes, getViolation, violationActionField]);

  const handleSubmit = useCallback(() => {
    (async () => {
      const submissionInfo = {
        is_open_status: formStateData.is_open_status,
        violation_comment: formStateData.violation_comment,
      };

      const newData = penaltyValuesOnlyNumbers?.reduce(
        (acc, item) => {
          const formFields = penaltyCaptions[item].option;
          formFields.forEach(({ name, type }) => {
            const fieldValue = formStateData[name];
            switch (type) {
              case SURVEY_QUESTION_TYPE.DROPDOWN:
              case SURVEY_QUESTION_TYPE.CHECKBOX:
              case SURVEY_QUESTION_TYPE.RADIOGROUP: {
                acc[name] = fieldValue?.value || null;
                break;
              }
              case SURVEY_QUESTION_TYPE.DATE: {
                const date = fieldValue;
                if (date) {
                  acc[name] = formatTimeToUtc({ date, timeZone });
                } else {
                  acc[name] = null;
                }
                break;
              }
              default:
                acc[name] = fieldValue;
                break;
            }
            return acc;
          });
          return acc;
        },
        { ...submissionInfo },
      );
      const newViolationData = {
        ...violation.data.attributes,
        ...newData,
      };

      try {
        await dispatch(updateViolation(newViolationData, id));
        notification.success(MESSAGES.VISIT.VIOLATION.SUCCESS);
        history.push(`${submitUrl}/${id}`);
      } catch (err) {
        notification.error(err.response.data.errors[0].detail);
      }
    })();
  }, [actionCheckbox, dispatch, formStateData, history, id, penaltyCaptions, submitUrl]);

  if (isLoading) return <Loader />;

  return (
    <PageContainer>
      <Styled.Wrapper>
        <ViolationStyled.Header>
          <ViolationStyled.HeaderRow>
            <ViolationStyled.TitleCol>
              <BackPageButton />
              <ViolationStyled.Title>Violation status info: Edit page</ViolationStyled.Title>
            </ViolationStyled.TitleCol>
            <Button onClick={handleSubmit} text="Save changes" variant={BUTTON_TYPES.DANGER} />
          </ViolationStyled.HeaderRow>
          <ViolationStyled.HeaderRow>
            <ViolationStyled.Text>Violation type(s): {violationType || ' - '}</ViolationStyled.Text>
            <ViolationStyled.Text>
              Penalty:
              <ViolationStyled.TextValue>{violationPenalty || ' - '}</ViolationStyled.TextValue>
            </ViolationStyled.Text>
          </ViolationStyled.HeaderRow>
        </ViolationStyled.Header>
        <ViolationStyled.RowWrapperEdit>
          <Section title="Action(s) taken">
            {!penaltyValuesOnlyNumbers?.length && <NoData />}
            {penaltyValuesOnlyNumbers.map(
              (value) =>
                penaltyCaptions[value]?.title && (
                  <ViolationStyled.DL key={penaltyCaptions[value].title}>
                    <ViolationStyled.DT>{penaltyCaptions[value].title}</ViolationStyled.DT>
                    {penaltyCaptions[value].option.map((item) => {
                      return <ViolationStyled.DD key={item.name}>{getInputs(item)}</ViolationStyled.DD>;
                    })}
                  </ViolationStyled.DL>
                ),
            )}
          </Section>
          <Section title="Violation status">
            <Styled.Dl>
              <Styled.InputWrapper>
                <RadioButtonGroup
                  name="is_open_status"
                  title="Status"
                  options={VIOLATION_STATUS_RADIO_VALUE}
                  direction="row"
                  value={formStateData.is_open_status}
                  onChange={handleChange}
                />
                <TextArea
                  name="violation_comment"
                  value={formStateData.violation_comment || ''}
                  type={INPUT_TYPE.TEXT}
                  floatingLabel
                  title="Notes"
                  onChange={handleChange}
                />
              </Styled.InputWrapper>
            </Styled.Dl>
          </Section>
        </ViolationStyled.RowWrapperEdit>
      </Styled.Wrapper>
    </PageContainer>
  );
}

ViolationEdit.propTypes = {
  submitUrl: PropTypes.string.isRequired,
};

export default ViolationEdit;
