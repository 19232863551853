import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

function ListItem({ title, description, isValid }) {
  return (
    <Styled.ListItem isValid={isValid}>
      <p>{title}</p>
      {description && (
        <Styled.InnerList>
          {description.map((innerTitle) => {
            return <Styled.InnerListItem key={innerTitle}>{innerTitle}</Styled.InnerListItem>;
          })}
        </Styled.InnerList>
      )}
    </Styled.ListItem>
  );
}

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  description: PropTypes.array,
};

ListItem.defaultProps = {
  description: null,
};

export default memo(ListItem);
