import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { deviceLess } from '_constants/DEVICES';
import { Section, Content } from 'ui-kit/Section/styles';

export const MainContainer = styled.div`
  padding: 30px 25px 40px;
  @media ${deviceLess.desktopHd} {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
  }
`;

export const TopLine = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleWrapper = styled.div`
  > a {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 22px;
`;

export const Info = styled.p`
  font-size: 14px;
  color: var(--dim-grey);
`;

export const ButtonWrapper = styled.div`
  > button {
    min-width: 135px;
  }
`;

export const SectionGroup = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`;

export const Inner = styled.div`
  display: flex;
  flex: 3;
  flex-flow: row nowrap;
  border: 1px solid var(--grey);
  font-weight: normal;

  > section {
    ${Content} {
      padding: 0;
    }
  }

  @media ${DEVICES.tablet} {
    display: block;
    border: none;
  }

  ${Section} {
    border: none;
    flex: 1 0;

    :first-of-type {
      @media ${DEVICES.tablet} {
        margin-bottom: 25px;
        border: 1px solid var(--grey);
      }
    }

    :nth-child(2) {
      border-left: 1px solid var(--grey);
      border-right: 1px solid var(--grey);

      @media ${DEVICES.tablet} {
        margin-bottom: 25px;
        border: 1px solid var(--grey);
      }
    }

      @media ${DEVICES.tablet} {
        border: 1px solid var(--grey);
      }
    }
  }
`;

export const WrapperSelect = styled.div`
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  position: relative;

  @media ${deviceLess.laptop} {
    height: 50px;
  }
  margin-bottom: 25px;
  margin-left: ${({ isSubcategory }) => (isSubcategory ? '19px' : '0')};
`;

export const Location = styled.div`
  ${({ isHorizontalLayout }) =>
    isHorizontalLayout &&
    css`
      @media ${deviceLess.laptop} {
        position: relative;
        display: flex;
        flex: 2;
        height: 52px;

        ${LocationLabel} {
          flex: 1;
        }

        ${LocationText} {
          flex: 1;
          margin-left: 10px;
        }
      }
    `}
`;

export const LocationLabel = styled.span`
  display: block;
  font-size: 16px;
  color: var(--dim-grey);
  margin-bottom: 8px;
`;

export const LocationText = styled.p`
  font-size: 18px;
  color: var(--dark);
  min-height: 18px;
`;

export const MapWrapper = styled.div`
  div.singleRetailerMapMainMap,
  div.singleRetailerMapStreetView {
    width: 100%;
    height: 260px;
    border: 1px solid #cccccc;
  }
  div.singleRetailerMapStreetView.hidden {
    display: none !important;
  }

  div.singleRetailerMap > div.leaflet-container {
    cursor: crosshair !important;
  }

  /* override Leaflet 1.x suppressing the ARIA outline (Leaflet 1.7 and earlier) */
  div.singleRetailerMap :focus,
  div.singleRetailerMap {
    outline: -webkit-focus-ring-color auto thin !important;
    outline: revert !important;
  }

  /* retailer marker DivIcon */
  div.singleRetailerMap > div.leaflet-container div.leaflet-marker-icon.retailer-point-icon {
    border-radius: 50%;
    background-color: #ff8080;
    border-color: #ff0000;
  }

  /* EasyButton buttons */
  div.singleRetailerMap div.leaflet-control-container button.easy-button-button {
    background-color: white;
    border: 0 none transparent;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 4px;

    font-size: 14px;
  }

  /* override some global styles that break the map */
  img {
    display: inline;
  }

  /* PRINT MEDIA to hide some map controls (not all, scale still makes sense in a static printout) when the page is printed */
  @media print {
    div.mapView div.mapViewMainMap .leaflet-biglayerpanel-control,
    div.mapView div.mapViewMainMap .leaflet-control-basemapbar,
    div.mapView div.mapViewMainMap .leaflet-control-zoom,
    div.mapView div.mapViewMainMap .leaflet-credits-control,
    div.mapView div.mapViewMainMap .leaflet-bar.leaflet-control,
    div.mapView div.mapViewMainMap .leaflet-control-attribution {
      display: none !important;
    }
  }
`;

export const GisInfoWrapper = styled.div`
  margin-top: 20px;
  padding: 0 15px;
`;

export const GisInfo = styled.p`
  padding: 0;
  margin: 0 0 10px;
  color: var(--dim-grey);
`;

export const EditWrapper = styled.div`
  padding: 15px;

  > button {
    min-width: 135px;
  }
`;

export const EditDescription = styled.p`
  margin: 0 0 20px;
  color: var(--dark);
`;

export const UpdateLocationButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;

  > button {
    min-width: 135px;

    :not(:last-of-type) {
      margin-right: 15px;
    }
  }
`;

export const HorizontalBorder = styled.div`
  border-bottom: 1px solid #e0e0e0;
  position: absolute;
  top: calc(120%);
  left: -20px;
  width: calc(100% + 40px);
  filter: opacity(0.4);

  @media ${deviceLess.laptop} {
    border-bottom: 1px solid #e0e0e0;
    position: absolute;
    left: -20px;
    width: calc(100% + 40px);
    filter: opacity(0.4);
  }
`;
