/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-day-picker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FILTER_OPTIONS, FILTER_FIELDS } from '_constants/old/filters';
import { OldFilterEntity, AuthEntity } from '_entities';
import { FormGroupWrapper } from 'modules/Modals/old/FilterModal/components/FormGroupWrapper';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import { BaseSelect } from 'ui-kit';
import * as Styled from './styles';
import {  NewFiltersEntity } from '_entities';
import { Checkbox } from 'ui-kit';

const { getUserId } = AuthEntity.selectors;
const { setShadowFilter } = OldFilterEntity.actions;
const { getAgencyOptions } = NewFiltersEntity.selectors;



export const VisitsQuickQuery = () => {
  const {
    filter: { shadowFilters },
  } = useSelector((state) => state);
  const [selectValue, setSelectValue] = useState({});
  const dispatch = useDispatch();
  const agencyOptions = useSelector(getAgencyOptions);
  const userId = useSelector(getUserId);

  const ENFORCEMENT_QUICK_QUERY = [
    {
      id: 1,
      field: FILTER_FIELDS.VISITS_SUBMITTED_BY_ME,
      title: 'All visits submitted by me',
      options: FILTER_OPTIONS.SUBMITTED_BY_ME_OPTIONS,
      type: 'checkbox'
    },
    {
      id: 2,
      field: FILTER_FIELDS.AGENCY_ID,
      title: 'Visits by Agency',
      options: agencyOptions,
      type: 'multiselect'
    },
  ];

  const handleChangeSelect = (value, field) => {
    setSelectValue({ ...selectValue, [field]: value });
    dispatch(setShadowFilter({ ...shadowFilters, [field]: value.map((elem) => elem.value) }));
  };

  const handleChangeCheckbox = (itemValue) => {
    const {target: {value}} = itemValue;
    dispatch(setShadowFilter({ ...shadowFilters, user_id: value ? userId : value }));
  }

  const getDropDefaultSelectedValues = (array) => {
    if (!agencyOptions) return null;
    return agencyOptions.filter((item) => array?.includes(item.value));
  };

  useEffect(() => {
    const agencyId = getDropDefaultSelectedValues(shadowFilters?.agency_id);
    setSelectValue({ agency_id: agencyId });
  }, [shadowFilters]);

  const checkBoxValue = useMemo(() => {
    if(Array.isArray(shadowFilters.user_id)) return shadowFilters.user_id.includes(userId);
    return shadowFilters.user_id === userId
  },[shadowFilters.user_id])

    const getElement = (title, field, options, type) => {
      switch (type) {
        case 'checkbox':
          return <Checkbox label={title} name={field} onChange={handleChangeCheckbox} value={checkBoxValue} />;
        case 'multiselect':
          return (
            <Styled.SelectWrapper>
              <BaseSelect
                onChange={(value) => {
                  handleChangeSelect(value, field);
                }}
                isMulti
                labelText={title}
                name={field}
                options={options}
                // eslint-disable-next-line no-prototype-builtins
                value={selectValue.hasOwnProperty(field) && selectValue[field]}
              />
            </Styled.SelectWrapper>
          );
        default:
          return null;
      }
    };

  return (
    <Styled.Wrapper>
      <FormGroupWrapper>
      <FormGroup title="Show">
      {ENFORCEMENT_QUICK_QUERY.map((item) => getElement(item.title, item.field, item.options, item.type))}
      </FormGroup>
      </FormGroupWrapper>
    </Styled.Wrapper>
  );
};
