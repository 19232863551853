import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeElement = styled.time`
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: normal;

  color: #0d0d0d;
`;
