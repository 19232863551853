/* eslint-disable no-fallthrough */
import { isEmpty } from 'lodash';
import * as TYPES from './Notification.types';

const initialState = {
  notificationList: [],
  unreadNotifications: 0,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_NOTIFICATION_LIST_SUCCESS: {
      const unreadNotifications = action?.result?.data?.filter((el) => isEmpty(el?.attributes?.read_date));

      return {
        ...state,
        notificationList: action?.result?.data,
        unreadNotifications: unreadNotifications?.length,
      };
    }

    default:
      return state;
  }
};

export default reducer;
