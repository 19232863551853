import moment from 'moment';

export const parseYouthTableData = (data) => {
  const getYouthData = (youthData) => youthData.map((item) => item.id);

  return data.map((youth) => {
    return {
      ...youth.attributes,
      birthdate: moment(youth.attributes.birthdate).format('L'),
      agency: getYouthData(youth.relationships.agencies.data),
      sec_geogs: youth.relationships.geographies,
      id: youth.id,
    };
  });
};
