/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */

/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import MenuItems from 'modules/Header/components/MenuItems';
import { AdaText } from 'assets/styles/global';
import * as Styled from './styles';

const handleLogoutAction = (logout, logoutAction, setOpen) => {
  logout(logoutAction);
  setOpen(false);
};

const handleProfileAction = (setOpen, history) => {
  setOpen(false);
  history.push('profile');
};

export const BurgerMenuItems = ({ open, email, logout, logoutAction, setOpen, history }) => (
  <Styled.StyledMenu open={open}>
    <MenuItems history={history} />
    <Styled.EmailMenu
      tabIndex={0}
      onClick={() => handleProfileAction(setOpen, history)}
      onKeyDown={(e) => e.keyCode === 13 && handleProfileAction(setOpen, history)}
    >
      {email}
    </Styled.EmailMenu>
    <Styled.Logout
      tabIndex={0}
      onClick={() => handleLogoutAction(logout, logoutAction, setOpen)}
      onKeyDown={(e) => e.keyCode === 13 && handleLogoutAction(logout, logoutAction, setOpen)}
    >
      LOG OUT
    </Styled.Logout>
  </Styled.StyledMenu>
);

const toggleHeaderMenu = (e, setOpen) => {
  setOpen((prev) => !prev);
};

const BurgerMenu = ({ open, setOpen, innerRef }) => (
  <Styled.BurgerContainer data-html2canvas-ignore open={open}>
    <Burger innerRef={innerRef} open={open} setOpen={setOpen} />
  </Styled.BurgerContainer>
);

export const Burger = ({ open, setOpen, innerRef }) => (
  <Styled.StyledBurger
    open={open}
    onClick={(e) => toggleHeaderMenu(e, setOpen)}
    aria-label="menu"
    ref={innerRef}
    id="burgerMenuMobileId"
  >
    <AdaText>burger menu</AdaText>
    <Styled.Block hidden>close</Styled.Block>
    <Styled.BurgerStick open={open} />
  </Styled.StyledBurger>
);

export default BurgerMenu;
