import * as TYPES from './Filters.types';

const initialState = {
  userObj: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_LOCATION_SUCCESS: {
      const LocationObj = action.result.map((elem) => {
        return {
          title: elem.singular,
          options: elem.polygons.map((item) => {
            return { label: item.name, value: item.id.toString() };
          }),
        };
      });
      return {
        ...state,
        LocationObj,
      };
    }
    case TYPES.GET_ENFORCEMENT_AGENCY_SUCCESS: {
      const agencyObj = action.result.data.map((elem) => {
        return {
          value: elem.id,
          label: elem.attributes.name,
        };
      });
      return {
        ...state,
        agencyObj,
      };
    }
    case TYPES.GET_ENFORCEMENT_USER_SUCCESS: {
      const userObj = action.result.data.map((elem) => {
        return {
          value: elem.id,
          label: elem.attributes.email,
        };
      });
      return {
        ...state,
        userObj,
      };
    }
    case TYPES.GET_RETAILER_CORPORATION_SUCCESS: {
      const corpObj = action.result.data.map((elem) => {
        return {
          value: elem.attributes.corporation,
          label: `Owning corporation: ${elem.attributes.corporation}`,
        };
      });
      return {
        ...state,
        corpObj,
      };
    }

    default:
      return state;
  }
};

export default reducer;
