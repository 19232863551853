const initUser = {
  status: 'loading',
  isLoad: false,
  isClickEditPen: false,
  suspensionChanges: false,
  showMessageAllSaved: false,
  isEditForm: false,
  retailerIsCreated: false,
  requiredField: [],
  isData: false,
  retailerLicenses: {
    data: [
      {
        type: 'Retailer',
        id: 1,
        attributes: {
          agency: {
            name: '',
            contact_info: '',
          },
          name: '',
          address: '',
          address2: '',
          license_id: 1,
          agency_name: '',
          city: '',
          has_assignments: false,
          inspection_date: '',
          disposition: '',
          state: '',
          zipcode: '',
          latlong: {
            latitude: '',
            longitude: '-',
          },
          place_id: '',
          aka: [],
          is_current: true,
          is_historic: null,
          historic_last_merge_date: null,
          merge_date: null,
          closed_date: null,
          metadata: {
            county: '',
          },
          lic_num: '1',
          tobacco: false,
          alcohol: false,
          marijuana: false,
          guns: null,
          store_type: 2,
          chain: true,
          corporation: '',
          wic: true,
          snap: false,
          near_school: false,
          near_retailer: true,
          pharmacy: true,
          ss_precision: '',
          address_validated: true,
          g_precision: '',
          is_gps: true,
          loc_updated: null,
          addr_updated: null,
          q_pcthisp: 5,
          q_pctblack: 5,
          q_pctamerind: 5,
          q_pctashawpi: 3,
          q_pctwhite: 1,
          q_pctotherrace: 4,
          q_pctyouth: 1,
          q_mhhinc: 1,
          q_pcturban: 1,
          q_pcteducollegegrad: 1,
          q_pctedusomecollege: 5,
          q_pcteduhighschool: 3,
          q_pctedunohighschool: 5,
          created: '',
          modified: '',
          other1: 'test 1',
          other2: 'test 2',
        },
        relationships: {
          agency: {
            data: {
              type: 'Agency',
              id: '1',
            },
          },
          retailer: {
            data: {
              type: 'Retailer',
              id: '1',
            },
          },
          new_retailer: {
            data: null,
          },
          historic: {
            data: [],
          },
          similar_stores: {
            data: [],
            meta: {
              count: 0,
            },
          },
          non_duplicate_stores: {
            data: [],
            meta: {
              count: 0,
            },
          },
          visits: {
            data: [
              {
                type: 'Visit',
                id: '1',
              },
            ],
          },
          aggregationpolygon_set: {
            data: [
              {
                type: '',
                id: '1',
              },
            ],
            meta: {
              count: 1,
            },
          },
        },
        links: {
          self: '',
        },
      },
    ],
    included: [
      {
        attributes: { name: '' },
        id: '1',
        type: 'Agency',
      },
    ],
    meta: {
      pagination: { page: 1, pages: 1, count: 20 },
    },
  },
  enforcementForms: {},
  checkDuplicate: false,
  locationIsUpdated: false,
  locationIsError: false,
  link: '',
  selectedAgency: '',
  startDay: '',
  endDay: '',
  retailer: {
    data: {
      id: '1',
      attributes: {
        name: '',
        address: '',
        state: '',
        created: '',
        modified: '',
        is_current: true,
        closed_date: null,
        tobacco: true,
        pharmacy: true,
        agency: '',
        end_date: '',
        license_number: '',
        latlong: {
          latitude: 0,
          longitude: 0,
        },
        locked_at: null,
        start_date: '',
        cdph_business_type: '',
        age_restricted_flag: '',
        entity_type: '',
      },
      relationships: {
        modified_by_user: { data: '' },
        role: {
          data: {
            id: 6,
          },
        },
        modified_by_agency: {
          data: {
            type: 'Agency',
            id: '1',
            attributes: { name: '' },
          },
        },
        similar_stores: { meta: { count: '' } },
        non_duplicate_stores: { meta: { count: '' } },
      },
    },
    included: [
      {
        attributes: { name: '', abbreviation: '', state: '' },
        id: '1',
        type: 'Agency',
      },
    ],
  },
  cities: [],
  counties: [],
  supervisory: [],
  names: [],
  calcpoly: [],
};

const retailerData = (state, action) => ({
  ...state,
  retailer: Array.isArray(action.payload.data) ? state.retailer : action.payload,
  status: 'done',
  isLoad: true,
});

const retailerDataStartLoad = (state) => ({
  ...state,
  isLoad: false,
});
const setIsClickEditPen = (state, action) => ({
  ...state,
  isClickEditPen: action.payload,
});

const setLink = (state, action) => ({
  ...state,
  link: action.payload,
});

const setRequiredField = (state, action) => ({
  ...state,
  requiredField: action.payload,
});

const setIsEditForm = (state, action) => ({
  ...state,
  isEditForm: action.payload,
});

const setRetailerLicenses = (state, action) => ({
  ...state,
  retailerLicenses: action.payload,
});

const setShowMessageAllSaved = (state, action) => ({
  ...state,
  showMessageAllSaved: action.payload,
});
const setCities = (state, action) => ({
  ...state,
  cities: action.payload,
});
const setAllCities = (state, action) => ({
  ...state,
  cities: action.payload,
});
const setAllCounties = (state, action) => ({
  ...state,
  counties: action.payload,
});
const setAllSupervisory = (state, action) => ({
  ...state,
  supervisory: action.payload,
});
const setNames = (state, action) => ({
  ...state,
  names: action.payload,
});

const suspensionAgency = (state, action) => ({
  ...state,
  selectedAgency: action.payload,
});
const suspensionStart = (state, action) => ({
  ...state,
  startDay: action.payload.value,
});

const suspensionEnd = (state, action) => ({
  ...state,
  endDay: action.payload.value,
});

const setEnfrocementForms = (state, action) => ({
  ...state,
  enforcementForms: action.payload,
});

const setRetailerIsCreated = (state, action) => ({
  ...state,
  retailerIsCreated: action.payload,
});

const setLocationIsUpdated = (state, action) => ({
  ...state,
  locationIsUpdated: action.payload,
});

const setLocationIsError = (state, action) => ({
  ...state,
  locationIsError: action.payload,
});

const isDuplicate = (state, action) => ({
  ...state,
  checkDuplicate: action.payload,
});

const setSuspensionChanges = (state, action) => ({
  ...state,
  suspensionChanges: action.payload,
});

const setSuspensionDeleted = (state, action) => ({
  ...state,
  suspensionDeleted: action.payload,
});

const setSuspensionAdded = (state, action) => ({
  ...state,
  suspensionAdded: action.payload,
});

const setRetailerLocked = (state, action) => ({
  ...state,
  retailerLocked: action.payload,
});

const setIsData = (state, action) => ({
  ...state,
  isData: action.payload,
});

const setPreviousObj = (state, action) => ({
  ...state,
  prevObj: action.payload,
});

const enforcementData = (state, action) => ({
  ...state,
  retailerEnforcement: action.payload,
});

const reducer = (state = initUser, action) => {
  switch (action.type) {
    case 'GET_RETAILER_DATA':
      return retailerData(state, action);
    case 'START_LOAD_RETAILER_DATA':
      return retailerDataStartLoad(state, action);
    case 'SET_CITIES':
      return setCities(state, action);
    case 'SET_ALL_CITIES':
      return setAllCities(state, action);
    case 'SET_ALL_COUNTIES':
      return setAllCounties(state, action);
    case 'SET_ALL_SUPERVISORY':
      return setAllSupervisory(state, action);
    case 'SET_NAMES':
      return setNames(state, action);
    case 'SET_SUSPENSION_START_DATE':
      return suspensionStart(state, action);
    case 'SET_SUSPENSION_END_DATE':
      return suspensionEnd(state, action);
    case 'SET_SUSPENSION_AGENCY':
      return suspensionAgency(state, action);
    case 'SET_STATUS_MESSAGE':
      return setShowMessageAllSaved(state, action);
    case 'IS_EDIT_FORM':
      return setIsEditForm(state, action);
    case 'SET_LINK':
      return setLink(state, action);
    case 'GET_ENFORCEMENT_FORMS':
      return setEnfrocementForms(state, action);
    case 'SET_MESSAGE_RETAILER':
      return setRetailerIsCreated(state, action);
    case 'SET_MESSAGE_UPDATE_LOCATION':
      return setLocationIsUpdated(state, action);
    case 'SET_MESSAGE_ERROR_LOCATION':
      return setLocationIsError(state, action);
    case 'CHECK_DUPLICATE':
      return isDuplicate(state, action);
    case 'SET_REQUIRED_FIELD':
      return setRequiredField(state, action);
    case 'GET_RETAILER_LICENSES':
      return setRetailerLicenses(state, action);
    case 'SET_SUSPENSION_CHANGES':
      return setSuspensionChanges(state, action);
    case 'SET_SUSPENSION_DELETED':
      return setSuspensionDeleted(state, action);
    case 'SET_SUSPENSION_ADDED':
      return setSuspensionAdded(state, action);
    case 'SET_RETAILER_LOCKED':
      return setRetailerLocked(state, action);
    case 'IS_DATA':
      return setIsData(state, action);
    case 'IS_EDIT_PEN':
      return setIsClickEditPen(state, action);
    case 'SAVE_PREVIOUS_RETAILER_REQUEST_OBJ':
      return setPreviousObj(state, action);
    case 'GET_ENFORCEMENT':
      return enforcementData(state, action);
    default:
      return state;
  }
};

export default reducer;
