import React, { useEffect, useState } from 'react';
import { Img } from 'ui-kit';
import { CT_LOGO, NOTIFICATION_BELL_ICON } from 'assets/icons';
import { AuthEntity, CollectEntity, NotificationEntity } from '_entities';
import { IsIncludes } from 'modules';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationModal } from 'modules/Modals';
import { useWindowDimensions, useIsReadyAndOffline } from 'hooks';
import { idbService, notification } from 'utils/services';
import { URLS } from '_constants';
import { useLocation } from 'react-router-dom';
import { getMenuRoutes } from './_utils';
import * as Styled from './styles';
import MenuDesktop from './components/DesktopMenu';
import HeaderMobile from './components/MobileMenu';

const { getAuthenticatedUser, getUserEmail, getProfilePermissions, getModularityPermissions, getDashboardLinks } =
  AuthEntity.selectors;
const { setCollectCount } = CollectEntity.actions;
const { getNotificationList } = NotificationEntity.actions;
const { getNotificationMessages, getUnreadMessages } = NotificationEntity.selectors;

const Header = () => {
  const dispatch = useDispatch();
  const { width: viewportWidth } = useWindowDimensions();
  const isReadyAndOffline = useIsReadyAndOffline();
  const isAuthenticated = useSelector(getAuthenticatedUser);
  const userEmail = useSelector(getUserEmail);
  const userPermissions = useSelector(getProfilePermissions);
  const modularityPermissions = useSelector(getModularityPermissions);
  const dashboardInfoSections = useSelector(getDashboardLinks);
  const notifications = useSelector(getNotificationMessages);
  const unreadMessages = useSelector(getUnreadMessages);
  const [isNotificationsModalOpened, setIsNotificationsModalOpened] = useState(false);
  const ROUTES = getMenuRoutes(isAuthenticated, userEmail, isReadyAndOffline, dashboardInfoSections);
  const { pathname } = useLocation();

  const menuItemHasAccess = (menuItem) => {
    const selectedPermission = menuItem.modularity ? modularityPermissions : userPermissions;
    return menuItem.permission
      ? IsIncludes(selectedPermission, menuItem.permission, menuItem?.isVisit, menuItem?.isDevider)
      : true;
  };

  const ROUTES_WITH_PERMISSIONS = ROUTES.map((menuItem) => {
    let newSubroutes = null;
    if (menuItem.subRoutes) {
      newSubroutes = menuItem.subRoutes.filter((subItem) => {
        return menuItemHasAccess(subItem);
      });
    }
    return menuItemHasAccess(menuItem) ? { ...menuItem, subRoutes: newSubroutes ? [...newSubroutes] : null } : null;
  }).filter((item) => item !== null);

  // this function will be moved to utils function on next release, to do
  const updateOfflineSurveyCount = async () => {
    const db = await idbService.initiateIDB();
    const count = await db.transaction('surveys').objectStore('surveys').count();
    await dispatch(setCollectCount(count));
  };

  const loadNotifications = async () => {
    try {
      await dispatch(getNotificationList());
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleNotificationClick = () => {
    setIsNotificationsModalOpened(() => true);
  };

  const closeNotificationModal = () => {
    setIsNotificationsModalOpened(() => false);
  };

  useEffect(() => {
    if (unreadMessages && !isNotificationsModalOpened && isAuthenticated && pathname === URLS.dashboard) {
      const messages = unreadMessages > 1 ? 'messages' : 'message';
      notification.info({
        notification: `You have ${unreadMessages} unread ${messages}!`,
        onClick: handleNotificationClick,
      });
    }
  }, [unreadMessages, isNotificationsModalOpened, isAuthenticated]);

  useEffect(() => {
    updateOfflineSurveyCount();
    if (isAuthenticated) {
      loadNotifications();
    }
  }, []);

  return (
    <Styled.HeaderContainer>
      <Img alt="ct logo" src={CT_LOGO} size={[69, 28]} />
      {viewportWidth <= 950 ? (
        <HeaderMobile routes={ROUTES_WITH_PERMISSIONS} />
      ) : (
        <MenuDesktop routes={ROUTES_WITH_PERMISSIONS} />
      )}
      {isAuthenticated && (
        <Styled.ImageWrapper>
          <Img
            tabIndex={0}
            id={1}
            size={[20, 20]}
            onClick={handleNotificationClick}
            src={NOTIFICATION_BELL_ICON}
            aria-label="notification"
            alt="notification"
          />
          {Boolean(unreadMessages) && <Styled.Counter>{unreadMessages <= 9 ? unreadMessages : '9+'}</Styled.Counter>}
        </Styled.ImageWrapper>
      )}
      {isNotificationsModalOpened && (
        <NotificationModal
          onClose={closeNotificationModal}
          notificationList={notifications}
          loadNotifications={loadNotifications}
        />
      )}
    </Styled.HeaderContainer>
  );
};

export default Header;
