export const parseCalcpolyData = (data) => {
  if (!Array.isArray(data)) return [];

  return data.reduce((acc, item) => {
    item.polygons.forEach(({ id, name }) => {
      const newItem = {
        label: name,
        value: id,
      };
      if (item.slug === 'county') {
        acc.push(newItem);
      }
      if (item.slug === 'entirestate') {
        acc.unshift(newItem);
      }
    });
    return acc;
  }, []);
};
