import React from 'react';
import { FILTER_TYPES } from '_constants/old/filters';
import { RetailersFilterByAddressName } from 'pages/old/RetailerList/filters/RetailersFilterByAddressName';
// import { EnforcementFilterByAddressName } from 'pages/Enforcement/filters/EnforcementFilterByAddressName';
// import { LicensesFilterByAddressName } from 'pages/Licenses/filters/LicensesFilterByAddressName';

export const FilterNameAddress = ({ filterType }) => {
  const displayContent = () => {
    switch (filterType) {
      case FILTER_TYPES.RETAILERS:
        return <RetailersFilterByAddressName />;
      // case FILTER_TYPES.ENFORCEMENT:
      //   return <EnforcementFilterByAddressName />;
      // case FILTER_TYPES.LICENSES:
      //   return <LicensesFilterByAddressName />;
      default:
        return null;
    }
  };

  return displayContent();
};
