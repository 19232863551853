/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState } from 'react';
import { compact, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { ActionCell, TableWrapper } from 'ui-kit';
import useSortTable from 'utils/old/useSortTable';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { URLS } from '_constants';
import { COLUMNS, INITIAL_COLUMNS } from './constants';
import TimeCell from '../old/cells/TimeCell';

const getInitialColumns = ({ handleSort, sortOption, dumpedActiveColumns, editCellParams, getToast }) =>
  compact([
    dumpedActiveColumns.youth_id && {
      Header: () => (
        <HeaderCell col="Youth ID" sortBy="youth_id" sortOption={sortOption} handleSort={handleSort('youth_id')} />
      ),
      minWidth: 300,
      accessor: 'youth_id',
      Cell: ({ original }) => <LinkCell id={uniqueId()} text={original.youth_id} to={`${URLS.youth}/${original.id}`} />,
    },
    dumpedActiveColumns.gender && {
      Header: () => (
        <HeaderCell col="Gender" sortBy="gender" sortOption={sortOption} handleSort={handleSort('gender')} />
      ),
      minWidth: 260,
      accessor: 'gender',
      Cell: ({ value }) => {
        const genderMapper = {
          1: 'Female',
          2: 'Male',
          3: 'Other',
        };
        return <TextCell text={genderMapper[value]} />;
      },
    },
    dumpedActiveColumns.birthdate && {
      Header: () => (
        <HeaderCell col="Birthdate" sortBy="birthdate" sortOption={sortOption} handleSort={handleSort('birthdate')} />
      ),
      minWidth: 260,
      accessor: 'birthdate',
      Cell: ({ value }) => <TimeCell data={value} />,
    },
    dumpedActiveColumns.is_active && {
      Header: () => (
        <HeaderCell col="Status" sortBy="is_active" sortOption={sortOption} handleSort={handleSort('is_active')} />
      ),
      minWidth: 150,
      accessor: 'is_active',
      Cell: ({ value }) => {
        const statusLabelMapper = {
          true: 'Active',
          false: 'Inactive',
        };
        return <TextCell text={statusLabelMapper[value]} />;
      },
    },
    {
      Header: () => {},
      width: 112,
      accessor: 'editPen',
      Cell: ({ original }) => <ActionCell onlyEdit original={original} getToast={getToast} {...editCellParams} />,
    },
  ]);

const YouthTable = ({ data, sortHandler, sortOption }) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [activeColumns, setActiveColumns] = useState(INITIAL_COLUMNS);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();

  const getToast = () => getToastLock('Youth', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );

  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns: COLUMNS,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: 'youth',
      route: URLS.youthPages.edit,
      getToast,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitialColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        editCellParams,
        getToast, // eslint-disable-next-line
      }),
    [dumpedActiveColumns, sortOption, tableControlParams, handleSort],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data || []}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
    />
  );
};

export default memo(YouthTable);
