import React, { memo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import * as Styled from './styles';

ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * @example
 * data={[
 *   { value: value1, color: '#737373' },
 *   { value: value2: '#e0e0e0' },
 * ]}
 */

const DoughnutChart = (props) => {
  const { title, visits, data } = props;

  const getValueList = (name) => data?.map((item) => item[name]);

  const dataValues = getValueList('value');
  const backgroundColor = getValueList('color');

  const dataBase = {
    datasets: [
      {
        data: dataValues,
        backgroundColor,
        borderWidth: 0,
        cutout: '70%',
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
        callbacks: {
          label: ({ yLabel }) => `${yLabel} %`,
        },
      },
      hover: {
        animationDuration: 0,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <Styled.Wrapper>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Chart>
        <Doughnut data={dataBase} options={options} />
        {dataValues && <Styled.Value>{`${dataValues[0]}%`}</Styled.Value>}
      </Styled.Chart>
      {(visits || visits === 0) && <Styled.Visits>{visits} visit(s)</Styled.Visits>}
    </Styled.Wrapper>
  );
};

DoughnutChart.propTypes = {
  title: PropTypes.string.isRequired,
  visits: PropTypes.bool,
  data: PropTypes.array.isRequired,
};

DoughnutChart.defaultProps = {
  visits: false,
};

export default memo(DoughnutChart);
