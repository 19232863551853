const API_URL_FROM_ENV = process.env.REACT_APP_URL_API_DEV;
const API_PROTO_FROM_ENV = process.env.REACT_APP_PROTOCOL_API_DEV;
const ACCOUNT = window.location.host.split('.')[0];

/* eslint-disable import/prefer-default-export */
const getBaseUrl = () => {
  const baseUrl = window.location.origin;
  return API_URL_FROM_ENV ? `${API_PROTO_FROM_ENV}${ACCOUNT}.${API_URL_FROM_ENV}` : `${baseUrl}/api/`;
};

export const baseUrl = getBaseUrl();
