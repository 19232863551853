export const GET_VENUE_LIST = 'GET_VENUE_LIST';
export const GET_VENUE_LIST_SUCCESS = 'GET_VENUE_LIST_SUCCESS';
export const GET_VENUE_LIST_FAIL = 'GET_VENUE_LIST_FAIL';

export const GET_VENUE_CSV = 'GET_VENUE_CSV';
export const GET_VENUE_CSV_SUCCESS = 'GET_VENUE_CSV_SUCCESS';
export const GET_VENUE_CSV_FAIL = 'GET_VENUE_CSV_FAIL';

export const GET_VENUE_DATA = 'GET_VENUE_DATA';
export const GET_VENUE_DATA_SUCCESS = 'GET_VENUE_DATA_SUCCESS';
export const GET_VENUE_DATA_FAIL = 'GET_VENUE_DATA_FAIL';

export const ADD_VENUE = 'ADD_VENUE';
export const ADD_VENUE_SUCCESS = 'ADD_VENUE_SUCCESS';
export const ADD_VENUE_FAIL = 'ADD_VENUE_FAIL';

export const EDIT_VENUE = 'EDIT_VENUE';
export const EDIT_VENUE_SUCCESS = 'EDIT_VENUE_SUCCESS';
export const EDIT_VENUE_FAIL = 'EDIT_VENUE_FAIL';

export const RESET_VENUE = 'RESET_VENUE';
