const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '550px',
  tablet: '769px',
  tableView: '900px',
  header: '950px',
  laptop: '1124px',
  laptopL: '1440px',
  auditPage: '1650px',
  desktop: '2560px',
  desktopHd: '1920px',
};

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tablet: `(max-width: ${size.tablet})`,
  tableView: `(max-width: ${size.tableView})`,
  header: `(max-width: ${size.header})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
  auditPage: `(max-width: ${size.auditPage})`,
};

export const deviceLess = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXL: `(min-width: ${size.mobileXL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopHd: `(min-width: ${size.desktopHd})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export default device;
