import styled, { css } from 'styled-components';
import { Section, Content } from 'ui-kit/Section/styles';
import { ButtonWrapper } from 'ui-kit/Button/styles';
import { deviceLess } from '_constants/DEVICES';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  height: 100%;
  padding: 40px 25px 50px;
  @media ${deviceLess.desktopHd} {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
  }

  > ${Section} {
    ${ButtonWrapper} {
      position: absolute;
      top: 25px;
      right: 35px;
      padding: 0 20px;
    }
  }
`;
export const InputWrapper = styled.div`
  margin-bottom: 25px;

  input {
    max-width: 600px;
    width: 100%;
  }

  .react-time-picker {
    max-width: 600px;
  }

  .BaseSelect__control {
    max-width: 600px;
  }
`;

export const Title = styled.label`
  display: inline-block;
  margin: 0 0 5px;
  background: var(--white);
  font-size: 14px;
  line-height: 22px;
  color: var(--dark);
  user-select: none;
  @media ${deviceLess.laptop} {
    position: absolute;
  }
`;

export const Dl = styled.dl`
  padding: 5px 20px;
  overflow: hidden;
  ${({ isSubmission }) =>
    isSubmission &&
    css`
      padding: 10px 20px !important;
      overflow: visible;
    `}
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  border: 1px solid var(--grey);
  @media ${DEVICES.tablet} {
    flex-direction: column;
  }

  ${Section} {
    position: relative;
    height: fit-content;
    @media ${deviceLess.tablet} {
      width: 30%;
      min-width: 350px;
    }
    border: none;

    ${Dl} {
      ${({ isSubmission }) =>
        isSubmission &&
        css`
          padding: 10px 20px;
        `}
      padding: 5px 20px;
    }

    ${Content} {
      padding: 0;
    }
    :first-of-type {
      @media ${deviceLess.tablet} {
        width: 70%;
      }
      border-right: 1px solid var(--grey);

      ${InputWrapper} {
        padding: 25px 20px;
        margin: 0;

        :not(:last-of-type) {
          border-bottom: 1px solid var(--lite-grey);
        }
      }
    }
  }
`;

export const Dt = styled.dt`
  margin: 0 0 10px;
  padding: 0;
  font-size: 16px;
  line-height: 1.17;
  color: var(--dim-grey);
`;

export const Dd = styled.dd`
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark);

  :not(:last-of-type) {
    margin-bottom: 25px;
  }

  a {
    color: var(--curious-blue);
  }

  img {
    display: block;
    width: auto;
    max-height: 206px;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    cursor: pointer;
  }
`;

export const ItemWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
  @media ${deviceLess.laptop} {
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    margin-bottom: 10px;
    
    dt {
      width: 100%;
      min-width: 200px;
      max-width: 50%;
      display: inline-block;
      word-wrap: break-word;
    }
    > dd {
      padding-left: 25px;
      width: 100%;
      min-width: 200px;
      display: inline-block;
      word-wrap: break-word;
    }
  }
}`;

export const VerticalBorder = styled.div`
  @media ${deviceLess.laptop} {
    border-right: 1px solid #e0e0e0;
    position: absolute;
    width: 50%;
    min-width: 200px;
    max-width: 50%;
    height: 100%;
    z-index: 1;
  }
`;

export const HorizontalBorder = styled.div`
  border-bottom: 1px solid #e0e0e0;
  position: absolute;
  top: 110%;
  left: -30px;
  width: 200%;
  z-index: 1;
  filter: opacity(0.4);

  @media ${deviceLess.laptop} {
    height: 100%;
    top: 0;
    left: -30px;
    width: 200%;
    z-index: 1;
    filter: opacity(0.4);
  }
`;

export const SubTitle = styled.h3`
  width: calc(100% + 40px);
  transform: translateX(-20px);
  margin: 25px 0 35px;
  padding: 5px 20px 0;
  font-size: 16px;
  border-top: 1px solid var(--grey);
`;

export const ViolationButton = styled.div`
  position: absolute;
  right: 20px;
  top: 2px;
  z-index: 3;
`;

export const ViolationSectionGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  border: 1px solid var(--grey);
  overflow: hidden;

  @media ${DEVICES.tablet} {
    flex-direction: column;
  }

  ${Section} {
    position: relative;
    height: inherit;
    min-width: 338px;
    border: none;
    overflow: hidden;

    @media ${deviceLess.tablet} {
      width: 30%;
      min-width: 350px;
    }

    ${Dl} {
      ${({ isSubmission }) =>
        isSubmission &&
        css`
          padding: 10px 20px;
        `}
      padding: 5px 20px;
    }

    ${Content} {
      padding: 0;
    }

    :first-of-type {
      width: 100%;

      @media ${deviceLess.tablet} {
        width: 70%;
      }
      border-right: 1px solid var(--grey);
    }
  }
`;

export const SubmissionItemWrapper = styled.div`
  margin-bottom: 10px;
   > dt{
  margin: 0;
   }

  > dd {
  min-width: 298px;
  display: inline-block;
  word-wrap: break-word;
  }

@media ${deviceLess.laptop} {
  display: flex;
  flex-direction: row;
  
  dt {
    width: 170px;
    min-width: 130px;
  }
  > dd {
    padding-left: 10px;
    width: 100%;
    min-width: 200px;
    display: inline-block;
    word-wrap: break-word;
  }
}
}`;
