import FILTER_FIELDS from './FILTER_FIELDS';

const RETAILERS_QUICK_QUERY_OPTIONS = {
  OPTION_CURRENT_TOBACCO: 'Retailers selling tobacco',
  OPTION_CURRENT_ALCOHOL: 'Retailers selling alcohol',
  OPTION_CURRENT_NEAR_SCHOOL: 'Retailers near schools',
  OPTION_CURRENT_NO_VISIT_TYPE: 'Retailers with no visit types:,within time period:',
  OPTION_CURRENT_MORE_ENFORCEMENT_VIOLATION:
    'Retailers with \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 or more Enforcement violations',
  OPTION_CURRENT_LAST_YEAR_VIOLATIONS:
    'Retailers with \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 or more sale to minor violations within one year of each other',
};

const RETAILERS_QUICK_QUERY_MAPPING = {
  [FILTER_FIELDS.TOBACCO]: RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_TOBACCO,
  [FILTER_FIELDS.ALCOHOL]: RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_ALCOHOL,
  [FILTER_FIELDS.NEAR_SCHOOL]: RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_NEAR_SCHOOL,
  [FILTER_FIELDS.NO_VISIT_TYPE]: RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_NO_VISIT_TYPE,
  [FILTER_FIELDS.VIOLATION_COUNT_GRT]: RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_MORE_ENFORCEMENT_VIOLATION,
  [FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT]: RETAILERS_QUICK_QUERY_OPTIONS.OPTION_CURRENT_LAST_YEAR_VIOLATIONS,
};

export default {
  RETAILERS_QUICK_QUERY_OPTIONS,
  RETAILERS_QUICK_QUERY_MAPPING,
};
