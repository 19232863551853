export const addTimezoneToDate = (date = new Date()) => {
  if (typeof date === 'string') {
    const selectedDate = new Date(date);
    const offsetInMinutes = selectedDate.getTimezoneOffset();
    const offsetInHours = offsetInMinutes / 60;

    return new Date(new Date(date).getTime() + 60 * offsetInHours * 60 * 1000);
  }
  return date;
};
