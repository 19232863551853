/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React from 'react';
import { FILTER_TABS } from '_constants/old/filters';
import { RetailerCharacteristics } from 'pages/Audit/old/filters/RetailerCharacteristics';
import { LicenseCharacteristics } from 'pages/Audit/old/filters/LicenseCharacteristics';
import { EnforcementCharacteristics } from 'pages/Audit/old/filters/EnforcementCharacteristics';
import { StoreMerger } from 'pages/Audit/old/filters/StoreMerger';
import { MobileCollapsedTab } from '../../components/MobileCollapsedTab';
import QuickQuery from '../../sections/QuickQuery';
import { FilterNameAddress } from '../../sections/FilterByAddressName';
import { QueryBuilder } from '../../sections/QueryBuilder';
import { AdvancedFilters } from '../../sections/AdvancedFilters';
import * as Styled from './styles';

export const MobileFilterView = ({ filterType, menu }) => {
  const FILTER_DATA = [
    {
      title: FILTER_TABS.QUICK_QUERY,
      children: <QuickQuery filterType={filterType} />,
    },
    {
      title: FILTER_TABS.FILTER_BY_NAME_ADDRESS,
      children: <FilterNameAddress filterType={filterType} />,
    },
    {
      title: FILTER_TABS.QUERY_BUILDER,
      children: <QueryBuilder filterType={filterType} />,
    },
    {
      title: FILTER_TABS.ADVANCED_FILTERS,
      children: <AdvancedFilters filterType={filterType} />,
    },
    {
      title: FILTER_TABS.RETAILER_CHARACTERISTICS,
      children: <RetailerCharacteristics />,
    },
    {
      title: FILTER_TABS.LICENSE_CHARACTERISTICS,
      children: <LicenseCharacteristics />,
    },
    {
      title: FILTER_TABS.ENFORCEMENT_CHARACTERISTICS,
      children: <EnforcementCharacteristics />,
    },
    {
      title: FILTER_TABS.STORE_MERGER,
      children: <StoreMerger />,
    },
  ];

  const FILTER_CONTENT = menu.reduce((acc, item) => {
    if (!item.isActive) return;
    const currentItem = FILTER_DATA.find((filterData) => filterData.title === item.name);
    acc.push(currentItem);
    return acc;
  }, []);

  return (
    <Styled.Wrapper aria-label="modalDescription">
      {FILTER_CONTENT.map(({ title, children }, index) => (
        <MobileCollapsedTab title={title} key={index} index={index}>
          {children}
        </MobileCollapsedTab>
      ))}
    </Styled.Wrapper>
  );
};
