export const saveIdToLocalStorage = (id) => {
  const importIdList = JSON.parse(localStorage.getItem('importIdList')) || [];

  if (!importIdList.includes(id)) {
    importIdList.push(id);

    localStorage.setItem('importIdList', JSON.stringify(importIdList));
  }
};

export const isIdInLocalStorage = (profileIds) => {
  if (!profileIds) return false;
  const importIdList = JSON.parse(localStorage.getItem('importIdList')) || [];
  const profileIdList = profileIds?.map(({ id }) => +id);
  const lastImportId = Math.max(...profileIdList.map((id) => +id));

  return importIdList.includes(lastImportId);
};
