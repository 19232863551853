import * as actions from './Auth.actions';
import reducer from './Auth.reducer';
import * as types from './Auth.types';
import * as selectors from './Auth.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
