import styled from 'styled-components';
import { Wrapper as FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup/styles';
import { DEVICES } from '_constants';

export const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))`
  position: relative;

  ${FormGroup} {
    padding: 22px 20px;

    @media ${DEVICES.tablet} {
      padding: 10px 18px;
    }
  }
`;
