import React, { useMemo } from 'react';
import { useWindowDimensions } from 'hooks';
import PropTypes from 'prop-types';
import ItemsPerPage from './old/components/ItemsPerPage';
import PageCount from './old/components/PageCount';
import PaginationPageInfo from './old/components/PaginationPageInfo';
import PaginationNavigation from './old/components/PaginationNavigation';
import * as Styled from './styles';

const TableSizeControls = ({
  itemsPerPageProps,
  paginationNavProps,
  paginationInfoProps,
  pageCountProps,
  isStoreMerger,
}) => {
  const { width: viewportWidth } = useWindowDimensions();

  const dropDownBlock = useMemo(
    () => (
      <Styled.DropdownsBlock>
        <ItemsPerPage {...itemsPerPageProps} />
        <PageCount {...pageCountProps} />
      </Styled.DropdownsBlock>
    ),
    [itemsPerPageProps, pageCountProps],
  );

  const paginationBlock = useMemo(
    () => (
      <Styled.PaginationBlock>
        <PaginationPageInfo {...paginationInfoProps} />
        <PaginationNavigation {...paginationNavProps} />
      </Styled.PaginationBlock>
    ),
    [paginationInfoProps, paginationNavProps],
  );

  return (
    <Styled.Wrapper className="table-footer" isStoreMerger={isStoreMerger}>
      {viewportWidth >= 1124 ? (
        <>
          {dropDownBlock}
          {paginationBlock}
        </>
      ) : (
        <>
          {paginationBlock}
          {dropDownBlock}
        </>
      )}
    </Styled.Wrapper>
  );
};

TableSizeControls.propTypes = {
  itemsPerPageProps: PropTypes.object.isRequired,
  paginationNavProps: PropTypes.object.isRequired,
  paginationInfoProps: PropTypes.object.isRequired,
  pageCountProps: PropTypes.object.isRequired,
  isStoreMerger: PropTypes.bool,
};

TableSizeControls.defaultProps = {
  isStoreMerger: false,
};

export default TableSizeControls;
