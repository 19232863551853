import React, { useMemo } from 'react';
import { BaseSelect } from 'ui-kit';
import PropTypes from 'prop-types';
import * as Styled from './styles';

function TableDropDown({ value, options, onChange, isSearchable, inputId }) {
  const dropDownValue = useMemo(
    () => ({
      label: value,
      value,
    }),
    [value],
  );
  return (
    <Styled.Wrapper>
      <BaseSelect
        value={dropDownValue}
        onChange={onChange}
        options={options}
        inputId={inputId}
        isSearchable={isSearchable}
      />
    </Styled.Wrapper>
  );
}

TableDropDown.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputId: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.bool.isRequired,
  isSearchable: PropTypes.bool,
};

TableDropDown.defaultProps = {
  isSearchable: false,
};

export default TableDropDown;
