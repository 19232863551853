import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { VenueEntity } from '_entities';
import { Loader } from 'ui-kit';
import VenueAdd from 'pages/Venues/Add';
import { HasAccess } from 'modules';
import { PERMISSIONS, URLS, USER_ROLE_TYPES } from '_constants';

const { getVenueData } = VenueEntity.actions;
const { getVenueInfo } = VenueEntity.selectors;

const { ACCOUNT_MANAGER, MULTI_ACCOUNT_MANAGER, AGENCY_MANAGER, CAMPAIGN_MANAGER, DATA_COLLECTOR } = USER_ROLE_TYPES;

function VenueEdit() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const venueInfo = useSelector(getVenueInfo);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getVenueData(id));
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading((prev) => !prev);
      }
    })();
  }, []);

  const roleRuleSet = {
    secGeogs: [AGENCY_MANAGER, CAMPAIGN_MANAGER, ACCOUNT_MANAGER, DATA_COLLECTOR],
    all: [MULTI_ACCOUNT_MANAGER],
  };

  const venueRuleData = {
    aggregationpolygon_set: venueInfo?.relationships?.aggregationpolygon_set,
  };

  const canEditVenue = HasAccess(PERMISSIONS.CHANGE_VENUE, venueRuleData, roleRuleSet);

  if (isLoading) return <Loader />;

  return canEditVenue ? <VenueAdd isEditPage /> : <Redirect to={URLS.dashboard} />;
}

export default VenueEdit;
