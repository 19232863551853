/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LinkElem } from 'modules/Header/styles';
import PropTypes from 'prop-types';
import { URLS } from '_constants';
import { CollectEntity } from '_entities';
import CollectItem from './CollectItem';
import * as Styled from '../styles';

const { getCollectCount } = CollectEntity.selectors;

function RouteItem({ route, onClick, index, tabRef, headerFocus }) {
  const collectCount = useSelector(getCollectCount);
  const handleClick = useCallback(
    (e, path) => {
      onClick(e, path);
    },
    [index, onClick],
  );

  return (
    <Styled.RouteListItem role="menuitem">
      <LinkElem
        to={route.path}
        aria-label={route.title}
        name={route.path}
        onClick={(e) => handleClick(e, route.path)}
        ref={tabRef}
        tabIndex={headerFocus === index ? 0 : -1}
      >
        {route.title}
      </LinkElem>
      {route.path === URLS.collect && collectCount > 0 && <CollectItem count={collectCount} />}
    </Styled.RouteListItem>
  );
}

RouteItem.propTypes = {
  route: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  tabRef: PropTypes.object.isRequired,
  headerFocus: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RouteItem;
