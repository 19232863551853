import { formatDateTimeZone } from 'utils/custom';

const getCampaignManager = (data, id) => {
  if (!id || !data) return null;
  const campaign = data.find((item) => item.id === id);
  return campaign?.relationships.campaign_manager || null;
};

const getRoleName = (data, itemId) => {
  if (!itemId || !data) return null;
  const userItem = data.find(({ id }) => id === itemId);
  const roleName = userItem.attributes.role_name;
  return roleName;
};

export default (data, included, timeZone) => {
  const enforcements = data.map((item) => {
    const { attributes, relationships } = item;
    const {
      agency_name: agencyName,
      penalty,
      violation_status_text: violationStatus,
      violation_text: violation,
      visit_time: visitDate,
      visit_type_text: visitType,
      aggregationpolygon_set: secGeog,
    } = attributes;

    const { agency, campaign, user } = relationships;
    const campaignList = included?.filter(({ type }) => type === 'Campaign');
    const userList = included.filter(({ type }) => type === 'User');
    const userId = user?.data?.id;

    const enforcementItem = {
      id: item.id,
      agency: { data: { ...agency.data, name: agencyName } },
      penalty,
      sec_geogs: secGeog?.map((geog) => `${geog}`),
      violationStatus,
      violation,
      visitDate: formatDateTimeZone(visitDate, timeZone, 'MM/DD/YYYY'),
      visitType,
      campaign,
      campaign_manager: getCampaignManager(campaignList, campaign?.data?.id),
      dataCollectors: userId ? [userId] : [],
      roleName: getRoleName(userList, userId),
      user,
    };
    return enforcementItem;
  });

  return enforcements;
};
