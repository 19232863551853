export default {
  LOCATION: 'Location',
  DEMOGRAPHICS: 'Demographics',
  SALES_SUSPENSION: 'Sales suspension',
  POLICY_TESTER: 'Policy tester',
  INTERNET_SALES: 'Internet sales',
  RETAILER_TYPE: 'Retailer type',
  ENFORCEMENT_DATE_AGENCY_TYPE: 'Enforcement activity date, agency and type',
  INSPECTION_OUTCOME: 'Inspection Outcome',
  ENFORCEMENT_OTHER: 'Product Type',
  LICENSE_SUSPENDED: 'License suspended',
  LICENSE_DETAILS: 'License details',
  VISITS_HISTORY: 'Visits history',
  RETAILER_CHARACTERISTICS: 'Retailer characteristics',
  VISIT_DATE_AGENCY_STATUS: 'Visit date, agency, and status',
  VISIT_CHARACTERISTICS: 'Visit Characteristics and Outcomes',
};
