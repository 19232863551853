import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--grey);
  font-weight: normal;
  color: red;
  padding: 25px;
`;

export const Title = styled.h2`
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  line-height: 50px;
  padding: 0 20px;
`;

export const ListItem = styled.li`
  padding: 5px;
`;
