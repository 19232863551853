/* eslint-disable react/prop-types */
import React, { memo, useMemo, useState } from 'react';
import { compact } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { TableWrapper, ActionCell } from 'ui-kit';
import getToastLock from 'utils/old/getAddToastLock';
import useToastContext from 'utils/old/useToastContext';
import { URLS, PERMISSIONS } from '_constants';
import useSortTable from 'utils/old/useSortTable';
import { AccessControl } from 'modules';
import useIsUserCanEditHook from 'pages/User/utils';
import { COLUMNS, INITIAL_COLUMNS } from '../constants';

const getInitialColumns = ({ handleSort, sortOption, dumpedActiveColumns, isUserCanEdit, editCellParams }) =>
  compact([
    dumpedActiveColumns.first_name && {
      Header: () => (
        <HeaderCell
          col="First Name"
          sortBy="first_name"
          sortOption={sortOption}
          handleSort={handleSort('first_name')}
        />
      ),
      minWidth: 140,
      accessor: 'first_name',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.last_name && {
      Header: () => (
        <HeaderCell col="Last Name" sortBy="last_name" sortOption={sortOption} handleSort={handleSort('last_name')} />
      ),
      minWidth: 140,
      accessor: 'last_name',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.email && {
      Header: () => (
        <HeaderCell col="Email address" sortBy="email" sortOption={sortOption} handleSort={handleSort('email')} />
      ),
      minWidth: 140,
      accessor: 'email',
      Cell: ({ value, original }) => <LinkCell id={original.id} text={value} to={`${URLS.users}/${original.id}`} />,
    },
    dumpedActiveColumns.is_active && {
      Header: () => (
        <HeaderCell col="Status" sortBy="is_active" sortOption={sortOption} handleSort={handleSort('is_active')} />
      ),
      minWidth: 140,
      accessor: 'is_active',
      Cell: ({ value }) => <TextCell text={value ? 'Active' : 'Inactive'} />,
    },
    dumpedActiveColumns.role_name && {
      Header: () => (
        <HeaderCell col="User role" sortBy="role_name" sortOption={sortOption} handleSort={handleSort('role_name')} />
      ),
      minWidth: 140,
      accessor: 'role',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    {
      Header: () => {},
      width: 56,
      accessor: 'editPen',
      Cell: ({ original }) => {
        return (
          isUserCanEdit(original.state, original.id, original.userRoleType, original.agencies, original.secGeogs) && (
            <AccessControl permission={PERMISSIONS.CHANGE_USER}>
              <ActionCell original={original} {...editCellParams} />
            </AccessControl>
          )
        );
      },
    },
  ]);

const UsersTable = ({ data, sortHandler, sortOption, myUserRoleType, myUserId }) => {
  const handleSort = useSortTable(sortOption, sortHandler);
  const [isUserCanEdit] = useIsUserCanEditHook();
  const [activeColumns, setActiveColumns] = useState(INITIAL_COLUMNS);
  const [isOpen, setIsOpen] = useState(false);
  const addToast = useToastContext();
  const getToast = () => getToastLock('Enforcement', addToast);

  const dumpedActiveColumns = useMemo(
    () => activeColumns.reduce((acc, value) => ({ ...acc, [value]: value }), {}),
    [activeColumns],
  );
  const tableControlParams = useMemo(
    () => ({
      activeColumns,
      columns: COLUMNS,
      setActiveColumns,
      setIsOpen,
      isOpen,
    }),
    [activeColumns, isOpen],
  );

  const editCellParams = useMemo(
    () => ({
      editText: ' user',
      route: URLS.userPages.edit,
      getToast,
      onlyEdit: true,
      myUserRoleType,
      myUserId,
    }),
    [],
  );

  const wrappedColumns = useMemo(
    () =>
      getInitialColumns({
        handleSort,
        sortOption,
        dumpedActiveColumns,
        tableControlParams,
        isUserCanEdit,
        editCellParams, // eslint-disable-next-line
      }),
    [dumpedActiveColumns, sortOption, tableControlParams, handleSort, isUserCanEdit, editCellParams],
  );

  return (
    <TableWrapper
      columns={wrappedColumns}
      data={data}
      tableControlParams={tableControlParams}
      isEdit
      editCellParams={editCellParams}
    />
  );
};

UsersTable.defaultProps = {};

export default memo(UsersTable);
