export default {
  MULTI_ACCOUNT_MANAGER: 'Multi-Account Manager',
  ACCOUNT_MANAGER: 'Account Manager',
  AGENCY_MANAGER: 'Agency Manager',
  CAMPAIGN_MANAGER: 'Campaign Manager',
  DATA_COLLECTOR: 'Data Collector',
  OPEN_DATA_COLLECTOR: 'Open Data Collector',
  OPEN_DATA_COLLECTOR_BETA: 'Open Data Collector BETA',
  VISITOR: 'Visitor',
  LOCAL_DATA_COLLERCTOR: 'Local View Data Collector',
  LOCAL_CAMPAIGN_MANAGER: 'Local Campaign Manager',
  LOCAL_VIEW_CAMPAIGN_MANAGER: 'Local View Campaign Manager',
};
