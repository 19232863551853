import React from 'react';
import { URLS } from '_constants';
import { VisitView } from 'modules';

const EnforcementView = () => {
  return (
    <VisitView
      headerTitle="View enforcement visit"
      sectionTitle="Enforcement information"
      editPageUrl={URLS.enforcementPages.edit}
      editViolationUrl={URLS.enforcementPages.violationsEdit}
      viewUrl={URLS.enforcement}
    />
  );
};

export default EnforcementView;
