import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'react-time-picker';

import * as Styled from './styles';

const HOUR_NAME = 'hour24';
const MINUTE_NAME = 'minute';
const AMPM_NAME = 'amPm';

// if someone will edit onChange callback behaviour, please make the behavior like this:
// if hours or minutes is empty, so you return in onChange null, the same as will be if 2 fields will be empty in the same time
function BaseTimePicker({ onChange, value, label, rest, error, pageColumns }) {
  const time = useMemo(() => {
    if (!value) return null;
    const currentTime = value.split(':');
    return {
      hours: +currentTime[0] || 0,
      minutes: +currentTime[1] || 0,
    };
  }, [value]);

  const hourElem = document.getElementsByName(HOUR_NAME)[0];
  const minuteElem = document.getElementsByName(MINUTE_NAME)[0];
  const amPmElement = document.getElementsByName(AMPM_NAME)[0];

  useMemo(() => {
    if (amPmElement) {
      amPmElement.id = AMPM_NAME;
    }
    if (minuteElem) {
      minuteElem.id = MINUTE_NAME;
    }
    if (hourElem) {
      hourElem.id = HOUR_NAME;
    }
  }, [amPmElement, hourElem, minuteElem]);

  return (
    <Styled.Wrapper hours={time?.hours} minutes={time?.minutes} pageColumns={pageColumns}>
      {label && <Styled.Label> {label} </Styled.Label>}
      <TimePicker
        onChange={onChange}
        value={value}
        disableClock
        amPmAriaLabel="am/pm"
        hourAriaLabel="hours"
        minuteAriaLabel="minutes"
        nativeInputAriaLabel="time"
        className={error ? 'error' : ''}
        {...rest}
        // Don't delete format prop. It allows to use am/pm format on Windows OS
        format="h:mm a"
      />
      <Styled.Error aria-label="error">{error && error}</Styled.Error>
    </Styled.Wrapper>
  );
}

BaseTimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  rest: PropTypes.any,
  error: PropTypes.string,
  pageColumns: PropTypes.string,
};

BaseTimePicker.defaultProps = {
  value: '12:00',
  rest: {},
  error: '',
  pageColumns: '',
};

export default BaseTimePicker;
