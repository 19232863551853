import React, { useCallback, useReducer } from 'react';
import { AuthEntity } from '_entities';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PageContainer } from 'modules';
import { BUTTON_TYPES, INPUT_TYPE, MESSAGES, URLS } from '_constants';
import { notification } from 'utils/services';
import BackPageButton from 'modules/BackPageButton';
import { EMAIL_NOTIFICATION_ICON } from 'assets/icons';
import { formInitialState, FORM_ACTION_TYPES, inputTypes } from './constants';
import { formReducer, init } from './_utils';
import * as Styled from './styles';

const { forgotPassword } = AuthEntity.actions;

function ForgotPassword() {
  const [formState, formDispatch] = useReducer(formReducer, formInitialState, init);
  const history = useHistory();

  const dispatch = useDispatch();

  const handleChange = useCallback(({ target: { name, value } }) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!formState?.canBeSubmitted) {
        formDispatch({ type: FORM_ACTION_TYPES.VALIDATE_DATA, name: inputTypes.email, payload: formState.data.email });
        return;
      }
      try {
        const data = {
          email: formState.data.email,
        };
        await dispatch(forgotPassword(data));
      } catch (err) {
        console.log(err);
      } finally {
        notification.info({ notification: MESSAGES.FORGOT.MATCH, icon: EMAIL_NOTIFICATION_ICON });
        history.push(URLS.login);
      }
    },
    [dispatch, formState, history],
  );

  return (
    <PageContainer>
      <Styled.BackButtonWrapper>
        <BackPageButton />
      </Styled.BackButtonWrapper>
      <Styled.Wrapper>
        <Styled.FormWrapper onSubmit={handleSubmit}>
          <Styled.Title>Forgot password?</Styled.Title>
          <Styled.Description>Please enter your email address to reset it.</Styled.Description>
          <Styled.ForgotInput
            name={inputTypes.email}
            value={formState.data.email}
            error={formState.errors.email}
            type={INPUT_TYPE.EMAIL}
            floatingLabel
            title="Email address"
            onChange={handleChange}
          />
          <Styled.SubmitButton text="Reset password" variant={BUTTON_TYPES.DANGER} />
        </Styled.FormWrapper>
      </Styled.Wrapper>
    </PageContainer>
  );
}

export default ForgotPassword;
