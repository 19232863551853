import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { BaseSelect, Modal, Button } from 'ui-kit';
import { BUTTON_TYPES, MESSAGES } from '_constants';
import { VisitEntity } from '_entities';
import { getTimeZone } from 'utils/custom';
import { notification } from 'utils/services';
import generateQSParams from 'utils/old/generateQSParams';
import * as Styled from './styles';

const { getVisitListCSV } = VisitEntity.actions;
const { getVisitForms } = VisitEntity.selectors;

const exceptionFields = ['page', 'page_size', 'ordering'];

export default function ExportVisitModal({ open, onClose, visitType }) {
  const [selectedForm, setSelectedForm] = useState([]);
  const listOfForms = useSelector(getVisitForms);
  const {
    oldFilter: { shadowFilters },
  } = useSelector((state) => state);
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();

  const options = useMemo(
    () =>
      listOfForms?.map((item) => ({
        label: item.form__name,
        value: item.form_id,
      })),
    [listOfForms],
  );

  const closeModal = () => {
    setSelectedForm('');
    setIsValid(true);
    onClose();
  };

  const exportCsv = async () => {
    if (!selectedForm.length) {
      setIsValid(false);
      return null;
    }
    try {
      const idList = selectedForm.map((item) => item.value).join(',');
      const newFilters = Object.keys(shadowFilters).reduce((acc, item) => {
        if (exceptionFields.includes(item)) return acc;
        acc[item] = shadowFilters[item];
        return acc;
      }, {});
      newFilters.tz = getTimeZone(Date.now()).timeZone;
      const params = generateQSParams(newFilters);
      await dispatch(getVisitListCSV(visitType, idList, params));
      notification.success(MESSAGES.COLLECT.SUCCESS);
      setSelectedForm('');
      onClose();
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  const handleChangeSelect = (val) => {
    setSelectedForm(val);
    setIsValid(true);
  };

  if (!open) return null;
  return (
    <Modal autoWidth withoutPadding open={open} onClose={closeModal} preventDesktopOverflow>
      <Styled.Wrapper>
        <>
          <Styled.Title>Export .csv</Styled.Title>
          <Styled.Description>Choose the survey form(s):</Styled.Description>
          <Styled.DropWrapper>
            <BaseSelect
              value={selectedForm}
              onChange={handleChangeSelect}
              inputId="surveyVisitModalDrop"
              options={options}
              isMulti
              error={!isValid && 'The form is required'}
              className={!isValid && 'error'}
              isExportVisit
              allowSelectAll
            />
          </Styled.DropWrapper>
        </>
        <Styled.Footer>
          <Button onClick={closeModal} text="Cancel" variant={BUTTON_TYPES.DARK} />
          <Button onClick={exportCsv} text="Export" variant={BUTTON_TYPES.DANGER} />
        </Styled.Footer>
      </Styled.Wrapper>
    </Modal>
  );
}

ExportVisitModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  visitType: PropTypes.number.isRequired,
};

ExportVisitModal.defaultProps = {
  open: false,
};
