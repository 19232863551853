export default {
  RETAILERS: 'Retailers',
  LICENSES: 'Licenses',
  REPORTS: 'reports',
  ENFORCEMENT: 'Enforcement activities',
  DASHBOARD: 'dashboard',
  ID_FILTER_DESC: 'firstDesktopItem',
  ID_FILTER_MOB: 'firstMobItem',
  ID_LOG_IN_INPUT: 'emailInput',
  DUPLICATES: 'duplicates',
  SEPARATE: 'separate',
  HISTORIC: 'historic',
  KEY_LICENSES: 'licenses',
  KEY_RETAILERS: 'retailers',
  KEY_SUSPENSION: 'suspensions',
  KEY_ENFORCEMENTS: 'enforcement',
  CITY: 'city',
  ADDRESS: 'address',
  NAME: 'name',
  JURISDICTIONS: 'jurisdictions',
  ADD_TEXT: 'The agency was created',
  EDIT_TEXT: 'All changes are saved',
  DELETE_TEXT: 'The agency was deleted',
  EDIT_20H: 'Please note that you have 20 hours to edit a enforcement',
  ENF_SAVED: 'The enforcement was successfully created',
  ENF_DELETE: 'Enforcement was deleted',
  PASSWORD_RESET: 'Password reset email was successfully sent.',
  PASSWORD_RESET_ERROR: 'Password reset was not sent.',
  ADD_USER: 'The user was successfully created',
  EDIT_USER: 'Changes are saved',
  DUPLICATE_USER: 'A user with that User ID already exists',
  LIS_DELETE: 'The license was deleted',
  LIS_ADD: 'The license was successfully created',
  LIS_EDIT_LOCK: 'Please note that you have 20 hours to edit a license',
  SUS_CREATE: 'The suspension was successfully created',
  SUS_DELETE: 'The suspension was deleted',
  RETAILER_SAVE: 'The retailer was successfully created',
  RETAILER_DELETE: 'The retailer was deleted',
  EDIT_20H_RETAILER: 'Please note that you have 20 hours to edit a retailer',
  RETAILER_DELETE_OUT: `Sorry, this Retailer is out of your jurisdiction. You can add/edit
  Retailers only from your jurisdictions`,
  RETAILER_CITY_OUT: `The latitude and longitude fall outside of your jurisdictions`,
  NEW_GEO: ` The location was successfully updated`,
  AGE_CREATE: 'The agency was successfully created',
  AGE_DELETE: 'The agency was deleted',
  AGE_EDIT: 'All changes are saved',
};
