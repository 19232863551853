/* eslint-disable react/prop-types */
import React from 'react';
import VisitsLists from 'modules/VisitsListPage';
import { fetchEnforcements } from '_entities/old/Enfocement/enforcement.service';
import { VisitEntity } from '_entities';
import { useSelector } from 'react-redux';
import VISIT_TYPE from '_constants/VISIT_TYPE';
import { FILTER_TYPES } from '_constants/old/filters';
import getInitialColumns from './getInitColumns';

const ENFORCEMENT_HEADER = 'Enforcement visits';

const { getEnforcementTableData } = VisitEntity.selectors;

const { ENFORCEMENT } = VISIT_TYPE;

const EnforcementContainerList = ({ location }) => {
  const tableData = useSelector(getEnforcementTableData);

  return (
    <VisitsLists
      tableAPIcall={fetchEnforcements}
      titleHeader={ENFORCEMENT_HEADER}
      tableData={tableData}
      visitType={ENFORCEMENT}
      getInitColumns={getInitialColumns}
      filterType={FILTER_TYPES.ENFORCEMENT}
      search={location.search}
    />
  );
};

export default EnforcementContainerList;
