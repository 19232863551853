export const GET_CALC_POLY = 'GET_CALC_POLY';
export const GET_CALC_POLY_SUCCESS = 'GET_CALC_POLY_SUCCESS';
export const GET_CALC_POLY_FAIL = 'GET_CALC_POLY_FAIL';

export const GET_AREA_REPORT = 'GET_AREA_REPORT';
export const GET_AREA_REPORT_SUCCESS = 'GET_AREA_REPORT_SUCCESS';
export const GET_AREA_REPORT_FAIL = 'GET_AREA_REPORT_FAIL';

export const GET_ENFORCEMENT_REPORT = 'GET_ENFORCEMENT_REPORT';
export const GET_ENFORCEMENT_REPORT_SUCCESS = 'GET_ENFORCEMENT_REPORT_SUCCESS';
export const GET_ENFORCEMENT_REPORT_FAIL = 'GET_ENFORCEMENT_REPORT_FAIL';

export const GET_EDUCATION_REPORT = 'GET_EDUCATION_REPORT';
export const GET_EDUCATION_REPORT_SUCCESS = 'GET_EDUCATION_REPORT_SUCCESS';
export const GET_EDUCATION_REPORT_FAIL = 'GET_EDUCATION_REPORT_FAIL';

export const RESET_CALC_POLY = 'RESET_CALC_POLY';
