import React from 'react';
import PropTypes from 'prop-types';

import L from 'leaflet';
import HomeIconImage from '../../../../../assets/icons/home.svg';
import StreetViewIconImage from '../../../../../assets/icons/pegman.svg';
import { MapDiv } from './singleRetailerMap.styles';

import { MAP_MINZOOM, MAP_MAXZOOM, MAP_BBOX_USA, MAP_BASEMAPS } from '../../../../SnapshotMaps/constants';

require('leaflet/dist/leaflet.css');
require('../../../../../mapcontrols/leaflet-basemapbar');
require('../../../../../mapcontrols/leaflet-basemapbar.css');
require('../../../../../mapcontrols/leaflet-zoombar.css');
require('../../../../../mapcontrols/leaflet-zoombar');

const loadGoogleMapsApi = require('load-google-maps-api');

const googleMapsApiConfig = {
  clientId: 'gme-countertools',
};

class SingleRetailerMap extends React.Component {
  constructor(props) {
    super(props);

    this.mapDiv = React.createRef(); // React ref to the map's DIV
    this.leafletMap = undefined; // the L.Map
    this.mapDivId = `map-${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`; // L.Map() requires the DIV id= attribute; generate a random one

    this.mapStreetViewDiv = React.createRef(); // React ref to the DIV for Google StreetView

    this.retailerMarker = undefined; // retailer marker, draggable; used to show and also to change the retailer latlong
  }

  componentDidMount() {
    this.initMap();
    this.initGoogleStreetView();
  }

  componentDidUpdate(oldProps) {
    // isDraggable = call setMarkerDraggable() to toggle the marker's draggable status and other UI behaviors
    const { isDraggable } = this.props;
    this.setMarkerDraggable(isDraggable);

    // retailerData = move marker to the given latlong
    // if changing over to Add Retailer and invalid latlng, handle that special case too
    const retailerhaschanged = oldProps.retailerData.id !== this.props.retailerData.id; // eslint-disable-line react/destructuring-assignment
    const hasretailer = this.props.retailerData.id; // eslint-disable-line react/destructuring-assignment
    if (!hasretailer) return;

    if (retailerhaschanged) {
      this.retailerMarkerUpdateFromProps();

      if (this.retailerMarkerHasValidLatLng()) {
        this.leafletMap.setView(this.retailerMarker.getLatLng(), MAP_MAXZOOM);
      } else {
        this.leafletMap.fitBounds(MAP_BBOX_USA);
      }
    }
  }

  // this method could be used by the caller to get the retailer-marker's latlng location, e.g. when saving
  getRetailerLatLng() {
    if (!this.retailerMarkerHasValidLatLng()) return null;

    const latlng = this.retailerMarker.getLatLng();
    return {
      latitude: latlng.lat,
      longitude: latlng.lng,
    };
  }

  setMarkerDraggable(draggable) {
    // toggle the retailer marker's dragging behavior
    // but also a custom behavior of changing its mouse cursor to show that it is/isn't draggable
    const icon = this.retailerMarker._icon;

    if (draggable) {
      this.retailerMarker.dragging.enable();
      icon.style.pointerEvents = '';
    } else {
      this.retailerMarker.dragging.disable();
      icon.style.pointerEvents = 'none';
    }
  }

  initMap() {
    // the L.Map and our basic amenities: nicer zoom bar, scale bar, base map selector
    this.leafletMap = L.map(this.mapDivId, {
      minZoom: MAP_MINZOOM,
      maxZoom: MAP_MAXZOOM,
      keyboard: true, // keyboard basics: arrow keys pan, +- zoom
      scrollWheelZoom: !L.Browser.ie, // IE bug, allow scroll means we can't scroll within other controls e.g. layer panel
      zoomControl: false, // custom zoom control below
      tap: false, // work around Safari double-clicking the map
    }).fitBounds(MAP_BBOX_USA);
    this.mapDiv.current.ariaLabel =
      'Map showing retailer location. Pan with arrow keys. Zoom in with + key. Zoom out with - key.';

    L.basemapbar({
      layers: MAP_BASEMAPS,
      controlid: this.mapDivId,
    })
      .addTo(this.leafletMap)
      .selectLayer(MAP_BASEMAPS[0].label);

    this.leafletMap.zoombar = L.zoombar({
      position: 'topright',
      controlid: this.mapDivId,
      homeBounds: MAP_BBOX_USA, // modified after the Retailer latlong is loaded
      homeIconUrl: HomeIconImage,
    }).addTo(this.leafletMap);

    L.control
      .scale({
        position: 'bottomright',
        updateWhenIdle: true,
      })
      .addTo(this.leafletMap);

    // the retailer marker; not added to the map yet, that's on update
    // note that during Add Retailer we have "" latitude & longitude; work around that
    this.retailerMarker = L.marker([0, 0], {
      draggable: false, // see setMarkerDraggable() to toggle this
      keyboard: false,
      icon: L.divIcon({
        className: 'retailer-point-icon',
        iconSize: [20, 20],
        iconAnchor: [10, 10],
        popupAnchor: [10, 10],
      }),
      priorLatLng: null, // set in dragstart event so we can "undo" a drag
    });
    this.retailerMarker.addTo(this.leafletMap);

    this.retailerMarker
      .on('dragstart', () => {
        if (this.retailerMarkerHasValidLatLng()) {
          // if we started with invalid latlng, skip priorLatLng and "marker moved 8000 miles" warning
          this.retailerMarker.priorLatLng = this.retailerMarker.getLatLng();
        }
      })
      .on('dragend', () => {
        this.positionRetailerMarkerAtLatLng(this.retailerMarker.getLatLng(), true);
      });

    this.leafletMap.on('click', (event) => {
      // clicking the map will simulate a drag event on the marker, so it tracks prior & new position
      // but should do nothing if dragging is currently disabled
      const draggable = this.retailerMarker.dragging.enabled();
      if (!draggable) return;

      // if we started with invalid latlng, skip dragstart priorLatLng and "marker moved 8000 miles" warning
      if (this.retailerMarkerHasValidLatLng()) this.retailerMarker.fire('dragstart');
      this.retailerMarker.setLatLng(event.latlng).fire('dragend');
    });

    // set the view by zooming to this.retailerMarker if we had a valid latlng
    this.retailerMarkerUpdateFromProps();
    if (this.retailerMarkerHasValidLatLng()) {
      this.leafletMap.setView(this.retailerMarker.getLatLng(), MAP_MAXZOOM);
    }
  }

  initGoogleStreetView() {
    // custom control: Google Street View
    // button triggers the draggable marker (or clears it)
    // dragging marker updates the GSV, and moving the GSV moves the marker
    this.leafletMap.streetview = {};

    this.leafletMap.streetview.button = L.easyButton(
      `<img src="${StreetViewIconImage}" style="width: 18px; height: 20px;" title="Turn on/off the Google Street View panel" aria-label="Turn on/off the Google Street View panel" alt="Google Street View" />`,
      () => {
        // turn the GSV tool on/off
        if (this.streetviewActive()) this.streetviewOff();
        else this.streetviewOn();
      },
      {
        position: 'topright',
      },
    ).addTo(this.leafletMap);
    this.leafletMap.streetview.button._container.classList.add('leaflet-button-streetview'); // eslint-disable-line no-underscore-dangle
    this.leafletMap.streetview.button.button.id = `${this.mapDivId}-streetview-button`;

    this.leafletMap.streetview.marker = L.marker([0, 0], {
      title: 'Drag to move the Google Street View',
      draggable: true,
      keyboard: false,
      icon: L.icon({
        iconUrl: StreetViewIconImage,
        iconSize: [39, 39],
        iconAnchor: [20, 39],
      }),
      riseOnHover: true,
    }).on('dragend', () => {
      this.streetviewUpdateFromMarker();
    });

    const gmapiloader = loadGoogleMapsApi({
      client: googleMapsApiConfig.clientId,
    });
    gmapiloader
      .then((googleMaps) => {
        this.leafletMap.streetview.service = new googleMaps.StreetViewService();
        this.leafletMap.streetview.panorama = new googleMaps.StreetViewPanorama(this.mapStreetViewDiv.current, {
          position: new googleMaps.LatLng(0, 0),
          pov: { heading: 0, pitch: 0, zoom: 1 },
          addressControl: false,
          linksControl: true,
          zoomControl: true,
          zoomControlOptions: {
            style: googleMaps.ZoomControlStyle.SMALL,
          },
          enableCloseButton: false, // the map control is to goggle the tool
        });

        googleMaps.event.addListener(this.leafletMap.streetview.panorama, 'position_changed', () => {
          const glatlng = this.leafletMap.streetview.panorama.getPosition();
          const markerlatlng = L.latLng([glatlng.lat(), glatlng.lng()]);

          // place the marker where the StreetView pano is
          // do not fire a dragend to re-request a pano, as that creates a loop; we just got the pano so we know the pano is up to date!
          this.leafletMap.streetview.marker.setLatLng(markerlatlng);
        });
      })
      .catch((error) => {
        console.error(['GMAPI could not be loaded', error]); // eslint-disable-line
      });
  }

  retailerMarkerUpdateFromProps() {
    const { retailerData } = this.props;
    const { attributes } = retailerData;

    // set the lat-lng
    const markerlat = attributes ? parseFloat(attributes.latlong.latitude) : NaN; // eslint-disable-line react/destructuring-assignment
    const markerlng = attributes ? parseFloat(attributes.latlong.longitude) : NaN; // eslint-disable-line react/destructuring-assignment
    const markerlatlngvalid = !Number.isNaN(markerlat) && !Number.isNaN(markerlng);
    const latlng = markerlatlngvalid ? [markerlat, markerlng] : [0, 0];
    this.retailerMarker.setLatLng(latlng);

    // update the map's Zoom Home control, so this is the new "home"
    // note non-API behavior here for this control, overriding options like this
    this.leafletMap.zoombar.options.homeBounds = undefined;
    this.leafletMap.zoombar.options.homeLatLng = latlng;
    this.leafletMap.zoombar.options.homeZoom = MAP_MAXZOOM;

    // set the marker's mouseover title
    const markericon = this.retailerMarker._icon;
    const newtitle = this.props.retailerDotTitle; // eslint-disable-line react/destructuring-assignment
    if (markericon) markericon.title = newtitle;
  }

  retailerMarkerHasValidLatLng() {
    const latlng = this.retailerMarker.getLatLng();
    const isvalid = latlng.lat !== 0 || latlng.lng !== 0;
    return isvalid;
  }

  positionRetailerMarkerAtLatLng(newlatlng, warnaboutdistance) {
    // if the marker has moved more than X distance, confirm
    // this can happen because of a click or a drag, and we kept a priorLatLng for distance comparison
    // if they say no, then cancel the move -- meaning, move it back to the priorLatLng
    const oldlatlng = this.retailerMarker.priorLatLng;

    const { onChangeLocation = () => {} } = this.props;
    if (newlatlng) onChangeLocation(newlatlng);

    if (warnaboutdistance && oldlatlng) {
      const meters = oldlatlng.distanceTo(newlatlng);
      const miles = meters * 0.000621371;
      const feet = meters * 3.28084;
      const distancetext = miles < 0.25 ? `${Math.round(feet).toLocaleString()} feet` : `${miles.toFixed(2)} miles`;

      const promptfirst = feet > 100;
      if (promptfirst) {
        const msg = `Move the store to the clicked location?\nThis is ${distancetext} from its current location.\nClick OK to move it, or Cancel to cancel.`;
        const ok = window.confirm(msg); // eslint-disable-line no-alert
        if (!ok) {
          this.retailerMarker.setLatLng(oldlatlng);
          return;
        }
      }
    }

    // move the marker and recenter the map
    this.retailerMarker.setLatLng(newlatlng).addTo(this.leafletMap);
    this.leafletMap.panTo(newlatlng);

    // update the map's Zoom Home control, so this is the new "home"
    // note non-API behavior here for this control, overriding options like this
    this.leafletMap.zoombar.options.homeBounds = undefined;
    this.leafletMap.zoombar.options.homeLatLng = newlatlng;
    this.leafletMap.zoombar.options.homeZoom = MAP_MAXZOOM;
  }

  streetviewActive() {
    // is the Street View tool running?
    return this.leafletMap.hasLayer(this.leafletMap.streetview.marker);
  }

  streetviewOn(lat, lng) {
    // start the Street View tool

    // show the DIV
    this.mapStreetViewDiv.current.classList.remove('hidden');

    // add the marker and zoom to it
    // the default latlng for the marker & panorama is the retailer marker's latlng
    // but this could be overridden by supplying explcit lat, lng
    let latlng = this.retailerMarker.getLatLng();
    if (!Number.isNaN(parseFloat(lat)) && !Number.isNaN(parseFloat(lng))) latlng = L.latLng(lat, lng);
    this.leafletMap.streetview.marker.setLatLng(latlng).addTo(this.leafletMap);
    this.leafletMap.setView(latlng, MAP_MAXZOOM);

    // update StreetView to focus on that marker
    this.streetviewUpdateFromMarker();
  }

  streetviewOff() {
    // stop the Street View tool

    // hide the panel
    this.mapStreetViewDiv.current.classList.add('hidden');

    // remove the marker from the map
    this.leafletMap.removeLayer(this.leafletMap.streetview.marker);
  }

  streetviewUpdateFromMarker() {
    const markerlatlng = this.leafletMap.streetview.marker.getLatLng();
    const glatlng = new google.maps.LatLng(markerlatlng.lat, markerlatlng.lng); // eslint-disable-line no-undef
    const pano = this.leafletMap.streetview.panorama;
    const svc = this.leafletMap.streetview.service;

    // look for the closest pano to this latlng, and change the view over to it
    const panooptions = {
      location: glatlng,
      radius: 100, // how far (meters) to look for a panorama at this proposed latlng
      preference: 'nearest', // nearest or best; best is sbujective, we want accurate location
      source: google.maps.StreetViewSource.OUTDOOR, // eslint-disable-line no-undef
    };
    svc.getPanorama(panooptions, (data) => {
      if (!data) return; // no GSV here
      pano.setPosition(data.location.latLng);
      pano.setVisible(true);
    });
  }

  render() {
    return (
      <div className="singleRetailerMap">
        <MapDiv ref={this.mapDiv} id={this.mapDivId} className="singleRetailerMapMainMap" />
        <div ref={this.mapStreetViewDiv} className="singleRetailerMapStreetView hidden" />
      </div>
    );
  }
}

SingleRetailerMap.propTypes = {
  isDraggable: PropTypes.bool,
  retailerData: PropTypes.object,
  retailerDotTitle: PropTypes.string,
  onChangeLocation: PropTypes.func,
};

SingleRetailerMap.defaultProps = {
  isDraggable: false,
  retailerData: {},
  retailerDotTitle: '',
  onChangeLocation: () => {},
};

export default SingleRetailerMap;
