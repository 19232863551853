import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import { CHIPS_TYPES } from '../../components/ChipsList/constants';

export default {
  [FILTER_FIELDS.ACTIVATION_SUSPENSION]: {
    chipsType: CHIPS_TYPES.RADIOBUTTON,
    title: FILTER_OPTIONS.SALES_SUSPENSION_OPTIONS.slice(1).reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
};
