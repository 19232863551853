import styled from 'styled-components';
import { DEVICES } from '_constants';
import { deviceLess } from '_constants/DEVICES';
import { Section } from 'ui-kit/Section/styles';

export const MainContainer = styled.div`
  @media ${deviceLess.desktopHd} {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
  }
  padding: 30px 25px 40px;
`;

export const TopLine = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleWrapper = styled.div`
  > a {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 22px;
`;
export const CheckboxTitle = styled.label`
  min-width: 150px;
`;

export const Info = styled.p`
  font-size: 14px;
  color: var(--dim-grey);
`;

export const ButtonWrapper = styled.div`
  > button {
    min-width: 135px;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid var(--grey);
  font-weight: normal;

  @media ${DEVICES.tablet} {
    display: block;
    border: none;
  }

  ${Section} {
    border: none;
    flex: 1 0;

    :first-of-type {
      @media ${DEVICES.tablet} {
        margin-bottom: 25px;
        border: 1px solid var(--grey);
      }
    }

    :nth-child(2) {
      border-left: 1px solid var(--grey);
      border-right: 1px solid var(--grey);

      @media ${DEVICES.tablet} {
        margin-bottom: 25px;
        border: 1px solid var(--grey);
      }
    }

    :last-of-type {
      @media ${DEVICES.tablet} {
        border: 1px solid var(--grey);
      }
    }
  }
`;

export const WrapperSelect = styled.div`
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 25px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

export const FieldWrapper = styled.div`
  @media ${deviceLess.laptop} {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    
    dt {
      width: 100%;
      min-width: 200px;
      max-width: 50%;
      display: inline-block;
      word-wrap: break-word;
    }
    > dd {
      padding-left: 60px;
      width: 100%;
      min-width: 200px;
      display: inline-block;
      word-wrap: break-word;
    }
  }
}`;

export const Location = styled.div``;

export const LocationLabel = styled.span`
  display: block;
  font-size: 16px;
  color: var(--dim-grey);
  margin-bottom: 8px;
`;

export const LocationText = styled.p`
  font-size: 18px;
  color: var(--dark);
  min-height: 18px;
`;

export const MapWrapper = styled.div`
  padding: 0;
  margin: 0 0 20px;
  width: 100%;
  min-height: 260px;

  @media ${DEVICES.tablet} {
    height: 210px;
  }
`;

export const GisInfoWrapper = styled.div`
  padding: 0 15px;
`;

export const GisInfo = styled.p`
  padding: 0;
  margin: 0 0 10px;
  color: var(--dim-grey);
`;

export const EditWrapper = styled.div`
  padding: 15px;

  > button {
    min-width: 135px;
  }
`;

export const EditDescription = styled.p`
  margin: 0 0 20px;
  color: var(--dark);
`;

export const UpdateLocationButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;

  > button {
    min-width: 135px;

    :not(:last-of-type) {
      margin-right: 15px;
    }
  }
`;

export const Counter = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const RetailerCountContainer = styled.div`
  width: calc(100% + 40px);
  border-bottom: ${({ isBorder }) => isBorder && `1px solid var(--grey)`};
  padding-left: 20px;
  margin: 0 0 20px -20px;
`;

export const RetailerAdditionalInfo = styled.div`
  display: ${({ isShow }) => (isShow ? `none` : `block`)};
  width: 100%;
`;

export const InputSubText = styled.div`
  margin-top: ${({ isError }) => (isError ? `20px` : `0`)};
`;
