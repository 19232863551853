import { isNull } from 'lodash';

const formatAssignmentsNumber = (assignmentsAmount) => {
  return isNull(assignmentsAmount) ? '-' : assignmentsAmount;
};

const getAgencyData = (itemId, agencyList) => {
  const agencyItem = agencyList.find(({ id }) => id === itemId);
  const { name } = agencyItem.attributes;
  return {
    name,
  };
};

const getManagerData = (itemId, users) => {
  const userItem = users.find(({ id }) => id === itemId);
  const { first_name: firstName, last_name: lastName } = userItem.attributes;
  return {
    name: `${firstName} ${lastName}`,
    id: itemId,
  };
};

const getFormData = (itemId, forms) => {
  const formItem = forms.find(({ id }) => id === itemId);
  return formItem.attributes.name;
};

export const parseCollectData = ({ data, included }) => {
  if (!included?.length) return [];
  const userList = included.filter(({ type }) => type === 'User');
  const agencyList = included.filter(({ type }) => type === 'Agency');
  const formList = included.filter(({ type }) => type === 'Form');

  const campaigns = data.map((item) => {
    const { attributes, relationships } = item;
    const {
      campaign_name: campaignName,
      num_assign: numAssign,
      num_assign_submitted: numAssignSubmitted,
      sec_geog_name: secGeogName,
      archived: isArchived,
    } = attributes;

    const {
      campaign_manager: {
        data: { id: campaignManagerID },
      },
      agency: {
        data: { id: agencyId },
      },
      form: {
        data: { id: formId },
      },
    } = relationships;

    const campaignItem = {
      campaign: {
        name: campaignName,
        id: item.id,
      },
      assignments: {
        total: formatAssignmentsNumber(numAssign),
        completed: formatAssignmentsNumber(numAssignSubmitted),
      },
      agency: getAgencyData(agencyId, agencyList).name,
      agencyId,
      isArchived,
      manager: {
        ...getManagerData(campaignManagerID, userList),
      },
      sec_geog: secGeogName,
      form: getFormData(formId, formList),
      id: item.id,
    };
    return campaignItem;
  });

  return campaigns;
};
