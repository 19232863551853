import * as actions from './Visit.actions';
import reducer from './Visit.reducer';
import * as types from './Visit.types';
import * as selectors from './Visit.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
