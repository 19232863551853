import * as TYPES from './Retailers.types';

export const initialState = {
  status: 'loading',
  isLoad: false,
  isClickEditPen: false,
  suspensionChanges: false,
  showMessageAllSaved: false,
  isEditForm: false,
  retailerIsCreated: false,
  requiredField: [],
  isData: false,
  retailerLicenses: {
    data: [
      {
        type: 'Retailer',
        id: 1,
        attributes: {
          agency: {
            name: '',
            contact_info: '',
          },
          name: '',
          address: '',
          address2: '',
          license_id: 1,
          agency_name: '',
          city: '',
          has_assignments: false,
          inspection_date: '',
          disposition: '',
          state: '',
          zipcode: '',
          latlong: {
            latitude: '',
            longitude: '-',
          },
          place_id: '',
          aka: [],
          is_current: true,
          is_historic: null,
          historic_last_merge_date: null,
          merge_date: null,
          closed_date: null,
          metadata: {
            county: '',
          },
          lic_num: '1',
          tobacco: false,
          alcohol: false,
          marijuana: false,
          guns: null,
          store_type: 2,
          chain: true,
          corporation: '',
          wic: true,
          snap: false,
          near_school: false,
          near_retailer: true,
          pharmacy: true,
          ss_precision: '',
          address_validated: true,
          g_precision: '',
          is_gps: true,
          loc_updated: null,
          addr_updated: null,
          q_pcthisp: 5,
          q_pctblack: 5,
          q_pctamerind: 5,
          q_pctashawpi: 3,
          q_pctwhite: 1,
          q_pctotherrace: 4,
          q_pctyouth: 1,
          q_mhhinc: 1,
          q_pcturban: 1,
          q_pcteducollegegrad: 1,
          q_pctedusomecollege: 5,
          q_pcteduhighschool: 3,
          q_pctedunohighschool: 5,
          created: '',
          modified: '',
          other1: 'test 1',
          other2: 'test 2',
        },
        relationships: {
          agency: {
            data: {
              type: 'Agency',
              id: '1',
            },
          },
          retailer: {
            data: {
              type: 'Retailer',
              id: '1',
            },
          },
          new_retailer: {
            data: null,
          },
          historic: {
            data: [],
          },
          similar_stores: {
            data: [],
            meta: {
              count: 0,
            },
          },
          non_duplicate_stores: {
            data: [],
            meta: {
              count: 0,
            },
          },
          visits: {
            data: [
              {
                type: 'Visit',
                id: '1',
              },
            ],
          },
          aggregationpolygon_set: {
            data: [
              {
                type: '',
                id: '1',
              },
            ],
            meta: {
              count: 1,
            },
          },
        },
        links: {
          self: '',
        },
      },
    ],
    included: [
      {
        attributes: { name: '' },
        id: '1',
        type: 'Agency',
      },
    ],
    meta: {
      pagination: { page: 1, pages: 1, count: 20 },
    },
  },
  enforcementForms: {},
  checkDuplicate: false,
  locationIsUpdated: false,
  locationIsError: false,
  link: '',
  selectedAgency: '',
  startDay: '',
  endDay: '',
  retailer: {
    data: {
      id: '1',
      attributes: {
        name: '',
        address: '',
        state: '',
        created: '',
        modified: '',
        is_current: true,
        closed_date: null,
        tobacco: true,
        pharmacy: true,
        agency: '',
        end_date: '',
        license_number: '',
        latlong: {
          latitude: 0,
          longitude: 0,
        },
        locked_at: null,
        start_date: '',
        cdph_business_type: '',
        age_restricted_flag: '',
        entity_type: '',
      },
      relationships: {
        modified_by_user: { data: '' },
        role: {
          data: {
            id: 6,
          },
        },
        modified_by_agency: {
          data: {
            type: 'Agency',
            id: '1',
            attributes: { name: '' },
          },
        },
        similar_stores: { meta: { count: '' } },
        non_duplicate_stores: { meta: { count: '' } },
      },
    },
    included: [
      {
        attributes: { name: '', abbreviation: '', state: '' },
        id: '1',
        type: 'Agency',
      },
    ],
  },
  cities: [],
  counties: [],
  supervisory: [],
  names: [],
  calcpoly: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SET_ALL_CITIES_SUCCESS: {
      const response = action.result;
      let cities = [];
      if (response) {
        cities = response.map((item) => ({ value: item, label: item }));
      }
      return {
        ...state,
        cities,
      };
    }
    case TYPES.SET_ALL_COUNTIES_SUCCESS: {
      const response = action.result;
      let counties = [];
      if (response) {
        // TODO: set correct field of response
        counties = response.map((item) => ({ value: item, label: item }));
      }
      return {
        ...state,
        counties,
      };
    }
    case TYPES.SET_ALL_SUPERVISORY_SUCCESS: {
      const response = action.result;
      let supervisory = [];
      if (response) {
        // TODO: set correct field of response
        supervisory = response.map((item) => ({ value: item, label: item }));
      }
      return {
        ...state,
        supervisory,
      };
    }

    default:
      return state;
  }
};

export default reducer;
