const COLUMNS = [
  {
    label: 'Name',
    value: 'name',
    disabled: true,
  },
  {
    label: 'Address',
    value: 'address',
    disabled: true,
  },
  {
    label: 'City',
    value: 'city',
    disabled: true,
  },
  {
    label: 'Zip code',
    value: 'zipcode',
    disabled: true,
  },
];

const INITIAL_COLUMNS = ['youth_id', 'gender', 'birthdate', 'is_active'];

export { COLUMNS, INITIAL_COLUMNS };
