export const retailerAction = {
  retailerData: 'GET_RETAILER_DATA',
  retailerDataStartLoad: 'START_LOAD_RETAILER_DATA',
  enforcementModalChoose: 'ENFORCEMENT_MODAL_CHOOSE',
  enforcementModalClear: 'ENFORCEMENT_MODAL_CLEAR',
  suspension: 'GET_SUSPENSION',
  enforcement: 'GET_ENFORCEMENT',
  license: 'GET_LICENSE',
  setCities: 'SET_CITIES',
  setAllCities: 'SET_ALL_CITIES',
  setAllSupervisory: 'SET_ALL_SUPERVISORY',
  setNames: 'SET_NAMES',
  suspensionAgency: 'SET_SUSPENSION_AGENCY',
  suspensionStart: 'SET_SUSPENSION_START_DATE',
  suspensionEnd: 'SET_SUSPENSION_END_DATE',
  isEditForm: 'IS_EDIT_FORM',
  setLink: 'SET_LINK',
  enforcementForms: 'GET_ENFORCEMENT_FORMS',
  isMessageRetailerCreated: 'SET_MESSAGE_RETAILER',
  retailerSetStatusMessage: 'SET_STATUS_MESSAGE',
  setMessageUpdateLocation: 'SET_MESSAGE_UPDATE_LOCATION',
  checkDuplicate: 'CHECK_DUPLICATE',
  updateLocation: 'UPDATE_LOCATION',
  errorLocation: 'SET_MESSAGE_ERROR_LOCATION',
  setRequiredField: 'SET_REQUIRED_FIELD',
  getRetailerLicenses: 'GET_RETAILER_LICENSES',
  setSuspensionChanges: 'SET_SUSPENSION_CHANGES',
  setSuspensionDeleted: 'SET_SUSPENSION_DELETED',
  setSuspensionAdded: 'SET_SUSPENSION_ADDED',
  setAllCounties: 'SET_ALL_COUNTIES',
  setRetailerLocked: 'SET_RETAILER_LOCKED',
  setIsData: 'IS_DATA',
  isEditPen: 'IS_EDIT_PEN',
  savePreviousRetailerRequesObj: 'SAVE_PREVIOUS_RETAILER_REQUEST_OBJ',
};

const retailerData = (payload) => ({
  type: retailerAction.retailerData,
  payload,
});

const saveRetailerObj = (payload) => ({
  type: retailerAction.savePreviousRetailerRequesObj,
  payload,
});

const retailerDataStartLoad = (payload) => ({
  type: retailerAction.retailerDataStartLoad,
  payload,
});

const setRetailerLicenses = (payload) => ({
  type: retailerAction.getRetailerLicenses,
  payload,
});

const setRequiredField = (payload) => ({
  type: retailerAction.setRequiredField,
  payload,
});

const setLink = (payload) => ({
  type: retailerAction.setLink,
  payload,
});

const setStatusMessageRetailerCreated = (payload) => ({
  type: retailerAction.isMessageRetailerCreated,
  payload,
});

const setErrorLocation = (payload) => ({
  type: retailerAction.errorLocation,
  payload,
});

const checkingDuplicate = (payload) => ({
  type: retailerAction.checkDuplicate,
  payload,
});

const setStatusMessageLocation = (payload) => ({
  type: retailerAction.setMessageUpdateLocation,
  payload,
});

const setRetailerStatusMessage = (payload) => ({
  type: retailerAction.retailerSetStatusMessage,
  payload,
});

const setEditForm = (payload) => ({
  type: retailerAction.isEditForm,
  payload,
});

const matchedCities = (payload) => ({
  type: retailerAction.setCities,
  payload,
});
const matchedAllCities = (payload) => ({
  type: retailerAction.setAllCities,
  payload,
});
const matchedAllSupervisory = (payload) => ({
  type: retailerAction.setAllSupervisory,
  payload,
});
const matchedAllCounties = (payload) => ({
  type: retailerAction.setAllCounties,
  payload,
});
const matchedNames = (payload) => ({
  type: retailerAction.setNames,
  payload,
});

const enforcementModal = (payload) => ({
  type: retailerAction.enforcementModalChoose,
  payload,
});

const enforcementModalClear = (payload) => ({
  type: retailerAction.enforcementModalClear,
  payload,
});

const retailerSuspension = (payload) => ({
  type: retailerAction.suspension,
  payload,
});

const retailerEnforcement = (payload) => ({
  type: retailerAction.enforcement,
  payload,
});

const retailerLicense = (payload) => ({
  type: retailerAction.license,
  payload,
});

const suspensionSelectedAgencyModal = (payload) => ({
  type: retailerAction.suspensionAgency,
  payload,
});

const suspensionSelectedStartModal = (payload) => ({
  type: retailerAction.suspensionStart,
  payload,
});

const suspensionSelectedEndModal = (payload) => ({
  type: retailerAction.suspensionEnd,
  payload,
});

const enforcementForms = (payload) => ({
  type: retailerAction.enforcementForms,
  payload,
});

const setSuspensionChanges = (payload) => ({
  type: retailerAction.setSuspensionChanges,
  payload,
});

const setSuspensionDeleted = (payload) => ({
  type: retailerAction.setSuspensionDeleted,
  payload,
});

const setSuspensionAdded = (payload) => ({
  type: retailerAction.setSuspensionAdded,
  payload,
});

const setRetailerLocked = (payload) => ({
  type: retailerAction.setRetailerLocked,
  payload,
});

const isData = (payload) => ({
  type: retailerAction.setIsData,
  payload,
});

const setIsClickEditPen = (payload) => ({
  type: retailerAction.isEditPen,
  payload,
});

export {
  retailerData,
  retailerDataStartLoad,
  enforcementModal,
  retailerSuspension,
  retailerEnforcement,
  retailerLicense,
  matchedCities,
  matchedNames,
  suspensionSelectedAgencyModal,
  suspensionSelectedStartModal,
  suspensionSelectedEndModal,
  setEditForm,
  setLink,
  enforcementForms,
  setStatusMessageRetailerCreated,
  setRetailerStatusMessage,
  setStatusMessageLocation,
  checkingDuplicate,
  setErrorLocation,
  setRequiredField,
  setRetailerLicenses,
  matchedAllCities,
  matchedAllCounties,
  matchedAllSupervisory,
  setRetailerLocked,
  enforcementModalClear,
  isData,
  setSuspensionAdded,
  setSuspensionDeleted,
  setSuspensionChanges,
  setIsClickEditPen,
  saveRetailerObj,
};
