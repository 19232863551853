import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'ui-kit';
import { BUTTON_TYPES } from '_constants';
import * as Styled from '../styles';

function YouthAgeModal({ isOpen, onClose }) {
  return (
    <Modal open={isOpen} onClose={onClose} isHideElements preventEscape>
      <Styled.ModalWrapper>
        <Styled.ModalTitle>Warning</Styled.ModalTitle>
        <Styled.ModalDescription>This youth volunteer is over 21. Do you want to continue?</Styled.ModalDescription>
        <Styled.ModalButtonWrapper>
          <Styled.ModalButtonCancel
            onClick={onClose}
            text="Confirm Birthdate"
            variant={BUTTON_TYPES.DANGER}
            aria-label="Confirm Birthdate"
          />
        </Styled.ModalButtonWrapper>
      </Styled.ModalWrapper>
    </Modal>
  );
}

YouthAgeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default YouthAgeModal;
