import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../styles';

function ViewItem({ data, title, children, page }) {
  if (children) return <Styled.ItemWrapper page={page}>{children}</Styled.ItemWrapper>;

  return (
    <Styled.ItemWrapper page={page}>
      <Styled.Dt>{title}</Styled.Dt>
      <Styled.Dd>{data || '-'}</Styled.Dd>
    </Styled.ItemWrapper>
  );
}

ViewItem.propTypes = {
  data: PropTypes.string,
  title: PropTypes.string,
  page: PropTypes.string,
};

ViewItem.defaultProps = {
  data: '',
  title: '',
  page: '',
};

export default ViewItem;
