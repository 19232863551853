import { useSelector } from 'react-redux';
import { AuthEntity } from '_entities';

const { getUserSubdomain } = AuthEntity.selectors;

function IsVaAccount() {
  const userState = useSelector(getUserSubdomain);
  const vaAccountStates = ['va', 'post'];
  return vaAccountStates.includes(userState);
}

export default IsVaAccount;
