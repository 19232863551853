import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';

export const DropdownsBlock = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin: 0 0 0 24px;
  }
`;

export const PaginationBlock = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin: 0 0 0 24px;
    display: flex;
    button {
      padding: 1px 6px;
    }
  }
`;

const WrapperDropdown = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  margin-bottom: 27px;
  ${({ isRow }) =>
    isRow &&
    css`
      display: flex;
      align-items: center;
    `};

  @media ${DEVICES.tablet} {
    width: 100%;
    margin-bottom: 15px;
    ${({ isMerger }) =>
      isMerger &&
      css`
        margin-bottom: 10px;
      `}
  }

  @media ${DEVICES.mobileL} {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const Wrapper = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: 1px solid var(--rgb-grey);
  border-bottom: 1px solid var(--rgb-grey);
  border-left: 1px solid var(--rgb-grey);
  box-sizing: border-box;
  padding: 10px 15px;

  ${({ isStoreMerger }) =>
    isStoreMerger &&
    css`
      padding: 0;
      border: none;
    `}

  ${WrapperDropdown} {
    margin-bottom: 0;
  }

  @media ${DEVICES.laptop} {
    flex-direction: column;
    height: auto;

    ${PaginationBlock} {
      order: -1;
    }

    ${DropdownsBlock} {
      margin: 10px 0 0;
    }

    ${PaginationBlock},
    ${DropdownsBlock} {
      justify-content: space-between;
      width: 100%;

      > div {
        margin: 0;
      }
      button {
        padding 1px 6px;
      }
    }
  }

  @media ${DEVICES.tablet} {
    padding: 10px 5px;
  }
`;

export const DropdownsWrapper = styled.div`
  position: relative;
`;
