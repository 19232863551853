import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';
import * as Styled from './styles';

export default function CheckedList({
  title,
  value,
  onChange,
  options,
  error,
  onDelete,
  onChecked,
  onUnChecked,
  onDisabled,
  withSelectAll,
  preselectDisabled,
  direction,
  name,
  pageColumns,
  ...rest
}) {
  const handleOptionChange = (pickedValue) => () => {
    if (value.includes(pickedValue)) {
      const checkedValues = value;
      const idIndex = checkedValues.indexOf(pickedValue);

      checkedValues.splice(idIndex, 1);

      if (name) {
        onChange({ name, values: [...checkedValues] });
      } else onChange([...checkedValues]);

      if (!onUnChecked) return;
      onUnChecked(pickedValue);
    } else {
      if (name) {
        onChange({ name, values: [...value, pickedValue] });
      } else onChange([...value, pickedValue]);
      if (!onChecked) return;
      onChecked(pickedValue);
    }
  };

  return (
    <Styled.Wrapper pageColumns={pageColumns}>
      {title && <Styled.Title>{title}</Styled.Title>}
      {/* {withSelectAll && <SelectControl />} */}
      <Styled.OptionsWrapper direction={direction} id={rest.listID}>
        {options.map(({ label, value: optionValue, count }, index) => (
          <Styled.CheckboxWrapper key={index}>
            <Checkbox
              label={`${label}  ${count !== undefined ? `(${count})` : ''}`}
              value={value.includes(optionValue)}
              onChange={handleOptionChange(optionValue)}
              disabled={onDisabled || (count !== undefined && count === 0)}
              preselectDisabled={preselectDisabled}
            />
          </Styled.CheckboxWrapper>
        ))}
      </Styled.OptionsWrapper>
      {error && <Styled.Error aria-label="error">{error}</Styled.Error>}
    </Styled.Wrapper>
  );
}

CheckedList.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any.isRequired,
  withSelectAll: PropTypes.bool,
  preselectDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChecked: PropTypes.func,
  onDelete: PropTypes.func,
  onUnChecked: PropTypes.func,
  onDisabled: PropTypes.func,
  name: PropTypes.string,
  direction: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  pageColumns: PropTypes.string,
};

CheckedList.defaultProps = {
  title: '',
  error: null,
  options: [],
  withSelectAll: false,
  preselectDisabled: false,
  onChecked: null,
  onDelete: null,
  onUnChecked: null,
  onDisabled: null,
  name: '',
  direction: 'column',
  pageColumns: '',
};
