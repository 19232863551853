import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, BaseSelect, Loader } from 'ui-kit';
import { VisitEntity } from '_entities';
import { BUTTON_TYPES } from '_constants';
import { notification } from 'utils/services';
import * as Styled from '../styles';

const { updateVisit } = VisitEntity.actions;

function AttachToCampaignModal({
  isOpen,
  onClose,
  createVisitOnSumbit,
  assignmentsAttached,
  visitId,
  disabled,
  isSurvey,
  updateVisitInfo,
  currentCampaignId,
}) {
  const [attachedCampaign, setAttachedCampaign] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAttachDisabled, setIsAttachDisabled] = useState(false);
  const dispatch = useDispatch();

  if (!isOpen) return null;

  const handleCloseModal = () => {
    setAttachedCampaign({});
    onClose();
  };

  const handleChangeSelect = (value) => {
    setAttachedCampaign(value);
    setIsValid(true);
  };

  const handleProceedSubmition = async () => {
    if (isEmpty(attachedCampaign) || !attachedCampaign?.value) {
      setIsValid(false);
      return;
    }
    try {
      if (!isEmpty(visitId)) {
        setIsLoading(true);
        const data = {
          data: {
            type: 'Visit',
            id: visitId,
            relationships: { campaign: { data: { type: 'Campaign', id: attachedCampaign?.value } } },
          },
        };

        await dispatch(updateVisit(data, visitId));
        setIsAttachDisabled(true);
        handleCloseModal();
        if (!isSurvey) {
          notification.success('The visit was successfully attached to the campaign');
          updateVisitInfo();
        }
      } else {
        await createVisitOnSumbit(attachedCampaign?.value);
        if (!isSurvey) {
          notification.success('The visit was successfully attached to the campaign');
          updateVisitInfo();
        }
        setIsAttachDisabled(true);
        handleCloseModal();
      }
    } catch {
      notification.error('An error occurred during the request');
      handleCloseModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitWithoutAttachment = () => {
    createVisitOnSumbit();
    handleCloseModal();
  };

  const { data, included } = assignmentsAttached;

  const campaignsList = data?.reduce(
    (
      acc,
      {
        id,
        attributes: { campaign_name: campaignName },
        relationships: {
          agency: { data: campaingAgencyData },
        },
      },
    ) => {
      acc[id] = `${campaignName} (agency ${
        included.find(({ id: agencyId }) => agencyId === campaingAgencyData?.id)?.attributes?.name
      })`;
      return acc;
    },
    {},
  );

  const selectList = data
    ?.map((campaigns) => {
      const { id: campaignId } = campaigns;

      return { value: campaignId, label: campaignsList[campaignId] };
    })
    ?.filter(({ label }) => label !== campaignsList[currentCampaignId]);

  if (isLoading) return <Loader />;

  return (
    <Modal open={isOpen} onClose={onClose} preventEscape preventDesktopOverflow>
      <Styled.ModalWrapper>
        <Styled.ModalTitle>Attach to Campaign</Styled.ModalTitle>
        <Styled.ModalDescription>
          If you need this visit for the retailer attached to a campaign, choose one below.
        </Styled.ModalDescription>
        <Styled.SelectWrapper>
          <BaseSelect
            inputId="selectAttachedCampaignModalDrop"
            onChange={handleChangeSelect}
            name="Select Campaign"
            options={selectList}
            value={attachedCampaign}
            menuPlacement="top"
            error={!isValid && 'Select a campaign'}
            className={!isValid && 'error'}
          />
        </Styled.SelectWrapper>
        <Styled.ModalButtonWrapper>
          <Styled.ModalButtonCancel
            onClick={handleCloseModal}
            text="Cancel"
            variant={BUTTON_TYPES.DARK}
            aria-label="Cancel"
          />
          <Styled.ModalButtonSet
            onClick={handleProceedSubmition}
            text="Attach"
            variant={BUTTON_TYPES.DANGER}
            aria-label="Attach"
            disabled={isAttachDisabled}
          />
          {isSurvey && (
            <Styled.ModalButtonSet
              onClick={handleSubmitWithoutAttachment}
              text="Do not attach"
              variant={BUTTON_TYPES.DANGER}
              aria-label="Do not attach"
              disabled={disabled}
            />
          )}
        </Styled.ModalButtonWrapper>
      </Styled.ModalWrapper>
    </Modal>
  );
}

AttachToCampaignModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  createVisitOnSumbit: PropTypes.func,
  assignmentsAttached: PropTypes.object,
  visitId: PropTypes.string,
  disabled: PropTypes.bool,
  isSurvey: PropTypes.bool,
  updateVisitInfo: PropTypes.func,
  currentCampaignId: PropTypes.string,
};

AttachToCampaignModal.defaultProps = {
  createVisitOnSumbit: () => {},
  assignmentsAttached: {},
  visitId: '',
  disabled: false,
  isSurvey: false,
  updateVisitInfo: () => {},
  currentCampaignId: '',
};

export default AttachToCampaignModal;
