import * as TYPES from './Survey.types';

const initialState = {
  forms: [],
  singleForm: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_SURVEY_FORMS_DATA_SUCCESS: {
      return {
        forms: action.result.data,
      };
    }
    case TYPES.GET_SURVEY_SINGLE_FORM_SUCCESS: {
      return {
        singleForm: action.result,
      };
    }
    default:
      return state;
  }
};

export default reducer;
