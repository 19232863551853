export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';

export const GET_ENFORCEMENT_AGENCY = 'GET_ENFORCEMENT_AGENCY';
export const GET_ENFORCEMENT_AGENCY_SUCCESS = 'GET_ENFORCEMENT_AGENCY_SUCCESS';
export const GET_ENFORCEMENT_AGENCY_FAIL = 'GET_ENFORCEMENT_AGENCY_FAIL';

export const GET_ENFORCEMENT_USER = 'GET_ENFORCEMENT_USER';
export const GET_ENFORCEMENT_USER_SUCCESS = 'GET_ENFORCEMENT_USER_SUCCESS';
export const GET_ENFORCEMENT_USER_FAIL = 'GET_ENFORCEMENT_USER_FAIL';

export const GET_RETAILER_CORPORATION = 'GET_RETAILER_CORPORATION';
export const GET_RETAILER_CORPORATION_SUCCESS = 'GET_RETAILER_CORPORATION_SUCCESS';
export const GET_RETAILER_CORPORATION_FAIL = 'GET_RETAILER_CORPORATION_FAIL';
