/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable comma-spacing */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { ARROW_RIGHT } from 'assets/icons';
import { Label } from '../InputWrapper/styles';
import * as Styled from './styles';

const TAB_HEIGHT = 52;

export const QueryBuilderContainer = ({ activeTab, setActiveTab, tabs, children }) => {
  const closeQueryBuilderSection = () => setActiveTab(null);

  const handleActiveTab = (tab) => {
    const parentElem = document.querySelector('#filter-body');

    parentElem.scrollTo({
      top: TAB_HEIGHT * 2 + 104,
      behavior: 'smooth',
    });
    setActiveTab(tab);
  };

  const handleKeyDown = (e, title) => {
    if (e.keyCode === 13) {
      handleActiveTab(title);
    }
  };

  const handleBackKeyDown = (e) => {
    if (e.keyCode !== 13) return;
    closeQueryBuilderSection();
  };

  useEffect(() => {
    if (activeTab) {
      document.getElementById('quickBuilder').focus();
    }
    if (!activeTab) {
      document.getElementById('firstChild').focus();
    }
  }, [activeTab]);

  return (
    <Styled.Wrapper>
      {activeTab ? (
        <Styled.ContentBox>
          <Styled.Header>
            <Label hidden htmlFor="quickBuilder" id="backButtonLabel" aria-label="Link back to Quick Builder">
              Link back to Quick Builder
            </Label>
            <Styled.Link
              tabIndex={0}
              role="button"
              onClick={closeQueryBuilderSection}
              onKeyDown={handleBackKeyDown}
              aria-label="Link back to Quick Builder"
              id="quickBuilder"
              name="backToQuickBuilder"
            >
              Query Builder /
            </Styled.Link>
            {activeTab}
          </Styled.Header>
          <Styled.Body>{children}</Styled.Body>
        </Styled.ContentBox>
      ) : (
        <Styled.TabList aria-label="Menu">
          {tabs.map((tab, index) => (
            <Styled.TabItem
              key={tab.id}
              aria-label={tab.title}
              tabIndex={0}
              id={index === 0 ? 'firstChild' : tab.title}
              onClick={() => handleActiveTab(tab.title)}
              onKeyDown={(e) => handleKeyDown(e, tab.title, index)}
            >
              <Styled.MenuItem>{tab.title}</Styled.MenuItem>
              <img alt="arrow-right" src={ARROW_RIGHT} />
            </Styled.TabItem>
          ))}
        </Styled.TabList>
      )}
    </Styled.Wrapper>
  );
};
