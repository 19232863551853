import { capitalizeFirstLetter } from './capitalizeFirstLetter';
import { formatDateTimeZone } from './formatDateTimeZone';

export const parseRevisitData = ({ data }, timeZone) =>
  data.map((item) => ({
    ...item,
    attributes: {
      id: item.id,
      ...item.attributes,
      date: formatDateTimeZone(item.attributes.violation_date, timeZone, 'L'),
      name: capitalizeFirstLetter(item.attributes.name),
    },
  }));
