import * as actions from './filter.actions';
import reducer from './filter.reducer';
import * as types from './filter.types';
import * as selectors from './filter.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
