const initialState = {
  meta: {},
  mergersTableData: null,
  mergerRetailer: null,
};

const mergersViewData = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MERGER_DATA':
      return {
        ...state,
        mergersTableData: action.payload.tableData,
        meta: action.payload.meta,
        loading: false,
      };
    case 'CHANGE_LOADING':
      return {
        ...state,
        loading: action.payload.loading,
      };
    case 'MERGER_RETAILER':
      return {
        ...state,
        mergerRetailer: action.payload,
      };
    case 'MERGER_EVENT':
      return {
        ...state,
        mergerEvent: action.payload,
      };
    default:
      return state;
  }
};

export default mergersViewData;
