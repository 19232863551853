import styled from 'styled-components';
import { Image } from '../Img/styles';

export const Wrapper = styled.article`
  max-width: 352px;
  width: 100%;
  padding: 16px;
`;

export const Chart = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  ${Image} {
    margin-right: 8px;
  }
`;

export const Legend = styled.ul``;

export const LegendValues = styled.li`
  font-size: 16px;
  font-weight: 500;

  ::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    background: ${({ color }) => color};
  }
`;
