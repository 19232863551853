import styled, { css } from 'styled-components';

export const CellTitle = styled.div`
  margin-right: 6px;
  font-weight: bold;
  font-family: Roboto;
  width: fit-content !important;
  height: max-content !important;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  text-align: left;
`;

export const CellWrapper = styled.button`
  border: none;
  display: flex;
  align-items: center;
  padding: 7px 17px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  background: transparent;

  // ${({ limitedWidth }) =>
    limitedWidth &&
    css`
      //    width: 110px;
      //
      //   ${CellTitle} {
      //     width: 100% !important;
      //     flex: 1 0 auto;
      //   }
      //
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
      color: #0d0d0d;
    `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  :hover {
    cursor: pointer;
  }
`;

export const TooltipContainer = styled.div`
  display: none;
  opacity: 0;
  position: absolute;
  z-index: 1;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  transition: opacity 0.3s ease-in-out;
  top: 75%;
  left: ${({ positionX }) => positionX}};
  transform: translateX(-100%);
`;

export const ToolTipSection = styled.div`
  display: flex;
  flex-direction: column;

  ${({ isSubDesc }) =>
    isSubDesc &&
    css`
      margin-top: 10px;
    `}

  p {
    display: inline-block;
    max-width: 400px;
    width: max-content;
    text-align: left;

    &:not(:last-child) {
      padding-bottom: 10px;
    }
  }
`;

export const TooltipContent = styled.div`
  display: inline-block;
  white-space: normal;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover ${TooltipContainer} {
    display: inline-block;
    opacity: 1;
  }
`;
