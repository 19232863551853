import { store } from 'lib';
import { AuthEntity } from '_entities';
import { AUTH_TOKEN } from '_constants';
import types from '_entities/Auth';
import { isNull } from 'lodash';

const { setToken, getProfileData, startWithoutToken } = AuthEntity.actions;

export const saveToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token);
  store.dispatch(setToken(token));
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  store.dispatch({ type: types.types.LOG_OUT });
};

export const manageToken = (token = null) => {
  if (typeof token === 'string' && token.length > 1) {
    saveToken(token);
    return;
  }
  removeToken();
};

export const startApp = async () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('auth_token') || getToken() || null;
  if (isNull(token)) {
    store.dispatch(startWithoutToken());
    return;
  }
  manageToken(token);
  try {
    manageToken(token);
    await store.dispatch(getProfileData());
  } catch (error) {
    removeToken();
    store.dispatch(startWithoutToken());
  }
};

export const getToken = () => localStorage.getItem(AUTH_TOKEN) || null;

export default {
  removeToken,
  manageToken,
  saveToken,
  getToken,
  startApp,
};
