import styled from 'styled-components';
import { DEVICES } from '_constants';
import { InputWrap } from '../../old/styles';

export const Wrapper = styled.div.attrs(() => ({ ariaRequired: true }))``;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 19px;
  color: var(--dark);
  margin: 0 0 15px;
  font-weight: 400;
`;

export const PickerWrapper = styled.div`
  display: flex;

  ${InputWrap} {
    margin: 0;

    & + ${InputWrap} {
      margin: 0 0 0 20px;
    }
  }

  @media ${DEVICES.tablet} {
    flex-direction: column;

    ${InputWrap} {
      width: 100%;

      & + ${InputWrap} {
        margin: 20px 0 0;
      }
    }
  }
`;
