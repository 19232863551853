/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import styles from '_constants/old/_constants/index';
import InputSearch from '../../inputSearch';
import { FormWrapper } from '../filter.style';
import { Button } from '../../styles/button.style';

const {
  BUTTON_STYLES: { SEARCH },
} = styles;

const SearchBlock = ({ placeholder, onChangeInput, handleClick, onKeyDown, isWidth, onRemoveSearch, searchValue }) => (
  <FormWrapper>
    <InputSearch
      placeholder={placeholder}
      isColor
      onChange={onChangeInput}
      onKeyDown={onKeyDown}
      isWidth={isWidth}
      onRemoveSearch={onRemoveSearch}
      searchValue={searchValue}
    />
    <Button id="searchButton" type="button" buttonType={SEARCH} onClick={handleClick}>
      Search
    </Button>
  </FormWrapper>
);

export default memo(SearchBlock);
