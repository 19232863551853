import styled from 'styled-components';
import { DEVICES } from '_constants';
import { LinkElem } from 'modules/Header/styles';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background: var(--lite-grey);
  padding: 0 20px 0 0;
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  ${LinkElem} {
    padding: 0 15px;
    height: 100%;
    margin: 0;
  }
  > img {
    margin: 0 0 0 22px;
  }
`;

export const Body = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 20px;
  top: 54px;
  width: 100%;
  z-index: 900;
  left: ${({ isOpenMenu }) => (isOpenMenu ? 0 : '-999px')};
  bottom: 0;
  overflow-y: auto;
  background: var(--white);
  transition: left 0.6s ease-out;
`;

export const RoutesWrapper = styled.nav`
  margin: 5px 0 20px;
`;

export const RoutesList = styled.ul`
  margin: 0;
  padding: 0;
`;
export const RoutesListItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 48px;
  display: flex;
  align-items: center;

  a {
    margin-left: 25px;
  }
`;

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  display: none;
  z-index: 800;

  @media ${DEVICES.header} {
    display: block;
    margin: 0 0 0 auto;
  }
`;
