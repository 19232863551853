const infoTableRightSideTitle = ' Duplicates: Are these retailers the same store?';
const infoTableRightSideSubTitle = ` *Example: 7-Eleven 100 E Main St, Hometown, NC, 27510 is the
                                    exact same retailer as 7-11 100 East Main Street, HomeTown, NC
                                    27510-4419.`;
const infoHistorical = `Historic: Are these retailers at the same location but not
                        open for business simultaneously (changed chain/name over
                        time)?`;
const infoExample = `Example: This location used to be a 7-Eleven convenience store
                     but has changed ownership and is now a Circle K..`;
const infoSeparate = ` Separates: Are these retailers two separate stores?`;
const infoKroger = ` *Example: Kroger 500 E Main St, Suite A, Hometown, NC, 27510,
                      a full service grocery store, and Smoke Shop 500 E Main St,
                      Suite D, Hometown, NC, 27510, a tobacco specialty shop, are
                      located in the same stripmall. They geocode to the same
                      location but are two separate retailers.`;

const infoDuplicate = `Duplicates: Are these retailers the same store?`;
const infoExampleMain = `*Example: 7-Eleven 100 E Main St, Hometown, NC, 27510 is the
                        exact same retailer as 7-11 100 East Main Street, HomeTown, NC
                        27510-4419.`;
const infoRetailers = `Historic: Are these retailers at the same location but not
                    open for business simultaneously (changed chain/name over
                    time)?`;
const infoLocation = `Example: This location used to be a 7-Eleven convenience store
but has changed ownership and is now a Circle K..`;
export {
  infoTableRightSideTitle,
  infoTableRightSideSubTitle,
  infoHistorical,
  infoExample,
  infoSeparate,
  infoKroger,
  infoDuplicate,
  infoExampleMain,
  infoRetailers,
  infoLocation,
};
