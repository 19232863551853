import { isEmpty } from 'lodash';
import { formatDateTimeZone } from './formatDateTimeZone';

export const getUserAccount = (id, included) => {
  const account = included.find((item) => item.type === 'Account' && item.id === id);
  return account?.attributes?.subdomain;
};

export const parseUserProfileData = ({ data, included }, timeZone) => {
  const {
    last_modified_by: { data: modifiedUser },
    created_by: { data: createdBy },
    agencies: { data: agencies },
    sec_geogs: { data: secGeogs },
    accounts_managed: { data: accountsManaged },
  } = data.relationships;

  const accountId = data.relationships.account?.data?.id;
  const state = getUserAccount(accountId, included);
  const getFormattedItem = (date) => (isEmpty(date) ? null : formatDateTimeZone(date, timeZone, 'MMM D, YYYY'));
  const getItemList = (type, idList) =>
    included.reduce((acc, item) => {
      if (item.type === type && idList.includes(item.id)) {
        acc.push({ label: item.attributes.name, value: item.id });
      }
      return acc;
    }, []);

  const userRole = included.reduce((acc, item) => {
    if (item.type === 'Role') {
      acc = { label: item.attributes.name, value: item.id, roleType: item.attributes.role_type };
    }
    return acc;
  }, {});
  const agenciesIdList = agencies.map((item) => item.id);
  const secGeogsIdList = secGeogs.map((item) => item.id);
  const accountsManagedIdList = accountsManaged.map((item) => item.id);
  const secGeogsList = getItemList('AggregationPolygon', secGeogsIdList);
  const agenciesList = getItemList('Agency', agenciesIdList);
  const accountList = getItemList('Account', accountsManagedIdList);
  const modifiedById = modifiedUser ? modifiedUser.id : createdBy?.id;
  const userCreator = included.reduce((acc, item) => {
    if (item.type === 'User' && item.id === modifiedById) {
      const user = {
        ...item.attributes,
        id: item.id,
      };
      acc = user;
    }
    return acc;
  }, {});

  const result = {
    ...data.attributes,
    agency: agenciesList,
    accounts_managed: accountList,
    sec_geogs: secGeogsList,
    role: userRole,
    modified: getFormattedItem(data.attributes.modified),
    date_joined: getFormattedItem(data.attributes.date_joined),
    last_modified_by: userCreator,
    id: data.id,
    state,
  };

  return result;
};
