import { parseCalcpolyData } from 'utils/custom';
import * as TYPES from './Infographics.types';

const initialState = {
  calcPoly: [],
  chosenGeography: '',
  disparateBurden: null,
  enforcementOverview: null,
  retailerReduction: null,
  youthAppeal: null,
  posLandscape: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.GET_CALCPOLY_SUCCESS: {
      const newData = parseCalcpolyData(action?.result);
      return {
        ...state,
        calcPoly: newData,
      };
    }

    case TYPES.GET_DISPARATEBURDEN_SUCCESS: {
      const data = action?.result?.data.attributes;

      return {
        ...state,
        disparateBurden: data,
      };
    }

    case TYPES.GET_ENFORCEMENT_OVERVIEW_SUCCESS: {
      const data = action?.result?.data.attributes;

      return {
        ...state,
        enforcementOverview: data,
      };
    }

    case TYPES.GET_RETAILER_REDUCTION_SUCCESS: {
      const data = action?.result?.data.attributes;

      return {
        ...state,
        retailerReduction: data,
      };
    }

    case TYPES.GET_YOUTHAPPEAL_SUCCESS: {
      const data = action?.result?.data.attributes;

      return {
        ...state,
        youthAppeal: data,
      };
    }

    case TYPES.GET_POSLANDSCAPE_SUCCESS: {
      const data = action?.result?.data.attributes;

      return {
        ...state,
        posLandscape: data,
      };
    }

    case TYPES.SET_CHOSEN_GEOGRAPHY: {
      return {
        ...state,
        chosenGeography: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
