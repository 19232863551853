import { API_URLS, REQUEST_HEADERS } from '_constants';
import * as TYPES from './Youth.types';

export const getYouthList = (params) => {
  return {
    types: [TYPES.GET_YOUTH_LIST, TYPES.GET_YOUTH_LIST_SUCCESS, TYPES.GET_YOUTH_LIST_FAIL],
    promise: (client) => client.get(`${API_URLS.youth}?${params}`),
  };
};

export const addYouth = (data) => {
  return {
    types: [TYPES.ADD_YOUTH, TYPES.ADD_YOUTH_SUCCESS, TYPES.ADD_YOUTH_FAIL],
    promise: (client) => client.post(API_URLS.youth, data, { headers: { ...REQUEST_HEADERS } }),
  };
};

export const getYouth = (id, timeZone) => {
  return {
    types: [TYPES.GET_YOUTH, TYPES.GET_YOUTH_SUCCESS, TYPES.GET_YOUTH_FAIL],
    promise: (client) =>
      client.get(`${API_URLS.youth}/${id}?include=agencies,geographies,account,created_by,last_edited_by`),
    timeZone,
  };
};

export const editYouth = (id, data) => {
  return {
    types: [TYPES.EDIT_YOUTH, TYPES.EDIT_YOUTH_SUCCESS, TYPES.EDIT_YOUTH_FAIL],
    promise: (client) => client.patch(`${API_URLS.youth}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
  };
};
