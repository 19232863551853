import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Loader } from 'ui-kit';
import { BUTTON_TYPES } from '_constants';
import * as Styled from '../styles';

const MODAL_TYPE = {
  desc: {
    visit: () => 'Are you sure you want to delete this visit',
    visits: () => 'Are you sure you want to delete these visits',
    retailers: (message) => `${message}`,
  },
  onSuccess: {
    restore: (entity) => `${entity} were successfully restored`,
    delete: (entity) => `${entity} were successfully deleted`,
  },
  buttonNames: { delete: 'Delete', restore: 'Restore' },
};

function RestoreDeleteModal({ isOpen, onClose, modalMessage, modalType, postRestoreOrDelete, payload }) {
  const [loading, setLoading] = useState(false);

  const handleRestoreDelete = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await postRestoreOrDelete(payload);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  if (loading) return <Loader />;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Styled.ModalWrapper>
        <Styled.ModalTitle>{MODAL_TYPE.desc[modalType]}</Styled.ModalTitle>
        <Styled.ModalDescription>{modalMessage}</Styled.ModalDescription>
        <Styled.ModalButtonWrapper>
          <Styled.ModalButtonCancel onClick={onClose} text="No" variant={BUTTON_TYPES.DARK} aria-label="No" />

          <Styled.ModalButtonSet
            onClick={handleRestoreDelete}
            text="Yes"
            variant={BUTTON_TYPES.DANGER}
            aria-label="Yes"
          />
        </Styled.ModalButtonWrapper>
      </Styled.ModalWrapper>
    </Modal>
  );
}

RestoreDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  payload: PropTypes.object,
  postRestoreOrDelete: PropTypes.func,
  modalMessage: PropTypes.string,
  modalType: PropTypes.string,
};

RestoreDeleteModal.defaultProps = {
  modalMessage: '',
  modalType: '',
  postRestoreOrDelete: () => {},
  payload: {},
};

export default RestoreDeleteModal;
