/* eslint-disable import/prefer-default-export */
/* eslint-disable radix */
import { MESSAGES } from '_constants';
import { setIsNotification } from '_entities/old/Retailers/retailers.action';
import { notification } from 'utils/services';
import { fetchConstructor } from '../old/api/api';
import apiData from '../old/api/config';

export const youthCSV = async (dispatch, queryParams, addToast) => {
  try {
    const token = sessionStorage.getItem('token');
    dispatch(setIsNotification(false));
    const headers = {
      Authorization: `Token ${token}`,
      accept: 'application/vnd.api+json',
    };
    const detectedTz = `tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    let params = `?${detectedTz}`;
    if (queryParams) {
      params += `&${queryParams.substr(1)}`;
    }
    // eslint-disable-next-line no-unused-vars
    const response = queryParams && (await fetchConstructor('get', `${apiData.youthCSV}${params}`, headers));
    addToast(response.data);
    dispatch(setIsNotification(true));
  } catch (e) {
    console.log(e);
    notification.error(MESSAGES.VISIT.VIOLATION_PDF.ERROR);
  }
};
