import BUTTON_STYLES from './BUTTON_STYLES';
import ROUTES from './ROUTES';
import ORDERING from './ORDERING';
import TITLES from './TITLES';
import DASHBOARD from './DASHBOARD';
import TITLES_KEYS from './TITLES_KEYS';

export default {
  BUTTON_STYLES,
  ROUTES,
  ORDERING,
  TITLES_KEYS,
  TITLES,
  DASHBOARD,
};
