import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';
import LOCATION_CHIPS from 'modules/Modals/old/FilterModal/common/Location/chips';
import DEMOGRAPHICS_CHIPS from 'modules/Modals/old/FilterModal/common/Demographics/chips';
import SALES_SUSPENSION_CHIPS from 'modules/Modals/old/FilterModal/common/SalesSuspension/chips';
import RETAILER_TYPE_CHIPS from 'modules/Modals/old/FilterModal/common/RetailerType/chips';
import { CHIPS_TYPES, DROPDOWN_DATA_TYPES } from 'modules/Modals/old/FilterModal/components/ChipsList/constants';
import { DROPDOWN_OPTIONS } from './RetailersBuilderSections/VisitsHistory/component/HasHadVisits';

// TODO: CHECK QUICK QUERY RADIO BUTTONS
const filterMapping = {
  // Quick query
  [FILTER_FIELDS.COUNTY]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.COUNTRY,
    },
    title: 'County',
  },
  [FILTER_FIELDS.DISPOSITION]: {
    chipsType: CHIPS_TYPES.radiobutton,
    title: {
      2: 'Pending violation',
    },
  },
  [FILTER_FIELDS.AGENCY_ID]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.AGENCY,
    },
    title: 'Agency',
  },
  [FILTER_FIELDS.ENFORCEMENT_EVER]: {
    chipsType: CHIPS_TYPES.radiobutton,
    title: {
      f: 'No enforcement activities',
    },
  },
  [FILTER_FIELDS.ENFORCEMENT_EVER]: {
    chipsType: CHIPS_TYPES.radiobutton,
    title: {
      f: 'No enforcement activities',
    },
  },
  [FILTER_FIELDS.ENFORCEMENT_COUNT_GRT]: {
    chipsType: CHIPS_TYPES.RADIODROPDOWN,
    title: 'Enforcement violations',
  },
  [FILTER_FIELDS.VIOLATION_COUNT_GRT]: {
    chipsType: CHIPS_TYPES.RADIODROPDOWN,
    title: 'violations',
  },
  [FILTER_FIELDS.NO_LICENSES]: {
    chipsType: CHIPS_TYPES.radiobutton,
    title: {
      t: 'No licenses',
    },
  },
  [FILTER_FIELDS.SUSPESIONS_DATE_RANGE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Sales suspensions start date:',
      end: 'Sales suspensions end date:',
    },
  },
  [FILTER_FIELDS.NO_VISIT_TYPE_DATE_RANGE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Retailers with no visits start date:',
      end: 'Retailers with no visits end date:',
    },
  },
  [FILTER_FIELDS.NO_ENFORCEMENT_DATE_RANGE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'No visit type start date:',
      end: 'No visit type activities end date:',
    },
  },
  [FILTER_FIELDS.NO_VISIT_TYPE_TYPE]: {
    chipsType: CHIPS_TYPES.RADIODROPDOWN,
    title: 'Type',
  },
  [FILTER_FIELDS.MINOR_VIOLATION_COUNT_GRT]: {
    chipsType: CHIPS_TYPES.RADIODROPDOWN,
    title: 'sale to minor violations within 1 year',
  },
  [FILTER_FIELDS.CORP_FLAG]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: {
      t: 'With corporate flag',
    },
  },
  [FILTER_FIELDS.IS_CURRENT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.BUSINESS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.OBJECT_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.OBJECT_TYPE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Object Type: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.ACTION_FLAG]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.ACTION_TYPE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Action Type: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.ACTION_TIME_RANGE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Date range start date:',
      end: 'Date range end date:',
    },
  },
  // Filter by name & address
  [FILTER_FIELDS.NAME]: {
    chipsType: CHIPS_TYPES.textinput,
    title: 'Retailer name',
  },
  [FILTER_FIELDS.ADDRESS]: {
    chipsType: CHIPS_TYPES.textinput,
    title: 'Retailer address',
  },
  [FILTER_FIELDS.CITY]: {
    chipsType: CHIPS_TYPES.textinput,
    title: 'Retailer city',
  },
  [FILTER_FIELDS.ZIPCODE]: {
    chipsType: CHIPS_TYPES.textinput,
    title: 'Retailer zip code',
  },
  // Query builder Location
  ...LOCATION_CHIPS,
  // Query builder Demographics
  ...DEMOGRAPHICS_CHIPS,
  // Query builder Sales suspension
  ...SALES_SUSPENSION_CHIPS,
  // Query builder Retailer Characteristics
  [FILTER_FIELDS.TOBACCO]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_TOBACCO_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELL_CIGARETTES]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_CIGARETTES_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELL_CIGARS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_CIGARS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELL_ECIG]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_ECIG_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELL_SMOKELESS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_SMOKELESS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELLS_TICKETS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_TICKETS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.HAS_GAMING_MACHINES]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.HAS_GAMING_MACHINES_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.ALCOHOL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_ALCOHOL_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.RETAILER_BUSINESS_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.STORE_TYPE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },

  // Query builder visits history
  [FILTER_FIELDS.HAS_HAD_VISITS_DROP]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: DROPDOWN_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: `Type: ${label}` }), {}),
  },

  [FILTER_FIELDS.HAS_HAD_VISITS_CHECKBOX]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: { 'any,ever': 'Has had visit' },
  },

  [FILTER_FIELDS.HAS_HAD_VISITS_DATE_RANGE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Has had visits start date:',
      end: 'Has had visits end date:',
    },
  },

  [FILTER_FIELDS.ENFOCEMENT_VIOLATION_FOUND]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_FOUND_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Enforcement violation found: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.ENFOCEMENT_VIOLATION_CASES]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_CASES_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Enforcement violation cases: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.ENFORCEMENT_NUMBER_OF_VIOLATION]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_NUMBER_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Enforcement number of violation: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.ENFORCEMENT_VIOLATION_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_TYPE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Enforcement violation type: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.ENFORCEMENT_ACTION_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_ACTION_TYPE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Enforcement action type: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.EDUCATION_VIOLATION_FOUND]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_FOUND_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Education violation found: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.EDUCATION_VIOLATION_CASES]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_CASES_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Education violation cases: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.EDUCATION_NUMBER_OF_VIOLATION]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_NUMBER_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Education number of violation: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.EDUCATION_VIOLATION_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_TYPE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Education violation type: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.EDUCATION_ACTION_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_ACTION_TYPE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Education action type: ${label}` }),
      {},
    ),
  },
  // Query builder Policy tester
  [FILTER_FIELDS.NEAR_SCHOOL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.NEAR_SCHOOL_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Near School: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.NEAR_RETAILER]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.NEAR_RETAILER_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Near retailer: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.PHARMACY]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.HAS_PHARMACY_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Pharmacy: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.WIC]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.WIC_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SNAP]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SNAP_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.HAS_AVC_AGREEMENT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.HAS_AVC_AGREEMENT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.INTERNET_SALES_VISTIS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.INTERNET_SALES_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.RESIDENTIAL_LOCATION]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.RESIDENTIAL_LOCATION_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  // Query builder Internet sales
  [FILTER_FIELDS.INTERNET_SALES]: {
    chipsType: CHIPS_TYPES.radiobutton,
    title: FILTER_OPTIONS.YES_NO_OPTIONS.slice(1).reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Internet sales: ${label}` }),
      {},
    ),
  },
  // Query builder Retailer type
  ...RETAILER_TYPE_CHIPS,
  // Advanced filters
  [FILTER_FIELDS.CREATION_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Retailer added to database start date:',
      end: 'Retailer added to database end date:',
    },
  },
  [FILTER_FIELDS.UPDATE_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Retailer last updated start date:',
      end: 'Retailer last updated end date:',
    },
  },
  [FILTER_FIELDS.CLOSED_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Retailer closed start date:',
      end: 'Retailer closed end date:',
    },
  },
  [FILTER_FIELDS.MERGE_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Retailer in Store Merger start date:',
      end: 'Retailer in Store Merger end date:',
    },
  },
  [FILTER_FIELDS.G_PRECISION_GOOD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.RETAILER_LOCATION_ACCURACY_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.ADDRESS_VALIDATED]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.RETAILER_ADDRESS_VALIDATED_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  // Retailer characteristics
  [FILTER_FIELDS.RETAILER_CHAR_RETAILER_NAME]: {
    chipsType: CHIPS_TYPES.TEXTINPUT,
    title: 'RC - Retailer name',
  },
  [FILTER_FIELDS.RETAILER_CHAR_RETAILER_ADDRESS]: {
    chipsType: CHIPS_TYPES.TEXTINPUT,
    title: 'RC - Retailer address',
  },

  // License characteristics
  [FILTER_FIELDS.LICENSE_CHAR_RETAILER_NAME]: {
    chipsType: CHIPS_TYPES.TEXTINPUT,
    title: 'LC - Retailer name',
  },
  [FILTER_FIELDS.LICENSE_CHAR_RETAILER_ADDRESS]: {
    chipsType: CHIPS_TYPES.TEXTINPUT,
    title: 'LC -Retailer address',
  },

  // Enforcement characteristics
  [FILTER_FIELDS.VISIT_CHAR_RETAILER_NAME]: {
    chipsType: CHIPS_TYPES.TEXTINPUT,
    title: 'EC - Retailer name',
  },
  [FILTER_FIELDS.VISIT_CHAR_RETAILER_ADDRESS]: {
    chipsType: CHIPS_TYPES.TEXTINPUT,
    title: 'EC - Retailer address',
  },

  // Store merger
  [FILTER_FIELDS.STORE_MERGER_RETAILER_NAME]: {
    chipsType: CHIPS_TYPES.TEXTINPUT,
    title: 'SC - Retailer name',
  },
  [FILTER_FIELDS.STORE_MERGER_RETAILER_ADDRESS]: {
    chipsType: CHIPS_TYPES.TEXTINPUT,
    title: 'SC - Retailer address',
  },
};

export default filterMapping;
