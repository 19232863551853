export const formNames = {
  interestType: 'interest_type',
  interestArea: 'interest_area',
  comparisonType: 'comparison_type',
  comparisonArea: 'comparison_area',
  dateStart: 'date_start',
  dateEnd: 'date_end',
};

export const FORM_ACTION_TYPES = {
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  SET_ERROR: 'SET_ERROR',
};

export const FORM_REQUIRED_FIELDS_BASE = [
  formNames.interestType,
  formNames.interestArea,
  formNames.dateStart,
  formNames.dateEnd,
];

export const FORM_REQUIRED_FIELDS = [...FORM_REQUIRED_FIELDS_BASE, formNames.comparisonType, formNames.comparisonArea];

const defaultRetailerState = Object.values(formNames).reduce((newObj, name) => ({ ...newObj, [name]: null }), {});

export const formInitialState = {
  data: defaultRetailerState,
  errors: {},
  canBeSubmitted: false,
};
