import { FILTER_FIELDS, FILTER_OPTIONS, FILTER_TYPES } from '_constants/old/filters';
import { CHIPS_TYPES, DROPDOWN_DATA_TYPES } from 'modules/Modals/old/FilterModal/components/ChipsList/constants';
import LOCATION_CHIPS from 'modules/Modals/old/FilterModal/common/Location/chips';

export const BASE_CHIPS = {
  [FILTER_FIELDS.VISITS_SUBMITTED_BY_ME]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: 'All visits submitted by me ',
  },
  [FILTER_FIELDS.AGENCY_ID]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.AGENCY,
    },
    title: 'Agency',
  },
  [FILTER_FIELDS.VISIT_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Visit start date:',
      end: 'Visit end date:',
    },
  },
  [FILTER_FIELDS.VISIT_USER]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.USER,
    },
    title: 'User',
  },
  [FILTER_FIELDS.SURVEY_WHY]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SURVEY_WHY_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.ENFORCEMENT_COMPLETED]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.ENFORCEMENT_COMPLETED_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.EDUCATION_COMPLETED]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.EDUCATION_COMPLETED_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.VIOLATION]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VISIT_VIOLATION_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CITATION_CLERK]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.CITATION_CLERK_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CITATION_RETAILER]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.CITATION_RETAILER_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SALE_TO_MINOR]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VISIT_SALE_TO_MINOR_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.FDA_DECISION]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VISIT_FDA_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.ID_BASED]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.ID_BASED_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.ASK_AGE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.ASK_AGE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.ASKED_FOR_ID]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.ASKED_FOR_ID_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.PRESENTED_ID]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.PRESENTED_ID_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.EXAMINED_ID]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.EXAMINED_ID_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.AGE_VERIFY_EQUIP]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.AGE_VERIFY_EQUIP_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.AGE_VERIFY_USED]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.AGE_VERIFY_USED_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.TOBACCO_PROD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.TOBACCO_PROD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.TOBACCO_MARKETED]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.TOBACCO_MARKETED_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.RECEIPT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.RECEIPT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CREATION_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Visit added to database start date:',
      end: 'Visit added to database end date:',
    },
  },
  [FILTER_FIELDS.UPDATE_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Visit last updated start date:',
      end: 'Visit last updated end date:',
    },
  },
  [FILTER_FIELDS.IS_CURRENT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.BUSINESS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.TOBACCO]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_TOBACCO_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.ALCOHOL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_ALCOHOL_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.RETAILER_BUSINESS_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.STORE_TYPE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.NEAR_SCHOOL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.NEAR_SCHOOL_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Near School: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.NEAR_RETAILER]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.NEAR_RETAILER_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Near retailer: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.PHARMACY]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.HAS_PHARMACY_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: `Pharmacy: ${label}` }),
      {},
    ),
  },
  [FILTER_FIELDS.HAS_AVC_AGREEMENT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.HAS_AVC_AGREEMENT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.INTERNET_SALES_VISTIS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.INTERNET_SALES_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CHAIN]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.CHAIN_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.WIC]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.WIC_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SNAP]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SNAP_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CORPORATION]: {
    chipsType: CHIPS_TYPES.MULTIDROPDOWN,
    meta: {
      dataType: DROPDOWN_DATA_TYPES.CORPORATION,
    },
    title: 'Corporation',
  },
  ...LOCATION_CHIPS,

  [FILTER_FIELDS.CIGARETTES_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.CIGARETTES_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.PROMO_CIG]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.PROMO_CIG_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.MENTHOL_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.MENTHOL_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.PROMO_MENTHOL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.PROMO_MENTHOL_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.NEWPORT_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.NEWPORT_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.LCC_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.LCC_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SINGLE_LCC_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SINGLE_LCC_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.FLAV_LCC_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.FLAV_LCC_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.PROMO_LCC]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.PROMO_LCC_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CIGAR_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.CIGAR_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.FLAV_CIGAR_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.FLAV_CIGAR_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SMOKELESS_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SMOKELESS_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.FLAV_SMOKELESS_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.FLAV_SMOKELESS_SOLD_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.PROMO_SMOKELESS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.PROMO_SMOKELESS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.ECIG_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.ECIG_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.FLAV_ECIG_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.FLAV_ECIG_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.PROMO_ECIG]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.PROMO_ECIG_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELLS_ALCOHOL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_ALCOHOL_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELLS_TOBACCO]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_TOBACCO_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELL_CIGARETTES]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_CIGARETTES_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELL_CIGARS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_CIGARS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELL_ECIG]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_ECIG_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELL_SMOKELESS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_SMOKELESS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CHECKED_PERMIT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.CHECKED_PERMIT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.CHECKED_PERMIT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.CHECKED_PERMIT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.POSTED_BOH]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.POSTED_BOH_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.POSTED_TAX]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.POSTED_TAX_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.POSTED_STATE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.POSTED_STATE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.NO_SMOKING_SIGN]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.NO_SMOKING_SIGN_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELF_SERVICE_ANY]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELF_SERVICE_ANY_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SELF_SERVICE_LEGAL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELF_SERVICE_LEGAL_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.HUMIDORS_PRESENT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.HUMIDORS_PRESENT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.HUMIDORS_LOCKED]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.HUMIDORS_LOCKED_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.VENDING_PRESENT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VENDING_PRESENT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.VENDING_LEGAL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VENDING_LEGAL_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.FLAV_CIG_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.FLAV_CIG_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.LOW_MILD_SOLD]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.LOW_MILD_SOLD_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.VENDING_NON_ADULT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VENDING_NON_ADULT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.PACK_SIZE_20]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.PACK_SIZE_20_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.SMOKELESS_ADS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SMOKELESS_ADS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.DISCUSSED_LAWS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_LAWS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.DISCUSSED_RESP]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_RESP_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.DISCUSSED_ID]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_ID_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.DISCUSSED_ENFORCE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_ENFORCE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.DISCUSSED_PENALTIES]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_PENALTIES_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.DISCUSSED_TRAINING]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_TRAINING_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.DISCUSSED_SIGN]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_SIGN_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.DISCUSSED_SMOKEFREE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_SMOKEFREE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.DISCUSSED_KIT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.DISCUSSED_KIT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.GIVEN_REGS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.GIVEN_REGS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.GIVEN_STATE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.GIVEN_STATE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.GIVEN_LOCAL]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.GIVEN_LOCAL_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.GIVEN_NO_SMOKE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.GIVEN_NO_SMOKE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.GIVEN_CIGAR]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.GIVEN_CIGAR_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.GIVEN_KIT]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.GIVEN_KIT_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.GIVEN_EMPLOY]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.GIVEN_EMPLOY_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.EDUCATE_REASON]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.EDUCATE_REASON_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.EMPLOYEE_TITLE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.EMPLOYEE_TITLE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
};

export const MASS_CHIPS = (filterType) => ({
  [FILTER_FIELDS.ACTION_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title:
      FILTER_TYPES.ENFORCEMENT === filterType
        ? FILTER_OPTIONS.VISIT_ACTION_TYPE_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})
        : FILTER_OPTIONS.EDUCATION_ACTION_TYPE_OPTIONS.reduce(
            (acc, { label, value }) => ({ ...acc, [value]: label }),
            {},
          ),
  },
  [FILTER_FIELDS.VIOLATION_TYPE]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VISIT_VIOLATION_TYPE_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.VIOLATION_OPEN_STATUS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.VIOLATION_OPEN_STATUS_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
  [FILTER_FIELDS.VIOLATION_CLOSED_DATE]: {
    chipsType: CHIPS_TYPES.DATETIME,
    title: {
      start: 'Violation closed start date:',
      end: 'Violation closed end date:',
    },
  },
});

export const VA_CHIPS = () => ({
  [FILTER_FIELDS.SELLS_TICKETS]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.SELLS_TICKETS_OPTIONS.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {}),
  },
  [FILTER_FIELDS.HAS_GAMING_MACHINES]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.HAS_GAMING_MACHINES_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
});

export const RESIDENTIAL_CHIPS = () => ({
  [FILTER_FIELDS.RESIDENTIAL_LOCATION]: {
    chipsType: CHIPS_TYPES.CHECKBOX,
    title: FILTER_OPTIONS.RESIDENTIAL_LOCATION_OPTIONS.reduce(
      (acc, { label, value }) => ({ ...acc, [value]: label }),
      {},
    ),
  },
});
