export const getFileInfo = (value) => {
  if (Array.isArray(value))
    return {
      name: value[0]?.file?.name || value[0]?.file_name,
      path: value[0]?.file_path,
      disabled: Boolean(value[0]?.file?.name),
    };
  if (typeof value === 'object') return { name: value?.file_name, path: value?.file_path, disabled: false };
  return { name: null, path: null, disabled: true };
};
