import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-day-picker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FILTER_FIELDS } from '_constants/old/filters';
import RadioButtonsOrganizer from 'ui-kit/old/radioButton/radioButtonsOrganizer';
import { OldFilterEntity } from '_entities';
import { FormGroupWrapper } from 'modules/Modals/old/FilterModal/components/FormGroupWrapper';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import { VENUES_QUICK_QUERY_OPTIONS, VENUES_QUICK_QUERY_MAPPING } from './_constants';
import * as Styled from './styles';

const { setShadowFilter } = OldFilterEntity.actions;

export const VenuesQuickQuery = () => {
  const {
    filter: { shadowFilters },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const filters = {
    [FILTER_FIELDS.VENUES_NEAR_RETAILER]: null,
    [FILTER_FIELDS.ALL_SCHOOLS]: null,
    [FILTER_FIELDS.QUICK_VENUES_TYPE]: null,
  };

  const RADIO_BUTTONS = [
    {
      value: VENUES_QUICK_QUERY_MAPPING[FILTER_FIELDS.VENUES_NEAR_RETAILER],
      checked: shadowFilters[FILTER_FIELDS.VENUES_NEAR_RETAILER] === 't',
    },
    {
      value: VENUES_QUICK_QUERY_MAPPING[FILTER_FIELDS.ALL_SCHOOLS],
      checked: shadowFilters[FILTER_FIELDS.ALL_SCHOOLS] === '&type=Public School&type=Private School',
    },
    {
      value: VENUES_QUICK_QUERY_MAPPING[FILTER_FIELDS.QUICK_VENUES_TYPE],
      checked:
        shadowFilters[FILTER_FIELDS.QUICK_VENUES_TYPE] === 't&type=Public School&type=Private School&near_retailer=t',
    },
  ];

  const handleRadioButton = (value) => {
    switch (value) {
      case VENUES_QUICK_QUERY_OPTIONS.OPTION_CURRENT_VENUES_NEAR_RETAILER:
        filters[FILTER_FIELDS.VENUES_NEAR_RETAILER] = 't';
        break;
      case VENUES_QUICK_QUERY_OPTIONS.OPTION_CURRENT_ALL_SCHOOLS:
        filters[FILTER_FIELDS.ALL_SCHOOLS] = '&type=Public School&type=Private School';
        break;
      case VENUES_QUICK_QUERY_OPTIONS.OPTION_CURRENT_TYPE:
        filters[FILTER_FIELDS.QUICK_VENUES_TYPE] = 't&type=Public School&type=Private School&near_retailer=t';
        break;
      default:
        break;
    }
    dispatch(setShadowFilter({ ...shadowFilters, ...filters }));
  };

  return (
    <Styled.Wrapper>
      <FormGroupWrapper>
        <FormGroup title="Show">
          <RadioButtonsOrganizer
            hideResetButton
            radioButtons={RADIO_BUTTONS}
            onChange={({ value }) => handleRadioButton(value)}
            name="sellsTobacco"
            isColumn
          />
        </FormGroup>
      </FormGroupWrapper>
    </Styled.Wrapper>
  );
};
