import * as actions from './Retailer.actions';
import reducer from './Retailer.reducer';
import * as types from './Retailer.types';
import * as selectors from './Retailer.selectors';

export default {
  actions,
  reducer,
  types,
  selectors,
};
