import { isEmpty } from 'lodash';

const isIncludes = (data, entity, isVisit, isDevider) => {
  if (isDevider) {
    const [sectionPermission, ...itemPermissions] = entity;
    if (isEmpty(itemPermissions)) return false;
    return data?.includes(sectionPermission) && data?.some((item) => itemPermissions?.includes(item));
  }
  if (isVisit) {
    return entity?.every((item) => data?.includes(item));
  }
  if (Array.isArray(entity)) {
    // TODO: Performance issue: need to refactor
    return data?.some((item) => entity.map(String).includes(String(item)));
  }
  return data?.includes(entity);
};
export default isIncludes;
