export default {
  QUICK_QUERY: 'Quick query',
  FILTER_BY_NAME_ADDRESS: 'Filter by name & address',
  QUERY_BUILDER: 'Query builder',
  RETAILER_CHARACTERISTICS: 'Retailer characteristics',
  LICENSE_CHARACTERISTICS: 'License characteristics',
  ENFORCEMENT_CHARACTERISTICS: 'Enforcement characteristics',
  STORE_MERGER: 'Store merger',
  ADVANCED_FILTERS: 'Advanced filters',
};
