const isRequired = (name) => `The ${name} is required`;
const isNotValid = (name) => `The ${name} is not valid`;
const isNotValidDigits = (name, length) => `Enter a valid ${name} consisting of ${length} digits`;
// TODO: Discuss with Georgy.
const isInValidLogin = (name) => `A valid ${name === 'email' ? 'email address' : name} is required`;
const lessThan = (name, count, type = 'symbols') => `The ${name} must be no less than ${count} ${type}`;
const moreThan = (name, count, type = 'symbols') => `The ${name} must be no more than ${count} ${type}`;
const duplicateAnswer = 'This answer is duplicated';
const exist = (name) => `This ${name} already exist`;
const inappropriatePassword = 'New password does not match the requirements';
const unEqualPassword = "These passwords don't match. Please try again.";
const invalidZipCode = 'Invalid zipcode. Valid formats are XXXXX or XXXXX-XXXX.';
const invalidBirthDate = 'Select a valid birthdate of age between 16 and 20 years old inclusive.';
const invalidBirthDateOlderPeople = 'Select a valid birthdate of age between 21 and 65 years old inclusive.';

export default {
  isInValidLogin,
  isRequired,
  isNotValid,
  lessThan,
  moreThan,
  exist,
  duplicateAnswer,
  inappropriatePassword,
  unEqualPassword,
  invalidZipCode,
  invalidBirthDate,
  invalidBirthDateOlderPeople,
  isNotValidDigits,
};
