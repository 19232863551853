import { FILTER_FIELDS, FILTER_OPTIONS } from '_constants/old/filters';

export const VISIT_CHARACTERISTICS_TABS_BASE = [
  {
    type: 'multiple',
    title: 'Compliance inspection',
    id: 1,
    collapsed: [
      {
        id: 2,
        title: 'Compliance and violation',
        collapsed: [
          {
            field: FILTER_FIELDS.CHECKED_PERMIT,
            options: FILTER_OPTIONS.CHECKED_PERMIT_OPTIONS,
            id: 3,
          },
          {
            field: FILTER_FIELDS.VIOLATION,
            options: FILTER_OPTIONS.EDUCATION_VIOLATION_OPTIONS,
            id: 4,
          },
        ],
      },
      {
        id: 5,
        title: 'Signage',
        collapsed: [
          {
            field: FILTER_FIELDS.POSTED_BOH,
            options: FILTER_OPTIONS.POSTED_BOH_OPTIONS,
            id: 6,
          },
          {
            field: FILTER_FIELDS.POSTED_TAX,
            options: FILTER_OPTIONS.POSTED_TAX_OPTIONS,
            id: 7,
          },
          {
            field: FILTER_FIELDS.POSTED_STATE,
            options: FILTER_OPTIONS.POSTED_STATE_OPTIONS,
            id: 8,
          },
          {
            field: FILTER_FIELDS.NO_SMOKING_SIGN,
            options: FILTER_OPTIONS.NO_SMOKING_SIGN_OPTIONS,
            id: 9,
          },
        ],
      },
      {
        id: 10,
        title: 'Tobacco',
        collapsed: [
          {
            field: FILTER_FIELDS.SELF_SERVICE_ANY,
            options: FILTER_OPTIONS.SELF_SERVICE_ANY_OPTIONS,
            id: 11,
          },
          {
            field: FILTER_FIELDS.SELF_SERVICE_LEGAL,
            options: FILTER_OPTIONS.SELF_SERVICE_LEGAL_OPTIONS,
            id: 12,
          },
          {
            field: FILTER_FIELDS.HUMIDORS_PRESENT,
            options: FILTER_OPTIONS.HUMIDORS_PRESENT_OPTIONS,
            id: 13,
          },
          {
            field: FILTER_FIELDS.HUMIDORS_LOCKED,
            options: FILTER_OPTIONS.HUMIDORS_LOCKED_OPTIONS,
            id: 14,
          },
          {
            field: FILTER_FIELDS.VENDING_PRESENT,
            options: FILTER_OPTIONS.VENDING_PRESENT_OPTIONS,
            id: 15,
          },
          {
            field: FILTER_FIELDS.VENDING_LEGAL,
            options: FILTER_OPTIONS.VENDING_LEGAL_OPTIONS,
            id: 16,
          },
        ],
      },
      {
        id: 17,
        title: 'FDA violations',
        collapsed: [
          {
            field: FILTER_FIELDS.FLAV_CIG_SOLD,
            options: FILTER_OPTIONS.FLAV_CIG_SOLD_OPTIONS,
            id: 18,
          },
          {
            field: FILTER_FIELDS.LOW_MILD_SOLD,
            options: FILTER_OPTIONS.LOW_MILD_SOLD_OPTIONS,
            id: 19,
          },
          {
            field: FILTER_FIELDS.PACK_SIZE_20,
            options: FILTER_OPTIONS.PACK_SIZE_20_OPTIONS,
            id: 21,
          },
          {
            field: FILTER_FIELDS.SMOKELESS_ADS,
            options: FILTER_OPTIONS.SMOKELESS_ADS_OPTIONS,
            id: 22,
          },
        ],
      },
    ],
  },
  {
    type: 'multiple',
    title: 'Education and discussion',
    id: 23,
    collapsed: [
      {
        id: 24,
        title: 'Topics discussed',
        collapsed: [
          {
            field: FILTER_FIELDS.DISCUSSED_LAWS,
            options: FILTER_OPTIONS.DISCUSSED_LAWS_OPTIONS,
            id: 25,
          },
          {
            field: FILTER_FIELDS.DISCUSSED_RESP,
            options: FILTER_OPTIONS.DISCUSSED_RESP_OPTIONS,
            id: 26,
          },
          {
            field: FILTER_FIELDS.DISCUSSED_ID,
            options: FILTER_OPTIONS.DISCUSSED_ID_OPTIONS,
            id: 27,
          },
          {
            field: FILTER_FIELDS.DISCUSSED_ENFORCE,
            options: FILTER_OPTIONS.DISCUSSED_ENFORCE_OPTIONS,
            id: 28,
          },
          {
            field: FILTER_FIELDS.DISCUSSED_PENALTIES,
            options: FILTER_OPTIONS.DISCUSSED_PENALTIES_OPTIONS,
            id: 29,
          },
          {
            field: FILTER_FIELDS.DISCUSSED_TRAINING,
            options: FILTER_OPTIONS.DISCUSSED_TRAINING_OPTIONS,
            id: 30,
          },
          {
            field: FILTER_FIELDS.DISCUSSED_SIGN,
            options: FILTER_OPTIONS.DISCUSSED_SIGN_OPTIONS,
            id: 31,
          },
          {
            field: FILTER_FIELDS.DISCUSSED_SMOKEFREE,
            options: FILTER_OPTIONS.DISCUSSED_SMOKEFREE_OPTIONS,
            id: 32,
          },
          {
            field: FILTER_FIELDS.DISCUSSED_KIT,
            options: FILTER_OPTIONS.DISCUSSED_KIT_OPTIONS,
            id: 33,
          },
        ],
      },
      {
        id: 34,
        title: 'Materials given',
        collapsed: [
          {
            field: FILTER_FIELDS.GIVEN_REGS,
            options: FILTER_OPTIONS.GIVEN_REGS_OPTIONS,
            id: 35,
          },
          {
            field: FILTER_FIELDS.GIVEN_STATE,
            options: FILTER_OPTIONS.GIVEN_STATE_OPTIONS,
            id: 36,
          },
          {
            field: FILTER_FIELDS.GIVEN_LOCAL,
            options: FILTER_OPTIONS.GIVEN_LOCAL_OPTIONS,
            id: 37,
          },
          {
            field: FILTER_FIELDS.GIVEN_NO_SMOKE,
            options: FILTER_OPTIONS.GIVEN_NO_SMOKE_OPTIONS,
            id: 38,
          },
          {
            field: FILTER_FIELDS.GIVEN_CIGAR,
            options: FILTER_OPTIONS.GIVEN_CIGAR_OPTIONS,
            id: 39,
          },
          {
            field: FILTER_FIELDS.GIVEN_KIT,
            options: FILTER_OPTIONS.GIVEN_KIT_OPTIONS,
            id: 40,
          },
          {
            field: FILTER_FIELDS.GIVEN_EMPLOY,
            options: FILTER_OPTIONS.GIVEN_EMPLOY_OPTIONS,
            id: 41,
          },
        ],
      },
    ],
  },
  {
    type: 'multiple',
    title: 'Other visit details',
    id: 42,
    collapsed: [
      {
        id: 43,
        field: FILTER_FIELDS.EDUCATE_REASON,
        title: 'Reason for visit',
        options: FILTER_OPTIONS.EDUCATE_REASON_OPTIONS,
      },
      {
        id: 44,
        field: FILTER_FIELDS.EMPLOYEE_TITLE,
        title: 'Other visit details',
        options: FILTER_OPTIONS.EMPLOYEE_TITLE_OPTIONS,
      },
    ],
  },
];

export const VISIT_CHARACTERISTICS_TABS_MASS = [
  {
    id: 45,
    field: FILTER_FIELDS.VIOLATION_TYPE,
    title: 'Violation type',
    options: FILTER_OPTIONS.VISIT_VIOLATION_TYPE_OPTIONS,
    type: 'single',
  },
  {
    id: 46,
    field: FILTER_FIELDS.ACTION_TYPE,
    title: 'Action type',
    options: FILTER_OPTIONS.EDUCATION_ACTION_TYPE_OPTIONS,
    type: 'single',
  },
  {
    id: 47,
    field: FILTER_FIELDS.VIOLATION_OPEN_STATUS,
    title: 'Violation case status',
    options: FILTER_OPTIONS.VIOLATION_OPEN_STATUS_OPTIONS,
    type: 'single',
  },
  {
    id: 48,
    field: FILTER_FIELDS.VIOLATION_CLOSED_DATE,
    title: 'Violation case closed date',
    type: 'calendars',
  },
];
