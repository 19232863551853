import styled, { css } from 'styled-components';
import { deviceLess } from '_constants/DEVICES';
// import { Image } from '../Img/styles';
// import { Loader, Wrapper as LoaderWrapper } from '../Loader/styles';

export const Message = styled.div`
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  user-select: none;
  ${({ pageColumns }) =>
    pageColumns &&
    css`
      top: calc(80% + 2px);
    `}
  @media ${deviceLess.laptop} {
  ${({ pageColumns }) =>
    pageColumns &&
    css`
      left: 50%;
      top: calc(70%);
    `}
`;

export const Error = styled(Message)`
  color: var(--danger);
`;

export const Title = styled.label`
  display: inline-block;
  margin: 0 0 5px;
  background: var(--white);
  font-size: 14px;
  line-height: 22px;
  color: var(--dark);
  user-select: none;

  ${({ required, disabled }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: var(--danger);
        position: relative;
        right: -5px;
        cursor: ${disabled ? 'not-allowed' : 'default'};
      }
    `}

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  color: var(--dark);
  padding: 0 10px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  height: 34px;
  font-size: 14px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--grey-border);
      background: var(--lite-grey);
      cursor: not-allowed;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: var(--danger);
    `}
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white);

  ${({ disabled }) =>
    disabled &&
    css`
      user-select: none;
      background-color: var(--pale-grey);
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `}
`;

export const RequiredDot = styled.span`
  font-weight: 900;
  color: var(--dark);
`;

export const HorizontalBorder = styled.div`
  @media ${deviceLess.laptop} {
    border-bottom: 1px solid #e0e0e0;
    position: absolute;
    height: 100%;
    left: -20px;
    width: calc(100% + 40px);
    z-index: 1;
    filter: opacity(0.4);
  }
`;

const columnsStyles = {
  three_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;
      flex: 2;

      ${Title} {
        flex: 1;
      }

      ${InputWrapper} {
        flex: 1;
      }
    }
  `,
  two_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${Title} {
        min-width: 400px;
      }

      ${InputWrapper} {
        width: 100%;
        max-width: 1200px;
      }
    }
  `,
  youth_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${Title} {
        min-width: 400px;
      }

      ${InputWrapper} {
        width: 100%;
        max-width: 1200px;
      }
      ${Message} {
        left: 400px;
      }
    }
  `,
  one_column_with_submission: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${Title} {
        width: 100%;
        min-width: 200px;
        max-width: 50%;
        display: inline-block;
        word-wrap: break-word;
      }

      ${InputWrapper} {
        padding-left: 60px;
        width: 100%;
        max-width: 1200px;
      }
      ${Message} {
        padding-left: 60px;
        width: 100%;
        max-width: 1200px;
      }
    }
  `,
  one_column: css`
    @media ${deviceLess.laptop} {
      display: flex;

      ${Title} {
        min-width: 200px;
        display: inline-block;
        word-wrap: break-word;
      }

      ${InputWrapper} {
        padding-left: 20px;
        width: 100%;
        max-width: 600px;
        min-width: 200px;
      }

      ${Message} {
        left: 200px;
        padding-left: 20px;
        width: 100%;
        max-width: 600px;
        min-width: 200px;
      }
    }
  `,
  '': ``,
};

export const MainInputWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${({ pageColumns }) => (pageColumns ? '20px' : '0')};

  ${({ pageColumns }) => pageColumns && columnsStyles[pageColumns]}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      user-select: none;

      ${Title} {
        color: var(--black-two);
      }
    `}
`;
