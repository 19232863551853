/* eslint-disable react/prop-types */
import React from 'react';
import { uniqueId } from 'lodash';
import { RemoveOption, WrapperADAText } from 'ui-kit/BaseSelect/styles';
import { Img } from 'ui-kit';
import { REMOVE_ICON } from 'assets/icons';

const CustomRemoveOption = ({ innerProps }) => {
  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      innerProps.onClick();
    }
  };

  return (
    <RemoveOption {...innerProps} id={uniqueId()} tabIndex={0} onKeyDown={handleKeyDown}>
      <Img src={REMOVE_ICON} size={[12, 12]} />
      <WrapperADAText>Clear button</WrapperADAText>
    </RemoveOption>
  );
};

export default CustomRemoveOption;
