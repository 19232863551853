/* Libs */
import styled, { css } from 'styled-components';

export const Image = styled.img`
  ${({ width, height, relative, disabled }) => css`
    height: ${height}${relative ? '%' : 'px'};
    width: ${width}${relative ? '%' : 'px'};
    ${disabled &&
    css`
      filter: invert(0.8);
      pointer-events: none;
      cursor: default;
    `};
  `}
`;
