import moment from 'moment';

const formatDate = (date) => {
  const formattedDate = moment(date);
  return `${formattedDate.format('YYYY')}-${formattedDate.format('MM')}-${formattedDate.format('DD')}`;
};

export const isValidDate = (data) => {
  const regExp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
  const isDate = regExp.test(data);
  if (isDate) {
    return formatDate(data);
  }
  return data;
};
