/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { dropDownClickOutside } from 'modules/Header/_utils';
import PropTypes from 'prop-types';
import RouteListItems from '../menuListItems';
import * as Styled from '../styles';

function CollapsedItem({ route, index, tabRef, headerFocus, getANewFocus }) {
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  dropDownClickOutside(route.title, setIsOpenDropDown);

  const toggleDropDown = useCallback(() => setIsOpenDropDown((prev) => !prev), []);

  const handleKeyDown = useCallback(
    (e) => {
      if ([40].includes(e.keyCode) && !isOpenDropDown) {
        e.preventDefault();
        toggleDropDown();
      } else if ([13, 32].includes(e.keyCode)) {
        toggleDropDown();
      }
    },
    [index, isOpenDropDown, toggleDropDown],
  );

  useEffect(() => {
    if (isOpenDropDown) return;
    getANewFocus(index);
  }, [isOpenDropDown]);
  return (
    <Styled.RouteListItem role="menuitem">
      <Styled.DropDownLink
        id={route.title}
        ref={tabRef}
        key={route.title}
        isActive={isOpenDropDown}
        aria-expanded={isOpenDropDown}
        activeclassname="active"
        aria-haspopup="true"
        onClick={toggleDropDown}
        onKeyDown={handleKeyDown}
        tabIndex={headerFocus === index ? 0 : -1}
      >
        <Styled.DropButton>
          <Styled.DropButtonText>{route.title}</Styled.DropButtonText>
          <Styled.ArrowMenu isDropDownOpen={isOpenDropDown} />
        </Styled.DropButton>
        <RouteListItems
          subRoutes={route.subRoutes}
          setIsOpenDropDown={setIsOpenDropDown}
          isOpenDropDown={isOpenDropDown}
        />
      </Styled.DropDownLink>
    </Styled.RouteListItem>
  );
}

CollapsedItem.propTypes = {
  route: PropTypes.shape({
    title: PropTypes.string,
    subRoutes: PropTypes.array,
  }).isRequired,
  index: PropTypes.number.isRequired,
  tabRef: PropTypes.object.isRequired,
  headerFocus: PropTypes.number.isRequired,
  getANewFocus: PropTypes.func.isRequired,
};

export default CollapsedItem;
