import styled from 'styled-components';

export const MenuContainer = styled.nav.attrs(() => ({
  ariaRequired: true,
}))`
  width: 100%;
  height: 100%;
  margin-left: 68px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px 0 0;
`;
