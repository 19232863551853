/* eslint-disable react/prop-types */
import React from 'react';
import { compact, uniqueId } from 'lodash';
import LinkCell from 'modules/old/cells/LinkCell';
import TimeCell from 'modules/old/cells/TimeCell';
import HeaderCell from 'modules/old/cells/HeaderCell';
import TextCell from 'modules/old/cells/TextCell';
import { ActionCell } from 'ui-kit';
import { URLS } from '_constants';

const getInitialColumns = ({
  handleSort,
  sortOption,
  dumpedActiveColumns,
  editCellParams,
  getToast,
  tableViewMode,
  checkboxList,
  handleCheckbox,
  hasAccessToMultiDeleteRestore,
}) =>
  compact([
    tableViewMode &&
      hasAccessToMultiDeleteRestore && {
        Header: () => {},
        width: 60,
        accessor: 'checkbox',
        Cell: ({ original }) => {
          const { id } = original;
          return (
            <ActionCell
              original={original}
              name={id}
              value={checkboxList?.[id] || false}
              handleCheckbox={handleCheckbox}
              isCheckBox
              {...editCellParams}
            />
          );
        },
      },
    dumpedActiveColumns.visitDate && {
      Header: () => (
        <HeaderCell col="Date" sortBy="visit_time" sortOption={sortOption} handleSort={handleSort('visit_time')} />
      ),
      minWidth: 300,
      accessor: 'visitDate',
      Cell: ({ value, original }) => (
        <TimeCell id={original.id} data={value} to={`${URLS.assessments}/${original.id}`} />
      ),
    },
    dumpedActiveColumns.agency && {
      Header: () => (
        <HeaderCell
          col="Agency"
          sortBy="agency__name"
          sortOption={sortOption}
          handleSort={handleSort('agency__name')}
        />
      ),
      minWidth: 260,
      accessor: 'agency',
      Cell: ({ value }) => <TextCell text={value.name} />,
    },
    dumpedActiveColumns.retailer__name && {
      Header: () => (
        <HeaderCell
          col="Retailer name"
          sortBy="retailer__name"
          sortOption={sortOption}
          handleSort={handleSort('retailer__name')}
        />
      ),
      minWidth: 260,
      accessor: 'retailer__name',
      Cell: ({ value, original }) => (
        <LinkCell id={uniqueId()} text={value} to={`${URLS.retailers}/${original.retailerID}`} />
      ),
    },
    dumpedActiveColumns.address && {
      Header: () => (
        <HeaderCell
          col="Address"
          sortBy="retailer__address"
          sortOption={sortOption}
          handleSort={handleSort('retailer__address')}
        />
      ),
      minWidth: 150,
      accessor: 'address',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.city && {
      Header: () => (
        <HeaderCell
          col="City"
          sortBy="retailer__city"
          sortOption={sortOption}
          handleSort={handleSort('retailer__city')}
        />
      ),
      minWidth: 150,
      accessor: 'city',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    dumpedActiveColumns.zipcode && {
      Header: () => (
        <HeaderCell
          col="Zip code"
          sortBy="retailer__zipcode"
          sortOption={sortOption}
          handleSort={handleSort('retailer__zipcode')}
        />
      ),
      minWidth: 150,
      accessor: 'zipcode',
      Cell: ({ value }) => <TextCell text={value} />,
    },
    {
      Header: () => {},
      width: 112,
      accessor: 'editPen',
      Cell: ({ original }) => {
        const newEditCellParams = {
          ...editCellParams,
          route: URLS.assessmentsPages.edit,
        };
        return <ActionCell onlyEdit original={original} getToast={getToast} {...newEditCellParams} />;
      },
    },
  ]);

export default getInitialColumns;
