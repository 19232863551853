import styled from 'styled-components';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  padding: 40px 25px 50px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    min-width: 135px;
    margin-left: 25px;
  }
`;

export const HeaderBox = styled.div`
  margin: 0 0 0 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 24px;

  @media ${DEVICES.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const H1 = styled.h1`
  font-size: 22px;
  line-height: 26px;
  font-family: Roboto;
  font-style: normal;
  display: block;
  font-weight: 500;
  @media print {
    margin: 0;
  }
`;

export const ChipsWrapper = styled.div`
  padding: 0 0 24px;
`;

export const FilterWrapper = styled.div`
  margin: 0 0 15px;
`;

export const TableContainer = styled.div``;
