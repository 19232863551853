/* Libs */
import React from 'react';
import PropTypes from 'prop-types';

/* Styles */

import * as Styled from './styles';

function Img({ src, size, ...restProps }) {
  const [width, height] = size;

  return <Styled.Image src={src} width={width} height={height} {...restProps} />;
}

/* Img type of props */

Img.propTypes = {
  src: PropTypes.string,
  size: PropTypes.arrayOf(PropTypes.number),
};

/* Img default props */

Img.defaultProps = {
  src: null,
  size: [75, 75],
};

export default Img;
