import React, { useReducer, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageContainer, IsMassAccount } from 'modules';
import moment from 'moment';
import BackPageButton from 'modules/BackPageButton';
import { useDispatch, useSelector } from 'react-redux';
import { YouthEntity, AuthEntity } from '_entities';
import { Button, Input, BaseSelect, RadioButtonGroup, Section, Loader, Picker, Checkbox } from 'ui-kit';
import { notification } from 'utils/services';
import PropTypes from 'prop-types';
import { BUTTON_TYPES, URLS, MESSAGES } from '_constants';
import {
  formNames,
  formInitialState,
  FORM_ACTION_TYPES,
  radioButtonDefaultValues,
  genderOptions,
  PAGE_COLUMNS,
} from './constants';
import { formReducer, init } from './_utils';
import * as Styled from './styles';

const { addYouth, editYouth } = YouthEntity.actions;
const { getYouthData } = YouthEntity.selectors;
const { getAgenciesList, getSecGeogList } = AuthEntity.selectors;

const generateSelectOptions = (data, name) => {
  return data.map((item) => ({
    label: item.attributes.name,
    value: item.id,
    name,
  }));
};

const AddYouth = ({ isEditPage }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const youthProfileFull = useSelector(getYouthData);
  const youthProfile = youthProfileFull.attributes;
  const agenciesData = useSelector(getAgenciesList);
  const secGeogsData = useSelector(getSecGeogList);
  const [state, formDispatch] = useReducer(formReducer, formInitialState, init);
  const formStateData = state.data.attributes;
  const formStateErrors = state.errors;
  const pageTitle = isEditPage ? 'Edit youth' : 'Add youth';
  const submitButtonTitle = isEditPage ? 'Save changes' : 'Confirm';
  const agencies = agenciesData && generateSelectOptions(agenciesData, 'agency');
  const secGeogs = secGeogsData && generateSelectOptions(secGeogsData, 'sec_geogs');
  const isMassAccount = IsMassAccount();

  const handleChange = useCallback(({ target: { name, value } }) => {
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: value });
  }, []);

  const handleSelectChange = useCallback(
    (selectData, name) => {
      // multiSelect return array of options or empty array
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name, payload: selectData });
    },
    [formDispatch],
  );

  const handlePickerChange = useCallback((date) => {
    formDispatch({
      type: FORM_ACTION_TYPES.ENTER_DATA,
      name: formNames.birthday,
      payload: date,
    });
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      formDispatch({ type: FORM_ACTION_TYPES.VALIDATE_DATA });

      if (!state.canBeSubmitted) return;

      setLoading(true);
      (async () => {
        const formatSelectedData = (arr, type) => {
          return arr.map(({ value, label }) => ({
            type,
            id: value,
            attributes: {
              name: label,
            },
          }));
        };

        const youthData = Object.keys(formStateData).reduce(
          (acc, item) => {
            const currentValue = formStateData[item];
            switch (item) {
              case formNames.gender: {
                const { value } = currentValue;
                acc.data.attributes[formNames.gender] = value;
                return acc;
              }

              case formNames.geographies: {
                acc.data.relationships[formNames.geographies] = {
                  data: formatSelectedData(currentValue, 'AggregationPolygon'),
                };
                return acc;
              }

              case formNames.agencies: {
                acc.data.relationships[formNames.agencies] = {
                  data: formatSelectedData(currentValue, 'Agency'),
                };
                return acc;
              }

              case formNames.isActive: {
                const newValue = !!currentValue;
                acc.data.attributes[item] = newValue;
                return acc;
              }

              case formNames.birthday: {
                const newDate = moment(currentValue).format('YYYY-MM-DD');
                acc.data.attributes[item] = newDate;
                return acc;
              }

              default:
                acc.data.attributes[item] = currentValue;
                return acc;
            }
          },
          {
            data: {
              attributes: {},
              relationships: {
                account: { data: { type: 'Account' } },
              },
              type: 'Youth',
            },
          },
        );

        try {
          if (isEditPage) {
            youthData.data.id = youthProfile.id;
            await dispatch(editYouth(youthProfile.id, youthData));
            notification.success(MESSAGES.YOUTH.EDIT.SUCCESS);
            history.push(`${URLS.youth}/${youthProfile.id}`);
          } else {
            const {
              data: { id },
            } = await dispatch(addYouth(youthData));
            notification.success(MESSAGES.YOUTH.CREATE.SUCCESS);
            history.push(`${URLS.youth}/${id}`);
          }
        } catch (err) {
          notification.error(err.response?.data?.errors[0].message);
        } finally {
          setLoading(false);
        }
      })();
    },
    [dispatch, formStateData, history, isEditPage],
  );

  useEffect(() => {
    if (isEditPage) formDispatch({ type: FORM_ACTION_TYPES.SET_DATA, payload: youthProfile });
  }, [youthProfile]);

  if (loading) return <Loader />;

  return (
    <PageContainer>
      <Styled.MainContainer>
        <Styled.TopLine>
          <Styled.TitleWrapper>
            <BackPageButton />
            <Styled.Title>{pageTitle}</Styled.Title>
            <Styled.Info>* - required fields</Styled.Info>
          </Styled.TitleWrapper>
          <Styled.ButtonWrapper>
            <Button onClick={handleSubmit} variant={BUTTON_TYPES.DANGER} text={submitButtonTitle} />
          </Styled.ButtonWrapper>
        </Styled.TopLine>
        <Styled.Inner>
          <Styled.VerticalBorder />
          <Section title="Youth information">
            <Styled.InputWrapper>
              <Input
                name={formNames.youthId}
                title="Youth ID"
                onChange={handleChange}
                error={formStateErrors[formNames.youthId]}
                value={formStateData[formNames.youthId]}
                required
                floatingLabel
                pageColumns={PAGE_COLUMNS}
              />
              <Styled.HorizontalBorder />
            </Styled.InputWrapper>
            {isMassAccount && (
              <Styled.CheckboxWrapper>
                <Styled.CheckboxTitle>Add youth older than 21 years old</Styled.CheckboxTitle>
                <Checkbox
                  value={formStateData[formNames.isOlderThanYouthAge]}
                  name={formNames.isOlderThanYouthAge}
                  onChange={handleChange}
                  pageColumns={PAGE_COLUMNS}
                />
                <Styled.HorizontalBorder />
              </Styled.CheckboxWrapper>
            )}
            <Styled.InputWrapper>
              <Picker
                label="Birthdate (mm/dd/yyyy)*"
                required
                showMonthYearDropdown
                idInput={formNames.birthday}
                onChange={handlePickerChange}
                selected={formStateData[formNames.birthday]}
                error={formStateErrors[formNames.birthday]}
                inputFor={formNames.birthday}
                isFullWidth
                pageColumns={PAGE_COLUMNS}
              />
              <Styled.HorizontalBorder />
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <BaseSelect
                name={formNames.gender}
                title="Gender"
                onChange={(obj) => handleSelectChange(obj, formNames.gender)}
                error={formStateErrors[formNames.gender]}
                value={formStateData[formNames.gender]}
                options={genderOptions}
                labelText="Gender"
                inputId={formNames.gender}
                required
                floatingLabel
                pageColumns={PAGE_COLUMNS}
              />
              <Styled.HorizontalBorder />
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <RadioButtonGroup
                name={formNames.isActive}
                title="Status *"
                options={radioButtonDefaultValues}
                direction="column"
                value={formStateData[formNames.isActive]}
                error={formStateErrors[formNames.isActive]}
                onChange={handleChange}
                required
                pageColumns={PAGE_COLUMNS}
              />
              <Styled.HorizontalBorder />
            </Styled.InputWrapper>
            <Styled.InputWrapper canGrow>
              <BaseSelect
                name={formNames.geographies}
                title="Geographies"
                onChange={(arr) => handleSelectChange(arr, formNames.geographies)}
                error={formStateErrors[formNames.geographies]}
                value={formStateData[formNames.geographies]}
                options={secGeogs}
                labelText="Geographies"
                inputId={formNames.geographies}
                isMulti
                floatingLabel
                required
                allowSelectAll
                pageColumns={PAGE_COLUMNS}
              />
              <Styled.HorizontalBorder />
            </Styled.InputWrapper>
            <Styled.InputWrapper canGrow>
              <BaseSelect
                name={formNames.agencies}
                title="Agencies"
                onChange={(arr) => handleSelectChange(arr, formNames.agencies)}
                error={formStateErrors[formNames.agencies]}
                value={formStateData[formNames.agencies]}
                options={agencies}
                labelText="Agencies"
                inputId={formNames.agencies}
                isMulti
                floatingLabel
                required
                allowSelectAll
                pageColumns={PAGE_COLUMNS}
              />
            </Styled.InputWrapper>
          </Section>
        </Styled.Inner>
      </Styled.MainContainer>
    </PageContainer>
  );
};

AddYouth.propTypes = {
  isEditPage: PropTypes.bool,
};

AddYouth.defaultProps = {
  isEditPage: false,
};

export default AddYouth;
