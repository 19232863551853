import { createInitialValues } from 'utils/custom';

export const formNames = {
  email: 'email',
  password: 'password',
};

export const FORM_ACTION_TYPES = {
  RESET_TYPE: 'RESET_TYPE',
  ENTER_DATA: 'ENTER_DATA',
  VALIDATE_DATA: 'VALIDATE_DATA',
  TRIM_DATA: 'TRIM_DATA',
  INVALID_LOGIN: 'INVALID_LOGIN',
};

export const FORM_REQUIRED_FIELDS = ['email', 'password'];

export const formInitialState = {
  data: createInitialValues(formNames, ''),
  errors: createInitialValues(formNames, null),
  canBeSubmitted: false,
};
