import React from 'react';
import FILTERS from '_constants/old/_constants/filters';
import { InputWrapper } from 'modules/Modals/old/FilterModal/components/InputWrapper';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import * as Styled from './styles';

export const RetailersFilterByAddressName = () => {
  const FIELDS = [
    {
      id: 1,
      label: 'Retailer name',
      name: FILTERS.FILTER_FIELDS.NAME,
    },
    {
      id: 2,
      label: 'Retailer address',
      name: FILTERS.FILTER_FIELDS.ADDRESS,
    },
    {
      id: 3,
      label: 'Retailer city',
      name: FILTERS.FILTER_FIELDS.CITY,
    },
    {
      id: 4,
      label: 'Retailer zip code',
      name: FILTERS.FILTER_FIELDS.ZIPCODE,
    },
  ];

  return (
    <Styled.Wrapper>
      <FormGroup>
        <Styled.List>
          {FIELDS.map((field) => (
            <Styled.ListItem key={field.id}>
              <InputWrapper name={field.name} id={field.name} tabIndex={0} type="text" title={field.label} />
            </Styled.ListItem>
          ))}
        </Styled.List>
      </FormGroup>
    </Styled.Wrapper>
  );
};
