import React, { Suspense } from 'react';
import PropTypes, { element, object } from 'prop-types';

const LazyLoad = ({ component: Component, fallback, ...rest }) => (
  <>
    <Suspense fallback={fallback}>
      <Component {...rest} />
    </Suspense>
  </>
);

LazyLoad.propTypes = {
  component: PropTypes.oneOfType([element, object]).isRequired,
  fallback: PropTypes.element,
};

LazyLoad.defaultProps = {
  fallback: <div />,
};

export default LazyLoad;
