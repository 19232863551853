/* eslint-disable react/prop-types */
import React from 'react';
import { Img } from 'ui-kit';
import { DROP_ARROW } from 'assets/icons';
import { DropdownIndicator } from '../../styles';

const CustomDropdownIndicator = (props) => (
  <DropdownIndicator {...props}>
    <Img src={DROP_ARROW} size={[12, 12]} />
  </DropdownIndicator>
);

export default CustomDropdownIndicator;
