import { FILTER_TABS } from '_constants/old/filters';

export const COLUMNS = [
  {
    label: 'Type',
    value: 'type',
    disabled: true,
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'City',
    value: 'city',
  },
  {
    label: 'Zip code',
    value: 'zipcode',
  },
];

export const INITIAL_COLUMNS = ['type', 'name', 'address', 'city', 'zipcode'];

export const FILTER_DATA = [
  {
    name: FILTER_TABS.QUICK_QUERY,
    isActive: true,
  },
  {
    name: FILTER_TABS.QUERY_BUILDER,
    isActive: true,
  },
  {
    name: FILTER_TABS.ADVANCED_FILTERS,
    isActive: true,
  },
];

export const TITLES = [
  { value: 'List View', id: 'list' },
  { value: 'Map View', id: 'map' },
];

export const MODAL_NAMES = {
  FILTER_MODAL: 'FILTER_MODAL',
};

export const EXCEPTION_FIELDS = ['page', 'page_size', 'ordering'];
