import React from 'react';
import { URLS } from '_constants';
import { VisitEdit } from 'modules';

function AssessmentEdit() {
  return (
    <VisitEdit headerTitle="Edit assessment visit" sectionTitle="Assessment information" submitUrl={URLS.assessments} />
  );
}

export default AssessmentEdit;
