import { REQUEST_HEADERS, API_URLS } from '_constants';
import * as TYPES from './Campaign.types';

export const getRetailerCount = (params) => {
  return {
    types: [TYPES.GET_RETAILER_COUNT, TYPES.GET_RETAILER_COUNT_SUCCESS, TYPES.GET_RETAILER_COUNT_FAIL],
    promise: (client) => client.get(`${API_URLS.retailers}?is_current=t&sec_geog=t&${params}`),
  };
};

export const addCampaign = (data) => ({
  types: [TYPES.ADD_CAMPAIGN, TYPES.ADD_CAMPAIGN_SUCCESS, TYPES.ADD_CAMPAIGN_FAIL],
  promise: (client) => client.post(API_URLS.campaigns, data, { headers: { ...REQUEST_HEADERS } }),
});

export const getCampaignData = (id) => ({
  types: [TYPES.VIEW_CAMPAIGN, TYPES.VIEW_CAMPAIGN_SUCCESS, TYPES.VIEW_CAMPAIGN_FAIL],
  promise: (client) =>
    client.get(`${API_URLS.campaigns}/${id}?include=account,agency,campaign_manager,form,last_modified_by`),
});

export const getManageAssignments = (id, params) => ({
  types: [
    TYPES.GET_MANAGE_ASSIGNMENTS_LIST,
    TYPES.GET_MANAGE_ASSIGNMENTS_LIST_SUCCESS,
    TYPES.GET_MANAGE_ASSIGNMENTS_LIST_FAIL,
  ],
  promise: (client) => client.get(`${API_URLS.campaigns}/${id}/retailers?${params}`),
});

export const editCampaignData = (id, data) => ({
  types: [TYPES.EDIT_ASSIGNMENTS_LIST, TYPES.EDIT_ASSIGNMENTS_LIST_SUCCESS, TYPES.EDIT_ASSIGNMENTS_LIST_FAIL],
  promise: (client) => client.patch(`${API_URLS.campaigns}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
});

export const archiveCampaign = (id, data) => ({
  types: [TYPES.ARCHIVE_CAMPAIGN, TYPES.ARCHIVE_CAMPAIGN_SUCCESS, TYPES.ARCHIVE_CAMPAIGN_FAIL],
  promise: (client) => client.patch(`${API_URLS.campaigns}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
});

export const getAssignmentsList = (params) => {
  return {
    types: [TYPES.GET_ASSIGNMENTS_LIST, TYPES.GET_ASSIGNMENTS_LIST_SUCCESS, TYPES.GET_ASSIGNMENTS_LIST_FAIL],
    promise: (client) => client.get(`${API_URLS.assignments}?${params}&include=retailer,form,users,campaign`),
  };
};

export const updateAssignments = (id, data) => {
  return {
    types: [TYPES.GET_ASSIGNMENTS_LIST, TYPES.GET_ASSIGNMENTS_LIST_SUCCESS, TYPES.GET_ASSIGNMENTS_LIST_FAIL],
    promise: (client) => client.patch(`${API_URLS.assignments}/${id}`, data, { headers: { ...REQUEST_HEADERS } }),
  };
};

export const bulkAssignments = (data, search) => {
  return {
    types: [TYPES.ADD_DATA_COLLECTOR, TYPES.ADD_DATA_COLLECTOR_SUCCESS, TYPES.ADD_DATA_COLLECTOR_FAIL],
    promise: (client) =>
      client.post(`${API_URLS.assignments}/bulk_assign?${search ? `search=${search}` : ''}`, data, {
        headers: { ...REQUEST_HEADERS },
      }),
  };
};

export const getDeleteCampaign = (id) => {
  return {
    types: [TYPES.DELETE_CAMPAIGN, TYPES.DELETE_CAMPAIGN_SUCCESS, TYPES.DELETE_CAMPAIGN_FAIL],
    promise: (client) => client.delete(`${API_URLS.campaigns}/${id}`),
  };
};

export const removeBulkAssignments = (data, search) => {
  return {
    types: [TYPES.REMOVE_ASSIGNMENTS, TYPES.REMOVE_ASSIGNMENTS_SUCCESS, TYPES.REMOVE_ASSIGNMENTS_FAIL],
    promise: (client) =>
      client.delete(`${API_URLS.assignments}/bulk_delete?${search ? `search=${search}` : ''}`, {
        headers: { ...REQUEST_HEADERS },
        data,
      }),
  };
};

export const getCampaignRetailersData = (params) => ({
  types: [TYPES.CAMPAIGN_RETAILERS, TYPES.CAMPAIGN_RETAILERS_SUCCESS, TYPES.CAMPAIGN_RETAILERS_FAIL],
  promise: (client) => client.get(`${API_URLS.retailers}?${params}`),
});

export const getDataCollectorsList = (params) => ({
  types: [TYPES.DATA_COLLECTORS, TYPES.DATA_COLLECTORS_SUCCESS, TYPES.DATA_COLLECTORS_FAIL],
  promise: (client) => client.get(`${API_URLS.users}?${params}&page_size=10000`),
});

export const getMySecGeogsList = () => ({
  types: [TYPES.GET_MY_SEC_GEOGS, TYPES.GET_MY_SEC_GEOGS_SUCCESS, TYPES.GET_MY_SEC_GEOGS_FAIL],
  promise: (client) => client.get(API_URLS.mySecGeogs),
});
