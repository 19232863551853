import React from 'react';
import { FILTER_TYPES } from '_constants/old/filters';
// import { UsersAdvancedFilter } from 'pages/old/Users/filters/UsersAdvancedFilter';
import { RetailersAdvancedFilter } from 'pages/old/RetailerList/filters/RetailersAdvancedFilter';
import { VisitsAdvancedFilter } from 'modules/VisitsListPage/Filters/VisitsAdvancedFilters';
import { VenuesAdvancedFilter } from 'pages/Venues/List/Filters/VenuesAdvanced';

export const AdvancedFilters = ({ filterType }) => {
  const displayContent = () => {
    switch (filterType) {
      // case FILTER_TYPES.USERS:
      //   return <UsersAdvancedFilter />;
      case FILTER_TYPES.RETAILERS:
        return <RetailersAdvancedFilter />;
      case FILTER_TYPES.ENFORCEMENT:
        return <VisitsAdvancedFilter />;
      case FILTER_TYPES.ASSESSMENT:
        return <VisitsAdvancedFilter />;
      case FILTER_TYPES.EDUCATION:
        return <VisitsAdvancedFilter />;
      case FILTER_TYPES.VENUES:
        return <VenuesAdvancedFilter />;
      default:
        return null;
    }
  };

  return displayContent();
};
