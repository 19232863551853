const COLUMNS = [
  {
    label: 'Date',
    value: 'date',
    disabled: true,
  },
  {
    label: 'Type',
    value: 'type',
    disabled: true,
  },
  {
    label: 'Agency',
    value: 'agency',
    disabled: true,
  },
  {
    label: 'Violation',
    value: 'violation',
    disabled: true,
  },
];

const INITIAL_COLUMNS = ['date', 'type', 'agency', 'violation'];

const ENTITY_TYPE = {
  RETAILER: 'RETAILER',
  VENUE: 'VENUE',
};

export { COLUMNS, INITIAL_COLUMNS, ENTITY_TYPE };
