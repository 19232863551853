import * as TYPES from './filter.types';

export const initialState = {
  mainPath: 'Quick builder',
  crumbs: JSON.parse(localStorage.getItem('BreadCrumbs')) || [],
  filterPaths: [
    'Location',
    'Sales suspension',
    'Policy Tester',
    'Had an enforcement activity during date range',
    'Most recent enforcement activity',
    'Internet sales',
  ],
  generalFilters: {
    ordering: 'name',
    page_size: 20,
    calcpoly: [],
  },

  shadowFilters: {
    ordering: 'name',
    page_size: 20,
    calcpoly: [],
  },

  filterErrors: {},
  isVisitBack: false,
  wasSearch: false,
  visitPath: '',
  queryStringData: {},
};

export const clearCrumbs = () => ({
  type: TYPES.CLEAR_CRUMBS,
});

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPES.SET_SHADOW_FILTER: {
      return {
        ...state,
        shadowFilters: action.payload,
      };
    }
    case TYPES.SET_FILTER_ERROR: {
      return {
        ...state,
        filterErrors: action.payload,
      };
    }
    case TYPES.GET_JURISDICTIONS_SUCCESS: {
      const response = action.result;
      let jurisdictions = '';
      if (response) {
        jurisdictions = response.map((item) => ({
          value: item.attributes.name,
          label: item.attributes.name,
          id: item.id,
          type: 'AggregationPolygon',
        }));
      }
      return {
        ...state,
        jurisdictions,
      };
    }
    case TYPES.GET_AGENCIES_SUCCESS: {
      const response = action.result;
      let agencies = '';
      if (response) {
        agencies = response.map((item) => ({
          value: item.name,
          label: item.name,
          id: item.id,
          type: 'Agency',
        }));
      }
      return {
        ...state,
        agencies,
      };
    }
    case TYPES.SET_NEW_CRUMB: {
      return {
        ...state,
        crumbs: [state.mainPath, action.path],
      };
    }
    case TYPES.CLEAR_CRUMBS: {
      return {
        ...state,
        crumbs: [],
      };
    }
    case TYPES.CLEAR_ALL_FILTERS: {
      return {
        ...state,
        generalFilters: {
          ...initialState.generalFilters,
        },
        shadowFilters: {
          ...initialState.generalFilters,
        },
        filterErrors: initialState.filterErrors,
      };
    }
    case TYPES.CANCEL_FILTERS: {
      return {
        ...state,
        shadowFilters: state.generalFilters,
        filterErrors: initialState.filterErrors,
      };
    }
    case TYPES.APPLY_FILTERS: {
      return {
        ...state,
        generalFilters: action.payload,
        shadowFilters: action.payload,
        queryStringData: action.payload,
      };
    }
    case TYPES.SET_QUERY_DATA: {
      return {
        ...state,
        generalFilters: action.payload,
        shadowFilters: action.payload,
        queryStringData: action.payload,
      };
    }
    case TYPES.SET_IS_VISIT_BACK: {
      return {
        ...state,
        isVisitBack: action.payload,
      };
    }
    case TYPES.SET_VISIT_PATH: {
      return {
        ...state,
        visitPath: action.payload,
      };
    }
    case TYPES.SET_WAS_SEARCH: {
      return {
        ...state,
        wasSearch: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
