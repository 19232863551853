import { API_URLS } from '_constants';
import * as TYPES from './Retailers.types';

export const setAllCities = () => ({
  types: [TYPES.SET_ALL_CITIES, TYPES.SET_ALL_CITIES_SUCCESS, TYPES.SET_ALL_CITIES_FAIL],
  promise: (client) => client.get(API_URLS.cities),
});

export const setAllCounties = () => ({
  types: [TYPES.SET_ALL_COUNTIES, TYPES.SET_ALL_COUNTIES_SUCCESS, TYPES.SET_ALL_COUNTIES_FAIL],
  promise: (client) => client.get(API_URLS.counties),
});

export const setAllSupervisories = () => ({
  types: [TYPES.SET_ALL_SUPERVISORY, TYPES.SET_ALL_SUPERVISORY_SUCCESS, TYPES.SET_ALL_SUPERVISORY_FAIL],
  promise: (client) => client.get(API_URLS.supervisoryDistricts),
});
