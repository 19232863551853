import styled from 'styled-components';
import { Image } from '../Img/styles';

export const Wrapper = styled.article`
  max-width: 352px;
  width: 100%;
  padding: 16px;
  background-color: var(--lite-grey);
`;

export const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  ${Image} {
    margin-right: 8px;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
`;

export const Value = styled.div`
  font-size: 36px;
  line-height: 1.2;
  font-weight: 500;
  margin-top: 14px;
`;

export const Inner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 22px;

  ${Value} {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

export const Item = styled.div`
  flex: 1 0;
  padding-right: 8px;

  :not(:first-of-type) {
    padding-left: 20px;
    border-left: 1px solid var(--grey-border);
  }
`;

export const Label = styled.span`
  font-size: 17px;
  font-weight: 500;
`;
