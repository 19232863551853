/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react';

const radioLabels = [
  { title: 'Yes', value: true },
  { title: 'No', value: false },
  { title: 'Unknown', value: '' },
];

export const TestIsSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    !/firefox/.test(userAgent) && !/chrome/.test(userAgent) && /safari/.test(userAgent) && !/opera/.test(userAgent)
  );
};
export const initCity = {
  key: 'city',
  value: '',
  name: 'city',
  error: '',
  message: '',
  isEdited: false,
};
export const initAddress = {
  key: 'address',
  value: '',
  name: 'address',
  error: '',
  message: '',
  isEdited: false,
};
export const initName = {
  key: 'name',
  value: '',
  name: 'name',
  error: '',
  message: '',
  isEdited: false,
};
const InitialRetailer = [
  {
    key: 'zipcode',
    value: '',
    name: 'zipCode',
    error: '',
    message: '',
    isEdited: false,
  },
  {
    key: 'county_name',
    value: '',
    name: 'county',
    isEdited: false,
  },
  {
    key: 'state',
    value: 'CA',
    name: 'state',
    isEdited: false,
  },
  {
    key: 'phone',
    value: '',
    name: 'phoneNumber',
    isEdited: false,
  },
  {
    key: 'alternate_info',
    value: '',
    name: 'alternateRetailerInfo',
    isEdited: false,
  },
  {
    key: 'modified_by_agency',
    value: '',
    name: 'agencyModified',
    error: '',
    message: '',
    isEdited: false,
  },
  {
    key: 'cdph_business_type',
    name: 'retailerType',
    value: '',
    isEdited: false,
  },
  {
    key: 'is_current',
    value: 'Yes',
    name: 'inBusiness',
    isEdited: false,
  },
  {
    key: 'closed_date',
    value: '',
    name: 'closedDate',
    isEdited: false,
  },
  {
    key: 'age_restricted_flag',
    value: '',
    name: 'ageRestrictedFacilityFlag',
    isEdited: false,
  },
  {
    key: 'corp_flag',
    value: '',
    name: 'corporeFlag',
    isEdited: false,
  },
  {
    key: 'entity_type',
    value: '',
    name: 'entityType',
    isEdited: false,
  },
  {
    key: 'tobacco',
    value: '',
    name: 'sellsTobacco',
    isEdited: false,
  },
  {
    key: 'pharmacy',
    value: '',
    name: 'hasPharmacyCounter',
    isEdited: false,
  },
  {
    key: 'internet_sales',
    value: '',
    name: 'internetSales',
    isEdited: false,
  },
  {
    key: 'url',
    value: '',
    name: 'url',
    isEdited: false,
  },
  {
    key: 'additionalFields',
    value: [
      {
        title: `Additional information #1`,
        name: `Additional information #1`,
        value: '',
        checked: true,
        labelText: 'Visible (default)',
        error: true,
        defaultMessage: '',
      },
      {
        title: `Additional information #2`,
        name: `Additional information #2`,
        value: '',
        checked: true,
        labelText: 'Visible (default)',
        error: true,
        defaultMessage: '',
      },
    ],
    name: 'additionalFields',
    isEdited: false,
  },
  {
    value: { latitude: 0, longitude: 0 },
    name: 'latlong',
    error: '',
    message: '',
    key: 'latlong',
  },
];

const additionalFields = [
  {
    title: `Additional information #1`,
    name: `Additional information #1`,
    value: '',
    checked: true,
    labelText: 'Visible (default)',
    error: true,
    defaultMessage: '',
  },
  {
    title: `Additional information #2`,
    name: `Additional information #2`,
    value: '',
    checked: true,
    labelText: 'Visible (default)',
    error: true,
    defaultMessage: '',
  },
  {
    title: `Additional information #3`,
    name: `Additional information #3`,
    value: '',
    checked: false,
    labelText: 'Visible',
    error: false,
    defaultMessage: '',
  },
  {
    title: `Additional information #4`,
    name: `Additional information #4`,
    value: '',
    checked: false,
    labelText: 'Visible',
    error: false,
    defaultMessage: '',
  },
  {
    title: `Additional information #5`,
    name: `Additional information #5`,
    value: '',
    checked: false,
    labelText: 'Visible',
    error: false,
    defaultMessage: '',
  },
  {
    title: `Additional information #6`,
    name: `Additional information #6`,
    value: '',
    checked: false,
    labelText: 'Visible',
    error: false,
    defaultMessage: '',
  },
  {
    title: `Additional information #7`,
    name: `Additional information #7`,
    value: '',
    checked: false,
    labelText: 'Visible',
    error: false,
    defaultMessage: '',
  },
  {
    title: `Additional information #8`,
    name: `Additional information #8`,
    value: '',
    checked: false,
    labelText: 'Visible',
    error: false,
    defaultMessage: '',
  },
  {
    title: `Additional information #9`,
    name: `Additional information #9`,
    value: '',
    checked: false,
    labelText: 'Visible',
    error: false,
    defaultMessage: '',
  },
  {
    title: `Additional information #10`,
    name: `Additional information #10`,
    value: '',
    checked: false,
    labelText: 'Visible',
    error: false,
    defaultMessage: '',
  },
];

const retailerTypeOption = [
  { id: '09', value: 'Market' },
  { id: '11', value: 'Donut' },
  { id: '20', value: 'Supermarket' },
  { id: '22', value: 'Liquor' },
  { id: '23', value: 'Vape-Shop' },
  { id: '26', value: 'Tobacco' },
  { id: '27', value: 'Drug' },
  { id: '33', value: 'Convenience' },
  { id: '36', value: 'Discount/Warehouse' },
  { id: '58', value: 'Other' },
  { id: '62', value: 'Gas Station' },
];

const initDataArray = {
  city: { open: false, name: 'city' },
  name: { open: false, name: 'name' },
  address: { open: false, name: 'address' },
};

const arrayRadioButtonFields = ['sellsTobacco', 'hasPharmacyCounter', 'internetSales', 'inBusiness'];

const inputStyle = (findObject) => ({
  width: '100%',
  height: '34px',
  paddingLeft: '45px',
  boxSizing: 'border-box',
  position: 'relative',
  marginBottom: '5px',
  marginTop: '5px',
  border: findObject('phoneNumber').error ? '1px solid #D33535' : '1px solid #E5E5E5',
});

const retailerType = [
  'Market',
  'Donut',
  'Supermarket',
  'Liquor',
  'Vape-Shop',
  'Tobacco',
  'Drug',
  'Convenience',
  'Discount/Warehouse',
  'Other',
  'Gas Station',
];

const retailerTypeOptions = [
  {
    value: 'Market',
    label: 'Market',

    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Donut',
    label: 'Donut',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Supermarket',
    label: 'Supermarket',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Liquor',
    label: 'Liquor',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Vape-Shop',
    label: 'Vape-Shop',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Tobacco',
    label: 'Tobacco',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Drug',
    label: 'Drug',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Convenience',
    label: 'Convenience',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Discount/Warehouse',
    label: 'Discount/Warehouse',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Other',
    label: 'Other',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
  {
    value: 'Gas Station',
    label: 'Gas Station',
    key: 'cdph_business_type',
    name: 'retailerType',
  },
];

const initCloseDataField = [
  { value: 'Yes', checked: false },
  { value: 'No', checked: false },
];

const initArrayAgeFlag = ['18', '21', '25'];

const initArrayCorporeFlag = ['Yes', 'No'];

const initArrayEntityType = ['IND', 'BUS', 'GOV'];

const initialValuesFields = [
  {
    title: `Additional information #1`,
    value: '',
    checked: true,
    labelText: 'Visible (default)',
  },
  {
    title: `Additional information #2`,
    value: '',
    checked: true,
    labelText: 'Visible (default)',
  },
  {
    title: `Additional information #3`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #4`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #5`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #6`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #7`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #8`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
  {
    title: `Additional information #9`,
    value: '',
    checked: false,
    labelText: 'Visible',
  },
];

const initArraySellsTobacco = [
  { value: 'Unknown', checked: false },
  { value: 'Yes', checked: false },
  { value: 'No', checked: false },
];

const initArrayInternetSales = [
  { value: 'Yes', checked: false },
  { value: 'No', checked: false },
];

const header = 'You are creating a duplicate.';
const invalidAddressHeader = 'The address you entered is not valid';
const headerTextFields = 'Manage free text fields';
const headerEndEdit = 'Are you still editing this record?';
const viewComponentComeBack = 'Are you sure you want to leave the  page? Your changes won’t be saved';
const invalidAddressComponent = 'Do you still want to save this retailer?';
const text = `To manually update the location, click Update Location below, click on
              the point on the map and drag it to the desired location. Then click Save new location and Save changes.`;
const viewComponent = 'There is a high possibility that the record could be deleted in the future';

const initialValueComponentEdit = (
  <span>
    Your edits will automatically be discarded in
    <b> 20 </b>
    seconds unless you click yes
  </span>
);

const initLocation = (findObject) => ({
  value: {
    latitude: findObject('latlong').value.latitude,
    longitude: findObject('latlong').value.longitude,
  },
});

const initDataEditRetailer = (retailerData, modAgency, findAdditionalFields, findRetailerType, findTitle) => {
  const data = retailerData.data.attributes;
  return [
    {
      value: data?.latlong
        ? {
            latitude: data?.latlong.latitude,
            longitude: data?.latlong.longitude,
          }
        : '',
      name: 'latlong',
      error: '',
      message: '',
      isEdited: false,
      key: 'latlong',
    },
    {
      value: data?.city ? data?.city : '',
      name: 'city',
      error: '',
      message: '',
      key: 'city',
      isEdited: false,
    },
    {
      value: data?.address ? data?.address : '',
      name: 'address',
      error: '',
      key: 'address',
      isEdited: false,
    },
    {
      value: data?.name ? data?.name : '',
      name: 'name',
      error: '',
      message: '',
      key: 'name',
      isEdited: false,
    },
    {
      value: data?.zipcode ? data.zipcode : '',
      name: 'zipCode',
      error: '',
      message: '',
      key: 'zipcode',
      isEdited: false,
    },
    {
      value: data?.county_name ? data?.county_name : '',
      name: 'county',
      key: 'county_name',
      isEdited: false,
    },
    {
      value: data?.state ? data?.state : '',
      name: 'state',
      key: 'state',
      isEdited: false,
    },
    {
      value: data?.phone ? data?.phone.slice(1) : '',
      name: 'phoneNumber',
      key: 'phone',
      isEdited: false,
    },
    {
      value: data?.alternate_info ? data?.alternate_info : '',
      name: 'alternateRetailerInfo',
      key: 'alternate_info',
      isEdited: false,
    },
    {
      value: modAgency,
      key: 'modified_by_agency',
      name: 'agencyModified',
      isEdited: false,
    },
    {
      value: data?.cdph_business_type ? findRetailerType(data?.cdph_business_type) : '',
      name: 'retailerType',
      key: 'cdph_business_type',
      isEdited: false,
    },
    {
      value: data?.is_current === false ? 'No' : 'Yes',
      name: 'inBusiness',
      key: 'is_current',
      isEdited: false,
    },
    {
      value:
        data?.closed_date && data?.is_current === false
          ? `${data?.closed_date?.split('-')[1]}/${data?.closed_date?.split('-')[2]}/${
              data?.closed_date?.split('-')[0]
            }`
          : '',
      name: 'closedDate',
      key: 'closed_date',
      isEdited: false,
    },
    {
      value: data?.age_restricted_flag ? data?.age_restricted_flag : '',
      name: 'ageRestrictedFacilityFlag',
      key: 'age_restricted_flag',
      isEdited: false,
    },
    {
      value: findTitle(data?.corp_flag) || '',
      name: 'corporeFlag',
      key: 'corp_flag',
      isEdited: false,
    },
    {
      value: data?.entity_type ? data?.entity_type : '',
      name: 'entityType',
      key: 'entity_type',
      isEdited: false,
    },
    {
      value: data?.tobacco === null ? 'Unknown' : data?.tobacco === false ? 'No' : 'Yes',
      name: 'sellsTobacco',
      key: 'tobacco',
      isEdited: false,
    },
    {
      value: data?.pharmacy === null ? 'Unknown' : data?.pharmacy === false ? 'No' : 'Yes',
      name: 'hasPharmacyCounter',
      key: 'pharmacy',
      isEdited: false,
    },
    {
      value: data?.internet_sales === null ? '' : data?.internet_sales === false ? 'No' : 'Yes',
      name: 'internetSales',
      key: 'internet_sales',
      isEdited: false,
    },
    {
      value: retailerData ? data?.url : '',
      name: 'url',
      key: 'url',
      isEdited: false,
    },
    {
      value: findAdditionalFields(data),
      name: 'additionalFields',
      key: 'additionalFields',
      isEdited: false,
    },
  ];
};

const initArrayEnforcement = [
  'Undercover Buy',
  'Signage Check',
  'Advertising limitations',
  'Failure to display license',
  'Failure to verify age',
  'Flavored tobacco',
  'Hookah',
  'Internet/Mail violation',
  'Non-MSA inventory',
  'Open Packages/Singles',
  'Package size',
  'Sale to a minor',
  'Self-Service',
  'Shoulder Tap',
  'Unlicensed',
  'Untaxed product',
  'Other',
];

const arrLinks = [
  '/retailers',
  '/dashboard',
  '/enforcement',
  '/licenses',
  '/reports',
  '/users',
  '/agencies',
  '/healthcheck',
];

const arrFieldsNumbers = ['license_number', 'license_holder_id', 'license_holder_name'];

export {
  retailerTypeOption,
  initDataArray,
  header,
  arrLinks,
  text,
  viewComponent,
  initialValueComponentEdit,
  headerEndEdit,
  viewComponentComeBack,
  headerTextFields,
  additionalFields,
  InitialRetailer,
  arrFieldsNumbers,
  radioLabels,
  arrayRadioButtonFields,
  retailerType,
  initArrayCorporeFlag,
  initArrayAgeFlag,
  initArrayEntityType,
  initArraySellsTobacco,
  retailerTypeOptions,
  initArrayInternetSales,
  initCloseDataField,
  initialValuesFields,
  initArrayEnforcement,
  initDataEditRetailer,
  initLocation,
  inputStyle,
  invalidAddressHeader,
  invalidAddressComponent,
};
