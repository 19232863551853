/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';
import { BurgerMenu } from './components';
import MenuList from './components/MenuList';

function HeaderMobile({ routes }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const burgerRef = useRef();

  useEffect(() => {
    document.body.style.overflowY = isOpenMenu ? 'hidden' : 'auto';
  }, [isOpenMenu]);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.HeaderBox>
          <BurgerMenu innerRef={burgerRef} open={isOpenMenu} setOpen={setIsOpenMenu} />
        </Styled.HeaderBox>
      </Styled.Header>
      <Styled.Body isOpenMenu={isOpenMenu}>
        {isOpenMenu && (
          <MenuList isOpenMenu={isOpenMenu} routes={routes} setIsOpenMenu={setIsOpenMenu} burgerRef={burgerRef} />
        )}
      </Styled.Body>
    </Styled.Wrapper>
  );
}

HeaderMobile.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default HeaderMobile;
