/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import { Button } from 'ui-kit';
import PropTypes from 'prop-types';

const ButtonOrganizer = ({ buttons }) => (
  <>
    {buttons.map((item) => (
      <Button
        key={item.text}
        variant={item.variant}
        onClick={item.onClick}
        aria-label={item.text}
        type={item.type}
        id={item.id}
        text={item.text}
        style={item?.style}
      />
    ))}
  </>
);

ButtonOrganizer.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default ButtonOrganizer;
