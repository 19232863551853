import { isNull } from 'lodash';
import { formIsValid } from 'utils/validation';
import { validateText, validateDigits } from 'utils/validation/fields';
import { COMMON_ERRORS } from '_constants/errors';
import {
  FORM_ACTION_TYPES,
  FORM_REQUIRED_FIELDS,
  formInitialState,
  formNames,
  errorLabels,
  genderOptions,
} from './constants';

export const init = (initialState) => {
  return { ...initialState };
};

export const getFullYearAge = (date) => {
  const ageDifMs = Date.now() - date.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const validateData = (action) => {
  const name = errorLabels[action.name];
  switch (action.name) {
    case formNames.geographies:
    case formNames.agencies: {
      if (action.payload.length !== 0) return false;
      return COMMON_ERRORS.isRequired(name);
    }

    case formNames.gender: {
      if (action.payload.value) return false;
      return COMMON_ERRORS.isRequired(name);
    }

    case formNames.isActive: {
      if (action.payload.value !== '') return false;
      return COMMON_ERRORS.isRequired(name);
    }

    case formNames.birthday: {
      if (isNull(action.payload) || action.payload === '') return COMMON_ERRORS.isRequired('birthdate');
      const youthAge = getFullYearAge(action.payload);
      if ((youthAge < 16 || youthAge > 20) && action.isActive && !action.isOlderThanYouthAge)
        return COMMON_ERRORS.invalidBirthDate;
      if ((youthAge < 21 || youthAge > 65) && action.isOlderThanYouthAge && action.isActive)
        return COMMON_ERRORS.invalidBirthDateOlderPeople;

      return false;
    }

    case formNames.youthId: {
      return validateDigits({
        value: action.payload.trim(),
        name: 'youth id',
        required: FORM_REQUIRED_FIELDS.includes(action.name),
        max: 6,
        min: 6,
      });
    }

    case formNames.isOlderThanYouthAge: {
      return false;
    }

    default:
      return validateText({
        value: action.payload.trim(),
        name,
        required: FORM_REQUIRED_FIELDS.includes(action.name),
        max: 6,
        min: 6,
      });
  }
};

const checkFormIsValid = (errors) => formIsValid(errors, FORM_REQUIRED_FIELDS);

const validateFormData = (data) =>
  FORM_REQUIRED_FIELDS.reduce((acc, name) => {
    acc[name] = validateData({
      payload: data.attributes[name],
      name,
      isActive: data.attributes.is_active,
      isOlderThanYouthAge: data.attributes.is_older_than_youth_age,
    });
    return acc;
  }, {});

export const formReducer = (formState, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.ENTER_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...formState.data.attributes,
          [action.name]: action.payload,
        },
      };

      const newErrors = {
        ...formState.errors,
        [action.name]: false,
      };

      const shadowErrors = validateFormData(newData);

      return {
        ...formState,
        data: newData,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(shadowErrors),
      };
    }

    case FORM_ACTION_TYPES.VALIDATE_DATA: {
      const { data } = formState;

      const newErrors = validateFormData(data);

      return {
        ...formState,
        errors: newErrors,
        canBeSubmitted: checkFormIsValid(newErrors),
      };
    }

    case FORM_ACTION_TYPES.RESET_DATA: {
      const newData = {
        ...formState.data,
        attributes: {
          ...action.payload,
        },
      };

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: false,
      };
    }

    case FORM_ACTION_TYPES.SET_DATA: {
      const youthData = FORM_REQUIRED_FIELDS.reduce((acc, item) => {
        switch (item) {
          case formNames.gender: {
            acc[item] = genderOptions.find((option) => option.value === action.payload[item]);
            return acc;
          }

          default: {
            acc[item] = action.payload[item];
            return acc;
          }
        }
      }, {});

      const newData = {
        ...formState.data,
        attributes: {
          ...youthData,
        },
      };

      const shadowErrors = validateFormData(newData);

      return {
        ...formState,
        data: newData,
        errors: false,
        canBeSubmitted: checkFormIsValid(shadowErrors),
      };
    }

    default:
      return {
        ...formInitialState,
      };
  }
};

export const setFormForEdit = (data) => {
  return Object.values(formNames).reduce((acc, item) => {
    switch (item) {
      default: {
        acc[item] = data[item] || '';
        return acc;
      }
    }
  }, {});
};

export const checkFormData = (dispatch, state) => {
  FORM_REQUIRED_FIELDS.forEach((name) => {
    dispatch({
      type: FORM_ACTION_TYPES.VALIDATE_DATA,
      name,
      payload: state[name],
    });
  });
};
