import styled, { css } from 'styled-components';
import { Section, Content } from 'ui-kit/Section/styles';
import { deviceLess } from '_constants/DEVICES';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  @media ${deviceLess.desktopHd} {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
  }
  padding: 25px 25px 40px;
`;

export const TopLine = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const TitleWrapper = styled.div`
  > a {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 22px;
`;

export const CheckboxTitle = styled.label`
  min-width: 150px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    min-width: 135px;
    padding: 0 20px;
    margin-right: 25px;

    :last-of-type {
      margin-right: 0;
    }
  }

  @media ${DEVICES.tablet} {
    flex-direction: column;
    align-items: flex-start;

    button {
      margin: 0;
      margin-bottom: 5px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

export const Dl = styled.dl`
  margin: 0;
  padding: 0;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: stretch;
  border: 1px solid var(--grey);
  overflow: hidden;

  @media ${DEVICES.laptop} {
    flex-flow: column nowrap;
  }

  ${Section} {
    flex: 1 0;
    border: none;
    @media ${deviceLess.laptop} {
      max-width: 45%;
    }

    :first-of-type {
      border-right: 1px solid var(--grey);
      @media ${deviceLess.laptop} {
        max-width: 55%;
      }

      ${Content} {
        padding: 0;
      }

      ${Dl} {
        padding: 20px;
      }

      ${InputWrapper} {
        padding: 25px 20px;
        margin: 0;

        :not(:last-of-type) {
          border-bottom: 1px solid var(--grey);
        }
      }
    }
  }
`;

export const Dt = styled.dt`
  margin: 0 0 10px;
  padding: 0;
  font-size: 16px;
  line-height: 1.17;
  color: var(--dim-grey);

  :not(:first-of-type) {
    margin-top: 25px;
  }
`;

export const Dd = styled.dd`
  display: block;
  font-size: 18px;
  line-height: 1.33;
  color: var(--dark);

  a {
    color: var(--curious-blue);
  }
`;

const wrapperStyles = {
  Youth: '200px',
  Campaign: '200px',
  '': '150px',
};

export const ItemWrapper = styled.div`
  ${({ page }) =>
    css`
  margin-bottom: 10px;
  @media ${deviceLess.tablet} {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  
  dt {
    min-width: ${wrapperStyles[page]};
    display: inline-block;
    word-wrap: break-word;
  }
  > dd, div {
    padding-left: 20px;
    width: 50%;
    min-width: 200px;
    display: inline-block;
    word-wrap: break-word;
  }
  }}`}
`;

export const UserItemWrapper = styled.div`
  position: relative;
  margin-bottom: 25px;
  @media ${deviceLess.tablet} {
    display: flex;
    flex-direction: row;
    flex: 2;

    dt {
      width: 45%;
    }
    > div,
    > dd {
      width: 55%;
      min-width: 200px;
      display: inline-block;
      word-wrap: break-word;
    }
  }
`;

export const DdWrapper = styled.div``;

export const VerticalBorder = styled.div`
  @media ${deviceLess.tablet} {
    border-right: 1px solid #e0e0e0;
    position: absolute;
    left: 0;
    top: 0;
    width: 43%;
    height: 100%;
    z-index: 1;
  }
`;

export const HorizontalBorder = styled.div`
  border-bottom: 1px solid #e0e0e0;
  position: absolute;
  top: calc(100% + 10px);
  left: -20px;
  width: calc(100% + 40px) !important;
  z-index: 1;
  filter: opacity(0.4);

  @media ${deviceLess.laptop} {
    border-bottom: 1px solid #e0e0e0;
    position: absolute;
    top: calc(100% + 5px);
    left: -20px;
    width: calc(100% + 40px);
    z-index: 1;
    filter: opacity(0.4);
  }
`;
