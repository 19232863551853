/* eslint-disable react/prop-types */
import { uniqueId } from 'lodash';
import React, { useState } from 'react';
import { ARROW_RIGHT } from 'assets/icons';
import { Span } from '../../old/styles';
import * as Styled from './styles';

export const MobileCollapsedTab = ({ children, title, index }) => {
  const [isShowContent, setIsShowContent] = useState(false);

  const toggleContent = () => setIsShowContent((prev) => !prev);
  const onKeyDown = (e, id) => {
    if (e.keyCode === 13) {
      toggleContent();
    }

    if (e.keyCode === 9 && id) {
      const elem = document.getElementById(id);

      elem?.scrollIntoView(true);
    }
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.Tab
          onClick={toggleContent}
          onKeyDown={(e) => onKeyDown(e, `MenuItem${title}`)}
          isActive={isShowContent}
          tabIndex={0}
          id={index === 0 ? 'firstMobItem' : uniqueId()}
        >
          {title}
          <img alt="arrow-right" src={ARROW_RIGHT} aria-hidden />
        </Styled.Tab>
        {isShowContent && <Styled.Content>{children}</Styled.Content>}
      </Styled.Wrapper>
      <Span id={`MenuItem${title}`}>text</Span>
    </>
  );
};
