import React, { useRef } from 'react';
import { FILTER_FIELDS } from '_constants/old/filters';
import { FormGroup } from 'modules/Modals/old/FilterModal/components/FormGroup';
import { InputWrapper } from 'modules/Modals/old/FilterModal/components/InputWrapper';
import * as Styled from './styles';

export const RetailerCharacteristics = () => {
  const input = useRef(null);

  const FIELDS = [
    {
      id: 1,
      label: 'Retailer name',
      name: FILTER_FIELDS.RETAILER_CHAR_RETAILER_NAME,
    },
    {
      id: 2,
      label: 'Retailer address',
      name: FILTER_FIELDS.RETAILER_CHAR_RETAILER_ADDRESS,
    },
  ];

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      input.current.focus();
    }
  };

  return (
    <Styled.Wrapper>
      <FormGroup>
        <Styled.List>
          {FIELDS.map((field) => (
            <Styled.ListItem key={field.id}>
              <InputWrapper
                name={field.name}
                id={field.name}
                title={field.label}
                ref={input}
                tabIndex={0}
                onKeyDown={handleKeyDown}
                type="text"
              />
            </Styled.ListItem>
          ))}
        </Styled.List>
      </FormGroup>
    </Styled.Wrapper>
  );
};
