import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Loader } from 'ui-kit';
import { VisitEntity } from '_entities';
import VisitEditForm from './components/VisitEditForm';

const { getVisitInfo, setVisitInfo, resetVisitData } = VisitEntity.actions;

function VisitEdit(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const payload = { ...props };
        await Promise.all([dispatch(getVisitInfo(id)), dispatch(setVisitInfo(payload))]);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
    return async () => {
      await dispatch(resetVisitData());
    };
  }, [dispatch, id, props]);

  if (isLoading) return <Loader />;

  return <VisitEditForm />;
}

export default VisitEdit;
