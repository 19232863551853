import { isEmpty } from 'lodash';
import { formatDateTimeZone } from './formatDateTimeZone';

const getFormattedItem = (date, timeZone) => (isEmpty(date) ? null : formatDateTimeZone(date, timeZone, 'L'));

export const parseYouthProfileData = ({ data, included }, timeZone) => {
  const {
    last_edited_by: { data: modifiedUser },
    created_by: { data: createdBy },
    agencies: { data: agencies },
    geographies: { data: geographies },
  } = data.relationships;

  const getItemList = (type, idList) =>
    included.reduce((acc, item) => {
      if (item.type === type && idList.includes(item.id)) {
        acc.push({ label: item.attributes.name, value: item.id });
      }
      return acc;
    }, []);

  const agenciesIdList = agencies.map((item) => item.id);
  const secGeogsIdList = geographies.map((item) => item.id);
  const secGeogsList = getItemList('AggregationPolygon', secGeogsIdList);
  const agenciesList = getItemList('Agency', agenciesIdList);
  const modifiedById = modifiedUser ? modifiedUser.id : createdBy?.id;
  const userCreator = (id) => {
    return included.reduce((acc, item) => {
      if (item.type === 'User' && item.id === id) {
        const user = {
          ...item.attributes,
          id: item.id,
        };
        acc = user;
      }
      return acc;
    }, {});
  };

  // In order to save the right day after time zone difference we should add 12 hours to birth date
  const birthdateToNoon = new Date(new Date(data.attributes.birthdate).getTime() + 60 * 12 * 60 * 1000);

  const result = {
    attributes: {
      ...data.attributes,
      birthdate: birthdateToNoon,
      agencies: agenciesList,
      geographies: secGeogsList,
      modified: getFormattedItem(data.attributes.modified, timeZone),
      date_joined: getFormattedItem(data.attributes.date_joined, timeZone),
      last_edited_by: userCreator(modifiedById),
      created_by: userCreator(createdBy?.id),
      id: data.id,
    },
    relationships: data.relationships,
  };

  return result;
};
