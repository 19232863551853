/* eslint-disable react/prop-types */
import React from 'react';
import { isEmpty } from 'lodash';
import * as Styled from './styles';
import TextCell from '../TextCell';

const LinkCell = ({ to, withTooltip, text, additionalText, ...rest }) => {
  if (isEmpty(text)) return <TextCell />;

  return (
    <Styled.Wrapper>
      {additionalText && <Styled.AdditionalText>{additionalText}</Styled.AdditionalText>}
      <Styled.Link to={to} {...rest}>
        {text}
      </Styled.Link>
    </Styled.Wrapper>
  );
};

LinkCell.propTypes = {};

LinkCell.defaultProps = {};

export default LinkCell;
