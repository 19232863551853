export const mergersAction = {
  mergerData: 'GET_MERGER_DATA',
  LoadStarted: 'CHANGE_LOADING',
  mergerRetailer: 'MERGER_RETAILER',
  mergerEvent: 'MERGER_EVENT',
};

const mergersTableData = (payload) => ({
  type: mergersAction.mergerData,
  payload,
});

const mergersLoadStarted = (payload) => ({
  type: mergersAction.LoadStarted,
  payload,
});

const mergerRetailer = (payload) => ({
  type: mergersAction.mergerRetailer,
  payload,
});

const mergerEvent = (payload) => ({
  type: mergersAction.mergerEvent,
  payload,
});

export { mergersTableData, mergersLoadStarted, mergerRetailer, mergerEvent };
