/* eslint-disable */
import React from 'react';
import * as Styled from './styles';

function ToastList({ toasts }) {
  return (
    <Styled.Wrapper>
      <Styled.List>
        {
          toasts.map(({ text, src = null }, index) => (
            <Styled.ListItem key={`${ index }_${ text }`} bottom={`${ (index + 1) * 56 }px`}>
              {src && <Styled.ToastIcon src={src} alt={text}/>}
              {text}
            </Styled.ListItem>
          ))
          }
      </Styled.List>
    </Styled.Wrapper>
  );
}

export default ToastList;
