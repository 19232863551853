import styled from 'styled-components';
import { CLOSE_MODAL_ICON_WHITE, COPY_ICON } from 'assets/icons';

export const CopyButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 13px;
  right: 10px;
  height: 25px;
  color: white;
  padding: 0;
  border: none;
`;

export const CopyText = styled.span`
  margin-top: 5px;
  margin-right: 10px;
`;

export const CopyButton = styled.button`
  cursor: pointer;
  width: 25px;
  height: 25px;
  color: white;
  background: transparent url(${COPY_ICON}) no-repeat center center;
  padding: 0;
  border: none;

  &::after {
    content: 'Copy to clipboard';
    position: absolute;
    top: 17px;
    right: -10px;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    font-size: 12px;
  }

  &:hover::after {
    opacity: 1;
  }
`;

export const CancelButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--white);
  background: transparent url(${CLOSE_MODAL_ICON_WHITE}) no-repeat center center;
  padding: 0;
  border: none;

  &::after {
    content: 'Close pop-up window';
    position: absolute;
    top: 0px;
    right: 30px;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    font-size: 12px;
  }

  &:hover::after {
    opacity: 1;
  }
`;

export const InfoListWrapper = styled.div`
  padding: 20px;
`;

export const InfoListTitle = styled.p`
  margin-bottom: 5px;
`;

export const InfoList = styled.ul`
  max-height: 500px;
  overflow-y: auto;
  list-style: none;

  li {
    margin-bottom: 5px;
  }
`;

export const ImportNotificiationWrapper = styled.div`
  width: 600px;
  min-width: 300px;
  height: fit-content
  overflow-y: auto;
  overflow-wrap: break-word;
  padding: 20px;
`;

export const ImportHeadingWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  word-wrap: break-word;
  word-break: break-all;
  max-width: 100%;
`;
