import { isEmpty } from 'lodash';
import { getDataFromInclude } from './getDataFromInclude';

/**
 * Return array with violations point
 *
 * @example getViolationSet([attributes: {},id: "220",relationships: {},type: "Form"],'action_checkbox_enforcement)
 * return [{text: 'Educational warning',value: '1',},{text: '$1,000 fine',value: '2',},]
 *
 * @param {visitIncluded} array
 * @param {field} string
 *
 * @return {array}
 */

const violationInfo = (data) => {
  return data?.reduce((acc, item) => {
    acc[item.value] = item.text;
    return acc;
  }, {});
};

export const getViolationData = (visitIncluded, field) => {
  const formPages = getDataFromInclude(visitIncluded, 'Form').attributes.form_json.pages;
  let questionsField = '';

  const violationData = formPages.find((page) => {
    const isQuestions = Object.prototype.hasOwnProperty.call(page, 'questions');
    questionsField = isQuestions ? 'questions' : 'elements';
    const data = page[questionsField].find((question) => question.name === field);

    return !isEmpty(data);
  });

  if (violationData) {
    const data = violationData[questionsField].find((question) => question.name === field);
    return data?.choices ? violationInfo(data?.choices) : field;
  }

  const data = formPages.reduce((acc, item) => {
    if (item.name !== 'Visit Details') return acc;
    // eslint-disable-next-line no-prototype-builtins
    const isQuestions = item.hasOwnProperty('questions');
    const visitDetailsData = item[isQuestions ? 'questions' : 'elements'].find(({ name }) => name === field);
    if (visitDetailsData) {
      acc.push(...visitDetailsData.choices);
      return acc;
    }
    return acc;
  }, []);
  return violationInfo(data);
};
